import { FieldLabels, ReportFilter } from '@api/models'
import {
  ReactSelectActionMeta,
  ReactSelectOption,
  ReactSelectOptions,
} from '@components/common/Select/Select.types'
import { useFields } from '@hooks'
import { isNullOrUndefined } from '@utils/basic'
import { useState } from 'react'
import { InputChangeEvent } from 'types'
import { ReportFilterField } from './ReportFilter.types'
import { reportFilterDefaultSearchTypes } from './report-filter-fields'

interface IReportFiltersHook {
  isLoadingFields: boolean
  fields: FieldLabels
  state: ReportFilter[]
  isNoFilters: boolean
  getFilterField: (filter: ReportFilter) => ReportFilterField
  handleFieldChange: (
    option: ReactSelectOption,
    actionMeta: ReactSelectActionMeta,
    index: number,
    searchType?: string
  ) => void
  insertNewFilter: () => void
  removeFilter: (index: number) => void
  getFieldSelectOptions: (filterIndex: number) => ReactSelectOptions
  getSingleFieldValue: (index: number) => string
  handleSingleValueChange: (e: InputChangeEvent, index: number) => void
  handleRangeValueChange: (e: InputChangeEvent, index: number) => void
  getRangeFieldValue: (index: number, key: 'From' | 'To') => string
  getMultiSelectValue: (index: number) => string[]
  handleMultiSelectChange: (list: string[], index: number) => void
  handleCheckboxChange: (e: any, index: number) => void
  getSearchTypeFieldOptions: (filterIndex: number) => ReactSelectOptions
  handleSearchTypeChange: (
    option: ReactSelectOption,
    actionMeta: ReactSelectActionMeta,
    index: number
  ) => void
}

interface ReportFiltersProps {
  filterFields: ReportFilterField[]
  filtersTemplateData: any[]
}

export default function useReportFilters({
  filterFields,
  filtersTemplateData,
}: ReportFiltersProps): IReportFiltersHook {
  const [state, setState] = useState<ReportFilter[]>(
    filtersTemplateData ? filtersTemplateData : []
  )

  const isNoFilters: boolean = state.length === 0

  // Why skipRequest? We don't need fields if there are no filters set
  // because fields are only use in map of filters
  const { fields, isLoading: isLoadingFields } = useFields({
    skipRequest: state.length === 0,
  })

  const getFilterField = (filter: ReportFilter): ReportFilterField => {
    return filterFields.find(field => field.name === filter.Name)
  }

  const blankFilter: ReportFilter = {
    Name: '',
    List: [],
    Total: false,
  }

  // Get select options from field options

  function getFieldSelectOptions(filterIndex: number): ReactSelectOptions {
    const fieldSelectOptions: ReactSelectOption[] = []

    for (const filterField of filterFields) {
      const isFieldAlreadySelected: boolean =
        state.filter(filter => filter.Name === filterField.name).length > 0

      const isFieldSelectedInCurrentIndex: boolean =
        state.findIndex(filter => filter.Name === filterField.name) === filterIndex

      if (!isFieldAlreadySelected || isFieldSelectedInCurrentIndex) {
        let label: string
        if (!isNullOrUndefined(filterField.chargeTypeCode)) {
          if (filterField.name === 'CH_ACT_ID') {
            label = 'Activity ID'
          } else {
            label = fields[`${filterField.name}${filterField.chargeTypeCode}`]
          }
        } else {
          label = fields[filterField.name]
        }

        fieldSelectOptions.push({
          label,
          value: filterField.name,
        })
      }
    }

    return fieldSelectOptions
  }

  function getSearchTypeFieldOptions(filterIndex: number): ReactSelectOptions {
    const searchTypeFIeldOptions: ReactSelectOption[] = []

    for (const searchType of reportFilterDefaultSearchTypes) {
      searchTypeFIeldOptions.push({
        label: searchType.label,
        value: searchType.value,
      })
    }

    return searchTypeFIeldOptions
  }

  // Insert or remove filters
  function insertNewFilter() {
    setState(prevState => [...prevState, blankFilter])
  }

  function removeFilter(index: number): void {
    setState(prevState => {
      prevState.splice(index, 1)

      return [...prevState]
    })
  }

  // Change functions

  // Change the field Name
  function handleFieldChange(
    option: ReactSelectOption,
    actionMeta: ReactSelectActionMeta,
    index: number
  ): void {
    setState(prevState => {
      prevState[index].Name = option.value
      prevState[index].SearchType = 'equals'

      return [...prevState]
    })
  }

  function handleSearchTypeChange(
    option: ReactSelectOption,
    actionMeta: ReactSelectActionMeta,
    index: number
  ): void {
    setState(prevState => {
      prevState[index].SearchType = option.value

      return [...prevState]
    })
  }

  // Change the single value for string filter field
  function handleSingleValueChange(e: InputChangeEvent, index: number): void {
    setState(prevState => {
      prevState[index].List[0] = e.target.value

      return [...prevState]
    })
  }

  const getSingleFieldValue = (index: number): string => {
    if (!state[index].List || state[index].List.length === 0) {
      return ''
    } else return state[index].List[0]
  }

  // Range values
  function handleRangeValueChange(e: InputChangeEvent, index: number): void {
    setState(prevState => {
      prevState[index][e.target.id] = e.target.value

      return [...prevState]
    })
  }

  const getRangeFieldValue = (index: number, key: 'From' | 'To'): string => {
    if (!state[index][key]) {
      return ''
    } else return state[index][key]
  }

  // Multi select
  function handleMultiSelectChange(list: string[], index: number): void {
    setState(prevState => {
      prevState[index].List = list

      return [...prevState]
    })
  }

  const getMultiSelectValue = (index: number): string[] => {
    if (!state[index].List) return []

    return state[index].List
  }

  function handleCheckboxChange(e: InputChangeEvent, index: number): void {
    setState(prevState => {
      prevState[index].Total = e.target.checked

      return [...prevState]
    })
  }

  return {
    handleCheckboxChange,
    isLoadingFields,
    fields,
    state,
    isNoFilters,
    getFilterField,
    handleFieldChange,
    insertNewFilter,
    removeFilter,
    getFieldSelectOptions,
    getSingleFieldValue,
    handleSingleValueChange,
    handleRangeValueChange,
    getRangeFieldValue,
    getMultiSelectValue,
    handleMultiSelectChange,
    getSearchTypeFieldOptions,
    handleSearchTypeChange,
  }
}
