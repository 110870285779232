export const APPEARANCE = {
  default: 'default',
  primary: 'primary',
  muted: 'muted',
  clear: 'clear',
}
export const INTENT = {
  default: 'default',
  danger: 'danger',
}

export const SHAPE = {
  default: 'default',
  pill: 'pill',
  circle: 'circle',
  square: 'square',
}

export const SIZE = {
  mini: 'mini',
  default: 'default',
  compact: 'compact',
  large: 'large',
  extraLarge: 'extra-large',
}
