import { Palette } from '@styles-api/palette.types'
import { BoxShadow, StyleObject } from '@styles-api/css.types'
import CSS from 'csstype'

export const container = ({ height, flexGrow }) => {
  let fg
  if (flexGrow === true) fg = 1

  return {
    height: flexGrow ? 'min-content' : height,
    display: 'block',
    flexGrow: flexGrow ? 1 : undefined,
  }
}

export const base: StyleObject = {
  borderRadius: 'var(--radius1)',
  display: 'flex',
  alignItems: 'start',
  minHeight: 'var(--size-default)',
  paddingTop: 'var(--scale550)',
  paddingBottom: 'var(--scale550)',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
  lineHeight: '1.28em',
}

export const getIntent = ({ intent, appearance }): StyleObject => {
  let backgroundColor: Palette
  let color: Palette
  let borderColor: Palette
  let borderWidth: string
  let borderLeftWidth: string
  let boxShadow: BoxShadow

  if (appearance === 'default') {
    borderWidth = '1px'
    switch (intent) {
      case 'default':
        backgroundColor = 'var(--primary-color50)'
        color = 'var(--text-color)'
        borderColor = 'var(--primary-color200)'
        break
      case 'warning':
        backgroundColor = 'var(--warning-color50)'
        color = 'var(--text-color)'
        borderColor = 'var(--warning-color200)'
        break
      case 'success':
        backgroundColor = 'var(--success-color50)'
        color = 'var(--text-color)'
        borderColor = 'var(--success-color200)'
        break
      case 'danger':
        backgroundColor = 'var(--danger-color50)'
        color = 'var(--danger-color)'
        borderColor = 'var(--danger-color200)'
        break
    }
  } else if (appearance === 'elevated') {
    borderLeftWidth = '4px'
    boxShadow = 'var(--card-shadow)'
    switch (intent) {
      case 'default':
        backgroundColor = 'var(--primary-color50)'
        color = 'var(--text-color)'
        borderColor = 'var(--primary-color)'
        break
      case 'warning':
        backgroundColor = 'var(--warning-color50)'
        color = 'var(--text-color)'
        borderColor = 'var(--warning-color)'
        break
      case 'success':
        backgroundColor = 'var(--success-color50)'
        color = 'var(--text-color)'
        borderColor = 'var(--success-color)'
        break
      case 'danger':
        backgroundColor = 'var(--danger-color50)'
        color = 'var(--danger-color)'
        borderColor = 'var(--danger-color)'
        break
    }
  }

  return {
    backgroundColor,
    color,
    borderColor,
    borderWidth,
    borderLeftWidth,
    boxShadow,
  }
}
export const icon: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  marginRight: 'var(--scale300)',
  height: 'var(--scale600)',
  width: 'var(--scale600)',
  marginTop: '-1px',
}
//
export const noteStyles: StyleObject = {
  borderRadius: 'var(--radius1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 'var(--size-default)',
}
export const note__innerStyles: StyleObject = {
  display: 'flex',
  alignItems: 'center',
}
export const note__contentStyles: StyleObject = {
  marginLeft: '12px',
}
export const note__removeButtonStyles: StyleObject &
  { [P in CSS.Pseudos]?: CSS.Properties } = {
  height: '20px!important',
  width: '20px!important',
  marginLeft: '12px',
  color: 'var(--textColor)',
  ':hover': {
    background: 'rgba(0,0,0,0.1)!important',
  },
}
/* ----------------------- STATES ----------------------- */
export const note_defaultStyles: StyleObject = {
  background: 'var(--shade200)',
}
export const note_primaryStyles: StyleObject = {
  background: 'var(--primaryColor)',
  color: 'var(--primaryColorContrastText)',
}
export const note_lightStyles: StyleObject = {
  background: 'var(--primaryColorLight)',
}
export const note_outlineStyles: StyleObject = {
  background: 'none',
  border: '1px solid var(--primaryColor)',
}

export const note_primary_successStyles: StyleObject = {
  background: 'var(--successColor)',
  color: 'var(--successColorContrastText)',
}
export const note_primary_warningStyles: StyleObject = {
  background: 'var(--warningColor)',
  color: 'var(--warningColorContrastText)',
}
export const note_primary_dangerStyles: StyleObject = {
  background: 'var(--dangerColor)',
  color: 'var(--dangerColorContrastText)',
}
export const note_primary__labelStyles: StyleObject = {
  background: 'var(--primaryColorContrastText)!important',
}
export const note_primary__removeButtonStyles: StyleObject &
  { [P in CSS.Pseudos]?: CSS.Properties } = {
  color: 'var(--primaryColorContrastText)!important',
  ':hover': {
    background: 'rgba(0,0,0,0.3)!important',
  },
}

export const note_light_successStyles: StyleObject = {
  background: 'var(--successColorLight)',
}
export const note_light_warningStyles: StyleObject = {
  background: 'var(--warningColorLight)',
}
export const note_light_dangerStyles: StyleObject = {
  background: 'var(--dangerColorLight)',
}

export const note_outline_successStyles: StyleObject = {
  border: '1px solid var(--successColor)',
}
export const note_outline_warningStyles: StyleObject = {
  border: '1px solid var(--warningColor)',
}
export const note_outline_dangerStyles: StyleObject = {
  border: '1px solid var(--dangerColor)',
}

/* ----------------------- SKELETON ----------------------- */

// skeleton
export const labelSkeleton: StyleObject = {
  height: 'var(--scale500)',
  width: '80px',
  marginBottom: 'var(--scale300)',
  marginTop: 'var(--scale300)',
}
export const noteSkeleton = ({ size }) => {
  let height = `calc(var(--size-${size}) + 1px)`
  return {
    height,
    width: '100%',
    borderRadius: 'var(--radius1)',
  }
}
