import {
  Button,
  Checkbox,
  Fieldset,
  Flexbox,
  Icon,
  Select,
  Spacer,
  Spinner,
} from '@components/common'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ReportFiltersProps } from './ReportFilter.types'
import ReportFilterInput from './ReportFilterInput'
import useReportFilters from './useReportFilters'
import { reportFilterDefaultSearchTypes } from './report-filter-fields'

const ReportFilters = ({
  filterFields,
  includesTotals = true,
  filtersTemplateData,
}: ReportFiltersProps) => {
  const {
    isLoadingFields,
    state,
    isNoFilters,
    getFilterField,
    handleFieldChange,
    insertNewFilter,
    removeFilter,
    getFieldSelectOptions,
    handleSingleValueChange,
    handleSearchTypeChange,
    getSearchTypeFieldOptions,
    getSingleFieldValue,
    handleRangeValueChange,
    getRangeFieldValue,
    getMultiSelectValue,
    handleMultiSelectChange,
    handleCheckboxChange,
  } = useReportFilters({ filterFields, filtersTemplateData })

  // update form filter value when state changes
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue('filter', state)
  }, [setValue, state])

  return (
    <Fieldset legend="Filters/Sorting">
      {isNoFilters && (
        <Button onClick={() => insertNewFilter()}>Add filter/sort field</Button>
      )}

      {!isNoFilters &&
        (isLoadingFields ? (
          <Flexbox height={60} alignItems="center" justifyContent="center">
            <Spinner />
          </Flexbox>
        ) : (
          <>
            <ul>
              {state.map((filter, index) => (
                <>
                  {state.length > 1 && index !== 0 && <Spacer y="compact" />}
                  <li key={index}>
                    <Flexbox alignItems="end" justifyContent="space-between">
                      <Select
                        options={getFieldSelectOptions(index)}
                        name={`report-filter-${index}`}
                        value={{
                          label: getFieldSelectOptions(index).find(
                            option => option.value === state[index].Name
                          )?.label,
                          value: state[index].Name,
                        }}
                        onChange={(option, actionMeta, r) =>
                          handleFieldChange(option, actionMeta, index)
                        }
                        label="Field"
                        width={'33.333%'}
                      />
                      <Spacer x="mini" />

                      {state[index].Name === 'AssetID' && (
                        <>
                          <Select
                            options={reportFilterDefaultSearchTypes}
                            name={`report-search-type-${index}`}
                            value={{
                              label: getSearchTypeFieldOptions(index).find(
                                option => option.value === state[index].SearchType
                              )?.label,
                              value: state[index].SearchType,
                            }}
                            onChange={(option, actionMeta, r) =>
                              handleSearchTypeChange(option, actionMeta, index)
                            }
                            label="Search Type"
                            width={'15%'}
                          />

                          <Spacer x="mini" />
                        </>
                      )}

                      <ReportFilterInput
                        state={state}
                        handleSingleValueChange={handleSingleValueChange}
                        filterField={getFilterField(filter)}
                        index={index}
                        getSingleFieldValue={getSingleFieldValue}
                        handleRangeValueChange={handleRangeValueChange}
                        getRangeFieldValue={getRangeFieldValue}
                        getMultiSelectValue={getMultiSelectValue}
                        handleMultiSelectChange={handleMultiSelectChange}
                      />
                      {includesTotals && (
                        <>
                          <Spacer x="mini" />
                          <Flexbox flexDirection="column" justifyContent="center">
                            <Spacer y="mini" />
                            <Checkbox
                              label="Total"
                              checked={state[index].Total}
                              onChange={e => handleCheckboxChange(e, index)}
                              disabled={!getFilterField(filter)}
                            />
                            <Spacer y="mini" />
                          </Flexbox>
                        </>
                      )}
                      <Spacer x="mini" />
                      <Flexbox flexDirection="column" justifyContent="flex-end">
                        <Button
                          onClick={() => removeFilter(index)}
                          icon={<Icon name="Trash2" />}
                          shape="square"
                        />
                      </Flexbox>
                    </Flexbox>
                  </li>
                </>
              ))}
            </ul>
            <Spacer y="default" />
            <Flexbox justifyContent="flex-end">
              <Button
                onClick={() => insertNewFilter()}
                icon={<Icon name="PlusCircle" />}
                appearance="text"
              >
                Add new
              </Button>
            </Flexbox>
          </>
        ))}
    </Fieldset>
  )
}
export default ReportFilters
