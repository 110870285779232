import { Asset, FieldLabels } from '@api/models'
import DataDisplayList from '@components/DataDisplayList'
import { formatCurrency, formatDisplayDate } from '@utils/basic'
import { useGetCompanyQuery, useGetSettingsQuery } from '@api'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

interface Props {
  fields: FieldLabels
}

const AssetBookInformationDisplay = ({ fields }: Props) => {
  const params = useParams()

  const { data: company } = useGetCompanyQuery(parseInt(params.companyID))

  const { watch } = useFormContext<Asset>()
  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  const items = [
    {
      label: fields.AS_FIN_COS,
      value: formatCurrency(watch('AS_FIN_COS')),
    },
    {
      label: fields.AS_REC_DAT,
      value: formatDisplayDate(watch('AS_REC_DAT'), DateFormat),
    },
    {
      label: fields.AS_TAX_BUS,
      value: `${watch('AS_TAX_BUS')}%`,
    },
    {
      label: fields.AS_FIN_SAL,
      value: formatCurrency(watch('AS_FIN_SAL')),
    },
    {
      label: fields.AS_CAT_ID,
      value: `${watch('AS_CAT_ID')}`,
    },
  ]

  if (company?.CM_TAX) {
    items.push({
      label: fields.AS_TCLS_ID,
      value: watch('AS_TCLS_ID'),
    })
  }

  if (company?.CM_SMH) {
    items.push({
      label: fields.AS_SCLS_ID,
      value: watch('AS_SCLS_ID'),
    })
  }

  if (company?.CM_BOK4_CD) {
    items.push({
      label: fields.AS_4CLS_ID,
      value: watch('AS_4CLS_ID'),
    })
  }

  return <DataDisplayList items={items} columns={2} />
}
export default AssetBookInformationDisplay
