import React from 'react'
import { SimpleTableProps, SimpleTableSubComponents } from './SimpleTable.types'

import { Body, Cell, Head, Header, HeadRow, Row } from './elements'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

const SimpleTable: React.FC<SimpleTableProps> & SimpleTableSubComponents = ({
  ...props
}) => {
  const [css] = useStyletron()

  return (
    <table {...props} className={css(styles.table)}>
      {props.children}
    </table>
  )
}

SimpleTable.Head = Head
SimpleTable.HeadRow = HeadRow
SimpleTable.Header = Header

SimpleTable.Body = Body
SimpleTable.Row = Row
SimpleTable.Cell = Cell

export default SimpleTable
