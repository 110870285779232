import { ImportColumn } from "../importTypes";

export const assets: ImportColumn[] = [
    {
        label: 'Company ID',
        value: 'AS_COM_ID'
    },
    {
        label: 'Asset ID',
        value: 'AS_ASS_ID'
    },
    {
        label: 'Asset Description',
        value: 'AS_DESC'
    },
    {
        label: 'Asset Description Extra',
        value: 'AS_DESC_E'
    },
    {
        label: 'Serial Number',
        value: 'AS_SERIAL'
    },
    {
        label: 'Date Added',
        value: 'AS_ADD_DAT'
    },
    {
        label: 'Added by user',
        value: 'AS_ADD_USR'
    },
    {
        label: 'Date Last Modified',
        value: 'AS_CHG_DAT'
    },
    {
        label: 'Modified by user',
        value: 'AS_CHG_USR'
    },
    {
        label: 'Division',
        value: 'AS_KEY0_ID'
    },
    {
        label: 'Branch',
        value: 'AS_KEY1_ID'
    },
    {
        label: 'Department',
        value: 'AS_KEY2_ID'
    },
    {
        label: 'Cost Center',
        value: 'AS_KEY3_ID'
    },
    {
        label: 'Account',
        value: 'AS_KEY4_ID'
    },
    {
        label: 'Key 5',
        value: 'AS_KEY5_ID'
    },
    {
        label: 'Key 6',
        value: 'AS_KEY6_ID'
    },
    {
        label: 'Key 7',
        value: 'AS_KEY7_ID'
    },
    {
        label: 'Key 8',
        value: 'AS_KEY8_ID'
    },
    {
        label: 'Key 9',
        value: 'AS_KEY9_ID'
    },
    {
        label: 'Category ID',
        value: 'AS_CAT_ID'
    },
    {
        label: 'Tax Class ID',
        value: 'AS_TCLS_ID'
    },
    {
        label: 'Primary ID',
        value: 'AS_PRI_UCD'
    },
    {
        label: 'Secondary ID',
        value: 'AS_SEC_UCD'
    },
    {
        label: 'Building Code',
        value: 'AS_TAXB_CD'
    },
    {
        label: 'Listed Property Code',
        value: 'AS_TAXL_CD'
    },
    {
        label: 'Business Use %',
        value: 'AS_TAX_BUS'
    },
    {
        label: 'Listed Property Type',
        value: 'AS_TAX_PTP'
    },
    {
        label: 'Date in Service',
        value: 'AS_REC_DAT'
    },
    {
        label: 'Vendor ID',
        value: 'AS_VEN_ID'
    },
    {
        label: 'Vendor Name',
        value: 'AS_VEN_NM'
    },
    {
        label: 'PO Number',
        value: 'AS_REC_PON'
    },
    {
        label: 'Requisitioner',
        value: 'AS_REC_USR'
    },
    {
        label: 'Corporate Acq. Vouchers Written',
        value: 'AS_SUB_DCR'
    },
    {
        label: 'Tax Acq. Vouchers Written',
        value: 'AS_SUB_DTR'
    },
    {
        label: 'State Acq. Vouchers Written',
        value: 'AS_SUB_DSR'
    },
    {
        label: 'Other Acq. Vouchers Written',
        value: 'AS_SUB_DOR'
    },
    {
        label: 'Acquisition Cost',
        value: 'AS_FIN_COS'
    },
    {
        label: 'Salvage Amount',
        value: 'AS_FIN_SAL'
    },
    {
        label: 'Depreciable Value',
        value: 'AS_FIN_VAL'
    },
    {
        label: 'Location Costs',
        value: 'AS_FIN_CLC'
    },
    {
        label: 'Service Costs',
        value: 'AS_FIN_CSC'
    },
    {
        label: 'Other Costs (Tasks)',
        value: 'AS_FIN_COC'
    },
    {
        label: 'Folder Costs',
        value: 'AS_FIN_CFC'
    },
    {
        label: 'Total Capitalized Costs',
        value: 'AS_FIN_TAC'
    },
    {
        label: 'Asset Status',
        value: 'AS_DIS_CD'
    },
    {
        label: 'Disposal Date',
        value: 'AS_DIS_DAT'
    },
    {
        label: 'Realized Amount',
        value: 'AS_DIS_SAL'
    },
    {
        label: 'Disposal/Sold To',
        value: 'AS_DIS_NAM'
    },
    {
        label: 'Disposed By User',
        value: 'AS_DIS_USR'
    },
    {
        label: 'Insurance Policy',
        value: 'AS_IN_POL'
    },
    {
        label: 'Invoice Date',
        value: 'AS_REP_DAT'
    },
    {
        label: 'State Code',
        value: 'AS_STAT_CD'
    },
    {
        label: 'Other Code',
        value: 'AS_BOK4_CD'
    },
    {
        label: 'Write Off Code',
        value: 'AS_WRO_CD'
    },
    {
        label: 'Write Off Date',
        value: 'AS_WRO_DAT'
    },
    {
        label: 'Parent ID',
        value: 'AS_PAR_ASS'
    },
    {
        label: 'Next Service Date',
        value: 'AS_NSER_DT'
    },
    {
        label: 'Maintenance Contract ID',
        value: 'AS_MAIN_CN'
    },
    {
        label: 'Depreciation Has Started Code',
        value: 'AS_DEP_STC'
    },
    {
        label: 'Lease ID',
        value: 'AS_LEASE'
    },
    {
        label: 'Lease Rate',
        value: 'AS_LEASEP'
    },
    {
        label: 'Default Location Disposition',
        value: 'AS_LOCD_CD'
    },
    {
        label: 'Default Location Post Code',
        value: 'AS_LAPC_ID'
    },
    {
        label: 'Default Location Depreciation',
        value: 'AS_LDPC_ID'
    },
    {
        label: 'Default Service Disposition',
        value: 'AS_SERD_CD'
    },
    {
        label: 'Default Service Post Code',
        value: 'AS_SAPC_ID'
    },
    {
        label: 'Default Service Depreciation',
        value: 'AS_SDPC_ID'
    }
]