import { Card, EmptyState, Link } from '@components/common'
import { COMPANY_PATHS } from '@constants'

interface Props {
  assetCompanyID: number
  profileName: string
}
const ProfileNotMaintained = ({ assetCompanyID, profileName }: Props) => {
  return (
    <Card>
      <EmptyState
        heading={`Company ${assetCompanyID} does not maintain ${profileName.toLowerCase()} depreciation.`}
        text={
          <>
            To view and edit the depreciation profiles this company maintains,{' '}
            <Link to={`/${COMPANY_PATHS.ROOT}/${assetCompanyID}/${COMPANY_PATHS.DETAILS}`}>
              go to company details
            </Link>
            .
          </>
        }
      />
    </Card>
  )
}

export default ProfileNotMaintained
