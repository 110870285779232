import React, { useState } from 'react'
import { ToggleSwitchProps } from './ToggleSwitch.types'

import { useStyletron } from 'styletron-react'
import {
  toggleContainerStyles,
  toggle_on__labelStyles,
  toggle__hiddenInputStyles,
  toggle__labelStyles,
  toggle__switchStyles,
} from './Styles'

import * as styles from './Styles'

import { motion } from 'framer-motion'
import { SPRING_MOTION } from '@constants'
import { StyleObject } from '@styles-api/css.types'

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  name,
  checked,
  onChange,
  disabled,
  label,
  autoFocus,
}) => {
  const [focus, setFocus] = useState(false)
  function handleKeyPress(e) {
    if (e.keyCode !== 32) return

    e.preventDefault()
    onChange(!checked)
  }

  const [css] = useStyletron()

  var containerStyles = css(toggleContainerStyles({ focus }))
  var hiddenInputStyles = css(toggle__hiddenInputStyles)
  var labelStyles = css(toggle__labelStyles)
  var switchStyles = css(toggle__switchStyles({ focus }))

  const floatRight: StyleObject = {
    marginLeft: 'auto',
    float: 'right',
  }

  if (checked) {
    labelStyles += ' ' + css(toggle_on__labelStyles)
    switchStyles += ' ' + css(floatRight)
  }

  return (
    <div className={css(styles.wrapper)}>
      <span className={css(styles.labelText)}>{label}</span>
      <div
        className={containerStyles}
        onFocus={() => setFocus(!focus)}
        onBlur={() => setFocus(!focus)}
      >
        <input
          type="checkbox"
          name={name}
          className={hiddenInputStyles}
          id={id}
          checked={checked}
          onChange={e => onChange(e.target.checked)}
          disabled={disabled}
          tabIndex={0}
          autoFocus={autoFocus}
          onFocus={() => setFocus(!focus)}
          onBlur={() => setFocus(!focus)}
        />

        {name ? (
          <label
            tabIndex={disabled ? -1 : 0}
            onKeyDown={e => handleKeyPress(e)}
            htmlFor={name}
            className={labelStyles}
          >
            <motion.span
              layout
              transition={SPRING_MOTION}
              className={switchStyles}
              tabIndex={-1}
            />

            <span tabIndex={-1} />
          </label>
        ) : null}
      </div>
    </div>
  )
}

export default ToggleSwitch
