import { DeprecExtraFields, DepreciationClass, FieldLabels } from '@api/models'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import {
  Checkbox,
  Fieldset,
  Flexbox,
  Grid,
  Input,
  Radio,
  Select,
  Spacer,
} from '@components/common'
import { DeprecClassHookProps } from '@components/depreciation-class/DepreciationClass.types'

interface Props extends DeprecClassHookProps {
  fields: FieldLabels
  extraFields: DeprecExtraFields
  isNonLinearDepMethodSelected: boolean
  isNoDepMethodSelected: boolean
  deductFromAcq: boolean
  deductFromBookValue: boolean
  lifetimeLabel: string
}

const DeprecClassDeprecInfoForm = ({
  fields,
  extraFields,
  isNoDepMethodSelected,
  isNonLinearDepMethodSelected,
  deductFromAcq,
  deductFromBookValue,
  lifetimeLabel,
  context,
}: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
  } = useFormContext<DepreciationClass>()

  const {
    getInputProps,
    getSelectProps,
    getRadioProps,
    getCreatableSelectProps,
    getCheckBoxProps,
  } = useFormFieldProps<DepreciationClass>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  return (
    <Grid>
      <Grid.Item>
        <Controller
          control={control}
          name={'DP_MET_CD'}
          render={({ field }) => (
            <Select
              {...getSelectProps('DP_MET_CD', field, extraFields.depreciationMethod)}
              isSearchable
              autoFocus={context !== 'create'}
            />
          )}
        />
      </Grid.Item>
      <Grid.Item>
        <Controller
          control={control}
          name={'DP_STRT_CD'}
          render={({ field }) => (
            <Select
              {...getSelectProps('DP_STRT_CD', field, extraFields.startCode)}
              isSearchable
            />
          )}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          {...getInputProps('DP_LIFETIM')}
          min={0}
          disabled={isNoDepMethodSelected}
          label={lifetimeLabel}
        />
      </Grid.Item>
      <Grid.Item>
        <Fieldset legend="Rate">
          <Flexbox alignItems="center">
            <Input
              {...getInputProps('DP_RATE', 'percentage')}
              min={0}
              MAX={100}
              disabled={isNonLinearDepMethodSelected}
              label=""
              width="120px"
            />
            <Spacer x="default" />
            <Controller
              control={control}
              name="DP_RATE_CD"
              render={({ field }) => (
                <Radio {...getRadioProps(field)}>
                  <Radio.Option
                    value="A"
                    label="Annual"
                    disabled={isNonLinearDepMethodSelected}
                  />
                  <Radio.Option
                    value="L"
                    label="Lifetime"
                    disabled={isNonLinearDepMethodSelected}
                  />
                </Radio>
              )}
            />
          </Flexbox>
        </Fieldset>
      </Grid.Item>
      <Grid.Item>
        <Controller
          control={control}
          name={'DP_ACPC_ID'}
          render={({ field }) => (
            <Select
              {...getSelectProps('DP_ACPC_ID', field, extraFields.glPost)}
              isSearchable
            />
          )}
        />
      </Grid.Item>
      <Grid.Item>
        <Controller
          control={control}
          name={'DP_DSPC_ID'}
          render={({ field }) => (
            <Select
              {...getSelectProps('DP_DSPC_ID', field, extraFields.glPost)}
              isSearchable
            />
          )}
        />
      </Grid.Item>
      <Grid.Item>
        <Controller
          control={control}
          name={'DP_DPPC_ID'}
          render={({ field }) => (
            <Select
              {...getSelectProps('DP_DPPC_ID', field, extraFields.glPost)}
              isSearchable
            />
          )}
        />
      </Grid.Item>
      <Grid.Item>
        <Controller
          control={control}
          name="DP_DGPC_ID"
          render={({ field }) => (
            <Select {...getSelectProps('DP_DGPC_ID', field, extraFields.glPost)} />
          )}
        />
      </Grid.Item>
      <Grid.Item m={6}>
        <Fieldset legend="Year 1 Tax Credit">
          <Input
            {...getInputProps('DP_TAX_CRD')}
            label="Amount"
            icon={'$'}
            disabled={!deductFromAcq}
          />
          <Spacer y="compact" />
          <Controller
            control={control}
            name="DP_TAX_UCD"
            render={({ field }) => (
              <Select
                {...getCreatableSelectProps('DP_TAX_UCD', field, extraFields.taxCreditCode)}
                disabled={!deductFromAcq}
                placeholder="Start typing to search or create new"
              />
            )}
          />
          <Spacer y="compact" />
          <Controller
            control={control}
            name="DP_TAXC_CD"
            render={({ field }) => (
              <Checkbox {...getCheckBoxProps(field)} label="Deduct from acquisition cost" />
            )}
          />
        </Fieldset>
      </Grid.Item>
      <Grid.Item m={6}>
        <Fieldset legend="Year 1 Depreciation Add-on">
          <Input
            {...getInputProps('DP_INT_DED')}
            icon={'$'}
            label="Amount"
            disabled={!deductFromBookValue}
          />
          <Spacer y="compact" />
          <Controller
            control={control}
            name="DP_INT_UCD"
            render={({ field }) => (
              <Select
                {...getCreatableSelectProps(
                  'DP_INT_UCD',
                  field,
                  extraFields.yearOneDeprecAddonCode
                )}
                disabled={!deductFromBookValue}
                placeholder="Start typing to search or create new"
              />
            )}
          />
          <Spacer y="compact" />
          <Controller
            control={control}
            name="DP_INT_CD"
            render={({ field }) => (
              <Checkbox {...getCheckBoxProps(field)} label="Deduct from book value" />
            )}
          />
        </Fieldset>
      </Grid.Item>
    </Grid>
  )
}

export default DeprecClassDeprecInfoForm
