import {
  useAssetDisposalMutation,
  useAssetHasHistoryQuery,
  useFetchAssetFoldersQuery,
  useMaintainedDepProfilesQuery,
  useReinstateAssetMutation,
  useSaveFolderListMutation,
} from '@api'

import { useAssetNeedsRecalcMutation } from '@api/assets/assetsEndpointsHooks'

import {
  useGetAssetQuery,
  useDeleteAssetMutation,
  useUpdateAssetMutation,
  useDuplicateAssetMutation,
} from '@api/assets/assetsEndpoints'

import {
  AllAssetDetailFields,
  Asset,
  assetDetailsSchema,
  AssetRecalcDTO,
  EditAssetPayload,
} from '@api/models'
import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useForm, UseFormHandleSubmit, UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from '@components/common'
import {
  DELETE_SUCCESS,
  DISPOSE_SUCCESS,
  DUPLICATE_SUCCESS,
  ERROR_REQUEST,
  ERROR_SAVE,
  REINSTATE_SUCCESS,
} from '@constants'
import { useNavigate } from 'react-router-dom'

interface AssetDetailsHook {
  asset: Asset
  isLoadingAsset: boolean
  assetCompanyID: number
  assetID: string
  assetHasHistory: boolean
  handleUpdateAsset: (payload: AllAssetDetailFields) => Promise<void>
  handleUpdateAssetWithRecalc: (payload) => Promise<void>
  changeStatusAndSave: (payload: AllAssetDetailFields) => Promise<void>
  recalcFormState: RecalcFormState
  closeRecalcForm: () => void
  isDirty: boolean
  methods: UseFormReturn<AllAssetDetailFields>
  handleSubmit: UseFormHandleSubmit<AllAssetDetailFields>
  dirtyFields: object
  discardChanges: () => void
  isAssetBookInfoFormActive: boolean
  setIsAssetBookInfoFormActive: Dispatch<SetStateAction<boolean>>
  isMainDetailsFormActive: boolean
  setIsMainDetailsFormActive: Dispatch<SetStateAction<boolean>>
  isGLKeysFormActive: boolean
  setIsGLKeysFormActive: Dispatch<SetStateAction<boolean>>
  isPreferencesFormActive: boolean
  setIsPreferencesFormActive: Dispatch<SetStateAction<boolean>>
  isDiscardModalOpen: boolean
  setIsDiscardModalOpen: Dispatch<SetStateAction<boolean>>
  isDisposalFormModalOpen: boolean
  setIsDisposalFormModalOpen: Dispatch<SetStateAction<boolean>>
  isReinstatementModalOpen: boolean
  setIsReinstatementModalOpen: Dispatch<SetStateAction<boolean>>
  isChangeAssetStatusModalOpen: boolean
  setIsChangeAssetStatusModalOpen: Dispatch<SetStateAction<boolean>>
  isConfirmDeleteModalOpen: boolean
  setIsConfirmDeleteModalOpen: Dispatch<SetStateAction<boolean>>
  isLoadingSave: boolean
  isLoadingAssetDisposal: boolean
  isLoadingAssetReinstatement: boolean
  handleDeleteAsset: () => Promise<void>
  handleDuplicateAsset: (newAssetID: string, onDuplicateFinish: () => void) => Promise<void>
  isLoadingDeleteAsset: boolean
  isLoadingDuplicateAsset: boolean
  isFormValid: boolean
  isAssetDisposedOrPendingDisposal: boolean
}

interface RecalcFormState {
  open: boolean
  intitialValues:
  | {
    asset: AllAssetDetailFields
    assetRecalcDTO: AssetRecalcDTO
  }
  | undefined
}

export default function useAssetDetails(): AssetDetailsHook {
  const params = useParams()
  const navigate = useNavigate()
  const assetCompanyID = parseInt(params.companyID)
  const assetID = params.assetID
  const {
    data: maintainedDepProfiles,
    isLoading: isLoadingMaintainedDepProfiles,
  } = useMaintainedDepProfilesQuery(assetCompanyID)
  const schema = assetDetailsSchema(assetCompanyID, maintainedDepProfiles)

  // Declare form hook
  const methods = useForm<AllAssetDetailFields>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: schema.cast({}),
  })

  const {
    reset,
    formState: { dirtyFields, isDirty, isValid: isFormValid },
    watch,
    handleSubmit,
  } = methods

  // Declare states
  const [recalcFormState, setRecalcFormState] = useState<RecalcFormState>({
    open: false,
    intitialValues: undefined,
  })

  const [isAssetBookInfoFormActive, setIsAssetBookInfoFormActive] = useState<boolean>(false)
  const [isMainDetailsFormActive, setIsMainDetailsFormActive] = useState<boolean>(false)
  const [isGLKeysFormActive, setIsGLKeysFormActive] = useState<boolean>(false)
  const [isPreferencesFormActive, setIsPreferencesFormActive] = useState<boolean>(false)
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false)
  const [isDisposalFormModalOpen, setIsDisposalFormModalOpen] = useState<boolean>(false)
  const [isReinstatementModalOpen, setIsReinstatementModalOpen] = useState<boolean>(false)
  const [isChangeAssetStatusModalOpen, setIsChangeAssetStatusModalOpen] = useState<boolean>(
    false
  )
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<boolean>(false)

  function closeAllForms(): void {
    setIsAssetBookInfoFormActive(false)
    setIsMainDetailsFormActive(false)
    setIsPreferencesFormActive(false)
    setIsDiscardModalOpen(false)
    setIsDisposalFormModalOpen(false)
    setIsReinstatementModalOpen(false)
    setIsChangeAssetStatusModalOpen(false)
    setIsConfirmDeleteModalOpen(false)
    setIsGLKeysFormActive(false)

    setRecalcFormState({
      open: false,
      intitialValues: undefined,
    })
  }

  function closeRecalcForm(): void {
    setRecalcFormState(prevState => ({
      ...prevState,
      open: false,
    }))
  }

  function discardChanges(): void {
    reset({ ...asset, tags })
    closeAllForms()
  }

  // Get asset and tags
  const {
    data: asset,
    isLoading: isLoadingGetAsset,
    isSuccess: isSuccessGettingAsset,
  } = useGetAssetQuery({
    assetCompanyID,
    assetID,
  })

  const {
    data: tags,
    isLoading: isLoadingTags,
    isSuccess: isSuccessGettingTags,
  } = useFetchAssetFoldersQuery({
    assetCompanyID,
    assetID,
  })
  // Reset form state when asset loads
  useEffect(() => {
    if (isSuccessGettingAsset && isSuccessGettingTags) {
      reset({
        ...asset,
        tags,
      })
    }
  }, [asset, tags, isSuccessGettingAsset, isSuccessGettingTags, reset])

  const isLoadingAsset: boolean =
    isLoadingGetAsset || isLoadingTags || isLoadingMaintainedDepProfiles

  // Check if asset has history
  const { data: assetHasHistory } = useAssetHasHistoryQuery({
    assetCompanyID,
    assetID,
  })

  // Handle update asset (and save tags)
  const [
    assetNeedsRecalc,
    { isLoading: isLoadingAssetNeedsRecalc },
  ] = useAssetNeedsRecalcMutation()

  const [updateAsset, { isLoading: isLoadingUpdateAsset }] = useUpdateAssetMutation()

  const [
    saveFolderList,
    { isLoading: isLoadingSaveFolderList },
  ] = useSaveFolderListMutation()

  async function saveTags(payload: AllAssetDetailFields) {
    try {
      await saveFolderList({
        assetCompanyID,
        assetID,
        keys: payload.tags,
      })
    } catch (error) {
      toast.error(ERROR_REQUEST)
    }
  }

  async function handleUpdateAsset(payload: AllAssetDetailFields): Promise<void> {
    try {
      // Check if needs recalc
      const assetRecalcDTO = await assetNeedsRecalc(payload).unwrap()

      if (assetRecalcDTO.ShouldRecalc) {
        setRecalcFormState({
          open: true,
          intitialValues: {
            asset: payload,
            assetRecalcDTO,
          },
        })
      } else {
        // Update asset if not recalc
        await saveTags(payload)

        const updatedAsset = await updateAsset({
          asset: payload,
          assetRecalcDTO: null,
        }).unwrap()

        reset({ ...updatedAsset, tags: watch('tags') })

        toast.success(`Asset ${asset.AssetID} successfully updated.`)

        closeAllForms()
      }
    } catch (error) {
      toast.error(ERROR_SAVE)

      closeAllForms()
    }
  }

  // This function is used on the confirm recalculation modal
  async function handleUpdateAssetWithRecalc(
    assetPayload: EditAssetPayload
  ): Promise<void> {
    try {
      await saveTags(assetPayload.asset as any)

      const updatedAsset = await updateAsset(assetPayload).unwrap()

      reset({ ...updatedAsset, tags: watch('tags') })
      closeAllForms()

      toast.success(`Asset ${asset.AssetID} successfully updated.`)
    } catch (error) {
      toast.error(ERROR_SAVE)
    }
  }

  // Redux hooks to get state of disposal and reinstatement mutations
  const [
    ,
    {
      isLoading: isLoadingAssetDisposal,
      isSuccess: isSuccessAssetDisposal,
      reset: resetAssetDisposalMutation,
    },
  ] = useAssetDisposalMutation({ fixedCacheKey: 'runAssetDisposal' })

  const [
    ,
    {
      isLoading: isLoadingAssetReinstatement,
      isSuccess: isSuccessAssetReinstatement,
      reset: resetAssetReinstatement,
    },
  ] = useReinstateAssetMutation({ fixedCacheKey: 'reinstateAsset' })

  // Function to run if asset disposal runs successfully
  useEffect(() => {
    if (isSuccessAssetDisposal) {
      toast.success(DISPOSE_SUCCESS)
      closeAllForms()
      resetAssetDisposalMutation()
    }
  }, [isSuccessAssetDisposal, resetAssetDisposalMutation])

  // Function to run if asset reinstatement runs successfully
  useEffect(() => {
    if (isSuccessAssetReinstatement) {
      toast.success(REINSTATE_SUCCESS)
      closeAllForms()
      resetAssetReinstatement()
    }
  }, [isSuccessAssetReinstatement, resetAssetReinstatement])

  // Active/deactive asset
  async function changeStatusAndSave(payload: AllAssetDetailFields): Promise<void> {
    const currentAssetStatus = asset.AS_DIS_CD === 'A' ? 'N' : 'A'

    try {
      // Run update asset function but switch the asset status
      await handleUpdateAsset({
        ...payload,
        AS_DIS_CD: currentAssetStatus
      })
    } catch (error) {
      toast.error(ERROR_SAVE)
    }
  }

  // Delete asset
  const [deleteAsset, { isLoading: isLoadingDeleteAsset }] = useDeleteAssetMutation()

  async function handleDeleteAsset(): Promise<void> {
    try {
      await deleteAsset({
        assetCompanyID,
        assetID,
      })

      toast.success(DELETE_SUCCESS)

      navigate(`/assets?assetCompanyID=${assetCompanyID}`)
    } catch (error) {
      toast.error(ERROR_REQUEST)
    }
  }

  const [duplicateAsset, { isLoading: isLoadingDuplicateAsset }] = useDuplicateAssetMutation()

  async function handleDuplicateAsset(newAssetID: string, onDuplicateFinish: () => void): Promise<void> {
    try {
      await duplicateAsset({
        assetCompanyID,
        baseAssetID: assetID,
        newAssetID
      }).unwrap()

      toast.success(DUPLICATE_SUCCESS)
      onDuplicateFinish()
      navigate(`/assets/${assetCompanyID}/${newAssetID}/details`)
    } catch (error: any) {
      toast.error(error.data ?? ERROR_REQUEST)
    }
  }

  const isAssetDisposedOrPendingDisposal: boolean =
    asset.AS_DIS_CD === 'D' || asset.AS_DIS_CD === 'P'

  const isLoadingSave: boolean =
    isLoadingUpdateAsset || isLoadingAssetNeedsRecalc || isLoadingSaveFolderList

  return {
    asset,
    isLoadingAsset,
    assetCompanyID,
    assetID,
    assetHasHistory,
    handleUpdateAsset,
    handleUpdateAssetWithRecalc,
    changeStatusAndSave,
    recalcFormState,
    closeRecalcForm,
    isDirty,
    methods,
    handleSubmit,
    dirtyFields,
    discardChanges,
    isAssetBookInfoFormActive,
    setIsAssetBookInfoFormActive,
    isMainDetailsFormActive,
    setIsMainDetailsFormActive,
    isGLKeysFormActive,
    setIsGLKeysFormActive,
    isPreferencesFormActive,
    setIsPreferencesFormActive,
    isDiscardModalOpen,
    setIsDiscardModalOpen,
    isDisposalFormModalOpen,
    setIsDisposalFormModalOpen,
    setIsReinstatementModalOpen,
    isReinstatementModalOpen,
    isChangeAssetStatusModalOpen,
    setIsChangeAssetStatusModalOpen,
    isConfirmDeleteModalOpen,
    setIsConfirmDeleteModalOpen,
    isLoadingSave,
    isLoadingAssetDisposal,
    isLoadingAssetReinstatement,
    handleDeleteAsset,
    isLoadingDuplicateAsset,
    handleDuplicateAsset,
    isLoadingDeleteAsset,
    isFormValid,
    isAssetDisposedOrPendingDisposal,
  }
}
