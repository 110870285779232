import { StyleObject } from '@styles-api/css.types'

export const depStatusCard: StyleObject = {
  backgroundColor: 'var(--shade100)',
  borderWidth: '1px',
  borderColor: 'var(--default-border-color)',
  borderRadius: 'var(--radius4)',
  paddingLeft: 'var(--scale500)',
  paddingRight: 'var(--scale500)',
  paddingTop: 'var(--scale100)',
  paddingBottom: 'var(--scale100)',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  minWidth: '300px',
  ':hover': {
    cursor: 'default',
  },
}

export const depStatusText = ({ IsRunningDepreciation }: StyleObject) => {
  return {
    color: IsRunningDepreciation ? 'var(--success-color)' : '#0070c9',
    fontSize: 'var(--regular-font-size)',
    fontWeight: 'var(--font-weight-bold)',
    fontFamily: 'var(--font-family)',
  }
}

export const depErrorText: StyleObject = {
  color: 'var(--danger-color)',
  fontSize: 'var(--regular-font-size)',
  fontWeight: 'var(--font-weight-bold)',
  fontFamily: 'var(--font-family)',
}
