import { FetchGLPostsResponse, GLPost, GLPostKey, IGLPostKey } from '@api/models'
import { toast, useModal } from '@components/common'
import {
  useCreateGLPostMutation,
  useDeleteGLPostMutation,
  useEditGLPostMutation,
  useFetchGLPostsQuery,
  useGetGLPostKeysQuery,
} from '@api'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTableParams } from '@hooks'
import { TableParamsHook } from 'hooks/useTableParams'

export interface GLPostsHook extends TableParamsHook {
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  searchKey: string
  selectedGLPost: GLPost
  selectedGLPostKeys

  glPosts: FetchGLPostsResponse
  glPostKeys: IGLPostKey[]
  isLoadingGLPosts: boolean
  isErrorFetchingGLPosts: boolean
  isRefetchingGLPosts: boolean

  handleEditGLPost: (payload: GLPost) => Promise<void>
  handleCreateGLPost: (payload: GLPost) => Promise<void>
  isLoadingEditGLPost: boolean
  isLoadingCreateGLPost: boolean

  handleDeleteGLPost: (payload: GLPost) => Promise<void>
  isLoadingDeleteGLPost: boolean

  deleteGLPostModalIsOpen: boolean
  triggerDeleteGLPostModal
  closeDeleteGLPostModal
  openDeleteGLPostModal: (glPost: GLPost) => void
  openEditGLPostModal: (glPost: GLPost) => void
  triggerEditGLPostModal
  closeEditGLPostModal
  editGLPostModalIsOpen: boolean

  openCreateGLPostModal: () => void
  createGLPostModalIsOpen: boolean
  closeCreateGLPostModal
}

export default function useGLPosts(): GLPostsHook {
  const [filterState, setFilterState] = useState({})
  const [selectedGLPost, setSelectedGLPost] = useState<GLPost>(undefined)
  const [selectedGLPostKeys, setSelectedGLPostKeys] = useState({})
  const [skipGetGLPostsQuery, setSkipGetGLPostsQuery] = useState<boolean>(true)

  const searchKey: GLPostKey = 'GL_POST_ID'
  const initialItemsPerPage = 20

  // Use useTableParams hook for table filtering, pagination, etc.
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  // Fetch GL Posts
  const {
    data: glPosts,
    isLoading: isLoadingGLPosts,
    isError: isErrorFetchingGLPosts,
    isFetching: isRefetchingGLPosts,
  } = useFetchGLPostsQuery(fetchRequestPayload)

  // MODAL STATES
  // Delete modal
  const {
    modalIsOpen: deleteGLPostModalIsOpen,
    triggerModal: triggerDeleteGLPostModal,
    closeModal: closeDeleteGLPostModal,
  } = useModal()
  function openDeleteGLPostModal(glPost: GLPost): void {
    setSelectedGLPost(glPost)
    triggerDeleteGLPostModal()
  }

  // Edit modal
  const {
    modalIsOpen: editGLPostModalIsOpen,
    triggerModal: triggerEditGLPostModal,
    closeModal: closeEditGLPostModal,
  } = useModal()

  const { data: glPostKeys, isLoading: isLoadingGLPostKeys } = useGetGLPostKeysQuery(
    { id: selectedGLPost?.GL_POST_ID },
    { skip: skipGetGLPostsQuery }
  )

  function openEditGLPostModal(glPost: GLPost): void {
    setSelectedGLPost(glPost)
    setSkipGetGLPostsQuery(false)
    triggerEditGLPostModal()
  }
  useEffect(() => {
    if (glPostKeys) {
      setSelectedGLPostKeys(glPostKeys)
    }
  }, [isLoadingGLPostKeys])

  // Create modal
  const {
    modalIsOpen: createGLPostModalIsOpen,
    triggerModal: triggerCreateGLPostModal,
    closeModal: closeCreateGLPostModal,
  } = useModal()
  function openCreateGLPostModal(): void {
    setSelectedGLPost({
      GLICOCD: 'T',
      GLIDOCD: 'T',
    } as GLPost)
    triggerCreateGLPostModal()
  }

  // MUTATIONS
  // Delete
  const [deleteGLPost, { isLoading: isLoadingDeleteGLPost }] = useDeleteGLPostMutation()
  async function handleDeleteGLPost(payload: GLPost): Promise<void> {
    try {
      await deleteGLPost(payload).unwrap()
      closeDeleteGLPostModal()
      toast.success(`Successfully deleted "${payload.GL_DESC}" from your GL codes.`)
    } catch (error) {
      toast.error('There was an unexpected error.')
    }
  }

  // Edit
  const [editGLPost, { isLoading: isLoadingEditGLPost }] = useEditGLPostMutation()
  async function handleEditGLPost(payload: GLPost): Promise<void> {
    try {
      await editGLPost(payload).unwrap()
      closeEditGLPostModal()
      toast.success(`GL code ${payload.GL_POST_ID} successfully updated.`)
    } catch (error) {
      toast.error('There was an unexpected error updating this GL code.')
    }
  }

  // Create
  const [createGLPost, { isLoading: isLoadingCreateGLPost }] = useCreateGLPostMutation()
  async function handleCreateGLPost(payload: GLPost): Promise<void> {
    try {
      await createGLPost(payload).unwrap()

      closeCreateGLPostModal()
      toast.success(
        `Successfully added ${payload.GL_POST_ID} (${payload.GL_DESC}) to GL codes.`
      )
    } catch (error) {
      toast.error('There was an unexpected error creating the GL code.')
    }
  }

  return {
    // tableParams return
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    //
    filterState,
    setFilterState,
    searchKey,
    selectedGLPost,
    selectedGLPostKeys,

    glPosts,
    isLoadingGLPosts,
    isErrorFetchingGLPosts,
    isRefetchingGLPosts,
    glPostKeys,

    handleDeleteGLPost,
    isLoadingDeleteGLPost,

    handleEditGLPost,
    handleCreateGLPost,
    isLoadingCreateGLPost,
    isLoadingEditGLPost,

    deleteGLPostModalIsOpen,
    triggerDeleteGLPostModal,
    closeDeleteGLPostModal,
    openDeleteGLPostModal,
    openEditGLPostModal,
    triggerEditGLPostModal,
    closeEditGLPostModal,
    editGLPostModalIsOpen,

    createGLPostModalIsOpen,
    openCreateGLPostModal,
    closeCreateGLPostModal,
  }
}
