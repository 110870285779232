import { DepreciationProfileCode } from '@api/models'
import usePermission from '@components/Auth/Permissions/usePermission'
import { Button, Flexbox, Heading, Spacer } from '@components/common'
import DepreciationClassesTable from '@components/DepreciationClassesTable'
import ImportData from '@components/ImportData'
import View from '@components/View'
import { download } from '@utils/basic'

interface Props {
  profile: DepreciationProfileCode
}

const DepreciationClassesView = ({ profile }: Props) => {
  const { canEditSettings: canEdit } = usePermission()

  const importButton = (
    <Button
      onClick={() => download(`${process.env.PUBLIC_URL}/templates/categories.csv`, 'categories.csv')}
      appearance="text"
    >
      Download an import template here.
    </Button>
  )

  return (
    <View title={`${profile === 'A' ? 'Categories' : 'Tax Classes'}`}>
      <Flexbox justifyContent="space-between">
        <div>
          <Heading type="h1">{profile === 'A' ? 'Categories' : 'Tax Classes'}</Heading>
        </div>

        <Flexbox alignItems='center' flexDirection='row' gap={12}>
          <ImportData action='categories' title='Import Categories' modalDesc={importButton} />
          <Button
            link={`/${profile === 'A' ? 'categories' : 'tax-classes'}/create`}
            appearance="primary"
            hidden={!canEdit}
          >
            Add new {profile === 'A' ? 'category' : 'tax class'}
          </Button>
        </Flexbox>
      </Flexbox>

      <Spacer y="default" />

      <DepreciationClassesTable profile={profile} />
    </View>
  )
}
export default DepreciationClassesView
