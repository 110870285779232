import { maxStringMsg } from '@utils/basic'
import { REQUIRED_FIELD } from '@constants'
import * as Yup from 'yup'

// TODO: Class Codes and Category IDs
export const vendorSchema = Yup.object({
  VN_VEN_ID: Yup.string().max(12, maxStringMsg(12)).required(REQUIRED_FIELD),
  VN_NAME: Yup.string().max(50, maxStringMsg(50)).required(REQUIRED_FIELD),
  VN_STREET: Yup.string().max(50, maxStringMsg(25)).nullable(),
  VN_CITY: Yup.string().max(25, maxStringMsg(25)).nullable(),
  VN_CNTY: Yup.string().max(25, maxStringMsg(25)).nullable(),
  VN_STATE: Yup.string().max(2, maxStringMsg(2)).nullable(),
  VN_ZIP: Yup.string().max(10, maxStringMsg(10)).nullable(),
  VN_PHONE: Yup.string().max(14, maxStringMsg(14)).nullable(),
  VN_FAX: Yup.string().max(14, maxStringMsg(14)).nullable(),
})

export type Vendor = Yup.InferType<typeof vendorSchema>
export type VendorKey = keyof Vendor

export const initialVendor: Vendor = {
  VN_VEN_ID: '',
  VN_NAME: '',
  VN_STREET: '',
  VN_CITY: '',
  VN_CNTY: '',
  VN_STATE: '',
  VN_ZIP: '',
  VN_PHONE: '',
  VN_FAX: '',
}

// Request payloads and responses
export type ListVendorsResponse = {
  ID: string
  Name: string
}[]

export interface FetchVendorsResponse {
  count: number
  list: Vendor[]
}
