import React from 'react'
import { useStyletron } from 'styletron-react'

import * as styles from '../Styles'

export interface TableBodyProps extends React.HTMLProps<HTMLTableSectionElement> {
  height?: string
}

const Body: React.FC<TableBodyProps> = ({ height, ...props }) => {
  const [css] = useStyletron()
  let bodyStyles = css({
    ...styles.getHeight({ height }),
  })

  return (
    <div {...props} className={bodyStyles}>
      {props.children}
    </div>
  )
}

export default Body
