import { Select } from '@components/common'
import { useGetCompaniesQuery } from '@api/companies/companiesEndpointsHooks'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

// import { setAssetCompany } from '@api/companies/companiesSlice'
import { setAssetCompany } from '@store/actions/company'
import { useMatch } from 'react-router-dom'

const AssetCompanySelect = () => {
  const { isLoading, data } = useGetCompaniesQuery()
  const assetCompanyID = useSelector<any>(state => state.company)
  const dispatch = useDispatch()
  const match = useMatch('/assets/:companyID/:assetID/*')
  const isAssetDetailsPage = !!match

  useEffect(() => {
    if (!isLoading && data && data.length > 0) {
      dispatch(setAssetCompany(data[0].AssetCompanyID))
    }
  }, [isLoading, data, dispatch])

  const options =
    data?.map(c => ({
      label: `${c.AssetCompanyID} (${c.CM_DESC})`,
      value: c.AssetCompanyID,
    })) ?? []

  function handleCompanySelect(option) {
    dispatch(setAssetCompany(option.value))

    // also reset the asset table params when switching companies 
    localStorage.setItem('pageNumber', '1')
    localStorage.setItem('itemsPerPage', '20')
  }

  if (isLoading || options.length === 0) {
    return <></>
  }

  return (
    <Select
      value={options.find(c => c.value === assetCompanyID)}
      options={options}
      onChange={handleCompanySelect}
      disabled={isAssetDetailsPage}
    />
  )
}

export default AssetCompanySelect
