import { useListVendorsQuery } from '@api'
import { Button, Flexbox, Radio, Spacer, Text } from '@components/common'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterLoading from './FilterLoading'
import { AssetsTableFilterField } from './Filters.types'

interface Props {
  filter: AssetsTableFilterField
}

const VendorsFilter = ({ filter }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])

  const [value, setValue] = useState<string | undefined>(
    searchParams.get(filter.column) ?? undefined
  )

  const inputChangeTimeoutDuration: number = 400

  const {
    data: vendors,
    isLoading: isLoadingVendors,
    isError: isErrorGettingVendors,
  } = useListVendorsQuery()

  function onChange(e: any) {
    setValue(e.target.value)
    setTimeout(() => {
      if (e.target.value === '') {
        searchParams.delete(e.target.name)
        setSearchParams(searchParams)
      } else {
        setSearchParams({
          ...searchParamsObject,
          [e.target.name]: (e.target.value = typeof Number
            ? e.target.value.toString()
            : e.target.value),
          pageNumber: '1',
        })
      }
    }, inputChangeTimeoutDuration)
  }

  function clearSelection() {
    searchParams.delete(filter.column)
    setSearchParams(searchParams)
    setValue(undefined)
  }

  if (isErrorGettingVendors) {
    return <Text intent="danger">Error getting vendors.</Text>
  }

  if (isLoadingVendors) {
    return <FilterLoading />
  }

  if (vendors.length === 0) {
    return <Text>You haven't added any depreciation profiles yet.</Text>
  }

  return (
    <div>
      <Spacer y="mini" />
      <Radio name={filter.column} onChange={onChange} select={value} legend="">
        {vendors.map((vendor, index: number) => (
          <Radio.Option
            value={vendor.ID}
            label={`${vendor.ID} (${vendor.Name})`}
            autoFocus={index === 0}
            key={vendor.ID}
          />
        ))}
      </Radio>
      <Spacer y="mini" />
      <Flexbox justifyContent="flex-end">
        <Text small>
          <Button
            onClick={() => clearSelection()}
            appearance="text"
            disabled={value === '' || value === undefined}
          >
            Clear
          </Button>
        </Text>
      </Flexbox>
    </div>
  )
}
export default VendorsFilter
