import { ImportColumn } from "../importTypes";

export const maintenance: ImportColumn[] = [
    {
        label: 'Contract ID',
        value: 'MN_CON_ID'
    },
    {
        label: 'Contract Description',
        value: 'MN_DESC'
    },
    {
        label: 'Company Name',
        value: 'MN_NAME'
    },
    {
        label: 'Company Address',
        value: 'MN_STREET'
    },
    {
        label: 'Company City',
        value: 'MN_CITY'
    },
    {
        label: 'Company Country',
        value: 'MN_CNTY'
    },
    {
        label: 'Company State',
        value: 'MN_STATE'
    },
    {
        label: 'Company ZIP',
        value: 'MN_ZIP'
    },
    {
        label: 'Company Phone',
        value: 'MN_PHONE'
    },
    {
        label: 'Company Fax',
        value: 'MN_FAX'
    },
    {
        label: 'Contact Name',
        value: 'MN_CON_NM'
    },
    {
        label: 'Contact Phone',
        value: 'MN_CON_PN'
    },
    {
        label: 'Contact Fax',
        value: 'MN_CON_FX'
    },
    {
        label: 'Contract Amount',
        value: 'MN_CON_AMT'
    },
    {
        label: 'Payment Terms',
        value: 'MN_PMT_CD'
    },
    {
        label: 'Start of Coverage',
        value: 'MN_CP_FRM'
    },
    {
        label: 'End of Coverage',
        value: 'MN_CP_TO'
    },
    {
        label: 'Labour Included',
        value: 'MN_LB_CD'
    },
    {
        label: 'Labour Start Date',
        value: 'MN_LB_FRM'
    },
    {
        label: 'Labour End Date',
        value: 'MN_LB_TO'
    },
    {
        label: 'Parts Included',
        value: 'MN_PT_CD'
    },
    {
        label: 'Parts Start Date',
        value: 'MN_PT_FRM'
    },
    {
        label: 'Parts End Date',
        value: 'MN_PT_TO'
    },
    {
        label: 'Other Included',
        value: 'MN_OT_CD'
    },
    {
        label: 'Other Start Date',
        value: 'MN_OT_FRM'
    },
    {
        label: 'Other End Date',
        value: 'MN_OT_TO'
    }
]