import { Form, Grid, Input, Fieldset, Note } from '@common'
import { VENDOR_FORM_ID } from '@constants'
import { useVendorForm, VendorFormProps } from './useVendorForm'

const VendorForm = ({
  isEdit,
  initialValues,
  handleSubmit,
  canEdit = true,
}: VendorFormProps) => {
  const { getField, handleFormSubmit } = useVendorForm({
    initialValues,
    handleSubmit,
    canEdit,
  })

  return (
    <Form id={VENDOR_FORM_ID} onSubmit={handleFormSubmit} noValidate>
      <Grid>
        {!isEdit && (
          <>
            <Grid.Item>
              <Input {...getField('VN_VEN_ID')} readOnly={isEdit} autoFocus={!isEdit} />
            </Grid.Item>
          </>
        )}

        <Grid.Item>
          <Input {...getField('VN_NAME')} autoFocus={isEdit} />
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="Vendor Information">
            <Grid>
              <Grid.Item>
                <Input {...getField('VN_STREET')} />
              </Grid.Item>
              <Grid.Item s={9}>
                <Input {...getField('VN_CITY')} />
              </Grid.Item>
              <Grid.Item s={3}>
                <Input {...getField('VN_STATE')} helper="2 character code" />
              </Grid.Item>
              <Grid.Item>
                <Input {...getField('VN_CNTY')} />
              </Grid.Item>
              <Grid.Item>
                <Input {...getField('VN_ZIP')} />
              </Grid.Item>
              <Grid.Item>
                <Input {...getField('VN_PHONE')} />
              </Grid.Item>
              <Grid.Item>
                <Input {...getField('VN_FAX')} />
              </Grid.Item>
            </Grid>
          </Fieldset>
        </Grid.Item>
        {!isEdit && (
          <Grid.Item>
            <Note intent="warning" content={'ID cannot be changed once vendor is added.'} />
          </Grid.Item>
        )}
      </Grid>
    </Form>
  )
}
export default VendorForm
