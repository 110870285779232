import HttpClient from "./HttpClient";

export class VouchApi extends HttpClient {
    private static classInstance?: VouchApi;

    public constructor () {
        super (`/Vouch`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new VouchApi();
        }

        return this.classInstance;
    }

    public index = (pageNumber: number) => this.instance.get(`/Index?pageNumber=${pageNumber}`);
    public details = (assetCompanyId: number, assetId: string, jvSysNr1: number, jvSysNr2: number, jvSysNr3: number) =>
        this.instance.get(`/Details?assetCompanyId=${assetCompanyId}&assetId=${assetId}&JV_SYS_NR1=${jvSysNr1}&JV_SYS_NR2=${jvSysNr2}&JV_SYS_NR3=${jvSysNr3}`);
    public purge = (companyId: number, reversed: boolean, from: string, to: string) =>
        this.instance.post(`/PurgeVouchers?companyId=${companyId}&reversed=${reversed}&from=${from}&to=${to}`);
    public import = (file: any) => this.instance.get(`/ImportVouch`, file);
    public journal = (fromDate: string, toDate: string, profile: string) => this.instance.get(`/VouchJournal?fromDate=${fromDate}&toDate=${toDate}&profile=${profile}`);
    public glPostReport = (companyId: number, sort: string, filter: string, fromDate: string, toDate: string, profile: string, preview: boolean, showDisposals: boolean) =>
        this.instance.get(`/GLPostReport?companyId=${companyId}&sort=${sort}&filter=${filter}&fromDate=${fromDate}&toDate=${toDate}&profile=${profile}&preview=${preview}&showDisposals=${showDisposals}`);
    public exportGLPostReport = (companyId: number, filter: string, fromDate: string, toDate: string, profile: string, preview: boolean, showDisposals: boolean) =>
        this.instance.get(`/ExportGLPostReport?companyId=${companyId}&filter=${filter}&fromDate=${fromDate}&toDate=${toDate}&profile=${profile}&preview=${preview}&showDisposals=${showDisposals}`);
}