import { apiSlice } from '@api'
import {
  FetchGLPostsResponse,
  GLPost,
  GetGLPostKeysPayload,
  IGLPostKey,
  ListGLPostsResponse,
} from './models'
import { StandardFetchRequestPayload } from '@api/models'

export const glPostEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    // GL POSTS
    listGLPosts: build.query<ListGLPostsResponse, void>({
      query: () => `GLPost/ListGLPosts`,
      providesTags: ['glPost'],
    }),
    fetchGLPosts: build.query<FetchGLPostsResponse, StandardFetchRequestPayload>({
      query: ({ pageNumber, itemsPerPage, filter }) =>
        `GLPost/FetchGLPosts?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      providesTags: ['glPost'],
    }),
    getGLPostKeys: build.query<IGLPostKey[], GetGLPostKeysPayload>({
      query: ({ id }) => `GLPost/GetKeys?id=${id}`,
    }),
    createGLPost: build.mutation<void, GLPost>({
      query: ({ ...glPost }) => ({
        url: 'GLPost/Create',
        method: 'POST',
        body: { ...glPost },
      }),
      invalidatesTags: ['glPost'],
    }),
    deleteGLPost: build.mutation<void, GLPost>({
      query: ({ ...glPost }) => ({
        url: 'GLPost/Delete',
        method: 'POST',
        body: { ...glPost },
      }),
      invalidatesTags: ['glPost'],
    }),
    editGLPost: build.mutation<void, GLPost>({
      query: ({ ...glPost }) => ({
        url: 'GLPost/Edit',
        method: 'PUT',
        body: { ...glPost },
      }),
      invalidatesTags: ['glPost'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useListGLPostsQuery,
  useFetchGLPostsQuery,
  useGetGLPostKeysQuery,
  useCreateGLPostMutation,
  useDeleteGLPostMutation,
  useEditGLPostMutation,
} = glPostEndpoints
