import React from 'react'
import { useStyletron } from 'styletron-react'
import * as styles from '../Styles'

export interface TableHeadRowProps extends React.HTMLProps<HTMLTableRowElement> {}

const HeadRow: React.FC<TableHeadRowProps> = ({ ...props }) => {
  const [css] = useStyletron()
  return (
    <div role="row" className={css(styles.headRow)} {...props}>
      {props.children}
    </div>
  )
}

export default HeadRow
