import React from 'react'

import { useStyletron } from 'styletron-react'
import { Heading, Icon } from '../'
import * as styles from './Styles'

const SideSheetHeader = ({ title, close }) => {
  const [css] = useStyletron()

  let headerStyles = css(styles.header)
  return (
    <div className={headerStyles}>
      <Heading type="h1" baseSize bold>
        {title}
      </Heading>
      <button onClick={close} className={css(styles.closeButton)}>
        <Icon name="X" className={css(styles.closeIcon)} />
      </button>
    </div>
  )
}
export default SideSheetHeader
