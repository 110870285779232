import React from 'react'
import { useStyletron } from 'styletron-react'
import * as styles from '../Styles'

import { Spinner, Spacer } from '@common'

const LoadingRows = () => {
  const [css] = useStyletron()
  return (
    <div className={css(styles.loadingRows)}>
      <Spinner theme="light" />
      <Spacer x="mini" />
      Loading rows...
    </div>
  )
}
export default LoadingRows
