import { apiSlice } from '@api'
import { StandardFetchRequestPayload } from '@api/models'
import { ListInsurancesResponse, FetchInsurancesResponse, InsurancePolicy } from './models'

export const insuranceEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    listInsurances: build.query<ListInsurancesResponse, void>({
      query: () => `Insurance/ListInsurances`,
    }),
    fetchInsurances: build.query<FetchInsurancesResponse, StandardFetchRequestPayload>({
      query: ({ pageNumber, itemsPerPage, filter }) =>
        `Insurance/FetchInsurances?pageNumber=${pageNumber}&itemsPerPage${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      transformResponse: (response: FetchInsurancesResponse, _meta, _arg) => ({
        count: response.count,
        list: response.list.map(ins => ins),
      }),
      providesTags: ['insurance'],
    }),
    createInsurance: build.mutation<void, InsurancePolicy>({
      query: ({ ...insurance }) => ({
        url: 'Insurance/Create',
        method: 'POST',
        body: { ...insurance },
      }),
      invalidatesTags: ['insurance'],
    }),
    editInsurance: build.mutation<void, InsurancePolicy>({
      query: ({ ...insurance }) => ({
        url: 'Insurance/Edit',
        method: 'PUT',
        body: { ...insurance },
      }),
      invalidatesTags: ['insurance'],
    }),
    deleteInsurance: build.mutation<void, InsurancePolicy>({
      query: ({ ...insurance }) => ({
        url: 'Insurance/Delete',
        method: 'POST',
        body: { ...insurance },
      }),
      invalidatesTags: ['insurance'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useListInsurancesQuery,
  useFetchInsurancesQuery,
  useCreateInsuranceMutation,
  useEditInsuranceMutation,
  useDeleteInsuranceMutation,
} = insuranceEndpoints
