import {
  AssetMainView,
  AssetDepreciationProfilesView,
  AssetStructureView,
  AssetHistoryView,
  AssetLocationView,
  AssetLocationDetailsView,
  AssetServiceView,
  AssetTasksView,
  AssetPartialDisposalsView,
  AssetAttachmentView,
  AssetDisposalView,
  AssetChargeView,
  AssetLocationWrapperView,
} from '@views'
import { ASSET_PATHS } from '@constants'
import AssetDeprecClassFormWrapper from '@components/asset/AssetDepreciationProfiles/AssetDeprecClassFormWrapper'
import { Navigate } from 'react-router-dom'
import AssetCustomDeprecWrapper from '@components/asset/AssetDepreciationProfiles/AssetCustomDeprecWrapper'

const assetRoutes = [
  {
    key: 'Asset Main',
    path: `${ASSET_PATHS.DETAILS}`,
    children: [
      {
        index: true,
        element: <AssetMainView />,
      },
      {
        path: `${ASSET_PATHS.DISPOSAL}`,
        element: <AssetDisposalView />,
      },
    ],
  },
  {
    path: ASSET_PATHS.DEPRECIATION,
    element: <Navigate to="A" replace={true} />,
  },
  {
    key: 'Asset Depreciation',
    path: `${ASSET_PATHS.DEPRECIATION}/:profile`,
    element: <AssetDepreciationProfilesView />,
    children: [
      {
        index: true,
        element: <AssetDeprecClassFormWrapper />,
      },
      {
        path: ASSET_PATHS.CUSTOM_DEPRECIATION,
        element: <AssetCustomDeprecWrapper />,
      },
    ],
  },
  {
    key: 'Asset History',
    path: `${ASSET_PATHS.HISTORY}`,
    element: <AssetHistoryView />,
  },
  {
    key: 'Asset Structure',
    path: `${ASSET_PATHS.STRUCTURE}`,
    element: <AssetStructureView />,
  },
  {
    key: 'Asset Location',
    path: `${ASSET_PATHS.LOCATION}`,
    children: [
      {
        index: true,
        element: <AssetLocationView />,
      },
      {
        path: ':locationID',
        element: <AssetLocationDetailsView />,
      },
      {
        path: 'create',
        element: <AssetLocationWrapperView isCreate={true} />,
      },
      {
        path: ':locationID/edit',
        element: <AssetLocationWrapperView isCreate={false} />,
      },
    ],
  },
  {
    key: 'Asset Service',
    path: `${ASSET_PATHS.SERVICE}`,
    children: [
      {
        index: true,
        element: <AssetServiceView />,
      },
      {
        path: ':systemNumber',
        element: <AssetChargeView chargeTypeCode="S" isCreate={false} />,
      },
      {
        path: 'create',
        element: <AssetChargeView chargeTypeCode="S" isCreate={true} />,
      },
    ],
  },
  {
    key: 'Asset Tasks',
    path: `${ASSET_PATHS.TASKS}`,
    children: [
      {
        index: true,
        element: <AssetTasksView />,
      },
      {
        path: ':systemNumber',
        element: <AssetChargeView chargeTypeCode="O" isCreate={false} />,
      },
      {
        path: 'create',
        element: <AssetChargeView chargeTypeCode="O" isCreate={true} />,
      },
    ],
  },
  {
    key: 'Asset Partial Disposals',
    path: `${ASSET_PATHS.PARTIAL_DISPOSALS}`,
    children: [
      {
        index: true,
        element: <AssetPartialDisposalsView />,
      },
      {
        path: ':systemNumber',
        element: <AssetChargeView chargeTypeCode="P" isCreate={false} />,
      },
      {
        path: 'create',
        element: <AssetChargeView chargeTypeCode="P" isCreate={true} />,
      },
    ],
  },
  {
    key: 'Asset Attachments',
    path: `${ASSET_PATHS.ATTACHMENTS}`,
    element: <AssetAttachmentView />,
  },
]

export default assetRoutes
