import React, { useState } from 'react'
import { TableProps, TableSubComponents } from './Table.types'

import {
  Body,
  Cell,
  Head,
  Header,
  HeadRow,
  LoadingRows,
  NoResults,
  Row,
  TableActions,
} from './elements'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

import Overflow from './detectScroll'

const Table: React.FC<TableProps> & TableSubComponents = ({ ...props }) => {
  const [canScrollRight, setCanScrollRight] = useState(false)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollUp, setCanScrollUp] = useState(false)
  const [canScrollDown, setCanScrollDown] = useState(false)

  const [css] = useStyletron()
  let tableStyles = css({
    ...styles.table,
    ...styles.getHeight({ height: props.height }),
    ...styles.getMinHeight({ minHeight: props.minHeight }),
  })

  let overflowShadowStyles = css({
    ...styles.overflowShadow({
      canScrollDown,
      canScrollLeft,
      canScrollRight,
      canScrollUp,
    }),
  })

  return (
    <div style={{ position: 'relative' }} className="table_component">
      <Overflow
        onStateChange={state => {
          setCanScrollRight(state.canScroll.right)
          setCanScrollLeft(state.canScroll.left)
          setCanScrollUp(state.canScroll.up)
          setCanScrollDown(state.canScroll.down)
        }}
        style={{ maxHeight: 500 }}
        className={tableStyles}
        role="table"
        {...props}
      >
        <div className={overflowShadowStyles} />
        <Overflow.Content
          style={{ position: 'relative' }}
          className="overflow-table_sticky-header"
        >
          {props.children}
        </Overflow.Content>
      </Overflow>
    </div>
  )
}

//table subcomponents
Table.Actions = TableActions

Table.Head = Head
Table.HeadRow = HeadRow
Table.Header = Header

Table.Body = Body
Table.Row = Row
Table.Cell = Cell

Table.LoadingRows = LoadingRows
Table.NoResults = NoResults

export default Table
