import { DepreciationProfileCode } from '@api/models'
import { DEPRECIATION_CLASS_VIEWS } from '@constants'
import { GlobalCustomDepreciationView, DepreciationClassDetailsView } from '@views'
import { Navigate } from 'react-router-dom'

const deprecClassRoutes = (profile: DepreciationProfileCode) => {
  return [
    {
      index: true,
      element: <Navigate to={DEPRECIATION_CLASS_VIEWS.DETAILS} replace={true} />,
    },
    {
      path: DEPRECIATION_CLASS_VIEWS.DETAILS,
      element: <DepreciationClassDetailsView profile={profile} />,
    },
    {
      path: DEPRECIATION_CLASS_VIEWS.CUSTOM_DEPRECIATION,
      element: <GlobalCustomDepreciationView profile={profile} />,
    },
  ]
}

export default deprecClassRoutes
