import { Spinner } from '@components/common'
import { useAuth } from '@hooks'
import { ReactNode } from 'react'

/**
 * Protector component that wraps all routes. This blocks the quick flicker of
 * visible content before the user is redirected to auth.
 */
const AuthProtector = ({ children }: { children?: ReactNode | undefined }): JSX.Element => {
  const { shouldProtectApp, isLoading } = useAuth()

  if (shouldProtectApp && isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </div>
    )
  }

  return <>{children}</>
}

export default AuthProtector
