import AssetsTable from '@components/AssetsTable'
import View from '@components/layout/View'

const AssetsView = () => {
  return (
    <View title="Assets" showHeader={false}>
      <AssetsTable />
    </View>
  )
}

export default AssetsView
