import { apiSlice } from '@api'
import { formatISODate } from '@utils/basic'
import { DepRunStatus } from '../models'

export const DepreciationStatusEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getDepRunStatus: build.query<DepRunStatus, number>({
      query: assetCompanyID => `AssetHistory/DepRunStatus/${assetCompanyID}`,
      transformResponse: (info: DepRunStatus) => ({
        ...info,
        PostDate: formatISODate(info.PostDate),
        AsOfDate: info.AsOfDate === null ? null : formatISODate(info.AsOfDate),
      }),
      providesTags: ['depreciationStatus'],
    }),
  }),
  overrideExisting: false,
})

export const { useGetDepRunStatusQuery } = DepreciationStatusEndpoints
