import { useGetDashboardAssetsQuery } from '@api/dashboard/dashboardEndpoints'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { AcquisitionData, BookValueData } from './DashboardFilter.types'

interface IDashboardFiltersHook {
  // Select and MultiSelect
  getMultiSelectValue: (index: number) => string[]
  handleMultiSelectChange: (list: string[], index: number) => void
  handleSelectAmountChange: (value: { label: string; value: string }, index: number) => void
  getSelectValue: (index: number) => { label: string; value: string }

  // Form methods
  methods: any
  handleDashboardFiltersSubmit: (data: any) => void

  // Assets
  assetCount: number
  filteredAssetsNumber: any
  isFetchingDashboardAssets: boolean
  isSuccessGettingDashboardAssets: boolean

  // Acquisition Data
  acquisitionData: AcquisitionData[]
  totalAcquisition: number

  // Book Value Data
  bookValueData: BookValueData[]
  totalBookValue: number
}

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
  ]
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/
  const item = [...lookup].reverse().find(item => num >= item.value)
  return item ? (num / item.value).toFixed(digits).replace(regexp, '') + item.symbol : '0'
}

export default function useDashboardFilters({
  filterState,
  setFilterState,
}): IDashboardFiltersHook {
  const assetCompanyID = useSelector<any>(state => state.company) as number

  // Fetch filters different from the state filters to avoid re-rendering
  const [fetchFilters, setFetchFilters] = useState(filterState)

  // For the multi select component
  const getMultiSelectValue = (index: number): string[] => {
    if (!filterState[index].List) {
      setFilterState(prevState => {
        const newState = [...prevState]
        newState[index].List = ['All']

        setValueDashboardFilters('filterState.1.List', newState)
        return newState
      })
    }

    setValueDashboardFilters('filterState.1.List', filterState[index].List)
    return filterState[index].List
  }

  const handleMultiSelectChange = (list: string[], index: number): void => {
    setFilterState(prevState => {
      const newState = prevState.map((item, i) => {
        if (i === index) {
          if (list.includes('All') && list.length === 1) {
            return { ...item, List: ['All'] }
          } else {
            return { ...item, List: list.filter(item => item !== 'All') }
          }
        }
        return item
      })

      setValueDashboardFilters('filterState.1.List', newState)

      return newState
    })
  }

  // For the select component
  const getSelectValue = (index: number): { label: string; value: string } => {
    // get the value, and format it to be used in the select component
    const value = filterState[index].amount ? filterState[index].amount : '4'
    const label = filterState[index].label ? filterState[index].label : 'Last 4 Quarters'

    setValueDashboardFilters('filterState.0.Amount', value)
    setValueDashboardFilters('filterState.0.Label', label)

    return { label, value }
  }

  const handleSelectAmountChange = (
    value: { label: string; value: string },
    index: number
  ): void => {
    setFilterState(prevState => {
      const newState = prevState.map((item, i) => {
        if (i === index) {
          return { ...item, amount: value.value, label: value.label }
        }
        return item
      })

      setValueDashboardFilters('filterState.0.Amount', newState)

      return newState
    })
  }

  // Form methods
  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      filterState,
    },
  })

  // Set the form values
  const { setValue: setValueDashboardFilters } = methods

  // Check the state of the filters
  const isAllCategories = filterState[1].List.includes('All')
  const isOneCategory = filterState[1].List.length === 1 && !isAllCategories
  const isMultipleCategories = !isOneCategory && !isAllCategories

  // Get the data from the API
  const {
    data: dashboardAssets,
    isFetching: isFetchingDashboardAssets,
    isSuccess: isSuccessGettingDashboardAssets,
  } = useGetDashboardAssetsQuery({ assetCompanyID, filter: fetchFilters })

  // Submit the form
  const handleDashboardFiltersSubmit = async (data: any): Promise<void> => {
    try {
      setFetchFilters(data.filterState)
    } catch (error) {
      console.log(error)
    }
  }

  // Data handling ( Assets, Acquisition, Book Value ) => Transforming the data to be used in the charts
  let assetTotals = {}
  let assetCount = 0
  let filteredAssetsNumber: {
    name: string
    Active: number
    DisposedAndPending: number
  }[] = []

  // Assets Count for when there is only one category
  if (dashboardAssets && isOneCategory) {
    dashboardAssets.Assets.forEach(category => {
      category.Categories.forEach(entry => {
        entry.CategoryValues.forEach(value => {
          const quarter = value.Quarter
          if (!assetTotals[quarter]) {
            assetTotals[quarter] = {
              name: quarter,
              Active: 0,
              DisposedAndPending: 0,
            }
          }

          assetTotals[quarter].Active += value.Active
          assetTotals[quarter].DisposedAndPending += value.DisposedAndPending
        })
      })
    })

    filteredAssetsNumber = Object.values(assetTotals)

    assetCount =
      filteredAssetsNumber[filteredAssetsNumber.length - 1].Active +
      filteredAssetsNumber[filteredAssetsNumber.length - 1].DisposedAndPending
  }

  // Assets Count for when there are multiple categories
  if ((isMultipleCategories || isAllCategories) && dashboardAssets) {
    let result = dashboardAssets.Assets[0].Categories.map(category => {
      let lastValue = category.CategoryValues[category.CategoryValues.length - 1]

      return {
        name: category.CategoryName,
        Active: lastValue.Active,
        DisposedAndPending: lastValue.DisposedAndPending,
      }
    })

    assetCount = result.reduce((total, category) => {
      return total + category.Active + category.DisposedAndPending
    }, 0)

    filteredAssetsNumber = result
  }

  // Acquisition Cost, Depreciation and Book Value
  let acquitisionSums = {}
  let bookValueSums = {}

  let acquisitionData: AcquisitionData[] = []
  let bookValueData: BookValueData[] = []

  let totalAcquisition = 0
  let totalBookValue = 0

  if (dashboardAssets) {
    for (let category of dashboardAssets.Acquisition) {
      for (let value of category.CategoryValues) {
        if (!(value.Quarter in acquitisionSums)) {
          acquitisionSums[value.Quarter] = {
            name: value.Quarter,
            Active: 0,
            Disposed: 0,
            Totals: 0,
          }
        }

        acquitisionSums[value.Quarter].Active += value.Active
        acquitisionSums[value.Quarter].Disposed += value.Disposed
        acquitisionSums[value.Quarter].Totals += value.Totals
      }
    }

    for (let category of dashboardAssets.BookValue) {
      for (let value of category.CategoryValues) {
        if (!(value.Quarter in bookValueSums)) {
          bookValueSums[value.Quarter] = {
            name: value.Quarter,
            Acquisition: 0,
            LTDDepreciation: 0,
            BookValue: 0,
          }
        }

        bookValueSums[value.Quarter].Acquisition += value.Acquisition
        bookValueSums[value.Quarter].LTDDepreciation += value.LTDDepreciation
        bookValueSums[value.Quarter].BookValue += value.BookValue
      }
    }

    acquisitionData = Object.values(acquitisionSums)
    bookValueData = Object.values(bookValueSums)

    totalAcquisition = acquisitionData.reduce((total, quarter) => {
      return total + quarter.Totals
    }, 0)

    totalBookValue = bookValueData.reduce((total, quarter) => {
      let lastValue = quarter.BookValue
      if (lastValue !== 0) {
        return lastValue
      }
      return total
    }, 0)
  }

  return {
    // Select and MultiSelect
    getMultiSelectValue,
    handleMultiSelectChange,
    handleSelectAmountChange,
    getSelectValue,

    // Form methods
    methods,
    handleDashboardFiltersSubmit,

    // Assets
    assetCount,
    filteredAssetsNumber,
    isFetchingDashboardAssets,
    isSuccessGettingDashboardAssets,

    // Acquisition Data
    acquisitionData,
    totalAcquisition,

    // Book Value Data
    bookValueData,
    totalBookValue,
  }
}
