import React, { useState } from 'react'
import { useStyletron } from 'styletron-react'
import { AccordionProps } from './Accordion.types'
import AccordionItem from './AccordionItem'
import * as styles from './Styles'

const Accordion: React.FC<AccordionProps> = ({ items, initialOpen = undefined }) => {
  const [css] = useStyletron()
  const [activeIndex, setActiveIndex] = useState(initialOpen)

  const renderAccordion = items.map((item, index) => {
    const isOpen = index === activeIndex ? 'show-description' : ''

    return (
      <AccordionItem
        key={index}
        isOpen={isOpen}
        item={item}
        index={index}
        onClick={() => {
          activeIndex === index ? setActiveIndex(undefined) : setActiveIndex(index)
        }}
        id={index}
        autoFocus={item.autoFocus}
      />
    )
  })

  return <ul className={css(styles.wrapper)}>{renderAccordion}</ul>
}

export default Accordion
