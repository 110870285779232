import React from 'react'
import { useStyletron } from 'styletron-react'

import * as styles from './Styles'
import TableRowSkeleton from './TableRowSkeleton'

interface Props {
  rows?: number
  height?: string
}

const TableSkeleton: React.FC<Props> = ({ height, rows = 8 }) => {
  const [css] = useStyletron()

  const SkeletonHeader = () => (
    <div className={css(styles.skeletonHead)}>
      <div className={css(styles.skeletonPlaceholder)} />
    </div>
  )
  return (
    <div className={css({ ...styles.skeletonWrapper({ height }) })}>
      <SkeletonHeader />
      <TableRowSkeleton rows={rows} />
    </div>
  )
}
export default TableSkeleton
