export const helpText = {
  AcquisitionCost: {
    text:
      'Shows the total Acquisition Cost of the company for assets purchased in the last selected quarters.',
  },
  BookValue: {
    text:
      'Displays the entire book value of the company for the last selected quarters. If you don’t see LTDDepreciation and BookValue data, it’s because you need to run a depreciation process to populate that data.',
  },
}
