import {
  Accordion,
  Button,
  Card,
  Flexbox,
  Heading,
  Icon,
  Modal,
  Search,
  SideSheet,
  Spacer,
  Table,
  Tag,
  useModal,
  useSideSheet,
} from '@components/common'
import { AccordionItem } from '@components/common/Accordion/Accordion.types'
import Pagination from '@components/Pagination'
import { ASSET_VIEWS } from '@constants'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTable, useSortBy, useFlexLayout, useColumnOrder } from 'react-table'
import ChangeColumnOrder from './ChangeColumnOrder'
import { Filters } from './Filters'
import SelectColumns from './SelectColumns'
import { useAssetsTable } from '@hooks'
import AddAssetFormWrapper from '@components/AddAssetForm/AddAssetFormWrapper'
import { useDispatch, useSelector } from 'react-redux'
import usePermission from '@components/Auth/Permissions/usePermission'
import AssetsTableLoading from './AssetsTableLoading'
import ImportData from '@components/ImportData'
import { download } from '@utils/basic'

const AssetsTable = () => {
  const setFilterState = useDispatch()
  const filterState = useSelector<any>(state => state.filters)

  // Call useAssetsTable
  const {
    assetCompanies,
    isErrorGettingCompanies,

    fields,
    isErrorGettingFields,
    tableColumns,
    defaultUserColumns,

    userTableParams,
    getHiddenColumns,

    isErrorGettingUserTableParams,
    handleSetUserTableParams,
    isLoadingSetUserTableParams,
    hasUserTableParamsUpdated,
    // activeCompany,
    assets,

    isReFetchingAssets,
    isErrorGettingAssets,

    isTableLoading,

    currentPage,
    itemsPerPage,
    activeAssetCompanyID,
    activeCompanyName,
    activeFilterTags,
    changeAssetsPerPage,
    changePage,
    gotoAssetPage,
    removeFilter,
    clearAllFilters,
    searchByDescription,
    searchByDescriptionValue,
    updateSortSearchParam,
    sortByInitialState,
  } = useAssetsTable({
    filterState,
    setFilterState,
  })

  // Declare React-Table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
    setHiddenColumns,
    visibleColumns,
    setSortBy,
    state: { sortBy, hiddenColumns: hiddenColumnsState },
  } = useTable(
    {
      columns: tableColumns,
      data: assets ? assets.list : [],
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        hiddenColumns: getHiddenColumns(defaultUserColumns),
        columnOrder: defaultUserColumns,
        sortBy: sortByInitialState(),
      },
    },

    useFlexLayout,
    useSortBy,
    useColumnOrder
  )

  useEffect(() => {
    if (!isTableLoading && userTableParams) {
      setColumnOrder(userTableParams.Order)
      setHiddenColumns(getHiddenColumns(userTableParams.Columns))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumnOrder, setHiddenColumns, userTableParams, isTableLoading])

  // Update sort param
  useEffect(() => {
    updateSortSearchParam(sortBy, setSortBy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  //
  const {
    sideSheetIsOpen: isFiltersWindowOpen,
    triggerSideSheet: openFiltersWindow,
    closeSideSheet: closeFiltersWindow,
  } = useSideSheet()

  const {
    sideSheetIsOpen: isEditColumnsWindowOpen,
    triggerSideSheet: openEditColumnsWindow,
    closeSideSheet: closeEditColumnsWindow,
  } = useSideSheet()

  const {
    modalIsOpen: addAssetModalIsOpen,
    closeModal: closeAddAssetModal,
    triggerModal: triggerAddAssetModal,
  } = useModal()

  const navigate = useNavigate()

  const { canEditAssets: canEdit } = usePermission()

  // Returns
  if (isTableLoading) {
    return <AssetsTableLoading />
  }

  if (isErrorGettingCompanies) {
    return <p>Error getting companies.</p>
  }

  if (isErrorGettingAssets) {
    return <p>Error getting assets.</p>
  }

  if (isErrorGettingFields || isErrorGettingUserTableParams) {
    return <p>Error getting fields.</p>
  }

  if (assetCompanies.length === 0) {
    return <p>No companies.</p>
  }

  // Accordions for filters and edit columns
  const editColumnsAccordionItems: AccordionItem[] = [
    {
      label: 'Select Columns',
      content: <SelectColumns tableColumns={allColumns} fields={fields} />,
    },
    {
      label: 'Change Column Order',
      content: (
        <ChangeColumnOrder
          visibleColumns={visibleColumns}
          setColumnOrder={setColumnOrder}
          fields={fields}
        />
      ),
    },
  ]

  const importButton = (
    <Button
      onClick={() => download(`${process.env.PUBLIC_URL}/templates/assets.csv`, 'asset.csv')}
      appearance="text"
    >
      Download an import template here.
    </Button>
  )

  return (
    <>
      <header>
        <Flexbox justifyContent="space-between" alignItems="center">
          <Flexbox alignItems="center">
            <Heading type="h1">Assets</Heading>
          </Flexbox>
          <Flexbox>
            <Flexbox gap={12} alignItems='center' flexDirection='row'>
              <ImportData action='assets' title='Import Assets' modalDesc={importButton} />
              <Button
                appearance="primary"
                onClick={() => triggerAddAssetModal()}
                hidden={!canEdit}
              >
                Add asset
              </Button>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </header>
      <Spacer y="default" />
      <Card spacing="none">
        <Table.Actions>
          <Flexbox justifyContent="space-between" style={{ overflow: 'hidden' }}>
            <Search
              value={searchByDescriptionValue ? searchByDescriptionValue : ''}
              onChange={searchByDescription}
              placeholder="Search by asset description or asset ID..."
              name="AS_DESC"
              width="50%"
            />
            <Flexbox>
              <Button onClick={openFiltersWindow}>Filters</Button>
              <Spacer x="mini" />
              <Button onClick={openEditColumnsWindow}>Edit columns</Button>
              <Spacer x="mini" />
              <Button
                onClick={() => handleSetUserTableParams(visibleColumns, hiddenColumnsState)}
                loading={isLoadingSetUserTableParams}
                disabled={!hasUserTableParamsUpdated(visibleColumns)}
                icon={<Icon name="Star" />}
                appearance="primary"
              >
                Save column layout
              </Button>
            </Flexbox>
          </Flexbox>

          {activeFilterTags.length > 0 && (
            <div id="assets_table_active_filters">
              <Spacer y="mini" />
              <ul>
                {activeFilterTags.map(filter => (
                  <li key={filter.field} style={{ display: 'inline-block' }}>
                    <Tag
                      content={filter.label}
                      appearance="outline"
                      remove={() =>
                        removeFilter(
                          filter.field,
                          filter.isRange,
                          filter.isMin,
                          filter.isMax
                        )
                      }
                    />
                  </li>
                ))}
              </ul>
              <Spacer y="compact" />
              <Button onClick={() => clearAllFilters()} overShade>
                Clear all filters
              </Button>
              <Spacer y="mini" />
            </div>
          )}
        </Table.Actions>
        {assets.list.length === 0 ? (
          <Table.NoResults text="Try changing the active company, filters or search query." />
        ) : (
          <>
            <Table
              {...getTableProps()}
              aria-label="Assets Table"
              label="Assets Table"
              aria-colcount={visibleColumns.length}
              aria-rowcount={assets.list.length}
              height="calc(100vh - 280px)"
              // minHeight={assets.list.length > 10 && '300px'}
            >
              <Table.Head sticky>
                {isReFetchingAssets ? (
                  <Table.LoadingRows />
                ) : (
                  headerGroups.map((headerGroup, i) => (
                    <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, i) => (
                        <Table.Header
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          column={column}
                          key={i}
                          tabIndex={0}
                        >
                          {column.render('Header')}
                        </Table.Header>
                      ))}
                    </Table.HeadRow>
                  ))
                )}
              </Table.Head>
              <Table.Body {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Table.Row
                      {...row.getRowProps()}
                      onClick={() =>
                        navigate(
                          `/assets/${row.original.AssetCompanyID}/${row.original.AssetID}/${ASSET_VIEWS.DETAILS}`
                        )
                      }
                      onKeyDown={() =>
                        navigate(
                          `/assets/${row.original.AssetCompanyID}/${row.original.AssetID}/${ASSET_VIEWS.DETAILS}`
                        )
                      }
                      tabIndex={0}
                    >
                      {row.cells.map(cell => {
                        return (
                          <Table.Cell {...cell.getCellProps()} column={cell.column}>
                            {cell.render('Cell')}
                          </Table.Cell>
                        )
                      })}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <Pagination
              changePage={changePage}
              changeItemsPerPage={changeAssetsPerPage}
              goToPage={gotoAssetPage}
              totalItems={assets.count}
              maxItemsPerPage={itemsPerPage ? itemsPerPage : 20}
              currentPageNumber={currentPage ? currentPage : 1}
              labelStart={`Showing`}
              label={`of ${assets.count} asset${assets.count > 1 ? 's' : ''}`}
            />
          </>
        )}
      </Card>

      <SideSheet
        isOpen={isFiltersWindowOpen}
        close={closeFiltersWindow}
        title="Filters"
        actionLabel="Apply"
      >
        <Filters
          fields={fields}
          activeFilterTags={activeFilterTags}
          removeFilter={removeFilter}
        />
      </SideSheet>

      <SideSheet
        isOpen={isEditColumnsWindowOpen}
        close={closeEditColumnsWindow}
        title="Edit Columns"
        actionLabel="Apply"
      >
        <Accordion items={editColumnsAccordionItems} />
      </SideSheet>

      <Modal
        isOpen={addAssetModalIsOpen}
        close={closeAddAssetModal}
        title={`Add a new asset to Company ${activeAssetCompanyID} (${activeCompanyName})`}
        width={480}
        customStyles={{
          position: 'fixed',
          top: '10%',
        }}
      >
        <AddAssetFormWrapper assetCompanyID={activeAssetCompanyID} />
      </Modal>
    </>
  )
}
export default AssetsTable
