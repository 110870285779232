import { Card } from '@components/common'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { FormProvider } from 'react-hook-form'
import CommentForm from './CommentForm'
import useCommentsHook from './useCommentsHook'
import { useParams } from 'react-router-dom'
import { isNullOrUndefined } from '@utils/basic'
import { IUserGrantProps } from '@components/Auth/UserRole'
import CommentLoading from './CommentLoading'
import { CommentType } from '@api/models'

interface CommentEditorProps extends IUserGrantProps {
  title: string
  assetCompanyID: number
  type: CommentType
  objectID: string
  key1?: string
  key2?: string
  key3?: string
  key4?: string
  key5?: number
}

const CommentEditor = ({
  assetCompanyID,
  type,
  objectID,
  key1,
  key2,
  key3,
  key4,
  key5,
  canEdit,
}: CommentEditorProps) => {
  const { fields, isLoadingComment, onSave, methods, comment, showComment, setShowComment } = useCommentsHook({
    assetCompanyID,
    type,
    objectID,
    key1,
    key2,
    key3,
    key4,
    key5,
  })

  let EditingEnabled = false
  const params = useParams()
  const { data: isAssetDesc } = useGetAssetQuery({
    assetCompanyID: parseInt(params.companyID),
    assetID: params.assetID,
  })

  if (!isNullOrUndefined(isAssetDesc)) {
    EditingEnabled =
      !canEdit || isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P'
  }

  return (
    <FormProvider {...methods}>
      <Card title={'Comments'}>
        {isLoadingComment && <CommentLoading />}

        {!isLoadingComment && (
          <CommentForm
            fields={fields}
            comment={comment}
            methods={methods}
            canEdit={canEdit}
            onSave={onSave}
            showComment={showComment}
            setShowComment={setShowComment}
          />
        )}
      </Card>
    </FormProvider>
  )
}

export default CommentEditor
