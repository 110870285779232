import * as Yup from 'yup'
import { REQUIRED_FIELD } from '@constants'
import { dateExistValidation } from '../../models/validation'

export const companyPeriodSchema = Yup.object({
  AssetCompanyID: Yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  CP_YEAR: Yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  CP_PERIOD: Yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  CP_DATE: Yup.date()
    .typeError(REQUIRED_FIELD)
    .required(REQUIRED_FIELD)
    .default(new Date())
    .test(dateExistValidation),
  CP_PERCENT: Yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
  CP_FRWD: Yup.bool().default(false),
  CP_WEEKEND: Yup.bool().default(true),
})
export type CompanyPeriod = Yup.InferType<typeof companyPeriodSchema>
export type CompanyPeriodKeys = keyof CompanyPeriod
