import {
  AdminInsuranceReportFilter,
  AdminMaintenanceReportFilter,
  DefaultReportFilter,
  ChargeReportFilter,
  SearchType,
} from './ReportFilter.types'

export const adminInsuranceReportFilterFields: AdminInsuranceReportFilter[] = [
  {
    name: 'IN_POL_ID',
    type: String,
  },
  {
    name: 'IN_DESC',
    type: String,
  },
  {
    name: 'IN_CON_NM',
    type: String,
  },
  {
    name: 'IN_PHONE',
    type: String,
  },
  {
    name: 'IN_CP_FRM',
    type: Date,
  },
  {
    name: 'IN_CP_TO',
    type: Date,
  },
]

export const adminMaintenanceReportFilterFields: AdminMaintenanceReportFilter[] = [
  {
    name: 'MN_CON_ID',
    type: String,
  },
  {
    name: 'MN_DESC',
    type: String,
  },
  {
    name: 'MN_CON_NM',
    type: String,
  },
  {
    name: 'MN_PHONE',
    type: String,
  },
  {
    name: 'MN_CP_FRM',
    type: Date,
  },
  {
    name: 'MN_CP_TO',
    type: Date,
  },
]

export const defaultReportFilterFields: DefaultReportFilter[] = [
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'AS_KEY0_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY1_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY2_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY3_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY4_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY5_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY6_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY7_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY8_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY9_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_DESC',
    type: String,
  },
  {
    name: 'AS_CAT_ID',
    type: 'options',
    options: 'DepreciationTypeA',
  },
  {
    name: 'AS_MAIN_CN',
    type: String,
  },
  {
    name: 'AS_IN_POL',
    type: String,
  },
  {
    name: 'AS_PAR_ASS',
    type: String,
  },
  {
    name: 'AS_REC_PON',
    type: String,
  },
  {
    name: 'AS_PRI_UCD',
    type: String,
  },
  {
    name: 'AS_REC_USR',
    type: 'options',
    options: 'Picklist',
  },
  {
    name: 'AS_SEC_UCD',
    type: String,
  },
  {
    name: 'AS_UC1_UCD',
    type: String,
  },
  {
    name: 'AS_UC2_UCD',
    type: String,
  },
  {
    name: 'AS_UC3_UCD',
    type: String,
  },
  {
    name: 'AS_SERIAL',
    type: String,
  },
  {
    name: 'AS_TCLS_ID',
    type: 'options',
    options: 'DepreciationTypeX',
  },
  {
    name: 'AS_VEN_ID',
    type: 'options',
    options: 'Vendor',
  },
  {
    name: 'AS_VEN_NM',
    type: 'create-options',
  },
  {
    name: 'AS_LEASE',
    type: String,
  },
]

export const assetTransfersReportFilterFields: DefaultReportFilter[] = [
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'AS_KEY0_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY1_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY2_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY3_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY4_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY5_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY6_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY7_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY8_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY9_ID',
    type: 'options',
    options: 'Segment',
  },
]

export const glPostingReportsFilterFields: DefaultReportFilter[] = [
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'AS_KEY0_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY1_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY2_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY3_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY4_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY5_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY6_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY7_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY8_ID',
    type: 'options',
    options: 'Segment',
  },
  {
    name: 'AS_KEY9_ID',
    type: 'options',
    options: 'Segment',
  },
]

// LOCATION
export const currentAssetLocationReportFilterFields: ChargeReportFilter[] = [
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'CH_LOCT_CD',
    type: 'create-options',
  },
  {
    name: 'CH_LOC_NR',
    type: 'create-options',
  },
  {
    name: 'CH_DESC',
    type: 'create-options',
    chargeTypeCode: 'L',
  },
  {
    name: 'CH_DATE',
    type: Date,
    chargeTypeCode: 'L',
  },
]

export const assetLocationHistoryReportFilterFields: ChargeReportFilter[] = [
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'CH_LOCT_CD',
    type: 'create-options',
  },
  {
    name: 'CH_LOC_NR',
    type: 'create-options',
  },
  {
    name: 'CH_DESC',
    type: 'create-options',
    chargeTypeCode: 'L',
  },
  {
    name: 'CH_DATE',
    type: Date,
    chargeTypeCode: 'L',
  },

  {
    name: 'CH_AUDITNR',
    type: 'options',
    options: 'References',
  },
]

export const locationAccountingReportFilterFields: ChargeReportFilter[] = [
  {
    name: 'CH_CAPC_ID',
    type: 'options',
    options: 'Acquisition Post Code',
  },
  {
    name: 'CH_CDPC_ID', // Depreciation Post Code
    type: 'options',
    options: 'Depreciation Post Code',
  },
  {
    name: 'CH_DIS_CD',
    type: 'options',
    options: 'Disposition',
  },
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'CH_LOCT_CD',
    type: 'create-options',
  },
  {
    name: 'CH_LOC_NR',
    type: 'create-options',
  },
]

// SERVICES
export const serviceAnalysisReportFilterFields: ChargeReportFilter[] = [
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'CH_SER_CD',
    type: 'create-options',
  },
  {
    name: 'CH_AUDITNR',
    type: 'options',
    options: 'References',
  },
  {
    name: 'CH_DESC',
    type: 'create-options',
    chargeTypeCode: 'S',
  },
  {
    name: 'CH_PERSON',
    type: 'create-options',
  },
  {
    name: 'AS_NSER_DT',
    type: Date,
  },
]

export const serviceSchedulingReportFilterFields: ChargeReportFilter[] = [
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'CH_DATE',
    type: Date,
    chargeTypeCode: 'S',
  },
  {
    name: 'CH_SER_CD',
    type: 'create-options',
  },
  {
    name: 'CH_AUDITNR',
    type: 'options',
    options: 'References',
  },
]

export const serviceAccountingReportFilterFields: ChargeReportFilter[] = [
  {
    name: 'CH_CAPC_ID',
    type: 'options',
    options: 'Acquisition Post Code',
  },
  {
    name: 'CH_CDPC_ID', // Depreciation Post Code
    type: 'options',
    options: 'Depreciation Post Code',
  },
  {
    name: 'CH_DIS_CD',
    type: 'options',
    options: 'Disposition',
  },
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'CH_SER_CD',
    type: 'create-options',
  },
  {
    name: 'CH_AUDITNR',
    type: 'options',
    options: 'References',
  },
]

// TASKS/ACTIVITIES
export const activityAnalysisReportFilterFields: ChargeReportFilter[] = [
  {
    name: 'CH_ACT_ID',
    type: 'options',
    chargeTypeCode: 'O',
    options: 'Activity ID',
  },
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'CH_AUDITNR',
    type: 'options',
    options: 'References',
  },
]

export const taskAccountingReportFilterFields: ChargeReportFilter[] = [
  {
    name: 'CH_CAPC_ID', // Acquisition Post Code || Transition Post Code
    type: 'options',
    options: 'Acquisition Post Code',
  },
  {
    name: 'CH_CDPC_ID', // Depreciation Post Code
    type: 'options',
    options: 'Depreciation Post Code',
  },
  {
    name: 'CH_DIS_CD', // Disposition Code (Expense, Capitalize, Do not post...)
    type: 'options',
    options: 'Disposition',
  },
  {
    name: 'AssetID',
    type: 'create-options',
  },
  {
    name: 'CH_ACT_ID',
    type: 'options',
    chargeTypeCode: 'O',
    options: 'Activity ID',
  },
  {
    name: 'CH_AUDITNR',
    type: 'options',
    options: 'References',
  },
]

// SEARCH TYPES

export const reportFilterDefaultSearchTypes: SearchType[] = [
  { label: 'Equals', value: 'equals' },
  { label: 'Contains', value: 'contains' },
  { label: 'Starts with', value: 'startsWith' },
]
