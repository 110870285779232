import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

interface TableRowSkeletonProps {
  rows?: number
  height?: string
}

const TableRowSkeleton = ({
  rows = 1,
  height = 'calc(var(--size-default) - 2px)',
}: TableRowSkeletonProps) => {
  const [css] = useStyletron()

  const skeletonRows = [...Array(rows)].map((e, i) => (
    <div key={i} className={css(styles.skeletonRow({ height }))} />
  ))

  return <>{skeletonRows}</>
}

export default TableRowSkeleton
