import { ImportColumn } from "../importTypes";

export const tasks: ImportColumn[] = [
    {
        label: 'Activity ID',
        value: 'OT_ACT_ID'
    },
    {
        label: 'Activity Description',
        value: 'OT_DESC'
    },
    {
        label: 'Disposition Code',
        value: 'OT_RETD_CD'
    },
    {
        label: 'Transaction Post Code',
        value: 'OT_OAPC_ID'
    },
    {
        label: 'Depreciation Post Code',
        value: 'OT_ODPC_ID'
    }
]