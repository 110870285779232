import DataDisplayList from '@components/DataDisplayList'
import { Card, Flexbox, Grid, Heading, Spacer } from '@components/common'
import ProfileCircle from './ProfileCircle'

const ProfilePrefsMain = ({ user }) => {
  if (Array.isArray(user.role)) {
    user.role = user.role.join(', ')
  }

  const items = [
    {
      label: 'First Name',
      value: user.first_name,
    },
    {
      label: 'Last Name',
      value: user.last_name,
    },
    {
      label: 'Email',
      value: user.email,
    },
    {
      label: 'Email Confirmed',
      value: user.email_confirmed,
    },
    {
      label: 'Phone',
      value: user.phone_number,
    },
    {
      label: 'Phone Confirmed',
      value: user.phone_number_confirmed,
    },
    {
      label: 'Role',
      value: user.role,
    },
  ]

  return (
    <Card overflow="hidden">
      <Grid>
        <Grid.Item m={6}>
          <Flexbox justifyContent="center" alignItems="center" flexDirection="column">
            <ProfileCircle user={user} />
            <Heading type="h1">{user.full_name}</Heading>
          </Flexbox>
        </Grid.Item>

        <Grid.Item m={6}>
          <Heading type="h2">Account Details</Heading>
          <Spacer y="default" />
          <DataDisplayList items={items} columns={1} />
        </Grid.Item>
      </Grid>
    </Card>
  )
}

export default ProfilePrefsMain
