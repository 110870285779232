import React, { useState, useEffect } from 'react'
import { SearchProps } from './Search.types'

import { useStyletron } from 'styletron-react'
import * as styles from '../Input/Styles'

import InputIcon from '../Input/InputIcon'
import { Button, Icon } from '../'

const Search: React.FC<SearchProps> = ({
  label,
  size = 'default',
  error = false,
  helper,
  errorMsg,
  disabled,
  icon,
  iconRight,
  width = '100%',
  flexGrow = false,
  appearance = 'default',
  ...props
}) => {
  const [focus, setFocus] = React.useState(false)

  // useState & useEffect to allow for clearing value
  const [value, setValue] = useState(props.value)
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const [css] = useStyletron()
  let containerStyles = css({ ...styles.container({ width, flexGrow }) })
  let labelStyles = css({
    ...styles.label({ disabled, label }),
  })

  let inputWrapperStyles = css({
    ...styles.inputWrapperBase,
    ...styles.getFocus({ focus, error }),
    ...styles.inputWrapperDisabled({ disabled, appearance }),
    ...styles.getSize({ size, appearance, select: false }),
  })

  let inputStyles = css({
    ...styles.inputBase,
    ...styles.inputBaseDisabled({ disabled }),
  })

  let helperStyles = css({
    ...styles.helperText({ disabled }),
  })
  let errorStyles = css(styles.errorMsg)

  return (
    <div className={containerStyles}>
      <label htmlFor={props.name} className={labelStyles} id={`${props.name}-label`}>
        {label
          ? label
          : props.placeholder
          ? props.placeholder
          : `Enter value for ${props.name}`}
      </label>
      <div className={inputWrapperStyles}>
        <InputIcon size={size} icon={<Icon name="Search" />} position="left" />
        <input
          className={inputStyles}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          type="search"
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          value={value}
          disabled={disabled}
          autoFocus={props.autoFocus}
          required={props.required}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          pattern={props.pattern}
          readOnly={props.readOnly}
          placeholder={props.placeholder}
          aria-labelledby={
            props['aria-labelledby'] ? props['aria-labelledby'] : `${props.name}-label`
          }
        />
        {value !== '' && (
          <span>
            <Button
              size="mini"
              appearance="muted"
              icon={<Icon name="X" />}
              shape="circle"
              onClick={() => props.onChange({ target: { value: '' } })}
            />
          </span>
        )}

        {helper && !error && <div className={helperStyles}>{helper}</div>}
        {errorMsg && error && <div className={errorStyles}>{errorMsg}</div>}
      </div>
    </div>
  )
}
export default Search
