import {
  useAssetTransferMutation,
  useGetAssetStructuresQuery,
  useGetCompanyQuery,
  useGetYearsQuery,
  useListSegmentsQuery,
  useValidateAssetTransferMutation,
} from '@api'
import {
  AssetTransferParams,
  assetTransferSchema,
  FieldLabels,
  IAssetStructure,
  ValidateAssetTransferParams,
} from '@api/models'
import { toast } from '@components/common'
import { ERROR_REQUEST } from '@constants'
import { useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import useFields from './useFields'

interface AssetStructureHookProps {
  assetCompanyID: number
  assetID: string
}

interface ValidateAssetTransferState {
  isModalOpen: boolean
  input: boolean
  message?: string
}

interface IAssetStructureHook {
  companyHasPeriods: boolean
  assetStructures: IAssetStructure[]
  getSegmentOptions: (KY_NR: number) => ReactSelectOptions
  fields: FieldLabels
  isLoading: boolean

  handleValidateTransfer: (params: AssetTransferParams) => Promise<void>
  isValidatingTransfer: boolean

  handleAssetTransfer: (payload: AssetTransferParams) => Promise<void>
  isLoadingAssetTransfer: boolean

  validateTransferState: ValidateAssetTransferState
  closeValidateTransferModal: () => void
  isConfirmTransferModalOpen: boolean
  closeConfirmTransferModal: () => void

  methods: UseFormReturn<AssetTransferParams>
  activeTransfers: { field: string; from: string; to: string }[]
  isStructuresChanged: boolean
  periodOptions: ReactSelectOptions
  yearOptions: ReactSelectOptions
}

export default function useAssetStructure({
  assetCompanyID,
  assetID,
}: AssetStructureHookProps): IAssetStructureHook {
  // react-hook-form
  const methods = useForm<AssetTransferParams>({
    resolver: yupResolver(assetTransferSchema),
    mode: 'onChange',
    defaultValues: assetTransferSchema.cast({}),
  })

  const {
    reset,
    formState: { dirtyFields },
    watch,
  } = methods

  // Get years and check if company has periods
  const {
    data: years,
    isLoading: isLoadingYears,
    isSuccess: isSuccessGettingYears,
  } = useGetYearsQuery({ assetCompanyID })

  const companyHasPeriods: boolean = years?.length > 0

  // Get company for number of periods
  const {
    data: company,
    isLoading: isLoadingCompany,
    isSuccess: isSuccessLoadingCompany,
  } = useGetCompanyQuery(assetCompanyID, {
    skip: !companyHasPeriods,
  })
  let periodOptions: ReactSelectOptions = []
  // map number of periods to create period select options
  if (isSuccessLoadingCompany) {
    periodOptions = [...Array(company.CM_NR_PER).keys()].map((_, i) => ({
      label: (i + 1).toString(),
      value: i + 1,
    }))
  }

  // Get structures
  const {
    data: assetStructures,
    isLoading: isLoadingAssetStructures,
    isSuccess: isSuccessGettingStructures,
  } = useGetAssetStructuresQuery({ assetCompanyID, assetID }, { skip: !companyHasPeriods })

  // Reset form
  useEffect(
    function resetFormWhenDataLoads() {
      if (
        isSuccessGettingStructures &&
        isSuccessLoadingCompany &&
        isSuccessGettingYears &&
        companyHasPeriods
      ) {
        const values: AssetTransferParams = watch()

        for (const [key, structure] of Object.entries(assetStructures)) {
          values[`key${key}`] = structure.value
        }

        reset({
          ...values,
          assetCompanyID,
          assetID,
          year: years[years.length - 1],
          period: 1,
        })
      }
    },
    [isLoadingAssetStructures, isLoadingCompany, isLoadingYears]
  )

  // TRANSFER
  const [
    validateTransferState,
    setValidateTransferState,
  ] = useState<ValidateAssetTransferState>({
    isModalOpen: false,
    input: false,
  })
  function closeValidateTransferModal(): void {
    setValidateTransferState(prevState => ({
      ...prevState,
      isModalOpen: false,
    }))
  }

  const [isConfirmTransferModalOpen, setIsConfirmTransferModalOpen] = useState<boolean>(
    false
  )
  function closeConfirmTransferModal(): void {
    setIsConfirmTransferModalOpen(false)
  }

  // Validate
  const [
    validateTransfer,
    { isLoading: isValidatingTransfer },
  ] = useValidateAssetTransferMutation()

  // Runs when user first submits form
  async function handleValidateTransfer(params: AssetTransferParams): Promise<void> {
    try {
      const { assetCompanyID, assetID, period, year, m_asOf } = params

      const payload: ValidateAssetTransferParams = {
        assetCompanyID,
        assetID,
        period,
        year,
        m_asOf,
      }

      await validateTransfer(payload).unwrap()

      // If no errors on validation, we send user to confirm transfer
      setIsConfirmTransferModalOpen(true)
    } catch (error: any) {
      // If validation error, set validation modal state
      setValidateTransferState({
        isModalOpen: error.data.input,
        input: error.data.input,
        message: error.data.message,
      })

      if (!error.data.input) {
        toast.error(error.data.message)
      }
    }
  }

  // Transfer
  const [transferAsset, { isLoading: isLoadingAssetTransfer }] = useAssetTransferMutation()

  async function handleAssetTransfer(payload: AssetTransferParams): Promise<void> {
    try {
      await transferAsset(payload).unwrap()

      reset({
        ...payload,
        assetCompanyID,
        assetID,
        year: years[years.length - 1],
        period: 1,
      })

      toast.success(`Successfully performed asset structure transfer.`)
    } catch (error) {
      toast.error(ERROR_REQUEST)
    } finally {
      setIsConfirmTransferModalOpen(false)
      setValidateTransferState(prevState => ({
        ...prevState,
        isModalOpen: false,
      }))
    }
  }

  // Get segments for select options
  const { data: segments, isLoading: isLoadingSegments } = useListSegmentsQuery(undefined, {
    skip: !companyHasPeriods,
  })

  function getSegmentOptions(KY_NR: number): ReactSelectOptions {
    const filteredSegments = segments?.filter(segment => segment.KY_NR === KY_NR)

    const options: ReactSelectOptions = []

    for (const segment of filteredSegments) {
      options.push({
        label: `${segment.KY_VALUE} (${segment.KY_DESC})`,
        value: segment.KY_VALUE,
      })
    }

    return options
  }

  const yearOptions: ReactSelectOptions = []
  if (isSuccessGettingYears && companyHasPeriods) {
    for (const year of years) {
      yearOptions.push({
        label: year.toString(),
        value: year,
      })
    }
  }

  // Get fields
  const { fields, isLoading: isLoadingFields } = useFields({
    skipRequest: !companyHasPeriods,
  })

  // Changes/review
  const activeTransfers: { field: string; from: string; to: string }[] = []

  const originalValues = {}
  if (isSuccessGettingStructures) {
    for (const [key, structure] of Object.entries(assetStructures)) {
      originalValues[`key${key}`] = structure.value
    }
  }

  for (const [field, isFieldDirty] of Object.entries(dirtyFields)) {
    if (isFieldDirty && field !== 'year' && field !== 'period') {
      const key: number = parseInt(field.substring(field.length - 1))
      const fieldName: string = `AS_KEY${key}_ID`

      const fromSegmentValue: string = originalValues[field]
      const fromSegment: string | undefined = getSegmentOptions(key).find(
        option => option.value === fromSegmentValue
      )?.label

      const toSegmentValue: string = watch(field as any)
      const toSegment: string = getSegmentOptions(key).find(
        option => option.value === toSegmentValue
      )?.label

      activeTransfers.push({
        field: `${fields[fieldName]}`,
        from: fromSegment ?? 'unselected',
        to: toSegment ?? 'unselected',
      })
    }
  }

  const isStructuresChanged: boolean = activeTransfers.length > 0

  // Check before rendering
  const isLoading: boolean =
    isLoadingAssetStructures ||
    isLoadingSegments ||
    isLoadingFields ||
    isLoadingCompany ||
    isLoadingYears

  // Return
  return {
    companyHasPeriods,
    assetStructures,
    getSegmentOptions,
    fields,
    isLoading,

    handleValidateTransfer,
    isValidatingTransfer,
    handleAssetTransfer,
    isLoadingAssetTransfer,

    validateTransferState,
    closeValidateTransferModal,
    isConfirmTransferModalOpen,
    closeConfirmTransferModal,

    methods,
    activeTransfers,
    isStructuresChanged,
    periodOptions,
    yearOptions,
  }
}
