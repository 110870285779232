import { ButtonSkeleton, Grid, InputSkeleton, Spacer } from '@components/common'

const CommentLoading: React.FC = () => {
  return (
    <Grid>
      <Grid.Item>
        <InputSkeleton label />
        <InputSkeleton label />
        <Spacer y="default" />
        <InputSkeleton />
        <Spacer y="default" />
        <ButtonSkeleton width="var(--scale1500)" />
      </Grid.Item>
    </Grid>
  )
}

export default CommentLoading
