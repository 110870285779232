import { Asset } from './Asset.model'
import * as Yup from 'yup'
import { REQUIRED_FIELD } from '@constants'
import { dateExistValidation, digitLengthValidation } from '@api/models/validation'
import { getCurrentYear, getTodaysDate } from '@utils/basic'

export interface AssetTransfer {
  assetCompanyId: number
  assetId: string
  m_asOf: string
  year: number
  period: number
  key0?: string
  key1?: string
  key2?: string
  key3?: string
  key4?: string
  key5?: string
  key6?: string
  key7?: string
  key8?: string
  key9?: string
  removeKeys?: boolean
}

export type GetHistoryProfilesResponse = Array<{ label: string; value: string }>

export type AssetHistoryProfile = 'C' | 'T' | 'S' | 'O'

export const MANUAL_HISTORY_FORM_ID = 'manual_history_form'
export const historySchema = Yup.object({
  FN_AS_DAT: Yup.string()
    .required(REQUIRED_FIELD)
    .test(dateExistValidation)
    .default(getTodaysDate()),
  FN_LTD_DEP: Yup.number().required(REQUIRED_FIELD),
  assetCompanyID: Yup.number(),
  assetID: Yup.string(),
  FN_TYP_CD: Yup.string(),
})
export type HistoryBase = Yup.InferType<typeof historySchema>

export interface GetAssetHistoryPeriodsParams {
  assetCompanyID: number
  assetID: string
  profile: AssetHistoryProfile
}

export interface GetAssetHistoryParams {
  assetCompanyID: number
  assetID: string
  profile: AssetHistoryProfile
  date: string // From GetHistoryPeriods
}

export interface IHistory {
  AppCompanyID: number
  Asset: Asset
  AssetCompanyID: number
  AssetID: string
  FN_ACM_CD
  FN_AS_DAT: string
  FN_AS_PER: number
  FN_CUR_DEP: number
  FN_CUR_NET: number
  FN_FR_CD: string
  FN_LD_DATE: string
  FN_LD_USR: string
  FN_LTD_DEP: number
  FN_LTD_NET: number
  FN_SYS_NR: number
  FN_TYP_CD: string
  FN_Temp: boolean
  FN_YEAR: number
  FN_YTD_DEP: number
  FN_YTD_NET: number
  HistoryChargeType
  HistoryFirstRecord
  GetHistoryProfile
  YearsExpired: number
}
export interface AssetHistoryRecord {
  asset: IHistory
  capitalization: IHistory
  netAssetValue: number
  valueOfAssetDeprec: number
}

export interface AssetHistoryLockParams {
  assetCompanyID: number
  assetID: string
  FN_SYS_NR: number
  FN_TYP_CD: AssetHistoryProfile
  FN_AS_DAT: string
}

// Structures
export interface GetAssetStructuresParams {
  assetCompanyID: number
  assetID: string
}
export type IAssetStructure = { label: string; value: string }

export const validateAssetTransferSchema = Yup.object({
  assetCompanyID: Yup.number()
    .typeError(REQUIRED_FIELD)
    .test(digitLengthValidation(3))
    .required(REQUIRED_FIELD)
    .default(100),
  assetID: Yup.string().required(REQUIRED_FIELD).default(''),
  m_asOf: Yup.string().required(REQUIRED_FIELD).default(getTodaysDate()),
  year: Yup.number().required(REQUIRED_FIELD).default(getCurrentYear()),
  period: Yup.number().required(REQUIRED_FIELD).default(1),
})

export type ValidateAssetTransferParams = Yup.InferType<typeof validateAssetTransferSchema>
export type ValidateAssetTransferParamKey = keyof ValidateAssetTransferParams

export interface ValidateAssetTransferErrorResponse {
  input?: boolean
  message?: string
}

export const assetTransferSchema = Yup.object({
  assetCompanyID: Yup.number()
    .typeError(REQUIRED_FIELD)
    .test(digitLengthValidation(3))
    .required(REQUIRED_FIELD)
    .default(100),
  assetID: Yup.string().required(REQUIRED_FIELD).default(''),
  m_asOf: Yup.string().required(REQUIRED_FIELD).default(getTodaysDate()),
  year: Yup.number().required(REQUIRED_FIELD).default(getCurrentYear()),
  period: Yup.number().required(REQUIRED_FIELD).default(1),
  key0: Yup.string().default('').nullable(),
  key1: Yup.string().default('').nullable(),
  key2: Yup.string().default('').nullable(),
  key3: Yup.string().default('').nullable(),
  key4: Yup.string().default('').nullable(),
  key5: Yup.string().default('').nullable(),
  key6: Yup.string().default('').nullable(),
  key7: Yup.string().default('').nullable(),
  key8: Yup.string().default('').nullable(),
  key9: Yup.string().default('').nullable(),
  removeKeys: Yup.bool().default(false),
})

export type AssetTransferParams = Yup.InferType<typeof assetTransferSchema>
export type AssetTransferParamsKey = keyof AssetTransferParams
