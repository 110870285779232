import React from 'react'

import { useStyletron } from 'styletron-react'

import * as styles from '../Styles'

export interface TableHeaderProps extends React.HTMLProps<HTMLTableCellElement> {}

const Header: React.FC<TableHeaderProps> = ({ ...props }) => {
  const [css] = useStyletron()

  return (
    <th {...props} className={`${css(styles.cell)} ${css(styles.header)}`}>
      {props.children}
    </th>
  )
}

export default Header
