import { Setting } from '@api/models'
import usePermission from '@components/Auth/Permissions/usePermission'
import {
  Button,
  Card,
  Fieldset,
  Flexbox,
  Form,
  Grid,
  Note,
  Radio,
  Spacer,
  Text,
} from '@components/common'
import { CUSTOM_DATE_FORM_ID } from '@constants'
import { Controller, useForm } from 'react-hook-form'
import CustomizeFormatLoading from './CustomizeFormatLoading'

interface CustomizeFormatsFormProps {
  onSubmit: (values: Setting) => Promise<void>
  DateFormats: string
  isGettingSettings: boolean
}

const CustomizeFormatsForm = ({
  onSubmit,
  DateFormats,
  isGettingSettings,
}: CustomizeFormatsFormProps) => {
  const { handleSubmit, control } = useForm<Setting>({
    mode: 'onBlur',
    defaultValues: { SS_VALUE: DateFormats },
  })
  const { canEditSettings: canEdit } = usePermission()

  //Date Format Options
  const dateSettingList = ['MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd', 'yyyy/dd/MM']

  return (
    <Form id={CUSTOM_DATE_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Item m={6}>
          <Card>
            <Note intent="default" content={<>Select date format.</>} />
            <Spacer y="default" />
            <Fieldset legend="Date Format Options">
              <Controller
                control={control}
                name="SS_VALUE"
                render={({ field: { onChange, value } }) => (
                  <Radio
                    name="SelectedOption"
                    onChange={val => onChange(val.target.value)}
                    select={value}
                  >
                    {dateSettingList.map(option => (
                      <Radio.Option
                        key={option}
                        value={option}
                        label={option}
                        selected={true}
                        disabled={!canEdit}
                      />
                    ))}
                  </Radio>
                )}
              />
            </Fieldset>
            <Spacer y="default" />
            <Flexbox>
              <Button type="submit" appearance="primary" hidden={!canEdit}>
                Submit
              </Button>
            </Flexbox>
          </Card>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default CustomizeFormatsForm
