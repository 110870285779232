import { StyleObject } from '@styles-api/css.types'

export const wrapper: StyleObject = {
  display: 'inline-flex',
  alignItems: 'center',
}
export const dot: StyleObject = {
  height: 'var(--scale400)',
  width: 'var(--scale400)',
  borderRadius: '100%',
  userSelect: 'none',
}
export const getColor = ({ type }) => {
  let clr
  if (type === 'default') clr = 'var(--shade400)'
  else clr = `var(--${type}-color)`
  return {
    backgroundColor: clr,
  }
}
export const label: StyleObject = {
  marginLeft: 'var(--scale200)',
}
