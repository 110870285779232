import { TextSkeleton, Flexbox, ButtonSkeleton, InputSkeleton } from '@common'
import NoteSkeleton from '@components/common/Note/NoteSkeleton'

interface Props {
  totalInputs: number
}

const DepreciationProfilesLoading = ({ totalInputs }: Props) => {
  return (
    <Flexbox height={210} flexDirection="column" justifyContent="space-between">
      <TextSkeleton height="var(--scale950)" />
      <InputSkeleton label helperText count={totalInputs} />
    </Flexbox>
  )
}
export default DepreciationProfilesLoading
