import { useGetCompaniesQuery } from '@api'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { useSelector } from 'react-redux'

interface IUseReportData {
  assetCompanies: { label: string; value: number }[]
  depProfilesMap: {
    [key: string]: ReactSelectOptions // key is assetCompanyID
  }
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  isNoCompanies: boolean
  assetCompanyID: number
}

/**
 * Hook to get companies and depreciation profiles.
 */
export function useReport(): IUseReportData {
  const { data: assetCompanies, isLoading, isSuccess, isError } = useGetCompaniesQuery()
  const assetCompanyID = useSelector<any>(state => state.company) as number

  if (isLoading)
    return {
      assetCompanies: [],
      depProfilesMap: {},
      isLoading: true,
      isError: false,
      isSuccess: false,
      isNoCompanies: false,
      assetCompanyID: 0,
    }

  let parsedCompanies = assetCompanies.map(company => ({
    label: company.CM_DESC,
    value: company.AssetCompanyID,
  }))

  let depProfilesMap = {}
  assetCompanies.forEach(company => {
    let profiles = [{ value: 'C', label: 'Corporate' }]
    if (company.CM_TAX) profiles.push({ value: 'T', label: 'Tax' })
    if (company.CM_SMH) profiles.push({ value: 'S', label: 'State' })
    if (company.CM_B4MH) profiles.push({ value: 'O', label: 'Other' })
    depProfilesMap[company.AssetCompanyID] = profiles
  })

  return {
    assetCompanyID,
    assetCompanies: parsedCompanies,
    depProfilesMap,
    isLoading,
    isSuccess,
    isError,
    isNoCompanies: isSuccess && assetCompanies.length === 0,
  }
}
