import { StyleObject } from '@styles-api/css.types'

export default class PaginationStyles {
  container: StyleObject = {
    borderTopWidth: '1px',
    paddingLeft: 'var(--scale600)',
    paddingRight: 'var(--scale600)',
    paddingBottom: 'var(--scale600)',
    paddingTop: 'var(--scale600)',
    position: 'sticky',
    backgroundColor: 'var(--base-color)',
    left: 0,
    bottom: 0,
    borderBottomLeftRadius: 'var(--radius1)',
    borderBottomRightRadius: 'var(--radius1)',
    overflow: 'hidden',
    zIndex: 'var(--z1)',
  }
  nav: StyleObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
  list: StyleObject = {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
  }
  item: StyleObject = {
    listStyle: 'none',
  }
}
