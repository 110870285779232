import { apiSlice } from '@api'
import { serializeURLParams } from '@utils/basic'
import {
  Attachment,
  GetAssetAttachmentsPayload,
  EditAttachmentPayload,
  DeleteAttachmentPayload,
} from './models'

export const attachmentsEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    uploadAttachment: build.mutation<void, FormData>({
      query: payload => {
        const urlParams = {}
        urlParams['assetCompanyID'] = payload.get('assetCompanyID')
        urlParams['objectIDs'] = payload.get('objectIDs')
        urlParams['type'] = payload.get('type')
        urlParams['comment'] = payload.get('comment')

        return {
          url: `Attachment/UploadAttachment?${serializeURLParams(urlParams)}`,
          method: 'POST',
          body: payload,
        }
      },
      invalidatesTags: ['attachment'],
    }),
    getAssetAttachments: build.query<Attachment[], GetAssetAttachmentsPayload>({
      query: payload => `Asset/GetAssetAttachments?${serializeURLParams(payload)}`,
      providesTags: ['attachment'],
    }),
    updateAttachment: build.mutation<void, EditAttachmentPayload>({
      query: payload => ({
        url: 'Attachment/EditAttachment',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['attachment'],
    }),
    deleteAttachment: build.mutation<void, DeleteAttachmentPayload>({
      query: payload => ({
        url: `Attachment/DeleteAttachment?${serializeURLParams(payload)}`,
        method: 'POST',
      }),
      invalidatesTags: ['attachment'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAssetAttachmentsQuery,
  useUploadAttachmentMutation,
  useUpdateAttachmentMutation,
  useDeleteAttachmentMutation,
} = attachmentsEndpoints
