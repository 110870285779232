import AssetDisposalForm from '@components/asset/AssetDisposal/AssetDisposalForm'
import View from '@components/View'

const AssetDisposalView = () => {
  return (
    <View title="Asset Disposal">
      <AssetDisposalForm />
    </View>
  )
}

export default AssetDisposalView
