import React, { useState } from 'react'
import { InputProps } from './Input.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import InputIcon from './InputIcon'
import PasswordVisibilityButton from './PasswordVisibilityButton'
import Icon from '../Icon'

const BaseInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      label,
      size = 'default',
      error = false,
      helper,
      errorMsg,
      disabled,
      icon,
      iconRight,
      width = '100%',
      flexGrow = false,
      appearance = 'default',
      ...props
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false)
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true)
    }

    // styling
    const [css] = useStyletron()
    let containerStyles = css({
      ...styles.container({ width, flexGrow }),
    })

    let labelStyles = {
      ...styles.label({ disabled, label }),
    }
    let labelClassName = css(labelStyles)

    let inputWrapperStyles = css({
      ...styles.inputWrapperBase,
      ...styles.inputWrapperDisabled({ disabled, appearance }),
      ...styles.getSize({ size, appearance, select: false }),
      ...styles.getFocus({ focus, error }),
    })

    let inputStyles = css({
      ...styles.inputBase,
      ...styles.inputBaseDisabled({ disabled }),
    })
    if (props.readOnly) inputStyles += ' ' + css(styles.getReadOnly)
    let helperStyles = css({
      ...styles.helperText({ disabled }),
    })

    let errorMsgClassName = css(styles.errorMsg)
    let errorIconStyles = css(styles.errorMsgIcon)

    let tabIndex
    if (props.readOnly) tabIndex = -1

    // markup
    return (
      <div className={containerStyles}>
        <label htmlFor={props.name} className={labelClassName} id={`${props.name}-label`}>
          {label
            ? `${props.required ? '*' : ''}${label}`
            : props.placeholder
            ? props.placeholder
            : `Enter value for ${props.name}`}
        </label>
        <div className={inputWrapperStyles}>
          {icon && <InputIcon size={size} icon={icon} position="left" />}

          <input
            className={inputStyles}
            onFocus={() => setFocus(true)}
            onBlur={e => {
              setFocus(false)
              props.onBlur && props.onBlur(e)
            }}
            type={type === 'password' ? (passwordShown ? 'text' : 'password') : type}
            id={props.id ? props.id : `${props.name}_field`}
            onChange={props.onChange}
            value={props.value}
            disabled={disabled}
            maxLength={props.maxLength}
            minLength={props.minLength}
            autoFocus={props.autoFocus}
            required={props.required}
            onKeyPress={props.onKeyPress}
            onKeyDown={props.onKeyDown}
            onKeyUp={props.onKeyUp}
            step={type === 'number' ? props.step : undefined}
            min={type === 'number' ? props.min : undefined}
            max={type === 'number' ? props.max : undefined}
            pattern={props.pattern}
            readOnly={props.readOnly}
            tabIndex={tabIndex}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            autoComplete={props.autocomplete}
            aria-labelledby={
              props['aria-labelledby'] ? props['aria-labelledby'] : `${props.name}-label`
            }
            ref={ref}
            name={props.name ? props.name : props.id}
          />
          {iconRight && type !== 'password' && (
            <InputIcon size={size} icon={iconRight} position="right" />
          )}
          {type === 'password' && (
            <PasswordVisibilityButton
              size={size}
              onClick={togglePasswordVisiblity}
              isVisible={passwordShown}
            />
          )}
        </div>
        {helper && !error && <div className={helperStyles}>{helper}</div>}
        {errorMsg && error && (
          <div className={errorMsgClassName}>
            <Icon name="AlertCircle" className={errorIconStyles} /> {errorMsg}
          </div>
        )}
      </div>
    )
  }
)
export default BaseInput
