import { AccountFieldParm, SegmentType } from '@api/models'
import usePermission from '@components/Auth/Permissions/usePermission'
import { Button, Card, Flexbox, Form, Grid, Note, Select, Spacer } from '@components/common'
import { ACCOUNT_FORM_ID } from '@constants'
import useFormFieldProps from '@hooks/useFormField'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface AccountFieldPrefsFormProps {
  selectSegmentTypeOptions: SegmentType[]
  Accounts: string
  isGettingSettings: boolean
  onSubmit: (values: AccountFieldParm) => Promise<void>
}

const AccountFieldPrefsForm = ({
  selectSegmentTypeOptions,
  onSubmit,
  Accounts,
  isGettingSettings,
}: AccountFieldPrefsFormProps) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    control,
    setValue,
  } = useForm<AccountFieldParm>({
    mode: 'onBlur',
  })

  const { canEditSettings: canEdit } = usePermission()

  useEffect(() => {
    getAccountDefaultValues()
  }, [])

  function getAccountDefaultValues() {
    const AccountList = Accounts;
    const output = [];
    const sNumber = AccountList?.toString() ?? '';

    for (let i = 0, len = sNumber.length; i < len; i++) {
      output.push(+sNumber.charAt(i));
    }

    setValue('OPTION1', output[0]);
    setValue('OPTION2', output[1]);
    setValue('OPTION3', output[2]);
  }

  const { getSelectProps } = useFormFieldProps<AccountFieldParm>({
    fields: {},
    errors,
    isSubmitting,
    register,
  })
  if (isGettingSettings) {
    return <p>Loading..</p>
  }
  return (
    <Form id={ACCOUNT_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Item m={6}>
          <Card>
            <Note
              intent="default"
              content={
                <>
                  Select which segments to be used as account segments for ASSET TRANSFERS.
                </>
              }
            />
            <Spacer y="default" />
            <Flexbox>
              <Controller
                control={control}
                name="OPTION1"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('OPTION1', field, selectSegmentTypeOptions)}
                    width="260px"
                    disabled={!canEdit}
                  />
                )}
              />
            </Flexbox>
            <Spacer y="mini" />
            <Flexbox>
              <Controller
                control={control}
                name="OPTION2"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('OPTION2', field, selectSegmentTypeOptions)}
                    width="260px"
                    disabled={!canEdit}
                  />
                )}
              />
            </Flexbox>
            <Spacer y="mini" />
            <Flexbox>
              <Controller
                control={control}
                name="OPTION3"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('OPTION3', field, selectSegmentTypeOptions)}
                    width="260px"
                    disabled={!canEdit}
                  />
                )}
              />
            </Flexbox>
            <Spacer y="mini" />
            <Flexbox>
              <Button type="submit" appearance="primary" hidden={!canEdit}>
                Submit
              </Button>
            </Flexbox>
          </Card>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default AccountFieldPrefsForm
