import View from '@components/layout/View'
import { ProfilePrefs } from '@components/settings/profile'

const ProfileView = () => {
  return (
    <View title="Profile" showHeader={false}>
      <ProfilePrefs />
    </View>
  )
}

export default ProfileView
