export type UserRoleGrant = UserRole[]

export interface IUserGrantProps {
  grants?: UserRoleGrant
  canEdit?: boolean
}

export enum UserRole {
  ADMIN = 'Admin', // MRS Admin
  GENERAL_USER = 'General User', // MRS General User => this will impersonate a READ_ONLY user on the front-end
  FIXED_ASSETS_GENERAL_USER = 'Fixed Assets General User', // Organization General User
  FIXED_ASSETS_READ_ONLY = 'Fixed Assets Read Only User', // Organization Read Only User
  FIXED_ASSETS_ADMIN = 'Fixed Assets Admin', // Organization Admin
}

export const CAN_VIEW_ASSETS: UserRoleGrant = [
  UserRole.ADMIN,
  UserRole.GENERAL_USER,
  UserRole.FIXED_ASSETS_ADMIN,
  UserRole.FIXED_ASSETS_GENERAL_USER,
  UserRole.FIXED_ASSETS_READ_ONLY,
]

export const CAN_EDIT_ASSETS: UserRoleGrant = [
  UserRole.ADMIN,
  UserRole.FIXED_ASSETS_GENERAL_USER,
  UserRole.FIXED_ASSETS_ADMIN,
]

export const CAN_EDIT_COMPANIES: UserRoleGrant = [
  UserRole.ADMIN,
  UserRole.FIXED_ASSETS_ADMIN,
]
export const CAN_EDIT_SETTINGS: UserRoleGrant = [
  UserRole.ADMIN,
  UserRole.FIXED_ASSETS_ADMIN,
]

export const CAN_ACCESS_ADMIN_PORTAL: UserRoleGrant = [UserRole.ADMIN]
export const CAN_ACCESS_FIXED_ASSETS_ADMIN_PORTAL: UserRoleGrant = [
  UserRole.FIXED_ASSETS_ADMIN,
  UserRole.ADMIN
]

export const CAN_RUN_DEPRECIATION: UserRoleGrant = [
  UserRole.ADMIN,
  UserRole.FIXED_ASSETS_ADMIN,
  UserRole.FIXED_ASSETS_GENERAL_USER,
]

export const CAN_RUN_REPORTS: UserRoleGrant = [
  UserRole.ADMIN,
  UserRole.GENERAL_USER,
  UserRole.FIXED_ASSETS_ADMIN,
  UserRole.FIXED_ASSETS_GENERAL_USER,
  UserRole.FIXED_ASSETS_READ_ONLY,
]
