import { Spinner } from '@components/common'
import { StyleObject } from '@styles-api/css.types'
import { useStyletron } from 'styletron-react'

const wrapperStyles: StyleObject = {
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const FilterLoading = () => {
  const [css] = useStyletron()

  return (
    <div className={css(wrapperStyles)}>
      <Spinner />
    </div>
  )
}
export default FilterLoading
