import { Form, Modal } from '@components/common'
import { DELETE_DEPRECIATION_CLASS_FORM_ID } from '@constants'
import { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import { FormSubmitEvent } from 'types'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  handleSubmit: (e: FormSubmitEvent) => Promise<void>
  isSubmitting: boolean
}

const DeprecDeleteModal = ({ isOpen, setIsOpen, handleSubmit, isSubmitting }: Props) => {
  const params = useParams()

  return (
    <Modal
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      form={DELETE_DEPRECIATION_CLASS_FORM_ID}
      isActionLoading={isSubmitting}
      intent="danger"
      title={`Delete depreciation profile ${params.id}`}
      actionLabel={`Delete ${params.id}`}
    >
      <Form onSubmit={handleSubmit} id={DELETE_DEPRECIATION_CLASS_FORM_ID}>
        Are you sure you want to delete delete depreciation profile <b>{params.id}</b> ?
      </Form>
    </Modal>
  )
}

export default DeprecDeleteModal
