import { Link } from 'react-router-dom'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

import navLogo from '../../../../assets/images/navLogo.png'

const Brand = () => {
  const [css] = useStyletron()
  return (
    <Link to="/" className={css(styles.brandLink)}>
      <img src={navLogo} className={css(styles.brandIcon)} alt="WorthIT Icon" />
      <div className={css(styles.brandWorthIT)}>WorthIT</div>
      <div className={css(styles.brandFA)}>Fixed Assets</div>
    </Link>
  )
}
export default Brand
