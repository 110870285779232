import { useModal } from '@components/common'
import { useDepUndoInfoQuery, useDepUndoMutation } from '@api'
import { DepRunInput } from '@api/models'
import { useFields } from '@hooks'
import { useEffect } from 'react'

const useDepUndoHook = (assetCompanyID: number) => {
  const { fields } = useFields()

  const {
    modalIsOpen: isDepUndoFormOpen,
    triggerModal: openDepUndoForm,
    closeModal: closeDepUndoForm,
  } = useModal()

  const {
    data: depUndoInfo,
    isLoading: isLoadingGetDepUndoInfo,
    isSuccess: isSuccessGetDepUndoInfo,
    isError: isErrorGetDepUndoInfo,
    error: errorGetDepUndoInfo,
  } = useDepUndoInfoQuery(assetCompanyID, {
    refetchOnMountOrArgChange: true,
    skip: !isDepUndoFormOpen,
  })

  const [
    depUndoMutation,
    {
      isLoading: isDepreciationUndoing,
      isError: isErrorDepUndo,
      isSuccess: isSuccessDepUndo,
    },
  ] = useDepUndoMutation()

  useEffect(() => {
    if (isErrorGetDepUndoInfo) {
      closeDepUndoForm()
    }
  }, [closeDepUndoForm, isErrorGetDepUndoInfo])

  async function undoDepreciation(formData: DepRunInput) {
    closeDepUndoForm()
    await depUndoMutation(formData)
  }

  return {
    isSuccessDepUndo,
    isErrorDepUndo,
    isErrorGetDepUndoInfo,
    errorGetDepUndoInfo: (errorGetDepUndoInfo as any)?.data,
    isDepUndoFormOpen,
    openDepUndoForm,
    closeDepUndoForm,
    depUndoInfo,
    isLoadingGetDepUndoInfo,
    isSuccessGetDepUndoInfo,
    undoDepreciation,
    isDepreciationUndoing,
    fields,
  }
}

export default useDepUndoHook
