import { SKELETON_SHINE } from '@styles-api/css-constants'
import { StyleObject } from 'styletron-react'

export const headingStyles: StyleObject = {
  display: 'flex',
  gap: '10px',
  marginBottom: '2.5rem',
}

export const totalAmount: StyleObject = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem',
  gap: '0.5rem',
}

export const skeleton = ({ width, height }) => {
  return {
    ...SKELETON_SHINE,
    display: 'inline-block',
    height: height ? height : 'var(--scale400)',
    width: width,
    borderRadius: 'var(--radius1)',
  }
}
