import { DepreciationProfileCode } from '@api/models'
import CustomDepreciationWrapper from '@components/depreciation-class/CustomDepreciation'
import View from '@components/View'
import { useParams } from 'react-router-dom'

interface GlobalCustomDepreciationViewProps {
  profile: DepreciationProfileCode
}

const GlobalCustomDepreciationView = ({ profile }: GlobalCustomDepreciationViewProps) => {
  const params = useParams()
  return (
    <View
      title={`${profile === 'A' ? 'Category' : 'Tax Class'} ${
        params.id
      } - Custom Depreciation`}
    >
      <CustomDepreciationWrapper
        params={{ assetCompanyID: 0, profile, objectID: params.id }}
        readonly={false}
      />
    </View>
  )
}

export default GlobalCustomDepreciationView
