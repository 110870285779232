import { FieldLabels } from '@api/models'
import { InputType } from '@components/common/Input/Input.types'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { isNullOrUndefined } from '@utils/basic'
import { useCallback } from 'react'
import { FieldError } from 'react-hook-form'

interface FormFieldProps<T> {
  isSubmitting: boolean
  // errors: { [key in keyof T]?: FieldError }
  errors?: {
    [key in keyof T]?: FieldError
  }
  fields: FieldLabels
  register
}

// TODO: add return types
interface FormFieldReturn<T> {
  getInputProps: (name: keyof T, type?: CustomInputType) => any
  getSelectProps: (name: keyof T, field: any, options: ReactSelectOptions) => any
  getCreatableSelectProps: (name: keyof T, field: any, options: ReactSelectOptions) => any
  getCheckBoxProps: (field: any) => any
  getRadioProps: (field: any) => any
  getDateProps: (name: keyof T, field: any) => any
  getMultiSelectProps: (name: keyof T, field: any, options: ReactSelectOptions) => any
}

type CustomInputType = 'text' | 'number' | 'currency' | 'percentage' | 'date'

function useFormFieldProps<T>({
  isSubmitting,
  errors,
  fields,
  register,
}: FormFieldProps<T>): FormFieldReturn<T> {
  const getInputProps = useCallback(
    (name: keyof T, type?: CustomInputType) => {
      let nativeInputType: InputType = 'text'

      switch (type) {
        case 'currency':
        case 'percentage':
        case 'number':
          nativeInputType = 'number'
          break
        case undefined:
        case 'text':
          nativeInputType = 'text'
          break
        case 'date':
          nativeInputType = 'date'
          break
        default:
          nativeInputType = 'text'
      }

      let icon: string = undefined
      if (type === 'currency') {
        icon = '$'
      }
      let iconRight: string = undefined
      if (type === 'percentage') {
        iconRight = '%'
      }

      return {
        ...register(name, {
          valueAsNumber: type === 'number' || type === 'currency' || type === 'percentage',
        }),
        disabled: isSubmitting,
        error: errors[name] !== undefined,
        errorMsg: errors[name]?.message,
        label: `${fields[name as any]}`,
        type: nativeInputType,
        icon,
        iconRight,
      }
    },
    [register, isSubmitting, errors, fields]
  )

  const getSelectProps = useCallback(
    (name: keyof T, field: any, options: ReactSelectOptions) => {
      const opt = options ? options : []
      const optionsWithBlank = [{ label: '', value: '' }, ...opt ]
      const value = optionsWithBlank.find(c => field.value === c.value)
      return {
        label: fields[name as any],
        options: optionsWithBlank,
        onBlur: field.onBlur,
        onChange: val => field.onChange(val?.value),
        value: value ? value : optionsWithBlank[0],
        ref: field.ref,
        error: errors[name] !== undefined,
        errorMsg: errors[name]?.message,
        disabled: isSubmitting,
        type: 'default',
      }
    },
    [isSubmitting, errors, fields]
  )

  const getCreatableSelectProps = useCallback(
    (name: keyof T, field: any, options: ReactSelectOptions) => {
      let optionsWithBlank = [{ label: '', value: '' }, ...options]

      // adds option to select if it doesn't exist
      if (
        !optionsWithBlank.some(c => c.value === field.value) &&
        !isNullOrUndefined(field.value)
      ) {
        optionsWithBlank = [...optionsWithBlank, { label: field.value, value: field.value }]
      }

      // sorts options alphabetically
      optionsWithBlank.sort((a, b) => {
        if (a.value === '') return -1
        if (b.value === '') return 1
        return a.value.localeCompare(b.value)
      })

      return {
        label: fields[name as any],
        options: optionsWithBlank,
        onBlur: field.onBlur,
        onChange: value => field.onChange(value || ''),
        value: field.value,
        ref: field.ref,
        error: errors[name] !== undefined,
        errorMsg: errors[name]?.message,
        disabled: isSubmitting,
        type: 'creatable',
      }
    },
    [isSubmitting, errors, fields]
  )

  const getMultiSelectProps = useCallback(
    (name: keyof T, field: any, options: ReactSelectOptions) => {
      return {
        label: fields[name as any],
        options: options,
        onBlur: field.onBlur,
        onChange: value => field.onChange(value || ''),
        value: field.value,
        ref: field.ref,
        error: errors[name] !== undefined,
        errorMsg: errors[name]?.message,
        disabled: isSubmitting,
        type: 'multi',
      }
    },
    [isSubmitting, errors, fields]
  )

  const getCheckBoxProps = useCallback(
    (field: any) => ({
      label: fields[field.name],
      checked: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }),
    [isSubmitting, fields]
  )

  const getRadioProps = useCallback(
    (field: any) => ({
      name: field.name,
      select: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }),
    [isSubmitting]
  )

  const getDateProps = useCallback(
    (name: keyof T, field: any) => ({
      label: field[name as any],
      name: field.name,
      select: field.value,
      onChange: field.onChange,
      onBlur: field.onBlur, //fix
      disabled: isSubmitting,
      error: errors[name] !== undefined,
      errorMsg: errors[name]?.message,
      ref: field.ref,
      value: field.value,
    }),
    [errors, isSubmitting]
  )

  return {
    getInputProps,
    getSelectProps,
    getCreatableSelectProps,
    getCheckBoxProps,
    getRadioProps,
    getMultiSelectProps,
    getDateProps,
  }
}

export default useFormFieldProps
