import { DepreciationProfileCode, FetchCustomDepreciationParams } from '@api/models'
import { Flexbox, Heading, Button, Icon, Divider } from '@components/common'
import CustomDepreciationWrapper from '@components/depreciation-class/CustomDepreciation'
import { useGetAssetDepreciationQuery } from '@api'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const AssetCustomDeprecWrapper = () => {
  const urlParams = useParams()

  const { data: depreciation, isLoading } = useGetAssetDepreciationQuery({
    assetCompanyID: parseInt(urlParams.companyID),
    assetID: urlParams.assetID,
    profile: urlParams.profile as DepreciationProfileCode,
  })

  const [
    customDepFetchParams,
    setCustomDepFetchParams,
  ] = useState<FetchCustomDepreciationParams>()

  useEffect(() => {
    if (!isLoading) {
      setCustomDepFetchParams({
        assetCompanyID: depreciation.AssetCompanyID,
        profile: depreciation.DP_TYP_CD as DepreciationProfileCode,
        objectID: depreciation.DP_OBJ_ID,
      })
    }
  }, [urlParams, depreciation, isLoading])

  if (isLoading) return <div>Loading...</div>
  if (customDepFetchParams === undefined) return <></>

  return (
    <>
      <Flexbox alignItems="center" justifyContent="space-between">
        <div>
          <Heading type="h2">Custom Depreciation</Heading>
        </div>
        <Button link=".." iconRight={<Icon name="ArrowLeft" />}>
          Back to Profile
        </Button>
      </Flexbox>
      <Divider spacing="default" />
      <CustomDepreciationWrapper
        params={customDepFetchParams}
        readonly={depreciation.AssetCompanyID === 0}
      />
    </>
  )
}

export default AssetCustomDeprecWrapper
