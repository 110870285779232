import { Button, Icon } from '@components/common'
import { useStyletron } from 'styletron-react'
import * as styles from './styles'
import useReportsNavigation from './useReportsNavigation'
import { reportsList } from './reportsList'
import { useNavigate } from 'react-router-dom'
import { useRetrieveReports } from '../General/RetrieveReports/useRetrieveReports'
import RetrieveReport from '../General/RetrieveReports/RetrieveReport'

const ReportsNavigation = ({
  showMenu,
  setShowMenu,
  selectedCategoryIndex,
  setSelectedCategoryIndex,
}: {
  showMenu?: Boolean
  setShowMenu?: Function
  selectedCategoryIndex?: Number
  setSelectedCategoryIndex?: Function
}) => {
  const [css] = useStyletron()
  const navigate = useNavigate()

  const {
    isRetrieveTemplateModalOpen,
    handleRetrieveTemplateSubmit,
    closeRetrieveTemplateModal,
    triggerRetrieveTemplateModal,
  } = useRetrieveReports({ setShowMenu, setSelectedCategoryIndex })

  const {
    handleOpenCategory,
    openCategoryIndex,
    handleTriggerRetrieveTemplateModal,
  } = useReportsNavigation({
    setShowMenu,
    triggerRetrieveTemplateModal,
    showMenu,
  })

  const selectCategoryHandler = (index: number) => {
    return () => {
      handleOpenCategory(index)
      setSelectedCategoryIndex(index)

      if (!showMenu) {
        setShowMenu(true)
        navigate('/reports')
      }
    }
  }

  return (
    <div className={css(styles.container)}>
      {reportsList.map((item, index) => {
        const categoryIsOpen = openCategoryIndex === index
        const categoryIsSelected = selectedCategoryIndex === index

        return (
          <div key={index}>
            <button
              className={css(styles.categoryButton(categoryIsOpen, categoryIsSelected))}
              onClick={selectCategoryHandler(index)}
            >
              {item.category}
              {categoryIsSelected && (
                <Icon
                  name="ChevronRight"
                  className={css(styles.categoryButtonIcon(categoryIsOpen))}
                />
              )}
            </button>
          </div>
        )
      })}
      <Button
        onClick={handleTriggerRetrieveTemplateModal}
        style={{ width: '100%', marginTop: '10px' }}
        appearance="primary"
        size="default"
      >
        Retrieve Template
      </Button>

      <RetrieveReport
        isRetrieveTemplateModalOpen={isRetrieveTemplateModalOpen}
        closeRetrieveTemplateModal={closeRetrieveTemplateModal}
        handleRetrieveTemplateSubmit={handleRetrieveTemplateSubmit}
      />
    </div>
  )
}
export default ReportsNavigation
