import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export function getBaseQuery(controllerUrl: string) {
  return fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_AUTHORITY}/api/${controllerUrl}`,
    prepareHeaders: headers => {
      const token = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`
        )
      ).access_token

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      } else {
        alert('Please login again.')
      }

      return headers
    },
  })
}

export function textResponseHandler(res: Response) {
  return res.text()
}
