import {
  Grid,
  Modal,
  Text,
  Fieldset,
  Flexbox,
  Input,
  Spacer,
  Spinner,
  toast,
  Icon,
} from '@components/common'
import { formatDisplayDate } from '@utils/basic'
import { useGetSettingsQuery } from '@api'
import { useEffect } from 'react'
import useDepUpdateHook from './useDepUpdateHook'
import DepreciationCard from './DepreciationCard'

interface DepreciationUpdateFormProps {
  assetCompanyID: number
  isPeriodBased: boolean
}

const DepreciationUpdateForm = ({
  assetCompanyID,
  isPeriodBased,
}: DepreciationUpdateFormProps) => {
  const {
    isDepUpdateFormOpen,
    isLoadingGetDepUpdateInfo,
    isSuccessGetDepUpdateInfo,
    isDepreciationUpdating,
    isErrorDepUpdate,
    isErrorGetDepUpdateInfo,
    errorGetDepUpdateInfo,
    depUpdateInfo,
    fields,
    openDepUpdateForm,
    closeDepUpdateForm,
    updateDepreciation,
  } = useDepUpdateHook(assetCompanyID)

  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  const isUpdateDisabled = assetCompanyID === undefined || assetCompanyID === null
  const shouldShowDepUpdateForm = isDepUpdateFormOpen && isSuccessGetDepUpdateInfo

  useEffect(() => {
    if (isErrorGetDepUpdateInfo) {
      toast.warn(errorGetDepUpdateInfo)
    } else if (isErrorDepUpdate) {
      toast.error('Period update failed')
    }
  }, [isErrorGetDepUpdateInfo, errorGetDepUpdateInfo, isErrorDepUpdate])

  return (
    <>
      <Modal
        isOpen={isDepreciationUpdating}
        close={() => {}}
        title="Updating Period Depreciation"
      >
        <Flexbox justifyContent="center">
          <Spinner size="large" />
        </Flexbox>
      </Modal>

      <Modal
        isOpen={shouldShowDepUpdateForm}
        close={closeDepUpdateForm}
        onSubmit={updateDepreciation}
        title="Period Depreciation Update"
        actionLabel="Update"
      >
        {shouldShowDepUpdateForm && (
          <Grid>
            <Grid.Item>
              <Fieldset legend="Status" legendCenter>
                <Flexbox>
                  <Grid>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_COM_ID}
                        value={depUpdateInfo.AssetCompanyID}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_DESCS}
                        value={depUpdateInfo.ShortName}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label="Last Year Closed"
                        value={depUpdateInfo.LastYearClosed}
                        readOnly
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Parameters" legendCenter>
                <Flexbox>
                  <Grid>
                    {isPeriodBased ? (
                      <>
                        <Grid.Item m={4}>
                          <Input label="Year" value={depUpdateInfo.FiscalYear} readOnly />
                        </Grid.Item>
                        <Grid.Item m={4}>
                          <Input label="Period" value={depUpdateInfo.Period} readOnly />
                        </Grid.Item>
                      </>
                    ) : (
                      <Grid.Item m={6}>
                        <Input
                          label="As of Date"
                          value={formatDisplayDate(depUpdateInfo.AsOfDate, DateFormat)}
                          readOnly
                        />
                      </Grid.Item>
                    )}
                    <Grid.Item m={isPeriodBased ? 4 : 6}>
                      <Input
                        label="Posting Date"
                        value={formatDisplayDate(depUpdateInfo.PostDate, DateFormat)}
                        readOnly
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Remarks" legendCenter>
                <Grid>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>1.</Text>
                      <Spacer x="compact" />
                      <Input value={depUpdateInfo.Remark1} readOnly />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>2.</Text>
                      <Spacer x="compact" />
                      <Input value={depUpdateInfo.Remark2} readOnly />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>3.</Text>
                      <Spacer x="compact" />
                      <Input value={depUpdateInfo.Remark3} readOnly />
                    </Flexbox>
                  </Grid.Item>
                </Grid>
              </Fieldset>
            </Grid.Item>
          </Grid>
        )}
      </Modal>

      <DepreciationCard
        disabled={isUpdateDisabled}
        loading={isLoadingGetDepUpdateInfo}
        openForm={openDepUpdateForm}
        icon={<Icon name="CheckCircle" color="var(--primary-color500)" />}
        type="Update"
        helpText="Generate “Permanent” calculated depreciation records for reporting within the fiscal year (except the last period)."
      />
    </>
  )
}

export default DepreciationUpdateForm
