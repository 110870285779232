import { Heading, Spacer } from '@components/common'
import CompanyForm from '@components/company/CompanyForm'
import Template from '@components/layout/Template'
import View from '@components/View'
import { useSelector } from 'react-redux'

const CreateCompanyView = () => {
  const assetCompanyID = useSelector<any>(state => state.company) as number

  return (
    <View title="Add Company">
      <Template maxWidth="compact">
        <Heading type="h1">Add new company</Heading>
        <Spacer y="default" />

        <CompanyForm context="create-company" companyID={assetCompanyID} />
      </Template>
    </View>
  )
}

export default CreateCompanyView
