import { apiSlice } from '@api'
import { FetchPicklistsResponse } from './models'

export const picklistsEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    fetchPicklists: build.query<FetchPicklistsResponse, void>({
      query: () => `Picklist/FetchPicklists`,
    }),
  }),
  overrideExisting: false,
})

export const { useFetchPicklistsQuery } = picklistsEndpoints
