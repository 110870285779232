import { exactDigitMsg, formatISODate, isNullOrUndefined, maxDigitMsg } from '@utils/basic'
import { AnyObject } from 'yup/lib/types'
import { assetChargeSchema } from '@api/assets/models'
import * as Yup from 'yup'
import moment from 'moment'

export const currencyValidation = {
  test: (number: number) => number === null || String(number).length <= 13,
  message: maxDigitMsg(13),
}
export const digitLengthValidation = val => ({
  test: (number: number) => number === null || String(number).length <= val,
  message: maxDigitMsg(val),
})

export const exactLengthValidation = val => ({
  test: (number: number) => number === null || String(number).length === val,
  message: exactDigitMsg(val),
})

export const endDateValidation = (
  endDatePropertyName: string
): Yup.TestConfig<string, AnyObject> => ({
  test: (value, testContext) => {
    return (
      isNullOrUndefined(value) ||
      formatISODate(value) > formatISODate(testContext.parent[endDatePropertyName])
    )
  },
  message: 'End date must be greater than start date',
})

export const dateExistValidation = {
  test: value => {
    exactLengthValidation(10)
    return isNullOrUndefined(value) || value !== ''
  },
  message: 'Invalid date',
}

export const rateZeroValidation = (
  methodPropertyName: string
): Yup.TestConfig<number, AnyObject> => ({
  test: (value: number, testContext) => {
    if (
      (testContext.parent[methodPropertyName] === 'D' ||
        testContext.parent[methodPropertyName] === 'C') &&
      value <= 0
    ) {
      return false
    }

    return true
  },
  message:
    'If the depreciation method is non-linear, then the rate must be greater than zero.',
})

export const dateAfterOrEqualValidation = (
  datePropertyName: string
): Yup.TestConfig<string, AnyObject> => ({
  test: (value, testContext) => {
    const propertyDate = testContext.parent[datePropertyName]

    if (isNullOrUndefined(value) || value === '0001-01-01T00:00:00') return true

    const propertyDateObj = moment(propertyDate).startOf('day')
    const currentValueDateObj = moment(value).startOf('day')

    return currentValueDateObj.isSameOrAfter(propertyDateObj, 'day')
  },
  message: `The date must be on or after the Date in Service.`,
})

export type SchemaType = typeof assetChargeSchema
