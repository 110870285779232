import { useGetCompaniesQuery } from '@api'

interface ICompanyNameHook {
  activeCompanyName: string
}

interface CompanyNameHookProps {
  companyID: number
}

export default function useActiveCompanyName({ companyID }: CompanyNameHookProps): ICompanyNameHook {
  const {
    data: assetCompanies,
  } = useGetCompaniesQuery()


  return {
    activeCompanyName: assetCompanies?.find(company => company.AssetCompanyID === companyID)
      ?.CM_DESC
  }
}

