import { Scale } from '@styles-api/layout.types'
import { Palette } from '@styles-api/palette.types'
import { FontSize } from '@styles-api/typography.types'
import { StyleObject } from '@styles-api/css.types'

export const base: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition:
    'all 70ms cubic-bezier(0,0,.38,.9),box-shadow 70ms cubic-bezier(0,0,.38,.9),border-color 70ms cubic-bezier(0,0,.38,.9),outline 70ms cubic-bezier(0,0,.38,.9)',
  fontWeight: 'var(--font-weight-semiBold)',
  overflow: 'hidden',
  boxSizing: 'border-box',
  position: 'relative',
  borderRadius: 'var(--radius1)',
  borderWidth: '1px',
  width: '100%',
}

export const getSpacing = ({ size }) => {
  let height = `var(--size-${size})`
  let paddingX: Scale
  let fontSize: FontSize

  switch (size) {
    case 'mini':
      paddingX = 'var(--scale500)'
      fontSize = 'var(--small-font-size)'
      break
    case 'compact':
      paddingX = 'var(--scale500)'
      fontSize = 'var(--base-font-size)'
      break
    case 'default':
      paddingX = 'var(--scale500)'
      fontSize = 'var(--base-font-size)'
      break
    case 'large':
      paddingX = 'var(--scale750)'
      fontSize = 'var(--large-font-size)'
      break
  }

  return {
    height,
    paddingLeft: paddingX,
    paddingRight: `calc(${paddingX} * 2)`,
    fontSize,
    cursor: 'pointer',
    background: 'none',
    appearance: 'none',
  }
}
export const getFocus = ({ focus, error }) => {
  if (!error)
    return {
      boxShadow: focus ? 'var(--focus-shadow)' : 'var(--default-btn-shadow)',
      borderColor: focus ? 'var(--primary-color)' : 'var(--btn-border-color)',
      outline: focus ? 'var(--focus-outline)' : 'none',
      backgroundColor: focus ? 'var(--base-color)' : 'var(--default-btn-bg)',
    }
  else if (error === true && focus === false) {
    return {
      outline: '2px solid var(--danger-color)',
      borderColor: 'var(--danger-color)',
    }
  }
}
export const selectIcon = ({ size, appearance }) => {
  let spacingRight

  if (appearance !== 'clear') {
    switch (size) {
      case 'mini':
        spacingRight = 'var(--scale400)'
        break
      case 'compact':
        spacingRight = 'var(--scale400)'
        break
      case 'default':
        spacingRight = 'var(--scale400)'
        break
      case 'large':
        spacingRight = 'var(--scale450)'
        break
    }
  } else spacingRight = '0'
  return {
    height: '100%',
    top: 0,
    position: 'absolute',
    right: spacingRight,
    width: 'var(--scale550)',
    pointerEvents: 'none',
  }
}

// autocomplete & clearable styles
export const control = (provided, state, disabled, error, type) => {
  let cursor
  if (type === 'default') cursor = 'pointer'
  else cursor = 'text'
  return {
    ...provided,
    pointerEvents: disabled ? 'none' : 'all',

    backgroundColor: error
      ? 'var(--danger-color50)'
      : type === 'default'
      ? 'var(--default-btn-bg)'
      : 'var(--base-color)',

    boxShadow: state.isFocused
      ? 'var(--focus-shadow)'
      : type === 'default'
      ? 'var(--default-btn-shadow)'
      : 'var(--input-inset-shadow)',
    position: 'relative',
    cursor: disabled ? 'not-allowed' : cursor,
    marginTop: state.value === '' ? '100px' : '0px',
    borderColor: state.isFocused
      ? 'var(--primary-color)'
      : error
      ? 'var(--danger-color)'
      : 'var(--btn-border-color)',
    borderWidth: '1px',
    borderRadius: 'var(--radius1)',
    display: 'flex',
    height: 'var(--size-default)',
    minHeight: 'auto',
    '&:hover': {
      borderColor: state.isFocused
        ? 'var(--primary-color)'
        : error
        ? 'var(--danger-color)'
        : 'var(--btn-border-color)',
    },
    transition: 'none',
    opacity: disabled ? '0.7' : '1',
  }
}
export const valueContainer = provided => {
  return {
    ...provided,
    height: '100%',
    paddingRight: 'var(--scale500)',
    paddingLeft: 'var(--scale500)',
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    flexWrap: 'wrap',
  }
}
export const indicatorsContainer: StyleObject = {
  height: '100%',
}
export const menu = (provided, state): StyleObject => {
  return {
    ...provided,
    boxShadow: 'var(--shadow2)',
    position: 'absolute',
    zIndex: 'var(--z2)',
    width: '100%',
    backgroundColor: 'var(--base-color)',
    borderRadius: 'var(--radius1)',
    marginTop: 'var(--scale200)',
    paddingTop: 'var(--scale200)',
    paddingBottom: 'var(--scale200)',
    borderWidth: '1px',
  }
}
export const option = (provided, state) => {
  let backgroundColor: Palette
  let color: Palette
  let backgroundColorHover: Palette

  if (!state.isSelected) {
    switch (true) {
      case state.isFocused:
        backgroundColor = 'var(--shade100)'
        color = 'var(--text-color)'
        backgroundColorHover = 'var(--shade100)'
        break
      case !state.isFocused:
        backgroundColor = 'var(--base-color)'
        color = 'var(--text-color)'
        backgroundColorHover = 'var(--shade100)'
    }
  } else if (state.isSelected) {
    switch (true) {
      case state.isFocused:
        backgroundColor = 'var(--primary-color500)'
        color = 'var(--base-color)'
        backgroundColorHover = 'var(--primary-color500)'
        break
      case !state.isFocused:
        backgroundColor = 'var(--primary-color)'
        color = 'var(--base-color)'
        backgroundColorHover = 'var(--primary-color500)'
        break
    }
  }

  return {
    ...provided,
    backgroundColor,
    color,
    borderBottom: '1px solid var(--border-color)',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 'var(--scale600)',
    paddingLeft: 'var(--scale600)',
    height: 'var(--size-default)',
    cursor: 'pointer',
    '&:nth-of-type(1)': {
      borderTop: '1px solid var(--border-color)',
    },
    ':hover': {
      backgroundColor: backgroundColorHover,
    },
  }
}

export const clearIcon: StyleObject = {
  height: 'var(--scale500)',
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    color: 'red',
    cursor: 'pointer',
  },
}

export const dropdownIndicator: StyleObject = {
  height: 'var(--scale500)',
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    color: 'blue',
    cursor: 'pointer',
  },
}

export const selectContainerStyles: StyleObject = {
  backgroundColor: 'var(--default-btn-bg)',
  boxShadow: 'var(--default-btn-shadow)',
  position: 'relative',
  borderColor: 'var(--btn-border-color)',
  borderWidth: '1px',
  borderRadius: 'var(--radius1)',
  display: 'flex',
  height: 'var(--size-default)',
  border: '5px solid red',
}
