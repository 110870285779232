import { createSlice } from '@reduxjs/toolkit'

const assetRegisterFilterSlice = createSlice({
  name: 'assetRegisterFilters',
  initialState: {},
  reducers: {
    setAssetRegisterFilter: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    clearAssetRegisterFiltersButKeepSort: (state: {
      sort?: [{ fields: string; dir: string }]
    }) => {
      // if I do have a sort, I want to keep it
      if (state.sort) {
        const { sort } = state
        return {
          sort,
        }
      }
      return {}
    },
    setAssetRegisterSort: (state, action) => {
      const { field, dir } = action.payload
      return {
        ...state,
        sort: [
          {
            field,
            dir,
          },
        ],
      }
    },
    clearAssetRegisterSort: (state: { sort?: [{ fiels: string; dir: string }] }) => {
      const { sort, ...rest } = state
      return rest
    },
  },
})

export const {
  setAssetRegisterFilter,
  clearAssetRegisterFiltersButKeepSort,
  setAssetRegisterSort,
  clearAssetRegisterSort,
} = assetRegisterFilterSlice.actions

export default assetRegisterFilterSlice.reducer
