import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ASSETS_PATH, ASSET_PATHS, APP_TITLE, APP_TITLE_DIVIDER } from '@constants'
import { useGetCompanyQuery } from '@api'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import Template from '@components/layout/Template'
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'

const AssetTemplate = () => {
  const params = useParams()

  const { data: asset, isLoading: isLoadingAsset } = useGetAssetQuery({
    assetCompanyID: parseInt(params.companyID),
    assetID: params.assetID,
  })

  const { isLoading: isLoadingCompany } = useGetCompanyQuery(parseInt(params.companyID))

  const assetViewPath = `/${ASSETS_PATH}/${params.companyID}/${params.assetID}`
  const navItems = [
    {
      label: 'Main',
      viewPath: `${assetViewPath}/${ASSET_PATHS.DETAILS}`,
    },

    {
      label: 'Depreciation Profiles',
      viewPath: `${assetViewPath}/${ASSET_PATHS.DEPRECIATION}`,
    },
    {
      label: 'History',
      viewPath: `${assetViewPath}/${ASSET_PATHS.HISTORY}?profile=C`,
    },
    {
      label: 'Structure Transfer',
      viewPath: `${assetViewPath}/${ASSET_PATHS.STRUCTURE}`,
    },
    {
      label: 'Location',
      viewPath: `${assetViewPath}/${ASSET_PATHS.LOCATION}`,
    },
    {
      label: 'Service',
      viewPath: `${assetViewPath}/${ASSET_PATHS.SERVICE}`,
    },
    {
      label: 'Tasks/Activity',
      viewPath: `${assetViewPath}/${ASSET_PATHS.TASKS}`,
    },
    {
      label: 'Partial Disposals',
      viewPath: `${assetViewPath}/${ASSET_PATHS.PARTIAL_DISPOSALS}`,
    },
    {
      label: 'Attachments',
      viewPath: `${assetViewPath}/${ASSET_PATHS.ATTACHMENTS}`,
    },
  ]

  const location = useLocation()
  const navigate = useNavigate()

  // Detect location changes when on template --> use this to ensure
  // back button sends user back to previous page before landing on asset (e.g. assets table)
  const [locationChangeCount, setLocationChangeCount] = useState<number>(0)

  useEffect(
    function updateLocationChangeCount() {
      setLocationChangeCount(locationChangeCount + 1)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  )

  if (isLoadingAsset || isLoadingCompany) return <p>Loading...</p>

  return (
    <>
      <Helmet
        titleTemplate={`${APP_TITLE} ${APP_TITLE_DIVIDER} Assets ${APP_TITLE_DIVIDER} ${asset.AS_DESC} ${APP_TITLE_DIVIDER}  %s `}
      />

      <Template
        localNav={navItems}
        title={`Asset ${params.assetID}: ${asset.AS_DESC}`}
        maxWidth="medium"
        hasBackButton
        backNavigateFunction={() => navigate(-locationChangeCount)}
        // breadcrumbs={[
        //   {
        //     label: `Assets`,
        //     path: `/${ASSETS_PATH}?assetCompanyID=${params.companyID}&itemsPerPage=20&pageNumber=1`,
        //   },
        //   {
        //     label: asset.AS_DESC,
        //     isActive: true,
        //   },
        // ]}
      >
        <Outlet />
      </Template>
    </>
  )
}

export default AssetTemplate
