import { Asset, FieldLabels } from '@api/models'
import { Card } from '@components/common'
import DataDisplayList from '@components/DataDisplayList'
import { formatDisplayDate } from '@utils/basic'
import { useGetSettingsQuery } from '@api'
import { useFormContext } from 'react-hook-form'
import AssetAccessLoading from './AssetAccessLoading'
interface Props {
  fields: FieldLabels
  isLoadingInfo: boolean
}

const AssetAccess = ({ fields, isLoadingInfo }: Props) => {
  const { watch } = useFormContext<Asset>()
  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  const items = [
    {
      label: fields.AS_ADD_DAT,
      value: formatDisplayDate(watch('AS_ADD_DAT'), DateFormat),
    },
    {
      label: fields.AS_ADD_USR,
      value: watch('AS_ADD_USR'),
    },
    {
      label: fields.AS_CHG_DAT,
      value: formatDisplayDate(watch('AS_CHG_DAT'), DateFormat),
    },
    {
      label: fields.AS_CHG_USR,
      value: watch('AS_CHG_USR'),
    },
  ]

  return (
    <Card title="Access">
      {isLoadingInfo && <AssetAccessLoading />}

      {!isLoadingInfo && <DataDisplayList items={items} columns={2} />}
    </Card>
  )
}

export default AssetAccess
