import { Card, Spacer, Table, TextSkeleton } from '@components/common'
import NoteSkeleton from '@components/common/Note/NoteSkeleton'
import TableRowSkeleton from '@components/common/Table/TableRowSkeleton'

const AssetBookInformationDisplayLoading: React.FC = () => {
  return (
    <>
      <TableRowSkeleton height="26px" rows={3} />
      <Spacer y="default" />
      <NoteSkeleton height="var(--scale1500)" size="large" />
    </>
  )
}

export default AssetBookInformationDisplayLoading
