import { ImportColumn } from "../importTypes";

export const folders: ImportColumn[] = [
    {
        label: 'Folder ID',
        value: 'FL_FOLD_ID'
    },
    {
        label: 'Tab Name',
        value: 'FL_TAB_NM'
    },
    {
        label: 'Item',
        value: 'FL_TAB_VL'
    },
    {
        label: 'System Number',
        value: 'FL_SYS_NR'
    }
]