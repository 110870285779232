import { Button, Flexbox, Heading, Modal, Spacer } from '@components/common'
import { TASK_FORM_ID } from '@constants'

import TaskForm from './TaskForm'
import TasksTable from './TasksTable'
import useTasks from './useTasks'
import usePermission from '@components/Auth/Permissions/usePermission'
import ImportData from '@components/ImportData'
import { download } from '@utils/basic'

const Tasks = () => {
  const {
    createTaskModalIsOpen,
    openCreateTaskModal,
    handleCreateTask,
    closeCreateTaskModal,
    selectedTask,
    isLoadingCreateTask,
  } = useTasks()
  const { canEditSettings: canEdit } = usePermission()

  const importButton = (
    <Button
      onClick={() => download(`${process.env.PUBLIC_URL}/templates/tasks.csv`, 'tasks.csv')}
      appearance="text"
    >
      Download an import template here.
    </Button>
  )

  return (
    <>
      <header>
        <Flexbox justifyContent="space-between">
          <Heading type="h1">Tasks/Activities</Heading>
          <Flexbox alignItems='center' flexDirection='row' gap={12}>
            <ImportData action='tasks' title='Import Tasks' modalDesc={importButton} />
            <Button onClick={openCreateTaskModal} appearance="primary" hidden={!canEdit}>
              Add new task
            </Button>
          </Flexbox>
        </Flexbox>
      </header>

      <Spacer y="default" />

      <TasksTable {...useTasks()} canEdit={canEdit} />

      <Modal
        isOpen={createTaskModalIsOpen}
        title={`Add a new task`}
        form={TASK_FORM_ID}
        close={closeCreateTaskModal}
        actionLabel={'Add task'}
        isActionLoading={isLoadingCreateTask}
        width={576}
      >
        <TaskForm
          handleSubmit={handleCreateTask}
          isEdit={false}
          initialValues={selectedTask}
        />
      </Modal>
    </>
  )
}
export default Tasks
