import { Asset, FieldLabels } from '@api/models'
import {
  useGetAssetDepreciationQuery,
  useGetCompanyQuery,
  useGetDisposalDataQuery,
  useGetDisposalProjectionsQuery,
  useGetSettingsQuery,
} from '@api'
import {
  Divider,
  Fieldset,
  Flexbox,
  Spinner,
  Text,
} from '@components/common'
import DataDisplayList from '@components/DataDisplayList'
import { formatCurrency, formatDisplayDate } from '@utils/basic'

interface Props {
  assetCompanyID: number
  assetID: string
  fields: FieldLabels
  asset: Asset
}

const AssetDisposalDetails = ({ assetCompanyID, assetID, fields, asset }: Props) => {
  //  Get disposal data
  const {
    data: disposalData,
    isLoading: isLoadingDisposalData,
    isSuccess: isSuccessGettingDisposalData,
  } = useGetDisposalDataQuery({ assetCompanyID, assetID })

  // Get projection data
  const {
    data: disposalProjections,
    isLoading: isLoadingDisposalProjections,
  } = useGetDisposalProjectionsQuery(
    {
      assetCompanyID,
      assetID,
      period: disposalData?.AssetDisposalParams.Period,
      year: disposalData?.AssetDisposalParams.Year,
      realizedAmount: disposalData?.AssetDisposalParams.RealizedAmount,
      disposalSoldTo: disposalData?.AssetDisposalParams.DisposalSoldTo,
      disposalDate: disposalData?.AssetDisposalParams.DisposalDate,
    },
    {
      skip: !isSuccessGettingDisposalData,
    }
  )

  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  // We need asset deprec to detect of years or units of production
  const {
    isLoading: isLoadingAssetDepreciation,
  } = useGetAssetDepreciationQuery({
    assetCompanyID,
    assetID,
    profile: 'A',
  })

  const {
    data: company,
    isLoading: isLoadingCompany,
  } = useGetCompanyQuery(assetCompanyID)

  const isLoading: boolean =
    isLoadingAssetDepreciation ||
    isLoadingDisposalData ||
    isLoadingDisposalProjections ||
    isLoadingCompany

  if (isLoading)
    return (
      <Flexbox justifyContent="center" alignItems="center" height={272}>
        <Spinner />
      </Flexbox>
    )

  const isPeriodBased: boolean = company.CM_YTD_BCD === 'P'

  let unorderedListItems = [
    {
      order: 3,
      label: 'Realized amount',
      value: formatCurrency(disposalData.AssetDisposalParams.RealizedAmount),
    },
    {
      order: 4,
      label: 'Disposal/sold to',
      value: disposalData.AssetDisposalParams.DisposalSoldTo,
    },
  ]

  if (isPeriodBased) {
    unorderedListItems.push(
      {
        order: 0,
        label: 'Disposal year',
        value: disposalData.AssetDisposalParams.Year.toString(),
      },
      {
        order: 1,
        label: 'Period',
        value: disposalData.AssetDisposalParams.Period.toString(),
      }
    )
  } else {
    unorderedListItems.push({
      order: 2,
      label: 'Disposal date',
      value: formatDisplayDate(disposalData.AssetDisposalParams.DisposalDate, DateFormat),
    })
  }

  const listItems = unorderedListItems
    .sort((a, b) => a.order - b.order)
    .map(x => ({ label: x.label, value: x.value }))

  return (
    <Fieldset legend="Disposal details">
      <DataDisplayList
        columns={1}
        items={[
          {
            label: 'Life-to-date',
            value: formatCurrency(disposalProjections?.LTDD ?? 0),
          },
          {
            label: 'Net-book',
            value: formatCurrency(disposalProjections?.NBV ?? 0),
          },
          {
            label: 'Projected gain/loss',
            value: (
              <Text
                intent={
                  disposalProjections?.PGL > 0
                    ? 'success'
                    : disposalProjections?.PGL === 0
                    ? 'default'
                    : 'danger'
                }
              >
                {formatCurrency(disposalProjections?.PGL ?? 0)}
              </Text>
            ),
          },
        ]}
      />
      <Divider />
      <DataDisplayList items={listItems} />
    </Fieldset>
  )
}
export default AssetDisposalDetails
