import { apiSlice } from '@api'

import { AllReportParams } from './models/ReportTemplate.model'

export const reportTemplateEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    // Get all Report templates:
    fetchAllReportTemplate: build.query<any, any>({
      query: assetCompanyID =>
        `ReportTemplate/FetchAllReportTemplate?assetCompanyID=${assetCompanyID}`,
      transformResponse: (response: any) => {
        return response.map((item: any) => {
          item.TP_DATA = JSON.parse(item.TP_DATA)
          return item
        })
      },
      providesTags: ['reportTemplate'],
    }),

    // Save templates:
    createReportTemplate: build.mutation<void, AllReportParams>({
      query: ({ ...template }) => ({
        url: 'ReportTemplate/CreateReportTemplate',
        method: 'POST',
        body: { ...template },
      }),
      invalidatesTags: ['reportTemplate'],
    }),

    // Edit templates:
    editTemplate: build.mutation<void, AllReportParams>({
      query: ({ ...template }) => ({
        url: 'ReportTemplate/EditTemplate',
        method: 'POST',
        body: { ...template },
      }),
      invalidatesTags: ['reportTemplate'],
    }),

    // Delete templates:
    deleteTemplate: build.mutation<void, AllReportParams>({
      query: ({ ...template }) => ({
        url: 'ReportTemplate/DeleteTemplate',
        method: 'POST',
        body: { ...template },
      }),
      invalidatesTags: ['reportTemplate'],
    }),
  }),


})

export const {
  useFetchAllReportTemplateQuery,
  useCreateReportTemplateMutation,

  useEditTemplateMutation,

  useDeleteTemplateMutation,
} = reportTemplateEndpoints
