import View from '@components/View'
import AssetDepreciationProfiles from '@components/asset/AssetDepreciationProfiles'

const AssetDepreciationProfilesView = () => {
  return (
    <View title="Depreciation Profiles">
      <AssetDepreciationProfiles />
    </View>
  )
}

export default AssetDepreciationProfilesView
