import {
  AllAssetDetailFields,
  AllAssetRecalcFields,
  AssetRecalcDTO,
  AssetRecalcFieldNames,
  assetRecalcSchema,
} from '@api/models'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Grid, Input, Fieldset, Radio, Form, Note, CustomDatePicker } from '@common'
import { ASSET_RECALC_FORM_ID } from '@constants'

interface AssetRecalcFormProps {
  initialValues:
    | {
        asset: AllAssetDetailFields
        assetRecalcDTO: AssetRecalcDTO
      }
    | undefined
  submitAction
}

const AssetRecalcForm = ({ initialValues, submitAction }: AssetRecalcFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm<AllAssetRecalcFields>({
    resolver: yupResolver(assetRecalcSchema),
    mode: 'onBlur',
    defaultValues: initialValues.assetRecalcDTO,
  })
  const watchSelectedOption = watch('SelectedOption')

  function getInputProps(name: AssetRecalcFieldNames) {
    return {
      ...register(name),
      disabled: isSubmitting,
      error: errors[name] !== undefined,
      errorMsg: errors[name]?.message,
    }
  }

  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      onBlur: field.onBlur,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  function onSubmitHandler(formData: AllAssetRecalcFields) {
    submitAction({ assetRecalcDTO: { ...formData }, asset: initialValues.asset })
  }

  const yearNotApplicable = initialValues.assetRecalcDTO.AsOfYear === 0

  // TODO: radio button disabled styling
  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)} id={ASSET_RECALC_FORM_ID}>
      <Grid colGap="compact">
        <Grid.Item>
          <Note
            intent="danger"
            content={
              <>
                You have made changes to asset
                <b>
                  {' '}
                  {initialValues.asset['AssetID']} ({initialValues.asset.AS_DESC})
                </b>{' '}
                which may require adjustments to historical information. Please confirm
                recalculation before saving.
              </>
            }
          />
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="Options">
            <Controller
              control={control}
              name="SelectedOption"
              render={({ field: { onChange, value } }) => (
                <Radio
                  name="SelectedOption"
                  onChange={val => onChange(val.target.value)}
                  select={
                    initialValues.assetRecalcDTO.Options.find(c => value === c.Value).Value
                  }
                >
                  {initialValues.assetRecalcDTO.Options.map(option => (
                    <Radio.Option
                      key={option.Value}
                      value={option.Value}
                      label={option.Label}
                      disabled={option.Disabled}
                    />
                  ))}
                </Radio>
              )}
            />
          </Fieldset>
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="Parameters">
            <Grid colGap="compact">
              <Grid.Item l={6}>
                {yearNotApplicable ? (
                  <Input value="n/a" disabled label="As Of Year" />
                ) : (
                  <Input
                    {...getInputProps('AsOfYear')}
                    label="As Of Year"
                    disabled={watchSelectedOption !== 'specified'}
                  />
                )}
              </Grid.Item>
              <Grid.Item l={6}>
                <Controller
                  control={control}
                  name="PostingDate"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps(field)}
                      label="Posting Date"
                      disabled={watchSelectedOption === 'current'}
                      error={errors['PostingDate'] !== undefined}
                      errorMsg={errors['PostingDate']?.message}
                    />
                  )}
                />
              </Grid.Item>
            </Grid>
          </Fieldset>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default AssetRecalcForm
