import {
  SubscribedApplicationRoles,
  UserInvitationFormFields,
  userInviteSchema,
} from '@api/admPortal/models'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Input, Modal, Spacer, toast } from '@components/common'
import { USER_INVITE_FORM_ID } from '@constants'

import { useInviteUserMutation } from '@api/admPortal/userManagementAPI'
import { useEffect } from 'react'
import { isNullOrUndefined } from '@utils/basic'
import { AppRoleSelect } from './AppRoleSelect'
import { useFormFieldProps } from '@hooks'

interface UserInviteFormProps {
  isOpen: boolean
  closeModal: () => void
  subbedAppRoles: SubscribedApplicationRoles
}

export const UserInviteForm = ({
  isOpen,
  closeModal,
  subbedAppRoles,
}: UserInviteFormProps) => {
  const methods = useForm<UserInvitationFormFields>({
    resolver: yupResolver(userInviteSchema),
    mode: 'onSubmit',
  })

  const {
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    watch,
  } = methods

  const { getInputProps } = useFormFieldProps<UserInvitationFormFields>({
    fields: {},
    isSubmitting,
    register,
    errors: errors as any,
  })

  const [inviteUser] = useInviteUserMutation()

  async function onSubmit(values: UserInvitationFormFields) {
    try {
      await inviteUser({
        email: values.email,
        roleNames: values.appRoles.map(x => x.roleName),
      }).unwrap()
      toast.success(`Invite sent to ${values.email}`)
      closeModal()
    } catch (err: any) {
      toast.error(err?.data || 'Failed to send invitation')
    }
  }

  useEffect(() => {
    if (!isOpen) reset(userInviteSchema.cast({}))
  }, [isOpen, reset])

  const appRoles = watch('appRoles')
  const hasSelectedRoles =
    appRoles !== undefined &&
    appRoles.length > 0 &&
    appRoles.every(
      x => !isNullOrUndefined(x.applicationName) && !isNullOrUndefined(x.roleName)
    )

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        close={closeModal}
        title="Invite a New User"
        width={600}
        form={USER_INVITE_FORM_ID}
        actionLabel="Send Invitation"
        action={handleSubmit(onSubmit)}
        isActionLoading={isSubmitting}
        dismissLabel="Cancel"
        actionDisabled={!hasSelectedRoles}
      >
        <Grid>
          <Grid.Item>
            <Input {...getInputProps('email')} label="Email" />
            <Spacer y="mini" />
            <AppRoleSelect subbedAppRoles={subbedAppRoles} />
          </Grid.Item>
        </Grid>
      </Modal>
    </FormProvider>
  )
}
