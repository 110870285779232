import { useFields } from '@hooks'
import { useEffect, useMemo } from 'react'
import { Column, SortingRule, useFlexLayout, useSortBy, useTable } from 'react-table'
import {
  Button,
  ButtonGroup,
  Card,
  Flexbox,
  Icon,
  Modal,
  Search,
  Table,
  Text,
} from '@components/common'
import { Folder } from '@api/models'
import Pagination from '@components/Pagination'
import { FoldersHook } from '../useFolders'
import { FOLDER_FORM_ID } from '@constants'
import FolderForm from '../FolderForm'
import FoldersTableLoading from './FoldersTableLoading'

const FoldersTable = ({
  pageNumber,
  itemsPerPage,
  handleSearch,
  searchValue,
  changePage,
  sortByInitialState,
  updateSortSearchParam,
  isTotalCountEmpty,
  //
  searchKey,
  folders,
  isLoadingFolders,
  isErrorFetchingFolders,
  isRefetchingFolders,

  handleDeleteFolder,
  isLoadingDeleteFolder,
  openDeleteFolderModal,
  selectedFolder,
  deleteFolderModalIsOpen,
  closeDeleteFolderModal,
  openEditFolderModal,
  closeEditFolderModal,
  editFolderModalIsOpen,
  handleEditFolder,
  isLoadingEditFolder,
  canEdit,
}: FoldersHook) => {
  // Declare columns
  const { fields, isLoading: isLoadingFields, isError: isErrorGettingFields } = useFields()

  const columns = useMemo<Column<Folder>[]>(
    () => [
      {
        Header: fields.FL_FOLD_ID,
        accessor: 'FL_FOLD_ID',
      },
      {
        Header: fields.FL_TAB_NM,
        accessor: 'FL_TAB_NM',
      },
      {
        Header: fields.FL_TAB_VL,
        accessor: 'FL_TAB_VL',
      },
      {
        Header: '',
        accessor: 'FL_SYS_NR',
        disableSortBy: true,
        Cell: ({ row }) => (
          <Flexbox alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
            <ButtonGroup hidden={!canEdit}>
              <Button
                icon={<Icon name="Edit" />}
                size="compact"
                onClick={e => {
                  e.stopPropagation()
                  openEditFolderModal(row.original)
                }}
              >
                Edit
              </Button>

              <Button
                icon={<Icon name="Trash2" />}
                size="compact"
                intent="danger"
                onClick={e => {
                  e.stopPropagation()
                  openDeleteFolderModal(row.original)
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Flexbox>
        ),
      },
    ],
    [isLoadingFields]
  )

  // Declare react table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: folders ? folders.list : [],
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        sortBy: sortByInitialState() as SortingRule<Folder>[],
      },
    },
    useSortBy,
    useFlexLayout
  )

  // Update sort param
  useEffect(() => {
    updateSortSearchParam(sortBy, setSortBy)
  }, [sortBy])

  // Returns
  const isTableLoading = isLoadingFolders || isLoadingFields
  const isError = isErrorFetchingFolders || isErrorGettingFields

  if (isTableLoading) {
    return <FoldersTableLoading />
  }

  if (isError) {
    return <Table.NoResults heading="There was an error." />
  }

  if (isTotalCountEmpty(folders.count)) {
    return (
      <Card spacing="none">
        <Table.NoResults heading="You haven't added any folders yet" />
      </Card>
    )
  }

  return (
    <>
      <Modal
        isOpen={deleteFolderModalIsOpen}
        close={closeDeleteFolderModal}
        title={`Delete folder ${selectedFolder?.FL_FOLD_ID}, ${selectedFolder?.FL_TAB_NM}, ${selectedFolder?.FL_TAB_VL}`}
        width={540}
        action={() => handleDeleteFolder(selectedFolder)}
        actionLabel={`Delete folder`}
        intent="danger"
        actionAutoFocus
        isActionLoading={isLoadingDeleteFolder}
      >
        <Text p>
          Are you sure you want to delete folder{' '}
          <strong>
            {selectedFolder?.FL_FOLD_ID}, {selectedFolder?.FL_TAB_NM},{' '}
            {selectedFolder?.FL_TAB_VL}
          </strong>
          ? <Text intent="danger">This cannot be undone</Text>.
        </Text>
      </Modal>

      <Modal
        isOpen={editFolderModalIsOpen}
        close={closeEditFolderModal}
        title={`Edit folder ${selectedFolder?.FL_FOLD_ID}, ${selectedFolder?.FL_TAB_NM}, ${selectedFolder?.FL_TAB_VL}`}
        width={576}
        actionLabel="Save changes"
        form={FOLDER_FORM_ID}
        isActionLoading={isLoadingEditFolder}
        dismissLabel={canEdit ? 'Discard changes' : 'Close'}
        actionDisabled={!canEdit}
      >
        <FolderForm
          isEdit={true}
          initialValues={selectedFolder}
          handleSubmit={handleEditFolder}
          canEdit={canEdit}
        />
      </Modal>

      <Card spacing="none">
        <Table.Actions>
          <Flexbox>
            <Search
              onChange={handleSearch}
              placeholder="Search by folder ID..."
              name={searchKey}
              width={'640px'}
              value={searchValue}
            />
          </Flexbox>
        </Table.Actions>
        {folders.list.length === 0 ? (
          <Table.NoResults text="" />
        ) : (
          <>
            <Table
              {...getTableProps()}
              aria-label="Company Table"
              label="Company Table"
              aria-colcount={columns.length}
              aria-rowcount={folders.list.length}
              height="calc(100vh - 280px)"
            >
              <Table.Head sticky>
                {isRefetchingFolders ? (
                  <Table.LoadingRows />
                ) : (
                  headerGroups.map((headerGroup, i) => (
                    <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, i) => (
                        <Table.Header
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          column={column}
                          key={i}
                          tabIndex={0}
                        >
                          {column.render('Header')}
                        </Table.Header>
                      ))}
                    </Table.HeadRow>
                  ))
                )}
              </Table.Head>

              <Table.Body {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Table.Row
                      {...row.getRowProps()}
                      onClick={() => {
                        openEditFolderModal(row.original)
                      }}
                      tabIndex={0}
                      key={i}
                    >
                      {row.cells.map(cell => (
                        <Table.Cell {...cell.getCellProps()} column={cell.column}>
                          {cell.render('Cell')}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <Pagination
              changePage={changePage}
              totalItems={folders.count}
              maxItemsPerPage={itemsPerPage}
              currentPageNumber={pageNumber}
              label={`Showing ${folders.list.length} of ${folders.count} record${
                folders.count > 1 ? 's' : ''
              }`}
            />
          </>
        )}
      </Card>
    </>
  )
}
export default FoldersTable
