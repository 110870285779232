import { ImportColumn } from "../importTypes";

export const companies: ImportColumn[] = [
    {
        label: 'Company ID',
        value: 'CM_COM_ID'
    },
    {
        label: 'Company Name',
        value: 'CM_DESC'
    },
    {
        label: 'Company Short Name',
        value: 'CM_DESCS'
    },
    {
        label: 'Year End Month',
        value: 'CM_YE_MM'
    },
    {
        label: 'Year End Day',
        value: 'CM_YE_DD'
    },
    {
        label: 'Number of Periods',
        value: 'CM_NR_PER'
    },
    {
        label: 'Depreciation Basis',
        value: 'CM_YTD_BCD'
    },
    {
        label: 'State Code',
        value: 'CM_STAT_CD'
    },
    {
        label: 'Other Book Code',
        value: 'CM_BOK4_CD'
    },
    {
        label: 'Tax Book Used',
        value: 'CM_TAX'
    },
    {
        label: 'State Book Used',
        value: 'CM_SMH'
    },
    {
        label: 'Other Book Used',
        value: 'CM_B4MH'
    },
    {
        label: 'Last Fiscal Year Closed',
        value: 'CM_LFYC'
    },
    {
        label: 'Last Run as of Date',
        value: 'CM_LE_AOD'
    },
    {
        label: 'Last Run as of Period',
        value: 'CM_LE_AOP'
    },
    {
        label: 'Last Run GL Date',
        value: 'CM_LE_GPD'
    },
    {
        label: 'Last Runt GL Period',
        value: 'CM_LE_GPP'
    },
    {
        label: 'Last Run System Date',
        value: 'CM_LE_SYD'
    },
    {
        label: 'Last Run Pending',
        value: 'CM_LE_PEN'
    },
    {
        label: 'YTD Factor',
        value: 'CM_LE_YTUF'
    },
    {
        label: 'Last Run Remarks 1',
        value: 'CM_LE_RE1'
    },
    {
        label: 'Last Run Remarks 2',
        value: 'CM_LE_RE2'
    },
    {
        label: 'Last Run Remarks 3',
        value: 'CM_LE_RE3'
    },
    {
        label: 'Last Update System Date',
        value: 'CM_LU_SYD'
    },
    {
        label: 'Last Update Event ID',
        value: 'CM_LU_EVN'
    },
    {
        label: 'Depreciation Start Code',
        value: 'CM_DEP_STC'
    }
]