import { AssetsApi } from '../..'
import { toast } from '@components/common'
import { ERROR_REQUEST, REQUIRED_FIELD } from '@constants'
import * as Yup from 'yup'
import { getTodaysDate, maxStringMsg } from '@utils/basic'
import { currencyValidation, dateExistValidation } from '../../models/validation'
import { MaintainedDepProfiles } from '../../companies/models/Company.model'

// Payload & response models
export interface DeleteAssetPayload {
  assetCompanyID: number
  assetID: string
}
export interface DuplicateAssetPayload {
  assetCompanyID: number
  baseAssetID: string
  newAssetID: string
}
export interface GetAssetPayload {
  assetCompanyID: number
  assetID: string
}
export interface GetAssetsPayload {
  assetCompanyID: number
  pageNumber: number
  itemsPerPage: number
  filter: Object
}
export interface GetAssetsResponse {
  count: number
  list: Asset[]
}

// Schema and types
export const assetSchema = (
  companyID: string,
  maintainedDepProfiles: MaintainedDepProfiles
) =>
  Yup.object({
    AssetID: Yup.string()
      .typeError(REQUIRED_FIELD)
      .max(12, maxStringMsg(12))
      .test({
        test: async function (assetID) {
          return !(await doesAssetExist(companyID, assetID))
        },
        message: 'An asset with this ID already exists',
      })
      .required(REQUIRED_FIELD),
    AS_DESC: Yup.string()
      .typeError(REQUIRED_FIELD)
      .max(50, maxStringMsg(50))
      .required(REQUIRED_FIELD),
    AS_DESC_E: Yup.string().max(50, maxStringMsg(50)).nullable(),
    AS_SERIAL: Yup.string().max(25, maxStringMsg(25)).nullable(),
    AS_ADD_DAT: Yup.string().nullable().test(dateExistValidation),
    AS_ADD_USR: Yup.string().nullable(),
    AS_CHG_DAT: Yup.string().nullable().test(dateExistValidation),
    AS_CHG_USR: Yup.string().nullable(),
    AS_KEY0_ID: Yup.string().nullable(),
    AS_KEY1_ID: Yup.string().nullable(),
    AS_KEY2_ID: Yup.string().nullable(),
    AS_KEY3_ID: Yup.string().nullable(),
    AS_KEY4_ID: Yup.string().nullable(),
    AS_KEY5_ID: Yup.string().nullable(),
    AS_KEY6_ID: Yup.string().nullable(),
    AS_KEY7_ID: Yup.string().nullable(),
    AS_KEY8_ID: Yup.string().nullable(),
    AS_KEY9_ID: Yup.string().nullable(),
    AS_CAT_ID: Yup.string().required(REQUIRED_FIELD),
    AS_TCLS_ID: maintainedDepProfiles.IsTaxMaintained
      ? Yup.string().required(REQUIRED_FIELD)
      : Yup.string().nullable(),
    AS_SCLS_ID: maintainedDepProfiles.IsStateTaxMaintained
      ? Yup.string().required(REQUIRED_FIELD)
      : Yup.string().nullable(),
    AS_4CLS_ID: maintainedDepProfiles.IsOtherTaxMaintained
      ? Yup.string().required(REQUIRED_FIELD)
      : Yup.string().nullable(),
    AS_PRI_UCD: Yup.string().max(25, maxStringMsg(25)).nullable(),
    AS_SEC_UCD: Yup.string().max(25, maxStringMsg(25)).nullable(),
    AS_TAXB_CD: Yup.string().nullable(),
    AS_TAXL_CD: Yup.string().nullable(),
    AS_TAX_BUS: Yup.number()
      .min(0, 'Cannot be a negative percentage')
      .max(100, 'Cannot exceed 100%')
      .typeError(REQUIRED_FIELD)
      .default(100),
    AS_TAX_PTP: Yup.string().max(20, maxStringMsg(20)).nullable(),
    AS_REC_DAT: Yup.string()
      .nullable()
      .default(getTodaysDate())
      .test(dateExistValidation),
    AS_VEN_ID: Yup.string().nullable(),
    AS_VEN_NM: Yup.string().max(50, maxStringMsg(50)).nullable(),
    AS_REC_PON: Yup.string().max(25, maxStringMsg(25)).nullable(),
    AS_REC_USR: Yup.string().max(25, maxStringMsg(25)).nullable(),
    AS_SUB_DCR: Yup.boolean().nullable(),
    AS_SUB_DTR: Yup.boolean().nullable(),
    AS_SUB_DSR: Yup.boolean().nullable(),
    AS_SUB_DOR: Yup.boolean().nullable(),
    AS_FIN_COS: Yup.number().test(currencyValidation).typeError(REQUIRED_FIELD).default(0),
    AS_FIN_SAL: Yup.number().test(currencyValidation).typeError(REQUIRED_FIELD).default(0),
    AS_FIN_VAL: Yup.number().test(currencyValidation).typeError(REQUIRED_FIELD).default(0),
    AS_FIN_CLC: Yup.number().test(currencyValidation).typeError(REQUIRED_FIELD).default(0),
    AS_FIN_CSC: Yup.number().test(currencyValidation).typeError(REQUIRED_FIELD).default(0),
    AS_FIN_COC: Yup.number().test(currencyValidation).typeError(REQUIRED_FIELD).default(0),
    AS_FIN_CFC: Yup.number().test(currencyValidation).typeError(REQUIRED_FIELD).default(0),
    AS_FIN_TAC: Yup.number().test(currencyValidation).typeError(REQUIRED_FIELD).default(0),
    AS_DIS_CD: Yup.string().default('true').nullable(), // true = active, false = inactive
    AS_DIS_DAT: Yup.string().nullable().test(dateExistValidation),
    AS_DIS_SAL: Yup.number().test(currencyValidation).nullable(),
    AS_DIS_NAM: Yup.string().max(25, maxStringMsg(25)).nullable(),
    AS_DIS_SYD: Yup.string().nullable(),
    AS_DIS_USR: Yup.string().nullable(),
    AS_IN_POL: Yup.string().max(12, maxStringMsg(12)).nullable(),
    AS_REP_DAT: Yup.string().nullable().test(dateExistValidation),
    AS_STAT_CD: Yup.string().nullable(),
    AS_BOK4_CD: Yup.string().nullable(),
    AS_WRO_CD: Yup.string().nullable(),
    AS_WRO_DAT: Yup.string().nullable().test(dateExistValidation),
    AS_PAR_ASS: Yup.string()
      .max(12, maxStringMsg(12))
      .test({
        test: async function (assetID) {
          if (!assetID) return true
          else return await doesAssetExist(companyID, assetID)
        },
        message: 'Must be an existing Asset ID',
      })
      .nullable(),
    AS_NSER_DT: Yup.string().nullable().test(dateExistValidation),
    AS_MAIN_CN: Yup.string().max(12, maxStringMsg(12)).nullable(),
    AS_DEP_STC: Yup.string().nullable(),
    AS_LEASE: Yup.string().max(15, maxStringMsg(15)).nullable(),
    AS_LEASEP: Yup.number().nullable(),
    AS_LOCD_CD: Yup.string().nullable(),
    AS_LAPC_ID: Yup.string().nullable(),
    AS_LDPC_ID: Yup.string().nullable(),
    AS_SERD_CD: Yup.string().nullable(),
    AS_SAPC_ID: Yup.string().nullable(),
    AS_SDPC_ID: Yup.string().nullable(),
    AS_UC1_UCD: Yup.string().max(25, maxStringMsg(25)).nullable(),
    AS_UC2_UCD: Yup.string().max(25, maxStringMsg(25)).nullable(),
    AS_UC3_UCD: Yup.string().max(25, maxStringMsg(25)).nullable(),
  })

export interface Asset {
  AssetCompanyID?: number
  AssetID?: string
  AS_4CLS_ID?: string
  AS_ADD_DAT?: string
  AS_ADD_USR?: string
  AS_BOK4_CD?: string
  AS_CAT_ID?: string
  AS_CHG_DAT?: string
  AS_CHG_USR?: string
  AS_DEP_STC?: string
  AS_DESC?: string
  AS_DESC_E?: string
  AS_DIS_CD?: string
  AS_DIS_DAT?: string
  AS_DIS_NAM?: string
  AS_DIS_SAL?: number
  AS_DIS_SYD?: string
  AS_DIS_USR?: string
  AS_FIN_CFC?: number
  AS_FIN_CLC?: number
  AS_FIN_COC?: number
  AS_FIN_COS?: number
  AS_FIN_CSC?: number
  AS_FIN_SAL?: number
  AS_FIN_TAC?: number
  AS_FIN_VAL?: number
  AS_IN_POL?: string
  AS_KEY0_ID?: string
  AS_KEY1_ID?: string
  AS_KEY2_ID?: string
  AS_KEY3_ID?: string
  AS_KEY4_ID?: string
  AS_KEY5_ID?: string
  AS_KEY6_ID?: string
  AS_KEY7_ID?: string
  AS_KEY8_ID?: string
  AS_KEY9_ID?: string
  AS_LAPC_ID?: string
  AS_LDPC_ID?: string
  AS_LEASE?: string
  AS_LEASEP?: number
  AS_LOCD_CD?: string
  AS_MAIN_CN?: string
  AS_NSER_DT?: string
  AS_PAR_ASS?: string
  AS_PRI_UCD?: string
  AS_REC_DAT?: string
  AS_REC_PON?: string
  AS_REC_USR?: string
  AS_REP_DAT?: string
  AS_SAPC_ID?: string
  AS_SCLS_ID?: string
  AS_SDPC_ID?: string
  AS_SEC_UCD?: string
  AS_SERD_CD?: string
  AS_SERIAL?: string
  AS_STAT_CD?: string
  AS_SUB_DCR?: boolean
  AS_SUB_DOR?: boolean
  AS_SUB_DSR?: boolean
  AS_SUB_DTR?: boolean
  AS_TAXB_CD?: string
  AS_TAXL_CD?: string
  AS_TAX_BUS?: number
  AS_TAX_PTP?: string
  AS_TCLS_ID?: string
  AS_UC1_UCD?: string
  AS_UC2_UCD?: string
  AS_UC3_UCD?: string
  AS_VEN_ID?: string
  AS_VEN_NM?: string
  AS_WRO_CD?: string
  AS_WRO_DAT?: string
}
export type AssetKey = keyof Asset

//
async function doesAssetExist(companyID: string, assetID: string): Promise<boolean> {
  return await AssetsApi.getInstance()
    .doesAssetExist(parseInt(companyID), assetID)
    .then(response => response.data)
    .catch(() => toast.error(ERROR_REQUEST))
}
