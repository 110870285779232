import AssetLocations from '@components/asset/AssetLocations'
import View from '@components/View'
import { useParams } from 'react-router-dom'

const AssetLocationView = () => {
  const params = useParams()

  return (
    <View title="Location">
      <AssetLocations
        assetCompanyID={parseInt(params.companyID)}
        assetID={params.assetID}
        type="L"
      />
    </View>
  )
}

export default AssetLocationView
