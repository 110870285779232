import { DepreciationProfileCode } from '@api/models'
import { DeprecClassHookProps } from '@components/depreciation-class/DepreciationClass.types'
import { useDepreciationClass } from '@hooks'
import { ASSET_PATHS } from '@constants'
import { useNavigate, useParams } from 'react-router-dom'

interface IAssetDepreciationProfilesHook {
  deprecClassHookProps: DeprecClassHookProps
  depreciationProfileLabel: string
  isLoading: boolean
  isProfileMaintained: boolean
  sideNavItems: {
    label: string
    onClick
    isActive: boolean
  }[]
}

export default function useAssetDepreciationProfiles(): IAssetDepreciationProfilesHook {
  const params = useParams()
  const navigate = useNavigate()

  const profile = params.profile as DepreciationProfileCode
  const assetID = params.assetID
  const assetCompanyID = parseInt(params.companyID)

  // Hook into useDepreciationClass
  const deprecClassHookProps: DeprecClassHookProps = {
    context: 'asset',
    params: {
      assetCompanyID,
      assetID,
      profile,
    },
  }

  const { isLoading, isProfileMaintained } = useDepreciationClass(deprecClassHookProps)

  // Nav
  const sideNavItems = [
    {
      label: 'Corporate',
      onClick: () => navigate(`../${ASSET_PATHS.DEPRECIATION}/A`),
      isActive: profile === 'A',
    },
    {
      label: 'Tax',
      onClick: () => navigate(`../${ASSET_PATHS.DEPRECIATION}/X`),
      isActive: profile === 'X',
    },
    {
      label: 'State',
      onClick: () => navigate(`../${ASSET_PATHS.DEPRECIATION}/S`),
      isActive: profile === 'S',
    },
    {
      label: 'Other',
      onClick: () => navigate(`../${ASSET_PATHS.DEPRECIATION}/O`),
      isActive: profile === 'O',
    },
  ]

  const depreciationProfileLabel: string = sideNavItems.find(item => item.isActive)?.label

  return {
    deprecClassHookProps,
    depreciationProfileLabel,
    isLoading,
    isProfileMaintained,
    sideNavItems,
  }
}
