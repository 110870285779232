import { StyleObject } from '@styles-api/css.types'

export const getMaxWidth = ({ maxWidth }) => {
  return {
    maxWidth: `var(--container-${maxWidth})`,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
}
export const viewContainer: StyleObject = {
  display: 'block',
}

// header
export const viewHeader: StyleObject = {
  width: '100%',
  marginBottom: 'var(--scale800)',
}

export const viewTitle: StyleObject = {
  display: 'flex',
  alignItems: 'center',
}

export const backButton: StyleObject = {
  fontSize: '11px',
  textTransform: 'uppercase',
  fontWeight: 'var(--font-weight-semiBold)',
  color: 'var(--text-light)',
  display: 'inline-flex',
  alignItems: 'center',
  marginBottom: 'var(--gap-mini)',
  transition: 'var(--button-hover-transition)',

  ':hover': {
    color: 'var(--text-color)',
    textDecoration: 'none',
  },
}
export const backButtonIcon: StyleObject = {
  height: 'var(--scale550)',
  marginRight: 'var(--scale100)',
  marginLeft: 'calc(var(--scale300) * -1)',
}
