import { Spacer, Flexbox, Button, Radio, Text } from '@components/common'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AssetsTableFilterField } from './Filters.types'

interface IRadioFilter {
  filter: AssetsTableFilterField
}

const RadioFilter = ({ filter }: IRadioFilter) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])

  const [optionValue, setOptionValue] = useState<string | undefined>(
    searchParams.get(filter.column) ?? undefined
  )

  const inputChangeTimeoutDuration: number = 400

  function onChange(e: any) {
    setOptionValue(e.target.value)
    setTimeout(() => {
      if (e.target.value === '') {
        searchParams.delete(e.target.name)
        setSearchParams(searchParams)
      } else {
        setSearchParams({
          ...searchParamsObject,
          [e.target.name]: (e.target.value = typeof Number
            ? e.target.value.toString()
            : e.target.value),
          pageNumber: '1',
        })
      }
    }, inputChangeTimeoutDuration)
  }

  function clearSelection() {
    searchParams.delete(filter.column)
    setSearchParams(searchParams)
    setOptionValue(undefined)
  }

  if (filter.types.length === 0) {
    return <Text>No options apply.</Text>
  }

  return (
    <div>
      <Spacer y="mini" />
      <Radio name={filter.column} onChange={onChange} select={optionValue} legend="">
        {filter.types.map((option, index: number) => (
          <Radio.Option
            value={option.value}
            label={option.name}
            autoFocus={index === 0}
            key={option.name}
          />
        ))}
      </Radio>
      <Spacer y="mini" />
      <Flexbox justifyContent="flex-end">
        <Text small>
          <Button
            onClick={() => clearSelection()}
            appearance="text"
            disabled={optionValue === '' || optionValue === undefined}
          >
            Clear
          </Button>
        </Text>
      </Flexbox>
    </div>
  )
}

export default RadioFilter
