import React from 'react'

import { useStyletron } from 'styletron-react'
import * as styles from '../Styles'

export interface TableHeadProps {
  sticky?: boolean
}

const Head: React.FC<TableHeadProps> = ({ ...props }) => {
  const [css] = useStyletron()

  let headStyles = css({
    ...styles.head,
    ...styles.getStickyHead({ sticky: props.sticky }),
  })
  return (
    <div className={headStyles}>
      {props.children}
    </div>
  )
}

export default Head
