import {
  GetCompaniesResponse,
  Company,
  FetchAssetCompaniesResponse,
  FetchAssetCompaniesPayload,
  CreateCompanyPayload,
  MaintainedDepProfiles,
} from './models'

import { apiSlice } from '@api'

const companiesEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    // COMPANIES
    getCompanies: build.query<GetCompaniesResponse, void>({
      query: () => `AssetCompanies/FetchCompanies`,
      providesTags: ['company'],
    }),
    getCompany: build.query<Company, number>({
      query: id => `AssetCompanies/GetAssetCompany?assetCompanyId=${id}`,
      providesTags: ['company'],
    }),
    fetchAssetCompanies: build.query<
      FetchAssetCompaniesResponse,
      FetchAssetCompaniesPayload
    >({
      query: ({ pageNumber, itemsPerPage, filter }) =>
        `AssetCompanies/FetchAssetCompanies?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      providesTags: ['company'],
    }),
    createCompany: build.mutation<void, CreateCompanyPayload>({
      query: ({ ...company }) => ({
        url: `/AssetCompanies/Create`,
        method: 'POST',
        body: { ...company },
      }),
      invalidatesTags: ['company'],
    }),
    editCompany: build.mutation<void, Company>({
      query: ({ ...company }) => ({
        url: 'AssetCompanies/Edit',
        method: 'PUT',
        body: { ...company },
      }),
      invalidatesTags: ['company'],
    }),
    doesCompanyHaveHistory: build.query<boolean, number>({
      query: id => ({
        url: `AssetCompanies/DoesAssetCompanyHaveHistory/${id}`,
        method: 'GET',
      }),
    }),
    maintainedDepProfiles: build.query<MaintainedDepProfiles, number>({
      query: id => ({
        url: `AssetCompanies/GetMaintainedDepProfiles/${id}`,
        method: 'GET',
      }),
    }),
    deleteCompany: build.mutation<void, Company>({
      query: ({ ...company }) => ({
        url: `AssetCompanies/Delete`,
        method: 'POST',
        body: { ...company },
      }),
      invalidatesTags: ['company'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useFetchAssetCompaniesQuery,
  useCreateCompanyMutation,
  useEditCompanyMutation,
  useDoesCompanyHaveHistoryQuery,
  useMaintainedDepProfilesQuery,
  useDeleteCompanyMutation,
} = companiesEndpoints
