import HttpClient from '../HttpClient'

export class MaintenanceApi extends HttpClient {
  private static classInstance?: MaintenanceApi

  public constructor() {
    super(`/Maintenance`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new MaintenanceApi()
    }

    return this.classInstance
  }

  public fetch = (filter: string, pageNumber: number, itemsPerPage: number) =>
    this.instance.get(
      `/FetchMaintenances?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`
    )
  public list = () => this.instance.get(`/ListMaintenance`)
  public get = (id: string) => this.instance.get(`/GetMaintenance?id=${id}`)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (data: any) => this.instance.post(`/Delete`, data)
  public import = (file: any) => this.instance.post(`/ImportMaintenance`, file)
}
