import HttpClient from "../HttpClient";

export class AssetTransferApi extends HttpClient {
    private static classInstance?: AssetTransferApi;

    public constructor () {
        super (`/AssetTransfer`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new AssetTransferApi();
        }

        return this.classInstance;
    }

    public import = (file: any) => this.instance.post(`/ImportAssetTransfers`, file);
    public report = (assetCompanyId: number) => this.instance.get(`/AssetTransferReport?assetCompanyId=${assetCompanyId}`);
}