import HttpClient from '../HttpClient'

export class VendorContactApi extends HttpClient {
  private static classInstance?: VendorContactApi

  public constructor() {
    super(`/VendorContact`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new VendorContactApi()
    }

    return this.classInstance
  }

  public fetch = (vendorId: string) =>
    this.instance.get(`/FetchContacts?vendorId=${vendorId}`)
  public get = (vcvenid: string, vcsysnr: number, vcconnm: string) =>
    this.instance.get(
      `/GetContact?VCVENID=${vcvenid}&VCSYSNR=${vcsysnr}&VCCONNM=${vcconnm}`
    )
  public import = (file: any) => this.instance.post(`/ImportContacts`, file)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (data: any) => this.instance.post(`/Delete`, data)
}
