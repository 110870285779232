import { useSendResetPasswordEmailMutation } from '@api/admPortal/userManagementAPI'
import {
  Button,
  Card,
  Flexbox,
  Form,
  Grid,
  Note,
  Spacer,
  Spinner,
  toast,
} from '@components/common'
import { CHANGE_PASSWORD_FORM_ID } from '@constants'

const ChangePasswordForm = () => {
  const [
    sendResetPasswordEmail,
    { isLoading: isSendingEmail },
  ] = useSendResetPasswordEmailMutation()

  async function onSubmitHandler() {
    try {
      await sendResetPasswordEmail().unwrap()
      toast.success('Email sent successfully')
    } catch (error: any) {
      toast.error(error?.data || 'Failed to send email')
    }
  }

  return (
    <Form id={CHANGE_PASSWORD_FORM_ID}>
      <Grid>
        <Grid.Item m={5}>
          <Card>
            <Note
              intent="default"
              content={
                <>
                  To change your password, click the button below and we're going to send
                  you an email with a link to reset your password.
                </>
              }
            />
            <Spacer y="default" />

            <Flexbox justifyContent="center">
              <Button
                appearance="primary"
                onClick={onSubmitHandler}
                style={{ minWidth: '150px' }}
              >
                {isSendingEmail ? <Spinner size="regular" /> : 'Change Password'}
              </Button>
            </Flexbox>
          </Card>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default ChangePasswordForm
