import { useState } from 'react'
import Step from './Step'
import { StepperProps } from './Stepper.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

import { Form, Spacer, Button } from '../'
import { SR_ONLY } from '@styles-api/css-constants'
import Icon from '../Icon'
import { FormProvider } from 'react-hook-form'

const Stepper = ({
  steps,
  formId,
  height,
  submitLabel,
  isLoadingSubmit,
  methods,
  onSubmit,
}: StepperProps) => {
  const [activeStep, setActiveStep] = useState(0)

  // get step status depending on current step
  function stepStatus({ index }) {
    if (activeStep === index) return 'current'
    else if (activeStep > index) return 'complete'
    else if (activeStep < index) return 'upcoming'
  }

  async function validateCurrentStep(): Promise<boolean> {
    return await methods.trigger(steps[activeStep].fieldNames)
  }

  // go to previous step --> prev button disabled if activeStep === 0
  function handlePrev() {
    setActiveStep(prevStep => prevStep - 1)
  }

  // go to next step --> if there are errors on current step, we set inputs to touched
  // ... to show the error state on inputs instead of going to next step
  async function handleNext() {
    const isValid = await validateCurrentStep()
    if (isValid) {
      setActiveStep(prevStep => prevStep + 1)
    }
  }

  const isLastStep = activeStep === steps.length - 1

  // styles
  const [css] = useStyletron()
  let wrapperStyles = css(styles.wrapper({ height }))
  let listStyles = css(styles.list)
  let formStyles = css(styles.form)
  let fieldsetStyles = css(styles.fieldset)
  let navStyles = css(styles.nav)

  let progressFluidWidth = (activeStep / steps.length) * 100
  let progressLineFraction = (1 / steps.length) * 100

  return steps.length === 0 ? null : (
    <div className={wrapperStyles}>
      <ol className={listStyles}>
        {steps.map((step, index) => (
          <Step
            key={step.label}
            step={index}
            label={step.label}
            status={stepStatus({ index })}
          />
        ))}
        <div className={css(styles.progressBase({ fraction: progressLineFraction }))} />
        <div
          className={css(
            styles.progressFluid({
              width: progressFluidWidth,
              fraction: progressLineFraction,
            })
          )}
        />
      </ol>
      <FormProvider {...methods}>
        <Form id={formId} onSubmit={methods.handleSubmit(onSubmit)} className={formStyles}>
          <fieldset className={fieldsetStyles}>
            <legend className={css(SR_ONLY)}>{steps[activeStep].label}</legend>
            {/* <Text>
            Step {activeStep + 1} of {steps.length}{' '}
            {steps[activeStep].description &&
              `: ${steps[activeStep].description}`}
          </Text>
          <Spacer y="compact" /> */}
            {steps[activeStep].element}
          </fieldset>

          <nav
            className={navStyles}
            role="navigation"
            aria-label={`${formId} Step Navigation`}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handlePrev}
              aria-label="Go to previous step"
              appearance="text"
              icon={<Icon name="ChevronLeft" />}
            >
              Back
            </Button>
            <Spacer x="mini" />
            {isLastStep && (
              <Button
                type="submit"
                appearance="primary"
                disabled={!isLastStep}
                id="btn-id"
                loading={isLoadingSubmit}
              >
                {submitLabel ? submitLabel : 'Submit'}
              </Button>
            )}
            {!isLastStep && (
              <Button
                disabled={activeStep === steps.length - 1}
                onClick={handleNext}
                aria-label="Go to next step"
              >
                Continue to {steps[activeStep + 1].label.toLowerCase()}
              </Button>
            )}
          </nav>
        </Form>
      </FormProvider>
    </div>
  )
}
export default Stepper
