import {
  useEditPeriodsMutation,
  useFetchPeriodsQuery,
  useGeneratePeriodsMutation,
  useGetSettingsQuery,
  useGetYearsQuery,
} from '@api'
import {
  EditPeriodsPayload,
  editPeriodsSchema,
  FieldLabels,
  generatePeriodSchema,
  GeneratePeriodsParams,
  Period,
  PeriodKey,
} from '@api/models'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Column } from 'react-table'
import { useFields } from '@hooks'
import { formatDisplayDate, getCurrentYear, splitPathsIntoArray } from '@utils/basic'
import { Control, Controller, useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomDatePicker, Input, toast } from '@components/common'
import { COMPANY_PATHS, ERROR_REQUEST } from '@constants'
import BigNumber from 'bignumber.js'
import { ReactSelectOptions } from '@components/common/Select/Select.types'

interface CompanyPeriodsHookProps {
  assetCompanyID: number
}

interface ICompanyPeriodsHook {
  getCheckBoxProps: (field) => any
  control: Control<EditPeriodsPayload>

  years: number[]
  companyHasPeriods: boolean
  mostRecentGeneratedFiscalYear: number | null
  year: number
  periods: Period[]
  isRefetchingPeriods: boolean
  tableColumns: Column<Period>[]
  isLoading: boolean
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  isDiscardModalOpen: boolean
  setIsDiscardModalOpen: Dispatch<SetStateAction<boolean>>
  discardChanges: () => void
  sumOfPercentages: number
  fields: FieldLabels
  havePercentagesChanged: boolean

  yearOptions: ReactSelectOptions
  handleChangeYear: (e: { label: string; value: any }) => void

  methods: UseFormReturn<EditPeriodsPayload>
  isDirty: boolean
  isValid: boolean

  handleEditPeriods: (payload: EditPeriodsPayload) => Promise<void>
  isLoadingEditPeriods: boolean

  isGeneratePeriodsModalOpen: boolean
  setIsGeneratePeriodsModalOpen: Dispatch<SetStateAction<boolean>>
  generatePeriodsFormMethods: UseFormReturn<GeneratePeriodsParams>
  handleGeneratePeriods: (payload: GeneratePeriodsParams) => Promise<void>
  isLoadingGeneratePeriods: boolean

  navigate: any
  previousPage: string
}

export default function useCompanyPeriods({
  assetCompanyID,
}: CompanyPeriodsHookProps): ICompanyPeriodsHook {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])

  const { pathname } = useLocation()

  const isOnPeriodsView: boolean =
    splitPathsIntoArray(pathname).filter(path => path === COMPANY_PATHS.PERIODS).length > 0

  const year = !searchParams.get('year') ? undefined : parseInt(searchParams.get('year'))

  // Get years
  const {
    data: years,
    isLoading: isLoadingYears,
    isSuccess: isSuccessGettingYears,
    isFetching: isRefetchingYears,
  } = useGetYearsQuery({ assetCompanyID })

  // Check if yearin search param is present in GetYears
  const isYearApplicable: boolean = years?.filter(y => y === year).length > 0

  // Company has periods of years > 0
  const companyHasPeriods: boolean = years?.length > 0

  const mostRecentGeneratedFiscalYear = (): null | number => {
    if (!companyHasPeriods || isLoadingYears) return null

    return years[years.length - 1]
  }

  // Update search params after getting years
  useEffect(
    function updateYearInSearchParam() {
      if (
        isSuccessGettingYears &&
        companyHasPeriods &&
        !isRefetchingYears &&
        isOnPeriodsView
      ) {
        const mostRecentYear = years[years.length - 1]

        // If no year in search param, or if year isn't applicable, set as most recent
        if (year === undefined || !isYearApplicable) {
          setSearchParams({
            ...searchParamsObject,
            year: mostRecentYear.toString(),
          })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingYears, year]
  )

  // Get periods, skip until years or if no years
  const shouldSkipPeriods: boolean =
    !isSuccessGettingYears ||
    year === undefined ||
    !isYearApplicable ||
    !companyHasPeriods ||
    !isOnPeriodsView

  const {
    data: periods,
    isLoading: isLoadingPeriods,
    isFetching: isRefetchingPeriods,
    isSuccess: isSuccessFetchingPeriods,
  } = useFetchPeriodsQuery(
    {
      assetCompanyID,
      year,
    },
    {
      skip: shouldSkipPeriods,
    }
  )

  // React-hook-form
  const methods = useForm<EditPeriodsPayload>({
    resolver: yupResolver(editPeriodsSchema),
    mode: 'onChange',
    defaultValues: periods ? { Periods: [], AllowWeekends: true } : {},
  })

  const {
    reset,
    formState: { dirtyFields, isValid, isSubmitting, errors },
    watch,
    register,
    control,
  } = methods

  useEffect(
    function resetFormWhenYearChanges() {
      if (isSuccessFetchingPeriods) {
        reset({ Periods: periods, AllowWeekends: true })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessFetchingPeriods, year, isRefetchingPeriods]
  )

  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      onBlur: field.onBlur,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  const havePercentagesChanged = (): boolean => {
    if (!dirtyFields.Periods) {
      return false
    }

    for (const period of dirtyFields?.Periods) {
      if (period?.CP_PERCENT) {
        return true
      }
    }

    return false
  }

  // Period table columns
  const { fields, isLoading: isLoadingFields, isSuccess: isSuccessFields } = useFields()

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false)

  const tableColumns = useMemo<Column<Period>[]>(
    () => [
      {
        Header: fields.CP_PERIOD,
        accessor: 'CP_PERIOD',
        maxWidth: 64,
      },
      {
        Header: 'Period Start',
        accessor: 'CP_START_DATE', 
        Cell: props => {
          if (!isEditing) return formatDisplayDate(props.value.toString(), DateFormat)          
          return formatDisplayDate(props.value.toString(), DateFormat)
        },     
      },
      {
        Header: fields.CP_DATE,
        accessor: 'CP_DATE',
        Cell: props => {
          if (!isEditing) return formatDisplayDate(props.value.toString(), DateFormat)
          const name: `Periods.${number}.${PeriodKey}` = `Periods.${props.row.index}.CP_DATE`

          const error = errors?.Periods?.[props.row.index]?.CP_DATE
          return (
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <CustomDatePicker
                  {...getDateProps(field)}
                  disabled={isSubmitting}
                  autoFocus={props.row.index === 0}
                  error={error !== undefined}
                  errorMsg={error?.message}
                />
              )}
            />
          )
        },
      },
      {
        Header: 'Percentage of Fiscal Year',
        accessor: 'CP_PERCENT',
        Cell: props => {
          if (!isEditing) return props.value

          const name: `Periods.${number}.${PeriodKey}` = `Periods.${props.row.index}.CP_PERCENT`

          const error = errors?.Periods?.[props.row.index]?.CP_PERCENT

          return (
            <Input
              {...register(name, { valueAsNumber: true })}
              error={error !== undefined}
              errorMsg={error?.message ?? ''}
              type="number"
              iconRight={'%'}
              disabled={isSubmitting}
            />
          )
        },
      },
      {
        Header: 'YTD Percentage',
        accessor: 'CP_YTD_PCT',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessFields, isEditing, errors]
  )

  // Check before rendering
  const isLoading: boolean =
    isLoadingYears ||
    isLoadingPeriods ||
    (companyHasPeriods && !isSuccessFetchingPeriods && !isRefetchingPeriods) ||
    isLoadingFields

  useEffect(
    function resetFormWithData() {
      if (companyHasPeriods && isSuccessFetchingPeriods) {
        reset({ Periods: periods, AllowWeekends: true })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingPeriods, isLoadingYears]
  )

  // Year selector
  const yearOptions: ReactSelectOptions = []
  if (isSuccessGettingYears && companyHasPeriods) {
    for (const year of years) {
      yearOptions.push({
        label: year.toString(),
        value: year,
      })
    }
  }

  function handleChangeYear(e: { label: string; value: any }): void {
    setSearchParams({
      ...searchParamsObject,
      year: e.value.toString(),
    })
  }

  // Handle edit periods
  const [editPeriods, { isLoading: isLoadingEditPeriods }] = useEditPeriodsMutation()

  async function handleEditPeriods(payload: EditPeriodsPayload): Promise<void> {
    try {
      await editPeriods(payload).unwrap()
      reset(payload)
      toast.success(`Successfully updated periods for fiscal year ${year}`)
    } catch (error) {
      toast.error(ERROR_REQUEST)
    } finally {
      resetUi()
    }
  }

  const sumOfPercentages: number = parseInt(
    new BigNumber(
      watch('Periods')
        ?.map(period => period.CP_PERCENT)
        .reduce((partialSum, a) => partialSum + a, 0)
    ).toPrecision(4)
  )

  // Discard edit periods
  function discardChanges(): void {
    reset()
    setIsEditing(false)
    setIsDiscardModalOpen(false)
  }

  // Reset UI
  function resetUi() {
    setIsDiscardModalOpen(false)
    setIsEditing(false)
    setIsGeneratePeriodsModalOpen(false)
  }

  // GENERATE PERIODS
  const generatePeriodsFormDefaultValues: GeneratePeriodsParams = {
    AssetCompanyID: assetCompanyID,
    FromYear: companyHasPeriods ? 0 : getCurrentYear(), // if company has fiscal years, FromYear is not used
    ToYear: companyHasPeriods ? years[years.length - 1] + 1 : getCurrentYear(),
    AllowWeekends: true,
  }

  const generatePeriodsFormMethods = useForm<GeneratePeriodsParams>({
    resolver: yupResolver(generatePeriodSchema(companyHasPeriods, years)),
    mode: 'onChange',
    defaultValues: generatePeriodsFormDefaultValues,
  })

  useEffect(
    function resetGenerateForm() {
      generatePeriodsFormMethods.reset(generatePeriodsFormDefaultValues)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyHasPeriods]
  )

  const [isGeneratePeriodsModalOpen, setIsGeneratePeriodsModalOpen] = useState<boolean>(
    false
  )

  const [
    generatePeriods,
    { isLoading: isLoadingGeneratePeriods },
  ] = useGeneratePeriodsMutation()

  async function handleGeneratePeriods(payload: GeneratePeriodsParams) {
    try {
      await generatePeriods(payload).unwrap()

      toast.success('Successfully generated periods.')

      setSearchParams({
        ...searchParamsObject,
        year:
          payload.FromYear !== 0
            ? payload.FromYear.toString()
            : (mostRecentGeneratedFiscalYear() + 1).toString(),
      })
    } catch (error) {
      toast.error(ERROR_REQUEST)
    } finally {
      resetUi()
    }
  }

  function getCheckBoxProps(field: any) {
    return {
      checked: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  // This is used to navigate back to the previous page when the user clicks "View Periods" inside the asset details page
  const navigate = useNavigate()
  const [previousPage, setPreviousPage] = useState(null)

  useEffect(() => {
    const storedPage = sessionStorage.getItem('previousPage')
    if (storedPage) {
      setPreviousPage(storedPage)
    }
    return () => {
      // Cleanup function to clear the previousPage storage when this component is unmounted
      sessionStorage.removeItem('previousPage')
    }
  }, [])

  return {
    control,
    getCheckBoxProps,
    years,
    companyHasPeriods,
    mostRecentGeneratedFiscalYear: mostRecentGeneratedFiscalYear(),
    year,
    periods,
    isRefetchingPeriods,
    tableColumns,
    isLoading,
    isEditing,
    setIsEditing,
    yearOptions,
    handleChangeYear,
    isDiscardModalOpen,
    discardChanges,
    setIsDiscardModalOpen,
    sumOfPercentages,
    fields,
    havePercentagesChanged: havePercentagesChanged(),

    methods,
    isDirty: dirtyFields.Periods !== undefined,
    isValid,

    handleEditPeriods,
    isLoadingEditPeriods,

    isGeneratePeriodsModalOpen,
    setIsGeneratePeriodsModalOpen,
    generatePeriodsFormMethods,
    handleGeneratePeriods,
    isLoadingGeneratePeriods,

    navigate,
    previousPage,
  }
}
