import { DepreciationClass, FieldLabels } from '@api/models'
import { Button, Card, Text } from '@components/common'
import {
  DepreciationClassCardsState,
  DepreciationClassFormState,
} from '@components/depreciation-class/DepreciationClass.types'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import { isNullOrUndefined } from '@utils/basic'

export interface DeprecClassFormSectionProps {
  fields: FieldLabels
  cardsState: DepreciationClassCardsState
  setCardsState: Dispatch<SetStateAction<DepreciationClassCardsState>>
  formState: DepreciationClassFormState
  isEdited: boolean
  deprec: DepreciationClass
  title: string
  formElement: JSX.Element
  displayElement: JSX.Element
  stateKey: keyof DepreciationClassCardsState
  canEdit: boolean
}

let EditingEnabled = false

const DeprecClassFormSection = ({
  formState,
  cardsState,
  setCardsState,
  isEdited,
  title,
  formElement,
  displayElement,
  stateKey,
  canEdit = true,
}: DeprecClassFormSectionProps) => {
  const urlParams = useParams()
  const { data: isAssetDesc } = useGetAssetQuery({
    assetCompanyID: !isNullOrUndefined(parseInt(urlParams.companyID))
      ? parseInt(urlParams.companyID)
      : 0,
    assetID: !isNullOrUndefined(urlParams.assetID) ? urlParams.assetID : '',
  })

  if (!isNullOrUndefined(isAssetDesc)) {
    EditingEnabled =
      isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false
  }
  const cardAddOn = () => {
    if (!canEdit || formState !== 'can-edit') return null

    if (isEdited) return <Text light>Edited</Text>

    return EditingEnabled ? (
      isEdited
    ) : (
      <Button
        appearance="text"
        onClick={() =>
          setCardsState(prevState => {
            return {
              ...prevState,
              [stateKey]: !prevState[stateKey],
            }
          })
        }
      >
        {cardsState[stateKey] ? 'Close' : 'Edit'}
      </Button>
    )
  }
  return (
    <Card title={title} addon={cardAddOn()}>
      {cardsState[stateKey] ? formElement : displayElement}
    </Card>
  )
}

export default DeprecClassFormSection
