import { apiSlice } from '@api'
import {
  FetchMaintenancesResponse,
  ListMaintenanceResponse,
  MaintenanceContract,
  MaintenanceExtraFields,
} from '@api/maintenance/models'
import { StandardFetchRequestPayload } from '@api/models'
import { serializeURLParams, formatISODate } from '@utils/basic'

export const maintenanceEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    // MAINTENANCE CONTRACTS
    fetchMaintenances: build.query<
      FetchMaintenancesResponse,
      StandardFetchRequestPayload
    >({
      query: ({ pageNumber, itemsPerPage, filter }) =>
        `Maintenance/FetchMaintenances?${serializeURLParams({
          pageNumber,
          itemsPerPage,
          filter,
        })}`,
      transformResponse: (res: FetchMaintenancesResponse) => ({
        count: res.count,
        list: res.list.map(m => ({
          ...m,
          MN_CP_FRM: formatISODate(m.MN_CP_FRM),
          MN_CP_TO: formatISODate(m.MN_CP_TO),
          MN_LB_FRM: formatISODate(m.MN_LB_FRM),
          MN_LB_TO: formatISODate(m.MN_LB_TO),
          MN_PT_FRM: formatISODate(m.MN_PT_FRM),
          MN_PT_TO: formatISODate(m.MN_PT_TO),
          MN_OT_FRM: formatISODate(m.MN_OT_FRM),
          MN_OT_TO: formatISODate(m.MN_OT_TO),
        })),
      }),
      providesTags: ['maintenance'],
    }),
    listMaintenance: build.query<ListMaintenanceResponse, void>({
      query: () => `Maintenance/ListMaintenance`,
    }),
    createMaintenance: build.mutation<void, MaintenanceContract>({
      query: contract => {
        let payload: MaintenanceContract = { ...contract }

        payload.MN_CP_FRM = formatISODate(payload.MN_CP_FRM)
        payload.MN_CP_TO = formatISODate(payload.MN_CP_TO)
        payload.MN_LB_FRM = formatISODate(payload.MN_LB_FRM)
        payload.MN_LB_TO = formatISODate(payload.MN_LB_TO)
        payload.MN_PT_FRM = formatISODate(payload.MN_PT_FRM)
        payload.MN_PT_TO = formatISODate(payload.MN_PT_TO)
        payload.MN_OT_FRM = formatISODate(payload.MN_OT_FRM)
        payload.MN_OT_TO = formatISODate(payload.MN_OT_TO)

        return {
          url: 'Maintenance/Create',
          method: 'POST',
          body: payload,
        }
      },
      invalidatesTags: ['maintenance'],
    }),
    editMaintenance: build.mutation<void, MaintenanceContract>({
      query: contract => {
        let payload: MaintenanceContract = { ...contract }

        if (payload.MN_LB_FRM.length < 8) payload.MN_LB_FRM = payload.MN_CP_FRM
        if (payload.MN_LB_TO.length < 8) payload.MN_LB_TO = payload.MN_CP_TO
        if (payload.MN_PT_FRM.length < 8) payload.MN_PT_FRM = payload.MN_CP_FRM
        if (payload.MN_PT_TO.length < 8) payload.MN_PT_TO = payload.MN_CP_TO
        if (payload.MN_OT_FRM.length < 8) payload.MN_OT_FRM = payload.MN_CP_FRM
        if (payload.MN_OT_TO.length < 8) payload.MN_OT_TO = payload.MN_CP_TO

        return {
          url: 'Maintenance/Edit',
          method: 'PUT',
          body: payload,
        }
      },
      invalidatesTags: ['maintenance'],
    }),
    deleteMaintenance: build.mutation<void, MaintenanceContract>({
      query: ({ ...contract }) => ({
        url: 'Maintenance/Delete',
        method: 'POST',
        body: { ...contract },
      }),
      invalidatesTags: ['maintenance'],
    }),
    getExtraMaintenanceFields: build.query<MaintenanceExtraFields, void>({
      query: () => `Maintenance/GetExtraFields`,
    }),
  }),
  overrideExisting: false,
})

export const {
  useListMaintenanceQuery,
  useFetchMaintenancesQuery,
  useCreateMaintenanceMutation,
  useEditMaintenanceMutation,
  useDeleteMaintenanceMutation,
  useGetExtraMaintenanceFieldsQuery,
} = maintenanceEndpoints
