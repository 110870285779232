import {
  Button,
  Card,
  Divider,
  Flexbox,
  Form,
  Heading,
  Icon,
  Modal,
  Note,
  Select,
  Spacer,
  Text,
} from '@components/common'
import Layout from '@components/layout/Layout'
import LocalSideNav from '@components/layout/LocalSideNav'
import { useAssetHistory } from '@hooks'
import { MANUAL_HISTORY_FORM_ID } from '@constants'
import { FormProvider } from 'react-hook-form'
import AssetHistoryLoading from './AssetHistoryLoading'
import AssetHistoryTable from './AssetHistoryTable'
import EmptyHistoryState from './EmptyHistoryState'
import ProfileNotMaintained from './ProfileNotMaintained'
import { useGetSettingsQuery } from '@api'
import AssetHistoryManualForm from './AssetHistoryManualForm'
import { formatDisplayDate } from '@utils/basic'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import usePermission from '@components/Auth/Permissions/usePermission'

interface Props {
  assetCompanyID: number
  assetID: string
}

const AssetHistory = ({ assetCompanyID, assetID }: Props) => {
  const {
    isLoading,
    profileName,
    navItems,
    isProfileMaintained,
    periodOptions,
    handlePeriodChange,
    period,
    hasHistory,
    tableData,
    tableColumns,

    isLoadingLockHistory,
    handleLockHistory,
    isLockModalOpen,
    setIsLockModalOpen,
    isLocked,
    formMethods,
    formState,
    openForm,
    closeForm,
    fields,
    handleChargeFormSubmit,
    isLoadingFormSubmit,
    isManual,
  } = useAssetHistory({
    assetCompanyID,
    assetID,
  })

  const { data: isAssetDesc } = useGetAssetQuery({
    assetCompanyID,
    assetID,
  })

  const { canEditAssets: canEdit } = usePermission()

  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  return (
    <>
      {/* Lock modal*/}
      <Modal
        title={`${isLocked ? 'Unlock' : 'Lock'} history for period ${formatDisplayDate(
          period,
          DateFormat
        )}`}
        isOpen={isLockModalOpen}
        close={() => setIsLockModalOpen(false)}
        form="lock_history_form"
        isActionLoading={isLoadingLockHistory}
        actionLabel={isLocked ? 'Unlock history' : 'Lock history'}
        intent={isLocked && !isManual ? 'default' : 'danger'}
      >
        <Form id="lock_history_form" noValidate onSubmit={handleLockHistory}>
          <Text p>
            All history <b>up to {formatDisplayDate(period, DateFormat)}</b> for asset{' '}
            {assetID} will be{' '}
            {`${
              isLocked || isManual
                ? isManual
                  ? 'unlocked. The unlocking history may affect previous reporting'
                  : 'unlocked'
                : 'locked, and you will be unable to edit any of the book information values for this asset'
            }`}
            .
          </Text>
        </Form>
      </Modal>

      <FormProvider {...formMethods}>
        {/* Form modal */}
        <Modal
          isOpen={formState.isOpen}
          title={`Add history  record - Asset ${assetID}: ${isAssetDesc.AS_DESC}`}
          form={MANUAL_HISTORY_FORM_ID}
          close={closeForm}
          actionLabel={`Add record`}
          isActionLoading={isLoadingFormSubmit}
          width={640}
          actionDisabled={!formMethods.formState.isValid}
        >
          <AssetHistoryManualForm
            fields={fields}
            handleChargeFormSubmit={handleChargeFormSubmit}
            //chargeTypeCode={type}
            isCreate={formState.isCreate}
          />
        </Modal>
      </FormProvider>

      {/* Main layout */}
      <Layout hasSidebar sidebarElement={<LocalSideNav items={navItems} />}>
        <Flexbox alignItems="center" justifyContent="space-between">
          <div>
            <Heading type="h2">{profileName} Depreciation History</Heading>
          </div>

          {!isLoading && isProfileMaintained && hasHistory && (
            <Flexbox>
              {canEdit && (
                <>
                  <Button
                    onClick={() => setIsLockModalOpen(true)}
                    appearance="clear"
                    icon={isLocked ? <Icon name="Unlock" /> : <Icon name="Lock" />}
                  >
                    {isLocked ? 'Unlock history' : 'Lock history'}
                  </Button>
                  <Spacer x="mini" />
                </>
              )}
              <Select
                options={periodOptions}
                onChange={handlePeriodChange}
                value={{
                  label: (
                    <>
                      <b>Period:</b> {formatDisplayDate(period, DateFormat)}{' '}
                    </>
                  ),
                  value: period,
                }}
                width={'164px'}
              />
            </Flexbox>
          )}
        </Flexbox>

        <Divider spacing="default" />

        {isLoading ? (
          <AssetHistoryLoading />
        ) : isProfileMaintained ? (
          hasHistory ? (
            <>
              {isLocked && (
                <>
                  <Note
                    appearance="elevated"
                    intent={isLocked ? 'danger' : 'default'}
                    content={
                      isLocked && !isManual
                        ? 'Depreciation history for this period is locked.'
                        : 'Depreciation history for this period is manually entered and locked.'
                    }
                  />
                  <Spacer y="default" />
                </>
              )}
              <Card spacing="none" overflow="hidden">
                <AssetHistoryTable
                  data={tableData}
                  columns={tableColumns}
                  isManual={isManual}
                />
              </Card>
            </>
          ) : (
            <EmptyHistoryState
              profileName={profileName}
              assetID={assetID}
              openForm={openForm}
            />
          )
        ) : (
          <ProfileNotMaintained assetCompanyID={assetCompanyID} profileName={profileName} />
        )}
      </Layout>
    </>
  )
}

export default AssetHistory
