import { StyleObject } from '@styles-api/css.types'

export const link: StyleObject = {
  color: 'var(--primary-color)',
  display: 'inline-flex',
  alignItems: 'center',
  ':hover': {
    textDecoration: 'underline',
  },
  ':active': {
    color: 'var(--primary-color500)',
  },
}
export const icon: StyleObject = {
  height: 'var(--scale550)',
  width: 'var(--scale550)',
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: 'var(--scale100)',
  transform: 'translateY(-1px)',
}
