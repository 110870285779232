import { Button, Grid, Heading, Spacer } from '@components/common'
import { useStyletron } from 'styletron-react'
import * as styles from './styles'
import { reportsMenuList } from './reportsMenuList'
import { Outlet, useNavigate } from 'react-router-dom'
import ReportMenuNavLink from './ReportMenuNavLink'
import { AnimatePresence, motion as m } from 'framer-motion'

const ReportsMenuPanel = ({
  activeReportTitle,
  showMenu,
  setShowMenu,
  selectedCategoryIndex,
}: {
  activeReportTitle: string
  showMenu: Boolean
  setShowMenu: Function
  selectedCategoryIndex?: number
}) => {
  const [css] = useStyletron()

  const animationProps = {
    initial: { opacity: 0, x: -100 },
    animate: { opacity: 1, x: 0 },
  }

  const navigate = useNavigate()
  const goBack = () => {
    if (showMenu) {
      setShowMenu(false)
    } else {
      setShowMenu(true)
    }
    navigate('/reports')
  }

  const isShowingMenu = showMenu ? true : false

  const reportsInSelectedCategory = [reportsMenuList[selectedCategoryIndex]]

  return (
    <div className={css(styles.container(isShowingMenu))} id="reports-menu-panel">
      <AnimatePresence>
        {showMenu &&
          reportsInSelectedCategory.map((item, index) => (
            <m.div key={index} {...animationProps}>
              <Grid>
                {item.reports.map((report, indexReport) => (
                  <Grid.Item s={12} m={6} l={4} key={indexReport}>
                    <ReportMenuNavLink
                      keyValue={indexReport}
                      to={report.path}
                      title={report.title}
                      description={report.description}
                      showMenu={showMenu}
                      setShowMenu={setShowMenu}
                    />
                  </Grid.Item>
                ))}
              </Grid>
              <Spacer y="default" />
            </m.div>
          ))}
      </AnimatePresence>

      {!showMenu && (
        <>
          <Heading type="h2" primary>
            {activeReportTitle} Report
          </Heading>
          <Spacer y="default" />
          <Outlet />
          <Button
            onClick={goBack}
            style={{ position: 'relative', bottom: 'var(--scale900)' }}
          >
            Back
          </Button>
        </>
      )}
    </div>
  )
}

export default ReportsMenuPanel
