import { AssetCharge, assetChargeSchema, AssetChargeType, FieldLabels } from '@api/models'
import { toast } from '@components/common'
import {
  useCreateChargeMutation,
  useDeleteChargeMutation,
  useEditChargeMutation,
  useFetchAssetChargeQuery,
} from '@api'
import { ERROR_REQUEST } from '@constants'
import useFields from './useFields'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

interface Props {
  assetCompanyID: number
  assetID: string
  type: AssetChargeType
  systemNumber: number
  isCreate: boolean
}

interface IAssetChargesHook {
  isLoading: boolean
  fields: FieldLabels

  formMethods: UseFormReturn<AssetCharge>
  handleChargeFormSubmit: (payload: AssetCharge) => Promise<void>
  isLoadingFormSubmit: boolean

  handleDeleteCharge: (payload: AssetCharge) => Promise<void>

  setDeleteChargeState: Dispatch<SetStateAction<DeleteChargeState>>
  deleteChargeState: DeleteChargeState

  isLoadingDeleteCharge: boolean
}

export interface DeleteChargeState {
  isModalOpen: boolean
  charge: AssetCharge | undefined
}

export default function useAssetCharges({
  assetCompanyID,
  assetID,
  type,
  systemNumber,
  isCreate,
}: Props): IAssetChargesHook {
  //
  const { fields, isLoading: isLoadingFields } = useFields()

  const [deleteChargeState, setDeleteChargeState] = useState<DeleteChargeState>({
    isModalOpen: false,
    charge: undefined,
  })

  // React hook form
  const formMethods = useForm<AssetCharge>({
    resolver: yupResolver(assetChargeSchema),
    mode: 'all',
    defaultValues: {
      ...assetChargeSchema.cast({}),
      AssetCompanyID: assetCompanyID,
      AssetID: assetID,
      CH_ACM_CD: type,
    },
  })

  const { reset: resetForm } = formMethods
  const navigate = useNavigate()

  // Get charge
  const {
    data: charge,
    isLoading: isLoadingCharge,
  } = useFetchAssetChargeQuery(systemNumber, { skip: isCreate })

  useEffect(() => {
    if (!isLoadingCharge && !isCreate) {
      resetForm({
        ...assetChargeSchema.cast(charge),
        AssetCompanyID: assetCompanyID,
        AssetID: assetID,
        CH_ACM_CD: type,
      })
    }
  }, [isLoadingCharge])

  // Form submit
  const [createCharge, { isLoading: isLoadingCreateCharge }] = useCreateChargeMutation()
  const [editCharge, { isLoading: isLoadingEditCharge }] = useEditChargeMutation()

  async function handleChargeFormSubmit(payload: AssetCharge): Promise<void> {
    payload['DispositionType'] = null
    try {
      if (isCreate) {
        await createCharge(payload).unwrap()

        toast.success(`Successfully created ${type.toLowerCase()} record.`)
        navigate(`..`)
      } else {
        await editCharge(payload).unwrap()

        toast.success(`Successfully edited ${type.toLowerCase()} record.`)
        resetForm(charge)
        navigate(`..`)
      }
    } catch (error: any) {
      toast.error(error.data)
    }
  }

  const isLoadingFormSubmit: boolean = isLoadingCreateCharge || isLoadingEditCharge

  // Delete charge
  const [deleteCharge, { isLoading: isLoadingDeleteCharge }] = useDeleteChargeMutation()

  async function handleDeleteCharge(payload: AssetCharge): Promise<void> {
    try {
      await deleteCharge(payload).unwrap()

      toast.success(`Successfully deleted ${type.toLowerCase()} record`)
    } catch (error) {
      toast.error(ERROR_REQUEST)
    } finally {
      setDeleteChargeState({
        isModalOpen: false,
        charge: undefined,
      })
    }
  }

  // Check before renderingS
  const isLoading: boolean = isLoadingCharge || isLoadingFields

  return {
    isLoading,
    fields,

    formMethods,

    handleChargeFormSubmit,
    isLoadingFormSubmit,

    deleteChargeState,
    setDeleteChargeState,
    handleDeleteCharge,
    isLoadingDeleteCharge,
  }
}
