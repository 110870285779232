import { Button, Card, EmptyState } from '@common'
import Template from '@components/layout/Template'

interface NoCustomDepreciationProps {
  openAddForm: () => void
  canEdit: boolean
}

const NoCustomDepreciation = ({ openAddForm, canEdit }: NoCustomDepreciationProps) => {
  return (
    <Template maxWidth="compact">
      <Card>
        <EmptyState
          heading={`No custom depreciation records have been added to this asset.`}
          primaryAction={
            <Button appearance="primary" onClick={() => openAddForm()} hidden={!canEdit}>
              Add custom depreciation record
            </Button>
          }
        />
      </Card>
    </Template>
  )
}
export default NoCustomDepreciation
