import { StyleObject } from '@styles-api/css.types'

export const fieldset: StyleObject = {
  display: 'block',
}
export const legend: StyleObject = {
  fontSize: 'var(--small-font-size)',
  color: 'var(--text-light)',
  display: 'block',
}

export const optionWrapper: StyleObject = {
  marginBottom: 'var(--scale100)',
}
export const label = ({ disabled }): StyleObject => {
  return {
    display: 'inline-flex',
    alignItems: 'center',
    width: 'auto',
    cursor: disabled ? 'unset' : 'pointer',
    position: 'relative',
    fontWeight: 'var(--font-weight-normal)',
    color: disabled ? 'var(--text-muted)' : 'var(--text-color)',
  }
}
export const buttonBase: StyleObject = {
  borderRadius: '100%',
  borderStyle: 'solid',
  borderWidth: '1px',
  height: 'var(--scale500)',
  width: 'var(--scale500)',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'var(--base-color)',
}
export const buttonInner = ({ checked, disabled }) => {
  return {
    backgroundColor: checked
      ? disabled
        ? 'var(--text-muted)'
        : 'var(--primary-color)'
      : 'var(--base-color)',
    height: 'var(--scale200)',
    width: 'var(--scale200)',
    borderRadius: '100%',
  }
}
export const getButtonState = ({ checked, disabled }) => {
  return {
    borderColor: !disabled
      ? checked
        ? 'var(--primary-color)'
        : 'var(--shade400)'
      : 'var(--text-muted)',
  }
}
export const getFocus = ({ focus }) => {
  if (focus) {
    return {
      boxShadow: '0 0 0 1px #ffffff, 0 0 0 2px var(--primary-color)',
    }
  }
}
export const labelText: StyleObject = {
  fontSize: 'var(--base-font-size)',
  paddingLeft: 'var(--scale300)',
}
//

export const nativeInput = ({ disabled }): StyleObject => {
  return {
    position: 'absolute',
    opacity: 0,
    cursor: disabled ? 'unset' : 'pointer',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
  }
}
