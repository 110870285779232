import { AssetTransferParams } from '@api/models'
import { Heading } from '@components/common'
import { StyleObject } from '@styles-api/css.types'
import { useFormContext } from 'react-hook-form'
import { useStyletron } from 'styletron-react'

interface Props {
  activeTransfers: { field: string; from: string; to: string }[]
}

const listStyle: StyleObject = {
  marginTop: 'var(--scale300)',
}
const listItemStyle: StyleObject = {
  marginBottom: 'var(--scale300)',
  ':last-child': {
    marginBottom: '0',
  },
}

const AssetStructureChangeSummary = ({ activeTransfers }: Props) => {
  const { watch } = useFormContext<AssetTransferParams>()

  const [css] = useStyletron()

  return (
    <>
      <Heading type="h3">Review transfer details</Heading>

      <ul className={css(listStyle)}>
        {activeTransfers.map(item => (
          <li key={item.field} className={css(listItemStyle)}>
            <b>{item.field}:</b>
            <br />
            From <i>{item.from}</i> to <i> {item.to}</i>
          </li>
        ))}

        <li className={css(listItemStyle)} style={{ marginTop: '20px' }}>
          <b>Posting period:</b> Fiscal year {watch('year')}, period {watch('period')}
        </li>
      </ul>
    </>
  )
}

export default AssetStructureChangeSummary
