import {
  useChangeRoleMutation,
  useDeleteUserMutation,
  useDisableUserMutation,
  useEnableUserMutation,
  useFetchUsersQuery,
} from '@api/admPortal'
import { ChangeRolePayload, UserListItem } from '@api/models'
import { getCurrentUserRoles } from '@components/Auth/profile'
import { toast, useModal } from '@components/common'
import useTableParams from '@hooks/useTableParams'
import { UserRole } from '@components/Auth/UserRole'
import { useState } from 'react'
import { useGetSubscribedApplicationRolesQuery } from '@api/admPortal/applicationAPI'
import { useAuth as useOidcAuth } from 'oidc-react'

export const useUserTable = () => {
  const [filterState, setFilterState] = useState({})

  const [selectedUser, setSelectedUser] = useState<UserListItem>(undefined)

  const { userData } = useOidcAuth()

  const searchKey: keyof UserListItem = 'email'
  const initialItemsPerPage = 20

  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError,
    isFetching: isRefetchingUsers,
  } = useFetchUsersQuery({ ...fetchRequestPayload, userName: userData?.profile?.name })

  const {
    data: subbedAppRoles,
    isLoading: isLoadingAppRoles,
  } = useGetSubscribedApplicationRolesQuery()

  const isAdmin: boolean = getCurrentUserRoles().includes(
    UserRole.ADMIN || UserRole.FIXED_ASSETS_ADMIN
  )

  let rolesOptions = []

  if (subbedAppRoles) {
    const roleNames = subbedAppRoles?.roleNamesBySubscribedApplicationNames['WFA']

    if (roleNames) {
      rolesOptions = roleNames.map(
        role => {
          let roleLabel = role.substring(13)

          return { label: roleLabel, value: role }
        }
      )
    }
  } else {
    rolesOptions = [
      { label: 'Admin', value: 'Fixed Assets Admin' },
      { label: 'General User', value: 'Fixed Assets General User' },
      { label: 'Read Only User', value: 'Fixed Assets Read Only User' },
    ]
  }

  // MODALS

  const {
    modalIsOpen: isUserInviteModalOpen,
    triggerModal: openUserInviteModal,
    closeModal: closeUserInviteModal,
  } = useModal()

  const {
    modalIsOpen: isDeleteUserModalOpen,
    triggerModal: triggerDeleteUserModal,
    closeModal: closeDeleteUserModal,
  } = useModal()

  function openDeleteUserModal(user: UserListItem): void {
    setSelectedUser(user)
    triggerDeleteUserModal()
  }

  const {
    modalIsOpen: isDisableUserModalOpen,
    triggerModal: triggerDisableUserModal,
    closeModal: closeDisableUserModal,
  } = useModal()

  function openDisableUserModal(user: UserListItem): void {
    setSelectedUser(user)
    triggerDisableUserModal()
  }

  const {
    modalIsOpen: isEnableUserModalOpen,
    triggerModal: triggerEnableUserModal,
    closeModal: closeEnableUserModal,
  } = useModal()

  function openEnableUserModal(user: UserListItem): void {
    setSelectedUser(user)
    triggerEnableUserModal()
  }

  // MUTATIONS

  // delete
  const [deleteUser, { isLoading: isLoadingDeleteUser }] = useDeleteUserMutation() // TODO: create this mutation
  async function handleDeleteUser(payload: UserListItem): Promise<void> {
    try {
      await deleteUser(payload).unwrap()
      closeDeleteUserModal()
      toast.success(`Successfully deleted user "${payload.email}" from users.`)
    } catch (error) {
      toast.error('There was an unexpected error deleting user.')
    }
  }

  // disable
  const [disableUser, { isLoading: isLoadingDisableUser }] = useDisableUserMutation() // TODO: create this mutation
  async function handleDisableUser(payload: UserListItem): Promise<void> {
    try {
      await disableUser(payload).unwrap()
      closeDisableUserModal()
      toast.success(`Successfully disabled user "${payload.email}".`)
    } catch (error) {
      toast.error('There was an unexpected error disabling user.')
    }
  }

  // enable
  const [enableUser, { isLoading: isLoadingEnableUser }] = useEnableUserMutation() // TODO: create this mutation
  async function handleEnableUser(payload: UserListItem): Promise<void> {
    try {
      await enableUser(payload).unwrap()
      closeEnableUserModal()
      toast.success(`Successfully enabled user "${payload.email}".`)
    } catch (error) {
      toast.error('There was an unexpected error enabling user.')
    }
  }

  const [changeRole] = useChangeRoleMutation()
  async function handleRoleChange(payload: ChangeRolePayload): Promise<void> {
    try {
      await changeRole(payload).unwrap()
      toast.success(
        `Successfully change ${payload.email} role to ${payload.changeRoleTo.substring(13)}`
      )
    } catch (error) {
      toast.error('There was an unexpected error changing this user role.')
    }
  }

  return {
    isAdmin,
    users,
    subbedAppRoles,
    isLoading: isLoadingUsers || isLoadingAppRoles,
    isError,
    searchKey,
    searchValue,
    isRefetchingUsers,
    pageNumber,
    itemsPerPage,
    changePage,
    handleSearch,
    sortByInitialState,
    updateSortSearchParam,

    selectedUser,
    setSelectedUser,

    isUserInviteModalOpen,
    openUserInviteModal,
    closeUserInviteModal,

    isDeleteUserModalOpen,
    triggerDeleteUserModal,
    closeDeleteUserModal,
    openDeleteUserModal,

    isDisableUserModalOpen,
    triggerDisableUserModal,
    closeDisableUserModal,
    openDisableUserModal,

    isEnableUserModalOpen,
    triggerEnableUserModal,
    closeEnableUserModal,
    openEnableUserModal,

    handleDeleteUser,
    isLoadingDeleteUser,

    handleDisableUser,
    isLoadingDisableUser,

    handleEnableUser,
    isLoadingEnableUser,

    rolesOptions,
    handleRoleChange,
  }
}
