import { Palette } from '@styles-api/palette.types'
import { StyleObject } from '@styles-api/css.types'

export const listWrapper = ({ inCard }) => {
  const inCardStyles = (inCard: boolean) => {
    if (inCard)
      return {
        marginLeft: 'calc(var(--scale600) * -1)',
        marginRight: 'calc(var(--scale600) * -1)',
        paddingLeft: 'var(--scale400)',
        paddingRight: 'var(--scale400)',
        marginTop: 'calc(var(--scale600) * -1)',
        paddingTop: 'var(--scale400)',
        backgroundColor: 'var(--shade50)',
        borderTopLeftRadius: 'var(--radius1)',
        borderTopRightRadius: 'var(--radius1)',
      }
  }
  return {
    borderBottomWidth: '1px',
    marginBottom: 'var(--scale600)',
    ...inCardStyles(inCard),
  }
}
export const list: StyleObject = {
  whiteSpace: 'nowrap',
  marginBottom: '-1px',
  overflowY: 'hidden',
}
export const button = ({ selected }) => {
  let backgroundColor: Palette
  if (selected) backgroundColor = 'var(--base-color)'
  const borders = (selected: boolean) => {
    if (selected)
      return {
        borderTopWidth: '1px',
        borderLeftWidth: '1px',
        borderRightWidth: '1px',
        fontWeight: 'var(--font-weight-bold)',
      }
  }
  return {
    paddingLeft: 'var(--scale600)',
    paddingRight: 'var(--scale600)',
    paddingTop: 'var(--scale300)',
    paddingBottom: 'var(--scale300)',
    borderTopLeftRadius: 'var(--radius1)',
    borderTopRightRadius: 'var(--radius1)',
    display: 'inline-block',
    cursor: 'pointer',
    marginBottom: '-1px',
    backgroundColor,
    ...borders(selected),
    color: selected ? 'var(--text-color)' : 'var(--text-light)',
    ':hover': {
      color: 'var(--text-color)',
    },
    ':active': {
      color: 'var(--text-light)',
    },
    transition: 'color var(--hover-transition)',
  }
}
