import HttpClient from "../HttpClient";

export class AssetTableColumnsApi extends HttpClient {
    private static classInstance?: AssetTableColumnsApi;

    public constructor () {
        super (`/FldParm`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new AssetTableColumnsApi();
        }

        return this.classInstance;
    }

    public fetch = () => this.instance.get(`/GetUserTableParams`);
    public list = (filter: string, pageNumber = 1, itemsPerPage = 20) => this.instance.get(`/ListFields?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`);
}