import { Button, Flexbox, Heading, Modal, Spacer } from '@components/common'
import { GLPOST_FORM_ID } from '@constants'

import GLPostForm from './GLPostForm'
import GLPostsTable from './GLPostsTable'
import useGLPosts from './useGLPosts'
import usePermission from '@components/Auth/Permissions/usePermission'
import ImportData from '@components/ImportData'
import { download } from '@utils/basic'

const GLPosts = () => {
  const {
    createGLPostModalIsOpen,
    openCreateGLPostModal,
    handleCreateGLPost,
    closeCreateGLPostModal,
    selectedGLPost,
    isLoadingCreateGLPost,
    selectedGLPostKeys,
  } = useGLPosts()
  const { canEditSettings: canEdit } = usePermission()

  const importButton = (
    <Button onClick={() => download(`${process.env.PUBLIC_URL}/templates/glpost.csv`, 'glpost.csv')} appearance='text'>
      Download an import template here.
    </Button>
  )

  return (
    <>
      <header>
        <Flexbox justifyContent="space-between">
          <Heading type="h1">General Ledger Codes</Heading>
          <Flexbox alignItems='center' flexDirection='row' gap={12}>
            <ImportData action='glposts' title='Import GL Post Codes' modalDesc={importButton} />
            <Button onClick={openCreateGLPostModal} appearance="primary" hidden={!canEdit}>
              Add GL code
            </Button>
          </Flexbox>
        </Flexbox>
      </header>

      <Spacer y="default" />

      <GLPostsTable {...useGLPosts()} canEdit={canEdit} />

      <Modal
        isOpen={createGLPostModalIsOpen}
        title={`Add a new GL code`}
        form={GLPOST_FORM_ID}
        close={closeCreateGLPostModal}
        actionLabel={'Add GL code'}
        isActionLoading={isLoadingCreateGLPost}
        width={576}
      >
        <GLPostForm
          handleSubmit={handleCreateGLPost}
          isEdit={false}
          initialValues={selectedGLPost}
          selectedGLPostKeys={selectedGLPostKeys}
        />
      </Modal>
    </>
  )
}
export default GLPosts
