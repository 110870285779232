import {
  Button,
  Flexbox,
  Heading,
  Modal,
  Spacer,
} from '@components/common'
import { SEGMENT_FORM_ID, SEGMENT_TYPE_FORM_ID } from '@constants'

import SegmentForm from './SegmentForm'
import SegmentsTable from './SegmentsTable'
import useSegments from './useSegments'
import usePermission from '@components/Auth/Permissions/usePermission'
import ImportData from '@components/ImportData'
import { download } from '@utils/basic'
import SegmentTypeForm from './SegmentTypeForm/SegmentTypeForm'

const Segments = () => {
  const {
    createSegmentModalIsOpen,
    openCreateSegmentModal,
    handleCreateSegment,
    closeCreateSegmentModal,
    newSegmentType,
    handleChangeSegmentTypeInForm,
    selectSegmentTypeOptions,
    isLoadingCreateSegment,
    navigate,
    previousPage,

    changeStructureModalIsOpen,
    openChangeStructureModal,
    closeChangeStructureModal,
    handleChangeStructure,
    isLoadingChangeStructure,
    selectedStructureType,
    handleChangeSegmentTypeInEditForm,
  } = useSegments()

  const { canEditSettings: canEdit } = usePermission()

  const importButton = (
    <Button
      onClick={() =>
        download(`${process.env.PUBLIC_URL}/templates/segments.csv`, 'segments.csv')
      }
      appearance="text"
    >
      Download an import template here.
    </Button>
  )

  return (
    <>
      <Modal
        isOpen={changeStructureModalIsOpen}
        title={`Edit Segment Type`}
        form={SEGMENT_TYPE_FORM_ID}
        close={closeChangeStructureModal}
        actionLabel={'Edit Segment Type'}
        isActionLoading={isLoadingChangeStructure}
      >
        <SegmentTypeForm
          handleSubmit={handleChangeStructure}
          isEdit={false}
          initialValues={{
            value: undefined,
            KY_DESC: '',
          }}
          selectSegmentTypeOptions={selectSegmentTypeOptions}
          handleChange={handleChangeSegmentTypeInEditForm}
          selectedStructureType={selectedStructureType}
        />
      </Modal>

      <Modal
        isOpen={createSegmentModalIsOpen}
        title={`Add a new Segment`}
        form={SEGMENT_FORM_ID}
        close={closeCreateSegmentModal}
        actionLabel={'Add Segment'}
        isActionLoading={isLoadingCreateSegment}
      >
        <SegmentForm
          handleSubmit={handleCreateSegment}
          isEdit={false}
          initialValues={{
            KY_DESC: '',
            KY_VALUE: '',
            KY_NR: undefined,
          }}
          selectSegmentTypeOptions={selectSegmentTypeOptions}
          handleChange={handleChangeSegmentTypeInForm}
          newSegment={newSegmentType}
        />
      </Modal>

      <header>
        <Flexbox justifyContent="space-between">
          <Heading type="h1">Segments</Heading>
          <Flexbox alignItems="center" flexDirection="row" gap={12}>
            <Button
              onClick={openChangeStructureModal}
              appearance="primary"
              hidden={!canEdit}
            >
              Edit segment types
            </Button>
            <ImportData
              action="segments"
              title="Import Segments"
              modalDesc={importButton}
            />
            <Button onClick={openCreateSegmentModal} appearance="primary" hidden={!canEdit}>
              Add segment
            </Button>
          </Flexbox>
        </Flexbox>
      </header>

      <Spacer y="default" />

      <SegmentsTable {...useSegments()} canEdit={canEdit} />

      {previousPage && (
        <Button onClick={() => navigate(previousPage)} style={{ marginTop: '10px' }}>
          Go back to asset
        </Button>
      )}
    </>
  )
}
export default Segments
