import View from '@components/layout/View'
import EditAssetIDForm from '@components/settings/edit-assetid/EditAssetIDForm'

const EditAssetIDView = () => {
  return (
    <View title="Edit AssetID" showHeader={false}>
      <EditAssetIDForm />
    </View>
  )
}

export default EditAssetIDView
