import HttpClient from '../HttpClient'
import { DeprecProps } from '../models'

export class DepreciationApi extends HttpClient {
  private static classInstance?: DepreciationApi

  public constructor() {
    super(`/Deprecs`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new DepreciationApi()
    }

    return this.classInstance
  }

  public getTaxCategoryClasses = (
    filter: string,
    pageNumber: number,
    itemsPerPage: number
  ) =>
    this.instance.get(
      `/FetchTaxCategoryClasses?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`
    )
  public listDepreciations = (type: string) =>
    this.instance.get(`/ListDepreciations?type=${type}`)
  public getDepreciationTypes = () => this.instance.get(`/GetDepreciationTypes`)
  public getDepreciation = (assetCompanyId: number, deprecId: string, profile: string) =>
    this.instance.get(
      `/GetDepreciation?assetCompanyId=${assetCompanyId}&deprecid=${deprecId}&profile=${profile}`
    )
  public getAssetDepreciation = (assetCompanyId: number, assetId: string, type: string) =>
    this.instance.get(
      `/GetAssetDepreciation?assetCompanyId=${assetCompanyId}&assetId=${assetId}&type=${type}`
    )
  public importDepreciation = (file: any) => this.instance.post(`/ImportDepreciation`, file)
  public getExtraFields = () => this.instance.get(`/GetExtraFields`)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (data: any) => this.instance.post(`/Delete`, data)
  public createAssetSpecificDepreciation = (
    assetCompanyID: number,
    assetID: string,
    deprecProfile: string // 'A' | 'X' | 'S' | 'O'
  ) =>
    this.instance.post(
      `/CreateAssetSpecificDepreciation/${assetCompanyID}/${assetID}/${deprecProfile}`
    )

  public editAssetSpecificDepreciation = (deprec: DeprecProps) =>
    this.instance.put(`/EditAssetSpecificDepreciation`, deprec)

  public deleteAssetSpecificDepreciation = (
    assetCompanyID: number,
    assetID: string,
    deprecProfile: string // 'C' | 'T' | 'S' | 'O'
  ) =>
    this.instance.post(
      `/DeleteAssetSpecificDepreciation/${assetCompanyID}/${assetID}/${deprecProfile}`
    )

  public doesDeprecExist = (objectID: string, profile: string) =>
    this.instance.get(`/DoesDeprecExist?objectID=${objectID}&profile=${profile}`)
}
