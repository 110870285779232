import usePermission from '@components/Auth/Permissions/usePermission'
import { Button, Flexbox, Heading, Spacer } from '@components/common'
import CompaniesTable from '@components/CompaniesTable'
import ImportData from '@components/ImportData'
import View from '@components/View'
import { COMPANY_PATHS } from '@constants'
import { download } from '@utils/basic'

const CompaniesView = () => {
  const { canEditCompanies: canEdit } = usePermission()
  
  const importButton = (
    <Button
      onClick={() => download(`${process.env.PUBLIC_URL}/templates/companies.csv`, 'companies.csv')}
      appearance="text"
    >
      Download an import template here.
    </Button>
  )

  return (
    <View title={`Companies`}>
      <Flexbox justifyContent="space-between">
        <Heading type="h1">Companies</Heading>

        <Flexbox alignItems="center" flexDirection="row" gap={12}>
          <ImportData action="companies" title="Import Companies" modalDesc={importButton} />
          <Button
            link={`/${COMPANY_PATHS.ROOT}/${COMPANY_PATHS.CREATE}`}
            appearance="primary"
            hidden={!canEdit}
          >
            Add new company
          </Button>
        </Flexbox>
      </Flexbox>

      <Spacer y="default" />

      <CompaniesTable />
    </View>
  )
}
export default CompaniesView
