import { serializeURLParams } from '@utils/basic'
import {
  GetFieldsResponse,
  FetchFieldParmsResponse,
  FetchFieldParmsPayload,
  FieldParm,
  GetUserTableParamsResponse,
  RawUserTableParamsResponse,
  SetUserTableParamsPayload,
} from '@api/fieldParm/models'
import { apiSlice } from '..'

export const fieldParmEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    // FIELDS & USER TABLE PARAMS
    getFields: build.query<GetFieldsResponse, void>({
      query: () => `Fldparm/ListFields`,
      providesTags: ['fieldParms'],
    }),
    fetchFieldParms: build.query<FetchFieldParmsResponse, FetchFieldParmsPayload>({
      query: payload => `Fldparm/FetchFields?${serializeURLParams(payload)}`,
      providesTags: ['fieldParms'],
    }),
    editFieldParm: build.mutation<void, FieldParm>({
      query: fieldParm => ({
        url: 'Fldparm/Edit',
        method: 'PUT',
        body: { ...fieldParm },
      }),
      invalidatesTags: ['fieldParms'],
    }),
    getUserTableParams: build.query<GetUserTableParamsResponse, void>({
      query: () => `Fldparm/GetUserTableParams`,
      transformResponse: (res: RawUserTableParamsResponse) => {
        if (!res) return null
        return {
          Columns: JSON.parse(res.Columns),
          Order: JSON.parse(res.Order),
          UserID: res.UserID,
        }
      },
      providesTags: ['userTableParams'],
    }),
    setUserTableParams: build.mutation<void, SetUserTableParamsPayload>({
      query: ({ columns, order }) => ({
        url: `Fldparm/SetUserTableParams`,
        method: 'POST',
        body: { columns: JSON.stringify(columns), order: JSON.stringify(order) },
      }),
      invalidatesTags: ['userTableParams'],
    }),
  }),
  overrideExisting: false,
})

export const {
  // FIELDS & USER TABLE PARAMS
  useGetFieldsQuery,
  useGetUserTableParamsQuery,
  useSetUserTableParamsMutation,
  useFetchFieldParmsQuery,
  useEditFieldParmMutation,
} = fieldParmEndpoints
