import React, { useState, useRef } from 'react'
import { PopMenuProps, PopMenuSubComponents } from './PopMenu.types'

import {
  popMenu_bottomLeftStyles,
  popMenu_bottomRightStyles,
  popMenu_rightEndStyles,
  popMenu_rightStartStyles,
  popMenu_leftStartStyles,
  popMenu_leftEndStyles,
  popMenu__menuStyles,
  popMenu__wrapperStyles,
  popMenu__triggerWrapper,
  popMenu_primary__menuStyles,
} from './Styles'

import { AnimatePresence, motion } from 'framer-motion'
import { useStyletron } from 'styletron-react'
import { useOnClickOutside } from '@hooks'

import Menu from './Menu'
import Item from './Item'
import Divider from './Divider'
import { WHILETAP_MOTION } from '@constants'

const PopMenu: React.FC<PopMenuProps> & PopMenuSubComponents = ({
  content,
  position = 'bottomLeft',
  intent = 'default',
  trigger,
  width,
  wrapperClassName,
  triggerClassName,
  appearance = 'default',
  ...props
}) => {
  const [visible, setVisible] = useState(false)

  function stopPropagation(e) {
    if (!e) e = window.event

    //IE9 & Other Browsers
    if (e.stopPropagation) {
      e.stopPropagation()
    }
    //IE8 and Lower
    else {
      e.cancelBubble = true
    }
  }

  const popMenu = e => {
    setVisible(!visible)
    stopPropagation(e)
  }

  const node = useRef()
  useOnClickOutside(node, () => setVisible(false))

  /* ====================================================== */
  /*                      APPLY STYLES                      */
  /* ====================================================== */
  const [css] = useStyletron()

  var wrapperStyles = css(popMenu__wrapperStyles)
  var menuStyles = css(popMenu__menuStyles)
  if (appearance === 'primary') {
    menuStyles += ' ' + css(popMenu_primary__menuStyles)
  }
  /* ---------------------- POSITIONS --------------------- */
  if (position === 'bottomLeft') {
    var xTranslate = '0%'
    var yTranslate = '0%'
    menuStyles += ' ' + css(popMenu_bottomLeftStyles)
  }
  if (position === 'bottomRight') {
    xTranslate = '0%'
    yTranslate = '0%'
    menuStyles += ' ' + css(popMenu_bottomRightStyles)
  }
  if (position === 'rightStart') {
    xTranslate = '100%'
    yTranslate = '0%'
    menuStyles += ' ' + css(popMenu_rightStartStyles)
  }
  if (position === 'rightEnd') {
    xTranslate = '100%'
    yTranslate = '-100%'
    menuStyles += ' ' + css(popMenu_rightEndStyles)
  }
  if (position === 'leftStart') {
    xTranslate = '-100%'
    yTranslate = '0%'
    menuStyles += ' ' + css(popMenu_leftStartStyles)
  }
  if (position === 'leftEnd') {
    xTranslate = '-100%'
    yTranslate = '-100%'
    menuStyles += ' ' + css(popMenu_leftEndStyles)
  }

  /* ====================================================== */
  /*                         MARKUP                         */
  /* ====================================================== */
  return (
    <div
      className={`${wrapperStyles} ${wrapperClassName ? wrapperClassName : ''}`}
      ref={node}
    >
      <motion.button
        onClick={popMenu}
        className={`${css(popMenu__triggerWrapper)} ${
          triggerClassName ? triggerClassName : ''
        }`}
        type="button"
        whileTap={WHILETAP_MOTION}
      >
        {trigger}
      </motion.button>
      <AnimatePresence>
        {visible && (
          <Menu
            className={menuStyles}
            xTranslate={xTranslate}
            yTranslate={yTranslate}
            width={width}
          >
            {props.children}
          </Menu>
        )}
      </AnimatePresence>
    </div>
  )
}

PopMenu.Item = Item
PopMenu.Divider = Divider

export default PopMenu
