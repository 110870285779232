import { Modal, toast } from '@components/common'
import { COMMENT_FORM_ID } from '@constants'
import useFields from '@hooks/useFields'
import { getTodaysDate } from '@utils/basic'
import { useLazyGetCommentQuery, useUpdateCommentMutation } from '@api'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { FormProvider, useForm } from 'react-hook-form'
import CommentForm from './CommentForm'
import { Comment, commentSchema, CommentType } from '@api/models'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import useCommentsHook from './useCommentsHook'

interface InlineCommentEditorProps {
  title: string
  assetCompanyID: number
  type: CommentType
  objectID: string
  key1?: string
  key2?: string
  key3?: string
  key4?: string
  key5?: number
  isOpen?: boolean
  close?: any
  canEdit?: boolean
}

const InlineCommentEditor = ({
  title,
  assetCompanyID,
  type,
  objectID,
  key1,
  key2,
  key3,
  key4,
  key5,
  isOpen,
  close,
  canEdit = true,
}: InlineCommentEditorProps) => {
  const { fields } = useFields()

  const { showComment, setShowComment } = useCommentsHook({
    assetCompanyID,
    type,
    objectID,
    key1,
    key2,
    key3,
    key4,
  })

  const [
    getComment,
    { data: comment, isLoading: isLoadingComment, isSuccess: isSuccessGettingComment },
  ] = useLazyGetCommentQuery()
  const [updateComment] = useUpdateCommentMutation()

  useEffect(() => {
    if (isOpen) {
      getComment({ assetCompanyID, type, objectID, key1, key2, key3, key4, key5 })
    }
  }, [assetCompanyID, getComment, key1, key2, key3, key4, key5, isOpen, objectID, type])

  useEffect(() => {
    if (isSuccessGettingComment && comment != null) {
      methods.reset({ ...comment })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, isSuccessGettingComment])

  const defaultComment: Comment = {
    AssetCompanyID: assetCompanyID,
    CO_ORI_CD: type,
    CO_GRP_ID: objectID,
    CO_KEY1: key1,
    CO_KEY2: key2,
    CO_KEY3: key3,
    CO_KEY4: key4,
    CO_KEY5: key5,
    CO_AD_DAT: getTodaysDate(),
    CO_COMMENT: '',
    CO_USR: '',
  }

  const methods = useForm<Comment>({
    defaultValues: { ...defaultComment },
    reValidateMode: 'onBlur',
    resolver: yupResolver(commentSchema),
  })

  async function onSave(formValues: Comment) {
    try {
      await updateComment(formValues).unwrap()
      closeModal()
      toast.success('Comment saved successfully')
    } catch (error) {}
  }

  function closeModal() {
    if (comment == null) methods.reset({ ...defaultComment })
    else methods.reset({ ...comment })
    close()
  }

  const { data: isAssetDesc, isLoading: isAssetDescLoading } = useGetAssetQuery({
    assetCompanyID,
    assetID: objectID,
  })

  const EditingEnabled =
    isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false

  if (isLoadingComment || isAssetDescLoading) {
    return <p>Loading...</p>
  }
  return (
    <>
      <FormProvider {...methods}>
        <Modal
          isOpen={isOpen}
          close={closeModal}
          title={title}
          width={500}
          height={312}
          form={COMMENT_FORM_ID}
          actionLabel="Save comment"
          actionDisabled={EditingEnabled || !canEdit}
          isActionLoading={methods.formState.isSubmitting}
          type="submit"
          action={methods.handleSubmit(onSave)}
          hideFooter={comment == null && !showComment}
        >
          <CommentForm
            fields={fields}
            methods={methods}
            canEdit={canEdit}
            comment={comment}
            showComment={showComment}
            setShowComment={setShowComment}
            hideSaveBtn
          />
        </Modal>
      </FormProvider>
    </>
  )
}

export default InlineCommentEditor
