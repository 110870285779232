import { APP_STATE_TYPES } from '@api/models'
import { InitialRequestsModal, useInitialRequests } from '@components/initialRequests'
import { Outlet } from 'react-router-dom'
import { useAppState } from '@hooks'
import AppStateModal from '@components/AppStateModal'

const PrivateRouteTemplate = () => {
  const { isLoading: isLoadingInitialReqs } = useInitialRequests()
  const { isLoading: isLoadingAppState, appState } = useAppState()

  if (isLoadingInitialReqs || isLoadingAppState) return <InitialRequestsModal />

  if (appState.StateType !== APP_STATE_TYPES.IDLE)
    return <AppStateModal appState={appState} />

  return <Outlet />
}

export default PrivateRouteTemplate
