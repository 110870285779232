import AttachmentEditor from '@components/attachment/AttachmentEditor'
import View from '@components/View'

const AssetAttachmentView = () => {
  return (
    <View title="Asset Attachments">
      <AttachmentEditor type="Asset" />
    </View>
  )
}

export default AssetAttachmentView
