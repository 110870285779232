import { AssetChargeType } from '@api/assets/models'
import useAssetCharges from '@hooks/useAssetCharges'
import AssetChargesTable from './AssetChargesTable'
import { Button, Card, Flexbox, Modal, Table } from '@components/common'
import { FormProvider } from 'react-hook-form'
import NoAssetCharges from './NoAssetCharges'
import AssetChargesLoading from './AssetChargesLoading'
import Template from '@components/layout/Template'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { useNavigate } from 'react-router-dom'
import usePermission from '@components/Auth/Permissions/usePermission'

interface Props {
  assetCompanyID: number
  assetID: string
  type: AssetChargeType
}

const AssetCharges = ({ assetCompanyID, assetID, type }: Props) => {
  const {
    isLoading,
    hasCharges,
    charges,
    chargeType,
    setDeleteChargeState,
    deleteChargeState,
    handleDeleteCharge,
    isLoadingDeleteCharge,
    openForm,
    fields,
    formMethods,
  } = useAssetCharges({
    assetCompanyID,
    assetID,
    type,
  })

  const { data: isAssetDesc, isLoading: isAssetDescLoading } = useGetAssetQuery({
    assetCompanyID,
    assetID,
  })

  const EditingEnabled =
    isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false

  const navigate = useNavigate()
  const { canEditAssets: canEdit } = usePermission()

  if (isLoading || isAssetDescLoading)
    return (
      <Template maxWidth="compact">
        <AssetChargesLoading />
      </Template>
    )

  return (
    <FormProvider {...formMethods}>
      {/* Form modal */}

      {/* Delete charge modal */}
      <Modal
        isOpen={deleteChargeState.isModalOpen}
        close={() => setDeleteChargeState({ isModalOpen: false, charge: undefined })}
        title={`Delete ${chargeType.toLowerCase()} record`}
        intent="danger"
        action={() => handleDeleteCharge(deleteChargeState.charge)}
        isActionLoading={isLoadingDeleteCharge}
        actionLabel={`Delete ${chargeType.toLowerCase()} record`}
      >
        Are you sure you want to delete {chargeType.toLowerCase()} record{' '}
        <b>{deleteChargeState.charge?.CH_DESC}</b>?
      </Modal>

      {/* Main UI */}

      {!hasCharges ? (
        <Template maxWidth="compact">
          <NoAssetCharges openForm={openForm} chargeType={chargeType} canEdit={canEdit} />
        </Template>
      ) : (
        <Template maxWidth="compact">
          <Card spacing="none" overflow="hidden">
            <Table.Actions>
              <Flexbox justifyContent="space-between" alignItems="center">
                <div>
                  {charges.length} total {chargeType.toLowerCase()} record
                  {charges.length > 1 ? 's' : ''}
                </div>
                <Button
                  appearance="primary"
                  disabled={EditingEnabled}
                  onClick={() => {
                    openForm(true)
                    navigate(`create`)
                  }}
                  hidden={!canEdit}
                >
                  Add {chargeType.toLowerCase()} record
                </Button>
              </Flexbox>
            </Table.Actions>

            <AssetChargesTable
              charges={charges}
              setDeleteChargeState={setDeleteChargeState}
              openForm={openForm}
              fields={fields}
              chargeTypeCode={type}
              canEdit={canEdit}
            />
          </Card>
        </Template>
      )}
    </FormProvider>
  )
}

export default AssetCharges
