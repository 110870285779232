import { useStyletron } from 'styletron-react'

import { Button, Flexbox, Icon, Text } from '@common'
import useAuth from '@hooks/useAuth'
import ProfileCircle from '@components/settings/profile/ProfileCircle'
import LinkComponent from '@components/common/Link/Link'

const RightList = () => {
  const { signOut, isLoadingSignOut, userName } = useAuth()

  const user = {
    first_name: userName.split(' ')[0],
    last_name: userName.split(' ')[1],
  }

  return (
    <Flexbox
      justifyContent="center"
      alignItems="center"
      gap={12}
      style={{ marginRight: '14px' }}
    >
      <LinkComponent to="https://docs.worthitfixedassets.com/docs" external={true}>
        <Button
          icon={<Icon name="HelpCircle" />}
          size="compact"
          appearance="clear"
          shape="circle"
        />
      </LinkComponent>
      <Button
        size="compact"
        appearance="default"
        link="/settings/profile"
        style={{
          minWidth: '140px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <Flexbox flexDirection="row" justifyContent="center" alignItems="center" gap={10}>
          <ProfileCircle user={user} size={20} margin={0} />
          <Text style={{ color: 'black' }}>{userName}</Text>
        </Flexbox>
      </Button>
      <Button
        loading={isLoadingSignOut}
        size="compact"
        appearance="primary"
        onClick={() => signOut()}
        style={{ minWidth: '100px' }}
      >
        Sign out
      </Button>
    </Flexbox>
  )
}
export default RightList
