import {
  AssetCharge,
  assetChargeSchema,
  AssetChargeType,
  ASSET_CHARGE_TYPES,
  FieldLabels,
} from '@api/models'
import { toast } from '@components/common'
import {
  useCreateChargeMutation,
  useDeleteChargeMutation,
  useEditChargeMutation,
  useFetchAssetChargesQuery,
} from '@api'
import { ERROR_REQUEST } from '@constants'
import useFields from './useFields'
import { Dispatch, SetStateAction, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

interface Props {
  assetCompanyID: number
  assetID: string
  type: AssetChargeType
}

interface IAssetChargesHook {
  charges: AssetCharge[]
  isLoading: boolean
  hasCharges: boolean
  chargeType: string
  fields: FieldLabels

  formMethods: UseFormReturn<AssetCharge>
  formState: FormState
  openForm: (isCreate: boolean, charge?: AssetCharge) => void
  closeForm: () => void
  handleChargeFormSubmit: (payload: AssetCharge) => Promise<void>
  isLoadingFormSubmit: boolean

  handleDeleteCharge: (payload: AssetCharge) => Promise<void>

  setDeleteChargeState: Dispatch<SetStateAction<DeleteChargeState>>
  deleteChargeState: DeleteChargeState

  isLoadingDeleteCharge: boolean
}

interface FormState {
  isOpen: boolean
  isCreate: boolean
}

export interface DeleteChargeState {
  isModalOpen: boolean
  charge: AssetCharge | undefined
}

export default function useAssetCharges({
  assetCompanyID,
  assetID,
  type,
}: Props): IAssetChargesHook {
  //
  const { fields, isLoading: isLoadingFields } = useFields()

  const [formState, setFormState] = useState<FormState>({
    isOpen: false,
    isCreate: false,
  })

  const [deleteChargeState, setDeleteChargeState] = useState<DeleteChargeState>({
    isModalOpen: false,
    charge: undefined,
  })

  // React hook form
  const formMethods = useForm<AssetCharge>({
    resolver: yupResolver(assetChargeSchema),
    mode: 'all',
    defaultValues: {
      ...assetChargeSchema.cast({}),
      AssetCompanyID: assetCompanyID,
      AssetID: assetID,
      CH_ACM_CD: type,
    },
  })

  const { reset: resetForm } = formMethods
  const navigate = useNavigate()

  function openForm(isCreate: boolean, charge?: AssetCharge): void {
    if (formState.isCreate) {
      resetForm({
        ...assetChargeSchema.cast({}),
        AssetCompanyID: assetCompanyID,
        AssetID: assetID,
        CH_ACM_CD: type,
      })
    } else {
      resetForm(charge)
    }

    setFormState({
      isOpen: true,
      isCreate,
    })
  }

  function closeForm() {
    setFormState({
      isOpen: false,
      isCreate: false,
    })
  }

  // Get charges
  const { data: charges, isLoading: isLoadingCharges } = useFetchAssetChargesQuery({
    assetCompanyID,
    assetID,
    type,
  })

  const hasCharges: boolean = charges?.length > 0

  const chargeType: string = ASSET_CHARGE_TYPES.find(charge => charge.value === type)?.label

  // Form submit
  const [createCharge, { isLoading: isLoadingCreateCharge }] = useCreateChargeMutation()
  const [editCharge, { isLoading: isLoadingEditCharge }] = useEditChargeMutation()

  async function handleChargeFormSubmit(payload: AssetCharge): Promise<void> {
    try {
      if (formState.isCreate) {
        await createCharge(payload).unwrap()

        toast.success(`Successfully created ${chargeType.toLowerCase()} record.`)
        navigate(`..`)
      } else {
        await editCharge(payload).unwrap()

        toast.success(`Successfully edited ${chargeType.toLowerCase()} record.`)
      }
    } catch (error: any) {
      toast.error(error.data)
    }
  }

  const isLoadingFormSubmit: boolean = isLoadingCreateCharge || isLoadingEditCharge

  // Delete charge
  const [deleteCharge, { isLoading: isLoadingDeleteCharge }] = useDeleteChargeMutation()

  async function handleDeleteCharge(payload: AssetCharge): Promise<void> {
    try {
      await deleteCharge(payload).unwrap()

      toast.success(`Successfully deleted ${chargeType.toLowerCase()} record`)
    } catch (error) {
      toast.error(ERROR_REQUEST)
    } finally {
      setDeleteChargeState({
        isModalOpen: false,
        charge: undefined,
      })
    }
  }

  // Check before rendering
  const isLoading: boolean = isLoadingCharges || isLoadingFields

  return {
    charges,
    hasCharges,
    isLoading,
    chargeType,
    fields,

    formMethods,
    formState,
    openForm,
    closeForm,
    handleChargeFormSubmit,
    isLoadingFormSubmit,

    deleteChargeState,
    setDeleteChargeState,
    handleDeleteCharge,
    isLoadingDeleteCharge,
  }
}
