import { apiSlice } from '@api'

interface AssetIdentifiersOptions {
  label: string
  value: string
}

export interface identifiersResponse {
  primaryID: AssetIdentifiersOptions[]
  secondaryID: AssetIdentifiersOptions[]
  requisitioner: AssetIdentifiersOptions[]
  userCode1: AssetIdentifiersOptions[]
  userCode2: AssetIdentifiersOptions[]
  userCode3: AssetIdentifiersOptions[]
}

export const identifiersEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getAssetIdentifiers: build.query<identifiersResponse, void>({
      query: () => `Asset/GetAssetIdentifiers`,
      providesTags: ['identifiers'],
    }),
  }),
})

export const { useGetAssetIdentifiersQuery } = identifiersEndpoints
