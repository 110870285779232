import AssetCharges from '@components/asset/AssetCharges'
import View from '@components/View'
import { useParams } from 'react-router-dom'

const AssetPartialDisposalsView = () => {
  const params = useParams()

  return (
    <View title="Partial Disposals">
      <AssetCharges
        assetCompanyID={parseInt(params.companyID)}
        assetID={params.assetID}
        type="P"
      />
    </View>
  )
}

export default AssetPartialDisposalsView
