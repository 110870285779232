import React from 'react'
import { RadioGroupProps, RadioSubComponents } from './Radio.types'
import { RadioGroupContext } from './context'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

import RadioOption from './RadioOption'

const Radio: React.FC<RadioGroupProps> & RadioSubComponents = ({
  name,
  onChange,
  select,
  legend = '',
  style,
  className,
  ...props
}) => {
  const [css] = useStyletron()

  let fieldsetClassName = css(styles.fieldset)
  let legendClassName = css(styles.legend)

  return (
    <RadioGroupContext.Provider value={{ name, onChange, select, type: 'radio' }}>
      <fieldset {...props} style={style} className={fieldsetClassName}>
        <legend className={legendClassName}>{legend}</legend>
        {props.children}
      </fieldset>
    </RadioGroupContext.Provider>
  )
}

Radio.Option = RadioOption

export default Radio
