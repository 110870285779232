import { Flexbox, Modal, Spacer, Spinner, Text } from '@components/common'

const InitialRequestsModal = () => {
  return (
    <Modal title=" " isOpen={true} close={() => {}}>
      <Flexbox
        justifyContent="center"
        alignItems="center"
        height={100}
        flexDirection="column"
      >
        <Spinner size="large" />
        <Spacer y="default" />
        <Text bold>Getting things ready</Text>
      </Flexbox>
    </Modal>
  )
}

export default InitialRequestsModal
