import { Attachment, AttachmentType } from '@api/models'
import {
  AttachmentApi,
  useDeleteAttachmentMutation,
  useGetAssetAttachmentsQuery,
} from '@api'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  ReactSelectOption,
  ReactSelectOptions,
} from '@components/common/Select/Select.types'
import { useEffect, useState } from 'react'

interface UseAttachmentHookProps {
  type: AttachmentType
}

const useAttachmentHook = ({ type }: UseAttachmentHookProps) => {
  const [options, setOptions] = useState<Attachment[]>([])
  const [selectedOption, setSelectedOption] = useState<ReactSelectOption>(null)
  const [attachment, setAttachment] = useState<{
    isLoading: boolean
    url: string
    details: Attachment
  }>(null)
  const assetCompanyID = useSelector<any>(state => state.company) as number
  const params = useParams()

  const [
    deleteAttachment,
    { isLoading: isDeletingAttachment },
  ] = useDeleteAttachmentMutation()

  const {
    isLoading: isAssetAttachmentsLoading,
    data: assetAttachments,
  } = useGetAssetAttachmentsQuery(
    {
      assetID: type === 'Asset' ? params.assetID : '',
      assetCompanyID,
    },
    {
      skip: type !== 'Asset',
    }
  )

  let objectID = ''

  switch (type) {
    case 'Asset':
      objectID = params.assetID
      break
  }

  let isLoading = isAssetAttachmentsLoading
  let selectOptions: ReactSelectOptions = options.map(a => ({
    label: a.Comment,
    value: a.FileName,
  }))

  // initialize options based on type
  useEffect(() => {
    if (isLoading) return

    switch (type) {
      case 'Asset':
        setOptions(assetAttachments)
        if (assetAttachments.length > 0) {
          setSelectedOption({
            label: assetAttachments[0].Comment,
            value: assetAttachments[0].FileName,
          })
        } else {
          setSelectedOption(null)
        }
        break
    }
  }, [assetAttachments, isLoading, type])

  // download attachment and parse into blob
  useEffect(() => {
    if (selectedOption === null) {
      setAttachment(null)
    } else {
      setAttachment({ url: '', isLoading: true, details: null })
      AttachmentApi.getInstance()
        .download(selectedOption.value)
        .then((res: any) => {
          setAttachment({
            url: URL.createObjectURL(new Blob([res.data])),
            isLoading: false,
            details: assetAttachments.find(a => a.FileName === selectedOption.value),
          })
        })
    }
  }, [selectedOption, assetAttachments])

  function handleAttachmentSelection(option: ReactSelectOption) {
    setSelectedOption(option)
  }

  return {
    selectOptions,
    isLoading,
    selectedOption,
    handleAttachmentSelection,
    attachment,
    assetCompanyID:
      type === 'Asset' || type === 'Service' || type === 'Task/Activity'
        ? assetCompanyID
        : 0,
    objectID,
    deleteAttachment,
    isDeletingAttachment,
  }
}

export default useAttachmentHook
