import { Form, Grid, Input, Note, Select } from '@common'
import { SEGMENT_FORM_ID } from '@constants'
import { Segment, SegmentBase, SegmentType } from '@api/models'
import { useSegmentForm } from './useSegmentForm'

interface SegmentFormProps {
  isEdit: boolean
  initialValues: SegmentBase
  handleSubmit: (payload: Segment) => Promise<void>
  newSegment?: SegmentType
  selectSegmentTypeOptions?: SegmentType[]
  handleChange?: (e: { label: string; value: any }) => void
}

const SegmentForm = ({
  isEdit,
  initialValues,
  handleSubmit,
  selectSegmentTypeOptions,
  newSegment,
  handleChange,
}: SegmentFormProps) => {
  const { getField, handleFormSubmit } = useSegmentForm({
    initialValues,
    handleSubmit,
  })

  return (
    <Form id={SEGMENT_FORM_ID} onSubmit={handleFormSubmit} noValidate>
      <Grid>
        {!isEdit && (
          <>
            <Grid.Item>
              <Select
                value={newSegment}
                options={selectSegmentTypeOptions}
                onChange={handleChange}
                name="kY_NR"
                label="Segment Type"
                readOnly={isEdit}
                autoFocus={!isEdit}
                required={!isEdit}
              />
            </Grid.Item>
            <Grid.Item>
              <Input {...getField('KY_VALUE')} readOnly={isEdit} />
            </Grid.Item>
          </>
        )}

        <Grid.Item>
          <Input {...getField('KY_DESC')} autoFocus={isEdit} />
        </Grid.Item>
        {!isEdit && (
          <Grid.Item>
            <Note
              content={'Type and key cannot be changed once segment is added.'}
              intent="warning"
            />
          </Grid.Item>
        )}
      </Grid>
    </Form>
  )
}
export default SegmentForm
