import { reportRoutes } from '@routes'

type ReportsList = {
  category: string
  reports: { title: string; path: string }[]
}[]

function getReportList() {
  let reportMap = {}
  for (const route of reportRoutes) {
    if (!reportMap[route.category]) {
      reportMap[route.category] = {
        category: route.category,
        reports: [],
      }
    }
    reportMap[route.category].reports.push(route)
  }

  const reportList: ReportsList = Object.keys(reportMap).map(key => reportMap[key])
  return reportList
}

export const reportsList: ReportsList = getReportList()
