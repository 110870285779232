import React from 'react'

import { useStyletron } from 'styletron-react'
import * as styles from '../Styles'

export interface TableCellProps extends React.HTMLProps<HTMLTableCellElement> {
  bold?: boolean
  column?: any
}

const Cell: React.FC<TableCellProps> = ({ bold = false, ...props }) => {
  const [css] = useStyletron()
  let cellStyles = css({
    ...styles.cell({ disabled: props.disabled }),
  })
  if (props.column?.isSorted) cellStyles += ' ' + css(styles.cellColumnSorted)
  return (
    <div role="cell" className={cellStyles} {...props}>
      {props.children}
    </div>
  )
}

export default Cell
