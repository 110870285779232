import React from 'react'
import { DividerProps } from './Divider.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

const Divider: React.FC<DividerProps> = ({
  spacing,
  orientation = 'horizantal',
}) => {
  const [css] = useStyletron()

  let dividerStyles = css({
    ...styles.base,
    ...styles.getOrientation({ orientation }),
    ...styles.getSpacing({ spacing, orientation }),
  })

  return <div className={dividerStyles} />
}

export default Divider
