import { AllAssetDetailFields, FieldLabels } from '@api/models'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import {
  Flexbox,
  Grid,
  Input,
  Select,
  Spinner,
  Link,
  CustomDatePicker,
} from '@components/common'
import { useGetCompanyQuery, useListDepreciationsQuery } from '@api'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { useParams } from 'react-router-dom'
import { ASSET_PATHS, ASSETS_PATH } from '@constants'

interface Props {
  fields: FieldLabels
}

const AssetBookInformationForm = ({ fields }: Props) => {
  const params = useParams()

  const {
    register,
    formState: { errors, isSubmitting },
    control,
  } = useFormContext<AllAssetDetailFields>()

  const {
    getInputProps,
    getSelectProps,
    getDateProps,
  } = useFormFieldProps<AllAssetDetailFields>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  const {
    data: company,
    isLoading: isLoadingCompany,
    isSuccess: isSuccessGettingCompany,
  } = useGetCompanyQuery(parseInt(params.companyID))

  const {
    data: categories,
    isLoading: isLoadingCategories,
    isSuccess: isSuccessGettingCategories,
  } = useListDepreciationsQuery({
    profile: 'A',
  })

  const categoryOptions: ReactSelectOptions = []
  if (isSuccessGettingCategories) {
    for (const category of categories) {
      categoryOptions.push({
        label: `${category.ID} - ${category.Name}`,
        value: category.ID,
      })
    }
  }

  const {
    data: taxClasses,
    isLoading: isLoadingTaxClasses,
    isSuccess: isSuccessGettingTaxClasses,
  } = useListDepreciationsQuery(
    { profile: 'X' },
    { skip: !company?.CM_TAX || !isSuccessGettingCompany }
  )

  const taxClassesOptions: ReactSelectOptions = []
  if (isSuccessGettingTaxClasses) {
    for (const taxClass of taxClasses) {
      taxClassesOptions.push({
        label: `${taxClass.ID} - ${taxClass.Name}`,
        value: taxClass.ID,
      })
    }
  }

  if (isLoadingCategories || isLoadingCompany || isLoadingTaxClasses)
    return (
      <Flexbox justifyContent="center" alignItems="center" height={240}>
        <Spinner />
      </Flexbox>
    )

  return (
    <Grid>
      <Grid.Item>
        <Input {...getInputProps('AS_FIN_COS', 'currency')} min={0} autoFocus />
      </Grid.Item>
      <Grid.Item>
        <Controller
          control={control}
          name="AS_REC_DAT"
          render={({ field }) => (
            <CustomDatePicker
              {...getDateProps('AS_REC_DAT', field)}
              label={fields[`AS_REC_DAT`]}
            />
          )}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          {...getInputProps('AS_TAX_BUS', 'percentage')}
          type="number"
          max={100}
          min={0}
          iconRight={'%'}
        />
      </Grid.Item>
      <Grid.Item>
        <Input
          {...getInputProps('AS_FIN_SAL', 'currency')}
          type="number"
          icon="$"
          min={0}
        />
      </Grid.Item>
      <Grid.Item>
        <Controller
          control={control}
          name="AS_CAT_ID"
          render={({ field }) => (
            <Select
              {...getSelectProps('AS_CAT_ID', field, categoryOptions)}
              type="autocomplete"
              isSearchable
              helper={
                <div>
                  Sets the{' '}
                  <Link
                    to={`/${ASSETS_PATH}/${params.companyID}/${params.assetID}/${ASSET_PATHS.DEPRECIATION}/A`}
                  >
                    corporate depreciation profile
                  </Link>
                </div>
              }
            />
          )}
        />
      </Grid.Item>
      {company.CM_TAX && (
        <Grid.Item>
          <Controller
            control={control}
            name="AS_TCLS_ID"
            render={({ field }) => (
              <Select
                {...getSelectProps('AS_TCLS_ID', field, taxClassesOptions)}
                type="autocomplete"
                isSearchable
                helper={
                  <div>
                    Sets the{' '}
                    <Link
                      to={`/${ASSETS_PATH}/${params.companyID}/${params.assetID}/${ASSET_PATHS.DEPRECIATION}/X`}
                    >
                      tax depreciation profile
                    </Link>
                  </div>
                }
              />
            )}
          />
        </Grid.Item>
      )}
      {company.CM_SMH && (
        <Grid.Item>
          <Controller
            control={control}
            name="AS_SCLS_ID"
            render={({ field }) => (
              <Select
                {...getSelectProps('AS_SCLS_ID', field, taxClassesOptions)}
                type="autocomplete"
                isSearchable
                helper={
                  <div>
                    Sets the{' '}
                    <Link
                      to={`/${ASSETS_PATH}/${params.companyID}/${params.assetID}/${ASSET_PATHS.DEPRECIATION}/S`}
                    >
                      state depreciation profile
                    </Link>
                  </div>
                }
              />
            )}
          />
        </Grid.Item>
      )}
      {company.CM_BOK4_CD && (
        <Grid.Item>
          <Controller
            control={control}
            name="AS_4CLS_ID"
            render={({ field }) => (
              <Select
                {...getSelectProps('AS_4CLS_ID', field, taxClassesOptions)}
                type="autocomplete"
                isSearchable
                helper={
                  <div>
                    Sets the{' '}
                    <Link
                      to={`/${ASSETS_PATH}/${params.companyID}/${params.assetID}/${ASSET_PATHS.DEPRECIATION}/O`}
                    >
                      "other" depreciation profile
                    </Link>
                  </div>
                }
              />
            )}
          />
        </Grid.Item>
      )}
    </Grid>
  )
}

export default AssetBookInformationForm
