import { StyleObject } from '@styles-api/css.types'

export const container = ({ height }) => {
  let h
  if (height) h = height
  return {
    background: 'var(--elevated-surface-background4)',
    borderRadius: 'var(--radius4)',
    boxShadow: 'var(--shadow4)',
    position: 'relative',
    maxHeight: '90vh',
    minWidth: '440px',
    minHeight: `${h}px`,
    zIndex: 'var(--z4)',
    borderWidth: '1px',
  }
}
export const content: StyleObject = {
  paddingTop: 'var(--scale600)',
  paddingBottom: 'var(--scale600)',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
}
export const getContentHeight = ({ title, action, onSubmit }) => {
  if (title && (action || onSubmit)) {
    return {
      maxHeight: 'calc(90vh - 64px - 88px)',
      overflow: 'hidden auto',
    }
  } else if (title && (!action || !onSubmit)) {
    return {
      maxHeight: 'calc(90vh - 120px)',
      overflow: 'hidden auto',
    }
  } else if (!title && (action || onSubmit)) {
    return {
      maxHeight: 'calc(90vh - var(--size-default))',
      overflow: 'hidden auto',
    }
  }
}
export const header: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--border-color)',
  paddingTop: 'var(--scale600)',
  paddingBottom: 'var(--scale600)',
  paddingLeft: 'var(--scale600)',
  position: 'relative',
  backgroundColor: 'var(--shade100)',
  borderTopRightRadius: 'var(--radius1)',
  borderTopLeftRadius: 'var(--radius1)',
  justifyContent: 'space-between'
}
export const closeButton: StyleObject = {
  position: 'relative',
  top: 0,
  right: 0,
  backgroundColor: 'var(--transparent)!important',
  height: '100%',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    color: 'var(--primary-color)',
  },
  ':active': {
    color: 'var(--primary-color500)',
    ':hover': {
      color: 'var(--primary-color500)',
    },
  },
  ':focus': {
    color: 'var(--primary-color)',
  },
}
export const closeIcon: StyleObject = {
  height: 'var(--scale600)',
  width: 'var(--scale600)',
}
export const footer: StyleObject = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
  paddingBottom: 'var(--scale600)',
  paddingTop: 'var(--scale600)',
  backgroundColor: 'var(--background-color)',
  borderBottomLeftRadius: 'var(--radius1)',
  borderBottomRightRadius: 'var(--radius1)',
  borderTopWidth: '1px',
}
