import { StyleObject } from '@styles-api/css.types'

export default class SaveBarStyles {
  bar: StyleObject = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,

    height: 'var(--topbar-height)',
    backgroundColor: 'var(--shade50)',
    zIndex: 'var(--z2)',

    display: 'grid',
    gridTemplateColumns: 'var(--sidebar-width) 1fr',
    gridTemplateAreas: "'appSidebar appMainContent'",
    width: '100%',
    borderBottomWidth: '1px',
  }
  sidebarArea: StyleObject = {
    paddingLeft: 'var(--scale600)',
    paddingRight: 'var(--scale600)',
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
    lineHeight: '1',
  }
  logo: StyleObject = {
    height: 'var(--scale800)',
    marginRight: 'var(--scale400)',
  }
  contentArea: StyleObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 'var(--gap-large)',
    paddingRight: 'var(--gap-large)',
  }
  label: StyleObject = {}
  buttons: StyleObject = {
    display: 'flex',
  }
}

export const saveBarStyles: StyleObject = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 'var(--z3)',
  backgroundColor: 'var(--base-color)',
  borderBottomWidth: '1px',
  // borderColor: 'var(--warning-color200)',
  height: 'var(--topbar-height)',
}
export const saveBar__containerStyles: StyleObject = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 'calc(var(--gap-default) + var(--sidebar-width))',
  paddingRight: 'var(--gap-default)',
  paddingBottom: 'var(--gap-mini)',
  paddingTop: 'var(--gap-mini)',
}
export const saveBar__mainContentWrapperStyles: StyleObject = {
  width: '100%',
  padding: '0 var(--gapXL)',
}
export const saveBar__mainAreaStyles: StyleObject = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 'var(--sizeXXL)',
}
