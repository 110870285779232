import Segments from '@components/Segments'
import View from '@components/layout/View'

const SegmentsView = () => {
  return (
    <View title="Segments" showHeader={false}>
      <Segments />
    </View>
  )
}

export default SegmentsView
