import { TextSkeleton, Flexbox, ButtonSkeleton, InputSkeleton } from '@common'
import NoteSkeleton from '@components/common/Note/NoteSkeleton'

const AddAssetFormWrapperLoading = () => {
  return (
    <Flexbox justifyContent="space-between" flexDirection="column" height={480}>
      <TextSkeleton height="var(--scale1600)" />

      <TextSkeleton height="var(--scale950)" />

      <InputSkeleton label />

      <InputSkeleton label />

      <NoteSkeleton size="large" />

      <TextSkeleton height="var(--scale1000)" />

      <ButtonSkeleton placeholder="Create asset record" />
    </Flexbox>
  )
}
export default AddAssetFormWrapperLoading
