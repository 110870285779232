import React from 'react'

import { useStyletron } from 'styletron-react'
import * as styles from '../Styles'

export interface TableCellProps extends React.HTMLProps<HTMLTableCellElement> {
  bold?: boolean
}

const Cell: React.FC<TableCellProps> = ({ bold, ...props }) => {
  const [css] = useStyletron()

  return (
    <td
      {...props}
      className={`${css(styles.cell)} ${css(styles.cellBold(bold))}`}
    >
      <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        {props.children}
      </span>
    </td>
  )
}

export default Cell
