import { useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'

interface LocationState {
  templateDetails?: {
    isEdit?: boolean
    templateData: any
  }
}

export function useFormMethods(
  locationState: LocationState | undefined,
  defaultValues: any
) {
  const [isRetrievingTemplate, setIsRetrievingTemplate] = useState(!!locationState)
  const [isEditTemplate, setIsEditTemplate] = useState(
    locationState?.templateDetails?.isEdit != null
      ? locationState.templateDetails.isEdit
      : false
  )
  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState(
    locationState?.templateDetails
  )

  const initialValues = isRetrievingTemplate
    ? locationState?.templateDetails?.templateData || defaultValues
    : defaultValues

  const methods = useForm({
    defaultValues: initialValues,
  })

  // Update the state when locationState changes
  useEffect(() => {
    if (locationState) {
      setIsRetrievingTemplate(true)
      setIsEditTemplate(
        locationState.templateDetails?.isEdit != null
          ? locationState.templateDetails.isEdit
          : false
      )
      setSelectedTemplateDetails(locationState.templateDetails)
    } else {
      // If locationState is null, update only the retrieving template flag
      setIsRetrievingTemplate(false)
    }
  }, [locationState])

  return {
    methods,
    isRetrievingTemplate,
    isEditTemplate,
    selectedTemplateDetails,
  }
}
