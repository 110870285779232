import { useState } from 'react'
import { useLockScroll } from '@hooks'

const useSideSheet = () => {
  const [sideSheetIsOpen, setSideSheetIsOpen] = useState(false)

  function triggerSideSheet() {
    setSideSheetIsOpen(true)
  }
  function closeSideSheet() {
    setSideSheetIsOpen(false)
  }

  useLockScroll(sideSheetIsOpen === true)

  return {
    sideSheetIsOpen,
    triggerSideSheet,
    closeSideSheet,
  }
}
export default useSideSheet
