export default function stopPropagation(e) {
  if (!e) e = window.event

  //IE9 & Other Browsers
  if (e.stopPropagation) {
    e.stopPropagation()
  }
  //IE8 and Lower
  else {
    e.cancelBubble = true
  }
}
