import { StyleObject } from '@styles-api/css.types'

export const list: StyleObject = {
  display: 'block',
  listStyle: 'none',
}

export const item: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'var(--base-color)',
  cursor: 'move',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
  borderRadius: 'var(--radius1)',
  borderWidth: '1px',
  borderColor: 'var(--base-color)',
}
export const getHover = ({ isHovering }) => {
  return {
    backgroundColor: isHovering ? 'var(--primary-color50)' : 'var(--base-color)',
    borderColor: isHovering ? 'var(--primary-color50)' : 'var(--base-color)',
  }
}
export const getGrabbingStyles = ({ isGrabbing }) => {
  return {
    boxShadow: isGrabbing ? 'var(--shadow2)' : 'none',
    cursor: isGrabbing ? 'grabbing' : 'grab',
    zIndex: isGrabbing ? 1 : 0,
  }
}

export const icon: StyleObject = {
  height: 'var(--scale550)',
  width: 'var(--scale550)',
  color: 'var(--text-light)',
  marginRight: 'var(--gap-compact)',
}
