import React from 'react'
import { EmptyStateProps } from './EmptyState.types'
import { useStyletron } from 'styletron-react'
import { Flexbox, Heading, Spacer, Text } from '../'
import { StyleObject } from '@styles-api/css.types'

const EmptyState: React.FC<EmptyStateProps> = ({
  heading = 'There are currently no records to show here.',
  headingLevel = 'h3',
  text,
  helpAction,
  height = 180,
  primaryAction,
}) => {
  const [css] = useStyletron()

  const baseStyles: StyleObject = {
    maxWidth: '548px',
    margin: '0 auto',
    paddingLeft: 'var(--gap-compact)',
    paddingRight: 'var(--gap-compact)',
    paddingTop: 'var(--gap-compact)',
    paddingBottom: 'var(--gap-compact)',
  }
  const alignCenter: StyleObject = {
    textAlign: 'center',
  }

  return (
    <Flexbox
      alignItems="center"
      justifyContent="center"
      className={css(baseStyles)}
      height={height}
    >
      <div className={css(alignCenter)}>
        <Heading type={headingLevel} centered>
          {heading}
        </Heading>

        {text && (
          <Text p light centered>
            {text}
          </Text>
        )}

        {(helpAction || primaryAction) && (
          <>
            <Spacer y="default" />
            <Flexbox justifyContent="center">
              {helpAction && (
                <>
                  {' '}
                  {helpAction} <Spacer x="mini" />{' '}
                </>
              )}
              {primaryAction}
            </Flexbox>
          </>
        )}
      </div>
    </Flexbox>
  )
}

export default EmptyState
