import { Period } from '@api/models'
import { Table } from '@components/common'
import { useEffect } from 'react'
import { Column, useFlexLayout, useTable } from 'react-table'

interface Props {
  columns: Column<Period>[]
  periods: Period[]
  isRefetching: boolean
  isEditing: boolean
}

const CompanyPeriodsTable = ({ columns, periods, isRefetching, isEditing }: Props) => {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data: periods ?? [],
      initialState: {
        hiddenColumns: [],
      },
    },
    useFlexLayout
  )

  useEffect(
    function hideYTDPercentageWhenEditing() {
      if (isEditing) {
        setHiddenColumns(['CP_YTD_PCT'])
      } else {
        setHiddenColumns([])
      }
    },
    [isEditing]
  )

  return (
    <Table {...getTableProps()} label="Periods table" aria-label="Periods table">
      <Table.Head>
        {isRefetching ? (
          <Table.LoadingRows />
        ) : (
          headerGroups.map((headerGroup, i) => (
            <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                <Table.Header
                  {...column.getHeaderProps()}
                  column={column}
                  key={i}
                  tabIndex={0}
                >
                  {column.render('Header')}
                </Table.Header>
              ))}
            </Table.HeadRow>
          ))
        )}
      </Table.Head>
      <Table.Body {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <Table.Row {...row.getRowProps()} tabIndex={0}>
              {row.cells.map(cell => {
                return (
                  <Table.Cell {...cell.getCellProps()} column={cell.column}>
                    {cell.render('Cell')}
                  </Table.Cell>
                )
              })}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default CompanyPeriodsTable
