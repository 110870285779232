import { apiSlice } from '@api'

export const dashboardEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getDashboardAssets: build.query<any, any>({
      query: ({ assetCompanyID, filter }) =>
        `Dashboard/GetDashboardData?assetCompanyID=${assetCompanyID}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      providesTags: ['dashboard'],
      keepUnusedDataFor: 7200,
    }),
  }),
  overrideExisting: false,
  
})

export const { useGetDashboardAssetsQuery } = dashboardEndpoints
