import React from 'react'
import * as styles from './Styles'
import { useStyletron } from 'styletron-react'
import Icon from '../Icon'

const PasswordVisibilityButton = ({ size, onClick, isVisible }) => {
  const [css] = useStyletron()
  let buttonStyles = {
    ...styles.passwordVisibilityButton({ size }),
  }
  let buttonClassName = css(buttonStyles)
  let iconClassName = css(styles.passwordIcon)
  return (
    <button className={buttonClassName} onClick={onClick}>
      <Icon name={isVisible ? 'EyeOff' : 'Eye'} className={iconClassName} />
    </button>
  )
}
export default PasswordVisibilityButton
