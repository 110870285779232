import { AdminInsuranceReportParams } from '@api/models'
import { Controller, useFormContext } from 'react-hook-form'
import { Select } from '@components/common'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { useEffect } from 'react'

interface IDepProfileSelectProps {
  depProfilesMap: {
    [key: string]: ReactSelectOptions // key is assetCompanyID
  }
}

const DepProfileSelect = ({ depProfilesMap }: IDepProfileSelectProps) => {
  const {
    formState: { isSubmitting },
    control,
    watch,
    setValue,
    getValues,
  } = useFormContext<AdminInsuranceReportParams>()

  const assetCompanyID = watch('assetCompanyID')

  useEffect(() => {
    if (
      assetCompanyID !== 0 &&
      !depProfilesMap[assetCompanyID].some(({ value }) => value === getValues('profile')) // if profile is not in the list of profiles for the selected asset company
    ) {
      setValue('profile', depProfilesMap[assetCompanyID][0].value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetCompanyID])

  function getSelectProps(field: any, options: ReactSelectOptions) {
    return {
      options,
      onChange: val => field.onChange(val?.value),
      value: options.find(c => field.value === c.value),
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  const depProfiles = assetCompanyID !== 0 ? depProfilesMap[assetCompanyID] : []

  return (
    <Controller
      control={control}
      name="profile"
      render={({ field }) => (
        <Select label="Depreciation Profile" {...getSelectProps(field, depProfiles)} />
      )}
    />
  )
}

export default DepProfileSelect
