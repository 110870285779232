import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { reportsList } from './reportsList'

interface useReportsNavigationProps {
  setShowMenu: Function
  triggerRetrieveTemplateModal: Function
  showMenu: Boolean
}

export default function useReportsNavigation({
  setShowMenu,
  triggerRetrieveTemplateModal,
  showMenu,
}: useReportsNavigationProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const paths = location.pathname.split('/')
  const activeReportPath = paths[paths.length - 1]

  const foundindex = reportsList.findIndex(item =>
    item.reports.some(report => report.path === activeReportPath)
  )
  const selectedCategoryIndex = foundindex >= 0 ? foundindex : null

  const [openCategoryIndex, setOpenCategoryIndex] = useState<number>(selectedCategoryIndex)

  // being able to toggle the category open/close
  const handleOpenCategory = (index: number) => {
    if (openCategoryIndex === index) {
      setOpenCategoryIndex(null)
    } else {
      setOpenCategoryIndex(index)
    }
  }

  const handleTriggerRetrieveTemplateModal = () => {
    if (!showMenu) {
      setShowMenu(true)
      navigate('/reports')
    }

    triggerRetrieveTemplateModal()
  }

  return {
    selectedCategoryIndex,
    handleOpenCategory,
    openCategoryIndex,
    handleTriggerRetrieveTemplateModal,
  }
}
