import HttpClient from '../HttpClient'

export class OtherApi extends HttpClient {
  private static classInstance?: OtherApi

  public constructor() {
    super(`/Other`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new OtherApi()
    }

    return this.classInstance
  }

  public fetchOthers = (
    filter: string,
    pageNumber: number,
    itemsPerPage: number
  ) =>
    this.instance.get(
      `/FetchOthers?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`
    )
  public getOther = (otatcid: string) =>
    this.instance.get(`/GetOther?id=${otatcid}`)
  public importOther = (file: any) => this.instance.post(`/ImportOther`, file)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (data: any) => this.instance.post(`/Delete`, data)
  public getExtraFields = () => this.instance.get(`/GetExtraFields`)
  public taskActivityReport = () => this.instance.get(`/TaskActivityReport`)
  public taskActivityAnalysisReport = (
    companyId: number,
    sort: string,
    filter: string,
    extraFields: string,
    format: number,
    showDisposals: boolean
  ) =>
    this.instance.get(
      `/TaskActivityAnalysisReport?companyId=${companyId}&sort=${sort}&filter=${filter}&extraFields=${extraFields}&format=${format}&showDisposals=${showDisposals}`
    )
  public taskAccountingReport = (
    companyId: number,
    sort: string,
    filter: string,
    extraFields: string,
    format: number,
    showDisposals: boolean
  ) =>
    this.instance.get(
      `/TaskAccountingReport?companyId=${companyId}&sort=${sort}&filter=${filter}&extraFields=${extraFields}&format=${format}&showDisposals=${showDisposals}`
    )
  public exportTaskActivityAnalysisReport = (
    companyId: number,
    filter: string,
    extraFields: string,
    showDisposals: boolean
  ) =>
    this.instance.get(
      `/ExportTaskActivityAnalysisReport?companyId=${companyId}&filter=${filter}&extraFields=${extraFields}&showDisposals=${showDisposals}`
    )
  public exportTaskAccountingReport = (
    companyId: number,
    filter: string,
    extraFields: string,
    showDisposals: boolean
  ) =>
    this.instance.get(
      `/ExportTaskAccountingReport?companyId=${companyId}&filter=${filter}&extraFields=${extraFields}&showDisposals=${showDisposals}`
    )
}
