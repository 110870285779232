// TODO: REQUISITIONER CREATABLE SELECT

import { AllAssetDetailFields, FieldLabels } from '@api/models'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import {
  CustomDatePicker,
  Fieldset,
  Flexbox,
  Grid,
  Input,
  Select,
  Spinner,
  Textarea,
} from '@components/common'
import { useListInsurancesQuery, useListVendorsQuery, useListMaintenanceQuery } from '@api'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { WRITE_OFF_CODES } from '@constants'
import { isNullOrUndefined } from '@utils/basic'
import { useEffect } from 'react'
import { useGetAssetIdentifiersQuery } from '@api/assets/identifiers/identifiersEndpoints'

interface Props {
  fields: FieldLabels
}

const AssetMainDetailsForm = ({ fields }: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
    watch,
    setValue,
    getFieldState,
    setFocus,
  } = useFormContext<AllAssetDetailFields>()

  const {
    getInputProps,
    getSelectProps,
    getDateProps,
    getCreatableSelectProps,
  } = useFormFieldProps<AllAssetDetailFields>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  // Get data
  // Vendors
  const {
    data: vendors,
    isLoading: isLoadingVendors,
    isSuccess: isSuccessGettingVendors,
  } = useListVendorsQuery()

  const vendorOptions: ReactSelectOptions = []
  if (isSuccessGettingVendors) {
    for (const vendor of vendors) {
      vendorOptions.push({
        label: `${vendor.ID} (${vendor.Name})`,
        value: vendor.ID,
      })
    }
  }

  // Insurance Policies
  const {
    data: insurances,
    isLoading: isLoadingInsurances,
    isSuccess: isSuccessGettingInsurances,
  } = useListInsurancesQuery()

  const insuranceOptions: ReactSelectOptions = []
  if (isSuccessGettingInsurances) {
    for (const policy of insurances) {
      insuranceOptions.push({
        label: `${policy.ID}`,
        value: policy.ID,
      })
    }
  }

  // Maintenanace contracts
  const {
    data: maintenances,
    isLoading: isLoadingMaintenances,
    isSuccess: isSuccessGettingMaintenances,
  } = useListMaintenanceQuery()

  const maintenanceOptions: ReactSelectOptions = []
  if (isSuccessGettingMaintenances) {
    for (const contract of maintenances) {
      maintenanceOptions.push({
        label: `${contract.ID}`,
        value: contract.ID,
      })
    }
  }

  // Options for Identifiers
  const {
    data: identifiers,
    isLoading: isLoadingIdentifiers,
    isSuccess: isSuccessGettingIdentifiers,
  } = useGetAssetIdentifiersQuery()

  let primaryIDOptions: ReactSelectOptions = []
  let secondaryIDOptions: ReactSelectOptions = []
  let requisitionerOptions: ReactSelectOptions = []
  let userCode1Options: ReactSelectOptions = []
  let userCode2Options: ReactSelectOptions = []
  let userCode3Options: ReactSelectOptions = []

  if (isSuccessGettingIdentifiers) {
    const {
      primaryID,
      secondaryID,
      requisitioner,
      userCode1,
      userCode2,
      userCode3,
    } = identifiers

    primaryIDOptions = primaryID.map(id => ({ label: id.label, value: id.value }))
    secondaryIDOptions = secondaryID.map(id => ({ label: id.label, value: id.value }))
    requisitionerOptions = requisitioner.map(id => ({ label: id.label, value: id.value }))
    userCode1Options = userCode1.map(id => ({ label: id.label, value: id.value }))
    userCode2Options = userCode2.map(id => ({ label: id.label, value: id.value }))
    userCode3Options = userCode3.map(id => ({ label: id.label, value: id.value }))
  }

  const vendorID = watch('AS_VEN_ID')

  // Update Vendor Name field if user changes vendor ID
  useEffect(() => {
    // Update vendor name to match ID
    if (!isNullOrUndefined(vendorID)) {
      setValue('AS_VEN_NM', vendors?.find(vendor => vendor.ID === vendorID)?.Name ?? "")
    }

    // If user clears vendor ID, clear vendor name and focus
    if (isNullOrUndefined(vendorID) && getFieldState('AS_VEN_ID').isDirty) {
      setValue('AS_VEN_NM', '')
      setFocus('AS_VEN_NM')
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorID])

  if (
    isLoadingVendors ||
    isLoadingInsurances ||
    isLoadingMaintenances ||
    isLoadingIdentifiers
  )
    return (
      <Flexbox justifyContent="center" alignItems="center" height={240}>
        <Spinner />
      </Flexbox>
    )

  return (
    <Grid>
      <Grid.Item>
        <Input {...getInputProps('AS_DESC')} autoFocus />
      </Grid.Item>
      <Grid.Item>
        <Controller
          name="AS_DESC_E"
          control={control}
          render={({ field }) => <Textarea {...field} label={fields['AS_DESC_E']} />}
        />
      </Grid.Item>
      <Grid.Item>
        <Fieldset legend="Identifiers">
          <Grid>
            <Grid.Item s={4} startS={1}>
              <Controller
                control={control}
                name="AS_PRI_UCD"
                render={({ field }) => (
                  <Select
                    {...getCreatableSelectProps('AS_PRI_UCD', field, primaryIDOptions)}
                    menuPlacement="top"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_SEC_UCD"
                render={({ field }) => (
                  <Select
                    {...getCreatableSelectProps('AS_SEC_UCD', field, secondaryIDOptions)}
                    menuPlacement="top"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4}>
              <Input {...getInputProps('AS_SERIAL')} />
            </Grid.Item>

            <Grid.Item s={4} startS={1}>
              <Controller
                control={control}
                name="AS_UC1_UCD"
                render={({ field }) => (
                  <Select
                    {...getCreatableSelectProps('AS_UC1_UCD', field, userCode1Options)}
                    menuPlacement="top"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_UC2_UCD"
                render={({ field }) => (
                  <Select
                    {...getCreatableSelectProps('AS_UC2_UCD', field, userCode2Options)}
                    menuPlacement="top"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_UC3_UCD"
                render={({ field }) => (
                  <Select
                    {...getCreatableSelectProps('AS_UC3_UCD', field, userCode3Options)}
                    menuPlacement="top"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4} startS={1}>
              <Input {...getInputProps('AS_REC_PON')} />
            </Grid.Item>
            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_REP_DAT"
                render={({ field }) => (
                  <CustomDatePicker
                    {...getDateProps('AS_REP_DAT', field)}
                    label={fields[`AS_REP_DAT`]}
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_REC_USR"
                render={({ field }) => (
                  <Select
                    {...getCreatableSelectProps('AS_REC_USR', field, requisitionerOptions)}
                    menuPlacement="top"
                  />
                )}
              />
            </Grid.Item>
          </Grid>
        </Fieldset>
      </Grid.Item>
      <Grid.Item>
        <Fieldset legend="Grouping">
          <Grid>
            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_IN_POL"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('AS_IN_POL', field, insuranceOptions)}
                    isSearchable
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_MAIN_CN"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('AS_MAIN_CN', field, maintenanceOptions)}
                    isSearchable
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_NSER_DT"
                render={({ field }) => (
                  <CustomDatePicker
                    {...getDateProps('AS_NSER_DT', field)}
                    label={fields[`AS_NSER_DT`]}
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_VEN_ID"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('AS_VEN_ID', field, vendorOptions)}
                    isSearchable
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item s={4}>
              <Input
                {...getInputProps('AS_VEN_NM')}
                disabled={!isNullOrUndefined(watch('AS_VEN_ID'))}
              />
            </Grid.Item>

            <Grid.Item s={4}>
              <Controller
                control={control}
                name="AS_WRO_CD"
                render={({ field }) => (
                  <Select {...getSelectProps('AS_WRO_CD', field, WRITE_OFF_CODES)} />
                )}
              />
            </Grid.Item>
          </Grid>
        </Fieldset>
      </Grid.Item>
    </Grid>
  )
}

export default AssetMainDetailsForm
