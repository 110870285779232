import { Asset, FieldLabels } from '@api/models'
import DataDisplayList from '@components/DataDisplayList'
import { useFormContext } from 'react-hook-form'
import { DISPOSITION_OPTIONS, PROPERTY_CODES, BUILDING_CODES } from '@constants'
import { Button, Text } from '@components/common'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  fields: FieldLabels
  setIsPreferencesFormActive: Dispatch<SetStateAction<boolean>>
  isAssetDisposedOrPendingDisposal: boolean
}

const AssetPreferencesDisplay = ({
  fields,
  setIsPreferencesFormActive,
  isAssetDisposedOrPendingDisposal,
}: Props) => {
  const { watch } = useFormContext<Asset>()

  const items = [
    {
      label: fields.AS_SERD_CD,
      value: DISPOSITION_OPTIONS.find(option => option.value === watch('AS_SERD_CD'))
        ?.label,
    },
    {
      label: fields.AS_SAPC_ID,
      value: watch('AS_SAPC_ID'),
    },
    {
      label: fields.AS_SDPC_ID,
      value: watch('AS_SDPC_ID'),
    },
    {
      label: fields.AS_LOCD_CD,
      value: DISPOSITION_OPTIONS.find(option => option.value === watch('AS_LOCD_CD'))
        ?.label,
    },
    {
      label: fields.AS_LAPC_ID,
      value: watch('AS_LAPC_ID'),
    },
    {
      label: fields.AS_LDPC_ID,
      value: watch('AS_LDPC_ID'),
    },
    {
      label: fields.AS_TAX_PTP,
      value: watch('AS_TAX_PTP'),
    },
    {
      label: fields.AS_TAXL_CD,
      value: PROPERTY_CODES.find(option => option.value === watch('AS_TAXL_CD'))?.label,
    },
    {
      label: fields.AS_TAXB_CD,
      value: BUILDING_CODES.find(option => option.value === watch('AS_TAXB_CD'))?.label,
    },
  ]

  const areAnyValuesSet = () => {
    const filteredItems = items.filter(
      item => item.value !== undefined && item.value !== ''
    )

    if (filteredItems.length > 0) {
      return true
    }

    return false
  }

  if (areAnyValuesSet()) return <DataDisplayList items={items} columns={2} />

  return (
    <Text light>
      You haven't set any preferences for this asset.{' '}
      {!isAssetDisposedOrPendingDisposal && (
        <Button appearance="text" onClick={() => setIsPreferencesFormActive(true)}>
          Set preferences
        </Button>
      )}
    </Text>
  )
}
export default AssetPreferencesDisplay
