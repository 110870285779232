import { Asset, FieldLabels } from '@api/models'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import DataDisplayList from '@components/DataDisplayList'
import { useFormContext } from 'react-hook-form'

interface Props {
  fields: FieldLabels
  segmentOptions: { [key: number]: ReactSelectOptions }
}

const AssetGLKeysDisplay = ({ fields, segmentOptions }: Props) => {
  const { watch } = useFormContext<Asset>()
  const tenKeys = [...Array(10).keys()]

  const items = tenKeys.map(i => {
    const value = watch(`AS_KEY${i}_ID` as any)
    return {
      label: fields[`AS_KEY${i}_ID`],
      value: segmentOptions[i].find(o => o.value === value)?.label,
    }
  })

  return <DataDisplayList items={items} columns={2} />
}
export default AssetGLKeysDisplay
