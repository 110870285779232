import { apiSlice } from '@api'
import { Setting } from '@api/models'

export const settingsEndopoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getSettings: build.query<string, string>({
      query: SS_KEY => `Settings/GetSetting?SS_KEY=${SS_KEY}`,
      transformResponse: (res: any) => {
        if (res && res.SS_VALUE !== null && res.SS_VALUE !== undefined) {
          return res.SS_VALUE.toString()
        } else {
          return ''
        }
      },
      providesTags: ['setting'],
    }),
    editSettings: build.mutation<void, Setting>({
      query: payload => ({
        url: 'Settings/EditSetting',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['setting'],
    }),
  }),
  overrideExisting: false,
})

export const { useGetSettingsQuery, useEditSettingsMutation } = settingsEndopoints
