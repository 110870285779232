import {
  Card,
  Checkbox,
  CustomDatePicker,
  Fieldset,
  Form,
  Grid,
  Radio,
  Spacer,
} from '@components/common'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import ReportFilters from '../ReportFilters'
import { defaultReportFilterFields } from '../ReportFilters/report-filter-fields'
import { PurchaseAnalysisReportParams } from '@api/models'
import { ActionButtons } from '../General'
import { useReport } from '@hooks'
import { useEffect } from 'react'
import { getTodaysDate } from '@utils/basic'
import { reportErrorHandler, reportResponseHandler } from '@utils/reports'
import { ReportsApi } from '@api'
import { useMemorizeReport } from '../General/MemorizeReports/useMemorizeReport'
import { PURCHASE_ANALYSIS_REPORT_PATH } from '@constants'
import MemorizeReports from '../General/MemorizeReports/MemorizeReport'
import { useLocation } from 'react-router-dom'
import { useFormMethods } from '../General/useFormMethods'

const defaultValues: PurchaseAnalysisReportParams = {
  assetCompanyID: 0,
  filter: [],
  fromDate: getTodaysDate(),
  toDate: getTodaysDate(),
  includeCaps: false,
  includeDisposals: false,
  includeAssetDesc: false,
  includeRequisitioner: false,
  includeVendorName: false,
  includeDateAdded: false,
  includeLeaseID: false,
  includePrimaryID: false,
  includeSecondaryID: false,
  format: 0,
  fileFormat: 'pdf',
}

async function onSubmitHandler(values: PurchaseAnalysisReportParams) {
  await ReportsApi.getInstance()
    .getPurchaseAnalysisReport(values)
    .then(data => reportResponseHandler(data, values.fileFormat))
    .catch(reportErrorHandler)
}

const PurchaseAnalysisForm = () => {
  const location = useLocation()
  const locationState: any = location.state

  const {
    methods,
    isRetrievingTemplate,
    isEditTemplate,
    selectedTemplateDetails,
  } = useFormMethods(locationState, defaultValues)

  const {
    formState: { isSubmitting, errors },
    control,
    setValue,
    watch,
  } = methods

  const { isLoading, isSuccess, isError, isNoCompanies, assetCompanyID } = useReport()

  const {
    handleMemorizeTemplateSubmit,
    isMemorizeTemplateModalOpen,
    closeMemorizeTemplateModal,
    triggerMemorizeTemplateModal,
    handleEditTemplateSubmit,
  } = useMemorizeReport({ methods, currentReportPath: PURCHASE_ANALYSIS_REPORT_PATH })

  const format = watch('format')
  const fileFormat = watch('fileFormat')

  useEffect(() => {
    if (isSuccess) {
      setValue('assetCompanyID', assetCompanyID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, assetCompanyID])

  function getCheckBoxProps(field: any) {
    return {
      checked: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  function getRadioProps(field: any) {
    return {
      name: field.name,
      select: field.value,
      onChange: e => field.onChange(parseInt(e.target.value)),
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      onBlur: field.onBlur,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (isNoCompanies) {
    return <div>No companies found</div>
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Card title={'Report Parameters'}>
            <Grid>
              <Grid.Item>
                <Fieldset legend="Invoice Date Range">
                  <Grid>
                    <Grid.Item s={6}>
                      <Controller
                        control={control}
                        name="fromDate"
                        render={({ field }) => (
                          <CustomDatePicker {...getDateProps(field)} label="From" />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item s={6}>
                      <Controller
                        control={control}
                        name="toDate"
                        render={({ field }) => (
                          <CustomDatePicker {...getDateProps(field)} label="To" />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item s={6}>
                <Fieldset legend={'Additonal Columns to Include'}>
                  <Grid rowGap="mini">
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeAssetDesc"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Asset Description"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeRequisitioner"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Requisitioner"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeVendorName"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Vendor Name"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includePrimaryID"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Primary ID"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeSecondaryID"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Secondary ID"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDateAdded"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Date Added"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeLeaseID"
                        render={({ field }) => (
                          <Checkbox label="Include Lease ID" {...getCheckBoxProps(field)} />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item s={6}>
                <Fieldset legend="Options">
                  <Grid rowGap="mini">
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="format"
                        render={({ field }) => (
                          <Radio {...getRadioProps(field)} legend="" labelHidden>
                            <Radio.Option value={0} label="List assets" />
                            <Radio.Option value={1} label="Totals only" />
                            <Radio.Option value={2} label="List assets; omit $ values" />
                          </Radio>
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeCaps"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Capitalizations"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDisposals"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Disposals"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item>
                <ReportFilters
                  filtersTemplateData={
                    isRetrievingTemplate
                      ? locationState.templateDetails.templateData.filter
                      : []
                  }
                  filterFields={defaultReportFilterFields}
                  includesTotals={fileFormat === 'pdf' && format !== 2 ? true : false}
                />
              </Grid.Item>
            </Grid>
          </Card>
          <Spacer y="default" />
          <ActionButtons
            isSubmitting={isSubmitting}
            triggerMemorizeTemplateModal={triggerMemorizeTemplateModal}
            isEditTemplate={isEditTemplate}
          />
        </Form>
      </FormProvider>

      <MemorizeReports
        isMemorizeTemplateModalOpen={isMemorizeTemplateModalOpen}
        closeMemorizeTemplateModal={closeMemorizeTemplateModal}
        handleMemorizeTemplateSubmit={handleMemorizeTemplateSubmit}
        handleEditTemplateSubmit={handleEditTemplateSubmit}
        isEditTemplate={isEditTemplate}
        selectedTemplateDetails={selectedTemplateDetails}
      />
    </>
  )
}

export default PurchaseAnalysisForm
