import { serializeURLParams } from '@utils/basic'
import HttpClient from '../HttpClient'
import {
  AssetRegisterReportParams,
  AdminInsuranceReportParams,
  AdminMaintenanceReportParams,
  AdminPropertiesReportParams,
  FinancialAdditionsReportParams,
  FinancialTransfersReportParams,
  FinancialDisposalsReportParams,
  FinancialCapitalizedCostsReportParams,
  FinancialGLPostSummaryReportParams,
  FinancialGLPostPreviewReportParams,
  DeprecSummaryReportParams,
  DeprecContinuityReportParams,
  DeprecHistoryReportParams,
  DeprecEditReportParams,
  DeprecComparisonReportParams,
  DeprecProjectionReportParams,
  DeprecProductionReportParams,
  PurchaseAnalysisReportParams,
  PurchaseAnalysisSpreadsheetReportParams,
  LocationCurrentReportParams,
  LocationHistoryReportParams,
  LocationAccountingReportParams,
  ServiceAnalysisReportParams,
  ServiceSchedulingReportParams,
  ServiceAccountingReportParams,
  TaskActivityAnalysisReportParams,
  TaskActivityAccountingReportParams,
  AssetByFoldersReportParams,
  ListReportParams,
} from '../models'

export class ReportsApi extends HttpClient {
  private static classInstance?: ReportsApi

  public constructor() {
    super(`/Report`, 'blob')
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new ReportsApi()
    }

    return this.classInstance
  }

  // List reports
  public getCompanyPeriodList = (reportParams: ListReportParams) =>
    this.instance.get(`/CompanyList?${serializeURLParams(reportParams)}`)
  public getSegmentList = (reportParams: ListReportParams) =>
    this.instance.get(`/SegmentList?${serializeURLParams(reportParams)}`)
  public getGLPostList = (reportParams: ListReportParams) =>
    this.instance.get(`/GLPostList?${serializeURLParams(reportParams)}`)
  public getCategoryList = (reportParams: ListReportParams) =>
    this.instance.get(`/CategoryList?${serializeURLParams(reportParams)}`)
  public getTaxClassList = (reportParams: ListReportParams) =>
    this.instance.get(`/TaxClassList?${serializeURLParams(reportParams)}`)
  public getVendorList = (reportParams: ListReportParams) =>
    this.instance.get(`/VendorList?${serializeURLParams(reportParams)}`)
  public getTaskActivityList = (reportParams: ListReportParams) =>
    this.instance.get(`/TaskActivityList?${serializeURLParams(reportParams)}`)
  public getInsuranceList = (reportParams: ListReportParams) =>
    this.instance.get(`/InsuranceList?${serializeURLParams(reportParams)}`)
  public getMaintenanceList = (reportParams: ListReportParams) =>
    this.instance.get(`/MaintenanceList?${serializeURLParams(reportParams)}`)
  public getFolderList = (reportParams: ListReportParams) =>
    this.instance.get(`/FolderList?${serializeURLParams(reportParams)}`)

  // Asset register report
  public getAssetRegisterReport = (reportParams: AssetRegisterReportParams) =>
    this.instance.get(`/AssetRegisterReport?${serializeURLParams(reportParams)}`)

  // Admin reports
  public getAdminInsuranceReport = (reportParams: AdminInsuranceReportParams) =>
    this.instance.get(`/AdminInsuranceReport?${serializeURLParams(reportParams)}`)
  public getAdminMaintenanceReport = (reportParams: AdminMaintenanceReportParams) =>
    this.instance.get(`/AdminMaintenanceReport?${serializeURLParams(reportParams)}`)
  public getAdminPropertiesReport = (reportParams: AdminPropertiesReportParams) =>
    this.instance.get(`/AdminPropertiesReport?${serializeURLParams(reportParams)}`)

  // Financial reports
  public getFinancialAdditionsReport = (reportParams: FinancialAdditionsReportParams) => {
    return this.instance.get(
      `/FinancialAdditionsReport?${serializeURLParams(reportParams)}`
    )
  }
  public getFinancialTransfersReport = (reportParams: FinancialTransfersReportParams) =>
    this.instance.get(`/FinancialTransfersReport?${serializeURLParams(reportParams)}`)
  public getFinancialDisposalsReport = (reportParams: FinancialDisposalsReportParams) =>
    this.instance.get(`/FinancialDisposalsReport?${serializeURLParams(reportParams)}`)
  public getFinancialCapitalizedCostsReport = (
    reportParams: FinancialCapitalizedCostsReportParams
  ) =>
    this.instance.get(
      `/FinancialCapitalizedCostsReport?${serializeURLParams(reportParams)}`
    )
  public getFinancialGLPostSummaryReport = (
    reportParams: FinancialGLPostSummaryReportParams
  ) =>
    this.instance.get(`/FinancialGLPostSummaryReport?${serializeURLParams(reportParams)}`)
  public getFinancialGLPostPreviewReport = (
    reportParams: FinancialGLPostPreviewReportParams
  ) =>
    this.instance.get(`/FinancialGLPostPreviewReport?${serializeURLParams(reportParams)}`)

  // Depreciation reports
  public getDeprecSummaryReport = (reportParams: DeprecSummaryReportParams) =>
    this.instance.get(`/DeprecSummaryReport?${serializeURLParams(reportParams)}`)
  public getDeprecContinuityReport = (reportParams: DeprecContinuityReportParams) =>
    this.instance.get(`/DeprecContinuityReport?${serializeURLParams(reportParams)}`)
  public getDeprecHistoryReport = (reportParams: DeprecHistoryReportParams) =>
    this.instance.get(`/DeprecHistoryReport?${serializeURLParams(reportParams)}`)
  public getDeprecEditReport = (reportParams: DeprecEditReportParams) =>
    this.instance.get(`/DeprecEditReport?${serializeURLParams(reportParams)}`)
  public getDeprecComparisonReport = (reportParams: DeprecComparisonReportParams) =>
    this.instance.get(`/DeprecComparisonReport?${serializeURLParams(reportParams)}`)
  public getDeprecProjectionReport = (reportParams: DeprecProjectionReportParams) =>
    this.instance.get(`/DeprecProjectionReport?${serializeURLParams(reportParams)}`)
  public getDeprecProductionReport = (reportParams: DeprecProductionReportParams) =>
    this.instance.get(`/DeprecProductionReport?${serializeURLParams(reportParams)}`)

  // Purchase analysis reports
  public getPurchaseAnalysisReport = (reportParams: PurchaseAnalysisReportParams) =>
    this.instance.get(`/PurchaseAnalysisReport?${serializeURLParams(reportParams)}`)
  public getPurchaseAnalysisSpreadsheet = (
    reportParams: PurchaseAnalysisSpreadsheetReportParams
  ) => this.instance.get(`/PurchaseAnalysisSpreadsheet?${serializeURLParams(reportParams)}`)

  // Location reports
  public getLocationCurrentReport = (reportParams: LocationCurrentReportParams) =>
    this.instance.get(`/LocationCurrentReport?${serializeURLParams(reportParams)}`)
  public getLocationHistoryReport = (reportParams: LocationHistoryReportParams) =>
    this.instance.get(`/LocationHistoryReport?${serializeURLParams(reportParams)}`)
  public getLocationAccountingReport = (reportParams: LocationAccountingReportParams) =>
    this.instance.get(`/LocationAccountingReport?${serializeURLParams(reportParams)}`)

  // Service reports
  public getServiceAnalysisReport = (reportParams: ServiceAnalysisReportParams) =>
    this.instance.get(`/ServiceAnalysisReport?${serializeURLParams(reportParams)}`)
  public getServiceSchedulingReport = (reportParams: ServiceSchedulingReportParams) =>
    this.instance.get(`/ServiceSchedulingReport?${serializeURLParams(reportParams)}`)
  public getServiceAccountingReport = (reportParams: ServiceAccountingReportParams) =>
    this.instance.get(`/ServiceAccountingReport?${serializeURLParams(reportParams)}`)

  // Task/activity reports
  public getTaskActivityAnalysisReport = (reportParams: TaskActivityAnalysisReportParams) =>
    this.instance.get(`/TaskActivityAnalysisReport?${serializeURLParams(reportParams)}`)
  public getTaskActivityAccountingReport = (
    reportParams: TaskActivityAccountingReportParams
  ) => this.instance.get(`/TaskAccountingReport?${serializeURLParams(reportParams)}`)

  // Assets by folder report
  public getAssetByFoldersReport = (reportParams: AssetByFoldersReportParams) =>
    this.instance.get(`AssetByFoldersReport?${serializeURLParams(reportParams)}`)
}
