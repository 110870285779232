import {
  AdminInsuranceForm,
  AdminMaintenanceForm,
  AdminPropertiesForm,
} from '@components/Report/Admin'
import {
  FinancialAdditionsForm,
  FinancialCapCostsForm,
  FinancialDisposalsForm,
  FinancialGLPPreviewForm,
  FinancialGLPSummaryForm,
  FinancialTransfersForm,
} from '@components/Report/Financial'
import { ListReportForm } from '@components/Report/List'
import {
  companyListOnSubmitHandler,
  segmentListOnSubmitHandler,
  glPostListOnSubmitHandler,
  categoryListOnSubmitHandler,
  taxClassListOnSubmitHandler,
  vendorListOnSubmitHandler,
  taskActivityListOnSubmitHandler,
  insuranceListOnSubmitHandler,
  maintenanceListOnSubmitHandler,
  folderListOnSubmitHandler,
} from '@components/Report/List/listSubmitHandlers'
import {
  DeprecContinuityForm,
  DeprecEditForm,
  DeprecHistoryForm,
  DeprecProductionForm,
  DeprecProjectionForm,
  DeprecSummaryForm,
} from '@components/Report/Depreciation'
import {
  COMPANY_LIST_REPORT_PATH,
  SEGMENT_LIST_REPORT_PATH,
  GLPOSTING_LIST_REPORT_PATH,
  CATEGORY_LIST_REPORT_PATH,
  TAX_CLASS_LIST_REPORT_PATH,
  VENDOR_LIST_REPORT_PATH,
  FOLDER_LIST_REPORT_PATH,
  TASK_ACTIVITY_LIST_REPORT_PATH,
  INSURANCE_LIST_REPORT_PATH,
  MAINTENANCE_LIST_REPORT_PATH,
  ADMIN_INSURANCE_REPORT_PATH,
  ADMIN_MAINTENANCE_REPORT_PATH,
  ADMIN_PROPERTIES_REPORT_PATH,
  FINANCIAL_ADDITIONS_REPORT_PATH,
  DEPRECIATION_SUMMARY_REPORT_PATH,
  FINANCIAL_TRANSFERS_REPORT_PATH,
  FINANCIAL_CAPITALIZED_COSTS_REPORT_PATH,
  FINANCIAL_DISPOSALS_REPORT_PATH,
  FINANCIAL_GLPOSTING_SUMMARY_REPORT_PATH,
  FINANCIAL_GLPOSTING_PREVIEW_REPORT_PATH,
  DEPRECIATION_CONTINUITY_REPORT_PATH,
  DEPRECIATION_HISTORY_REPORT_PATH,
  DEPRECIATION_EDIT_REPORT_PATH,
  DEPRECIATION_PROJECTION_REPORT_PATH,
  TASK_ACTIVITY_ANALYSIS_REPORT_PATH,
  TASK_ACTIVITY_ACCOUNTING_REPORT_PATH,
  SERVICE_ANALYSIS_REPORT_PATH,
  SERVICE_ACCOUNTING_REPORT_PATH,
  SERVICE_SCHEDULING_REPORT_PATH,
  PURCHASE_ANALYSIS_REPORT_PATH,
  PURCHASE_ANALYSIS_SPREADSHEET_REPORT_PATH,
  LOCATION_CURRENT_REPORT_PATH,
  LOCATION_ACCOUNTING_REPORT_PATH,
  LOCATION_HISTORY_REPORT_PATH,
  ASSET_REGISTER_REPORT_PATH,
  DEPRECIATION_PRODUCTION_REPORT_PATH,
} from '@constants'
import {
  TaskActivityAccountingForm,
  TaskActivityAnalysisForm,
} from '@components/Report/TaskActivity'
import {
  ServiceAccountingForm,
  ServiceAnalysisForm,
  ServiceSchedulingForm,
} from '@components/Report/Service'
import { PurchaseAnalysisForm, PurchaseSpreadsheetForm } from '@components/Report/Purchase'
import {
  LocationAccountingForm,
  LocationCurrentForm,
  LocationHistoryForm,
} from '@components/Report/Location'
import AssetRegisterForm from '@components/Report/AssetRegister'

export interface ReportRoute {
  key: string
  path: string
  element: React.ReactNode
  title: string
  description?: string
  category:
    | 'Lists'
    | 'Asset Register'
    | 'Depreciation'
    | 'Administration'
    | 'Financial'
    | 'Purchases'
    | 'Service'
    | 'Tasks/Activities'
    | 'Location'
  formId?: string
}

const reportRoutes: ReportRoute[] = [
  {
    key: 'depreciationSummary',
    path: DEPRECIATION_SUMMARY_REPORT_PATH,
    element: <DeprecSummaryForm />,
    title: 'Depreciation Summary',
    description:
      'Shows asset values based on your chosen depreciation profile and year. It includes key details like depreciation start date, cumulative depreciation, and net book value. Asset descriptions appear if you include capitalized improvements. The default calculation uses the Corporate depreciation profile as of the selected date.',
    category: 'Depreciation',
  },
  {
    key: 'depreciationContinuity',
    path: DEPRECIATION_CONTINUITY_REPORT_PATH,
    element: <DeprecContinuityForm />,
    title: 'Depreciation Continuity',
    description:
      'Provides a statement of changes in asset values for the current company based on the selected depreciation profile for the specified fiscal year. For the default report, the application calculates the value of the assets based on the Corporate depreciation profile for the date range selected.',
    category: 'Depreciation',
  },
  {
    key: 'depreciationHistory',
    path: DEPRECIATION_HISTORY_REPORT_PATH,
    element: <DeprecHistoryForm />,
    title: 'Depreciation History',
    description:
      'Displays the depreciation UPDATE records of assets for the current company for the date range selected.For the default report, the application displays the value of the assets based on the Corporate depreciation profile as of the date range selected',
    category: 'Depreciation',
  },
  {
    key: 'depreciationHistory',
    path: DEPRECIATION_EDIT_REPORT_PATH,
    element: <DeprecEditForm />,
    title: 'Depreciation Edit',
    description:
      'Run it after the period Depreciation or Year End Run functions to display the values to be posted during the Depreciation or Year End Update function. The value in the Current Depreciation field is the amount to be posted.',
    category: 'Depreciation',
  },
  {
    key: 'depreciationProjection',
    path: DEPRECIATION_PROJECTION_REPORT_PATH,
    element: <DeprecProjectionForm />,
    title: 'Depreciation Projection',
    description:
      'Displays the future worth and accumulated depreciation of an asset for a depreciation profile. This allows you to see today what effect a depreciation profile will have on your assets in the future. You must select the depreciation profile to be used.',
    category: 'Depreciation',
  },
  {
    key: 'depreciationProduction',
    path: DEPRECIATION_PRODUCTION_REPORT_PATH,
    element: <DeprecProductionForm />,
    title: 'Depreciation Production',
    description: 'Displays the production units values for the current company.',
    category: 'Depreciation',
  },
  {
    key: 'financialAdditions',
    path: FINANCIAL_ADDITIONS_REPORT_PATH,
    element: <FinancialAdditionsForm />,
    title: 'Financial Additions',
    description:
      'Displays a list of assets acquired in a specified date range. When no dates are specified, the report displays all assets acquired for this company.',
    category: 'Financial',
  },
  {
    key: 'financialTransfers',
    path: FINANCIAL_TRANSFERS_REPORT_PATH,
    element: <FinancialTransfersForm />,
    title: 'Asset Transfers',
    description:
      'Displays the assets that have been transferred including where, when and their NBV worth at the time of the transfer. This report has no default settings.',
    category: 'Financial',
  },
  {
    key: 'financialDisposals',
    path: FINANCIAL_DISPOSALS_REPORT_PATH,
    element: <FinancialDisposalsForm />,
    title: 'Asset Disposals',
    description:
      'Displays a list of assets disposed in a specified date range. When no dates are specified, the report displays all assets disposed for this company. The default settings generate a report sorting the asset information by the Asset Id.',
    category: 'Financial',
  },
  {
    key: 'financialCapitalizedCosts',
    path: FINANCIAL_CAPITALIZED_COSTS_REPORT_PATH,
    element: <FinancialCapCostsForm />,
    title: 'Capitalized Costs',
    description:
      'Provides a summary of the capitalized improvements for each asset including the costs of capitalized Location, Service, and Task-Activity records. The default settings generate a report sorting the asset information by the Asset Id.',
    category: 'Financial',
  },
  {
    key: 'financialGLPSummary',
    path: FINANCIAL_GLPOSTING_SUMMARY_REPORT_PATH,
    element: <FinancialGLPSummaryForm />,
    title: 'G/L Posting Summary',
    description:
      'Displays the accounting structures and accounts that have been debited and credited. Information for this report is extracted from the Journal Voucher information. The default settings generate a report sorting the accounting information by the Asset Id.',
    category: 'Financial',
  },
  {
    key: 'financialGLPPreview',
    path: FINANCIAL_GLPOSTING_PREVIEW_REPORT_PATH,
    element: <FinancialGLPPreviewForm />,
    title: 'G/L Posting Preview',
    description:
      'Shows the impact of the latest depreciation run on the General Ledger accounts.',
    category: 'Financial',
  },
  {
    key: 'purchaseAnalysis',
    path: PURCHASE_ANALYSIS_REPORT_PATH,
    element: <PurchaseAnalysisForm />,
    title: 'Purchase Analysis',
    description:
      'Addresses questions regarding the purchase of assets, vendor information and their related capitalizations.',
    category: 'Purchases',
  },
  {
    key: 'purchaseSpreadsheet',
    path: PURCHASE_ANALYSIS_SPREADSHEET_REPORT_PATH,
    element: <PurchaseSpreadsheetForm />,
    title: 'Purchase Analysis Spreadsheet',
    description:
      'Illustrates purchasing patterns over a specified date range. Each time interval selected is given one column on the report.',
    category: 'Purchases',
  },
  {
    key: 'locationCurrent',
    path: LOCATION_CURRENT_REPORT_PATH,
    element: <LocationCurrentForm />,
    title: 'Current Asset Location',
    description:
      'View where all the assets are currently, and the value of the assets based on the selected depreciation profile. The default settings generate a report sorting the assets first by the Location Code, next by the Location number, and finally by the Asset Id.',
    category: 'Location',
  },
  {
    key: 'locationAccounting',
    path: LOCATION_ACCOUNTING_REPORT_PATH,
    element: <LocationAccountingForm />,
    title: 'Location Accounting',
    description:
      'Displays the post codes each location record (capitalized or expensed) is using to update the general ledger. The location record must have a G/L post code attached to it to appear in this report. The default settings generate a report sorting the assets first by the Acquisition Post Code, then by the Asset Id, and finally by the Start Date.',
    category: 'Location',
  },
  {
    key: 'locationHistory',
    path: LOCATION_HISTORY_REPORT_PATH,
    element: <LocationHistoryForm />,
    title: 'Asset Location History',
    description:
      'Displays a history of the location records for each asset including the costs involved in moving the assets. The default settings generate a report displaying a history of all locations for each asset, sorted by Asset Id.',
    category: 'Location',
  },
  {
    key: 'serviceAnalysis',
    path: SERVICE_ANALYSIS_REPORT_PATH,
    element: <ServiceAnalysisForm />,
    title: 'Service Analysis',
    description:
      'Displays the service records entered on each asset within the current company, including the costs associated with each service. The default settings generate a report sorting the assets first by the Asset Id, then by the Service Code, and finally by the Service Date.',
    category: 'Service',
  },
  {
    key: 'serviceScheduling',
    path: SERVICE_SCHEDULING_REPORT_PATH,
    element: <ServiceSchedulingForm />,
    title: 'Service Scheduling',
    description:
      'Displays all assets in the current company that have service records and includes the next service date. Only assets that have a service record attached to them will be displayed. In the default report no asset range is specified. The report displays all service records for each asset, organized by the Asset Id. The application displays the cost of each service, as well as a grand total of service costs for all assets.',
    category: 'Service',
  },
  {
    key: 'serviceAccounting',
    path: SERVICE_ACCOUNTING_REPORT_PATH,
    element: <ServiceAccountingForm />,
    title: 'Service Accounting',
    description:
      'Displays the post codes each service record (capitalized or expensed) uses to update the general ledger. To appear in this report, the service record must have a G/L post code attached to it. The default settings generate a report sorting the assets first by the Acquisition Post Code, then by the Asset Id, and finally by the Service Date.',
    category: 'Service',
  },
  {
    key: 'taskActivityAnalysis',
    path: TASK_ACTIVITY_ANALYSIS_REPORT_PATH,
    element: <TaskActivityAnalysisForm />,
    title: 'Task/Activity Analysis',
    description:
      'Displays the tasks and/or activities records entered on each asset within the current company, including the associated costs. The default report displays task/activity details for all assets in the current company, organized by the Activity Id. The application calculates and displays subtotals of the costs for each activity.',
    category: 'Tasks/Activities',
  },
  {
    key: 'taskActivityAccounting',
    path: TASK_ACTIVITY_ACCOUNTING_REPORT_PATH,
    element: <TaskActivityAccountingForm />,
    title: 'Task/Activity Accounting',
    description:
      'Displays the post codes each task/activity record (capitalized or expensed) uses to update the general ledger. To appear in this report the task/activity record must have a G/L post code attached to it. The default settings generate a report sorting the assets first by the Acquisition Post Code, then by the Asset Id, and finally by the Task Date. The application calculates and displays subtotals of the costs for each Acquisition Post Code.',
    category: 'Tasks/Activities',
  },
  {
    key: 'assetRegister',
    path: ASSET_REGISTER_REPORT_PATH,
    element: <AssetRegisterForm />,
    title: 'Asset Register',
    description:
      'Generate a report detailing information for all assets in the current company. ',
    category: 'Asset Register',
  },
  {
    key: 'adminInsurance',
    path: ADMIN_INSURANCE_REPORT_PATH,
    element: <AdminInsuranceForm />,
    title: 'Assets by Insurance Policy',
    description:
      'Displays a list of assets by insurance policy and their asset book value. If you choose to include the capitalizations, the report includes a list of capitalized improvements for each asset. The default settings generate a report detailing all policies sorted by policy ID using a corporate depreciation profile. The report shows which policies cover which assets.',
    category: 'Administration',
  },
  {
    key: 'adminMaintenance',
    path: ADMIN_MAINTENANCE_REPORT_PATH,
    element: <AdminMaintenanceForm />,
    title: 'Assets by Maintenance Contract',
    description:
      'Generate a report detailing all contracts sorted by contract ID using a corporate depreciation profile. The report shows which contracts cover which assets.',
    category: 'Administration',
  },
  {
    key: 'adminProperties',
    path: ADMIN_PROPERTIES_REPORT_PATH,
    element: <AdminPropertiesForm />,
    title: 'Asset Special Properties',
    description:
      'This report displays a company’s assets, to be reported on U.S. tax forms, that use a Section 179 deduction. Information in this report includes the asset’s category ID, tax class, and percentage of use. The default settings generate a report detailing a company’s assets, sorted by asset ID.',
    category: 'Administration',
  },
  {
    key: 'segmentList',
    path: SEGMENT_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={segmentListOnSubmitHandler}
        path={SEGMENT_LIST_REPORT_PATH}
      />
    ),
    title: 'Segment List',
    description: '',
    category: 'Lists',
  },
  {
    key: 'companyList',
    path: COMPANY_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={companyListOnSubmitHandler}
        path={COMPANY_LIST_REPORT_PATH}
      />
    ),
    title: 'Company List',
    description: '',
    category: 'Lists',
  },
  {
    key: 'glPostList',
    path: GLPOSTING_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={glPostListOnSubmitHandler}
        path={GLPOSTING_LIST_REPORT_PATH}
      />
    ),
    title: 'G/L Posting List',

    category: 'Lists',
  },
  {
    key: 'categoryList',
    path: CATEGORY_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={categoryListOnSubmitHandler}
        allowExcel={false}
        path={CATEGORY_LIST_REPORT_PATH}
      />
    ),
    title: 'Category List',
    description: '',
    category: 'Lists',
  },
  {
    key: 'taxClassList',
    path: TAX_CLASS_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={taxClassListOnSubmitHandler}
        path={TAX_CLASS_LIST_REPORT_PATH}
      />
    ),
    title: 'Tax Class List',
    description: '',
    category: 'Lists',
  },
  {
    key: 'vendorList',
    path: VENDOR_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={vendorListOnSubmitHandler}
        allowExcel={false}
        path={VENDOR_LIST_REPORT_PATH}
      />
    ),
    title: 'Vendor List',
    description: '',
    category: 'Lists',
  },
  {
    key: 'taskActivityList',
    path: TASK_ACTIVITY_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={taskActivityListOnSubmitHandler}
        allowExcel={false}
        path={TASK_ACTIVITY_LIST_REPORT_PATH}
      />
    ),
    title: 'Task/Activity List',
    description: '',
    category: 'Lists',
  },
  {
    key: 'insuranceList',
    path: INSURANCE_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={insuranceListOnSubmitHandler}
        allowExcel={false}
        path={INSURANCE_LIST_REPORT_PATH}
      />
    ),
    title: 'Insurance List',
    description: '',
    category: 'Lists',
  },
  {
    key: 'maintenanceList',
    path: MAINTENANCE_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={maintenanceListOnSubmitHandler}
        allowExcel={false}
        path={MAINTENANCE_LIST_REPORT_PATH}
      />
    ),
    title: 'Maintenance List',
    description: '',
    category: 'Lists',
  },
  {
    key: 'folderList',
    path: FOLDER_LIST_REPORT_PATH,
    element: (
      <ListReportForm
        onSubmitHandler={folderListOnSubmitHandler}
        allowExcel={false}
        path={FOLDER_LIST_REPORT_PATH}
      />
    ),
    title: 'Folder List',
    description: '',
    category: 'Lists',
  },
]

export default reportRoutes
