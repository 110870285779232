import { FOCUS } from '@styles-api/css-constants'
import { StyleObject } from '@styles-api/css.types'

export const wrapper: StyleObject = {
  listStyle: 'none',
  width: '100%',
}
export const item: StyleObject = {
  borderBottomWidth: '1px',
  width: '100%',
}
export const button: StyleObject = {
  minHeight: 'var(--size-default)',
  paddingLeft: 'var(--scale550)',
  paddingRight: 'var(--scale550)',
  paddingTop: 'var(--scale400)',
  paddingBottom: 'var(--scale400)',
  lineHeight: 1,
  cursor: 'pointer',
  fontSize: 'var(--base-font-size)',
  color: 'var(--text-color)',
  fontWeight: 'var(--font-weight-semiBold)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  boxSizing: 'border-box',
  transition: 'var(--button-hover-transition)',
  textAlign: 'left',
  borderRadius: 'var(--radius1)',
  ':active': {
    backgroundColor: 'var(--shade200)',
  },
  ':hover': {
    backgroundColor: 'var(--shade100)',
  },
  ...FOCUS,
}
export const label: StyleObject = {
  display: 'block',
}
export const label_active: StyleObject = {
  transform: 'translateY(-1px)',
}
export const buttonSubheading: StyleObject = {
  display: 'block',
}
export const content: StyleObject = {
  overflow: 'hidden',
}
export const content__inner: StyleObject = {
  paddingTop: 'var(--scale300)',
  paddingBottom: 'var(--scale600)',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
}

export const icon: StyleObject = {
  height: 'var(--scale500)',
  width: 'var(--scale500)',
}
export const getIconLineStyles = ({ isOpen }) => {
  return {
    transform: isOpen ? 'rotate(-90deg)' : 'rotate(0deg)',
    transitionProperty: 'transform',
    transitionDuration: '120ms',
    transformOrigin: 'center',
    transitionTimingFunction: '0.04, 0.62, 0.23, 0.8',
  }
}

// skeleton styles
export const skeletonItem: StyleObject = {
  borderBottomWidth: '1px',
  width: '100%',
  overflow: 'hidden',
  paddingTop: 'var(--scale600)',
  paddingBottom: 'var(--scale600)',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
  boxSizing: 'border-box',
  minHeight: 'var(--scale1400)',
}
export const skeletonButton: StyleObject = {
  height: 'var(--scale800)',
}
