import { Helmet } from 'react-helmet'
import { APP_TITLE, APP_TITLE_DIVIDER } from '@constants'
import Frame from '@components/layout/Frame'
import PermissionProvider from '@components/Auth/Permissions/PermissionProvider'
import AppRouter from './routes/AppRoutes'

const Head = () => {
  return <Helmet titleTemplate={`${APP_TITLE} ${APP_TITLE_DIVIDER} %s `} />
}

// Main app component
const App = () => {
  return (
    <PermissionProvider>
      <Head />
      <Frame>
        <AppRouter />
      </Frame>
    </PermissionProvider>
  )
}

export default App
