import { AssetRegisterReportParams } from '@api/models'
import { Button, Fieldset, Note, Spacer, Tag } from '@components/common'
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form'

const AssetIndividualSelections = () => {
  const { control, watch } = useFormContext<AssetRegisterReportParams>()

  const assets = watch('assets')

  function removeAssetFromSelection(
    assetID: string,
    field: ControllerRenderProps<AssetRegisterReportParams, 'assets'>
  ) {
    if (!field.value.includes(assetID)) {
      return
    }

    field.onChange(field.value.filter(id => id !== assetID))
  }

  function removeAllAssets(
    field: ControllerRenderProps<AssetRegisterReportParams, 'assets'>
  ) {
    field.onChange([])
  }

  return (
    <Fieldset legend="Individual Selections">
      {assets.length === 0 ? (
        <Note content="No indivdual selections have been made. The report will default to selecting all assets within this company." />
      ) : (
        <>
          <ul>
            {assets.map(assetID => (
              <li key={assetID} style={{ display: 'inline-block' }}>
                <Controller
                  name="assets"
                  control={control}
                  render={({ field }) => (
                    <Tag
                      content={assetID}
                      appearance="outline"
                      remove={() => removeAssetFromSelection(assetID, field)}
                    />
                  )}
                />
              </li>
            ))}
          </ul>
          <Spacer y="default" />
          <Controller
            name="assets"
            control={control}
            render={({ field }) => (
              <Button type="button" onClick={() => removeAllAssets(field)}>
                Clear all
              </Button>
            )}
          />
        </>
      )}
    </Fieldset>
  )
}

export default AssetIndividualSelections
