import { AttachmentType } from '@api/models'
import { Card, EmptyState, Button } from '@components/common'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { useParams } from 'react-router-dom'

interface NoAttachmentsProps {
  type: AttachmentType
  openAddAttachmentForm: () => void
  canEdit: boolean
}

const NoAttachments = ({ type, openAddAttachmentForm, canEdit }: NoAttachmentsProps) => {
  let headingText = ''
  if (type === 'Asset') headingText = 'No attachments have been added to this asset'

  const params = useParams()
  const { data: isAssetDesc, isLoading: isAssetDetailLoading } = useGetAssetQuery({
    assetCompanyID: parseInt(params.companyID),
    assetID: params.assetID,
  })

  const EditingEnabled =
    isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false

  if (isAssetDetailLoading) return <>Loading...</>

  return (
    <Card>
      <EmptyState
        heading={headingText}
        primaryAction={
          <Button
            appearance="primary"
            disabled={EditingEnabled}
            onClick={() => openAddAttachmentForm()}
            hidden={!canEdit}
          >
            Add attachment
          </Button>
        }
      />
    </Card>
  )

  //<>No attachments found</>
}

export default NoAttachments
