import { useModal } from '@components/common'
import { useGetDepRunInfoQuery, useDepRunMutation } from '@api'
import { DepRunInput } from '@api/models'
import { useFields } from '@hooks'
import { useEffect } from 'react'

const useDepRunHook = (assetCompanyID: number) => {
  const { fields } = useFields()

  const {
    modalIsOpen: isDepRunFormOpen,
    triggerModal: openDepRunForm,
    closeModal: closeDepRunForm,
  } = useModal()

  const {
    data: depRunInfo,
    isLoading: isLoadingGetDepRunInfo,
    isSuccess: isSuccessGetDepRunInfo,
    isError: isErrorGetDepRunInfo,
    error: errorGetDepRunInfo,
  } = useGetDepRunInfoQuery(assetCompanyID, {
    refetchOnMountOrArgChange: true,
    skip: !isDepRunFormOpen,
  })

  const [
    depRunMutation,
    {
      isLoading: isDepreciationRunning,
      isError: isErrorDepRun,
      isSuccess: isSuccessDepRun,
    },
  ] = useDepRunMutation()

  useEffect(() => {
    if (isErrorGetDepRunInfo) {
      closeDepRunForm()
    }
  }, [closeDepRunForm, isErrorGetDepRunInfo])

  async function runDepreciation(formData: DepRunInput) {
    closeDepRunForm()
    await depRunMutation(formData)
  }

  return {
    isSuccessDepRun,
    isErrorDepRun,
    isErrorGetDepRunInfo,
    errorGetDepRunInfo: (errorGetDepRunInfo as any)?.data,
    isDepRunFormOpen,
    openDepRunForm,
    closeDepRunForm,
    depRunInfo,
    isLoadingGetDepRunInfo,
    isSuccessGetDepRunInfo,
    runDepreciation,
    isDepreciationRunning,
    fields,
  }
}

export default useDepRunHook
