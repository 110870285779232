import { COMPANY_PATHS } from '@constants'
import { CompanyDetailsView, CompanyPeriodsView } from '@views'

const companyRoutes = [
  {
    key: 'Company Details',
    path: `${COMPANY_PATHS.DETAILS}`,
    element: <CompanyDetailsView />,
  },
  {
    key: 'Company Periods',
    path: `${COMPANY_PATHS.PERIODS}`,
    element: <CompanyPeriodsView />,
  },
]

export default companyRoutes
