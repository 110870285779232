import React from 'react'
import { IconProps } from './Icon.types'
import * as icons from 'react-feather'

import { useStyletron } from 'styletron-react'
import {
  icon_elevatedStyles,
  icon_elevated_defaultStyles,
  icon_hasBgStyles,
  icon_large_hasBgStyles,
  icon_light_dangerStyles,
  icon_light_defaultStyles,
  icon_light_successStyles,
  icon_light_warningStyles,
  icon_medium_hasBgStyles,
  icon_mutedStyles,
  icon_muted_dangerStyles,
  icon_muted_successStyles,
  icon_muted_warningStyles,
  icon_primary_dangerStyles,
  icon_primary_defaultStyles,
  icon_primary_successStyles,
  icon_primary_warningStyles,
  icon_s_hasBgStyles,
  icon_xl_hasBgStyles,
  icon_xxl_hasBgStyles,
} from './Styles'

const Icon: React.FC<IconProps> = ({
  name,
  intent = 'default',
  appearance = 'default',
  size = 'm',
  elevated = false,
  className,
  color,
  style,
}) => {
  const IconComponent = icons[name]

  /* ====================================================== */
  /*                      APPLY STYLES                      */
  /* ====================================================== */
  const [css] = useStyletron()
  var styles = `${className ? className : ''} `

  /* --------------------- BACKGROUNDS -------------------- */
  if (appearance !== 'default' || elevated === true) {
    styles += ' ' + css(icon_hasBgStyles)
    if  (size === 's') styles += ' ' + css(icon_s_hasBgStyles)
    if (size === 'm') styles += ' ' + css(icon_medium_hasBgStyles)
    if (size === 'l') styles += ' ' + css(icon_large_hasBgStyles)
    if (size === 'xl') styles += ' ' + css(icon_xl_hasBgStyles)
    if (size === 'xxl') styles += ' ' + css(icon_xxl_hasBgStyles)
  }
  if (elevated) {
    styles += ' ' + css(icon_hasBgStyles) + ' ' + css(icon_elevatedStyles)
    if (appearance === 'default') styles += ' ' + css(icon_elevated_defaultStyles)
  }

  /* --------------------- APPEARANCES -------------------- */
  if (appearance === 'primary') {
    if (intent === 'default') styles += ' ' + css(icon_primary_defaultStyles)
    if (intent === 'success') styles += ' ' + css(icon_primary_successStyles)
    if (intent === 'warning') styles += ' ' + css(icon_primary_warningStyles)
    if (intent === 'danger') styles += ' ' + css(icon_primary_dangerStyles)
  }
  if (appearance === 'light') {
    if (intent === 'default') styles += ' ' + css(icon_light_defaultStyles)
    if (intent === 'success') styles += ' ' + css(icon_light_successStyles)
    if (intent === 'warning') styles += ' ' + css(icon_light_warningStyles)
    if (intent === 'danger') styles += ' ' + css(icon_light_dangerStyles)
  }
  if (appearance === 'muted') {
    styles += ' ' + css(icon_mutedStyles)
    if (intent === 'success') styles += ' ' + css(icon_muted_successStyles)
    if (intent === 'warning') styles += ' ' + css(icon_muted_warningStyles)
    if (intent === 'danger') styles += ' ' + css(icon_muted_dangerStyles)
  }
  if (appearance === 'default') {
    if (intent === 'success') styles += ' ' + css(icon_muted_successStyles)
    if (intent === 'warning') styles += ' ' + css(icon_muted_warningStyles)
    if (intent === 'danger') styles += ' ' + css(icon_muted_dangerStyles)
  }

  /* ====================================================== */
  /*                         MARKUP                         */
  /* ====================================================== */

  return <IconComponent className={styles} color={color} aria-hidden={true} style={style} />
}
export default Icon
