import { ImportColumn } from "../importTypes";

export const insurance: ImportColumn[] = [
    {
        label: 'Policy ID',
        value: 'IN_POL_ID'
    },
    {
        label: 'Policy Description',
        value: 'IN_DESC'
    },
    {
        label: 'Company Name',
        value: 'IN_NAME'
    },
    {
        label: 'Street Address',
        value: 'IN_STREET'
    },
    {
        label: 'City',
        value: 'IN_CITY'
    },
    {
        label: 'Country',
        value: 'IN_CNTY'
    },
    {
        label: 'Province/State',
        value: 'IN_STATE'
    },
    {
        label: 'Postal/Zip Code',
        value: 'IN_ZIP'
    },
    {
        label: 'Phone Number',
        value: 'IN_PHONE'
    },
    {
        label: 'Fax Number',
        value: 'IN_FAX'
    },
    {
        label: 'Insurance Agent',
        value: 'IN_CON_NM'
    },
    {
        label: 'Agent Phone Number',
        value: 'IN_CON_PN'
    },
    {
        label: 'Agent Fax Number',
        value: 'IN_CON_FX'
    },
    {
        label: 'Insurance Amount',
        value: 'IN_INS_AMT'
    },
    {
        label: 'Premium Amount',
        value: 'IN_PRM_AMT'
    },
    {
        label: 'Deduction per Year',
        value: 'IN_YR_DED'
    },
    {
        label: 'Deduction per Occurrence',
        value: 'IN_OC_DED'
    },
    {
        label: 'Occurrence Limit',
        value: 'IN_OC_LMT'
    },
    {
        label: 'Aggregate Limit',
        value: 'IN_AG_LMT'
    },
    {
        label: 'Start of Coverage',
        value: 'IN_CP_FRM'
    },
    {
        label: 'End of Coverage',
        value: 'IN_CP_TO'
    },
    {
        label: 'Payment Terms',
        value: 'IN_PMT_CD'
    },
    {
        label: 'Automatic Renewal',
        value: 'IN_REN_CD'
    }
]