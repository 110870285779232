import View from '@components/layout/View'
import { FieldPrefs } from '@components/settings'

const FieldPrefsView = () => {
  return (
    <View title="Field Labels" showHeader={false}>
      <FieldPrefs />
    </View>
  )
}

export default FieldPrefsView
