import {
  CompanyStructureBase,
  CompanyStructureKey,
  companyStructureSchema,
  SegmentType,
} from '@api/models'
import useFields from '@hooks/useFields'
import { getInputPropsForModalForms } from '@utils/getInputPropsForModalForms'
import { validateAndSubmit } from '@utils/validateAndSubmit'
import { useState, useEffect } from 'react'
import { FormSubmitEvent } from 'types'

interface SegmentFormProps {
  initialValues: CompanyStructureBase
  handleSubmit: (payload: CompanyStructureBase) => Promise<void>
  selectedStructureType?: SegmentType
}

export const useSegmentTypeForm = ({
  initialValues,
  handleSubmit,
  selectedStructureType,
}: SegmentFormProps) => {
  const { fields } = useFields()
  const [form, setForm] = useState({
    values: initialValues,
    errors: {},
  })

  function getField(name: CompanyStructureKey) {
    return getInputPropsForModalForms({
      name,
      fieldMap: fields,
      schema: companyStructureSchema,
      initialValues,
      form,
      setForm,
    })
  }

  async function handleFormSubmit(e: FormSubmitEvent): Promise<void> {
    e.preventDefault()
    await validateAndSubmit(companyStructureSchema, form, setForm, handleSubmit)
  }

  useEffect(() => {
    setForm({
      values: {
        value: selectedStructureType?.value,
        KY_DESC: initialValues.KY_DESC,
      },
      errors: {},
    })
  }, [selectedStructureType])

  return {
    getField,
    handleFormSubmit,
    form,
  }
}
