import React from 'react'
import { ButtonProps } from './Button.types'

import PassClassDown from '@utils/PassClassDown'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import { motion } from 'framer-motion'

import { Link } from 'react-router-dom'
import { Spinner } from '../'
const MotionLink = motion(Link)

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      onClick,
      className,
      id,
      form,
      disabled = false,
      style,
      size = 'default',
      sizeY,
      shape = 'default',
      appearance = 'default',
      intent = 'default',
      flex = false,
      icon,
      iconRight,
      tabIndex = 0,
      loading = false,
      link,
      flexGrow = false,
      overShade = false,
      children,
      hidden,
      ...props
    },
    ref
  ) => {
    // apply styles
    const [css] = useStyletron()

    let buttonStyles
    if (appearance !== 'text') {
      buttonStyles = css({
        ...styles.base,
        ...styles.getApperanceIntent({ appearance, intent, disabled, overShade }),
        ...styles.getSizeShape({ size, shape, flex, loading, flexGrow, sizeY }),
      })
    } else if (appearance === 'text') {
      buttonStyles = css({ ...styles.textButton({ disabled }) })
    }

    if (className) {
      buttonStyles += ' ' + className
    }
    // icon & icon wrapper styles
    let iconWrapperStyles = {
      ...styles.getIconWrapperStyles({ icon, shape, iconRight }),
    }
    let iconStyles = {
      ...styles.getIconStyles({ size, shape }),
    }
    // loading styles
    let contentStyles = {
      ...styles.getContentStyles({ loading }),
    }

    if (hidden) return <></>

    if (link)
      return (
        <MotionLink
          className={buttonStyles}
          id={id}
          to={link}
          ref={props.ref}
          style={style}
          aria-label={props['aria-label']}
        >
          <div className={css(contentStyles)}>
            {icon && (
              <div className={css(iconWrapperStyles)}>
                <PassClassDown className={css(iconStyles)}>{icon}</PassClassDown>
              </div>
            )}
            {children}
            {iconRight && (
              <div className={css(iconWrapperStyles)}>
                <PassClassDown className={css(iconStyles)}>{iconRight}</PassClassDown>
              </div>
            )}
          </div>
          {loading && (
            <Spinner theme={appearance === 'primary' ? 'dark' : 'light'} size="compact" />
          )}
        </MotionLink>
      )
    else
      return (
        <motion.button
          type={type}
          form={form}
          onClick={onClick}
          className={buttonStyles}
          id={id}
          disabled={disabled}
          ref={ref}
          style={style}
          tabIndex={tabIndex}
          aria-controls={props['aria-controls']}
          aria-label={props['aria-label']}
          autoFocus={props.autoFocus}
        >
          <div className={css(contentStyles)}>
            {icon && (
              <div className={css(iconWrapperStyles)}>
                <PassClassDown className={css(iconStyles)}>{icon}</PassClassDown>
              </div>
            )}
            {children}
            {iconRight && (
              <div className={css(iconWrapperStyles)}>
                <PassClassDown className={css(iconStyles)}>{iconRight}</PassClassDown>
              </div>
            )}
          </div>
          {loading && (
            <Spinner theme={appearance === 'primary' ? 'dark' : 'light'} size="compact" />
          )}
        </motion.button>
      )
  }
)

export default Button
