export const HOME_PATH = ''

// Assets
export const ASSETS_PATH = 'assets'

export const ASSET_PATHS = {
  DETAILS: 'details',
  DEPRECIATION: 'depreciation',
  CUSTOM_DEPRECIATION: 'custom-depreciation',
  HISTORY: 'history',
  STRUCTURE: 'structure',
  LOCATION: 'location',
  SERVICE: 'service',
  TASKS: 'tasks',
  PARTIAL_DISPOSALS: 'partial-disposals',
  ATTACHMENTS: 'attachments',
  DISPOSAL: 'disposal',
  CHARGES: 'create',
}
export const ASSET_HISTORY_PATHS = {
  CORPORATE: 'corporate',
  TAX: 'tax',
  STATE: 'state',
  OTHER: 'other',
  CAPITALIZATION: 'capitalization',
}
export const ASSET_DEPRECIATION_PATHS = {
  CORPORATE: 'corporate',
  TAX: 'tax',
  STATE: 'state',
  OTHER: 'other',
}

// Companies
export const COMPANIES_PATH = 'companies'

export const COMPANY_PATHS = {
  ROOT: 'companies',
  DETAILS: 'details',
  PERIODS: 'periods',
  CREATE: 'create',
}

// Settings
export const SETTINGS_PATH = 'settings'

export const SETTING_PATHS = {
  PROFILE: 'profile',
  FIELD_PREFS: 'field-preferences',
  CUSTOMIZE_FORMATS: 'customize-formats',
  ACCOUNT_STRUCTURE_SETUP: 'account-structure-setup',
  ACCOUNT_SETTINGS: 'account-settings',
  CHANGE_PASSWORD: 'change-password',
  EDIT_ASSETID: 'edit-assetid'
}

// Reports
export const REPORTS_PATH = 'reports'

// list reports: company, segment, glposting, category, tax class, vendor, task activity, insurance, maintenance
export const COMPANY_LIST_REPORT_PATH = 'company-list-report'
export const SEGMENT_LIST_REPORT_PATH = 'segment-list-report'
export const GLPOSTING_LIST_REPORT_PATH = 'glposting-list-report'
export const CATEGORY_LIST_REPORT_PATH = 'category-list-report'
export const TAX_CLASS_LIST_REPORT_PATH = 'tax-class-list-report'
export const VENDOR_LIST_REPORT_PATH = 'vendor-list-report'
export const FOLDER_LIST_REPORT_PATH = 'folder-list-report'
export const TASK_ACTIVITY_LIST_REPORT_PATH = 'task-activity-list-report'
export const INSURANCE_LIST_REPORT_PATH = 'insurance-list-report'
export const MAINTENANCE_LIST_REPORT_PATH = 'maintenance-list-report'

// asset register report
export const ASSET_REGISTER_REPORT_PATH = 'asset-register-report'

// admin reports: insurance, maintenance, properties
export const ADMIN_INSURANCE_REPORT_PATH = 'admin-insurance-report'
export const ADMIN_MAINTENANCE_REPORT_PATH = 'admin-maintenance-report'
export const ADMIN_PROPERTIES_REPORT_PATH = 'admin-properties-report'

// financial reports: additions, transfers, disposals, capitalized costs, glposting summary, glposting preview
export const FINANCIAL_ADDITIONS_REPORT_PATH = 'financial-additions-report'
export const FINANCIAL_TRANSFERS_REPORT_PATH = 'financial-transfers-report'
export const FINANCIAL_DISPOSALS_REPORT_PATH = 'financial-disposals-report'
export const FINANCIAL_CAPITALIZED_COSTS_REPORT_PATH = 'financial-capitalized-costs-report'
export const FINANCIAL_GLPOSTING_SUMMARY_REPORT_PATH = 'financial-glposting-summary-report'
export const FINANCIAL_GLPOSTING_PREVIEW_REPORT_PATH = 'financial-glposting-preview-report'

// depreciation reports: summary, continutity, history, edit, comparison, projection, production
export const DEPRECIATION_SUMMARY_REPORT_PATH = 'depreciation-summary-report'
export const DEPRECIATION_CONTINUITY_REPORT_PATH = 'depreciation-continuity-report'
export const DEPRECIATION_HISTORY_REPORT_PATH = 'depreciation-history-report'
export const DEPRECIATION_EDIT_REPORT_PATH = 'depreciation-edit-report'
export const DEPRECIATION_COMPARISON_REPORT_PATH = 'depreciation-comparison-report'
export const DEPRECIATION_PROJECTION_REPORT_PATH = 'depreciation-projection-report'
export const DEPRECIATION_PRODUCTION_REPORT_PATH = 'depreciation-production-report'

// purchase reports: analysis, spreadsheet
export const PURCHASE_ANALYSIS_REPORT_PATH = 'purchase-analysis-report'
export const PURCHASE_ANALYSIS_SPREADSHEET_REPORT_PATH = 'purchase-spreadsheet-report'

// location reports: current, history, accounting
export const LOCATION_CURRENT_REPORT_PATH = 'location-current-report'
export const LOCATION_HISTORY_REPORT_PATH = 'location-history-report'
export const LOCATION_ACCOUNTING_REPORT_PATH = 'location-accounting-report'

// service reports: analysis, scheduling, accounting
export const SERVICE_ANALYSIS_REPORT_PATH = 'service-analysis-report'
export const SERVICE_SCHEDULING_REPORT_PATH = 'service-scheduling-report'
export const SERVICE_ACCOUNTING_REPORT_PATH = 'service-accounting-report'

// task activity reports: analysis, accounting
export const TASK_ACTIVITY_ANALYSIS_REPORT_PATH = 'task-activity-analysis-report'
export const TASK_ACTIVITY_ACCOUNTING_REPORT_PATH = 'task-activity-accounting-report'

// assets by folder report
export const ASSETS_BY_FOLDERS_REPORT_PATH = 'assets-by-folders-report'

//Charges form
export const CHARGES_PATH = 'charges-path'
