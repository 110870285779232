import TopBar from './TopBar'
import Sidebar from './Sidebar'
import Main from './Main'
import { StyleObject } from '@styles-api/css.types'
import { useStyletron } from 'styletron-react'
import { ToastContainer } from '@components/common'

const flexBody: StyleObject = {
  display: 'grid',
  gridTemplateColumns: 'var(--sidebar-width) 1fr',
  gridTemplateAreas: "'appSidebar appMainContent'",
  width: '100%',
}

const Frame = ({ children }) => {
  const [css] = useStyletron()

  return (
    <>
      <TopBar />
      <div className={css(flexBody)}>
        <Sidebar />
        <Main>{children}</Main>
      </div>
      <ToastContainer />
    </>
  )
}
export default Frame
