import { FieldLabels, GeneratePeriodsParams } from '@api/models'
import { Checkbox, Form, Grid, Input, Note } from '@components/common'
import { COMPANY_GENERATE_PERIODS_FORM_ID } from '@constants'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'

interface Props {
  companyHasPeriods: boolean
  handleGeneratePeriods: (payload: GeneratePeriodsParams) => Promise<void>
  fields: FieldLabels
  assetCompanyID: number
  mostRecentGeneratedFiscalYear: number
}

const GeneratePeriodsForm = ({
  companyHasPeriods,
  handleGeneratePeriods,
  fields,
  assetCompanyID,
  mostRecentGeneratedFiscalYear,
}: Props) => {
  // React-hook-form context
  const methods = useFormContext<GeneratePeriodsParams>()

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
    control,
    watch,
  } = methods

  const { getInputProps, getCheckBoxProps } = useFormFieldProps({
    isSubmitting,
    errors,
    register,
    fields,
  })

  // Form UI
  return (
    <Form
      id={COMPANY_GENERATE_PERIODS_FORM_ID}
      noValidate
      onSubmit={handleSubmit(handleGeneratePeriods)}
    >
      <Grid>
        {companyHasPeriods ? (
          <Grid.Item>
            <Note
              content={
                <>
                  Most recent generated fiscal year for company {assetCompanyID} is{' '}
                  {mostRecentGeneratedFiscalYear}
                </>
              }
            />
          </Grid.Item>
        ) : (
          <Grid.Item>
            <Input
              {...getInputProps('FromYear', 'number')}
              label="From year"
              min={1900}
              max={watch('ToYear')}
              autoFocus
            />
          </Grid.Item>
        )}

        <Grid.Item>
          <Input
            {...getInputProps('ToYear', 'number')}
            label="Up to year"
            min={1900}
            max={2999}
            autoFocus={companyHasPeriods}
            helper={
              companyHasPeriods
                ? ''
                : 'Set to same as From year to generate one fiscal year.'
            }
          />
        </Grid.Item>
        <Grid.Item>
          <Controller
            control={control}
            name="AllowWeekends"
            render={({ field }) => (
              <Checkbox {...getCheckBoxProps(field)} label="Allow weekends" />
            )}
          />
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default GeneratePeriodsForm
