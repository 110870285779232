import React from 'react'
import { ViewProps } from './View.types'
import { Helmet } from 'react-helmet'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import ViewHeader from './ViewHeader'
import LocalNav from '../LocalNav'
import BackButton from './BackButton'
import Breadcrumbs from '../Breadcrumbs'

const View: React.FC<ViewProps> = ({
  title,
  pageHeader,
  localNav,
  backText,
  backTo,
  maxWidth = 'full',
  showHeader = true,
  breadcrumbs,
  ...props
}) => {
  const [css] = useStyletron()

  const Head = () => (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )

  let containerStyles = css({
    ...styles.viewContainer,
    ...styles.getMaxWidth({ maxWidth }),
  })

  return (
    <React.Fragment>
      <Head />
      <div>
        {backTo && <BackButton backTo={backTo} backText={backText ? backText : 'Back'} />}
        {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} />}
        {title && showHeader && (
          <ViewHeader
            pageHeader={pageHeader ? pageHeader : title}
            localNav={localNav && <LocalNav items={localNav} />}
          />
        )}
        <div className={containerStyles}>{props.children}</div>
      </div>
    </React.Fragment>
  )
}

export default View
