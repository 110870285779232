import HttpClient from "../HttpClient";

export class CommentApi extends HttpClient {
    private static classInstance?: CommentApi;

    public constructor () {
        super (`/Comment`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new CommentApi();
        }

        return this.classInstance;
    }

    public get = (assetCompanyId: number, objectId: string, type: string) => this.instance.get(`/GetComment?assetCompanyId=${assetCompanyId}&objectId=${objectId}&type=${type}`);
    public import = (file: any) => this.instance.post(`/ImportComment`, file);
    public create = (data: any) => this.instance.post(`/Create`, data);
    public edit = (data: any) => this.instance.put(`/Edit`, data);
    public delete = (data: any) => this.instance.post(`/Delete`, data);
}