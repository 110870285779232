import {
  CAN_ACCESS_FIXED_ASSETS_ADMIN_PORTAL,
  CAN_RUN_DEPRECIATION,
  CAN_RUN_REPORTS,
  CAN_VIEW_ASSETS,
  UserRoleGrant,
} from '@components/Auth/UserRole'

export interface SideBarNaveItem {
  path: string
  label: string
  grants?: UserRoleGrant
}

export const primaryNavHeading = 'Primary'
export const primaryNavItems: SideBarNaveItem[] = [
  {
    path: '/admin-portal',
    label: 'Admin Portal',
    grants: CAN_ACCESS_FIXED_ASSETS_ADMIN_PORTAL,
  },
  {
    path: '/',
    label: 'Dashboard',
  },
  {
    path: '/assets',
    label: 'Assets',
    grants: CAN_VIEW_ASSETS,
  },
  {
    path: `/reports`,
    label: 'Reports',
    grants: CAN_RUN_REPORTS,
  },
  {
    path: '/depreciation',
    label: 'Depreciation',
    grants: CAN_RUN_DEPRECIATION,
  },
]

export const secondaryNavHeading = 'Lists'
export const secondaryNavItems: SideBarNaveItem[] = [
  {
    path: '/companies',
    label: 'Companies',
  },
  {
    path: '/segments',
    label: 'Segments',
  },
  {
    path: '/post-codes',
    label: 'G/L Post Codes',
  },
  {
    path: '/categories',
    label: 'Categories',
  },
  {
    path: '/tax-classes',
    label: 'Tax Classes',
  },
  {
    path: '/vendors',
    label: 'Vendors',
  },
  {
    path: '/folders',
    label: 'Folders',
  },
  {
    path: '/tasks-activities',
    label: 'Tasks/Activities',
  },
  {
    path: '/insurance-policies',
    label: 'Insurance Policies',
  },
  {
    path: '/maintenance-contracts',
    label: 'Maintenance Contracts',
  },
  {
    path: '/settings',
    label: 'Settings',
  },
]
