import AddAssetForm from './AddAssetForm'
import useAddAssetForm from './useAddAssetForm'
import Template from '@components/layout/Template/Template'
import AddAssetFormWrapperLoading from './AddAssetFormWrapperLoading'

interface IAddAssetFormWrapper {
  assetCompanyID: number,
}

const AddAssetFormWrapper = ({ assetCompanyID }: IAddAssetFormWrapper) => {
  const { isLoading } = useAddAssetForm({ assetCompanyID })

  if (isLoading)
    return (
      <Template>
        <AddAssetFormWrapperLoading />
      </Template>
    )

  return <AddAssetForm assetCompanyID={assetCompanyID}/>
}

export default AddAssetFormWrapper
