import { StyleObject } from '@styles-api/css.types'

export const FieldsetStyles: StyleObject = {
  borderWidth: '1px',
  borderRadius: 'var(--radius1)',
  paddingTop: 'var(--gap-compact)',
  paddingBottom: 'var(--gap-compact)',
  paddingLeft: 'var(--gap-compact)',
  paddingRight: 'var(--gap-compact)',
}
export const fieldset_flexStyles: StyleObject = {
  display: 'flex',
}
export const fieldset__legend_centerStyles: StyleObject = {
  textAlign: 'center',
}
