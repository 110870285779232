import { EmptyState, Fieldset, Grid, Modal } from '@components/common'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import MemorizedReportsTable from '../MemorizeReports/MemorizedReportsTable'
import { useMemorizeReport } from '../MemorizeReports/useMemorizeReport'
interface IRetrieveReport {
  name?: string
  description?: string
  path?: string
  required?: boolean
  isEdit?: boolean
  isDelete?: boolean
}

interface IRetrieveReportProps {
  isRetrieveTemplateModalOpen: boolean
  closeRetrieveTemplateModal: () => void
  handleRetrieveTemplateSubmit?: (data: IRetrieveReport) => void
}

const RetrieveReport = ({
  isRetrieveTemplateModalOpen,
  closeRetrieveTemplateModal,
  handleRetrieveTemplateSubmit,
}: IRetrieveReportProps) => {
  const [disableRetrieveBtn, setDisableRetrieveBtn] = useState(true)

  const {
    allReportTemplateData,
    handleDeleteTemplateSubmit,
    isDelete,
    setIsDelete,
  } = useMemorizeReport({})

  const hasExistingTemplate = allReportTemplateData?.length > 0

  const {
    register: registerRetrieveReport,
    handleSubmit: handleSubmitRetrieveReport,
    reset: resetRetrieveReport,
    setValue: setValueRetrieveReport,
    formState: {
      errors: errorsRetrieveReport,
      isSubmitting: isSubmittingRetrieveReport,
      isSubmitSuccessful: isSubmitSuccessfulRetrieveReport,
    },
  } = useForm<IRetrieveReport>({
    mode: 'onSubmit',
  })

  const handleEditButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setValueRetrieveReport('isEdit', true)
    handleSubmitRetrieveReport(handleRetrieveTemplateSubmit)
  }

  const handleDeleteButtonClick = () => {
    setIsDelete(true)
  }

  const handleSelectedRow = row => {
    setDisableRetrieveBtn(false)
  }

  useEffect(() => {
    if (isSubmitSuccessfulRetrieveReport) {
      resetRetrieveReport()
    }
    if (!isRetrieveTemplateModalOpen) {
      resetRetrieveReport()
    }

    setDisableRetrieveBtn(true)
  }, [isSubmitSuccessfulRetrieveReport, isRetrieveTemplateModalOpen])

  return (
    <Modal
      isOpen={isRetrieveTemplateModalOpen}
      close={closeRetrieveTemplateModal}
      onSubmit={
        isDelete
          ? handleSubmitRetrieveReport(handleDeleteTemplateSubmit)
          : handleSubmitRetrieveReport(handleRetrieveTemplateSubmit)
      }
      title={`Retrieve Template`}
      actionLabel={'Retrieve'}
      actionDisabled={disableRetrieveBtn}
      width={800}
      customStyles={{
        position: 'fixed',
        top: '10%',
      }}
    >
      <Grid>
        <Grid.Item>
          <Fieldset legend="Current List">
            {hasExistingTemplate ? (
              <MemorizedReportsTable
                data={allReportTemplateData}
                isRetrieveTemplateModal={true}
                setValueRetrieveReport={setValueRetrieveReport}
                resetRetrieveReport={resetRetrieveReport}
                handleEditButtonClick={handleEditButtonClick}
                handleDeleteButtonClick={handleDeleteButtonClick}
                handleSelectedRow={handleSelectedRow}
              />
            ) : (
              <EmptyState
                heading="No saved templates found"
                text="Go to a report and select 'Memorize template' to save a new one."
                height={100}
              />
            )}
          </Fieldset>
        </Grid.Item>
      </Grid>
    </Modal>
  )
}

export default RetrieveReport
