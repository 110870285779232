export const getProfile = () => {
  return JSON.parse(
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`
    )
  ).profile
}

export const getUserFullName = () => getProfile().name
export const getUserEmail = () => getProfile().username
export const getCurrentUserRoles = () => getProfile().role
