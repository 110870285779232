import * as Yup from 'yup'

interface Props {
  name
  fieldMap
  schema
  form
  setForm
  initialValues?
}

export function getAsyncSelectPropsForModalForms({
  name,
  fieldMap,
  schema,
  form,
  setForm,
  initialValues,
}: Props) {
  function onChangeHandler(item: { label: string; value: string }) {
    const value = item?.value || ''

    Yup.reach(schema, name)
      .validate(value)
      .then(() => {
        if (form.values[name] === value) {
          return
        }

        setForm(prevState => {
          let errors = { ...prevState.errors }
          if (errors[name]) delete errors[name]
          return { errors, values: { ...prevState.values, [name]: value } }
        })
      })
      .catch(err => {
        setForm(prevState => ({
          values: { ...prevState.values, [name]: value },
          errors: { ...prevState.errors, [name]: err.message },
        }))
      })
  }

  let required
  if (schema.fields[name].exclusiveTests.required) required = true

  let value
  if (
    initialValues[name] &&
    form.values[name] === initialValues[name].value &&
    initialValues[name].value !== ''
  ) {
    value = initialValues[name]
  } else if (form.values[name] === '') {
    value = null
  }

  let errorMsg
  let error
  if (form.errors[name]) {
    errorMsg = form.errors[name]
    error = true
  }

  return {
    onChange: onChangeHandler,
    label: fieldMap[name],
    value,
    errorMsg,
    error,
    required,
  }
}
