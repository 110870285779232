import React from 'react'

import { useStyletron } from 'styletron-react'
import * as styles from '../Styles'

export interface TableRowProps extends React.HTMLProps<HTMLTableRowElement> {
  borderBottom?: 'none' | 'strong' | 'thin'
  borderTop?: 'none' | 'strong' | 'thin'
}

const Row: React.FC<TableRowProps> = ({
  borderBottom = 'none',
  borderTop = 'none',
  ...props
}) => {
  const [css] = useStyletron()

  const borderBottomStyle = ({ border }) => {
    let borderWidth: string

    switch (border) {
      case 'strong':
        borderWidth = '2px'
        break
      case 'thin':
        borderWidth = '1px'
        break
    }

    return {
      borderBottomWidth: borderWidth,
    }
  }

  return (
    <tr
      {...props}
      className={`${css(styles.row)} ${css(
        borderBottomStyle({ border: borderBottom })
      )}`}
    >
      {props.children}
    </tr>
  )
}

export default Row
