import { Card, Checkbox, Fieldset, Form, Grid, Spacer, toast } from '@components/common'
import { ReportsApi } from '@api'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { ADMIN_PROPERTIES_REPORT_PATH, ERROR_REQUEST } from '@constants'
import ReportFilters from '../ReportFilters'
import { defaultReportFilterFields } from '../ReportFilters/report-filter-fields'
import { AdminPropertiesReportParams } from '@api/models'
import { ActionButtons } from '../General'
import { useReport } from '@hooks'
import { useEffect } from 'react'
import { reportResponseHandler } from '@utils/reports'
import { useMemorizeReport } from '../General/MemorizeReports/useMemorizeReport'
import MemorizeReports from '../General/MemorizeReports/MemorizeReport'
import { useLocation } from 'react-router-dom'
import { useFormMethods } from '../General/useFormMethods'

const defaultValues: AdminPropertiesReportParams = {
  assetCompanyID: 0,
  filter: [],
  includeDisposals: false,
  includeAssetDesc: false,
  includeCategoryDesc: false,
  includeTaxClassDesc: false,
  fileFormat: 'pdf',
}

async function onSubmitHandler(values: AdminPropertiesReportParams) {
  await ReportsApi.getInstance()
    .getAdminPropertiesReport(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

const AdminPropertiesForm = () => {
  const location = useLocation()
  const locationState: any = location.state

  const {
    methods,
    isRetrievingTemplate,
    isEditTemplate,
    selectedTemplateDetails,
  } = useFormMethods(locationState, defaultValues)

  const {
    formState: { isSubmitting },
    control,
    setValue,
  } = methods

  const { isLoading, isSuccess, isError, isNoCompanies, assetCompanyID } = useReport()

  const {
    handleMemorizeTemplateSubmit,
    isMemorizeTemplateModalOpen,
    closeMemorizeTemplateModal,
    triggerMemorizeTemplateModal,
    handleEditTemplateSubmit,
  } = useMemorizeReport({ methods, currentReportPath: ADMIN_PROPERTIES_REPORT_PATH })

  useEffect(() => {
    if (isSuccess) {
      setValue('assetCompanyID', assetCompanyID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, assetCompanyID])

  function getCheckBoxProps(field: any) {
    return {
      checked: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (isNoCompanies) {
    return <div>No companies found</div>
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Card title={'Report Parameters'}>
            <Grid>
              <Grid.Item m={6}>
                <Fieldset legend={'Additional Columns To Include'}>
                  <Grid>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeAssetDesc"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Asset Description"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeCategoryDesc"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Category Description"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeTaxClassDesc"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Tax Class Description"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item m={6}>
                <Fieldset legend={'Options'}>
                  <Grid>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDisposals"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Disposals"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item>
                <ReportFilters
                  filtersTemplateData={
                    isRetrievingTemplate
                      ? locationState.templateDetails.templateData.filter
                      : []
                  }
                  filterFields={defaultReportFilterFields}
                />
              </Grid.Item>
            </Grid>
          </Card>
          <Spacer y="default" />
          <ActionButtons
            isSubmitting={isSubmitting}
            allowExcel={false}
            triggerMemorizeTemplateModal={triggerMemorizeTemplateModal}
          />
        </Form>
      </FormProvider>

      <MemorizeReports
        isMemorizeTemplateModalOpen={isMemorizeTemplateModalOpen}
        closeMemorizeTemplateModal={closeMemorizeTemplateModal}
        handleMemorizeTemplateSubmit={handleMemorizeTemplateSubmit}
        handleEditTemplateSubmit={handleEditTemplateSubmit}
        isEditTemplate={isEditTemplate}
        selectedTemplateDetails={selectedTemplateDetails}
      />
    </>
  )
}

export default AdminPropertiesForm
