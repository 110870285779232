export const ASSET_VIEWS = {
  DETAILS: 'details',
  DEPRECIATION: 'depreciation',
  HISTORY: 'history',
  STRUCTURE: 'structure',
  LOCATION: 'location',
  SERVICE: 'service',
  TASKS: 'tasks',
}
export const ASSET_HISTORY_VIEWS = {
  CORPORATE: 'corporate',
  TAX: 'tax',
  STATE: 'state',
  OTHER: 'other',
  CAPITALIZATION: 'capitalization',
}
export const ASSET_DEPRECIATION_VIEWS = {
  CORPORATE: 'corporate',
  TAX: 'tax',
  STATE: 'state',
  OTHER: 'other',
}
export const COMPANY_VIEWS = {
  ROOT: 'companies',
  DETAILS: 'details',
  PERIOD_SETUP: 'period-setup',
}
export const DEPRECIATION_CLASS_VIEWS = {
  DETAILS: 'details',
  CUSTOM_DEPRECIATION: 'custom-depreciation',
}
