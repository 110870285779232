import Tasks from '@components/Tasks'
import View from '@components/layout/View'

const TasksView = () => {
  return (
    <View title="Tasks" showHeader={false}>
      <Tasks />
    </View>
  )
}

export default TasksView
