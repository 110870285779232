import { StyleObject } from '@styles-api/css.types'

// const ITEM_X_PADDING = 'var(--scale600)'
// const ITEM_Y_PADDING = 'var(--scale400)'

// const paddingStyles: StyleObject = {
//   paddingTop: ITEM_Y_PADDING,
//   paddingBottom: ITEM_Y_PADDING,
//   paddingLeft: ITEM_X_PADDING,
//   paddingRight: ITEM_X_PADDING,
// }

export const container = (isShowingMenu: boolean): StyleObject => {
  return {
    backgroundColor: 'var(--base-color)',
    borderRadius: 'var(--radius2)',
    boxShadow: 'var(--card-shadow)',
    padding: 'var(--scale400)',
    overflow: 'visible',
  }
}

export const categoryList: StyleObject = {
  display: 'flex',
  flexDirection: 'row',
  paddingBlock: 'var(--scale400)',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: 'var(--shade100)',
  border: '2px solid blue',
}

export const link: StyleObject = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: 'var(--scale200)',
  borderRadius: 'var(--radius2)',
  color: 'var(--contrast-color)',
  ':hover': {
    backgroundColor: '#0070c9',
    color: '#fff',
    transition: 'all 0.2s ease-in-out',
  },
  backgroundColor: 'var(--shade100)',
}

export const shortDescription: StyleObject = {
  color: 'var(--shade400)',
  zIndex: 'var(--z1)',
  overflow: 'visible',
}

export const shortDescriptionTip: StyleObject = {
  position: 'absolute',
  zIndex: 'var(--z2)',
  width: '15rem',
  backgroundColor: 'var(--primary-color400)',
  padding: 'var(--scale200)',
  borderRadius: 'var(--radius2)',
  color: 'var(--base-color)',
  opacity: '0.9',
}
