import { Form, Grid, Input, Select, Tabs, AsyncSelect } from '@common'
import { GLPOST_FORM_ID } from '@constants'
import { GLPostKey } from '@api/models'
import { GLPostFormProps, useGLPostForm } from './useGLPostForm'

const GLPostForm = ({
  isEdit,
  initialValues,
  handleSubmit,
  selectedGLPostKeys,
  canEdit = true,
}: GLPostFormProps) => {
  const {
    getField,
    getAsyncField,
    handleFormSubmit,
    getSegments,
    sourceOptions,
    fields,
  } = useGLPostForm({
    handleSubmit,
    initialValues,
    selectedGLPostKeys,
    canEdit,
  })

  return (
    <Form id={GLPOST_FORM_ID} onSubmit={handleFormSubmit} noValidate>
      <Grid>
        {!isEdit && (
          <Grid.Item>
            <Input {...getField('GL_POST_ID')} readOnly={isEdit} autoFocus={!isEdit} />
          </Grid.Item>
        )}

        <Grid.Item>
          <Input {...getField('GL_DESC')} autoFocus={isEdit} />
        </Grid.Item>
        <Grid.Item>
          <Tabs>
            <Tabs.Item title="Credit">
              <Grid>
                <Grid.Item>
                  <Select
                    {...getField('GLICOCD', 'default', sourceOptions)}
                    label="Source"
                  />
                </Grid.Item>
                {[...Array(10)].map((select, index) => (
                  <Grid.Item s={6} key={`GLCKEY${index}`}>
                    <AsyncSelect
                      {...getAsyncField(`GLCKEY${index}` as GLPostKey)}
                      label={fields[`AS_KEY${index}_ID`]}
                      action={value => getSegments(index, value)}
                      disabled={!canEdit}
                    />
                  </Grid.Item>
                ))}
              </Grid>
            </Tabs.Item>
            <Tabs.Item title="Debit">
              <Grid>
                <Grid.Item>
                  <Select
                    {...getField('GLIDOCD', 'default', sourceOptions)}
                    label="Source"
                  />
                </Grid.Item>
                {[...Array(10)].map((select, index) => (
                  <Grid.Item s={6} key={`GLDKEY${index}`}>
                    <AsyncSelect
                      {...getAsyncField(`GLDKEY${index}` as GLPostKey)}
                      label={fields[`AS_KEY${index}_ID`]}
                      action={value => getSegments(index, value)}
                      disabled={!canEdit}
                    />
                  </Grid.Item>
                ))}
              </Grid>
            </Tabs.Item>
          </Tabs>
        </Grid.Item>
      </Grid>
    </Form>
  )
}
export default GLPostForm
