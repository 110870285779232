import { useParams } from 'react-router-dom'
import { FormProvider } from 'react-hook-form'
import { Button, Card, Flexbox, Icon, Spacer } from '@components/common'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import AssetLocationsForm from './AssetLocationsForm'
import Template from '@components/layout/Template'
import useAssetCharge from '@hooks/useAssetCharge'
import usePermission from '@components/Auth/Permissions/usePermission'

interface AssetLocationWrapperProps {
  isCreate: boolean
  systemNumber: number
}

const AssetLocationWrapper = ({ isCreate, systemNumber }: AssetLocationWrapperProps) => {
  const urlParams = useParams()
  const assetCompanyID = parseInt(urlParams.companyID)
  const assetID = urlParams.assetID
  const type = 'L'

  const {
    handleChargeFormSubmit,
    fields,
    formMethods,
    isLoading,
    isLoadingFormSubmit,
  } = useAssetCharge({
    assetCompanyID,
    assetID,
    type,
    systemNumber,
    isCreate,
  })

  const { data: isAssetDesc, isLoading: isLoadingDesc } = useGetAssetQuery({
    assetCompanyID,
    assetID,
  })

  const { canEditAssets: canEdit } = usePermission()

  const EditingEnabled =
    isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false

  if (isLoading || isLoadingDesc || isLoadingFormSubmit) {
    return <p> Loading...</p>
  }
  return (
    <>
      <Template maxWidth="compact">
        <Flexbox justifyContent="flex-start">
          <Button link=".." icon={<Icon name="ArrowLeft" />}>
            Back to main page
          </Button>
        </Flexbox>
        <Spacer y="default" />
        <Card
          title={`${isCreate ? 'Add' : 'Edit'} location record - Asset ${assetID} : ${
            isAssetDesc.AS_DESC
          }`}
        >
          <FormProvider {...formMethods}>
            <AssetLocationsForm
              fields={fields}
              handleChargeFormSubmit={handleChargeFormSubmit}
              isCreate={isCreate}
              EditingEnabled={EditingEnabled}
              canEdit={canEdit}
            />
          </FormProvider>
        </Card>
      </Template>
    </>
  )
}

export default AssetLocationWrapper
