import { Stepper } from '@common'
import { Step } from '@components/common/Stepper/Stepper.types'
import DepreciationProfiles from './DepreciationProfiles/DepreciationProfiles'
import Confirm from './Confirm/Confirm'
import Identifiers from './Identifiers/Identifiers'
import PurchaseDetails from './PurchaseDetails/PurchaseDetails'
import { ASSET_FORM_ID } from '@constants'
import useAddAssetForm from './useAddAssetForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Asset } from '@api/models'

interface IAddAssetForm {
  assetCompanyID: number,
}

const AddAssetForm = ({ assetCompanyID }: IAddAssetForm) => {
  const {
    fields,
    schema,
    identifiersFieldNames,
    identifiersLabel,
    purchaseDetailsFieldNames,
    deprProfilesFieldNames,
    purchaseDetailsLabel,
    deprProfilesLabel,
    handleAddAsset,
    isLoadingAddNewAsset,
    maintainedDepProfiles,
  } = useAddAssetForm({
    assetCompanyID,
  })

  const methods = useForm<Asset>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: schema.cast({ assetCompanyID }),
  })

  const formSteps: Array<Step> = [
    {
      label: identifiersLabel,
      element: <Identifiers fields={fields} />,
      fieldNames: identifiersFieldNames,
    },
    {
      label: purchaseDetailsLabel,
      element: <PurchaseDetails fields={fields} />,
      fieldNames: purchaseDetailsFieldNames,
    },
    {
      label: deprProfilesLabel,
      element: (
        <DepreciationProfiles
          fields={fields}
          maintainedDepProfiles={maintainedDepProfiles}
          assetCompanyID={assetCompanyID}
        />
      ),
      fieldNames: deprProfilesFieldNames,
    },
    {
      label: 'Review and Confirm',
      element: <Confirm maintainedDepProfiles={maintainedDepProfiles} fields={fields} />,
      fieldNames: [],
    },
  ]

  return (
    <Stepper
      methods={methods}
      steps={formSteps}
      schema={schema}
      formId={ASSET_FORM_ID}
      submitLabel="Add asset and continue"
      isLoadingSubmit={isLoadingAddNewAsset}
      onSubmit={handleAddAsset}
    />
  )
}
export default AddAssetForm
