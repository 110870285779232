import { maxStringMsg } from '@utils/basic'
import { REQUIRED_FIELD } from '@constants'
import * as Yup from 'yup'
import { dateExistValidation, digitLengthValidation } from '../../models/validation'

export const insuranceSchema = Yup.object({
  IN_POL_ID: Yup.string().max(12, maxStringMsg(12)).required(REQUIRED_FIELD),
  IN_DESC: Yup.string().max(50, maxStringMsg(50)).required(REQUIRED_FIELD),
  IN_NAME: Yup.string().max(50, maxStringMsg(50)).nullable(),
  IN_STREET: Yup.string().max(50, maxStringMsg(50)).nullable(),
  IN_CITY: Yup.string().max(25, maxStringMsg(25)).nullable(),
  IN_CNTY: Yup.string().max(25, maxStringMsg(25)).nullable(),
  IN_STATE: Yup.string().max(2, maxStringMsg(2)).nullable(),
  IN_ZIP: Yup.string().max(10, maxStringMsg(10)).nullable(),
  IN_PHONE: Yup.string().max(14, maxStringMsg(14)).nullable(),
  IN_FAX: Yup.string().max(14, maxStringMsg(14)).nullable(),
  IN_CON_NM: Yup.string().max(25, maxStringMsg(25)).nullable(),
  IN_CON_PN: Yup.string().max(14, maxStringMsg(14)).nullable(),
  IN_CON_FX: Yup.string().max(14, maxStringMsg(14)).nullable(),
  IN_INS_AMT: Yup.number().test(digitLengthValidation(13)),
  IN_PRM_AMT: Yup.number().test(digitLengthValidation(13)),
  IN_YR_DED: Yup.number().test(digitLengthValidation(13)),
  IN_OC_DED: Yup.number().test(digitLengthValidation(13)),
  IN_AG_LMT: Yup.number().test(digitLengthValidation(13)),
  IN_CP_FRM: Yup.string().required(REQUIRED_FIELD).test(dateExistValidation),
  IN_CP_TO: Yup.string().required(REQUIRED_FIELD).test(dateExistValidation),
  IN_PMT_CD: Yup.string().max(1, maxStringMsg(1)).nullable(), // TODO: remove nullable after adding payment terms
  IN_REN_CD: Yup.boolean(),
})

export type InsurancePolicy = Yup.InferType<typeof insuranceSchema>
export type InsurancePolicyKey = keyof InsurancePolicy

export const initialInsurancePolicy: InsurancePolicy = {
  IN_POL_ID: '',
  IN_DESC: '',
  IN_NAME: undefined,
  IN_STREET: undefined,
  IN_CITY: undefined,
  IN_CNTY: undefined,
  IN_STATE: undefined,
  IN_ZIP: undefined,
  IN_PHONE: undefined,
  IN_FAX: undefined,
  IN_CON_NM: undefined,
  IN_CON_PN: undefined,
  IN_CON_FX: undefined,
  IN_INS_AMT: 0,
  IN_PRM_AMT: 0,
  IN_YR_DED: 0,
  IN_OC_DED: 0,
  IN_AG_LMT: 0,
  IN_CP_FRM: undefined,
  IN_CP_TO: undefined,
  IN_PMT_CD: undefined,
  IN_REN_CD: false,
}

interface InsuranceProps {
  appCompanyID?: number
  iN_POL_ID?: string
  iN_DESC?: string
  iN_NAME?: string
  iN_STREET?: string
  iN_CITY?: string
  iN_CNTY?: string
  iN_STATE?: string
  iN_ZIP?: string
  iN_PHONE?: string
  iN_FAX?: string
  iN_CON_NM?: string
  iN_CON_PN?: string
  iN_CON_FAX?: string
  iN_INS_AMT?: number
  iN_PRM_AMT?: number
  iN_YR_DED?: number
  iN_OC_DED?: number
  iN_OC_LMT?: number
  iN_AG_LMT?: number
  iN_CP_FRM?: string
  iN_CP_TO?: string
  iN_PMT_CD?: string
  iN_REN_CD?: boolean
  paymentTerm?: {
    id?: string
    name?: string
  }
}

type InsuranceList = Array<{ id: string; name: string }>

export type { InsuranceProps, InsuranceList }

// Payloads and responses
export type ListInsurancesResponse = { ID: string; Name: string }[]

export interface FetchInsurancesResponse {
  count: number
  list: InsurancePolicy[]
}
