import { Card, Spacer, TableSkeleton } from '@components/common'

const CompanyDetailsLoading = () => {
  return (
    <>
      <Card spacing="none" overflow="hidden">
        <TableSkeleton rows={3} />
      </Card>

      <Spacer y="default" />

      <Card spacing="none" overflow="hidden">
        <TableSkeleton rows={3} />
      </Card>
    </>
  )
}
export default CompanyDetailsLoading
