import { StyleObject } from '@styles-api/css.types'

const styles = {
  circle: {
    display: 'inline-block',
    backgroundColor: '#1d3649',
    borderRadius: '50%',
  },
  circleInner: {
    color: 'white',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'table-cell',
    verticalAlign: 'middle',
  },
}

export const circle: StyleObject = styles.circle
export const circleInner: StyleObject = styles.circleInner
