import { useEffect, useState } from 'react'
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { AppState, APP_STATE_TYPES } from '@api/models'
import { apiSlice } from '@api'
import { useAppDispatch } from '../index'
import usePrevious from './usePrevious'
import { isNullOrUndefined } from '@utils/basic'
import { toast } from '@components/common'

export default function useAppState() {
  const dispatch = useAppDispatch()
  const [state, setState] = useState<{ isLoading: boolean; appState: AppState }>({
    isLoading: true,
    appState: null,
  })
  const prevAppState = usePrevious(state.appState)

  useEffect(() => {
    let connection: HubConnection;
    
    const SubscribeAppState = async () => {
      const oidcUserSessionPath = `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`;

      try {
        const storedUser = sessionStorage.getItem(oidcUserSessionPath);
        if (!storedUser) {
          throw new Error('No stored user found');
        }

        const token = JSON.parse(storedUser).access_token;
        if (!token) {
          throw new Error('No access token found');
        }

        const connection = new HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_API_URI}/hubs/AppStateHub`, {
            accessTokenFactory: () => token,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          })
          .configureLogging(LogLevel.None) // Use LogLevel.Trace for debugging
          .build();

        connection.on('ReceiveAppState', (appState) => {
          setState({
            isLoading: false,
            appState,
          });
        });

        await connection.start();
        await connection.invoke('SubscribeAppState');
      } catch (err) {
        console.error('Error establishing SignalR connection:', err);
      }
    };

    SubscribeAppState();

    // Cleanup function to ensure the connection is closed when the component unmounts
    return () => {
      connection.stop().catch(err => console.error('Error stopping SignalR connection:', err));
    };
  }, []);

  useEffect(() => {
    // null checks
    if (isNullOrUndefined(prevAppState) || isNullOrUndefined(state.appState)) return

    const currentAppState = state.appState.StateType

    // ensures cache invalidation only happens when going from (not IDLE => to IDLE)
    if (
      prevAppState.StateType === APP_STATE_TYPES.IDLE ||
      currentAppState !== APP_STATE_TYPES.IDLE
    )
      return

    // invalidate cache based on app state type
    switch (prevAppState.StateType) {
      case APP_STATE_TYPES.PERIOD_DEPRECIATION_RUN:
        toast.success(
          'Depreciation has run successfully. Results can be checked by running the Depreciation Edit Report.'
        )
        dispatch(apiSlice.util.invalidateTags(['depreciationRun', 'depreciationStatus']))
        break
      case APP_STATE_TYPES.YEAR_END_DEPRECIATION_RUN:
        toast.success(
          'Depreciation has run successfully. Results can be checked by running the Depreciation Edit Report.'
        )
        dispatch(apiSlice.util.invalidateTags(['depreciationRun', 'depreciationStatus']))
        break

      case APP_STATE_TYPES.PERIOD_DEPRECIATION_UPDATE:
        toast.success('Period updated successfully')
        dispatch(apiSlice.util.invalidateTags(['depreciationStatus']))
        break
      case APP_STATE_TYPES.YEAR_END_DEPRECIATION_UPDATE:
        toast.success('Year end update successfully')
        dispatch(apiSlice.util.invalidateTags(['depreciationStatus']))
        break
      case APP_STATE_TYPES.PERIOD_DEPRECIATION_UNDO:
        toast.success('Period undo successfully')
        dispatch(apiSlice.util.invalidateTags(['depreciationStatus']))
        break
      case APP_STATE_TYPES.YEAR_END_DEPRECIATION_UNDO:
        toast.success('Year end undo successfully')
        dispatch(apiSlice.util.invalidateTags(['depreciationStatus']))
        // dispatch(apiSlice.util.resetApiState())
        // dispatch(setAssetCompany(prevAppState.AssetCompanyID))
        break
      case APP_STATE_TYPES.CATEGORY_RECALCULATION:
      case APP_STATE_TYPES.TAX_CLASS_RECALCULATION:
        // dispatch(apiSlice.util.resetApiState())
        break
      default:
        break
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevAppState, state.appState])

  return {
    ...state,
  }
}
