import { useStyletron } from 'styletron-react'

import { Button, Spacer } from '../'
import * as styles from './Styles'

interface Props {
  close
  dismissLabel
  actionLabel
  actionDisabled
  action
  intent
  dismissButton
  form
  type?: 'submit' | 'reset' | 'button'
  dismissType?: 'reset' | 'button'
  actionAutoFocus
  isActionLoading
}

const ModalFooter = ({
  close,
  dismissLabel,
  actionLabel,
  actionDisabled,
  action,
  intent,
  dismissButton,
  form,
  type = 'submit',
  dismissType = 'button',
  actionAutoFocus,
  isActionLoading,
}: Props) => {
  const [css] = useStyletron()
  return (
    <div className={css(styles.footer)}>
      {dismissButton && (
        <>
          <Button onClick={close} type={dismissType}>
            {dismissLabel}
          </Button>
          <Spacer x="mini" />
        </>
      )}

      <Button
        appearance="primary"
        intent={intent}
        onClick={action}
        type={type}
        form={form}
        disabled={actionDisabled || isActionLoading}
        autoFocus={actionAutoFocus}
        loading={isActionLoading}
        flex={!dismissButton}
      >
        {actionLabel}
      </Button>
    </div>
  )
}
export default ModalFooter
