import { FieldLabels, HistoryBase } from '@api/models'
import {
  CustomDatePicker,
  Form,
  Grid,
  Input,
  Note,
} from '@components/common'
import { MANUAL_HISTORY_FORM_ID } from '@constants'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'

interface Props {
  fields: FieldLabels
  handleChargeFormSubmit: (payload: HistoryBase) => Promise<void>
  isCreate?: boolean
}

const AssetHistoryManualForm = ({ fields, handleChargeFormSubmit, isCreate }: Props) => {
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useFormContext<HistoryBase>()

  const { getInputProps, getDateProps } = useFormFieldProps<HistoryBase>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  return (
    <Form
      id={MANUAL_HISTORY_FORM_ID}
      noValidate
      onSubmit={handleSubmit(handleChargeFormSubmit)}
    >
      <Grid>
        <Grid.Item>
          <Note intent="default" content="History record will be locked by default." />
        </Grid.Item>
        <Grid.Item>
          <Controller
            control={control}
            name="FN_AS_DAT"
            render={({ field }) => (
              <CustomDatePicker
                {...getDateProps('FN_AS_DAT', field)}
                autoFocus
                label="As of fiscal year end "
              />
            )}
          />
        </Grid.Item>
        <Grid.Item>
          <Input {...getInputProps('FN_LTD_DEP')} label="LTD Depreciation" />
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default AssetHistoryManualForm
