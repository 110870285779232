import { Asset } from '@api/models'
import { Button, Input, Modal, Note, Spacer, Text } from '@components/common'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { InputChangeEvent } from '@types'

interface Props {
  asset: Asset
  assetHasHistory: boolean
  handleDelete: () => Promise<void>
  isLoadingDelete: boolean
  isOpen: boolean
  setIsConfirmDeleteModalOpen: Dispatch<SetStateAction<boolean>>
}

const ConfirmDeleteAssetModal = ({
  asset,
  assetHasHistory,
  handleDelete,
  isLoadingDelete,
  isOpen,
  setIsConfirmDeleteModalOpen,
}: Props) => {
  const [inputState, setInputState] = useState<string>('')
  const DELETE_MATCH_CHARS = 'delete'

  function handleInputChange(e: InputChangeEvent) {
    setInputState(e.target.value)
  }

  const deleteButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (inputState === DELETE_MATCH_CHARS) {
      deleteButtonRef.current!.focus()
    }
  }, [inputState])

  useEffect(() => {
    setInputState('')
  }, [isOpen])

  const showWarning: boolean = assetHasHistory

  const warningMessage = () => {
    return (
      <>
        <Text bold>IMPORTANT</Text>
        <Spacer y="mini" />
        <Text p>
          This asset {asset.AS_DIS_CD === 'P' ? 'is pending disposal and ' : ''} has
          depreciation history. It is recommended that you{' '}
          <b>fully dispose of the asset before deleting</b> to ensure your records are
          accurate.
        </Text>
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      close={() => setIsConfirmDeleteModalOpen(false)}
      title={`Delete asset ${asset.AssetID} (${asset.AS_DESC})`}
    >
      {showWarning && (
        <>
          <Note intent="danger" content={warningMessage()} />
          <Spacer y="default" />
        </>
      )}

      <Text p>
        Are you sure you want to delete asset{' '}
        <b>
          {asset.AssetID} ({asset.AS_DESC})
        </b>
        ? <Text intent="danger">This cannot be undone</Text>.
      </Text>

      <Spacer y="default" />

      <Input
        onChange={handleInputChange}
        value={inputState}
        label={`Enter '${DELETE_MATCH_CHARS}' to confirm`}
        autoFocus={!showWarning}
      />
      <Spacer y="default" />

      <div>
        {/* <Button flex onClick={() => setIsConfirmDeleteModalOpen(false)}>
          Cancel
        </Button>
        <Spacer y="mini" /> */}
        <Button
          flex
          onClick={handleDelete}
          appearance="primary"
          intent="danger"
          disabled={inputState !== DELETE_MATCH_CHARS}
          ref={deleteButtonRef}
          loading={isLoadingDelete}
        >
          Delete asset {asset.AssetID}
        </Button>
      </div>
    </Modal>
  )
}
export default ConfirmDeleteAssetModal
