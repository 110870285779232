import { useGetExtraDepreciationFieldsQuery } from '@api'
import { DeprecExtraFields, ExtraFieldsMap } from '@api/models'
import { ReactSelectOptions } from '@components/common/Select/Select.types'

interface IExtraFieldsHook {
  isLoadingExtraFields: boolean
  extraFieldsLabels: ExtraFieldsMap
  extraFields: DeprecExtraFields
}

interface FieldsHookProps {
  skipRequest?: boolean
}

export default function useExtraFields({
  skipRequest = false,
}: Partial<FieldsHookProps> = {}): IExtraFieldsHook {
  const {
    data: extraFields,
    isLoading: isLoadingExtraFields,
    isSuccess,
  } = useGetExtraDepreciationFieldsQuery(undefined, {
    skip: skipRequest,
  })

  let extraFieldsLabels: ExtraFieldsMap = {
    deprecCountry: {},
    deprecDepreciationType: {},
    depreciationMethod: {},
    dispositionType: {},
    startCode: {},
    deprecRateCode: {},
    glPost: {},
    accelerationCode: {},
    deprecSystem: {},
    yearOneDeprecAddonCode: {},
    taxCreditCode: {},
    deprecUserCode: {},
  }

  const glPostOptionsTransformed: ReactSelectOptions = []

  if (isSuccess) {
    Object.keys(extraFields).forEach(key => {
      extraFieldsLabels[key] = extraFields[key].reduce(
        (o, key) => ({ ...o, [key.value]: `${key.value} - ${key.label}` }),
        {}
      )
    })

    for (const glPostCode of extraFields.glPost) {
      glPostOptionsTransformed.push({
        label: `${glPostCode.value} (${glPostCode.label})`,
        value: glPostCode.value,
      })
    }
  }

  return {
    isLoadingExtraFields,
    extraFields: {
      ...extraFields,
      glPost: glPostOptionsTransformed,
    },
    extraFieldsLabels,
  }
}
