import { CompanyPeriod } from '@api/models'
import HttpClient from '../../HttpClient'

export class PeriodsApi extends HttpClient {
  private static classInstance?: PeriodsApi

  public constructor() {
    super(`/Periods`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new PeriodsApi()
    }

    return this.classInstance
  }

  public fetch = (companyId: number, year: number) =>
    this.instance.get(`/FetchPeriods?companyId=${companyId}&year=${year}`)
  public get = (companyId: number, year: number, periodNumber: number) =>
    this.instance.get(
      `/GetPeriod?companyId=${companyId}&year=${year}&periodNumber=${periodNumber}`
    )
  public getYears = (companyId: number) =>
    this.instance.get(`/GetYears?companyId=${companyId}`)
  public import = (file: any) => this.instance.post(`/ImportPeriods`, file)
  // public createGet = (assetCompanyId: string) =>
  //   this.instance.get(`/Create?assetCompanyId=${assetCompanyId}`)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (periodsToDelete: Array<CompanyPeriod>) =>
    this.instance.post(
      `/Delete?periods=${encodeURIComponent(
        `[${periodsToDelete.map(p => JSON.stringify(p))}]`
      )}`
    )
  public generate = (data: any) => this.instance.post(`/Generate`, data)
}
