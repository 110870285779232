import { Asset, FieldLabels } from '@api/models'
import {
  Card,
  Text,
  Button,
  Spacer,
  Note,
  Link,
} from '@components/common'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { useListSegmentsQuery } from '@api'
import { Dispatch, SetStateAction } from 'react'
import AssetGLKeysDisplay from './AssetGLKeysDisplay'
import AssetGLKeysForm from './AssetGLKeysForm'
import { IUserGrantProps } from '@components/Auth/UserRole'
import AssetGLKeysDisplayLoading from './AssetGLKeysDisplayLoading'

interface Props extends IUserGrantProps {
  fields: FieldLabels
  isLoadingInfo: boolean
  isGLKeysFormActive: boolean
  setIsGLKeysFormActive: Dispatch<SetStateAction<boolean>>
  dirtyFields: object
  assetHasHistory: boolean
  asset: Asset
  isAssetDisposedOrPendingDisposal: boolean
}

const AssetGLKeys = ({
  fields,
  isLoadingInfo,
  isGLKeysFormActive,
  setIsGLKeysFormActive,
  dirtyFields,
  assetHasHistory,
  asset,
  isAssetDisposedOrPendingDisposal,
  canEdit,
}: Props) => {
  const {
    data: segments,
    isLoading: isLoadingSegments,
    isSuccess: isSuccessGettingSegments,
  } = useListSegmentsQuery()

  const isLoadingInfoOrSegments = isLoadingSegments || isLoadingInfo
  const tenKeys = [...Array(10).keys()]

  let segmentOptions: {
    [key: number]: ReactSelectOptions
  } = {}
  tenKeys.forEach(key => (segmentOptions[key] = []))

  if (isSuccessGettingSegments) {
    for (const segment of segments) {
      segmentOptions[segment.KY_NR].push({
        label: `${segment.KY_VALUE} (${segment.KY_DESC})`,
        value: segment.KY_VALUE,
      })
    }
  }

  const glKeysFields = tenKeys.map(i => `AS_KEY${i}_ID`)

  let isCardValuesEdited: boolean
  for (const field of glKeysFields) {
    if (dirtyFields[field] === true) {
      isCardValuesEdited = true
    }
  }

  var editComponent = isCardValuesEdited ? (
    <Text light>Edited</Text>
  ) : (
    !isAssetDisposedOrPendingDisposal && (
      <Button onClick={() => setIsGLKeysFormActive(!isGLKeysFormActive)} appearance="text">
        {isGLKeysFormActive ? 'Close' : 'Edit'}
      </Button>
    )
  )

  return (
    <Card title={'GL keys'} {...(canEdit && { addon: editComponent })}>
      {(isGLKeysFormActive || isCardValuesEdited) && !isAssetDisposedOrPendingDisposal ? (
        <AssetGLKeysForm
          assetHasHistory={assetHasHistory}
          fields={fields}
          segmentOptions={segmentOptions}
        />
      ) : (
        <>
          {isLoadingInfoOrSegments && <AssetGLKeysDisplayLoading />}
          {!isLoadingInfoOrSegments && (
            <AssetGLKeysDisplay fields={fields} segmentOptions={segmentOptions} />
          )}
        </>
      )}
      {assetHasHistory && !isAssetDisposedOrPendingDisposal && !isLoadingInfo && (
        <>
          <Spacer y="default" />
          <Note
            content={
              <>
                Asset has depreciation history. To change GL keys, you must{' '}
                <Link to={`/assets/${asset.AssetCompanyID}/${asset.AssetID}/structure`}>
                  perform a structure transfer
                </Link>
                .
              </>
            }
          />
        </>
      )}
    </Card>
  )
}

export default AssetGLKeys
