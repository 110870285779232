import React from 'react'

import { SKELETON_SHINE } from '@styles-api/css-constants'
import { useStyletron } from 'styletron-react'

import * as styles from './Styles'
import { TextSkeleton } from '../Text'

interface Props {
  size?: 'mini' | 'compact' | 'default' | 'large'
  label?: boolean
  width?: string
  flexGrow?: boolean
  count?: number
  helperText?: boolean
}

const InputSkeleton: React.FC<Props> = ({
  size = 'default',
  label,
  width,
  flexGrow,
  count = 1,
  helperText,
}) => {
  const [css] = useStyletron()

  let containerStyles = css({ ...styles.container({ width, flexGrow }) })

  let labelStyles = css({
    ...styles.labelSkeleton,
    ...SKELETON_SHINE,
  })

  let inputStyles = css({
    ...styles.inputSkeleton({ size }),
    ...SKELETON_SHINE,
  })

  const returnItems = [...Array(count)].map((e, i) => (
    <li key={i}>
      {label && <div className={labelStyles} />}

      <div
        className={inputStyles}
        style={
          count > 1 && !label && !helperText
            ? { marginBottom: 'var(--scale300)', marginTop: 'var(--scale300)' }
            : null
        }
      />

      {helperText && <TextSkeleton width="70%" />}
    </li>
  ))

  return <ul className={containerStyles}>{returnItems}</ul>
}
export default InputSkeleton

/*

  return (
    <div className={containerStyles}>
      {label && <div className={labelStyles} />}

      <div className={inputStyles} />
    </div>
  )
  
  */
