import { useFields } from '@hooks'
import { useEffect, useMemo } from 'react'
import { Column, SortingRule, useFlexLayout, useSortBy, useTable } from 'react-table'
import {
  Button,
  ButtonGroup,
  Card,
  Flexbox,
  Icon,
  Modal,
  Search,
  Table,
  Text,
} from '@components/common'
import { InsurancePolicy } from '@api/models'
import Pagination from '@components/Pagination'
import { InsurancesHook } from '../useInsurances'
import { INSURANCE_FORM_ID } from '@constants'
import InsuranceForm from '../InsuranceForm'
import InsurancesTableLoading from './InsurancesTableLoading'

const InsurancesTable = ({
  pageNumber,
  itemsPerPage,
  handleSearch,
  searchValue,
  changePage,
  sortByInitialState,
  updateSortSearchParam,
  //
  searchKey,
  insurances,
  isLoadingInsurances,
  isErrorFetchingInsurances,
  isRefetchingInsurances,

  handleDeleteInsurance,
  isLoadingDeleteInsurance,
  openDeleteInsuranceModal,
  selectedInsurance,
  deleteInsuranceModalIsOpen,
  closeDeleteInsuranceModal,
  openEditInsuranceModal,
  closeEditInsuranceModal,
  editInsuranceModalIsOpen,
  handleEditInsurance,
  isLoadingEditInsurance,
  canEdit,
}: InsurancesHook) => {
  // Declare columns
  const { fields, isLoading: isLoadingFields, isError: isErrorGettingFields } = useFields()

  const columns = useMemo<Column<InsurancePolicy>[]>(
    () => [
      {
        Header: fields.IN_POL_ID,
        accessor: 'IN_POL_ID',
      },
      {
        Header: fields.IN_DESC,
        accessor: 'IN_DESC',
      },
      {
        Header: fields.IN_NAME,
        accessor: 'IN_NAME',
      },
      {
        Header: '',
        accessor: 'IN_AG_LMT',
        disableSortBy: true,
        Cell: ({ row }) => (
          <Flexbox alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
            <ButtonGroup hidden={!canEdit}>
              <Button
                icon={<Icon name="Edit" />}
                size="compact"
                onClick={e => {
                  e.stopPropagation()
                  openEditInsuranceModal(row.original)
                }}
              >
                Edit
              </Button>

              <Button
                icon={<Icon name="Trash2" />}
                size="compact"
                intent="danger"
                onClick={e => {
                  e.stopPropagation()
                  openDeleteInsuranceModal(row.original)
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Flexbox>
        ),
      },
    ],
    [isLoadingFields]
  )

  // Declare react table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: insurances ? insurances.list : [],
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        sortBy: sortByInitialState() as SortingRule<InsurancePolicy>[],
      },
    },
    useSortBy,
    useFlexLayout
  )

  // Update sort param
  useEffect(() => {
    updateSortSearchParam(sortBy, setSortBy)
  }, [sortBy])

  // Returns
  const isTableLoading = isLoadingInsurances || isLoadingFields
  const isError = isErrorFetchingInsurances || isErrorGettingFields

  if (isTableLoading) {
    return <InsurancesTableLoading />
  }

  if (isError) {
    return <Table.NoResults heading="There was an error." />
  }

  return (
    <>
      <Modal
        isOpen={deleteInsuranceModalIsOpen}
        close={closeDeleteInsuranceModal}
        title={`Delete insurance policy ${selectedInsurance?.IN_POL_ID} (${selectedInsurance?.IN_DESC})`}
        width={540}
        action={() => handleDeleteInsurance(selectedInsurance)}
        actionLabel={`Delete insurance policy`}
        intent="danger"
        actionAutoFocus
        isActionLoading={isLoadingDeleteInsurance}
      >
        <Text p>
          Are you sure you want to delete insurance policy{' '}
          <strong>
            {selectedInsurance?.IN_POL_ID} ({selectedInsurance?.IN_DESC})
          </strong>
          ? <Text intent="danger">This cannot be undone</Text>.
        </Text>
      </Modal>

      <Modal
        isOpen={editInsuranceModalIsOpen}
        close={closeEditInsuranceModal}
        title={`Edit insurance policy ${selectedInsurance?.IN_POL_ID} (${selectedInsurance?.IN_DESC})`}
        width={576}
        actionLabel="Save changes"
        form={INSURANCE_FORM_ID}
        isActionLoading={isLoadingEditInsurance}
        dismissLabel={canEdit ? 'Discard changes' : 'Close'}
        actionDisabled={!canEdit}
      >
        <InsuranceForm
          isEdit={true}
          initialValues={selectedInsurance}
          handleSubmit={handleEditInsurance}
          canEdit={canEdit}
        />
      </Modal>

      <Card spacing="none">
        <Table.Actions>
          <Flexbox>
            <Search
              onChange={handleSearch}
              placeholder="Search by policy description..."
              name={searchKey}
              width={'640px'}
              value={searchValue}
            />
          </Flexbox>
        </Table.Actions>
        {insurances.list.length === 0 ? (
          <Table.NoResults text="" />
        ) : (
          <>
            <Table
              {...getTableProps()}
              aria-label="Company Table"
              label="Company Table"
              aria-colcount={columns.length}
              aria-rowcount={insurances.list.length}
              height="calc(100vh - 280px)"
            >
              <Table.Head sticky>
                {isRefetchingInsurances ? (
                  <Table.LoadingRows />
                ) : (
                  headerGroups.map((headerGroup, i) => (
                    <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, i) => (
                        <Table.Header
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          column={column}
                          key={i}
                          tabIndex={0}
                        >
                          {column.render('Header')}
                        </Table.Header>
                      ))}
                    </Table.HeadRow>
                  ))
                )}
              </Table.Head>

              <Table.Body {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Table.Row
                      {...row.getRowProps()}
                      onClick={() => {
                        openEditInsuranceModal(row.original)
                      }}
                      tabIndex={0}
                      key={i}
                    >
                      {row.cells.map(cell => (
                        <Table.Cell {...cell.getCellProps()} column={cell.column}>
                          {cell.render('Cell')}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <Pagination
              changePage={changePage}
              totalItems={insurances.count}
              maxItemsPerPage={itemsPerPage}
              currentPageNumber={pageNumber}
              label={`Showing ${insurances.list.length} of ${insurances.count} record${
                insurances.count > 1 ? 's' : ''
              }`}
            />
          </>
        )}
      </Card>
    </>
  )
}
export default InsurancesTable
