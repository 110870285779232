import { maxStringMsg } from '@utils/basic'
import { REQUIRED_FIELD } from '@constants'
import * as Yup from 'yup'
import { dateExistValidation, digitLengthValidation, endDateValidation } from '../../models/validation'
import { ReactSelectOptions } from '@components/common/Select/Select.types'

export const maintenanceSchema = Yup.object({
  MN_CON_ID: Yup.string().max(12, maxStringMsg(12)).required(REQUIRED_FIELD),
  MN_DESC: Yup.string().max(50, maxStringMsg(50)).required(REQUIRED_FIELD),
  MN_NAME: Yup.string().max(50, maxStringMsg(50)).required(REQUIRED_FIELD),
  MN_STREET: Yup.string().max(50, maxStringMsg(50)).nullable(),
  MN_CITY: Yup.string().max(25, maxStringMsg(25)).nullable(),
  MN_CNTY: Yup.string().max(25, maxStringMsg(25)).nullable(),
  MN_STATE: Yup.string().max(2, maxStringMsg(2)).nullable(),
  MN_ZIP: Yup.string().max(10, maxStringMsg(10)).nullable(),
  MN_PHONE: Yup.string().max(14, maxStringMsg(14)).nullable(),
  MN_FAX: Yup.string().max(14, maxStringMsg(14)).nullable(),
  MN_CON_NM: Yup.string().max(25, maxStringMsg(25)).nullable(),
  MN_CON_PN: Yup.string().max(14, maxStringMsg(14)).nullable(),
  MN_CON_FX: Yup.string().max(14, maxStringMsg(14)).nullable(),
  MN_CON_AMT: Yup.number().test(digitLengthValidation(13)).nullable(),
  MN_PMT_CD: Yup.string().max(1, maxStringMsg(1)).nullable(),
  MN_CP_FRM: Yup.string().required(REQUIRED_FIELD).test(dateExistValidation),
  MN_CP_TO: Yup.string()
    .required(REQUIRED_FIELD)
    .test(endDateValidation('MN_CP_FRM'))
    .test(dateExistValidation),
  MN_LB_FRM: Yup.string()
    .when('MN_LB_CD', {
      is: true,
      then: schema => schema.required(REQUIRED_FIELD),
      otherwise: schema => schema.nullable(),
    })
    .test(dateExistValidation),
  MN_LB_TO: Yup.string()
    .when('MN_LB_CD', {
      is: true,
      then: schema => schema.required(REQUIRED_FIELD).test(endDateValidation('MN_LB_FRM')),
      otherwise: schema => schema.nullable(),
    })
    .test(dateExistValidation),
  MN_LB_CD: Yup.boolean(),
  MN_PT_FRM: Yup.string()
    .when('MN_PT_CD', {
      is: true,
      then: schema => schema.required(REQUIRED_FIELD),
      otherwise: schema => schema.nullable(),
    })
    .test(dateExistValidation),
  MN_PT_TO: Yup.string()
    .when('MN_PT_CD', {
      is: true,
      then: schema => schema.required(REQUIRED_FIELD).test(endDateValidation('MN_PT_FRM')),
      otherwise: schema => schema.nullable(),
    })
    .test(dateExistValidation),
  MN_PT_CD: Yup.boolean(),
  MN_OT_FRM: Yup.string()
    .when('MN_OT_CD', {
      is: true,
      then: schema => schema.required(REQUIRED_FIELD),
      otherwise: schema => schema.nullable(),
    })
    .test(dateExistValidation),
  MN_OT_TO: Yup.string()
    .when('MN_OT_CD', {
      is: true,
      then: schema => schema.required(REQUIRED_FIELD).test(endDateValidation('MN_OT_FRM')),
      otherwise: schema => schema.nullable(),
    })
    .test(dateExistValidation),
  MN_OT_CD: Yup.boolean(),
})

export type MaintenanceContract = Yup.InferType<typeof maintenanceSchema>
export type MaintenanceContractKey = keyof MaintenanceContract

export const initialMaintenanceContract: MaintenanceContract = {
  MN_CON_ID: '',
  MN_DESC: '',
  MN_NAME: undefined,
  MN_STREET: undefined,
  MN_CITY: undefined,
  MN_CNTY: undefined,
  MN_STATE: undefined,
  MN_ZIP: undefined,
  MN_PHONE: undefined,
  MN_FAX: undefined,
  MN_CON_NM: undefined,
  MN_CON_PN: undefined,
  MN_CON_FX: undefined,
  MN_CON_AMT: 0,
  MN_PMT_CD: undefined,
  MN_CP_FRM: '',
  MN_CP_TO: '',
  MN_LB_CD: true,
  MN_LB_FRM: '',
  MN_LB_TO: '',
  MN_PT_CD: true,
  MN_PT_FRM: '',
  MN_PT_TO: '',
  MN_OT_CD: true,
  MN_OT_FRM: '',
  MN_OT_TO: '',
}

// Payloads and responses

export interface FetchMaintenancesResponse {
  count: number
  list: MaintenanceContract[]
}

export interface MaintenanceExtraFields {
  paymentTerms: ReactSelectOptions
  cities: ReactSelectOptions
  countries: ReactSelectOptions
  states: ReactSelectOptions
}

// Payloads and responses
export type ListMaintenanceResponse = { ID: string; Name: string }[]
