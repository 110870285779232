import { SchemaType } from '@forms/validation'
import moment from 'moment'

export async function validateAndSubmit(schema: SchemaType, form, setForm, submitAction) {
  schema
    .validate(form.values, { abortEarly: false })
    .then(values => {
      const fields = schema.fields
      let parsedValues = {}

      Object.keys(fields).forEach(fieldName => {
        if (schema.fields[fieldName].type === 'date') {
          parsedValues[fieldName] = moment(values[fieldName]).format('yyyy-MM-DD')
        } else {
          parsedValues[fieldName] = values[fieldName]
        }
      })

      submitAction(parsedValues)
    })
    .catch(formErrors => {
      let errors = {}

      formErrors.inner.forEach(err => {
        errors[err.path] = err.message
      })

      console.log('Form errors', errors)

      setForm(prevState => ({ ...prevState, errors }))
    })
}
