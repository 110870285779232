import { DepreciationClass, ExtraFieldsMap, FieldLabels } from '@api/models'
import DataDisplayList from '@components/DataDisplayList'

interface Props {
  fields: FieldLabels
  deprec: DepreciationClass
  extraFieldsLabels: ExtraFieldsMap
}

const DeprecClassClassificationDisplay = ({ fields, deprec, extraFieldsLabels }: Props) => {
  const items = [
    {
      label: fields.DP_REPS_CD,
      value: extraFieldsLabels.deprecSystem[deprec.DP_REPS_CD],
    },
    {
      label: fields.DP_REP_UCD,
      value: deprec.DP_REP_UCD,
    },
    {
      label: fields.DP_REPA_CD,
      value: extraFieldsLabels.accelerationCode[deprec.DP_REPA_CD],
    },
    {
      label: 'Default Location Disposition Type',
      value: extraFieldsLabels.dispositionType[deprec.DP_LOCD_CD],
    },
    {
      label: 'Default Location Transaction Post Code',
      value: extraFieldsLabels.glPost[deprec.DP_LAPC_ID],
    },
    {
      label: 'Default Location Depreciation Post Code',
      value: extraFieldsLabels.glPost[deprec.DP_LDPC_ID],
    },
    {
      label: 'Default Service Disposition Type',
      value: extraFieldsLabels.dispositionType[deprec.DP_SERD_CD],
    },
    {
      label: 'Default Service Transaction Post Code',
      value: extraFieldsLabels.glPost[deprec.DP_SAPC_ID],
    },
    {
      label: 'Default Service Depreciation Post Code',
      value: extraFieldsLabels.glPost[deprec.DP_SDPC_ID],
    },
  ]

  return <DataDisplayList items={items} columns={2} />
}
export default DeprecClassClassificationDisplay
