import {
  useGetAssetHistoryPeriodsQuery,
  useGetAssetHistoryQuery,
  useGetCompanyQuery,
  useGetSettingsQuery,
  useLockAssetHistoryMutation,
  useManualCreateAssetHistoryMutation,
  useUnlockAssetHistoryMutation,
} from '@api'
import {
  AssetHistoryLockParams,
  AssetHistoryProfile,
  AssetHistoryRecord,
  DEPRECIATION_PROFILES,
  FieldLabels,
  HistoryBase,
  historySchema,
} from '@api/models'
import { Flexbox, Text, TextSkeleton, toast } from '@components/common'
import {
  formatCurrency,
  formatDisplayDate,
  formatISODate,
  isNullOrUndefined,
} from '@utils/basic'
import { ERROR_REQUEST } from '@constants'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { Column } from 'react-table'
import { FormSubmitEvent } from 'types'
import { useForm, UseFormReturn } from 'react-hook-form'
import useFields from './useFields'
import { yupResolver } from '@hookform/resolvers/yup'

interface AssetHistoryHookProps {
  assetCompanyID: number
  assetID: string
}

export interface AssetHistoryTableRow {
  rowLabel: string
  assetValue: number
  capitalizationValue: number
}

interface IAssetHistoryHook {
  isLoading: boolean
  isProfileMaintained: boolean
  hasHistory: boolean
  profileName: string

  historyPeriods: string[]
  period: string
  periodOptions: ReactSelectOptions
  handlePeriodChange: (e: { label: string; value: any }) => void

  assetHistory: AssetHistoryRecord
  isRefetchingAssetHistory: boolean

  handleLockHistory: (e: FormSubmitEvent) => Promise<void>
  isLoadingLockHistory: boolean
  isLockModalOpen: boolean
  setIsLockModalOpen: Dispatch<SetStateAction<boolean>>
  isLocked: boolean

  formMethods: UseFormReturn<HistoryBase>
  formState: FormState
  openForm: (isCreate: boolean, historyData?: HistoryBase) => void
  closeForm: () => void
  handleChargeFormSubmit: (payload: HistoryBase) => Promise<void>
  isLoadingFormSubmit: boolean
  fields: FieldLabels
  isManual: boolean

  navItems: {
    label: string
    onClick
    isActive: boolean
  }[]
  tableColumns: Column<AssetHistoryTableRow>[]
  tableData: AssetHistoryTableRow[]
}

interface FormState {
  isOpen: boolean
  isCreate: boolean
}

export default function useAssetHistory({
  assetCompanyID,
  assetID,
}: AssetHistoryHookProps): IAssetHistoryHook {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])

  const profile = searchParams.get('profile')
    ? (searchParams.get('profile') as AssetHistoryProfile)
    : undefined
  const period = searchParams.get('period') ?? undefined

  // Get company to find what profiles are maintained
  const { data: company, isLoading: isLoadingCompany } = useGetCompanyQuery(assetCompanyID)
  const { fields, isLoading: isLoadingFields } = useFields()

  const profilesMaintained: { [key in AssetHistoryProfile]: boolean } = {
    C: true,
    T: company?.CM_TAX,
    S: company?.CM_SMH,
    O: company?.CM_B4MH,
  }

  const isProfileMaintained: boolean = profilesMaintained[profile]

  // Get periods
  const isProfileInSearchParamsUndefinedOrInvalid =
    isNullOrUndefined(profile) ||
    (profile !== 'C' && profile !== 'O' && profile !== 'S' && profile !== 'T')

  const {
    data: historyPeriods,
    isLoading: isLoadingHistoryPeriods,
    isSuccess: isSuccessGettingHistoryPeriods,
  } = useGetAssetHistoryPeriodsQuery(
    { assetCompanyID, assetID, profile },
    { skip: isProfileInSearchParamsUndefinedOrInvalid || !isProfileMaintained }
  )
  const [formState, setFormState] = useState<FormState>({
    isOpen: false,
    isCreate: false,
  })

  const hasHistory: boolean = isSuccessGettingHistoryPeriods && historyPeriods.length > 0

  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  const periodOptions: ReactSelectOptions = []
  if (isSuccessGettingHistoryPeriods && isProfileMaintained) {
    for (const period of historyPeriods) {
      periodOptions.push({
        label: formatDisplayDate(period, DateFormat),
        value: period,
      })
    }
  }

  function handlePeriodChange(e: { label: string; value: any }): void {
    setSearchParams({
      ...searchParamsObject,
      period: e.value,
    })
  }

  // Get history
  const isDateInSearchParamsUndefinedOrInvalid =
    isNullOrUndefined(period) || historyPeriods?.filter(x => x === period).length === 0

  const {
    data: assetHistory,
    isLoading: isLoadingAssetHistory,
    isFetching: isRefetchingAssetHistory,
  } = useGetAssetHistoryQuery(
    {
      assetCompanyID,
      assetID,
      profile,
      date: period,
    },
    {
      skip:
        isNullOrUndefined(period) ||
        isNullOrUndefined(profile) ||
        isLoadingHistoryPeriods ||
        !isProfileMaintained,
    }
  )

  // Check before mounting
  const isLoading: boolean =
    !profile ||
    isLoadingHistoryPeriods ||
    isLoadingAssetHistory ||
    isLoadingCompany ||
    isLoadingFields

  // Update search params when we have periods
  useEffect(
    function updateSearchParams() {
      if (isLoading) {
        return
      }
      if (isProfileInSearchParamsUndefinedOrInvalid) {
        setSearchParams({
          ...searchParamsObject,
          profile: 'C',
        })
      }

      if (
        isSuccessGettingHistoryPeriods &&
        historyPeriods.length > 0 &&
        isDateInSearchParamsUndefinedOrInvalid
      ) {
        setSearchParams({
          ...searchParamsObject,
          period: historyPeriods[0],
        })
      }
    },
    [isLoading, profile, historyPeriods]
  )

  // Lock/unlock
  const [
    lockHistory,
    { isLoading: isLoadingLockHistoryMutation },
  ] = useLockAssetHistoryMutation()
  const [
    unlockHistory,
    { isLoading: isLoadingUnlockHistoryMutation },
  ] = useUnlockAssetHistoryMutation()

  const isLocked =
    assetHistory?.asset.FN_FR_CD === 'L' || assetHistory?.asset.FN_FR_CD === 'M'
  const isManual = assetHistory?.asset.FN_FR_CD === 'M'

  async function handleLockHistory(e: FormSubmitEvent): Promise<void> {
    e.preventDefault()

    const payload: AssetHistoryLockParams = {
      assetCompanyID,
      assetID,
      FN_AS_DAT: assetHistory.asset.FN_AS_DAT,
      FN_SYS_NR: assetHistory.asset.FN_SYS_NR,
      FN_TYP_CD: assetHistory.asset.FN_TYP_CD as AssetHistoryProfile,
    }

    try {
      if (isLocked) {
        await unlockHistory(payload).unwrap()

        toast.success(
          `Successfully unlocked history up to ${formatISODate(
            assetHistory.asset.FN_AS_DAT
          )}.`
        )
      } else {
        await lockHistory(payload).unwrap()

        toast.success(
          `Successfully locked history up to  ${formatISODate(
            assetHistory.asset.FN_AS_DAT
          )}.`
        )
      }
    } catch (error) {
      toast.error(ERROR_REQUEST)
    } finally {
      setIsLockModalOpen(false)
    }
  }

  const isLoadingLockHistory: boolean =
    isLoadingLockHistoryMutation || isLoadingUnlockHistoryMutation

  const [isLockModalOpen, setIsLockModalOpen] = useState<boolean>(false)

  // Misc
  const profileName = DEPRECIATION_PROFILES.find(x => x.code === profile)?.label

  // React hook form
  const formMethods = useForm<HistoryBase>({
    resolver: yupResolver(historySchema),
    mode: 'all',
    defaultValues: {
      ...historySchema.cast({}),
      assetCompanyID: assetCompanyID,
      assetID: assetID,
      FN_TYP_CD: profile,
    },
  })

  const { reset: resetForm } = formMethods

  function openForm(isCreate: boolean, historyData?: HistoryBase): void {
    if (isCreate) {
      resetForm({
        ...historySchema.cast({}),
      })
    } else {
      resetForm(historyData)
    }

    setFormState({
      isOpen: true,
      isCreate,
    })
  }

  function closeForm() {
    setFormState({
      isOpen: false,
      isCreate: false,
    })
  }

  // Form submit
  const [
    manualCreateAssetHistory,
    { isLoading: isLoadingCreateCharge },
  ] = useManualCreateAssetHistoryMutation()

  const isLoadingFormSubmit: boolean = isLoadingCreateCharge

  async function handleChargeFormSubmit(payloads: HistoryBase): Promise<void> {
    const payload = {
      ...payloads,
      assetCompanyID,
      assetID,
      FN_TYP_CD: profile,
    }
    try {
      if (formState.isCreate) {
        await manualCreateAssetHistory(payload).unwrap()

        toast.success(`Successfully created record.`)
        closeForm()
      }
    } catch (error: any) {
      toast.error(error.data)
    }
  }
  // Nav
  const navItems = [
    {
      label: 'Corporate',
      onClick: () => {
        setSearchParams({
          profile: 'C',
        })
      },
      isActive: profile === 'C',
    },
    {
      label: 'Tax',
      onClick: () => {
        setSearchParams({
          profile: 'T',
        })
      },
      isActive: profile === 'T',
    },
    {
      label: 'State',
      onClick: () => {
        setSearchParams({
          profile: 'S',
        })
      },
      isActive: profile === 'S',
    },
    {
      label: 'Other',
      onClick: () => {
        setSearchParams({
          profile: 'O',
        })
      },
      isActive: profile === 'O',
    },
  ]

  // Morph data for table
  const tableData: AssetHistoryTableRow[] = [
    {
      rowLabel: 'Value for depreciation',
      assetValue: assetHistory?.valueOfAssetDeprec,
      capitalizationValue: assetHistory?.capitalization.FN_CUR_NET,
    },
    {
      rowLabel: 'Current depreciation',
      assetValue: isManual ? undefined : assetHistory?.asset.FN_CUR_DEP,
      capitalizationValue: assetHistory?.capitalization.FN_CUR_DEP,
    },
    {
      rowLabel: 'Net value',
      assetValue: assetHistory?.asset.FN_LTD_NET,
      capitalizationValue: assetHistory?.capitalization.FN_LTD_NET,
    },
    {
      rowLabel: 'Life-to-date depreciation',
      assetValue: assetHistory?.asset.FN_LTD_DEP,
      capitalizationValue: assetHistory?.capitalization.FN_LTD_DEP,
    },
    {
      rowLabel: 'Net value at start of year',
      assetValue: isManual ? undefined : assetHistory?.asset.FN_YTD_NET,
      capitalizationValue: assetHistory?.capitalization.FN_YTD_NET,
    },
    {
      rowLabel: 'Year-to-date depreciation',
      assetValue: isManual ? undefined : assetHistory?.asset.FN_YTD_DEP,
      capitalizationValue: assetHistory?.capitalization.FN_YTD_DEP,
    },
    {
      rowLabel: !isManual ? 'Net asset value' : '',
      assetValue: isManual ? undefined : assetHistory?.netAssetValue,
      capitalizationValue: assetHistory?.capitalization.FN_LTD_NET,
    },
  ]

  const tableColumns = useMemo<Column<AssetHistoryTableRow>[]>(
    () => [
      {
        Header: '',
        accessor: 'rowLabel',
        Cell: props => {
          return <Text bold={props.row.index === tableData.length - 1}>{props.value}</Text>
        },
      },
      {
        Header: 'Asset',
        accessor: 'assetValue',
        maxWidth: 80,
        Cell: props => {
          if (isRefetchingAssetHistory) return <TextSkeleton width={'64px'} />

          return (
            <Flexbox justifyContent="space-between" style={{ width: '100%' }}>
              <Text bold={props.row.index === tableData.length - 1}>
                {(props.row.index === 0 || props.row.index === tableData.length - 1) &&
                props.value !== undefined
                  ? '$'
                  : ''}
              </Text>
              <Text bold={props.row.index === tableData.length - 1}>
                {props.value !== undefined ? formatCurrency(props.value) : ''}
              </Text>
            </Flexbox>
          )
        },
      },

      {
        Header: 'Capitalization',
        accessor: 'capitalizationValue',
        maxWidth: 80,
        Cell: props => {
          if (isRefetchingAssetHistory) return <TextSkeleton width={'64px'} />

          return (
            <Flexbox justifyContent="space-between" style={{ width: '100%' }}>
              <Text bold={props.row.index === tableData.length - 1}>
                {props.row.index === 0 ? '$' : ''}
              </Text>
              <Text bold={props.row.index === tableData.length - 1}>
                {props.value !== undefined ? formatCurrency(props.value) : ''}
              </Text>
            </Flexbox>
          )
        },
      },
    ],
    []
  )

  return {
    profileName,
    isLoading,
    isProfileMaintained,
    hasHistory,

    historyPeriods,
    period,
    periodOptions,
    handlePeriodChange,

    assetHistory,
    isRefetchingAssetHistory,

    handleLockHistory,
    isLoadingLockHistory,
    isLockModalOpen,
    setIsLockModalOpen,
    isLocked,

    navItems,
    tableColumns,
    tableData,

    formMethods,
    formState,
    openForm,
    closeForm,
    handleChargeFormSubmit,
    isLoadingFormSubmit,
    fields,
    isManual,
  }
}
