import { FetchCustomDepreciationParams } from '@api/models'
import { Card, Flexbox, Button, Table, Modal, Spacer, Note } from '@components/common'
import Template from '@components/layout/Template'
import {
  CUSTOM_DEPRECIATION_FORM_ID,
  CUSTOM_DEPRECIATION_RECALC_FORM_ID,
  DELETE_CUSTOM_DEPRECIATION_FORM_ID,
} from '@constants'
import { FormProvider } from 'react-hook-form'
import CustomDepreciationDeleteForm from './CustomDepreciationDeleteForm'
import CustomDepreciationForm from './CustomDepreciationForm'
import CustomDepreciationLoading from './CustomDepreciationLoading'
import CustomDepreciationRecalcForm from './CustomDepreciationRecalcForm'
import CustomDepreciationTable from './CustomDepreciationTable'
import NoCustomDepreciation from './NoCustomDepreciation'
import useCustomDepreciation from './useCustomDepreciation'
import usePermission from '@components/Auth/Permissions/usePermission'

interface CustomDepreciationWrapperProps {
  params: FetchCustomDepreciationParams
  readonly: boolean
}

const CustomDepreciationWrapper = ({
  params,
  readonly,
}: CustomDepreciationWrapperProps) => {
  const {
    customDepreciationList,
    isLoadingData,
    hasCustomDepreciation,
    depreciationMethod,
    openAddForm,
    openEditForm,
    openDeleteForm,
    closeForm,
    isDeleteFormOpen,
    isCustomDepFormOpen,
    isCustomDepFormValid,
    isSubmittingCustomDepForm,
    isDeleting,
    isDeleteFormValid,
    fieldLabels,
    type,
    customDepFormMethods,
    deleteMethods,
    selectedCustomDepreciation,
    handleRecalcCheck,
    recalcDTO,
    needsRecalc,
    handleCustomDepSubmit,
  } = useCustomDepreciation(params)

  const { canEditSettings: canEdit } = usePermission()

  if (isLoadingData) return <CustomDepreciationLoading />

  if (depreciationMethod === 'N')
    return (
      <Note
        intent="danger"
        content={
          <>
            No custom depreciation can exist on a profile with its depreciation method set
            to <b>No Depreciation</b>.
          </>
        }
      />
    )

  let formID = ''
  if (readonly) formID = undefined
  else if (needsRecalc) formID = CUSTOM_DEPRECIATION_RECALC_FORM_ID
  else formID = CUSTOM_DEPRECIATION_FORM_ID

  let customDepFormTitleStart = ''
  if (readonly) customDepFormTitleStart = 'Review'
  else if (type === 'add') customDepFormTitleStart = 'Add'
  else customDepFormTitleStart = 'Edit'

  return (
    <>
      {readonly && (
        <>
          <Note
            intent="default"
            content={
              <>
                To modify custom depreciation, you can go back to the profile and{' '}
                <b>create asset-specific depreciation</b>. Alternatively, you may modify
                custom depreciation records on the <b>global profile</b>, however this is
                not recommended as it will affect all assets using it.
              </>
            }
          />
          <Spacer y="default" />
        </>
      )}
      {!hasCustomDepreciation && !readonly && (
        <NoCustomDepreciation openAddForm={openAddForm} canEdit={canEdit} />
      )}
      <Modal
        isOpen={isCustomDepFormOpen}
        title={`${customDepFormTitleStart} custom depreciation record`}
        form={formID}
        close={closeForm}
        actionLabel={`${type === 'add' ? 'Add custom depreciation' : 'Save changes'}`}
        isActionLoading={isSubmittingCustomDepForm}
        width={640}
        actionDisabled={!isCustomDepFormValid || !canEdit}
      >
        <>
          <FormProvider {...customDepFormMethods}>
            <CustomDepreciationForm
              type={type}
              fieldLabels={fieldLabels}
              onSubmit={handleRecalcCheck}
              initialValues={selectedCustomDepreciation}
              depreciationMethod={depreciationMethod}
              disabled={needsRecalc || !canEdit}
              readonly={readonly}
            />
          </FormProvider>
          {needsRecalc && type !== 'delete' && (
            <>
              <Spacer y="large" />
              <CustomDepreciationRecalcForm
                deprecsp={selectedCustomDepreciation}
                deprecspRecalcDTO={recalcDTO}
                submitAction={handleCustomDepSubmit}
                isDelete={false}
              />
            </>
          )}
        </>
      </Modal>
      {hasCustomDepreciation && (
        <>
          <Modal
            isOpen={isDeleteFormOpen}
            title="Delete custom depreciation record"
            form={
              needsRecalc
                ? CUSTOM_DEPRECIATION_RECALC_FORM_ID
                : DELETE_CUSTOM_DEPRECIATION_FORM_ID
            }
            close={closeForm}
            actionLabel="Delete custom depreciation record"
            isActionLoading={isDeleting}
            width={640}
            actionDisabled={!isDeleteFormValid}
            intent="danger"
          >
            <FormProvider {...deleteMethods}>
              <CustomDepreciationDeleteForm
                customDepreciation={selectedCustomDepreciation}
                deleteAction={handleRecalcCheck}
              />
              {needsRecalc && type === 'delete' && (
                <>
                  <Spacer y="large" />
                  <CustomDepreciationRecalcForm
                    deprecsp={selectedCustomDepreciation}
                    deprecspRecalcDTO={recalcDTO}
                    submitAction={handleCustomDepSubmit}
                    isDelete={true}
                  />
                </>
              )}
            </FormProvider>
          </Modal>
          <Template maxWidth="compact">
            <Card spacing="none" overflow="hidden">
              <Table.Actions>
                <Flexbox justifyContent="space-between" alignItems="center">
                  <div>
                    {customDepreciationList.length} total custom depreciation record
                    {customDepreciationList.length > 1 ? 's' : ''}
                  </div>
                  {canEdit && !readonly && (
                    <Button appearance="primary" onClick={openAddForm}>
                      Add custom depreciation record
                    </Button>
                  )}
                </Flexbox>
              </Table.Actions>
              <CustomDepreciationTable
                customDepreciationList={customDepreciationList}
                openEditForm={openEditForm}
                openDeleteForm={openDeleteForm}
                fieldLabels={fieldLabels}
                depreciationMethod={depreciationMethod}
                readonly={readonly}
                canEdit={canEdit}
              />
            </Card>
          </Template>
        </>
      )}
    </>
  )
}

export default CustomDepreciationWrapper
