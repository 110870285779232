import * as Yup from 'yup'
import { maxStringMsg } from '@utils/basic'
import { REQUIRED_FIELD } from '@constants'

export const SEGMENT_FORM_ID = 'segment_form'
export const segmentSchema = Yup.object({
  KY_VALUE: Yup.string().max(15, maxStringMsg(15)).required(REQUIRED_FIELD),
  KY_DESC: Yup.string().max(50, maxStringMsg(50)).required(REQUIRED_FIELD),
  KY_NR: Yup.number().optional(),
})
export type SegmentBase = Yup.InferType<typeof segmentSchema>

export interface Segment extends SegmentBase {
  AppCompanyID: number
  CompanyStructure
  KY_NR: number
}
export type SegmentKey = keyof Segment

// Segment type form
export const COMPANY_STRUCTURE_FORM_ID = 'company_structure_form'
export const companyStructureSchema = Yup.object({
  value: Yup.number().required(REQUIRED_FIELD),
  KY_DESC: Yup.string().required(REQUIRED_FIELD),
})
export type CompanyStructureBase = Yup.InferType<typeof companyStructureSchema>

export interface CompanyStructure extends CompanyStructureBase {
  AppCompanyID: number
  Key: number
  Name: string
}

export type CompanyStructureKey = keyof CompanyStructure

// Request payloads and responses
export interface GetSegmentByKeyPayload {
  KY_NR: number
}
export type GetSegmentByKeyRespone = Segment[]

export interface FetchSegmentsPayload {
  key: number
  filter: Object
  pageNumber: number
  itemsPerPage: number
}
export interface FetchSegmentsResponse {
  count: number
  list: Segment[]
}

export interface CreateSegmentPayload extends SegmentBase {
  kY_NR: number
}

export interface SegmentType {
  label: string
  value: number
}

export interface GetSegmentsPayload {
  KY_NR: number
  desc: string
}
