import { emitter } from '.'
import { Events, Toast } from '../Toasts.types'
import { TAction } from '../useToast'

interface IToastDispatcher {
  dispatch: (value: TAction) => void
}

export const toastDispatcher = ({ dispatch }: IToastDispatcher) => {
  emitter.on(Events.SHOW, (toast: Toast) => {
    dispatch({ type: 'ADD', toast })

    setTimeout(() => {
      dispatch({ type: 'REMOVE', id: toast.id })
    }, 5200)
  })

  emitter.on(Events.HIDE, (id: string) => dispatch({ type: 'REMOVE', id }))

  emitter.on(Events.HIDE_ALL, () => dispatch({ type: 'REMOVE_ALL' }))
}
