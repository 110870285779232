import { Card, TableSkeleton } from '@components/common'

const AssetHistoryLoading = () => {
  return (
    <Card spacing="none" overflow="hidden">
      <TableSkeleton rows={6} />
    </Card>
  )
}

export default AssetHistoryLoading
