import DefaultFilter from './DefaultFilter'
import DepreciationsFilter from './DepreciationsFilter'
import { AssetsTableFilterField } from './Filters.types'
import InsurancesFilter from './InsurancesFilter'
import PicklistsFilter from './PicklistsFilter'
import RadioFilter from './RadioFilter'
import SegmentsFilter from './SegmentsFilter'
import VendorsFilter from './VendorsFilter'

interface Props {
  filter: AssetsTableFilterField
}

const AssetsTableFilter = ({ filter }: Props) => {
  if (filter.type === 'options') {
    switch (filter.optionsType) {
      case 'Segment':
        return <SegmentsFilter filter={filter} />

      case 'DepreciationTypeA':
        return <DepreciationsFilter filter={filter} profile="A" />

      case 'DepreciationTypeX':
        return <DepreciationsFilter filter={filter} profile="X" />

      case 'Insurance':
        return <InsurancesFilter filter={filter} />
      case 'Vendor':
        return <VendorsFilter filter={filter} />
      case 'Picklist':
        return <PicklistsFilter filter={filter} />
      case 'Radio':
        return <RadioFilter filter={filter} />
    }
  } else return <DefaultFilter filter={filter} />
}
export default AssetsTableFilter
