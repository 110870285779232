import View from '@components/layout/View'
import { useLocation } from 'react-router-dom'
import { Flexbox, Grid, Heading, Spacer } from '@components/common'
import ReportsNavigation from './ReportsNavigation'
import { reportRoutes } from '@routes'

import ReportsMenuPanel from './ReportsMenuPanel'
import { useState } from 'react'

const ReportTemplate = () => {
  const [showMenu, setShowMenu] = useState(true)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)

  const location = useLocation()
  const paths = location.pathname.split('/')
  const currentPath = paths[paths.length - 1]
  const activeReport =
    reportRoutes.find(route => route.path === currentPath) || reportRoutes[0]

  return (
    <View title={`Reports | ${activeReport.title}`} showHeader={false}>
      <Flexbox>
        <Heading
          type="h1"
          style={{ position: 'fixed', top: 'var(--scale1400) + var(--scale800)' }}
        >
          Reports
        </Heading>
        <span
          style={{
            visibility: 'hidden',
            top: 'var(--scale1400) + var(--scale800)',
            height: '33.6px',
          }}
        ></span>
      </Flexbox>
      <Spacer y="default" />
      <Grid colGap="large">
        <Grid.Item s={6} m={4} l={3}>
          <ReportsNavigation
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            selectedCategoryIndex={selectedCategoryIndex}
            setSelectedCategoryIndex={setSelectedCategoryIndex}
          />
        </Grid.Item>
        <Grid.Item s={6} m={8} l={9}>
          <ReportsMenuPanel
            activeReportTitle={activeReport.title}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            selectedCategoryIndex={selectedCategoryIndex}
          />
        </Grid.Item>
      </Grid>
    </View>
  )
}

export default ReportTemplate
