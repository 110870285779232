import { useEditSettingsMutation, useGetSegmentTypesQuery, useGetSettingsQuery } from '@api'
import { AccountFieldParm, SegmentType } from '@api/models'
import { isNullOrUndefined } from '@utils/basic'
import { toast } from '@components/common'

export interface AccountSettingHook {
  selectSegmentTypeOptions: SegmentType[]
  isLoadingAccountList: boolean
  onSubmit: (finalResult: AccountFieldParm) => Promise<void>
  isSavingSettings: boolean
  isGettingSettings: boolean
  Accounts: string
}

export default function useAccounts(): AccountSettingHook {
  // First we need account types
  const {
    data: selectSegmentTypeOptions,
    isLoading: isLoadingAccountList,
  } = useGetSegmentTypesQuery()

  const [editSettings, { isLoading: isSavingSettings }] = useEditSettingsMutation()
  const { data: Accounts, isLoading: isGettingSettings } = useGetSettingsQuery('Account')

  async function onSubmit(formValues: AccountFieldParm): Promise<void> {
    if (
      isNullOrUndefined(formValues.OPTION1) &&
      isNullOrUndefined(formValues.OPTION2) &&
      isNullOrUndefined(formValues.OPTION3)
    ) {
      toast.error('You must select at least one segment as your account segment.')
    } else {
      const finalAccountSettingList = ''.concat(
        !isNullOrUndefined(formValues.OPTION1) ? formValues.OPTION1.toString() : '',
        !isNullOrUndefined(formValues.OPTION2) ? formValues.OPTION2.toString() : '',
        !isNullOrUndefined(formValues.OPTION3) ? formValues.OPTION3.toString() : ''
      )

      try {
        await editSettings({ SS_KEY: 'Account', SS_VALUE: finalAccountSettingList })
        toast.success('Account selection saved successfully.')
      } catch (error) {
        toast.error('Error saving account selection.')
      }
    }
  }

  return {
    selectSegmentTypeOptions,
    isLoadingAccountList,
    onSubmit,
    isSavingSettings,
    Accounts,
    isGettingSettings,
  }
}
