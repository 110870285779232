import { EmptyState, Fieldset, Flexbox, Grid, Input, Modal } from '@components/common'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import MemorizedReportsTable from './MemorizedReportsTable'
import { useMemorizeReport } from './useMemorizeReport'
import {
  IMemorizeReportForm,
  IMemorizeReportsProps,
  memorizeReportSchema,
  MemorizeTemplateFields,
} from './IMemorizeReports'
import { yupResolver } from '@hookform/resolvers/yup'

const MemorizeReports = ({
  isMemorizeTemplateModalOpen,
  closeMemorizeTemplateModal,
  handleMemorizeTemplateSubmit,
  handleEditTemplateSubmit,
  isEditTemplate,
  selectedTemplateDetails,
}: IMemorizeReportsProps) => {
  const { allReportTemplateData } = useMemorizeReport({})

  const hasExistingTemplate = allReportTemplateData?.length > 0

  const {
    register: registerMemorizeReport,
    handleSubmit: handleSubmitMemorizeReport,
    reset: resetMemorizeReport,
    setValue: setValueMemorizeReport,
    formState: {
      errors: errorsMemorizeReport,
      isSubmitting: isSubmittingMemorizeReport,
      isSubmitSuccessful: isSubmitSuccessfulMemorizeReport,
    },
  } = useForm<MemorizeTemplateFields>({
    mode: 'onSubmit',
    resolver: yupResolver(memorizeReportSchema()),
  })

  function getInputPropsMemorizeReport(name: keyof MemorizeTemplateFields) {
    return {
      ...registerMemorizeReport(name),
      error: errorsMemorizeReport[name] !== undefined,
      errorMsg: errorsMemorizeReport[name]?.message,
      disabled: isSubmittingMemorizeReport,
    }
  }

  useEffect(() => {
    if (isSubmitSuccessfulMemorizeReport) {
      resetMemorizeReport()
    }
    if (!isMemorizeTemplateModalOpen) {
      resetMemorizeReport()
    }
  }, [isSubmitSuccessfulMemorizeReport, isMemorizeTemplateModalOpen])

  return (
    <Modal
      isOpen={isMemorizeTemplateModalOpen}
      close={closeMemorizeTemplateModal}
      onSubmit={
        isEditTemplate
          ? handleSubmitMemorizeReport(handleEditTemplateSubmit)
          : handleSubmitMemorizeReport(handleMemorizeTemplateSubmit)
      }
      title={`Memorize Report`}
      actionLabel={'Save'}
      width={800}
      customStyles={{
        position: 'fixed',
        top: '10%',
      }}
    >
      <Grid>
        <Grid.Item>
          <Fieldset legend="Current List">
            {hasExistingTemplate ? (
              <MemorizedReportsTable
                data={allReportTemplateData}
                isMemorizeTemplateModal={true}
                isEditTemplate={isEditTemplate}
                setValueMemorizeReport={setValueMemorizeReport}
                selectedTemplateDetails={selectedTemplateDetails}
              />
            ) : (
              <EmptyState
                heading="No saved templates found."
                text="Add a Name and a Description to this template and click 'Save'."
                height={50}
              />
            )}
          </Fieldset>
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="New Template Details">
            <Flexbox>
              <Grid>
                <Grid.Item m={4}>
                  <Input
                    {...getInputPropsMemorizeReport('name')}
                    label="Name"
                    {...(isEditTemplate && { disabled: true })}
                  />
                </Grid.Item>

                <Grid.Item m={8}>
                  <Input
                    {...getInputPropsMemorizeReport('description')}
                    label="Description"
                  />
                </Grid.Item>
              </Grid>
            </Flexbox>
          </Fieldset>
        </Grid.Item>
      </Grid>
    </Modal>
  )
}

export default MemorizeReports
