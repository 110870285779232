import {
  ButtonSkeleton,
  Card,
  Flexbox,
  Heading,
  InputSkeleton,
  Spacer,
  TableSkeleton,
  TextSkeleton,
} from '@components/common'

const UserTableLoading: React.FC = () => {
  return (
    <>
      <Flexbox justifyContent="space-between" alignItems="center">
        <Flexbox flexDirection="column">
          <Heading type="h2" primary>
            User Account Management
          </Heading>

          <Spacer y="mini" />

          <TextSkeleton width="500px" height="var(--scale600)" />
        </Flexbox>

        <Spacer y="default" />

        <InputSkeleton width="350px" />

        <ButtonSkeleton placeholder="Invite User" />
      </Flexbox>

      <Spacer y="default" />

      <Card spacing="none" overflow="hidden">
        <TableSkeleton rows={2} />
      </Card>

      <Card spacing="none" overflow="hidden">
        <Flexbox justifyContent="space-between" style={{ padding: 'var(--scale600)' }}>
          <TextSkeleton width="110px" height="var(--scale600)" />
          <TextSkeleton width="var(--scale1600)" height="var(--scale600)" />
        </Flexbox>
      </Card>
    </>
  )
}

export default UserTableLoading
