import React, { useState } from 'react'

import { motion } from 'framer-motion'
import { useMeasurePosition } from './useMeasurePosition'
import PassClassDown from '@utils/PassClassDown'
import { Icon } from '@common'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

interface ItemProps {
  index: number
  name: string
  updatePosition
  updateOrder
  setColumnOrder
  order
  height?
}

const Item: React.FC<ItemProps> = ({
  index,
  name,
  updatePosition,
  updateOrder,
  setColumnOrder,
  height = 36,
  order,
}) => {
  const [isDragging, setDragging] = useState(false)
  const ref = useMeasurePosition(pos => updatePosition(index, pos))

  const [css] = useStyletron()
  let itemStyles = css({
    ...styles.item,
  })

  return (
    <motion.li
      style={{
        height,
        zIndex: isDragging ? 3 : 1,
        position: 'relative',
      }}
      whileTap={{ zIndex: 3 }}
    >
      <motion.div
        style={{
          height,
        }}
        className={itemStyles}
        ref={ref}
        layout
        initial={false}
        whileTap={{
          scale: 1.01,
          boxShadow: '2px 0 6px 2px rgba(4, 107, 182, 0.25)',
          backgroundColor: 'var(--base-color)',
          borderColor: 'var(--primary-color)',
          zIndex: 3,
        }}
        whileHover={{
          backgroundColor: 'var(--primary-color50)',
        }}
        drag="y"
        onDragStart={() => setDragging(true)}
        onDragEnd={() => {
          setDragging(false)
          setTimeout(() => setColumnOrder(order), 500)
        }}
        onViewportBoxUpdate={(_viewportBox, delta) => {
          isDragging && updateOrder(index, delta.y.translate)
        }}
      >
        <PassClassDown className={css(styles.icon)}>
          <Icon name="Menu" />
        </PassClassDown>

        {name}
      </motion.div>
    </motion.li>
  )
}
export default Item
