import { toast, useModal } from '@components/common'

import { useNavigate } from 'react-router-dom'
import { reportsList } from '../../../../components/Report/ReportsNavigation/reportsList'
import { useDispatch } from 'react-redux'
import { clearAssetRegisterFiltersButKeepSort } from '@store/assetRegisterFiltersSlice'

interface IUseRetrieveReports {
  setShowMenu?: Function
  setSelectedCategoryIndex?: Function
}

export const useRetrieveReports = ({
  setShowMenu,
  setSelectedCategoryIndex,
}: IUseRetrieveReports) => {
  const {
    modalIsOpen: isRetrieveTemplateModalOpen,
    closeModal: closeRetrieveTemplateModal,
    triggerModal: triggerRetrieveTemplateModal,
  } = useModal()

  const clearAssetRegisterFilters = useDispatch()
  const navigate = useNavigate()

  const handleRetrieveTemplateSubmit = (templateDetails): void => {
    if (templateDetails.path == undefined) {
      toast.error('Please select a template to retrieve')
      return
    }

    clearAssetRegisterFilters(clearAssetRegisterFiltersButKeepSort())

    if (templateDetails.isEdit == undefined) {
      templateDetails.isEdit = false
    }

    navigate(`/reports/${templateDetails.path}`, {
      state: { templateDetails },
    })

    reportsList.forEach((category, categoryIndex) => {
      category.reports.forEach((report, reportIndex) => {
        if (report.path === templateDetails.path) {
          setSelectedCategoryIndex(categoryIndex)
        }
      })
    })

    setShowMenu(false)
    closeRetrieveTemplateModal()
  }

  return {
    isRetrieveTemplateModalOpen,
    handleRetrieveTemplateSubmit,
    closeRetrieveTemplateModal,
    triggerRetrieveTemplateModal,
  }
}
