import CompanyPeriods from '@components/company/CompanyPeriods'
import View from '@components/View'
import { useParams } from 'react-router-dom'

const CompanyPeriodsView = () => {
  const params = useParams()

  return (
    <View title="Periods">
      <CompanyPeriods assetCompanyID={parseInt(params.companyID)} />
    </View>
  )
}

export default CompanyPeriodsView
