import { StandardFetchRequestPayload } from '.'
import * as Yup from 'yup'
import { REQUIRED_FIELD } from '@constants'

export interface RoleListItem {
  applicationName: string
  roleName: string
}

export interface FetchRolesPayload extends StandardFetchRequestPayload {
  userID: string
}

export interface FetchRolesResponse {
  count: number
  list: RoleListItem[]
}

export interface SubscribedApplicationRoles {
  subscribedApplicationNames: string[]
  roleNamesBySubscribedApplicationNames: { [key: string]: string[] }
}

export interface RolePayload {
  userID: string
  applicationName: string
  roleName: string
}

export interface ChangeRolePayload {
  changeRoleTo: string
  email: string
  userID: string
}

export const appRoleSchema = Yup.object({
  applicationName: Yup.string().required(REQUIRED_FIELD).default(''),
  roleName: Yup.string().required(REQUIRED_FIELD).default(''),
})
