import React from 'react'
import { useStyletron } from 'styletron-react'
import * as styles from '../Styles'

export interface TableHeadRowProps extends React.HTMLProps<HTMLTableRowElement> {}

const HeadRow: React.FC<TableHeadRowProps> = ({ ...props }) => {
  const [css] = useStyletron()
  return (
    <tr {...props} className={`${css(styles.headerRow)}`}>
      {props.children}
    </tr>
  )
}

export default HeadRow
