import * as Yup from 'yup'
import { REQUIRED_FIELD } from '@constants'

import { maxStringMsg } from '@utils/basic'

const minCordsMsg = 'Minimum value is 180'
const maxCordsMsg = 'Maximum value is 180'

export const assetLocationSchema = Yup.object({
  AssetCompanyID: Yup.number(),
  AssetID: Yup.string(),
  ChargeSystemNumber: Yup.number().typeError(REQUIRED_FIELD).default(0),
  LocationName: Yup.string().required(REQUIRED_FIELD).default('').max(50, maxStringMsg(50)),
  LUserName: Yup.string().nullable().default(''),
  Latitude: Yup.number()
    .min(-180, minCordsMsg)
    .max(180, maxCordsMsg)
    .default(0)
    .typeError(REQUIRED_FIELD),
  Longitude: Yup.number()
    .min(-180, minCordsMsg)
    .max(180, maxCordsMsg)
    .default(0)
    .typeError(REQUIRED_FIELD),
  Address: Yup.string().required(REQUIRED_FIELD).default('').max(50, maxStringMsg(50)),
  City: Yup.string().nullable().default('').max(50, maxStringMsg(50)),
  State: Yup.string().nullable().default('').max(50, maxStringMsg(50)),
  Country: Yup.string().nullable().default('').max(50, maxStringMsg(50)),
  PostalCode: Yup.string().nullable().default('').max(50, maxStringMsg(50)),
})

export type AssetLocation = Yup.InferType<typeof assetLocationSchema>
export type AssetLocationKey = keyof AssetLocation

export interface FetchAssetLocationParams {
  assetCompanyID: number
  assetID: string
}

export interface FetchAssetLocationDetailsParams {
  systemNumber: number
}

export interface FetchCoordinatesPayload {
  Address: string
  City: string
  State: string
  Country: string
  PostalCode: string
}

export interface FetchCoordinatesResponse {
  lat: number
  lon: number
}

export interface LocationDetails {
  AssetCompanyID: number
  AssetID: string
  ChargeSystemNumber: number
  LocationName: string
  LUserName: string
  Latitude: number
  Longitude: number
  Address: string
  City: string
  State: string
  Country: string
  PostalCode: string
}
