import * as Yup from 'yup'
import { maxStringMsg } from '@utils/basic'
import { REQUIRED_FIELD } from '@constants'

export const glPostSchema = Yup.object({
  GL_POST_ID: Yup.string().max(12, maxStringMsg(12)).required(REQUIRED_FIELD),
  GL_DESC: Yup.string().max(50, maxStringMsg(50)).required(REQUIRED_FIELD),

  GLICOCD: Yup.string().default('T'),
  GLCreditSource: Yup.string().nullable(),
  GLCKEY0: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLCKEY1: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLCKEY2: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLCKEY3: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLCKEY4: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLCKEY5: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLCKEY6: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLCKEY7: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLCKEY8: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLCKEY9: Yup.string().max(65, maxStringMsg(65)).nullable(),

  GLIDOCD: Yup.string().default('T'),
  GLDebitSource: Yup.string().nullable(),
  GLDKEY0: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLDKEY1: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLDKEY2: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLDKEY3: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLDKEY4: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLDKEY5: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLDKEY6: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLDKEY7: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLDKEY8: Yup.string().max(65, maxStringMsg(65)).nullable(),
  GLDKEY9: Yup.string().max(65, maxStringMsg(65)).nullable(),
})
export type GLPost = Yup.InferType<typeof glPostSchema>
export type GLPostKey = keyof GLPost

export interface FetchGLPostsResponse {
  count: number
  list: GLPost[]
}
export type ListGLPostsResponse = {
  ID: string
  Name: string
}[]

export interface GetGLPostKeysPayload {
  id: string
}
export interface IGLPostKey {
  label: string
  value: string
}
