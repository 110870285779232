import { useFields, useTableParams } from '@hooks'
import { useFetchAssetCompaniesQuery } from '@api'
import { useEffect, useMemo, useState } from 'react'
import { Column, SortingRule, useFlexLayout, useSortBy, useTable } from 'react-table'
import { Card, Flexbox, Search, Table } from '@components/common'
import { useNavigate } from 'react-router'
import { COMPANY_VIEWS } from '@constants'
import { FetchAssetCompany } from '@api/models'
import Pagination from '@components/Pagination'
import CompaniesTableLoading from './CompaniesTableLoading'

const CompaniesTable = () => {
  const [filterState, setFilterState] = useState({})
  const searchKey = 'CM_DESC'
  const initialItemsPerPage = 20
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })
  // Fetch companies
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isFetching: isReFetchingCompanies,
    isError: isErrorFetchingCompanies,
  } = useFetchAssetCompaniesQuery(fetchRequestPayload)

  const { fields, isLoading: isLoadingFields, isError: isErrorGettingFields } = useFields()

  const columns = useMemo<Column<FetchAssetCompany>[]>(
    () => [
      {
        Header: fields.CM_COM_ID,
        accessor: 'AssetCompanyID',
      },
      {
        Header: fields.CM_DESC,
        accessor: 'CM_DESC',
      },
      {
        Header: fields.CM_DESCS,
        accessor: 'CM_DESCS',
      },
      {
        Header: fields.CM_YE_MM,
        accessor: 'CM_YE_MM',
      },
      {
        Header: fields.CM_YE_DD,
        accessor: 'CM_YE_DD',
      },
      {
        Header: fields.CM_NR_PER,
        accessor: 'CM_NR_PER',
      },
      {
        Header: fields.CM_YTD_BCD,
        accessor: 'CM_YTD_BCD',
      },
    ],
    [isLoadingFields]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: companies ? companies.list : [],
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        sortBy: sortByInitialState() as SortingRule<FetchAssetCompany>[],
      },
    },
    useSortBy,
    useFlexLayout
  )

  // Update sort param
  useEffect(() => {
    updateSortSearchParam(sortBy, setSortBy)
  }, [sortBy])

  const navigate = useNavigate()

  const isTableLoading = isLoadingFields || isLoadingCompanies

  if (isTableLoading) {
    return <CompaniesTableLoading />
  }

  if (isErrorFetchingCompanies || isErrorGettingFields) {
    return <Table.NoResults heading="There was an error." />
  }

  return (
    <Card spacing="none">
      <Table.Actions>
        <Flexbox justifyContent="space-between">
          <div>
            <Search
              onChange={handleSearch}
              placeholder="Search by company name..."
              name={searchKey}
              width={'640px'}
              value={searchValue}
            />
          </div>
        </Flexbox>
      </Table.Actions>
      {companies.list.length === 0 ? (
        <Table.NoResults text="" />
      ) : (
        <>
          <Table
            {...getTableProps()}
            aria-label="Company Table"
            label="Company Table"
            aria-colcount={columns.length}
            aria-rowcount={companies.list.length}
            height="calc(100vh - 280px)"
            minHeight={companies.list.length > 10 && '300px'}
          >
            <Table.Head sticky>
              {isReFetchingCompanies ? (
                <Table.LoadingRows />
              ) : (
                headerGroups.map((headerGroup, i) => (
                  <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                    {headerGroup.headers.map((column, i) => (
                      <Table.Header
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        column={column}
                        key={i}
                        tabIndex={0}
                      >
                        {column.render('Header')}
                      </Table.Header>
                    ))}
                  </Table.HeadRow>
                ))
              )}
            </Table.Head>

            <Table.Body {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <Table.Row
                    {...row.getRowProps()}
                    onClick={() =>
                      navigate(
                        `/companies/${row.original.AssetCompanyID}/${COMPANY_VIEWS.DETAILS}`
                      )
                    }
                    tabIndex={0}
                    key={i}
                  >
                    {row.cells.map(cell => (
                      <Table.Cell {...cell.getCellProps()} column={cell.column}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <Pagination
            changePage={changePage}
            totalItems={companies.count}
            maxItemsPerPage={itemsPerPage}
            currentPageNumber={pageNumber}
            label={`Showing ${companies.list.length} of ${companies.count} record${
              companies.count > 1 ? 's' : ''
            }`}
          />
        </>
      )}
    </Card>
  )
}
export default CompaniesTable
