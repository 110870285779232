import { Select } from '@components/common'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { useFormContext, Controller } from 'react-hook-form'

interface IFileFormatParams {
  fileFormat: string,
}

interface IpropsForComponent {
  pdfOnly?:boolean,
  allowExcel?: boolean,
  allowCSV?: boolean,
}


const FileFormatSelect = ({ pdfOnly, allowExcel, allowCSV }: IpropsForComponent) => {
  const {
    formState: { isSubmitting },
    control,
  } = useFormContext<IFileFormatParams>()

  function getSelectProps(field: any, options: ReactSelectOptions) {
    return {
      options: options,
      onChange: val => field.onChange(val?.value),
      value: options.find(c => field.value === c.value),
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  const fileFormats: ReactSelectOptions = [
    { label: 'PDF', value: 'pdf'},
    { label: 'CSV', value: 'csv'},
    { label: 'Excel', value: 'excel'},
  ]

  let filteredFileFormats = fileFormats;
  if (pdfOnly) {
    filteredFileFormats = filteredFileFormats.filter(x => x.value === 'pdf')
  } else {
    if (!allowExcel) {
      filteredFileFormats = filteredFileFormats.filter(x => x.value !== 'excel')
    }
    if (!allowCSV) {
      filteredFileFormats = filteredFileFormats.filter(x => x.value !== 'csv')
    }
  }

  return (
    <Controller
      control={control}
      name="fileFormat"
      render={({ field }) => <Select label="" {...getSelectProps(field, filteredFileFormats)} />}
    />
  )
}

export default FileFormatSelect
