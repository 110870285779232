import { AssetRegisterReportParams } from '@api/models'
import AssetsRegisterTable from './AssetsRegisterTable'
import { Controller, useFormContext } from 'react-hook-form'

type Field = {
  value: string[]
  onChange: (value: string[]) => void
}

const AssetSelector = () => {
  const { control } = useFormContext<AssetRegisterReportParams>()

  function addToSelectedAssets(assetID: string, field: Field) {
    if (field.value.includes(assetID)) {
      return
    }

    field.onChange([...field.value, assetID].sort())
  }

  return (
    <Controller
      name="assets"
      control={control}
      render={({ field }) => (
        <AssetsRegisterTable
          isRowSelected={({ original }) => field.value.includes(original.AssetID)}
          rowAction={({ original }) => addToSelectedAssets(original.AssetID, field)}
        />
      )}
    />
  )
}

export default AssetSelector
