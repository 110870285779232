import React from 'react'
import { ItemProps } from './PopMenu.types'

import { useStyletron } from 'styletron-react'
import { popMenu__itemStyles, popMenu__item__iconStyles } from './Styles'
import PassClassDown from '@utils/PassClassDown'

export const Item: React.FC<ItemProps> = ({
  size = 'm',
  appearance = 'default',
  icon,
  ...props
}) => {
  const [css] = useStyletron()
  return (
    <div onClick={props.onClick} {...props} className={css(popMenu__itemStyles)}>
      {icon && (
        <PassClassDown className={css(popMenu__item__iconStyles)}>
          {icon}
        </PassClassDown>
      )}
      {props.children}
    </div>
  )
}
export default Item
