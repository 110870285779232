import DashBoard from '@components/DashBoard'
import View from '@components/layout/View'

const HomeView = () => {
  return (
    <View title="Home" showHeader={false}>
      <DashBoard />
    </View>
  )
}

export default HomeView
