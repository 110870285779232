import { toast } from "@components/common"
import { AssetsApi } from "@api"
import { ERROR_REQUEST, REQUIRED_FIELD } from "@constants"
import * as Yup from 'yup'
import { maxStringMsg } from "@utils/basic"

export interface EditAssetID {
    AssetCompanyID: number,
    OldAssetID: string,
    NewAssetID: string
}

export const assetIDSchema = (
    companyID: string,
  ) =>
    Yup.object({
      OldAssetID: Yup.string()
        .typeError(REQUIRED_FIELD)
        .max(12, maxStringMsg(12))
        .test({
          test: async function (assetID) {
            return (await doesAssetExist(companyID, assetID))
          },
          message: 'This asset ID is not exists.',
        })
        .required(REQUIRED_FIELD),   
        NewAssetID: Yup.string()
        .typeError(REQUIRED_FIELD)
        .max(12, maxStringMsg(12))
        .test({
          test: async function (assetID) {
            return !(await doesAssetExist(companyID, assetID))
          },
          message: 'This asset ID is already exists.',
        })
        .required(REQUIRED_FIELD),   
    })
  
    async function doesAssetExist(companyID: string, assetID: string): Promise<boolean> {
    return await AssetsApi.getInstance()
      .doesAssetExist(parseInt(companyID), assetID)
      .then(response => response.data)
      .catch(() => toast.error(ERROR_REQUEST))
  }

