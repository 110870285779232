import {
  Button,
  Divider,
  Flexbox,
  Form,
  Grid,
  Modal,
  Note,
  Spacer,
  useModal,
} from '@components/common'
import { useFields, useAssetDetails } from '@hooks'
import { FormProvider } from 'react-hook-form'
import AssetMainDetails from './AssetMainDetails'
import AssetBookInformation from './AssetBookInformation'
import SaveBar from '@components/SaveBar'
import {
  ASSET_DETAILS_FORM_ID,
  ASSET_RECALC_FORM_ID,
  ASSET_REINSTATEMENT_FORM_ID,
} from '@constants'
import AssetRecalcForm from '../AssetRecalcForm'
import AssetGLKeys from './AssetGLKeys'
import AssetPreferences from './AssetPreferences'
import AssetAccess from './AssetAccess'
import DiscardModal from '@components/DiscardModal'
import AssetOrganization from './AssetOrganization'

import AssetStatus, { ChangeAssetStatusModal } from './AssetStatus'
import AssetReinstatementForm from '../AssetDisposal/AssetReinstatementForm'
import ConfirmDeleteAssetModal from './ConfirmDeleteAssetModal'
import CommentEditor from '@components/CommentEditor'
import useActiveCompanyName from '@hooks/useActiveCompanyName'
import DuplicateAssetForm from '../AssetDuplicate/DuplicateAssetForm'
import usePermission from '@components/Auth/Permissions/usePermission'

const AssetDetails = () => {
  const {
    asset,
    isLoadingAsset,
    assetCompanyID,
    assetHasHistory,
    handleUpdateAsset,
    handleUpdateAssetWithRecalc,
    changeStatusAndSave,
    recalcFormState,
    closeRecalcForm,
    isDirty,
    methods,
    handleSubmit,
    dirtyFields,
    discardChanges,
    isAssetBookInfoFormActive,
    setIsAssetBookInfoFormActive,
    isMainDetailsFormActive,
    setIsMainDetailsFormActive,
    isGLKeysFormActive,
    setIsGLKeysFormActive,
    isPreferencesFormActive,
    setIsPreferencesFormActive,
    isDiscardModalOpen,
    setIsDiscardModalOpen,
    setIsDisposalFormModalOpen,
    isLoadingSave,
    isLoadingAssetReinstatement,
    isReinstatementModalOpen,
    setIsReinstatementModalOpen,
    isChangeAssetStatusModalOpen,
    setIsChangeAssetStatusModalOpen,
    isConfirmDeleteModalOpen,
    setIsConfirmDeleteModalOpen,
    handleDeleteAsset,
    handleDuplicateAsset,
    isLoadingDeleteAsset,
    isLoadingDuplicateAsset,
    isFormValid,
    isAssetDisposedOrPendingDisposal,
  } = useAssetDetails()
  const { fields, isLoading: isLoadingFields } = useFields()
  const { activeCompanyName } = useActiveCompanyName({ companyID: asset.AssetCompanyID })
  const {
    modalIsOpen: duplicateModalIsOpen,
    closeModal: closeDuplicateAssetModal,
    triggerModal: triggerDuplicateAssetModal,
  } = useModal()

  const { canEditAssets: canEdit } = usePermission()
  const isLoadingInfo = isLoadingAsset || isLoadingFields

  // Returns
  return (
    <FormProvider {...methods}>
      {/* SAVE & DISCARD MODAL */}
      <SaveBar
        isVisible={canEdit && isDirty}
        formId={ASSET_DETAILS_FORM_ID}
        isSubmitting={isLoadingSave}
        isSaveDisabled={!isFormValid}
        openDiscardModal={() => setIsDiscardModalOpen(true)}
      />
      <DiscardModal
        discard={discardChanges}
        modalIsOpen={isDiscardModalOpen}
        closeModal={() => setIsDiscardModalOpen(false)}
      />

      {/* RECALC MODAL */}
      <Modal
        isOpen={recalcFormState.open}
        close={closeRecalcForm}
        form={ASSET_RECALC_FORM_ID}
        title="Recalculate depreciation"
        actionLabel="Recalculate and save"
        isActionLoading={isLoadingSave}
      >
        <AssetRecalcForm
          initialValues={recalcFormState.intitialValues}
          submitAction={handleUpdateAssetWithRecalc}
        />
      </Modal>

      {/* CHANGE ASSET STATUS MODAL */}
      <ChangeAssetStatusModal
        isOpen={isChangeAssetStatusModalOpen}
        setIsChangeAssetStatusModalOpen={setIsChangeAssetStatusModalOpen}
        isDirty={isDirty}
        asset={asset}
        isLoadingSubmit={isLoadingSave}
        onSubmit={handleSubmit(changeStatusAndSave)}
      />

      {/* REINSTATE MODAL */}
      <Modal
        isOpen={isReinstatementModalOpen}
        close={() => setIsReinstatementModalOpen(false)}
        form={ASSET_REINSTATEMENT_FORM_ID}
        title={`Reinstate asset ${asset.AssetID} (${asset.AS_DESC})`}
        actionLabel="Reinstate asset"
        isActionLoading={isLoadingAssetReinstatement}
      >
        <AssetReinstatementForm
          assetCompanyID={assetCompanyID}
          assetID={asset.AssetID}
          asset={asset}
        />
      </Modal>

      <Modal
        isOpen={duplicateModalIsOpen}
        close={closeDuplicateAssetModal}
        title={`Duplicate Asset ${asset.AssetID} for company ${asset.AssetCompanyID} (${activeCompanyName})`}
        width={480}
      >
        <DuplicateAssetForm
          assetID={asset.AssetID}
          onDuplicateClick={newAssetId =>
            handleDuplicateAsset(newAssetId, closeDuplicateAssetModal)
          }
          isLoadingDuplicate={isLoadingDuplicateAsset}
        />
      </Modal>

      {/* CONFIRM DELETE MODAL */}
      <ConfirmDeleteAssetModal
        isOpen={isConfirmDeleteModalOpen}
        setIsConfirmDeleteModalOpen={setIsConfirmDeleteModalOpen}
        asset={asset}
        assetHasHistory={assetHasHistory}
        handleDelete={handleDeleteAsset}
        isLoadingDelete={isLoadingDeleteAsset}
      />

      {/* FORM/MAIN UI */}

      <Form id={ASSET_DETAILS_FORM_ID} onSubmit={handleSubmit(handleUpdateAsset)}>
        <Grid>
          {isAssetDisposedOrPendingDisposal && (
            <Grid.Item>
              <Note
                appearance="elevated"
                content={
                  <>
                    Asset is in read-only mode
                    {asset.AS_DIS_CD === 'P'
                      ? ' due to pending disposal'
                      : ' because it has been disposed'}
                    .{' '}
                    {asset.AS_DIS_CD === 'P' ? (
                      <>
                        <Button
                          appearance="text"
                          onClick={() => setIsReinstatementModalOpen(true)}
                        >
                          Reinstate asset
                        </Button>
                        .
                      </>
                    ) : (
                      ''
                    )}
                  </>
                }
              />
            </Grid.Item>
          )}
          <Grid.Item s={8}>
            <AssetMainDetails
              fields={fields}
              isLoadingInfo={isLoadingInfo}
              dirtyFields={dirtyFields}
              isMainDetailsFormActive={isMainDetailsFormActive}
              setIsMainDetailsFormActive={setIsMainDetailsFormActive}
              isAssetDisposedOrPendingDisposal={isAssetDisposedOrPendingDisposal}
              canEdit={canEdit}
            />
            <Spacer y="default" />
            <AssetBookInformation
              asset={asset}
              fields={fields}
              isLoadingInfo={isLoadingInfo}
              assetHasHistory={assetHasHistory}
              dirtyFields={dirtyFields}
              isAssetBookInfoFormActive={isAssetBookInfoFormActive}
              setIsAssetBookInfoFormActive={setIsAssetBookInfoFormActive}
              isAssetDisposedOrPendingDisposal={isAssetDisposedOrPendingDisposal}
              canEdit={canEdit}
            />
            <Spacer y="default" />
            <AssetGLKeys
              fields={fields}
              isLoadingInfo={isLoadingInfo}
              assetHasHistory={assetHasHistory}
              dirtyFields={dirtyFields}
              isGLKeysFormActive={isGLKeysFormActive}
              setIsGLKeysFormActive={setIsGLKeysFormActive}
              asset={asset}
              isAssetDisposedOrPendingDisposal={isAssetDisposedOrPendingDisposal}
              canEdit={canEdit}
            />
            <Spacer y="default" />
            <AssetPreferences
              fields={fields}
              isLoadingInfo={isLoadingInfo}
              dirtyFields={dirtyFields}
              isPreferencesFormActive={isPreferencesFormActive}
              setIsPreferencesFormActive={setIsPreferencesFormActive}
              isAssetDisposedOrPendingDisposal={isAssetDisposedOrPendingDisposal}
              canEdit={canEdit}
            />
            <Spacer y="default" />
            <AssetAccess fields={fields} isLoadingInfo={isLoadingInfo} />
          </Grid.Item>
          <Grid.Item s={4}>
            <AssetStatus
              setIsDisposalFormModalOpen={setIsDisposalFormModalOpen}
              setIsReinstatementModalOpen={setIsReinstatementModalOpen}
              asset={asset}
              isLoadingInfo={isLoadingInfo}
              assetHasHistory={assetHasHistory}
              isDirty={isDirty}
              assetCompanyID={assetCompanyID}
              fields={fields}
              setIsChangeAssetStatusModalOpen={setIsChangeAssetStatusModalOpen}
              canEdit={canEdit}
            />
            <Spacer y="default" />
            <AssetOrganization
              fields={fields}
              isLoadingInfo={isLoadingInfo}
              isAssetDisposedOrPendingDisposal={isAssetDisposedOrPendingDisposal}
              asset={asset}
              canEdit={canEdit}
            />
            <Spacer y="default" />
            <CommentEditor
              title={`Edit Comment - Asset ${asset.AssetID}: ${asset.AS_DESC}`}
              assetCompanyID={assetCompanyID}
              type="AI"
              objectID={asset.AssetID}
              key1="00000"
              key2="00000"
              key5={1}
              canEdit={canEdit}
            />
          </Grid.Item>
        </Grid>
        {canEdit && (
          <>
            <Divider spacing="default" />
            <Flexbox justifyContent="space-between">
              <Flexbox justifyContent="space-between">
                <Button
                  onClick={() => setIsConfirmDeleteModalOpen(true)}
                  intent="danger"
                  disabled={isDirty}
                >
                  Delete asset
                </Button>
              </Flexbox>
              <Flexbox justifyContent="flex-end">
                {!isAssetDisposedOrPendingDisposal && (
                  <Button
                    type="button"
                    onClick={triggerDuplicateAssetModal}
                    disabled={isDirty}
                    autoFocus={false}
                  >
                    Duplicate Asset
                  </Button>
                )}
                <Spacer x="large" />
                <Button
                  type="button"
                  disabled={!isDirty}
                  onClick={() => setIsDiscardModalOpen(true)}
                >
                  Discard
                </Button>
                <Spacer x="mini" />
                <Button
                  type="submit"
                  appearance="primary"
                  disabled={!isDirty || !isFormValid}
                  loading={isLoadingSave}
                >
                  Save changes
                </Button>
              </Flexbox>
            </Flexbox>
          </>
        )}
      </Form>
    </FormProvider>
  )
}
export default AssetDetails
