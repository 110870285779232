import { StyleObject } from '@styles-api/css.types'

export const main: StyleObject = {
  paddingRight: 'var(--gap-large)',
  paddingLeft: 'var(--gap-large)',
  paddingTop: 'var(--scale800)',
  paddingBottom: 'var(--scale800)',
  position: 'relative',
  gridTemplateArea: 'appMainContent',
  minHeight: 'calc(100vh - var(--topbar-height))',
  minWidth: 'calc(100vw - var(--sidebar-width))',
}
