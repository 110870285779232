import { StyleObject } from '@styles-api/css.types'

interface ITableRow {
  isSelected: boolean
  isMemorizeTemplateModal: boolean
}

export const tableRow = ({
  isSelected,
  isMemorizeTemplateModal,
}: ITableRow): StyleObject => {
  return {
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: isSelected ? 'var(--shade100)' : 'none',
    color: isSelected ? 'var(--primary-color)' : 'var(--contrast-color)',
    fontWeight: isSelected ? 'var(--font-weight-bold)' : 'var(--font-weight-normal)',
    maxWidth: isMemorizeTemplateModal ? '100%' : '85%',
    borderRadius: '15px',
  }
}

export const helpButtonsWrapper: StyleObject = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  justifyItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'none',
}

export const editButton: StyleObject = {
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  transform: 'scale(0.8)',
  '&:hover': {
    backgroundColor: 'red',
  },
  zIndex: 1,
}

export const deleteButton: StyleObject = {
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  transform: 'scale(0.8)',
  '&:hover': {
    color: 'red',
  },
}
