/**
 * Custom hook for managing the AssetsTable component state and API calls.
 * @param filterState - The current filter state of the AssetsTable component.
 * @param setFilterState - A function to update the filter state of the AssetsTable component.
 * @returns An object containing various properties and functions to manage the state and behavior of the AssetsTable component.
 */
import {
  useGetCompaniesQuery,
  useGetSettingsQuery,
  useGetUserTableParamsQuery,
  useSetUserTableParamsMutation,
} from '@api'

import { Asset, AssetKey, GetAssetsPayload, GetAssetsResponse } from '@api/assets/models'
import { useGetAssetsQuery } from '@api/assets/assetsEndpointsHooks'

import { GetCompaniesResponse, GetUserTableParamsResponse, SortObject } from '@api/models'

import { Flexbox, StatusIndicator, toast } from '@components/common'
import { useActiveCompanyName, useFields } from '@hooks'
import { formatCurrency, formatDisplayDate, isNullOrUndefined } from '@utils/basic'
import { PAGINATION_NEXT_BUTTON_ID } from '@constants'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { Cell, Column, ColumnInstance, SortingRule } from 'react-table'
import { filterFields } from '@components/AssetsTable/Filters'
import { IFilter } from '@components/AssetsTable/Filters/Filters.types'
import { InputChangeEvent } from '@types'
import { ActiveFilterTag, Filter } from '@components/AssetsTable/AssetsTable.types'
import { useSelector } from 'react-redux'

import { setFilter, clearFiltersButKeepSort, clearSort, setSort } from '@store/filtersSlice'
import _ from 'lodash'
import {
  clearAssetRegisterFiltersButKeepSort,
  setAssetRegisterFilter,
} from '@store/assetRegisterFiltersSlice'

interface IAssetsTableHook {
  assetCompanies: GetCompaniesResponse
  isFetchingAssetCompanies: boolean
  hasLoadedAssetCompanies: boolean
  isErrorGettingCompanies: boolean

  fields
  isLoadingFields: boolean
  isErrorGettingFields: boolean

  tableColumns: Column<Asset>[]
  defaultUserColumns: AssetKey[]
  userTableParams: GetUserTableParamsResponse
  getHiddenColumns: (visibleColumns: AssetKey[]) => string[]

  isLoadingUserTableParams: boolean
  isErrorGettingUserTableParams: boolean
  handleSetUserTableParams: (
    visibleColumns: ColumnInstance<Asset>[],
    hiddenColumnsState: string[]
  ) => Promise<void>
  isLoadingSetUserTableParams: boolean
  hasUserTableParamsUpdated: (visibleColumns: ColumnInstance<Asset>[]) => boolean

  // activeCompany: Company
  assets: GetAssetsResponse
  isLoadingAssets: boolean
  isReFetchingAssets: boolean
  isErrorGettingAssets: boolean
  getAssetsError: any

  isTableLoading: boolean // table is loading if any of the api calls are loading

  currentPage: number
  itemsPerPage: number
  activeAssetCompanyID: number
  activeCompanyName: string
  activeFilterTags: ActiveFilterTag[]
  assetsPerPage: string
  changeAssetsPerPage: (e: InputChangeEvent) => void
  gotoAssetPage: (e: InputChangeEvent) => void
  changePage: (e: React.MouseEvent<HTMLButtonElement>) => void
  removeFilter: (field: string, isRange: boolean, isMin?: boolean, isMax?: boolean) => void
  clearAllFilters: () => void

  searchByDescriptionValue: string
  searchByDescription: (e: InputChangeEvent) => void

  updateSortSearchParam: (
    sortBy: SortingRule<Asset>[],
    setSortBy: (sortBy: SortingRule<Asset>[]) => void
  ) => void
  sortByInitialState: () => SortingRule<Asset>[]
}

interface AssetsTableHookProps {
  filterState: any
  setFilterState: Dispatch<SetStateAction<{}>>
  isAssetRegister?: boolean
}

export default function useAssetsTable({
  filterState,
  setFilterState,
  isAssetRegister,
}: AssetsTableHookProps): IAssetsTableHook {
  // getAssets payload references search params
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])

  const maxItemsPerPage = 200

  // First we need to get companies
  const {
    data: assetCompanies,
    isLoading: isLoadingCompanies,
    isFetching: isFetchingAssetCompanies,
    isSuccess: hasLoadedAssetCompanies,
    isError: isErrorGettingCompanies,
  } = useGetCompaniesQuery()

  // Get field labels
  const { fields, isLoading: isLoadingFields, isError: isErrorGettingFields } = useFields()
  // Getting Date format
  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  // Get user table params to set hidden columns and column order
  const {
    data: userTableParams,
    isLoading: isLoadingUserTableParams,
    isError: isErrorGettingUserTableParams,
  } = useGetUserTableParamsQuery()

  const [
    setUserTableParams,
    { isLoading: isLoadingSetUserTableParams },
  ] = useSetUserTableParamsMutation()

  // Declare getAssetsPayload based on search params
  const getAssetsPayload: GetAssetsPayload = {
    itemsPerPage: searchParams.get('itemsPerPage')
      ? parseInt(searchParams.get('itemsPerPage'))
      : 20,
    assetCompanyID: searchParams.get('assetCompanyID')
      ? parseInt(searchParams.get('assetCompanyID'))
      : assetCompanies && assetCompanies.length > 0
      ? assetCompanies[0].AssetCompanyID
      : 0,
    pageNumber: searchParams.get('pageNumber')
      ? parseInt(searchParams.get('pageNumber'))
      : 1,
    filter: filterState,
  }

  // Get assets
  const {
    data: assets,
    isLoading: isLoadingAssets,
    isError: isErrorGettingAssets,
    error: getAssetsError,
    isFetching: isReFetchingAssets,
  } = useGetAssetsQuery(getAssetsPayload, {
    skip: !hasLoadedAssetCompanies, // Skip getAssets call if companies have not been called successfully
  })

  // Update search params when companies load to get assetCompanyID
  useEffect(() => {
    // ** assetCompanyID, itemsPerPage and PageNumber are the mandatory URL params for the component to function
    if (!isLoadingCompanies && assetCompanies.length > 0) {
      // Check if assetCompanyID in URL param is valid
      let assetCompanyID
      const assetCompanyIds = assetCompanies.map(comp => comp.AssetCompanyID.toString())

      if (searchParams.get('assetCompanyID')) {
        if (
          assetCompanyIds.filter(id => id === searchParams.get('assetCompanyID')).length > 0
        ) {
          assetCompanyID = searchParams.get('assetCompanyID')
        } else {
          assetCompanyID = assetCompanies[0].AssetCompanyID.toString()
        }
      } else {
        assetCompanyID = assetCompanies[0].AssetCompanyID.toString()
      }

      // Limit items per page
      let itemsPerPage
      if (searchParams.get('itemsPerPage')) {
        if (parseInt(searchParams.get('itemsPerPage')) > maxItemsPerPage) {
          itemsPerPage = maxItemsPerPage
        } else {
          itemsPerPage = searchParams.get('itemsPerPage')
        }
      } else {
        itemsPerPage = '20'
      }

      setSearchParams({
        assetCompanyID,
        itemsPerPage,
        pageNumber: searchParams.get('pageNumber') ?? '1',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCompanies, assetCompanies, setSearchParams])

  // COLUMNS AND USER TABLE PARAMS

  const currencyCell = (props: any) => {
    return (
      <Flexbox justifyContent="space-between" style={{ width: '100%' }}>
        <span>{props.row.index === 0 ? '$' : ''}</span>
        {formatCurrency(props.value, false  )}
      </Flexbox>
    )
  }

  // Declare column data for react-table
  const tableColumns = useMemo<Column<Asset>[]>(
    () => [
      {
        Header: fields.AssetID,
        accessor: 'AssetID',
        maxWidth: 100,
      },
      {
        Header: fields.AS_DESC,
        accessor: 'AS_DESC',
      },
      {
        Header: fields.AS_SERIAL,
        accessor: 'AS_SERIAL',
      },
      {
        Header: fields.AS_REC_DAT,
        accessor: 'AS_REC_DAT',
        Cell: props => formatDisplayDate(props.value, DateFormat),
      },
      {
        Header: fields.AS_FIN_COS,
        accessor: 'AS_FIN_COS',
        width: 124,
        Cell: (props: Cell) => {
          if (props.value !== undefined) return currencyCell(props)
        },
      },
      {
        Header: fields.AS_DIS_CD,
        accessor: 'AS_DIS_CD',
        Cell: (props: Cell) => {
          let statusType: 'default' | 'success' | 'warning' | 'danger' | 'primary'
          let statusText: string
          switch (props.value) {
            case 'A':
              statusType = 'success'
              statusText = 'Active'
              break
            case 'P':
              statusType = 'warning'
              statusText = 'Pending'
              break
            case 'N':
              statusType = 'primary'
              statusText = 'Non-Active'
              break
            case 'D':
              statusType = 'danger'
              statusText = 'Disposed'
              break
          }
          return <StatusIndicator type={statusType} label={statusText} />
        },
      },
      {
        Header: fields.AS_DIS_DAT,
        accessor: 'AS_DIS_DAT',
        Cell: (props: Cell) =>
          props.value === '0001-01-01T00:00:00' || props.value === '0001-01-01'
            ? ''
            : formatDisplayDate(props.value, DateFormat),
      },
      {
        Header: fields.AS_CAT_ID,
        accessor: 'AS_CAT_ID',
      },
      {
        Header: fields.AS_TCLS_ID,
        accessor: 'AS_TCLS_ID',
      },
      {
        Header: fields.AS_DESC_E,
        accessor: 'AS_DESC_E',
      },
      {
        Header: fields.AS_KEY0_ID,
        accessor: 'AS_KEY0_ID',
      },
      {
        Header: fields.AS_KEY1_ID,
        accessor: 'AS_KEY1_ID',
      },
      {
        Header: fields.AS_KEY2_ID,
        accessor: 'AS_KEY2_ID',
      },
      {
        Header: fields.AS_KEY3_ID,
        accessor: 'AS_KEY3_ID',
      },
      {
        Header: fields.AS_KEY4_ID,
        accessor: 'AS_KEY4_ID',
      },
      {
        Header: fields.AS_FIN_TAC,
        accessor: 'AS_FIN_TAC',
        width: 120,
        Cell: (props: Cell) => {
          if (props.value !== undefined) return currencyCell(props)
        },
      },
      {
        Header: fields.AS_IN_POL,
        accessor: 'AS_IN_POL',
      },
      {
        Header: fields.AS_LEASE,
        accessor: 'AS_LEASE',
      },
      {
        Header: fields.AS_MAIN_CN,
        accessor: 'AS_MAIN_CN',
      },
      {
        Header: fields.AS_NSER_DT,
        accessor: 'AS_NSER_DT',
        Cell: (props: Cell) =>
          props.value !== undefined &&
          (props.value === '0001-01-01T00:00:00' || props.value === '0001-01-01'
            ? ''
            : formatDisplayDate(props.value, DateFormat)),
      },
      {
        Header: fields.AS_FIN_SAL,
        accessor: 'AS_FIN_SAL',
        width: 120,
        Cell: (props: Cell) => {
          if (props.value !== undefined) return currencyCell(props)
        },
      },
      {
        Header: fields.AS_PRI_UCD,
        accessor: 'AS_PRI_UCD',
      },
      {
        Header: fields.AS_SEC_UCD,
        accessor: 'AS_SEC_UCD',
      },
      {
        Header: fields.AS_UC1_UCD,
        accessor: 'AS_UC1_UCD',
      },
      {
        Header: fields.AS_UC2_UCD,
        accessor: 'AS_UC2_UCD',
      },
      {
        Header: fields.AS_UC3_UCD,
        accessor: 'AS_UC3_UCD',
      },
      {
        Header: fields.AS_VEN_ID,
        accessor: 'AS_VEN_ID',
      },
      {
        Header: fields.AS_VEN_NM,
        accessor: 'AS_VEN_NM',
      },
      {
        Header: fields.AS_ADD_DAT,
        accessor: 'AS_ADD_DAT',
        Cell: props => formatDisplayDate(props.value, DateFormat),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingFields]
  )

  function getHiddenColumns(visibleColumns: AssetKey[]): string[] {
    return tableColumns
      .map(column => column.accessor)
      .filter(accessor => !visibleColumns.includes(accessor as keyof Asset)) as string[]
  }

  const defaultUserColumns: AssetKey[] = [
    'AssetID',
    'AS_DESC',
    'AS_FIN_COS',
    'AS_REC_DAT',
    'AS_CAT_ID',
    'AS_DIS_CD',
  ]

  // Save column changes
  function assetKeysFromVisibleColumns(
    visibleColumns: ColumnInstance<Asset>[]
  ): AssetKey[] {
    const visibleColumnsAssetKeys: AssetKey[] = []
    for (const column of visibleColumns) {
      visibleColumnsAssetKeys.push(column.id as AssetKey)
    }

    return visibleColumnsAssetKeys
  }

  async function handleSetUserTableParams(
    visibleColumns: ColumnInstance<Asset>[],
    hiddenColumnsState: string[]
  ): Promise<void> {
    try {
      await setUserTableParams({
        columns: assetKeysFromVisibleColumns(visibleColumns),
        order: [
          ...assetKeysFromVisibleColumns(visibleColumns), // order of visibleColumns
          ...(hiddenColumnsState as (keyof Asset)[]), // order of hidden columns
        ],
      }).unwrap()
      toast.success('Successfully saved column layout.')
    } catch (error) {
      toast.error('Unexpected error saving column layout.')
    }
  }

  const hasUserTableParamsUpdated = (visibleColumns: ColumnInstance<Asset>[]): boolean => {
    const currentColumns = assetKeysFromVisibleColumns(visibleColumns)
    const currentSetOfColumns = new Set(currentColumns)
    const prevSetOfColumns = new Set(
      userTableParams ? userTableParams.Columns : defaultUserColumns
    )
    const difference = new Set(
      [...currentSetOfColumns].filter(x => !prevSetOfColumns.has(x))
    )
    const areColumnsTheSame =
      difference.size === 0 && currentSetOfColumns.size === prevSetOfColumns.size

    if (userTableParams) {
      for (let i = 0; i < currentColumns.length; i++) {
        if (currentColumns[i] !== userTableParams.Columns[i]) return true
      }
    }

    return !areColumnsTheSame
  }

  // Table is loading if any of the api calls are loading
  const isTableLoading: boolean =
    isLoadingAssets ||
    isLoadingCompanies ||
    isLoadingFields ||
    isLoadingFields ||
    isLoadingUserTableParams

  // Set default values for local Storage it's is empty
  if (!localStorage.getItem('itemsPerPage')) {
    localStorage.setItem('itemsPerPage', '20')
  }
  if (!localStorage.getItem('pageNumber')) {
    localStorage.setItem('pageNumber', '1')
  }

  // Get values
  const activeAssetCompanyID: number = parseInt(searchParams.get('assetCompanyID'))
  // get those values based on local storage
  const itemsPerPage: number = parseInt(localStorage.getItem('itemsPerPage')) ?? 20
  const currentPage: number = parseInt(localStorage.getItem('pageNumber')) ?? 1

  const { activeCompanyName } = useActiveCompanyName({ companyID: activeAssetCompanyID })

  // QUERY FUNCTIONS

  // Pagination

  function changePage(e: React.MouseEvent<HTMLButtonElement>): void {
    if (e.target instanceof Element) {
      let goToPageNumber: number
      if (e.target.id === PAGINATION_NEXT_BUTTON_ID) {
        goToPageNumber = currentPage + 1
      } else {
        goToPageNumber = currentPage - 1
      }

      setgotoPage(goToPageNumber.toString())

      setSearchParams({
        ...searchParamsObject,
        pageNumber: goToPageNumber.toString(),
      })

      localStorage.setItem('pageNumber', goToPageNumber.toString())
    }
  }

  // Pagination adjusting assets per page
  const [assetsPerPage, setassetsPerPage] = useState<string>()

  function changeAssetsPerPage(e: InputChangeEvent): void {
    setassetsPerPage(e.target.value)
    if (e.target instanceof Element) {
      let assetsPerPage =
        e.target.value === '' || e.target.value <= '0' || parseInt(e.target.value) > 100
          ? '20'
          : e.target.value

      setSearchParams({
        ...searchParamsObject,
        itemsPerPage: assetsPerPage,
      })
      localStorage.setItem('itemsPerPage', assetsPerPage)
    }
  }

  // Pagination adjusting assets per page
  const [, setgotoPage] = useState<string>()

  function gotoAssetPage(e: InputChangeEvent): void {
    if (e.target instanceof Element) {
      let gotoPage = e.target.value === '0' ? '1' : e.target.value
      if (gotoPage === '' || gotoPage < '0') {
        setgotoPage(gotoPage.toString())
      }
      setSearchParams({
        ...searchParamsObject,
        pageNumber: gotoPage.toString(),
      })

      localStorage.setItem('pageNumber', gotoPage.toString())
    }
  }

  // Company
  const assetCompanyID = useSelector<any>(state => state.company) as number

  // Using this ref to prevent infinite loop when updating search params
  const isUpdatingSeachParams = useRef(false)

  const setSearchParamsMemoized = useCallback(
    newSearchParams => {
      if (!isUpdatingSeachParams.current) {
        isUpdatingSeachParams.current = true
        setSearchParams({
          ...searchParamsObject,
          assetCompanyID: assetCompanyID.toString(),
          pageNumber: localStorage.getItem('pageNumber') ?? '1',
          itemsPerPage: localStorage.getItem('itemsPerPage') ?? '20',
          ...newSearchParams,
        })
        isUpdatingSeachParams.current = false
      }
    },

    [assetCompanyID, searchParams, searchParamsObject, setSearchParams]
  )

  useEffect(() => {
    if (assetCompanyID !== parseInt(searchParams.get('assetCompanyID') ?? '')) {
      if (!isUpdatingSeachParams.current) {
        isUpdatingSeachParams.current = true

        // Check if the new assetCompanyID is different from the current value in searchParams
        if (assetCompanyID.toString() !== searchParams.get('assetCompanyID')) {
          setSearchParamsMemoized({ assetCompanyID: assetCompanyID.toString() })
        }

        isUpdatingSeachParams.current = false
      }

      setSearchParamsMemoized({ assetCompanyID: assetCompanyID.toString() })
    }
  }, [assetCompanyID, setSearchParamsMemoized])

  /* FILTERS */

  // How filters work:
  // - User adds new filter
  // - Filter state object updates
  // - Filter state object updates searchParams
  // - useEffect updates filter state object based on searchParams
  // - getAssets payload uses filter state object

  const updateParamsBasedOnFilterState = (filterState: any): void => {
    const newSearchParamsObject = { ...searchParamsObject }

    if (filterState.filter) {
      const filters = filterState.filter.filters

      for (const filter of filters) {
        const filterField = filterFields.find(field => field.column === filter.field)
        const filterValue = filter.value
        const filterOperator = filter.operator

        if (filterField.compare) {
          // If compare field, params are min and max values
          if (filterOperator === 'gte') {
            newSearchParamsObject[`MIN_${filterField.column}`] = filterValue
          } else if (filterOperator === 'lte') {
            newSearchParamsObject[`MAX_${filterField.column}`] = filterValue
          }
        } else {
          newSearchParamsObject[filterField.column] = filterValue
        }
      }
    }

    // check if localStorage has itemsPerPage and pageNumber, if so add them to setSearchParams
    if (localStorage.getItem('itemsPerPage')) {
      newSearchParamsObject.itemsPerPage = localStorage.getItem('itemsPerPage')
    }
    if (localStorage.getItem('pageNumber')) {
      newSearchParamsObject.pageNumber = localStorage.getItem('pageNumber')
    }

    setSearchParams({
      ...searchParamsObject,
      ...newSearchParamsObject,
    })
  }

  useEffect(() => {
    updateParamsBasedOnFilterState(filterState)
  }, [])

  // Use effect to run when filters in searchParams change
  useEffect(() => {
    const searchParamsArray = [...searchParams]
    const filtersDependencies: string[] = []
    const filterProperties: string[] = [] // array of all columns available for filtering **with MIN and MAX if applicable

    for (const field of filterFields) {
      if (field.compare) {
        // If compare field, params are min and max values
        filtersDependencies.push(searchParams.get(`MIN_${field.column}`))
        filtersDependencies.push(searchParams.get(`MAX_${field.column}`))

        filterProperties.push(`MIN_${field.column}`)
        filterProperties.push(`MAX_${field.column}`)
      } else {
        filtersDependencies.push(searchParams.get(field.column))

        filterProperties.push(field.column)
      }
    }

    // Find which filterProperties are currently in search params
    // * note: searchParamsArray is an array of arrays with 2 items, the param key and param value...
    // e.g. seachParamsArray = [ ['assetCompanyID', '100'], ['itemsPerPage', '2'] ]
    const filterPropertiesInSearchParams = searchParamsArray.filter(
      param => filterProperties.filter(property => property === param[0]).length > 0
    )

    if (filterPropertiesInSearchParams.length > 0) {
      const newArrayOfFiltersObjects: any[] = []

      for (const filter of filterPropertiesInSearchParams) {
        let filterObjectToInsert: IFilter
        const filterFieldProperty = filter[0]
        const filterValue = filter[1]

        const isMinFilter = filterFieldProperty.includes('MIN_')
        const isMaxFilter = filterFieldProperty.includes('MAX_')
        // conditions for min and max filters
        if (isMinFilter || isMaxFilter) {
          filterObjectToInsert = {
            field: filterFieldProperty.slice(4) as AssetKey, // Remove "MIN_" or "MAX_"
            operator: isMinFilter ? 'gte' : 'lte',
            value: filterValue,
          }
        } else {
          const filterField = filterFields.find(
            field => field.column === filterFieldProperty
          )
          filterObjectToInsert = {
            field: filterField.column,
            operator: filterField.operator,
            value: filterValue,
          }
        }
        newArrayOfFiltersObjects.push(filterObjectToInsert)
      }

      const filterPayload = {
        filter: {
          logic: 'and',
          filters: newArrayOfFiltersObjects,
        },
      }

      // Compare the new filterPayload with the previous filterPayload content
      const filterPayloadChanged = !_.isEqual(filterPayload, filterState)

      if (filterPayloadChanged) {
        if (isAssetRegister) {
          setFilterState(setAssetRegisterFilter(filterPayload))
        } else {
          setFilterState(setFilter(filterPayload))
        }
      }
    }

    const hasSortOnParams = searchParams.get('sort')

    // this is clearing when the user changes
    if (!hasSortOnParams && filterState['sort']) {
      setFilterState(clearSort())
    }
  }, [searchParams])

  // Active filters
  const activeFilterTags: ActiveFilterTag[] = []

  if (filterState.filter) {
    // Get array of each filter field (e.g. ["AS_DESC", "AS_FIN_COS", "AS_FIN_COS"])
    const filterFieldsArray = filterState.filter?.filters?.map(function (item: Filter) {
      return item.field
    })

    // Find dpulicates in filterFieldsArray to detect range (MIN MAX) filters
    const countOfEachFilterField = filterFieldsArray.reduce(
      (result, value) => ({ ...result, [value]: (result[value] || 0) + 1 }),
      {}
    )
    const duplicateFilters: any = Object.keys(countOfEachFilterField).filter(
      a => countOfEachFilterField[a] > 1
    )

    // Insert MIN MAX filters into activeFilterTags
    if (duplicateFilters.length > 0) {
      for (const rangeFilterField of duplicateFilters) {
        const rangeField = filterFields.find(field => field.column === rangeFilterField)

        const filterMinObject: Filter = filterState.filter?.filters.find(
          (filter: Filter) =>
            filter.field === rangeField.column && filter.operator === 'gte'
        )
        const filterMaxObject: Filter = filterState.filter?.filters.find(
          (filter: Filter) =>
            filter.field === rangeField.column && filter.operator === 'lte'
        )

        let filterLabel: string
        if (rangeField.isCurrency) {
          filterLabel = `${fields[rangeField.column]} ranges from ${formatCurrency(
            filterMinObject.value
          )} to ${formatCurrency(filterMaxObject.value)}`
        } else {
          filterLabel = `${fields[rangeField.column]}: ${filterMinObject.value} to ${
            filterMaxObject.value
          }`
        }
        activeFilterTags.push({
          field: rangeField.column,
          label: filterLabel,
          isRange: true,
        })
      }
    }

    // Insert standard filters into activeFilterTags
    const standardFilters: any = Object.keys(countOfEachFilterField).filter(
      a => countOfEachFilterField[a] === 1
    )
    if (standardFilters.length > 0) {
      for (const standardFilterField of standardFilters) {
        const standardField = filterFields.find(
          field => field.column === standardFilterField
        )
        const filterObject = filterState.filter?.filters.find(
          (filter: Filter) => filter.field === standardField.column
        )
        let translateOperator: string

        switch (filterObject.operator) {
          case 'contains':
            translateOperator = 'contains'
            break
          case 'eq':
            translateOperator = 'is'
            break
          case 'gte':
            if (standardField.type === Date) {
              translateOperator = 'is on or after'
            } else {
              translateOperator = 'is greater than or equal to'
            }

            break
          case 'lte':
            if (standardField.type === Date) {
              translateOperator = 'is on or before'
            } else {
              translateOperator = 'is less than or equal to'
            }
            break
        }

        let filterLabel: string

        if (standardField.isCurrency) {
          filterLabel = `${
            fields[standardField.column]
          } ${translateOperator} ${formatCurrency(filterObject.value)}`
        } else if (fields[standardField.column] === 'Asset Description') {
          filterLabel = `Asset ID or Description ${translateOperator} ${filterObject.value}`
        } else {
          filterLabel = `${fields[standardField.column]} ${translateOperator} ${
            filterObject.value
          }`
        }

        activeFilterTags.push({
          field: standardField.column,
          label: filterLabel,
          isMin: filterObject.operator === 'gte',
          isMax: filterObject.operator === 'lte',
        })
      }
    }
  }

  function removeFilter(
    field: string,
    isRange: boolean,
    isMin?: boolean,
    isMax?: boolean
  ): void {
    if (isRange) {
      searchParams.delete(`MIN_${field}`)
      searchParams.delete(`MAX_${field}`)
    } else if (isMin) {
      searchParams.delete(`MIN_${field}`)
    } else if (isMax) {
      searchParams.delete(`MAX_${field}`)
    } else {
      searchParams.delete(field)
    }

    setSearchParams(searchParams)
    removeFilterFromReduxStore(field, isRange, isMin, isMax)
  }

  function removeFilterFromReduxStore(
    field: string,
    isRange: boolean,
    isMin?: boolean,
    isMax?: boolean
  ): void {
    const newFilters = filterState.filter?.filters.filter((filter: Filter) => {
      if (isRange) {
        return filter.field !== field
      } else if (isMin) {
        return !(filter.field === `MIN_${field}`)
      } else if (isMax) {
        return !(filter.field === `MAX_${field}`)
      } else {
        return filter.field !== field
      }
    })
    //if i do have newFilters, then i want to update the filter state
    if (newFilters.length > 0) {
      if (isAssetRegister) {
        setFilterState(setAssetRegisterFilter({ filter: { filters: newFilters } }))
      } else {
        setFilterState(
          setFilter({
            filter: {
              filters: newFilters,
            },
          })
        )
      }
    }
    // otherwise, i want to clear the filter state, but keep the sort
    else {
      if (isAssetRegister) {
        setFilterState(clearAssetRegisterFiltersButKeepSort())
      } else {
        setFilterState(clearFiltersButKeepSort())
      }
    }
  }

  function clearAllFilters(): void {
    let refreshedSearchParamsObject
    refreshedSearchParamsObject = {
      assetCompanyID:
        searchParams.get('assetCompanyID') ?? assetCompanies[0].AssetCompanyID.toString(),
      itemsPerPage: searchParams.get('itemsPerPage') ?? '20',
      pageNumber: searchParams.get('pageNumber') ?? '1',
    }
    if (searchParams.get('sort')) {
      refreshedSearchParamsObject.sort = searchParams.get('sort')
    }
    setSearchParams(refreshedSearchParamsObject)
    if (isAssetRegister) {
      setFilterState(clearAssetRegisterFiltersButKeepSort())
    } else {
      setFilterState(clearFiltersButKeepSort())
    }
  }

  /*
  Searching by description
  */
  const [searchByDescriptionValue, setSearchByDescriptionValue] = useState<string>(
    searchParams.get('AS_DESC') ?? ''
  )

  function searchByDescription(e: InputChangeEvent): void {
    setSearchByDescriptionValue(e.target.value)
    setTimeout(() => {
      if (e.target.value === '') {
        searchParams.delete('AS_DESC')
        setSearchParams(searchParams)
      } else {
        setSearchParams({
          ...searchParamsObject,
          AS_DESC: e.target.value,
          pageNumber: '1',
        })
      }
    }, 1800)
  }

  const description = searchParams.get('AS_DESC')
  useEffect(() => {
    if (isNullOrUndefined(description)) setSearchByDescriptionValue('')
  }, [description])

  // NOTE: filter change functions are in the Filter component

  /* SORTING */

  // 1. useEffect to update filter payload based on url params
  const sort = searchParams.get('sort')
  const reduxStateSort = useSelector<any>(state => state.filters.sort)

  useEffect(() => {
    if (sort) {
      const idUnderscore = sort.lastIndexOf('_')
      let field = sort.slice(0, idUnderscore)
      let dir = sort.slice(idUnderscore + 1)

      const sortObject: SortObject = {
        field,
        dir: dir as 'asc' | 'desc',
      }

      // only do it if the sort is different from the redux state
      if (JSON.stringify([sortObject]) !== JSON.stringify(reduxStateSort)) {
        setFilterState(setSort(sortObject))
      }
    }
  }, [sort])

  // 2. function to update url params when react-table sortBy updates
  function updateSortSearchParam(
    sortBy: SortingRule<Asset>[],
    setSortBy: (sortBy: SortingRule<Asset>[]) => void
  ): void {
    // ** multi sort is disabled in useTable
    // if we need multisort this will need to be refactored
    if (sortBy.length > 0) {
      const { desc: isDesc, id: field } = sortBy[0]

      let dir: 'asc' | 'desc'
      if (isDesc) {
        dir = 'desc'
      } else {
        dir = 'asc'
      }

      setSearchParams({
        ...searchParamsObject,
        sort: `${field}_${dir}`,
      })
    } else {
      if (searchParams.get('sort')) {
        searchParams.delete('sort')
        setSearchParams(searchParams)
      }
    }

    // update sortBy if page loaded with sort in url params
    if (sortBy.length === 0 && searchParams.get('sort')) {
      const idUnderscore = searchParams.get('sort').lastIndexOf('_')
      const field = searchParams.get('sort').slice(0, idUnderscore)

      const dir = searchParams.get('sort').slice(idUnderscore + 1)

      setSortBy([
        {
          id: field,
          desc: dir === 'desc',
        },
      ])
    }
  }

  const sortByInitialState = (): SortingRule<Asset>[] => {
    if (searchParams.get('sort')) {
      const idUnderscore = searchParams.get('sort').lastIndexOf('_')
      const field = searchParams.get('sort').slice(0, idUnderscore)

      const dir = searchParams.get('sort').slice(idUnderscore + 1)

      return [
        {
          id: field,
          desc: dir === 'desc',
        },
      ]
    } else return []
  }

  return {
    assetCompanies,
    isFetchingAssetCompanies,
    hasLoadedAssetCompanies,
    isErrorGettingCompanies,

    fields,
    isLoadingFields,
    isErrorGettingFields,
    tableColumns,
    defaultUserColumns,
    userTableParams,
    getHiddenColumns,

    isLoadingUserTableParams,
    isErrorGettingUserTableParams,
    handleSetUserTableParams,
    isLoadingSetUserTableParams,
    hasUserTableParamsUpdated,
    // activeCompany,
    assets,
    isLoadingAssets,
    isReFetchingAssets,
    isErrorGettingAssets,
    getAssetsError,

    isTableLoading,

    currentPage,
    itemsPerPage,
    activeAssetCompanyID,
    activeCompanyName,
    activeFilterTags,

    changePage,
    assetsPerPage,
    changeAssetsPerPage,
    gotoAssetPage,
    removeFilter,
    clearAllFilters,
    searchByDescription,
    searchByDescriptionValue,

    updateSortSearchParam,
    sortByInitialState,
  }
}
