export const locales = {
    'en-US': 'USD',
    'en-CA': 'CAD',
    'fr-CA': 'CAD',
    'fr-FR': 'EUR',
    'de-DE': 'EUR',
    'es-ES': 'EUR',
    'pt-BR': 'BRL',
    'zh-CN': 'CNY',
    'ja-JP': 'JPY',
    'ko-KR': 'KRW',
    'ru-RU': 'RUB',
    'it-IT': 'EUR',
    'tr-TR': 'TRY',
    'vi-VN': 'VND',
    'hi-IN': 'INR',
    'th-TH': 'THB',
    'id-ID': 'IDR',
    'ms-MY': 'MYR',
    'pl-PL': 'PLN',
    'uk-UA': 'UAH',
    'el-GR': 'EUR',
    'cs-CZ': 'CZK',
    'sk-SK': 'EUR',
    'ro-RO': 'EUR',
    'hu-HU': 'HUF',
    'ta-IN': 'INR',
    'te-IN': 'INR',
    'kn-IN': 'INR',
    'ml-IN': 'INR',
    'mr-IN': 'INR',
    'az-AZ': 'AZN',
    'kk-KZ': 'KZT',
    'hy-AM': 'AMD',
    'bn-BD': 'BDT',
    'pa-IN': 'INR',
    'ne-NP': 'NPR',
    'si-LK': 'LKR',
    'gu-IN': 'INR',
    'ta-LK': 'LKR',
    'te-LK': 'LKR',
    'kn-LK': 'LKR',
    'ml-LK': 'LKR',
    'mr-LK': 'LKR',
    'as-IN': 'INR',
    'or-IN': 'INR',
    'ml-MY': 'MYR',
    'pa-PK': 'PKR',
    'sd-PK': 'PKR',
    'bn-IN': 'INR',
    'gom-IN': 'INR',
    'mai-IN': 'INR',
    'asa-TZ': 'TZS',
    'luo-KE': 'KES',
    'cgg-UG': 'UGX',
    'fa-AF': 'AFN',
    'ps-AF': 'AFN',
    'ur-IN': 'INR',
    'ig-NG': 'NGN',
    'nyn-UG': 'UGX',
    'sn-ZW': 'ZWD',
    'hne-IN': 'INR',
    'wo-SN': 'XOF',
    'brx-IN': 'INR',
    've-ZA': 'ZAR',
    'ms-BN': 'BND'
}
