import { Card, InputSkeleton, Spacer, TextSkeleton } from '@components/common'

const CreateCompanyFormLoading = () => {
  return (
    <>
      <Card spacing="none" overflow="hidden" style={{ padding: '12px 16px' }}>
        <TextSkeleton width="var(--scale1800)" height="var(--scale600)" />
      </Card>

      <Card spacing="none" overflow="hidden" style={{ padding: '12px 16px' }}>
        <TextSkeleton width="var(--scale1800)" height="var(--scale600)" />
        <InputSkeleton width="100%" />

        <Spacer y="default" />

        <TextSkeleton width="var(--scale1800)" height="var(--scale600)" />
        <InputSkeleton width="100%" />

        <Spacer y="default" />

        <TextSkeleton width="var(--scale1800)" height="var(--scale600)" />
        <InputSkeleton width="100%" />
      </Card>

      <Spacer y="default" />

      <Card spacing="none" overflow="hidden" style={{ padding: '12px 16px' }}>
        <TextSkeleton width="var(--scale1800)" height="var(--scale600)" />
      </Card>

      <Card spacing="none" overflow="hidden" style={{ padding: '12px 16px' }}>
        <TextSkeleton width="var(--scale1800)" height="var(--scale600)" />
        <InputSkeleton width="50%" />

        <Spacer y="default" />

        <TextSkeleton width="var(--scale1800)" height="var(--scale600)" />
        <InputSkeleton width="25%" />

        <Spacer y="default" />

        <TextSkeleton width="var(--scale1800)" height="var(--scale600)" />
        <InputSkeleton width="100%" />
      </Card>
    </>
  )
}
export default CreateCompanyFormLoading
