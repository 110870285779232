import HttpClient from '../HttpClient'

export class InsuranceApi extends HttpClient {
  private static classInstance?: InsuranceApi

  public constructor() {
    super(`/Insurance`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new InsuranceApi()
    }

    return this.classInstance
  }

  public fetch = (filter: string, pageNumber: number, itemsPerPage: number) =>
    this.instance.get(
      `/FetchInsurances?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`
    )
  public list = () => this.instance.get(`/ListInsurances`)
  public getInsurance = (id: string) =>
    this.instance.get(`/GetInsurance?id=${id}`)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (data: any) => this.instance.post(`/Delete`, data)
  public import = (file: any) => this.instance.post(`/ImportInsurance`, file)
}
