import { Outlet, useParams } from 'react-router-dom'
import Template from '@components/layout/Template'
import { APP_TITLE_DIVIDER, COMPANIES_PATH, COMPANY_PATHS } from '@constants'
import { useGetCompanyQuery } from '@api'
import { Helmet } from 'react-helmet'
import { APP_TITLE } from '@constants'
import CompanyTemplateLoading from '@components/company/CompanyTemplateLoading'

const CompanyTemplate = () => {
  const params = useParams()

  const companyViewPath = `/${COMPANIES_PATH}/${params.companyID}`
  const navItems = [
    {
      label: 'Details',
      viewPath: `${companyViewPath}/${COMPANY_PATHS.DETAILS}`,
    },
    {
      label: 'Periods',
      viewPath: `${companyViewPath}/${COMPANY_PATHS.PERIODS}`,
    },
  ]

  const { data: company, isLoading: isLoadingCompany } = useGetCompanyQuery(
    parseInt(params.companyID)
  )

  if (isLoadingCompany) return <CompanyTemplateLoading />

  return (
    <>
      <Helmet
        titleTemplate={`${APP_TITLE} ${APP_TITLE_DIVIDER} Companies ${APP_TITLE_DIVIDER} ${company?.CM_DESC} ${APP_TITLE_DIVIDER}  %s `}
      />
      <Template
        localNav={navItems}
        title={`Company ${company?.AssetCompanyID}: ${company?.CM_DESC}`}
        maxWidth="compact"
        breadcrumbs={[
          {
            label: 'Companies',
            path: '/companies',
          },
          {
            label: `${params.companyID}: ${company?.CM_DESC}`,
            isActive: true,
          },
        ]}
      >
        <Outlet />
      </Template>
    </>
  )
}

export default CompanyTemplate
