import { Table } from '@components/common'
import { AssetHistoryTableRow } from '@hooks/useAssetHistory'
import { Column, useFlexLayout, useTable } from 'react-table'

interface Props {
  columns: Column<AssetHistoryTableRow>[]
  data: AssetHistoryTableRow[]
  isManual: boolean
}

const AssetHistoryTable = ({ columns, data, isManual }: Props) => {
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable(
    {
      columns,
      data: data ?? [],
    },
    useFlexLayout
  )

  return (
    <Table {...getTableProps()} label="Asset history table" aria-label="History table">
      <Table.Head>
        {headerGroups.map((headerGroup, i) => (
          <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => (
              <Table.Header
                {...column.getHeaderProps()}
                column={column}
                key={i}
                tabIndex={0}
                centered
                style={{
                  borderBottomWidth: i === 0 ? '0' : '1px',
                  borderColor: 'var(--shade300)',
                  marginBottom: '-1px',
                  ...column.getHeaderProps().style,
                }}
                className="center-col-header"
              >
                {column.render('Header')}
              </Table.Header>
            ))}
          </Table.HeadRow>
        ))}
      </Table.Head>
      <Table.Body {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <Table.Row {...row.getRowProps()} tabIndex={0}>
              {row.cells.map((cell, iCell) => {
                const shouldStyle: boolean = i === rows.length - 1 && iCell !== 0
                return (
                  <Table.Cell
                    {...cell.getCellProps()}
                    column={cell.column}
                    style={{
                      borderTopWidth: shouldStyle && !isManual ? '1px' : '0',
                      borderColor: 'var(--shade300)',
                      ...cell.getCellProps().style,
                    }}
                  >
                    {cell.render('Cell')}
                  </Table.Cell>
                )
              })}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default AssetHistoryTable
