import { toast } from '@components/common'
import { useDiscardRunMutation } from '@api'
import { Dispatch, SetStateAction, useState } from 'react'
import { FormSubmitEvent } from 'types'

interface DiscardRunHookProps {
  assetCompanyID: number
}
interface IDiscardRunHook {
  handleDiscardRunSubmit: (e: FormSubmitEvent) => Promise<void>
  isDiscardModalOpen: boolean
  setIsDiscardModalOpen: Dispatch<SetStateAction<boolean>>
}

export default function useDiscardRunHook({
  assetCompanyID,
}: DiscardRunHookProps): IDiscardRunHook {
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false)

  const [discardRunMutation] = useDiscardRunMutation()

  async function handleDiscardRunSubmit(e: FormSubmitEvent): Promise<void> {
    e.preventDefault()

    try {
      await discardRunMutation(assetCompanyID).unwrap()

      toast.success(`Run successfully discarded.`)
    } catch (error: any) {
      toast.error(error.data)
    } finally {
      setIsDiscardModalOpen(false)
    }
  }

  return {
    handleDiscardRunSubmit,
    isDiscardModalOpen,
    setIsDiscardModalOpen,
  }
}
