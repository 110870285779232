import { useFields } from '@hooks'
import { useEffect, useMemo } from 'react'
import { Column, SortingRule, useFlexLayout, useSortBy, useTable } from 'react-table'
import {
  Button,
  ButtonGroup,
  Card,
  Flexbox,
  Icon,
  Modal,
  Search,
  Table,
  Text,
} from '@components/common'
import { Vendor } from '@api/models'
import Pagination from '@components/Pagination'
import { VendorsHook } from '../useVendors'
import { VENDOR_FORM_ID } from '@constants'
import VendorForm from '../VendorForm'
import VendorsTableLoading from './VendorsTableLoading'

const VendorsTable = ({
  pageNumber,
  itemsPerPage,
  handleSearch,
  searchValue,
  changePage,
  sortByInitialState,
  updateSortSearchParam,
  //
  searchKey,
  vendors,
  isLoadingVendors,
  isErrorFetchingVendors,
  isRefetchingVendors,

  handleDeleteVendor,
  isLoadingDeleteVendor,
  openDeleteVendorModal,
  selectedVendor,
  deleteVendorModalIsOpen,
  closeDeleteVendorModal,
  openEditVendorModal,
  closeEditVendorModal,
  editVendorModalIsOpen,
  handleEditVendor,
  isLoadingEditVendor,
  canEdit,
}: VendorsHook) => {
  // Declare columns
  const { fields, isLoading: isLoadingFields, isError: isErrorGettingFields } = useFields()

  const columns = useMemo<Column<Vendor>[]>(
    () => [
      {
        Header: fields.VN_VEN_ID,
        accessor: 'VN_VEN_ID',
      },
      {
        Header: fields.VN_NAME,
        accessor: 'VN_NAME',
      },
      {
        Header: '',
        accessor: 'VN_CNTY',
        disableSortBy: true,
        Cell: ({ row }) => (
          <Flexbox alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
            <ButtonGroup hidden={!canEdit}>
              <Button
                icon={<Icon name="Edit" />}
                size="compact"
                onClick={e => {
                  e.stopPropagation()
                  openEditVendorModal(row.original)
                }}
              >
                Edit
              </Button>

              <Button
                icon={<Icon name="Trash2" />}
                size="compact"
                intent="danger"
                onClick={e => {
                  e.stopPropagation()
                  openDeleteVendorModal(row.original)
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Flexbox>
        ),
      },
    ],
    [isLoadingFields]
  )

  // Declare react table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: vendors ? vendors.list : [],
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        sortBy: sortByInitialState() as SortingRule<Vendor>[],
      },
    },
    useSortBy,
    useFlexLayout
  )

  // Update sort param
  useEffect(() => {
    updateSortSearchParam(sortBy, setSortBy)
  }, [sortBy])

  // Returns
  const isTableLoading = isLoadingVendors || isLoadingFields
  const isError = isErrorFetchingVendors || isErrorGettingFields

  if (isTableLoading) {
    return <VendorsTableLoading />
  }

  if (isError) {
    return <Table.NoResults heading="There was an error." />
  }

  return (
    <>
      <Modal
        isOpen={deleteVendorModalIsOpen}
        close={closeDeleteVendorModal}
        title={`Delete vendor ${selectedVendor?.VN_VEN_ID} (${selectedVendor?.VN_NAME})`}
        width={540}
        action={() => handleDeleteVendor(selectedVendor)}
        actionLabel={`Delete vendor`}
        intent="danger"
        actionAutoFocus
        isActionLoading={isLoadingDeleteVendor}
      >
        <Text p>
          Are you sure you want to delete vendor{' '}
          <strong>
            {selectedVendor?.VN_VEN_ID} ({selectedVendor?.VN_NAME})
          </strong>
          ? <Text intent="danger">This cannot be undone</Text>.
        </Text>
      </Modal>

      <Modal
        isOpen={editVendorModalIsOpen}
        close={closeEditVendorModal}
        title={`Edit vendor ${selectedVendor?.VN_VEN_ID} (${selectedVendor?.VN_NAME})`}
        width={576}
        actionLabel="Save changes"
        form={VENDOR_FORM_ID}
        isActionLoading={isLoadingEditVendor}
        actionDisabled={!canEdit}
        dismissLabel={canEdit ? 'Discard changes' : 'Close'}
      >
        <VendorForm
          isEdit={true}
          initialValues={selectedVendor}
          handleSubmit={handleEditVendor}
          canEdit={canEdit}
        />
      </Modal>

      <Card spacing="none">
        <Table.Actions>
          <Flexbox>
            <Search
              onChange={handleSearch}
              placeholder="Search by vendor name..."
              name={searchKey}
              width={'640px'}
              value={searchValue}
            />
          </Flexbox>
        </Table.Actions>
        {vendors.list.length === 0 ? (
          <Table.NoResults text="" />
        ) : (
          <>
            <Table
              {...getTableProps()}
              aria-label="Company Table"
              label="Company Table"
              aria-colcount={columns.length}
              aria-rowcount={vendors.list.length}
              height="calc(100vh - 280px)"
            >
              <Table.Head sticky>
                {isRefetchingVendors ? (
                  <Table.LoadingRows />
                ) : (
                  headerGroups.map((headerGroup, i) => (
                    <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, i) => (
                        <Table.Header
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          column={column}
                          key={i}
                          tabIndex={0}
                        >
                          {column.render('Header')}
                        </Table.Header>
                      ))}
                    </Table.HeadRow>
                  ))
                )}
              </Table.Head>

              <Table.Body {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Table.Row
                      {...row.getRowProps()}
                      onClick={() => {
                        openEditVendorModal(row.original)
                      }}
                      tabIndex={0}
                      key={i}
                    >
                      {row.cells.map(cell => (
                        <Table.Cell {...cell.getCellProps()} column={cell.column}>
                          {cell.render('Cell')}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <Pagination
              changePage={changePage}
              totalItems={vendors.count}
              maxItemsPerPage={itemsPerPage}
              currentPageNumber={pageNumber}
              label={`Showing ${vendors.list.length} of ${vendors.count} record${
                vendors.count > 1 ? 's' : ''
              }`}
            />
          </>
        )}
      </Card>
    </>
  )
}
export default VendorsTable
