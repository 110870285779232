import { AssetRegisterSectionOption } from '@api/models'
import { useState } from 'react'

interface UseSelectedFieldsAndComments {
  selectedFields: string[]
  selectedComments: string[]
  handleFieldCheckboxChange: (fieldName: string) => void
  handleCommentsCheckboxChange: (fieldName: string) => void
}

interface UseSelectedFieldsAndCommentsProps {
  sectionsInitialValues: AssetRegisterSectionOption
  sectionCommentsInitialValues: AssetRegisterSectionOption
}

export function useSelectedFieldsAndComments({
  sectionsInitialValues,
  sectionCommentsInitialValues,
}: UseSelectedFieldsAndCommentsProps): UseSelectedFieldsAndComments {
  const initialFields = extractSelectedKeys(sectionsInitialValues)
  const initialComments = extractSelectedKeys(sectionCommentsInitialValues)

  const [selectedFields, setSelectedFields] = useState(initialFields)
  const [selectedComments, setSelectedComments] = useState(initialComments)

  function extractSelectedKeys(initialValues) {
    if (!initialValues) return []

    const selectedKeys = []
    for (const [key, value] of Object.entries(initialValues)) {
      if (value) {
        selectedKeys.push(key)
      }
    }
    return selectedKeys
  }

  const handleFieldCheckboxChange = fieldName => {
    if (selectedFields.includes(fieldName)) {
      setSelectedFields(selectedFields.filter(field => field !== fieldName))
      if (selectedComments.includes(fieldName)) {
        setSelectedComments(selectedComments.filter(field => field !== fieldName))
      }
    } else {
      setSelectedFields(prevState => [...prevState, fieldName])
    }
  }

  const handleCommentsCheckboxChange = fieldName => {
    if (selectedFields.includes(fieldName) && selectedComments.includes(fieldName)) {
      setSelectedComments(selectedComments.filter(field => field !== fieldName))
    } else {
      setSelectedComments(prevState => [...prevState, fieldName])
    }
  }

  return {
    selectedFields,
    selectedComments,
    handleFieldCheckboxChange,
    handleCommentsCheckboxChange,
  }
}
