import { SKELETON_SHINE } from '@styles-api/css-constants'
import { Palette } from '@styles-api/palette.types'
import { StyleObject } from '@styles-api/css.types'

export const checkbox__wrapperStyles: StyleObject = {}

export const container = ({ grouped }) => {
  let marginBottom
  if (grouped) marginBottom = 'var(--scale200)'
  return {
    display: grouped ? 'flex' : 'inline-flex',
    alignItems: 'center',
    width: 'auto',
    cursor: 'pointer',
    position: 'relative',
    marginBottom,
  }
}
export const checkboxBase: StyleObject = {
  height: 'var(--scale500)',
  width: 'var(--scale500)',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: '1px',
  outlineOffset: '1px',
  borderRadius: '2px',

  boxShadow: 'var(--input-inset-shadow)',
}
export const getCheckboxState = ({ checked, hover }) => {
  let bg: Palette

  let borderColor: Palette

  if (checked) {
    if (hover) {
      bg = 'var(--primary-color500)'
      borderColor = 'var(--primary-color500)'
    } else {
      bg = 'var(--primary-color)'
      borderColor = 'var(--primary-color)'
    }
  } else if (!checked) {
    bg = 'var(--base-color)'
    if (!hover) {
      borderColor = 'var(--shade400)'
    } else {
      borderColor = 'var(--primary-color)'
    }
  }

  return {
    backgroundColor: bg,
    borderColor: borderColor,
  }
}
export const getFocus = ({ focus }) => {
  if (focus) {
    return {
      boxShadow: 'var(--focus-shadow)',
    }
  }
}
export const checkmark: StyleObject = {
  height: 'var(--scale550)',
  width: 'var(--scale550)',
  color: 'var(--interaction-contrast)',
  marginTop: '0px',
  strokeWidth: '3px',
}
export const nativeInput: StyleObject = {
  position: 'absolute',
  opacity: 0,
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
}
export const label: StyleObject = {
  paddingLeft: 'var(--scale300)',
  fontSize: 'var(--base-font-size)',
  fontWeight: 'var(--font-weight-normal)',
  lineHeight: 1,
}

// skeleton styles
export const containerSkeleton: StyleObject = {
  display: 'inline-flex',
  alignItems: 'center',
  width: 'auto',
  height: 'var(--scale800)',
}
export const checkboxSkeleton: StyleObject = {
  height: 'var(--scale600)',
  width: 'var(--scale600)',
  borderRadius: 'var(--radius1)',
  ...SKELETON_SHINE,
}
export const labelSkeleton: StyleObject = {
  height: 'var(--scale600)',
  width: '100px',
  marginLeft: 'var(--scale300)',
  ...SKELETON_SHINE,
}
