import React, { useRef, useState, useEffect } from 'react'
import { SideSheetProps } from './SideSheet.types'
import { createPortal } from 'react-dom'

import { usePortal, useOnClickOutside } from '@hooks'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import { OVERLAY, overflowShadow } from '@styles-api/css-constants'
import { AnimatePresence, motion } from 'framer-motion'

import SideSheetHeader from './SideSheetHeader'
import SideSheetFooter from './SideSheetFooter'
import DetectScrollOverflow from '@utils/DetectScrollOverflow'
import FocusTrap from 'focus-trap-react'

const SideSheet: React.FC<SideSheetProps> = ({
  isOpen,
  close,
  title,

  width,
  intent,
  action,
  actionLabel = 'Apply',
  dismissLabel = 'Cancel',
  actionDisabled,
  dismissAction,
  ...props
}) => {
  // define sidesheet portal root
  const portal = usePortal('sidesheet-root')
  // click outside to close
  const node = useRef()
  useOnClickOutside(node, () => close())
  // close with ESC key
  useEffect(() => {
    const closeEsc = e => {
      if (e.key === 'Escape') {
        close()
      }
    }
    window.addEventListener('keydown', closeEsc)
    return () => window.removeEventListener('keydown', closeEsc)
  }, [])

  // styles
  const [css] = useStyletron()
  let overlayStyles = css(OVERLAY)
  let containerStyles = css(styles.container)

  const [canScrollRight, setCanScrollRight] = useState(false)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollUp, setCanScrollUp] = useState(false)
  const [canScrollDown, setCanScrollDown] = useState(false)
  let overflowShadowStyles = css({
    ...overflowShadow({
      canScrollDown,
      canScrollLeft,
      canScrollRight,
      canScrollUp,
    }),
  })

  if (!portal) return null
  return createPortal(
    <AnimatePresence initial={false}>
      {isOpen && (
        <React.Fragment>
          <motion.div
            className={overlayStyles}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 0.1,
              transition: { duration: 0.08 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.12 },
            }}
          />
          <FocusTrap focusTrapOptions={{ initialFocus: false }}>
            <motion.aside
              className={containerStyles}
              initial={{ translateX: 380 }}
              animate={{
                translateX: 0,
                transition: { type: 'spring', stiffness: 1200, damping: 85 },
              }}
              exit={{
                translateX: 380,
                transition: {
                  duration: 0.16,
                },
              }}
              ref={node}
              role="dialog"
              aria-modal="true"
              tabIndex={-1}
              aria-label={props['aria-label']}
            >
              {title && <SideSheetHeader title={title} close={close} />}

              <DetectScrollOverflow
                onStateChange={state => {
                  setCanScrollRight(state.canScroll.right)
                  setCanScrollLeft(state.canScroll.left)
                  setCanScrollUp(state.canScroll.up)
                  setCanScrollDown(state.canScroll.down)
                }}
                style={{
                  maxHeight: 'calc(100vh - var(--topbar-height) - var(--scale1600))',
                }}
                {...props}
              >
                <div className={overflowShadowStyles} />
                <DetectScrollOverflow.Content
                  style={{ position: 'relative' }}
                  className={css(styles.content)}
                >
                  {props.children}
                </DetectScrollOverflow.Content>
              </DetectScrollOverflow>

              <SideSheetFooter
                action={action}
                actionLabel={actionLabel}
                close={close}
                dismissLabel={dismissLabel}
                intent={intent}
              />
            </motion.aside>
          </FocusTrap>
        </React.Fragment>
      )}
      ,
    </AnimatePresence>,

    portal
  )
}

export default SideSheet
