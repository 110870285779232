import React, { FC } from 'react'
import { useStyletron } from 'styletron-react'
import { Toast as ToastType } from '../Toasts.types'

import * as styles from './Styles'

import { toastIcon as TIcon } from './Icon'
import { Icon } from '@common'
import PassClassDown from '@utils/PassClassDown'

export interface IToast extends ToastType {
  onClose: (id: string) => void
}

const Toast: FC<IToast> = ({
  id,
  content,
  type,
  config: { backgroundColor, color } = {},
  onClose,
}) => {
  const [css] = useStyletron()

  let wrapperStyles = css({
    ...styles.base,
    ...styles.getType({ type }),
  })
  let contentStyles = css({ ...styles.content })
  let iconStyles = css({ ...styles.icon })
  let dismissStyles = css({ ...styles.dismissButton })

  return (
    <div className={wrapperStyles} role="alert">
      <div className={contentStyles}>
        <PassClassDown className={iconStyles}>{TIcon({ type })}</PassClassDown>
        {content}
      </div>

      <button onClick={() => onClose(id)} className={dismissStyles}>
        <Icon name="X" className={css(styles.dismissIcon)} />
      </button>
    </div>
  )
}

export default Toast
