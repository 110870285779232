import {
  Card,
  TableSkeleton
} from '@components/common'

const OrganizationTableLoading: React.FC = () => {
  return (
    <Card spacing="none" overflow="hidden">
      <TableSkeleton rows={1} />
    </Card>
  )
}

export default OrganizationTableLoading
