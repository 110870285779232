import { useListDepreciationsQuery } from '@api/index'
import { DepreciationProfileCode } from '@api/models'
import {
  Button,
  Flexbox,
  Form,
  Grid,
  Heading,
  Icon,
  Select,
  Spacer,
} from '@components/common'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { getLastCharacterInString } from '@utils/basic'
import { useState } from 'react'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Bar,
  AreaChart,
  Area,
} from 'recharts'
import { ChartProps, DashboardFilterField } from './DashboardFilters/DashboardFilter.types'
import { FormProvider } from 'react-hook-form'
import useDashboardFilters, { nFormatter } from './DashboardFilters/useDashboardFilters'
import DashboardItem from './DashboardItem'
import { helpText } from './helpText'

const Dashboard = () => {
  const [assetsGrow] = useState(true) // 15% growth

  const [filterState, setFilterState] = useState<DashboardFilterField[]>([
    {
      Name: 'Quarters',
      List: null,
      Label: 'Last 4 Quarters',
      Type: 'quarters',
      Amount: '4',
    },
    {
      Name: 'AS_CAT_ID',
      List: ['All'],
      Type: 'options',
      Options: 'DepreciationTypeA',
      Amount: null,
    },
  ])

  const {
    methods,

    getMultiSelectValue,
    handleMultiSelectChange,

    handleDashboardFiltersSubmit,
    handleSelectAmountChange,
    getSelectValue,

    // nFormatter,

    // Assets
    assetCount,
    isFetchingDashboardAssets,
    // isSuccessGettingDashboardAssets,
    filteredAssetsNumber,

    // Acquisition
    acquisitionData,
    totalAcquisition,

    // Depreciation
    // depreciationData,
    // totalDepreciation,

    // Book Value
    bookValueData,
    totalBookValue,
  } = useDashboardFilters({
    filterState,
    setFilterState,
  })

  const renderChart = ({ type, chartType, dataKeys, colors, isStacked }: ChartProps) => {
    let ChartComponent
    let ChartElement

    switch (chartType) {
      case 'Line':
        ChartComponent = LineChart
        ChartElement = Line
        break
      case 'Bar':
        ChartComponent = BarChart
        ChartElement = Bar
        break
      case 'Area':
        ChartComponent = AreaChart
        ChartElement = Area
        break
      default:
        throw new Error(`Invalid chartType: ${chartType}`)
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <ChartComponent data={type}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={nFormatter} />
          <Tooltip formatter={value => value && value.toLocaleString()} />
          <Legend />
          {dataKeys.map((key, index) => (
            <ChartElement
              key={key as string}
              type="monotone"
              dataKey={key}
              stroke={colors[index]}
              fill={colors[index]}
              stackId={isStacked && index >= 1 ? 'a' : null}
            />
          ))}
        </ChartComponent>
      </ResponsiveContainer>
    )
  }

  // Assets
  const renderAssetsChart = (type: any[]) =>
    renderChart({
      type,
      chartType: 'Line',
      dataKeys: ['Active', 'DisposedAndPending'],
      colors: ['#2ea44f', '#e11900'],
    })
  const renderAssetsBarChart = (type: any[]) =>
    renderChart({
      type,
      chartType: 'Bar',
      dataKeys: ['Active', 'DisposedAndPending'],
      colors: ['#2ea44f', '#e11900'],
    })

  // Acquisition
  const renderAcquisitionBarChart = (type: any[]) =>
    renderChart({
      type,
      chartType: 'Bar',
      dataKeys: ['Active', 'Disposed'],
      colors: ['#2ea44f', '#e11900'],
    })

  // Book Value
  const renderBookValueChart = (type: any[]) =>
    renderChart({
      type,
      chartType: 'Bar',
      dataKeys: ['Acquisition', 'BookValue', 'LTDDepreciation'],
      colors: ['#2ea44f', '#0070c9', '#ffa500'],
      isStacked: true,
    })

  const categoryIDFilter = filterState.filter(filter => filter.Name === 'AS_CAT_ID')[0]

  // Depreciations (For Category ID filter options)
  const {
    data: depreciations,
    isLoading: isLoadingDepreciations,
    isSuccess: isSuccessGettingDepreciations,
  } = useListDepreciationsQuery({
    profile: categoryIDFilter?.Options
      ? (getLastCharacterInString(categoryIDFilter?.Options) as DepreciationProfileCode)
      : 'A',
  })
  const depreciationOptions: ReactSelectOptions = []
  if (isSuccessGettingDepreciations) {
    depreciationOptions.push({
      label: 'All Categories',
      value: 'All',
    })
    for (const depreciation of depreciations) {
      depreciationOptions.push({
        label: `${depreciation.ID} - ${depreciation.Name}`,
        value: depreciation.ID,
      })
    }
  }

  let options: ReactSelectOptions = depreciationOptions

  // this is to check if categoryID is only one, then I'll show the data for that categoryID
  let isOneCategory = false

  if (filterState[1] && filterState[1].List && filterState[1].List[0]) {
    isOneCategory =
      filterState[1].List[0].valueOf() !== 'All' && filterState[1].List.length === 1
  }

  return (
    <>
      <header>
        <Heading type="h1">Dashboard</Heading>
      </header>

      <Spacer y="default" />

      {/* Filters */}
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleDashboardFiltersSubmit)}>
          <Flexbox alignItems="end" justifyContent="space-between">
            <Select
              id="QuartersSelect"
              name="report-filter-to-val-0"
              type="default"
              options={[
                { label: 'Last 4 Quarters', value: '4' },
                { label: 'Last 5 Quarters', value: '5' },
                { label: 'Last 6 Quarters', value: '6' },
                { label: 'Last 7 Quarters', value: '7' },
                { label: 'Last 8 Quarters', value: '8' },
                { label: 'Last 9 Quarters', value: '9' },
                { label: 'Last 10 Quarters', value: '10' },
                { label: 'Last 11 Quarters', value: '11' },
                { label: 'Last 12 Quarters', value: '12' },
              ]}
              value={getSelectValue(0)}
              onChange={e => handleSelectAmountChange(e, 0)}
              placeholder="Select an option"
              width="33%"
            />

            <Icon
              name="Calendar"
              style={{
                position: 'relative',
                right: '50px',
                bottom: '4px',
                cursor: 'pointer',
              }}
            ></Icon>

            <Select
              type="multi"
              options={options}
              value={getMultiSelectValue(1)}
              placeholder="Select a Category ID"
              flexGrow
              onChange={e => {
                handleMultiSelectChange(e, 1)
              }}
              name={`report-filter-multi-val-${1}`}
            />

            <Button type={'submit'} appearance={'primary'} style={{ marginLeft: '20px' }}>
              Apply
            </Button>
          </Flexbox>
        </Form>
      </FormProvider>

      <Spacer y="default" />

      {/* Charts */}
      <Grid>
        <DashboardItem
          title={`Total Number of Assets ${
            isOneCategory ? `For Category: ${categoryIDFilter?.List[0]}` : 'Per Category'
          }`}
          count={assetCount}
          growth={assetsGrow}
          renderChart={() =>
            isOneCategory
              ? renderAssetsChart(filteredAssetsNumber)
              : renderAssetsBarChart(filteredAssetsNumber)
          }
          gridSize={{ s: 12, m: 6, l: 6 }}
          isFetchingDashboardAssets={isFetchingDashboardAssets}
          isLoadingDepreciations={isLoadingDepreciations}
        />

        <DashboardItem
          title="Acquisitions and Disposals Per Quarter"
          count={totalAcquisition}
          growth={assetsGrow}
          icon="DollarSign"
          renderChart={() => renderAcquisitionBarChart(acquisitionData)}
          gridSize={{ s: 12, m: 6, l: 6 }}
          isFetchingDashboardAssets={isFetchingDashboardAssets}
          isLoadingDepreciations={isLoadingDepreciations}
          helpIcon
          helpText={helpText.AcquisitionCost.text}
        />

        <DashboardItem
          title="Book Value"
          count={totalBookValue}
          growth={assetsGrow}
          icon="DollarSign"
          renderChart={() => renderBookValueChart(bookValueData)}
          gridSize={{ s: 12, m: 12, l: 12 }}
          isFetchingDashboardAssets={isFetchingDashboardAssets}
          isLoadingDepreciations={isLoadingDepreciations}
          helpIcon
          helpText={helpText.BookValue.text}
        />
      </Grid>
    </>
  )
}
export default Dashboard
