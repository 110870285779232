import { Button, Card, Text, Spacer, Note } from '@components/common'
import { CompanyDetailsCardsState, CompanyHookContext } from '@hooks/useCompany'
import { Dispatch, SetStateAction } from 'react'

export interface DeprecClassFormSectionProps {
  context: CompanyHookContext
  companyHasHistory: boolean
  companyHasPeriods: boolean
  cardsState: CompanyDetailsCardsState
  setCardsState: Dispatch<SetStateAction<CompanyDetailsCardsState>>
  isEdited: boolean
  title: string
  formElement: JSX.Element
  displayElement: JSX.Element
  stateKey: keyof CompanyDetailsCardsState
  canEdit: boolean
}
const CompanyFormSection = ({
  context,
  companyHasHistory,
  companyHasPeriods,
  cardsState,
  setCardsState,
  isEdited,
  title,
  formElement,
  displayElement,
  stateKey,
  canEdit,
}: DeprecClassFormSectionProps) => {
  const cardAddOn = () => {
    if (!canEdit) return <></>

    if (context === 'create-company') return null

    if (stateKey === 'isDeprecInfoCardOpen' && (companyHasHistory || companyHasPeriods))
      return null

    if (isEdited) return <Text light>Edited</Text>

    return (
      <Button
        appearance="text"
        onClick={() =>
          setCardsState(prevState => {
            return {
              ...prevState,
              [stateKey]: !prevState[stateKey],
            }
          })
        }
      >
        {cardsState[stateKey] ? 'Close' : 'Edit'}
      </Button>
    )
  }
  return (
    <Card title={title} addon={cardAddOn()}>
      {cardsState[stateKey] ? formElement : displayElement}
      {stateKey === 'isDeprecInfoCardOpen' && (companyHasHistory || companyHasPeriods) && (
        <>
          <Spacer y="default" />
          <Note
            content={
              'You cannot edit the depreciation information for a company that has generated periods or depreciation history.'
            }
          />
        </>
      )}
    </Card>
  )
}

export default CompanyFormSection
