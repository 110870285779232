import { REQUIRED_FIELD, max_string_msg } from '@forms/messages'
import * as Yup from 'yup'

export interface UploadAttachmentPayload {
  file: any
  assetCompanyID: number
  objectIDs: string[]
  type: AttachmentType
  comment: string
}

export interface GetAssetAttachmentsPayload {
  assetCompanyID: number
  assetID: string
}

export interface DeleteAttachmentPayload {
  id: string
  type: AttachmentType
}

export interface EditAttachmentPayload {
  ID: string
  Comment: string
}

export interface Attachment {
  ID: string
  Comment: string
  FileName: string
  Type: AttachmentType
}

export type AttachmentType =
  | 'Asset'
  | 'Service'
  | 'Task/Activity'
  | 'Insurance'
  | 'Maintenance'

export const addAttachmentSchema = Yup.object({
  comment: Yup.string().required(REQUIRED_FIELD).max(50, max_string_msg(50)),
})

export const editAttachmentSchema = Yup.object({
  Comment: Yup.string().required(REQUIRED_FIELD).max(50, max_string_msg(50)),
})
