// TODO: ACQUISITION COST + ANY CAPITALIZATIONS

import {
  Button,
  Card,
  CustomDatePicker,
  Fieldset,
  Flexbox,
  Form,
  Grid,
  Input,
  Note,
  Select,
  Spacer,
  Spinner,
  Text,
  TextSkeleton,
  useModal,
} from '@components/common'
import {
  useAssetDisposalMutation,
  useGetAssetDepreciationQuery,
  useGetCompanyQuery,
  useGetDisposalDataQuery,
  useGetDisposalProjectionsQuery,
  useGetSettingsQuery,
  useGetYearsQuery,
} from '@api'

import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { ASSET_DISPOSAL_FORM_ID } from '@constants'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  AssetDisposalFields,
  assetDisposalSchema,
  DisposalProjectionsParams,
} from '@api/assets/models'

import { Controller, useForm } from 'react-hook-form'
import { useFields, useFormFieldProps } from '@hooks'
import { useEffect, useState } from 'react'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { formatCurrency, formatDisplayDate } from '@utils/basic'
import { useNavigate, useParams } from 'react-router-dom'
import InlineCommentEditor from '@components/CommentEditor/InlineCommentEditor'
import stopPropagation from '@utils/stopPropagation'
import Template from '@components/layout/Template'

// interface Props {
//   assetCompanyID: number
//   assetID: string
// }

const AssetDisposalForm = () => {
  const [showProjections, setShowProjections] = useState<boolean>(false)

  const params = useParams()
  const assetCompanyID = parseInt(params.companyID)
  const assetID = params.assetID
  const { data: asset } = useGetAssetQuery({
    assetCompanyID,
    assetID,
  })
  const navigate = useNavigate()
  const { modalIsOpen, triggerModal, closeModal } = useModal()

  //Getting Asset Fields
  const { fields } = useFields()

  //  Get disposal data
  const {
    data: disposalData,
    isLoading: isLoadingDisposalData,
    isSuccess: isSuccessGettingDisposalData,
  } = useGetDisposalDataQuery({ assetCompanyID, assetID })

  // We need company data for number of periods
  const {
    data: company,
    isLoading: isLoadingCompany,
    isSuccess: isSuccessLoadingCompany,
  } = useGetCompanyQuery(assetCompanyID)
  let periodOptions: ReactSelectOptions = []
  // map number of periods to create period select options
  if (isSuccessLoadingCompany) {
    periodOptions = [...Array(company.CM_NR_PER).keys()].map((_, i) => ({
      label: (i + 1).toString(),
      value: i + 1,
    }))
  }

  // We need asset deprec to detect of years or units of production
  const {
    data: assetDepreciation,
    isLoading: isLoadingAssetDepreciation,
  } = useGetAssetDepreciationQuery({
    assetCompanyID,
    assetID,
    profile: 'A',
  })

  // Get years
  const {
    data: years,
    isLoading: isLoadingYears,
    isSuccess: isSuccessLoadingYears,
  } = useGetYearsQuery({ assetCompanyID })
  const yearsOptions: ReactSelectOptions = []
  if (isSuccessLoadingYears) {
    for (const year of years) {
      const dateInServiceYear = new Date(asset.AS_REC_DAT).getFullYear()
      if (year >= dateInServiceYear) {
        yearsOptions.push({
          label: year.toString(),
          value: year,
        })
      }
    }
  }

  // Register react hook form
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<AssetDisposalFields>({
    mode: 'onSubmit',
    resolver: yupResolver(
      assetDisposalSchema(disposalData?.AssetDisposalParams.IsPeriodBased)
    ),
    defaultValues: disposalData?.AssetDisposalParams,
  })

  const projectionParams: DisposalProjectionsParams = {
    assetCompanyID,
    assetID,
    period: watch('Period'),
    year: watch('Year'),
    realizedAmount: watch('RealizedAmount'),
    disposalSoldTo: watch('DisposalSoldTo'),
    disposalDate: watch('DisposalDate'),
  }

  // Get projection data
  const {
    data: disposalProjections,
    isLoading: isLoadingDisposalProjections,
    isFetching: isFetchingDisposalProjections,
  } = useGetDisposalProjectionsQuery(projectionParams, {
    skip: !isSuccessGettingDisposalData || !showProjections,
  })

  // Reset form when data loads
  useEffect(() => {
    if (isSuccessGettingDisposalData) {
      reset(disposalData.AssetDisposalParams)
    }
  }, [isLoadingDisposalData])

  // Hook for inputs
  const {
    getInputProps,
    getSelectProps,
    getDateProps,
  } = useFormFieldProps<AssetDisposalFields>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  // Handle submit
  const [runDisposal] = useAssetDisposalMutation({ fixedCacheKey: 'runAssetDisposal' })

  const { data: DateFormat } = useGetSettingsQuery('DateFormat')
  const [formErrorMsg, setFormErrorMsg] = useState('')

  async function onSubmitHandler(values: AssetDisposalFields) {
    try {
      await runDisposal({
        ...values,
        AssetCompanyID: assetCompanyID,
        AssetID: assetID,
        IsPeriodBased: disposalData?.AssetDisposalParams.IsPeriodBased,
      }).unwrap()
      navigate('..') //To reroute back to main page
    } catch (error: any) {
      setFormErrorMsg(error.data)
    }
  }

  const isLoading: boolean =
    isLoadingDisposalData ||
    isLoadingCompany ||
    isLoadingYears ||
    isLoadingAssetDepreciation

  if (isLoading)
    return (
      <Flexbox justifyContent="center" alignItems="center" height={148}>
        <Spinner />
      </Flexbox>
    )

  const isPeriodBased: boolean = company.CM_YTD_BCD === 'P'

  return (
    <>
      {modalIsOpen && (
        <InlineCommentEditor
          title={'Edit Disposal Comment'}
          assetCompanyID={assetCompanyID}
          type={'AD'}
          objectID={assetID}
          isOpen={modalIsOpen}
          close={closeModal}
          key1={'00000'}
          key2={'00000'}
        />
      )}
      <Template maxWidth="compact">
        <Button
          intent="default"
          onClick={e => {
            stopPropagation(e)
            // setSearchParams({ id: props.row.original.CH_SYS_NR.toString() })
            triggerModal()
          }}
          size="compact"
        >
          Comments
        </Button>
        <Spacer y="mini" />
        <Card title={`Dispose Asset - ${assetID} : ${asset.AS_DESC}`}>
          <Form id={ASSET_DISPOSAL_FORM_ID} onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid>
              {formErrorMsg !== '' ? (
                <Grid.Item>
                  <Note intent="danger" content={<>{formErrorMsg}</>} />
                </Grid.Item>
              ) : (
                ''
              )}
              <Grid.Item>
                <Fieldset legend="Current status">
                  <ul>
                    <li>
                      <Flexbox justifyContent="space-between" alignItems="center">
                        <span>{fields.AS_REC_DAT}:</span>
                        {formatDisplayDate(asset.AS_REC_DAT, DateFormat)}
                      </Flexbox>
                    </li>
                    <li>
                      <Flexbox justifyContent="space-between" alignItems="center">
                        <span>{fields.AS_FIN_COS}:</span>
                        {formatCurrency(asset.AS_FIN_COS)}
                      </Flexbox>
                    </li>
                    <li>
                      <Flexbox justifyContent="space-between" alignItems="center">
                        <span>
                          {assetDepreciation.DP_MET_CD === 'P' ? 'Units' : 'Years'}:
                        </span>
                        {disposalData.AssetDisposalStatus.LifeTime}
                      </Flexbox>
                    </li>
                    <li>
                      <Flexbox justifyContent="space-between" alignItems="center">
                        <span>{fields.AS_FIN_SAL}:</span>
                        {formatCurrency(disposalData.AssetDisposalStatus.SalvageAmount)}
                      </Flexbox>
                    </li>
                  </ul>
                </Fieldset>
              </Grid.Item>
              <Grid.Item>
                <Fieldset legend="Disposal parameters">
                  <Grid>
                    {isPeriodBased ? (
                      <>
                        <Grid.Item s={6}>
                          <Controller
                            control={control}
                            name="Year"
                            render={({ field }) => (
                              <Select
                                {...getSelectProps('Year', field, yearsOptions)}
                                isSearchable
                                label="Year"
                                autoFocus
                              />
                            )}
                          />
                        </Grid.Item>
                        <Grid.Item s={6}>
                          <Controller
                            control={control}
                            name="Period"
                            render={({ field }) => (
                              <Select
                                {...getSelectProps('Period', field, periodOptions)}
                                isSearchable
                                label="Period"
                              />
                            )}
                          />
                        </Grid.Item>
                      </>
                    ) : (
                      <Grid.Item>
                        <Controller
                          control={control}
                          name="DisposalDate"
                          render={({ field }) => (
                            <CustomDatePicker
                              {...getDateProps('DisposalDate', field)}
                              min={formatDisplayDate(asset.AS_REC_DAT, DateFormat)}
                              label="Disposal date"
                              autoFocus
                            />
                          )}
                        />
                      </Grid.Item>
                    )}

                    <Grid.Item>
                      <Input
                        {...getInputProps('RealizedAmount', 'currency')}
                        label={`Realized amount`}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Input
                        {...getInputProps('DisposalSoldTo')}
                        label="Disposal/sold to"
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>
              <Grid.Item>
                <Flexbox alignItems="flex-end">
                  <Button
                    onClick={() => setShowProjections(!showProjections)}
                    appearance="text"
                  >
                    {showProjections
                      ? 'Hide corporate projections'
                      : 'Show corporate projections'}
                  </Button>
                </Flexbox>
                <Flexbox justifyContent="flex-end">
                  <Button
                    intent="danger"
                    type="submit"
                    appearance="primary"
                    style={{ marginLeft: 'auto' }}
                  >
                    Dispose Asset
                  </Button>
                </Flexbox>
              </Grid.Item>

              {showProjections && (
                <Grid.Item>
                  <Fieldset legend="Corporate projections">
                    <ul>
                      <li>
                        <Flexbox justifyContent="space-between" alignItems="center">
                          <span>Life-to-date:</span>
                          {isLoadingDisposalProjections || isFetchingDisposalProjections ? (
                            <TextSkeleton width={'64px'} />
                          ) : (
                            formatCurrency(disposalProjections.LTDD)
                          )}
                        </Flexbox>
                      </li>
                      <li>
                        <Flexbox justifyContent="space-between" alignItems="center">
                          <span>Net book value:</span>
                          {isLoadingDisposalProjections || isFetchingDisposalProjections ? (
                            <TextSkeleton width={'64px'} />
                          ) : (
                            formatCurrency(disposalProjections.NBV)
                          )}
                        </Flexbox>
                      </li>
                      <li>
                        <Flexbox justifyContent="space-between" alignItems="center">
                          <span>Projected gain/loss:</span>
                          {isLoadingDisposalProjections || isFetchingDisposalProjections ? (
                            <TextSkeleton width={'64px'} />
                          ) : (
                            <Text
                              intent={
                                disposalProjections.PGL > 0
                                  ? 'success'
                                  : disposalProjections.PGL === 0
                                  ? 'default'
                                  : 'danger'
                              }
                            >
                              {formatCurrency(disposalProjections.PGL)}
                            </Text>
                          )}
                        </Flexbox>
                      </li>
                    </ul>
                  </Fieldset>
                </Grid.Item>
              )}
            </Grid>
          </Form>
        </Card>
      </Template>
    </>
  )
}
export default AssetDisposalForm
