import React from 'react'

import Item from './Item'

import { Spacer, Text } from '@common'

import { usePositionReorder } from './usePositionReorder'
import { ColumnInstance } from 'react-table'
import { Asset, AssetKey } from '@api/models'

interface ChangeOrderProps {
  visibleColumns: ColumnInstance<Asset>[]
  setColumnOrder: (
    updater: string[] | ((columnOrder: string[]) => string[])
  ) => void

  fields
}

const ChangeOrder: React.FC<ChangeOrderProps> = ({
  visibleColumns,
  setColumnOrder,
  fields,
}) => {
  const visibleColumnsInitState: AssetKey[] = []

  for (const column of visibleColumns) {
    visibleColumnsInitState.push(column.id as AssetKey)
  }

  const [order, updatePosition, updateOrder] = usePositionReorder(
    visibleColumnsInitState
  )

  return (
    <>
      <Text small light>
        Drag and drop the items below to rearrange the column order (top to bottom
        = left to right).
      </Text>

      <Spacer y="mini" />
      <ul>
        {order.map((key: string, index: number) => (
          <Item
            name={fields[key]}
            key={key}
            index={index}
            updatePosition={updatePosition}
            updateOrder={updateOrder}
            setColumnOrder={setColumnOrder}
            order={order}
          />
        ))}
      </ul>
    </>
  )
}
export default ChangeOrder
