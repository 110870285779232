import { AssetCharge, FieldLabels } from '@api/models'
import {
  Button,
  Checkbox,
  CustomDatePicker,
  Divider,
  Fieldset,
  Flexbox,
  Form,
  Grid,
  Input,
  Select,
  Spacer,
  Spinner,
} from '@components/common'
import { ASSET_CHARGE_FORM_ID, DISPOSITION_OPTIONS } from '@constants'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import { isNullOrUndefined } from '@utils/basic'
import { useGetExtraChargeFieldsQuery } from '@api'
import stopPropagation from '@utils/stopPropagation'
import { useNavigate } from 'react-router-dom'

interface Props {
  fields: FieldLabels
  handleChargeFormSubmit: (payload: AssetCharge) => Promise<void>
  isCreate?: boolean
  EditingEnabled: boolean
  canEdit: boolean
}

const AssetLocationsForm = ({
  fields,
  handleChargeFormSubmit,
  isCreate,
  EditingEnabled,
  canEdit,
}: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
    handleSubmit,
    watch,
  } = useFormContext<AssetCharge>()

  const {
    getInputProps,
    getSelectProps,
    getCheckBoxProps,
    getCreatableSelectProps,
    getDateProps,
  } = useFormFieldProps<AssetCharge>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  const {
    data: extraFields,
    isLoading: isLoadingExtraFields,
  } = useGetExtraChargeFieldsQuery()

  const navigate = useNavigate()

  if (isLoadingExtraFields)
    return (
      <Flexbox height={240} justifyContent="center" alignItems="center">
        <Spinner />
      </Flexbox>
    )

  return (
    <Form
      id={ASSET_CHARGE_FORM_ID}
      noValidate
      onSubmit={handleSubmit(handleChargeFormSubmit)}
    >
      <Grid>
        <Grid.Item>
          <Input
            {...getInputProps('CH_DESC')}
            autoFocus
            label={fields[`CH_DESCL`]}
            readOnly={!canEdit}
          />
        </Grid.Item>
        <Grid.Item>
          <Controller
            control={control}
            name="CH_DATE"
            render={({ field }) => (
              <CustomDatePicker
                {...getDateProps('CH_DATE', field)}
                label={fields[`CH_DATEL`]}
                readOnly={!canEdit}
              />
            )}
          />
        </Grid.Item>
        <Grid.Item m={4}>
          <Input
            {...getInputProps('CH_COST', 'currency')}
            label={fields[`CH_COSTL`]}
            readOnly={!canEdit}
          />
        </Grid.Item>
        <Grid.Item m={8}>
          <Controller
            control={control}
            name="CH_DIS_CD"
            render={({ field }) => (
              <Select
                {...getSelectProps('CH_DIS_CD', field, DISPOSITION_OPTIONS)}
                disabled={!canEdit}
              />
            )}
          />
        </Grid.Item>
        <Grid.Item>
          <Controller
            control={control}
            name="CH_LOCT_CD"
            render={({ field }) => (
              <Select
                {...getCreatableSelectProps('CH_LOCT_CD', field, extraFields.locations)}
                menuPlacement="top"
                disabled={!canEdit}
              />
            )}
          />
        </Grid.Item>
        <Grid.Item m={6}>
          <Input {...getInputProps('CH_LOC_NR')} readOnly={!canEdit} />
        </Grid.Item>
        <Grid.Item m={6}>
          <Input {...getInputProps('CH_AUDITNR')} readOnly={!canEdit} />
        </Grid.Item>
        <Grid.Item>
          <Controller
            control={control}
            name="CH_REM_CD"
            render={({ field }) => (
              <Checkbox
                {...getCheckBoxProps(field)}
                label="Depreciate using remaining asset life"
                readOnly={!canEdit}
              />
            )}
          />
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="Post Codes">
            <Grid>
              <Grid.Item m={6}>
                <Controller
                  control={control}
                  name="CH_CAPC_ID"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('CH_CAPC_ID', field, extraFields.glPosts)}
                      menuPlacement="top"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item m={6}>
                <Controller
                  control={control}
                  name="CH_CDPC_ID"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('CH_CDPC_ID', field, extraFields.glPosts)}
                      menuPlacement="top"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item m={6}>
                <Controller
                  control={control}
                  name="CH_TAPC_ID"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('CH_TAPC_ID', field, extraFields.glPosts)}
                      menuPlacement="top"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item m={6}>
                <Controller
                  control={control}
                  name="CH_TDPC_ID"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('CH_TDPC_ID', field, extraFields.glPosts)}
                      menuPlacement="top"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
            </Grid>
          </Fieldset>
        </Grid.Item>

        {!isCreate && !isNullOrUndefined(watch('CH_ADD_USR')) && (
          <Grid.Item>
            <Divider spacing="default" />
            <b>Added by:</b> {watch('CH_ADD_USR')}
          </Grid.Item>
        )}

        {canEdit && (
          <Grid.Item>
            <Flexbox justifyContent="flex-end">
              <Button
                appearance="default"
                disabled={false}
                onClick={e => {
                  stopPropagation(e)
                  navigate(`..`)
                }}
              >
                Cancel
              </Button>
              <Spacer x="default" />
              <Button type="submit" appearance="primary" disabled={EditingEnabled}>
                {`${isCreate ? 'Add' : 'Edit'} location record`}
              </Button>
            </Flexbox>
          </Grid.Item>
        )}
      </Grid>
    </Form>
  )
}

export default AssetLocationsForm
