import { Attachment, EditAttachmentPayload, editAttachmentSchema } from '@api/models'
import { Input, Modal, toast } from '@components/common'
import useFormFieldProps from '@hooks/useFormField'
import { EDIT_ATTACHMENT_FORM_ID } from '@constants'
import { useForm } from 'react-hook-form'
import { useUpdateAttachmentMutation } from '@api'
import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

interface EditAttachmentFormProps {
  modalIsOpen: boolean
  closeModal: () => void
  defaultValues: Attachment
}

const EditAttachmentForm = ({
  modalIsOpen,
  closeModal,
  defaultValues,
}: EditAttachmentFormProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<EditAttachmentPayload>({
    reValidateMode: 'onBlur',
    defaultValues: {
      ID: defaultValues.ID,
      Comment: defaultValues.Comment,
    },
    resolver: yupResolver(editAttachmentSchema),
  })

  const [edit] = useUpdateAttachmentMutation()

  const { getInputProps } = useFormFieldProps<EditAttachmentPayload>({
    fields: {},
    register,
    errors: errors as any,
    isSubmitting,
  })

  async function onSubmit(values: EditAttachmentPayload) {
    try {
      await edit(values).unwrap()
      closeModal()
      toast.success('Saved successful')
    } catch (_) {
      toast.error('Edit failed')
    }
  }

  useEffect(() => {
    if (!modalIsOpen)
      reset({
        ID: defaultValues.ID,
        Comment: defaultValues.Comment,
      })
  }, [defaultValues, modalIsOpen, reset])

  return (
    <Modal
      title="Edit attachment"
      isOpen={modalIsOpen}
      close={closeModal}
      form={EDIT_ATTACHMENT_FORM_ID}
      actionLabel="Save"
      action={handleSubmit(onSubmit)}
      actionDisabled={isSubmitting}
    >
      <Input {...getInputProps('Comment')} label="Description" />
    </Modal>
  )
}

export default EditAttachmentForm
