import { createContext } from 'react'

export interface PermissionAuthContext {
  isAuthorized: (roleNames?: any[]) => boolean
  canEditAssets: boolean
  canViewAssets: boolean
  canEditCompanies: boolean
  canEditSettings: boolean
  canRunDepreciation: boolean
  canRunReports: boolean
}

const PermissionContext = createContext<PermissionAuthContext>({
  isAuthorized: () => false,
  canEditAssets: false,
  canViewAssets: false,
  canEditCompanies: false,
  canEditSettings: false,
  canRunDepreciation: false,
  canRunReports: false,
})

export default PermissionContext
