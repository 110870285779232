import React, { useRef, useEffect } from 'react'
import { ModalProps } from './Modal.types'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'

import { usePortal } from '@hooks'
import { AnimatePresence, motion } from 'framer-motion'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import { SPRING_MOTION } from '@constants'
import ModalHeader from './ModalHeader'
import ModalFooter from './ModalFooter'
import { OVERLAY } from '@styles-api/css-constants'

const Modal: React.FC<ModalProps> = ({
  isOpen,
  close,
  title,
  width = 320,
  height,
  intent = 'default',
  action,
  actionLabel = 'Apply',
  actionDisabled,
  dismissButton = true,
  dismissLabel = 'Cancel',
  onSubmit,
  form,
  type = 'submit',
  actionAutoFocus,
  isActionLoading,
  customStyles,
  hideFooter = false,
  ...props
}) => {
  // define portal root
  const portal = usePortal('modal-root')
  // close modal on click outside
  const node = useRef()
  //useOnClickOutside(node, () => close())
  // close modal with ESC key
  useEffect(() => {
    const closeEsc = e => {
      if (e.key === 'Escape') {
        close()
      }
    }
    window.addEventListener('keydown', closeEsc)
    return () => window.removeEventListener('keydown', closeEsc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // styles
  const [css] = useStyletron()

  var overlayStyles = css(OVERLAY)
  var containerStyles = css({ ...styles.container({ height }), ...customStyles })
  var contentStyles = {
    ...styles.content,
    ...styles.getContentHeight({ title, action, onSubmit }),
  }
  var contentClassName = css(contentStyles)

  if (!portal) return null
  return createPortal(
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          className={overlayStyles}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.12 },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.16 },
          }}
        >
          <FocusTrap focusTrapOptions={{ initialFocus: false, allowOutsideClick: true }}>
            <motion.div
              className={containerStyles}
              role="dialog"
              aria-modal="true"
              aria-label={title}
              tab-index="-1"
              ref={node}
              style={{ width: width }}
              layout
              initial={{ translateY: -20, opacity: 0 }}
              animate={{
                translateY: 0,
                opacity: 1,
                transition: SPRING_MOTION,
              }}
              exit={{ translateY: -20, opacity: 0, transition: { duration: 0 } }}
            >
              {onSubmit ? (
                <form onSubmit={onSubmit}>
                  {title && <ModalHeader title={title} close={close} />}
                  <div className={contentClassName}>{props.children}</div>
                  {(action || actionLabel) && (
                    <ModalFooter
                      action={action}
                      actionLabel={actionLabel}
                      close={close}
                      dismissButton={dismissButton}
                      dismissLabel={dismissLabel}
                      intent={intent}
                      actionDisabled={actionDisabled}
                      form={form}
                      type={type}
                      actionAutoFocus={actionAutoFocus}
                      isActionLoading={isActionLoading}
                    />
                  )}
                </form>
              ) : (
                <>
                  {title && <ModalHeader title={title} close={close} />}
                  <div className={contentClassName}>{props.children}</div>
                  {(form || action) && !hideFooter && (
                    <ModalFooter
                      action={action}
                      actionLabel={actionLabel}
                      close={close}
                      dismissButton={dismissButton}
                      dismissLabel={dismissLabel}
                      intent={intent}
                      actionDisabled={actionDisabled}
                      form={form}
                      type={type}
                      actionAutoFocus={actionAutoFocus}
                      isActionLoading={isActionLoading}
                    />
                  )}
                </>
              )}
            </motion.div>
          </FocusTrap>
        </motion.div>
      )}
    </AnimatePresence>,
    portal
  )
}

export default Modal
