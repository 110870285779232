import { SKELETON_SHINE } from '@styles-api/css-constants'
import { StyleObject } from '@styles-api/css.types'
import { CSSProperties } from 'react'

export const wrapper: StyleObject = {
  display: 'block',
  maxWidth: '100%',
  width: '100%',
  overflow: 'auto',
}
export const actions: StyleObject = {
  borderBottomWidth: '1px',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
  paddingBottom: 'var(--scale500)',
  paddingTop: 'var(--scale500)',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 'var(--z1)',
  borderTopRightRadius: 'var(--radius1)',
  borderTopLeftRadius: 'var(--radius1)',
  backgroundColor: 'var(--base-color)',
}
export const table: StyleObject = {
  borderSpacing: 0,
  overflowX: 'auto',
  position: 'relative',
  overscrollBehavior: 'none',
  overflowY: 'hidden!important',
}
export const overflowShadow = ({
  canScrollRight,
  canScrollLeft,
  canScrollDown,
  canScrollUp,
}) => {
  let shadowColor = 'rgba(0, 0, 0, 0.2)'

  let bottomShadow = `inset 0 -2rem 2rem -2rem ${shadowColor}`
  let topShadow = `inset 0 2rem 2rem -2rem ${shadowColor}`

  const shadows = []

  if (canScrollDown) shadows.push(bottomShadow)
  if (canScrollUp) shadows.push(topShadow)

  let shadowsString = shadows.join()

  return {
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 38px)',
    top: '38px',
    left: 0,
    pointerEvents: 'none',
    zIndex: 2,
    boxShadow: shadowsString,
    transition: 'all 0.1s',
  }
}

export const getHeight = ({ height }: CSSProperties) => {
  if (height)
    return {
      maxHeight: height,
      overscrollBehavior: 'none',
    }
}
export const getMinHeight = ({ minHeight }) => {
  if (minHeight)
    return {
      minHeight: minHeight,
    }
}

export const head: StyleObject = {
  backgroundColor: 'var(--background-color)',
  position: 'relative',
}
export const getStickyHead = ({ sticky }) => {
  if (sticky)
    return {
      position: 'sticky',
      width: '100%',
      top: 0,
      backgroundColor: 'var(--shade50)',
      zIndex: 1,
    }
}
export const headRow: StyleObject = {
  borderBottomWidth: '1px',
}

export const header = ({ column, resizeHovered, centered }): StyleObject => {
  return {
    color: column && column.isSorted ? 'var(--text-color)' : 'var(--text-color)',
    fontSize: 'var(--base-font-size)',
    paddingLeft: 'var(--scale600)',
    paddingRight: 'var(--scale600)',
    paddingTop: 'var(--gap-mini)',
    paddingBottom: 'var(--gap-mini)',
    fontWeight:
      (column && column.isSorted) || centered
        ? 'var(--font-weight-bold)'
        : 'var(--font-weight-bold)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: 'var(--size-default)',
    backgroundColor: 'var(--base-color)',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: centered ? 'center' : 'normal',
    ':hover': {
      color: column && column.canSort ? 'var(--text-color)' : 'var(--text-color)',
    },

    ':after': {
      height: '20px',
      content: '""',
      backgroundColor: resizeHovered ? 'var(--shade400)' : 'var(--border-color)',
      width: '1px',
      right: 0,
      top: '10px',
      position: 'absolute',
    },
    ':last-child': {
      ':after': {
        display: 'none',
      },
    },
  }
}
export const columnHeader_styles: StyleObject = {
  backgroundColor: 'var(--shade50)',
}
export const resizer: StyleObject = {
  width: 'var(--scale500)',
  position: 'absolute',
  right: 'calc(var(--scale500) /2 * -1)',
  top: 0,
  height: '100%',
  cursor: 'col-resize',
  zIndex: 10,
  bottom: 0,
}
export const bodyRow = ({ onClick }) => {
  return {
    cursor: onClick ? 'pointer' : 'default',
    ':nth-child(even)': {
      background: 'var(--shade50)',
      ':hover': {
        background: 'var(--shade100)',
      },
      ':active': {
        background: onClick ? 'var(--shade200)' : 'var(--shade100)',
      },
    },
    ':hover': {
      background: 'var(--shade100)',
    },
    ':active': {
      background: onClick ? 'var(--shade200)' : 'var(--shade100)',
    },
    // borderBottomWidth: '1px',
    zIndex: -1,
  }
}
export const cell = ({ disabled }) => {
  return {
    paddingLeft: 'var(--scale600)',
    paddingRight: 'var(--scale600)',
    paddingTop: 'var(--scale200)',
    paddingBottom: 'var(--scale200)',
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'not-allowed' : 'default',
    // borderRightWidth: '1px',
    // ':last-child': {
    //   borderRightWidth: '0px',
    // },
  }
}
export const cellColumnSorted: StyleObject = {
  fontWeight: 'var(--font-weight-bold)',
}
export const loadingRows: StyleObject = {
  backgroundColor: 'var(--primary-color50)',
  color: 'var(--primary-color)',
  height: '37px',
  width: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 'var(--scale600)',
  borderBottomWidth: '1px',
}
export const noResultsContainer: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '240px',
}

//! old --> need to replace
export const table__head__sortStyles: StyleObject = {
  position: 'absolute',
  right: '8px',
  fontSize: '12px',
  fontWeight: 'var(--font-weight-light)',
  color: 'var(--textColor)',
  top: 0,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}
export const table__head__sortIconStyles: StyleObject = {
  height: 'var(--scale550)',
  width: 'var(--scale550)',
  backgroundColor: 'rgba(255,255,255,0.5)',
  boxShadow: '0 0 4px 4px rgba(255,255,255,0.2)',
  borderRadius: '100%',
}

// skeleton
export const skeletonWrapper = ({ height }) => {
  return {
    display: 'block',
    width: '100%',
    height: height,
    overflow: 'hidden',
  }
}
export const skeletonHead: StyleObject = {
  height: 'calc(var(--size-default) + 4px)',
  boxSizing: 'content-box',
  borderBottomWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'var(--border-color)',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'var(--shade50)',
}

export const skeletonRow = ({ height }) => {
  return {
    height: height,
    display: 'flex',
    alignItems: 'center',
    ':nth-child(even)': {
      ...SKELETON_SHINE,
    },
  }
}

export const skeletonPlaceholder: StyleObject = {
  height: 'var(--scale550)',
  width: '100%',
  ...SKELETON_SHINE,
}
