import { NavLink } from 'react-router-dom'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

interface Props {
  items: {
    label: string
    path?: string
    isActive?: boolean
    isDisabled?: boolean
    onClick?
  }[]
}

const LocalSideNav = ({ items }: Props) => {
  const [css] = useStyletron()

  return (
    <div className={css(styles.container)}>
      <nav>
        <ul>
          {items.map(item => (
            <li key={item.label} className={css(styles.listItem)}>
              {item.onClick ? (
                <button onClick={item.onClick} className={css(styles.link(item.isActive))}>
                  {item.label}
                </button>
              ) : (
                <NavLink
                  to={item.path ?? ''}
                  className={({ isActive }) => {
                    return css({
                      ...styles.link(item.isActive ?? isActive),
                    })
                  }}
                  onClick={item.onClick}
                >
                  {item.label}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}
export default LocalSideNav
