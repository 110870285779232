import { StyleObject } from '@styles-api/css.types'
import { Palette } from '@styles-api/palette.types'

export const wrapper = ({ height }) => {
  return {
    display: 'block',
    position: 'relative',
    height: `${height}px`,
    paddingBottom: 'var(--scale1400)',
  }
}
export const list: StyleObject = {
  display: 'flex',
  alignItems: 'start',
  paddingBottom: 'var(--gap-default)',
  marginTop: 'var(--gap-mini)',
  justifyContent: 'space-between',
  position: 'relative',
  marginLeft: 'calc(var(--scale500) * -1)',
  marginRight: 'calc(var(--scale500) * -1)',
}
export const form: StyleObject = {
  height: 'calc(100% - var(--size-default) - var(--scale600))',
}
export const fieldset: StyleObject = {
  height: '100%',
}
export const stepAlignCenter: StyleObject = {
  textAlign: 'center',
}

export const step = ({ status }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flex: '1 1 0px',

    fontWeight: 'var(--font-weight-normal)',

    color:
      status === 'current' || status === 'complete'
        ? 'var(--text-color)'
        : 'var(--shade400)',
    backgroundColor: 'var(--transparent)',
    borderRadius: 'var(--radius1)',
    paddingLeft: 'var(--scale500)',
    paddingRight: 'var(--scale500)',
    zIndex: 2,
    position: 'relative',
  }
}
export const stepLabel: StyleObject = {
  zIndex: 3,
  lineHeight: '1.32',
}
export const stepIndicator = ({ status }) => {
  let backgroundColor: Palette
  switch (status) {
    case 'current':
      backgroundColor = 'var(--primary-color)'
      break
    case 'upcoming':
      backgroundColor = 'var(--shade400)'
      break
    case 'complete':
      backgroundColor = 'var(--success-color)'
      break
  }
  return {
    backgroundColor,
    lineHeight: 'var(--scale600)',
    height: 'var(--scale600)',
    width: 'var(--scale600)',
    borderRadius: '100%',
    fontSize: 'var(--small-font-size)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--interaction-contrast)',
    marginBottom: 'var(--scale100)',
    boxShadow: '0 0 0 12px rgba(255,255,255)',
    zIndex: 2,
  }
}
export const check: StyleObject = {
  height: 'var(--scale400)',
  width: 'var(--scale400)',
  strokeWidth: '3.5px',
}

export const nav: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 'var(--gap-compact)',

  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
}

let progressLineHeight = '1px'
export const progressBase = ({ fraction }) => {
  return {
    width: `calc(100% - ${fraction}%)`,
    height: progressLineHeight,
    left: `calc(${fraction}% / 2)`,
    backgroundColor: 'var(--shade300)',
    position: 'absolute',
    top: 'calc(var(--scale600) / 2)',
    zIndex: 0,
    transition: 'width 0.5s ease',
  }
}
export const progressFluid = ({ width, fraction }) => {
  return {
    width: `${width}%`,
    height: progressLineHeight,
    left: `calc(${fraction}% / 2)`,
    backgroundColor: 'var(--primary-color)',
    position: 'absolute',
    top: 'calc(var(--scale600) / 2)',
    zIndex: 1,
    transition: 'width 0.2s ease-in-out',
  }
}
