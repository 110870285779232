import React from 'react'
import { GridItemProps } from './Grid.types'

import { useStyletron } from 'styletron-react'

import * as styles from './Styles'

const GridItem: React.FC<GridItemProps> = ({
  s = 12,
  m,
  l,
  startS = 'auto',
  startM = 'auto',
  startL = 'auto',
  className,
  ...props
}) => {
  const [css] = useStyletron()

  let itemStyles = css({
    ...styles.getColLayout({ s, m, l, startL, startM, startS }),
  })

  return (
    <div className={`${itemStyles} ${className ? className : ''}`} {...props}>
      {props.children}
    </div>
  )
}

export default GridItem
