export const WHILETAP_MOTION = { scale: 1 }

export const WHILEHOVER_MOTION = { scale: 1.03 }

export const HOVER_TRANSITION = { ease: [0.25, 0.1, 0.25, 1], duration: 0.24 }

export const SPRING_MOTION = {
  type: 'spring',
  stiffness: 800,
  damping: 45,
}
