import { useState } from 'react'
import { useFields } from '@hooks'
import { getInputPropsForModalForms } from '@utils/getInputPropsForModalForms'
import { Vendor, VendorKey, vendorSchema } from '@api/models'
import { FormSubmitEvent } from '@types'
import { validateAndSubmit } from '@utils/validateAndSubmit'
import { ReactSelectOptions, SelectType } from '@components/common/Select/Select.types'

export interface VendorFormProps {
  isEdit?: boolean
  initialValues: Vendor
  handleSubmit: (payload: Vendor) => Promise<void>
  canEdit?: boolean
}

export const useVendorForm = ({ initialValues, handleSubmit, canEdit }) => {
  const { fields } = useFields()
  const [form, setForm] = useState({
    values: initialValues,
    errors: {},
  })

  function getField(
    name: VendorKey,
    select?: SelectType,
    selectOptions?: ReactSelectOptions
  ) {
    const field = getInputPropsForModalForms({
      name,
      select,
      selectOptions,
      fieldMap: fields,
      schema: vendorSchema as any,
      initialValues,
      form,
      setForm,
    })

    return { ...field, readOnly: !canEdit }
  }

  async function handleFormSubmit(e: FormSubmitEvent): Promise<void> {
    e.preventDefault()
    await validateAndSubmit(vendorSchema as any, form, setForm, handleSubmit)
  }

  return {
    getField,
    handleFormSubmit,
  }
}
