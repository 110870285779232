import { FileFormat } from '@api/models'
import { toast } from '@components/common'
import HttpStatusCode from '@components/common/Helper/HttpStatusCode'
import { ERROR_REQUEST } from '@constants'

function parseFileFormat(fileFormat: FileFormat) {
  switch (fileFormat) {
    case 'pdf':
      return 'pdf'
    case 'csv':
      return 'csv'
    case 'excel':
      return 'xlsx'
  }
}

export function base64ToPDF(base64: any) {
  // Create blob link to download
  var blob = new Blob([base64], { type: 'application/pdf' })
  const url = window.URL.createObjectURL(blob)
  window.open(url, '_blank')
}

function downloadFile(
  data: any,
  fileFormat: FileFormat,
  type: string = null,
  fileName: string = 'report'
) {
  const blob = type === null ? new Blob([data]) : new Blob([data], { type })

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = url
  // link.target = '_blank'
  link.download = `${fileName}.${parseFileFormat(fileFormat)}`

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  link.parentNode.removeChild(link)
  URL.revokeObjectURL(url)
}

export function downloadCSVFile(data: any, fileName: string = 'report') {
  downloadFile(data, 'csv', 'text/csv;charset=utf-8', fileName)
}

export function downloadExcelFile(data: any, fileName: string = 'report') {
  downloadFile(
    data,
    'excel',
    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64',
    fileName
  )
}

export async function reportErrorHandler({ response }) {
  if (
    response &&
    response.status === HttpStatusCode.UNPROCESSABLE_ENTITY &&
    response.data &&
    response.data.text
  ) {
    toast.error(await response.data.text())
    return
  }
  toast.error(ERROR_REQUEST)
}

export function reportResponseHandler(response: any, fileFormat: FileFormat) {
  switch (fileFormat) {
    case 'pdf':
      base64ToPDF(response.data)
      return
    case 'csv':
      downloadCSVFile(response.data)
      return
    case 'excel':
      downloadExcelFile(response.data)
      return
  }
}
