import { FOCUS } from '@styles-api/css-constants'
import { StyleObject } from '@styles-api/css.types'

export const topBar: StyleObject = {
  backgroundColor: 'var(--base-color)',
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  paddingLeft: 'var(--gap-compact)',
  paddingRight: 'var(--gap-compact)',
  height: 'var(--topbar-height)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 'var(--z3)',
}
export const topBarClearFix: StyleObject = {
  height: 'var(--topbar-height)',
  display: 'block',
  opacity: 0,
  width: '100%',
  zIndex: -1,
}
export const leftArea: StyleObject = {
  display: 'flex',
  alignItems: 'center',
}
export const brandLink: StyleObject = {
  display: 'flex',
  alignItems: 'center',

  ...FOCUS,
}
export const brandWorthIT: StyleObject = {
  color: 'var(--text-color)',
  fontWeight: 'var(--font-weight-heavy)',
  fontSize: '15px',
}
export const brandFA: StyleObject = {
  color: 'var(--text-color)',
  fontWeight: 'var(--font-weight-normal)',
  marginLeft: 'var(--scale200)',
  fontSize: '15px',
  minWidth: '96px'
}
export const brandIcon: StyleObject = {
  height: 'var(--scale800)',
  marginRight: 'var(--scale400)',
}
