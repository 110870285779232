import { AllAssetDetailFields, FieldLabels } from '@api/models'
import { Grid, Select } from '@components/common'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'

interface Props {
  fields: FieldLabels
  assetHasHistory: boolean
  segmentOptions: { [key: number]: ReactSelectOptions }
}

const AssetGLKeysForm = ({ fields, assetHasHistory, segmentOptions }: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
  } = useFormContext<AllAssetDetailFields>()

  const { getSelectProps } = useFormFieldProps<AllAssetDetailFields>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  const tenKeys = [...Array(10).keys()]

  return (
    <Grid>
      {tenKeys.map(i => (
        <Grid.Item key={i}>
          <Controller
            control={control}
            name={`AS_KEY${i}_ID` as any}
            render={({ field }) => (
              <Select
                {...getSelectProps(`AS_KEY${i}_ID` as any, field, segmentOptions[i])}
                isSearchable
                disabled={assetHasHistory}
              />
            )}
          />
        </Grid.Item>
      ))}
    </Grid>
  )
}
export default AssetGLKeysForm
