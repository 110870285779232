import React from 'react'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

import Brand from './Brand'
import RightList from './RightList'
import { Grid } from '@components/common'
import GridItem from '@components/common/Grid/GridItem'
import AssetCompanySelect from './AssetCompanySelect'
import DepreciationStatus from './DepreciationStatus/DepreciationStatus'

const TopBar = () => {
  const [css] = useStyletron()

  return (
    <React.Fragment>
      <header className={`theme--dark ${css(styles.topBar)}`}>
        <div className={css(styles.leftArea)}>
          <Grid colGap="large">
            <GridItem s={4} style={{ alignSelf: 'center' }}>
              <Brand />
            </GridItem>

            <GridItem s={6}>
              <AssetCompanySelect />
            </GridItem>
          </Grid>
        </div>

        <DepreciationStatus />

        <RightList />
      </header>
      <div className={css(styles.topBarClearFix)} role="presentation" />
    </React.Fragment>
  )
}
export default TopBar
