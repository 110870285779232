import Folders from '@components/Folders'
import View from '@components/layout/View'

const FoldersView = () => {
  return (
    <View title="Folders" showHeader={false}>
      <Folders />
    </View>
  )
}

export default FoldersView
