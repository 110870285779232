import { Button, Flexbox, Icon, Input, Spacer, Text } from '@components/common'
import { useStyletron } from 'styletron-react'
import { PAGINATION_NEXT_BUTTON_ID, PAGINATION_PREV_BUTTON_ID } from '@constants'
import { InputChangeEvent } from '@types'

import PaginationStyles from './styles'
var styles = new PaginationStyles()

interface IPagination {
  changePage: (e: React.MouseEvent<HTMLButtonElement>) => void
  changeItemsPerPage?: (e: InputChangeEvent) => void
  goToPage?: (e: InputChangeEvent) => void
  totalItems: number
  maxItemsPerPage?: number
  currentPageNumber: number
  label?: string
  labelStart?:string
}

const Pagination = ({
  changePage,
  changeItemsPerPage,
  goToPage,
  totalItems,
  maxItemsPerPage,
  currentPageNumber,
  label,
  labelStart
}: IPagination) => {
  const [css] = useStyletron()

  const totalPages = Math.ceil(totalItems / maxItemsPerPage)

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.nav)}>
        <Flexbox alignItems="center">
          <Text small aria-hidden={!labelStart}>
            {labelStart && labelStart}
          </Text>
          
          {labelStart && (<>
            <Spacer x="mini" />
            <Input aria-hidden={!labelStart} size='mini' width='25%' max='100' min='5' type='number' defaultValue={maxItemsPerPage} onChange={changeItemsPerPage}></Input>
            <Spacer x="mini" />
          </>)}
          
          <Text small aria-hidden={!label}>
            {label && label}
          </Text>
        </Flexbox>
        <Flexbox alignItems="center">
          {!labelStart && (<>
            <Text small style={{ marginRight: '0.25rem' }}>
             Page {currentPageNumber}
            </Text>
          </>)}

          {labelStart && (<>
            <Text small>
             Page
            </Text>
            <Spacer x="mini" />
            <Input  size='mini' width='25%' max={totalPages} min='0' type='number' value={currentPageNumber} onChange={goToPage}></Input>
            <Spacer x="mini" />
          </>)}

          <Text small>  
            of {totalPages}
          </Text>

          <Spacer x="mini" />
          <div

            role="navigation"
            aria-label="Pagination"
            className={css(styles.list)}
            aria-hidden={totalPages < 2}
          >
            {totalPages > 1 && (
              <>
                <Button
                  onClick={changePage}
                  disabled={currentPageNumber === 1}
                  appearance="text"
                  size="compact"
                  aria-label="Go to previous page"
                  icon={<Icon name="ChevronLeft" />}
                  id={PAGINATION_PREV_BUTTON_ID}
                >
                  Previous
                </Button>

                <Spacer x="default" />

                <Button
                  onClick={changePage}
                  disabled={currentPageNumber === totalPages}
                  appearance="text"
                  size="compact"
                  aria-label="Go to next page"
                  iconRight={<Icon name="ChevronRight" />}
                  id={PAGINATION_NEXT_BUTTON_ID}
                >
                  Next
                </Button>
              </>
            )}
          </div>
        </Flexbox>
      </div>
    </div>
  )
}
export default Pagination
