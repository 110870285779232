import HttpClient from "../HttpClient";

export class VendorsApi extends HttpClient {
    private static classInstance?: VendorsApi;

    public constructor () {
        super (`/Vendor`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new VendorsApi();
        }

        return this.classInstance;
    }

    public fetch = (filter: string, pageNumber: number, itemsPerPage: number) =>
        this.instance.get(`/FetchVendors?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`);
    public list = () => this.instance.get(`/ListVendors`);
    public getVendor = (id: string) => this.instance.get(`/GetVendor?id=${id}`);
    public create = (data: any) => this.instance.post(`/Create`, data);
    public edit = (data: any) => this.instance.put(`/Edit`, data);
    public delete = (data: any) => this.instance.post(`/Delete`, data);
    public import = (file: any) => this.instance.post(`/ImportVendor`, file);
}