import { ImportColumn } from "../importTypes";

export const segments: ImportColumn[] = [
    {
        label: 'Key Number',
        value: 'KY_NR'
    },
    {
        label: 'Key Value',
        value: 'KY_VALUE'
    },
    {
        label: 'Key Description',
        value: 'KY_DESC'
    }
]