import { useModal } from '@components/common'
import { useYearEndUndoInfoQuery, useYearEndUndoMutation } from '@api'
import { YearEndUndoInput } from '@api/models'
import { useFields } from '@hooks'
import { useEffect } from 'react'

const useYearEndUndoHook = (assetCompanyID: number) => {
  const { fields } = useFields()

  const {
    modalIsOpen: isYearEndUndoFormOpen,
    triggerModal: openYearEndUndoForm,
    closeModal: closeYearEndUndoForm,
  } = useModal()

  const {
    data: yearEndUndoInfo,
    isLoading: isLoadingGetYearEndUndoInfo,
    isSuccess: isSuccessGetYearEndUndoInfo,
    isError: isErrorGetYearEndUndoInfo,
    error: errorGetYearEndUndoInfo,
  } = useYearEndUndoInfoQuery(assetCompanyID, {
    refetchOnMountOrArgChange: true,
    skip: !isYearEndUndoFormOpen,
  })

  const [
    yearEndUndoMutation,
    {
      isLoading: isYearEndUndoing,
      isSuccess: isSuccessYearEndUndo,
      isError: isErrorYearEndUndo,
    },
  ] = useYearEndUndoMutation()

  useEffect(() => {
    if (isErrorGetYearEndUndoInfo) {
      closeYearEndUndoForm()
    }
  }, [closeYearEndUndoForm, isErrorGetYearEndUndoInfo])

  async function undoYearEndDepreciation(formData: YearEndUndoInput) {
    closeYearEndUndoForm()
    await yearEndUndoMutation(formData)
  }

  return {
    isErrorYearEndUndo,
    isErrorGetYearEndUndoInfo,
    isSuccessYearEndUndo,
    isYearEndUndoFormOpen,
    isYearEndUndoing,
    isLoadingGetYearEndUndoInfo,
    isSuccessGetYearEndUndoInfo,
    yearEndUndoInfo,
    errorGetYearEndUndoInfo: (errorGetYearEndUndoInfo as any)?.data,
    fields,
    openYearEndUndoForm,
    closeYearEndUndoForm,
    undoYearEndDepreciation,
  }
}

export default useYearEndUndoHook
