import {
  Grid,
  Modal,
  Text,
  Fieldset,
  Flexbox,
  Input,
  Spacer,
  Spinner,
  toast,
  Icon,
} from '@components/common'
import { formatDisplayDate } from '@utils/basic'
import { useGetSettingsQuery } from '@api'
import { useEffect } from 'react'
import useYearEndUpdateHook from './useYearEndUpdateHook'
import DepreciationCard from './DepreciationCard'

interface YearEndUpdateFormProps {
  assetCompanyID: number
}

const YearEndUpdateForm = ({ assetCompanyID }: YearEndUpdateFormProps) => {
  const {
    isSuccessYearEndUpdate,
    isYearEndUpdateFormOpen,
    isLoadingGetYearEndUpdateInfo,
    isSuccessGetYearEndUpdateInfo,
    isYearEndUpdating,
    isErrorGetYearEndUpdateInfo,
    errorGetYearEndUpdateInfo,
    isErrorYearEndUpdate,
    yearEndUpdateInfo,
    fields,
    openYearEndUpdateForm,
    closeYearEndUpdateForm,
    updateYearEndDepreciation,
  } = useYearEndUpdateHook(assetCompanyID)

  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  const isUpdateDisabled = assetCompanyID === undefined || assetCompanyID === null
  const shouldShowYearEndUpdateForm =
    isYearEndUpdateFormOpen && isSuccessGetYearEndUpdateInfo

  useEffect(() => {
    if (isErrorGetYearEndUpdateInfo) {
      toast.warn(errorGetYearEndUpdateInfo)
    }

    if (isErrorYearEndUpdate) {
      toast.error('Year end update failed')
    }

    if (isSuccessYearEndUpdate) {
      closeYearEndUpdateForm()
    }
  }, [
    isErrorGetYearEndUpdateInfo,
    errorGetYearEndUpdateInfo,
    isErrorYearEndUpdate,
    isSuccessYearEndUpdate,
    closeYearEndUpdateForm,
  ])

  return (
    <>
      <Modal
        isOpen={isYearEndUpdating}
        close={() => {}}
        title="Updating Year End Depreciation"
      >
        <Flexbox justifyContent="center">
          <Spinner size="large" />
        </Flexbox>
      </Modal>

      <Modal
        isOpen={shouldShowYearEndUpdateForm}
        close={closeYearEndUpdateForm}
        onSubmit={updateYearEndDepreciation}
        title="Year End Depreciation Update"
        actionLabel="Update"
      >
        {shouldShowYearEndUpdateForm && (
          <Grid>
            <Grid.Item>
              <Fieldset legend="Status" legendCenter>
                <Flexbox>
                  <Grid>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_COM_ID}
                        value={yearEndUpdateInfo.AssetCompanyID}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_DESCS}
                        value={yearEndUpdateInfo.ShortName}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label="Last Year Closed"
                        value={yearEndUpdateInfo.LastYearClosed}
                        readOnly
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Parameters" legendCenter>
                <Flexbox>
                  <Grid>
                    <Grid.Item m={6}>
                      <Input
                        label="Year To Close"
                        value={yearEndUpdateInfo.YearToClose}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={6}>
                      <Input
                        label="Posting Date"
                        value={formatDisplayDate(yearEndUpdateInfo.PostDate, DateFormat)}
                        readOnly
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Remarks" legendCenter>
                <Grid>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>1.</Text>
                      <Spacer x="compact" />
                      <Input value={yearEndUpdateInfo.Remark1} readOnly />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>2.</Text>
                      <Spacer x="compact" />
                      <Input value={yearEndUpdateInfo.Remark2} readOnly />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>3.</Text>
                      <Spacer x="compact" />
                      <Input value={yearEndUpdateInfo.Remark3} readOnly />
                    </Flexbox>
                  </Grid.Item>
                </Grid>
              </Fieldset>
            </Grid.Item>
          </Grid>
        )}
      </Modal>

      <DepreciationCard
        disabled={isUpdateDisabled}
        loading={isLoadingGetYearEndUpdateInfo}
        openForm={openYearEndUpdateForm}
        helpText='Generate "Permanent"  calculated depreciation records for reporting year end activity. '
        icon={<Icon name="CheckCircle" color="var(--primary-color500)" />}
        type="Update"
      />
    </>
  )
}

export default YearEndUpdateForm
