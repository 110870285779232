import { ImportColumn } from "../importTypes";

export const categories: ImportColumn[] = [
    {
        label: 'Asset Company ID',
        value: 'DP_COM_ID'
    },
    {
        label: 'Asset ID',
        value: 'DP_OBJ_ID'
    },
    {
        label: 'Depreciation Profile',
        value: 'DP_TYP_CD'
    },
    {
        label: 'Description',
        value: 'DP_DESC'
    },
    {
        label: 'Acquisition Post Code',
        value: 'DP_ACPC_ID'
    },
    {
        label: 'Depreciation Post Code',
        value: 'DP_DPPC_ID'
    },
    {
        label: 'Disposal Post Code',
        value: 'DP_DSPC_ID'
    },
    {
        label: 'Disposal Gain/Loss Post Code',
        value: 'DP_DGPC_ID'
    },
    {
        label: 'Transfer Post Code',
        value: 'DP_TFPC_ID'
    },
    {
        label: 'Default Location Disposition',
        value: 'DP_LOCD_CD'
    },
    {
        label: 'Default Location Post Code',
        value: 'DP_LAPC_ID'
    },
    {
        label: 'Default Location Depreciation Post Code',
        value: 'DP_LDPC_ID'
    },
    {
        label: 'Default Service Disposition',
        value: 'DP_SERD_CD' 
    },
    {
        label: 'Default Service Post Code',
        value: 'DP_SAPC_ID'
    },
    {
        label: 'Default Service Depreciation Post Code',
        value: 'DP_SDPC_ID'
    },
    {
        label: 'Depreciation Method',
        value: 'DP_MET_CD'
    },
    {
        label: 'Estimated Life',
        value: 'DP_LIFETIM'
    },
    {
        label: 'Start Code',
        value: 'DP_STRT_CD'
    },
    {
        label: 'Depreciation Rate',
        value: 'DP_RATE'
    },
    {
        label: 'Depreciation Rate Code',
        value: 'DP_RATE_CD'
    },
    {
        label: 'Year 1 Tax Credit Amount',
        value: 'DP_TAX_CRD'
    },
    {
        label: 'Year 1 Tax Credit',
        value: 'DP_TAXC_CD'
    },
    {
        label: 'Tax Credit Code',
        value: 'DP_TAX_UCD'
    },
    {
        label: 'Year 1 Depreciation Add-On Amount',
        value: 'DP_INT_DED'
    },
    {
        label: 'Year 1 Depreciation Add-On',
        value: 'DP_INT_CD'
    },
    {
        label: 'Year 1 Depreciation Add-On Code',
        value: 'DP_INT_UCD'
    },
    {
        label: 'Acceleration Code',
        value: 'DP_REPA_CD'
    },
    {
        label: 'Depreciation System Code',
        value: 'DP_REPS_CD'
    },
    {
        label: 'Depreciation User Code',
        value: 'DP_REP_UCD'
    },
    {
        label: 'Country',
        value: 'DP_CNTY_CD'
    },
    {
        label: 'Salvage Percent',
        value: 'DP_SAL_PCT'
    },
    {
        label: 'Locked',
        value: 'DP_LOCKED'
    }
]