import { DeprecExtraFields, DepreciationClass, FieldLabels } from '@api/models'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import { Fieldset, Grid, Select, Spacer } from '@components/common'
import { DeprecClassHookProps } from '@components/depreciation-class/DepreciationClass.types'

interface Props extends DeprecClassHookProps {
  fields: FieldLabels
  extraFields: DeprecExtraFields
}

const DeprecClassClassificationForm = ({ fields, extraFields, context }: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
  } = useFormContext<DepreciationClass>()

  const { getSelectProps, getCreatableSelectProps } = useFormFieldProps<DepreciationClass>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  return (
    <Grid>
      <Grid.Item>
        <Controller
          control={control}
          name="DP_REPS_CD"
          render={({ field }) => (
            <Select
              {...getSelectProps('DP_REPS_CD', field, extraFields.deprecSystem)}
              autoFocus={context !== 'create'}
            />
          )}
        />
      </Grid.Item>
      <Grid.Item>
        <Controller
          control={control}
          name="DP_REP_UCD"
          render={({ field }) => (
            <Select
              {...getCreatableSelectProps('DP_REP_UCD', field, extraFields.deprecUserCode)}
              placeholder="Start typing to search or create new"
            />
          )}
        />
      </Grid.Item>
      <Grid.Item>
        <Controller
          control={control}
          name="DP_REPA_CD"
          render={({ field }) => (
            <Select
              {...getSelectProps('DP_REPA_CD', field, extraFields.accelerationCode)}
            />
          )}
        />
      </Grid.Item>

      <Grid.Item>
        <Fieldset legend="Location Defaults">
          <Grid>
            <Grid.Item>
              <Controller
                control={control}
                name="DP_LOCD_CD"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('DP_LOCD_CD', field, extraFields.dispositionType)}
                    label="Disposition Type"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="DP_LAPC_ID"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('DP_LAPC_ID', field, extraFields.glPost)}
                    label="Transaction Post Code"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="DP_LDPC_ID"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('DP_LDPC_ID', field, extraFields.glPost)}
                    label="Depreciation Post Code"
                  />
                )}
              />
            </Grid.Item>
          </Grid>
        </Fieldset>
        <Spacer y="default" />
        <Fieldset legend="Service Defaults">
          <Grid>
            <Grid.Item>
              <Controller
                control={control}
                name="DP_SERD_CD"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('DP_SERD_CD', field, extraFields.dispositionType)}
                    label="Disposition Type"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="DP_SAPC_ID"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('DP_SAPC_ID', field, extraFields.glPost)}
                    label="Transaction Post Code"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="DP_SDPC_ID"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('DP_SDPC_ID', field, extraFields.glPost)}
                    label="Depreciation Post Code"
                  />
                )}
              />
            </Grid.Item>
          </Grid>
        </Fieldset>
      </Grid.Item>
    </Grid>
  )
}

export default DeprecClassClassificationForm
