import { useFetchPicklistsQuery } from '@api'
import { FetchPicklistsResponse } from '@api/models'
import { ReactSelectOptions } from '@components/common/Select/Select.types'

export type Picklist = {
  [key: string]: ReactSelectOptions
}

interface PicklistHook {
  data: FetchPicklistsResponse
  picklist: Picklist
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  isFetching: boolean
}

interface PicklistHookProps {
  skipRequest?: boolean
}

export default function usePicklist({
  skipRequest = false,
}: Partial<PicklistHookProps> = {}): PicklistHook {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  } = useFetchPicklistsQuery(undefined, { skip: skipRequest })

  let picklist: Picklist = {}
  if (data) {
    data.forEach(item => {
      if (picklist[item.PKNAME] === undefined) {
        picklist[item.PKNAME] = []
      }

      picklist[item.PKNAME].push({
        label: item.PKVAL,
        value: item.PKVAL,
      })
    })
  }

  return {
    picklist,
    data,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  }
}
