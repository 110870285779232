import { StyleObject } from '@styles-api/css.types'
import { TABLET, DESKTOP } from '@constants'

// Grid
export const baseGrid: StyleObject = {
  display: 'grid',
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
}

export const getGap = ({ gap, colGap, rowGap }) => {
  return {
    gap: gap !== 'none' ? `var(--gap-${gap})` : 0,
    columnGap: colGap !== 'none' ? colGap && `var(--gap-${colGap})` : 0,
    rowGap: rowGap !== 'none' ? rowGap && `var(--gap-${rowGap})` : 0,
  }
}

export const getColLayout = ({ s, m, l, startS, startM, startL }) => {
  // default s=12 --> by default, grid items span all 12 columns on all screen sizes
  // start, startM and startL default = auto to flow in line with other items unless defined

  return {
    // mobile first --> base style is the s value, m and l values ..
    // are given media query styles
    gridColumn: `${startS} / span ${s}`,
    [TABLET]: {
      gridColumn: m && `${startM} / span ${m}`,
    },
    [DESKTOP]: {
      gridColumn: l && `${startL} / span ${l}`,
    },
  }
}
