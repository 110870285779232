import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import List from './List'
import {
  primaryNavHeading,
  primaryNavItems,
  secondaryNavHeading,
  secondaryNavItems,
} from './nav-lists'
import { Divider } from '@common'
import usePermission from '@components/Auth/Permissions/usePermission'

const Sidebar = () => {
  const [css] = useStyletron()
  const { isAuthorized } = usePermission()

  const filteredPrimary = primaryNavItems.filter(item => isAuthorized(item.grants))
  const filteredSecondary = secondaryNavItems.filter(item => isAuthorized(item.grants))

  return (
    <aside className={css(styles.aside)}>
      <nav className={css(styles.nav)}>
        <List items={filteredPrimary} heading={primaryNavHeading} />
        <Divider spacing="default" />
        <List items={filteredSecondary} heading={secondaryNavHeading} />
      </nav>
    </aside>
  )
}
export default Sidebar
