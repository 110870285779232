import { MUST_BE_NUMBER, REQUIRED_FIELD } from '@constants'
import { maxStringMsg } from '@utils/basic'
import * as Yup from 'yup'
import { digitLengthValidation, exactLengthValidation } from '../../models/validation'

export const companySchema = Yup.object({
  AssetCompanyID: Yup.number()
    .typeError(MUST_BE_NUMBER)
    .test(exactLengthValidation(3))
    .required(REQUIRED_FIELD), // TODO: make a test to check if this already exists
  CM_DESC: Yup.string().max(50, maxStringMsg(50)).required(REQUIRED_FIELD),
  CM_DESCS: Yup.string().max(10, maxStringMsg(10)).required(REQUIRED_FIELD),
  CM_YE_MM: Yup.number()
    .typeError(MUST_BE_NUMBER)
    .test(digitLengthValidation(2))
    .required(REQUIRED_FIELD)
    .default(12)
    .min(1, 'Invalid month')
    .max(12, 'Invalid month'),
  CM_YE_DD: Yup.number()
    .typeError(MUST_BE_NUMBER)
    .required(REQUIRED_FIELD)
    .default(31)
    .min(1, 'Invalid day')
    .max(31, 'Invalid day'), //TODO: change test for #days per month
  CM_NR_PER: Yup.number()
    .typeError(MUST_BE_NUMBER)
    .test(digitLengthValidation(3))
    .required(REQUIRED_FIELD)
    .default(12), //TODO: change test to min/max
  CM_YTD_BCD: Yup.string().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD).default('P'),
  CM_STAT_CD: Yup.string().when('CM_SMH', {
    is: true,
    then: Yup.string().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
    otherwise: Yup.string().nullable(),
  }),
  CM_BOK4_CD: Yup.string().when('CM_B4MH', {
    is: true,
    then: Yup.string().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
    otherwise: Yup.string().max(2, maxStringMsg(2)).nullable(),
  }),
  CM_SMH: Yup.bool().nullable(),
  CM_B4MH: Yup.bool().nullable(),
  CM_TAX: Yup.bool().nullable(),

  CM_LFYC: Yup.number().nullable(),
  CM_LE_AOD: Yup.date().nullable(),
  CM_LE_AOP: Yup.number().nullable(),
  CM_LE_GPD: Yup.date().nullable(),
  CM_LE_GPP: Yup.number().nullable(),
  CM_LE_SYD: Yup.date().nullable(),
  CM_LE_PEN: Yup.string().nullable(),
  CM_LE_YTUF: Yup.number().nullable(),
  CM_LE_RE1: Yup.string().nullable(),
  CM_LE_RE2: Yup.string().nullable(),
  CM_LE_RE3: Yup.string().nullable(),
  CM_LU_SYD: Yup.date().nullable(),
  CM_LU_EVN: Yup.number().nullable(),
  CM_DEP_STC: Yup.string().nullable(),
})
export type Company = Yup.InferType<typeof companySchema>
export type CompanyKey = keyof Company

// Request payloads and responses
export type GetCompaniesResponse = Company[]

export interface FetchAssetCompaniesPayload {
  pageNumber: number
  itemsPerPage: number
  filter: Object
}
export interface FetchAssetCompany {
  AssetCompanyID: number
  CM_DESC: string
  CM_DESCS: string
  CM_NR_PER: number
  CM_YE_DD: number
  CM_YE_MM: number
  CM_YTD_BCD: string
}
export type FetchAssetCompanyKey = keyof FetchAssetCompany

export type FetchAssetCompaniesResponse = {
  count: number
  list: FetchAssetCompany[]
}

export type CreateCompanyPayload = Company

export interface MaintainedDepProfiles {
  IsTaxMaintained: boolean
  IsStateTaxMaintained: boolean
  IsOtherTaxMaintained: boolean
  AssetCompanyID: number
  CompanyDesc: string
}

// constants
export const DEPREC_BASIS_OPTIONS = [
  { label: 'Period Number', value: 'P' },
  { label: 'As of Date', value: 'D' },
]
