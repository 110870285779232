import { StyleObject } from '@styles-api/css.types'

export const loadingWrapper: StyleObject = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: 'var(--scale850)',
  height: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  zIndex: 'var(--z2)',
  display: 'flex',
  alignItems: 'center',
}

export const loadingContainer: StyleObject = {
  display: 'flex',
  width: 'var(--scale850)',
  justifyContent: 'space-between',
  transform: 'translateY(-6px)',
}

export const loadingCircle: StyleObject = {
  display: 'block',
  width: 'var(--scale100)',
  height: 'var(--scale900)',
  backgroundColor: 'var(--shade300)',
  opacity: '0.9',
  borderRadius: 'var(--pill-radius)',
}

export const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

export const loadingCircleVariants = {
  start: {
    scaleY: 0.4,
  },
  end: {
    scaleY: 1,
  },
}

export const loadingCircleTransition = {
  duration: 0.4,
  ease: 'easeInOut',
  repeatType: 'reverseLoop',
  repeat: Infinity,
}
