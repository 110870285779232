import { AssetTransferParams, AssetTransferParamsKey, FieldLabels } from '@api/models'
import {
  Button,
  Card,
  Divider,
  Form,
  Grid,
  Heading,
  Icon,
  Link,
  Select,
  Spacer,
  Text,
} from '@components/common'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { ASSET_STRUCTURE_TRANSFER_FORM_ID, COMPANY_PATHS } from '@constants'
import AssetStructureChangeSummary from './AssetStructureChangeSummary'
import usePermission from '@components/Auth/Permissions/usePermission'

interface Props {
  fields: FieldLabels
  getSegmentOptions: (KY_NR: number) => ReactSelectOptions
  assetCompanyID: number
  handleValidateTransfer: (params: AssetTransferParams) => Promise<void>
  isStructuresChanged: boolean
  activeTransfers: { field: string; from: string; to: string }[]
  periodOptions: ReactSelectOptions
  yearOptions: ReactSelectOptions
  isValidatingTransfer: boolean
}

const AssetStructureForm = ({
  fields,
  getSegmentOptions,
  assetCompanyID,
  handleValidateTransfer,
  isStructuresChanged,
  activeTransfers,
  periodOptions,
  yearOptions,
  isValidatingTransfer,
}: Props) => {
  const {
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    control,
    handleSubmit,
  } = useFormContext<AssetTransferParams>()

  const { getSelectProps } = useFormFieldProps<AssetTransferParams>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  const { canEditAssets: canEdit } = usePermission()

  return (
    <Form
      id={ASSET_STRUCTURE_TRANSFER_FORM_ID}
      noValidate
      onSubmit={handleSubmit(handleValidateTransfer)}
    >
      <Grid>
        <Grid.Item s={8}>
          <Card>
            <Heading type="h3">Asset account segments</Heading>
            <Text p light>
              Edit the fields below to transfer this asset across account segments.{' '}
              <Link icon={<Icon name="ExternalLink" />} to={`/segments`} setPreviousPage>
                View segments
              </Link>
              .
            </Text>
            <Spacer y="default" />
            <Grid>
              {[...Array(10).keys()].map((_, i) => (
                <Grid.Item key={i}>
                  <Controller
                    control={control}
                    name={`key${i}` as AssetTransferParamsKey}
                    render={({ field }) => (
                      <Select
                        {...getSelectProps(
                          `AS_KEY${i}_ID` as any,
                          field,
                          getSegmentOptions(i)
                        )}
                        disabled={!canEdit}
                        isSearchable
                      />
                    )}
                  />
                </Grid.Item>
              ))}
            </Grid>
          </Card>
        </Grid.Item>
        <Grid.Item s={4}>
          <Card>
            <Heading type="h3">Transfer parameters</Heading>
            <Text p light>
              Set the fiscal year and period number to record this structure transfer for.{' '}
              <Link
                icon={<Icon name="ExternalLink" />}
                to={`/${COMPANY_PATHS.ROOT}/${assetCompanyID}/${COMPANY_PATHS.PERIODS}`}
                setPreviousPage
              >
                View periods
              </Link>
              .
            </Text>

            <Spacer y="default" />

            <Grid>
              <Grid.Item s={6}>
                <Controller
                  control={control}
                  name="year"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('year', field, yearOptions)}
                      isSearchable
                      disabled={!isStructuresChanged}
                      label="As of fiscal year"
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={6}>
                <Controller
                  control={control}
                  name="period"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('period', field, periodOptions)}
                      isSearchable
                      disabled={!isStructuresChanged}
                      label="As of period"
                    />
                  )}
                />
              </Grid.Item>
              {isStructuresChanged && (
                <>
                  <Grid.Item>
                    <Divider spacing="default" />
                    <AssetStructureChangeSummary activeTransfers={activeTransfers} />
                  </Grid.Item>
                </>
              )}
              <Grid.Item>
                <Button
                  type="submit"
                  disabled={!isDirty || !isValid}
                  flex
                  appearance="primary"
                  loading={isValidatingTransfer}
                >
                  Submit structure transfer
                </Button>
              </Grid.Item>
            </Grid>
          </Card>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default AssetStructureForm
