import { AllAssetDetailFields, Asset, FieldLabels } from '@api/models'
import { Button, Card, Note, Spacer, Link, Text } from '@components/common'
import { Dispatch, SetStateAction } from 'react'
import AssetBookInformationDisplay from './AssetBookInformationDisplay'
import AssetBookInformationForm from './AssetBookInformationForm'
import { IUserGrantProps } from '@components/Auth/UserRole'
import AssetBookInformationDisplayLoading from './AssetBookInformationDisplayLoading'

interface Props extends IUserGrantProps {
  asset: Asset
  fields: FieldLabels
  isLoadingInfo: boolean
  assetHasHistory: boolean
  dirtyFields: object
  isAssetBookInfoFormActive: boolean
  setIsAssetBookInfoFormActive: Dispatch<SetStateAction<boolean>>
  isAssetDisposedOrPendingDisposal: boolean
}

const AssetBookInformation = ({
  asset,
  fields,
  isLoadingInfo,
  assetHasHistory,
  dirtyFields,
  isAssetBookInfoFormActive,
  setIsAssetBookInfoFormActive,
  isAssetDisposedOrPendingDisposal,
  canEdit,
}: Props) => {
  const bookInformationFields: Array<keyof AllAssetDetailFields> = [
    'AS_FIN_COS',
    'AS_REC_DAT',
    'AS_TAX_BUS',
    'AS_FIN_SAL',
    'AS_CAT_ID',
    'AS_TCLS_ID',
    'AS_4CLS_ID',
  ]

  let isCardValuesEdited: boolean
  for (const field of bookInformationFields) {
    if (dirtyFields[field] === true) {
      isCardValuesEdited = true
    }
  }

  var editComponent = isCardValuesEdited ? (
    <Text light>Edited</Text>
  ) : (
    !isAssetDisposedOrPendingDisposal && (
      <Button
        onClick={() => setIsAssetBookInfoFormActive(!isAssetBookInfoFormActive)}
        appearance="text"
      >
        {isAssetBookInfoFormActive ? 'Close' : 'Edit'}
      </Button>
    )
  )

  return (
    <Card title="Book information" {...(canEdit && { addon: editComponent })}>
      {(isAssetBookInfoFormActive || isCardValuesEdited) &&
      !isAssetDisposedOrPendingDisposal ? (
        <AssetBookInformationForm fields={fields} />
      ) : (
        <>
          {isLoadingInfo && <AssetBookInformationDisplayLoading />}
          {!isLoadingInfo && <AssetBookInformationDisplay fields={fields} />}
        </>
      )}

      {assetHasHistory && !isAssetDisposedOrPendingDisposal && !isLoadingInfo && (
        <>
          <Spacer y="default" />
          <Note
            content={
              <>
                Asset has depreciation history. Adjusting book information values will
                require depreciation recalculation after saving.{' '}
                <Link
                  to={`/assets/${asset.AssetCompanyID}/${asset.AssetID}/history?profile=C`}
                >
                  View history
                </Link>
                .
              </>
            }
          />
        </>
      )}
    </Card>
  )
}
export default AssetBookInformation
