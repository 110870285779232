import View from '@components/layout/View'
import CustomizeFormatPrefs from '@components/settings/customize-format/CustomizeFormatsPrefs'
//For Date Format Selection on Setting Page
const CustomizeFormatView = () => {
  return (
    <View title="Customize Format" showHeader={false}>
      <CustomizeFormatPrefs />
    </View>
  )
}

export default CustomizeFormatView
