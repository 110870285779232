import React, { useState } from 'react'
import { TabsProps, TabsSubComponents } from './Tabs.types'

import TabItem from './TabItem'
import TabButton from './TabButton'

import * as styles from './Styles'
import { useStyletron } from 'styletron-react'

const Tabs: React.FC<TabsProps> & TabsSubComponents = ({
  inCard = false,
  children,
}) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const [css] = useStyletron()

  return (
    <>
      <div className={css(styles.listWrapper({ inCard }))}>
        <div role="tablist" className={css(styles.list)}>
          {children.map((item, index) => (
            <TabButton
              key={item.props.title}
              title={item.props.title}
              index={index}
              setSelectedTab={setSelectedTab}
              selected={selectedTab === index}
              // passing styles here to access the selectedTab === index prop

              // indicator={
              //   appearance === 'buttons' &&
              //   selectedTab === index && (
              //     <motion.span
              //       layoutId="tabButtonBackground"
              //       className={css(styles.buttonBackground)}
              //       initial={false}
              //       transition={SPRING_MOTION}
              //     />
              //   )
              // }
            />
          ))}
        </div>
      </div>

      {children.map((item, index) => (
        <div
          key={item.props.title}
          className={selectedTab === index ? 'visible' : 'hidden'}
          hidden={selectedTab !== index}
          role="tabpanel"
          id={`${item.props.title.toLowerCase().replace(/\s+/g, '-')}-tab-id`}
          aria-labelledby={`${item.props.title
            .toLowerCase()
            .replace(/\s+/g, '-')}-tab-id`}
        >
          {item}
        </div>
      ))}
      {/* {children[selectedTab]} */}
    </>
  )
}

Tabs.Item = TabItem

export default Tabs
