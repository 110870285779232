import { StyleObject } from '@styles-api/css.types'

export const type = ({ type }) => {
  let font = `var(--${type})`
  return {
    font: font,
  }
}
export const centered: StyleObject = {
  textAlign: 'center',
}
export const eyebrow: StyleObject = {
  fontSize: 'var(--small-font-size)',
  fontWeight: 'var(--font-weight-semiBold)',
  textTransform: 'uppercase',
  letterSpacing: '0.4px',
  display: 'block',
}

export const h1: StyleObject = {
  font: 'var(--h1)',
}
export const h2: StyleObject = {
  font: 'var(--h2)',
}
export const h3: StyleObject = {
  font: 'var(--h3)',
}
export const h4: StyleObject = {
  font: 'var(--h4)',
}
export const h5: StyleObject = {
  font: 'var(--h5)',
}
export const h6: StyleObject = {
  font: 'var(--h6)',
}
export const baseSize: StyleObject = {
  font: 'var(--font-weight-semiBold) var(--base-font-size)/1.4em var(--font-family)',
}
export const underline: StyleObject = {
  paddingBottom: 'var(--scale200)',
  borderBottomWidth: '1px',
}

export const heading_centeredStyles: StyleObject = {
  textAlign: 'center',
}
export const light: StyleObject = {
  color: 'var(--shade7)',
}
export const bold: StyleObject = {
  fontWeight: 'var(--font-weight-bold)!important',
}

export const primary: StyleObject = {
  color: 'var(--primary-color)',
}

export const shadow: StyleObject = {
  textShadow: '1px 1px 0px rgba(0,0,0,0.2)',
}
