import { useState, useRef } from 'react'
import { clamp, distance } from 'popmotion'
import move from 'array-move'

export function usePositionReorder(visibleColumnsInitState) {
  const [order, setOrder] = useState(visibleColumnsInitState)

  // We need to collect an array of height and position data for all of this component's
  // `Item` children, so we can later use that in calculations to decide when a dragging
  // `Item` should swap places with its siblings.
  const positions = useRef([]).current
  const updatePosition = (item, offset) => {
    positions[item] = offset
  }

  // Find the ideal index for a dragging item based on its position in the array, and its
  // current drag offset. If it's different to its current index, we swap this item with that
  // sibling.
  const updateOrder = (item, dragOffset) => {
    const targetIndex = findIndex(item, dragOffset, positions)
    if (targetIndex !== item) setOrder(move(order, item, targetIndex))
    // setTimeout(() => setColumnOrder(order), 1000)
  }

  return [order, updatePosition, updateOrder]
}

const buffer = 18

export const findIndex = (item, yOffset, positions) => {
  let target = item
  const { top, height } = positions[item]
  const bottom = top + height

  // If moving down
  if (yOffset > 0) {
    var nextItem
    for (var i = 1; item + i <= positions.length; i++) {
      nextItem = positions[item + i]
      if (nextItem !== undefined) break
    }

    if (nextItem === undefined) return item
    const swapOffset =
      distance(bottom, nextItem.top + nextItem.height / 2) + buffer
    if (yOffset > swapOffset) target = item + 1

    // If moving up
  } else if (yOffset < 0) {
    var prevItem
    for (var j = 1; item - j >= 0; j++) {
      prevItem = positions[item - j]
      if (prevItem !== undefined) break
    }

    if (prevItem === undefined) return item
    const prevBottom = prevItem.top + prevItem.height
    const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer
    if (yOffset < -swapOffset) target = item - 1
  }

  return clamp(0, positions.length, target)
}
