import { FontWeight } from '@styles-api/typography.types'
import { StyleObject } from '@styles-api/css.types'

export const base: StyleObject = {}
export const table: StyleObject = {
  width: '100%',
  borderSpacing: 0,
  position: 'relative',
  borderCollapse: 'collapse',
}
export const cell: StyleObject = {
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
  paddingTop: 'var(--scale300)',
  paddingBottom: 'var(--scale300)',
  textAlign: 'left',
}
export const cellBold = bold => {
  let fontWeight: FontWeight
  if (bold === true) fontWeight = 'var(--font-weight-bold)'
  return {
    fontWeight,
  }
}
export const headerRow: StyleObject = {
  backgroundColor: 'var(--shade50)',
  borderBottomWidth: '1px',
}
export const header: StyleObject = {
  borderBottomWidth: '1px',
}
export const row: StyleObject = {
  minHeight: 'var(--size-default)',
  ':nth-child(even)': {
    background: 'var(--shade50)',
  },
}
