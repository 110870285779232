import moment from 'moment'
import Icon from '../Icon'
import { Select, Spacer } from '@components/common'
import { headerContainer, navigationIcon } from './Styles'

export interface CustomHeaderProps {
  date: Date
  changeYear: (year: string) => void
  changeMonth: (month: string) => void
  decreaseMonth: () => void
  increaseMonth: () => void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
}

export const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
}: CustomHeaderProps) => {
  const yearsOptions = Array.from({ length: 50 }, (_, i) => ({
    label: moment(date).year() - 25 + i,
    value: moment(date).year() - 25 + i,
  }))

  const monthsOptions = moment.months().map((month, i) => ({
    label: month,
    value: i,
  }))

  const selectedYear = moment(date).year()
  const selectedYearOption = yearsOptions.find(option => option.value === selectedYear)

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        'MyDropdown__option--is-selected'
      )[0]
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      }
    }, 15)
  }

  return (
    <div style={headerContainer}>
      <span onClick={decreaseMonth} style={navigationIcon}>
        <Icon name="ChevronLeft" />
      </span>

      <Select
        width={'80px'}
        onChange={option => changeYear(option.value.toString())}
        value={selectedYearOption}
        options={yearsOptions}
        onMenuOpen={onMenuOpen}
        className="MyDropdown"
        classNamePrefix={'MyDropdown'}
      />

      <Spacer x="mini" />

      <Select
        flexGrow
        onChange={option => changeMonth(option.value.toString())}
        value={{ label: moment(date).format('MMMM'), value: moment(date).month() }}
        options={monthsOptions}
        onMenuOpen={onMenuOpen}
        className="MyDropdown"
        classNamePrefix={'MyDropdown'}
      />

      <span onClick={increaseMonth} style={navigationIcon}>
        <Icon name="ChevronRight" />
      </span>
    </div>
  )
}
