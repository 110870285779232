import HttpClient from './HttpClient'

export class ImportApi extends HttpClient {
  private static classInstance?: ImportApi

  public constructor() {
    super(``, undefined, 'multipart/form-data')
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new ImportApi()
    }

    return this.classInstance
  }

  public importAll = (files: any) =>
    this.instance.post(`/Import/ImportAll`, files, {
      headers: { 'content-type': 'multipart/form-data' },
    })

  public importGLPosts = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/GLPost/ImportGLPost`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  public importAssets = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/Asset/ImportAssets`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  public importCompanies = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/AssetCompanies/ImportAssetCompanies`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  public importSegments = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/Segment/ImportSegment`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  public importCategories = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/Deprecs/ImportDeprecs`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  public importVendors = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/Vendor/ImportVendor`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  public importFolders = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/FldrMaster/ImportFolder`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  public importTasks = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/Other/ImportOther`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  public importInsurance = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/Insurance/ImportInsurance`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  public importMaintenance = async (file: FormData, mapping: unknown) => {
    file.append('mapping', JSON.stringify(mapping))
    return await this.instance.post(`/Maintenance/ImportMaintenance`, file, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  }
}
