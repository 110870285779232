import { useStyletron } from 'styletron-react'
import { useDepreciationStatus } from './useDepreciationStatus'
import * as styles from './styles'
import { Text, TextSkeleton, Tooltip } from '@components/common'

const DepreciationStatus = () => {
  const [css] = useStyletron()

  const {
    depRunStatus,
    depRunStatusLoading,
    depRunStatusError,
    error,
  } = useDepreciationStatus()

  const { AsOfDate, FiscalYear, IsRunningDepreciation, NumberOfPeriods, Period } =
    depRunStatus || {}

  const getCurrentRunStatusMessage = () => {
    if (depRunStatusError) {
      return error['data']
    } else if (depRunStatus && IsRunningDepreciation === true) {
      if (AsOfDate !== null) {
        return `Depreciation run as of ${AsOfDate}.`
      } else if (Period < NumberOfPeriods) {
        return `Depreciation run for Period ${Period} of ${FiscalYear}.`
      } else if (Period === NumberOfPeriods) {
        return `Depreciation run for Year ${FiscalYear}.`
      }
    } else if (depRunStatus && IsRunningDepreciation === false) {
      return 'No depreciation runs at the moment.'
    }
    return ''
  }

  const currentRunStatusMessage = getCurrentRunStatusMessage()

  return (
    <Tooltip content={currentRunStatusMessage} position='bottom'>
    <div className={css(styles.depStatusCard)}>
      {/* TODO: Loading style */}
      {depRunStatusLoading && <TextSkeleton width="250px">Loading...</TextSkeleton>}

      {!depRunStatusLoading && currentRunStatusMessage && !error && (
        <Text className={css(styles.depStatusText({ IsRunningDepreciation }))}>
          {currentRunStatusMessage}
        </Text>
      )}

      {depRunStatusError && (
        <Text className={css(styles.depErrorText)}>{error['data']}</Text>
      )}
    </div>
    </Tooltip>
  )
}

export default DepreciationStatus
