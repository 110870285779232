import { Form, Spacer } from '@components/common'
import { ListReportParams } from '@api/models'
import { FormProvider, useForm } from 'react-hook-form'
import { ActionButtons } from '../General'
import { useMemorizeReport } from '../General/MemorizeReports/useMemorizeReport'
import MemorizeReports from '../General/MemorizeReports/MemorizeReport'

interface IListReportFormProps {
  onSubmitHandler: (values: ListReportParams) => void
  allowExcel?: boolean
  path: string
}

const ListReportForm = ({
  onSubmitHandler,
  path,
  allowExcel = true,
}: IListReportFormProps) => {
  const methods = useForm<ListReportParams>({
    defaultValues: {
      fileFormat: 'pdf',
    },
  })
  const pdfOnlyLists =
    window.location.href.split('/').includes('category-list-report') ||
    window.location.href.split('/').includes('tax-class-list-report')

  const {
    formState: { isSubmitting },
  } = methods

  const {
    handleMemorizeTemplateSubmit,
    isMemorizeTemplateModalOpen,
    closeMemorizeTemplateModal,
    triggerMemorizeTemplateModal,
  } = useMemorizeReport({ methods, currentReportPath: path })

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          {/* <Card title={'Report Parameters'}>
            <Grid>
              <Grid.Item></Grid.Item>
            </Grid>
          </Card> */}
          <Spacer y="default" />
          <ActionButtons
            isSubmitting={isSubmitting}
            pdfOnly={pdfOnlyLists ? true : false}
            allowExcel={allowExcel}
            triggerMemorizeTemplateModal={triggerMemorizeTemplateModal}
          />
        </Form>
      </FormProvider>

      <MemorizeReports
        isMemorizeTemplateModalOpen={isMemorizeTemplateModalOpen}
        closeMemorizeTemplateModal={closeMemorizeTemplateModal}
        handleMemorizeTemplateSubmit={handleMemorizeTemplateSubmit}
      />
    </>
  )
}

export default ListReportForm
