import { SKELETON_SHINE } from '@styles-api/css-constants'
import { StyleObject } from '@styles-api/css.types'

export const text_mainStyles: StyleObject = {
  font: 'var(--paragraphFont)',
}
export const text_lightStyles: StyleObject = {
  color: 'var(--text-light)',
}
export const text_blockStyles: StyleObject = {
  display: 'block',
}
export const text_boldStyles: StyleObject = {
  fontWeight: 'var(--font-weight-bold)',
}
export const text_italicStyles: StyleObject = {
  fontStyle: 'italic',
}
export const text_successStyles: StyleObject = {
  color: 'var(--success-color)',
}
export const text_warningStyles: StyleObject = {
  color: 'var(--warning-color)',
}
export const text_dangerStyles: StyleObject = {
  color: 'var(--danger-color)',
}
export const text_smallStyles: StyleObject = {
  fontSize: 'var(--small-font-size)',

  lineHeight: '1.5em',
}
export const text_centeredStyles: StyleObject = {
  textAlign: 'center',
}

// skeleton
export const skeleton = ({ width, height }) => {
  return {
    ...SKELETON_SHINE,
    display: 'inline-block',
    height: height ? height : 'var(--scale400)',
    width: width,
    borderRadius: 'var(--radius1)',
  }
}
