import {
  Button,
  Card,
  Checkbox,
  Flexbox,
  Form,
  Heading,
  Modal,
  Note,
  Select,
  Spacer,
  Table,
  Text,
} from '@components/common'
import useCompanyPeriods from '@hooks/useCompanyPeriods'
import { COMPANY_GENERATE_PERIODS_FORM_ID, COMPANY_PERIOD_FORM_ID } from '@constants'
import CompanyPeriodsTable from './CompanyPeriodsTable'
import SaveBar from '@components/SaveBar'
import DiscardModal from '@components/DiscardModal'
import { Controller, FormProvider } from 'react-hook-form'
import GeneratePeriodsForm from './GeneratePeriodsForm'
import CompanyHasNoPeriods from './CompanyHasNoPeriods'
import usePermission from '@components/Auth/Permissions/usePermission'
import CompanyPeriodsLoading from './CompanyPeriodsLoading'

interface CompanyPeriodsProps {
  assetCompanyID: number
}

const CompanyPeriods = ({ assetCompanyID }: CompanyPeriodsProps) => {
  const {
    mostRecentGeneratedFiscalYear,
    year,
    yearOptions,
    handleChangeYear,
    periods,
    isRefetchingPeriods,
    tableColumns: tableColumnsCallback,
    isLoading,
    companyHasPeriods,
    setIsEditing,
    isEditing,
    methods,
    isDirty,
    isValid,
    isLoadingEditPeriods,
    isDiscardModalOpen,
    setIsDiscardModalOpen,
    discardChanges,
    generatePeriodsFormMethods,
    isGeneratePeriodsModalOpen,
    isLoadingGeneratePeriods,
    setIsGeneratePeriodsModalOpen,
    fields,
    handleGeneratePeriods,
    handleEditPeriods,
    sumOfPercentages,
    havePercentagesChanged,
    getCheckBoxProps,
    control,
    navigate,
    previousPage,
  } = useCompanyPeriods({
    assetCompanyID,
  })

  const { canEditCompanies: canEdit } = usePermission()

  if (isLoading) return <CompanyPeriodsLoading />

  return (
    <>
      {/* Save / Discard */}
      <SaveBar
        formId={COMPANY_PERIOD_FORM_ID}
        isVisible={isDirty}
        isSaveDisabled={!isValid}
        isSubmitting={isLoadingEditPeriods}
        openDiscardModal={() => setIsDiscardModalOpen(true)}
        shouldBlockNavigation={isDirty}
      />
      <DiscardModal
        modalIsOpen={isDiscardModalOpen}
        closeModal={() => setIsDiscardModalOpen(false)}
        discard={discardChanges}
      />

      {/* Generate Periods Modal */}

      <FormProvider {...generatePeriodsFormMethods}>
        <Modal
          isOpen={isGeneratePeriodsModalOpen}
          close={() => setIsGeneratePeriodsModalOpen(false)}
          form={COMPANY_GENERATE_PERIODS_FORM_ID}
          isActionLoading={isLoadingGeneratePeriods}
          title={`Generate periods for company ${assetCompanyID}`}
          actionLabel="Generate periods"
          actionDisabled={!generatePeriodsFormMethods.formState.isValid}
        >
          <GeneratePeriodsForm
            fields={fields}
            handleGeneratePeriods={handleGeneratePeriods}
            companyHasPeriods={companyHasPeriods}
            assetCompanyID={assetCompanyID}
            mostRecentGeneratedFiscalYear={mostRecentGeneratedFiscalYear}
          />
        </Modal>
      </FormProvider>

      {/* Main Table/Form UI*/}
      {!companyHasPeriods ? (
        <CompanyHasNoPeriods
          setIsGenerateModalOpen={setIsGeneratePeriodsModalOpen}
          assetCompanyID={assetCompanyID}
          canEdit={canEdit}
        />
      ) : (
        <Form
          id={COMPANY_PERIOD_FORM_ID}
          noValidate
          onSubmit={methods.handleSubmit(handleEditPeriods)}
        >
          {isEditing && (
            <>
              <Note
                appearance="elevated"
                content={
                  <>
                    <Heading eyebrow type="h3">
                      Editing periods for fiscal year {year}
                    </Heading>
                    <Spacer y="mini" />
                    <Text p>
                      Period end dates must fall after the previous period end date and
                      before the following period end date.
                    </Text>
                    <Text p>
                      If the sum of percentages of fiscal year for each period does not
                      equal 100%, the final period of the fiscal year will be adjusted
                      accordingly after saving.
                    </Text>
                  </>
                }
              />
              <Spacer y="default" />
              {havePercentagesChanged && sumOfPercentages !== 100 && (
                <>
                  <Note
                    intent="danger"
                    appearance="elevated"
                    content={
                      <>
                        The current sum of percentages is <b>{sumOfPercentages}%</b>. After
                        saving, the last period will be adjusted to reach a sum of 100%.
                      </>
                    }
                  />
                  <Spacer y="default" />
                </>
              )}
            </>
          )}
          <Card spacing="none">
            <Table.Actions>
              <Flexbox justifyContent="space-between">
                <Flexbox>
                  <Select
                    value={{
                      label: (
                        <>
                          <b>Fiscal year:</b> {year}
                        </>
                      ),
                      value: year,
                    }}
                    options={yearOptions}
                    onChange={handleChangeYear}
                    width={'164px'}
                    appearance="clear"
                    disabled={isEditing}
                  />
                </Flexbox>

                {canEdit && (
                  <>
                    <Flexbox alignItems="center">
                      {isEditing && (
                        <>
                          <Controller
                            control={control}
                            name="AllowWeekends"
                            render={({ field }) => (
                              <Checkbox
                                {...getCheckBoxProps(field)}
                                label="Allow weekends"
                              />
                            )}
                          />
                          <Spacer x="mini" />
                          |
                          <Spacer x="mini" />
                          <Button
                            type="submit"
                            appearance="primary"
                            disabled={!isValid || !isDirty}
                            loading={isLoadingEditPeriods}
                          >
                            Save changes
                          </Button>
                          <Spacer x="mini" />
                        </>
                      )}
                      <Button
                        onClick={() => setIsEditing(prevState => !prevState)}
                        disabled={isDirty}
                        type="button"
                      >
                        {isEditing ? 'Disable' : 'Enable'} editing
                      </Button>
                      <Spacer x="mini" />
                      <Button
                        appearance="primary"
                        disabled={isEditing}
                        onClick={() => setIsGeneratePeriodsModalOpen(true)}
                        type="button"
                      >
                        Generate periods
                      </Button>
                    </Flexbox>
                  </>
                )}
              </Flexbox>
            </Table.Actions>

            <CompanyPeriodsTable
              columns={tableColumnsCallback}
              periods={periods}
              isRefetching={isRefetchingPeriods}
              isEditing={isEditing}
            />
          </Card>
        </Form>
      )}

      {previousPage && (
        <Button onClick={() => navigate(previousPage)} style={{ marginTop: '10px' }}>
          Go back to asset
        </Button>
      )}
    </>
  )
}

export default CompanyPeriods
