import { Link } from 'react-router-dom'
import { useStyletron } from 'styletron-react'
import * as styles from './styles'
import { useState } from 'react'
import { Button, Card, Heading } from '@components/common'
import { Text } from '@components/common'
import { motion as m } from 'framer-motion'

const ReportMenuNavLink = ({
  to,
  title,
  description,
  keyValue,
  showMenu,
  setShowMenu,
}: {
  to: string
  title: string
  description: string
  keyValue: number
  showMenu: Boolean
  setShowMenu: Function
}) => {
  const [css] = useStyletron()

  const [istooltipVisible, setIsTooltipVisible] = useState(false)

  const animationProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.4, delay: 0.5 },
  }

  const toggleShowMenu = () => {
    setShowMenu(!showMenu)
  }

  const handleMouseEnter = () => {
    setIsTooltipVisible(true)
  }

  const handleMouseLeave = () => {
    setIsTooltipVisible(false)
  }

  const shortDescription = description ? description : ' '

  const isShortDescriptionLong = shortDescription.length > 100
  const isShortDescriptionEmpty = shortDescription.length === 1

  return (
    <Link to={to} onClick={toggleShowMenu}>
      <Card key={keyValue}>
        <Heading type="h3" primary style={{ height: '50px' }}>
          {title}
        </Heading>

        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ minHeight: !isShortDescriptionEmpty ? '90px' : '0' }}
        >
          <Text className={css(styles.shortDescription)}>
            {!isShortDescriptionEmpty &&
              shortDescription.slice(0, 100) + `${isShortDescriptionLong ? '...' : ''}`}
          </Text>
        </div>

        {istooltipVisible && isShortDescriptionLong && (
          <m.div {...animationProps} className={css(styles.shortDescriptionTip)}>
            <Text p={true}>{shortDescription}</Text>
          </m.div>
        )}

        <Button className={css(styles.link)}>View</Button>
      </Card>
    </Link>
  )
}

export default ReportMenuNavLink
