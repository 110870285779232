import { useGetFieldsQuery } from '@api'
import { FieldLabels, GetFieldsResponse } from '@api/models'

interface FieldLabelHook {
  data: GetFieldsResponse
  fields: FieldLabels
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  isFetching: boolean
}

interface FieldsHookProps {
  skipRequest?: boolean
}

export default function useFields({
  skipRequest = false,
}: Partial<FieldsHookProps> = {}): FieldLabelHook {
  const { data, isLoading, isError, isSuccess, isFetching } = useGetFieldsQuery(undefined, {
    skip: skipRequest,
  })

  let fields = {}
  if (data) {
    fields = data.reduce((o, key) => ({ ...o, [key.Name]: key.Value }), {})
  }

  return {
    fields,
    data,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  }
}
