import React from 'react'
import { SpacerProps } from './Spacer.types'

import { useStyletron } from 'styletron-react'
import { StyleObject } from '@styles-api/css.types'

const Spacer: React.FC<SpacerProps> = ({ y, x }) => {
  var ySpace
  if (y) ySpace = `var(--gap-${y})`

  var xSpace
  if (x) xSpace = `var(--gap-${x})`

  const styles: StyleObject = {
    display: 'block',
    width: '1px',
    height: '1px',
    marginTop: ySpace,
    marginRight: xSpace,
  }
  const [css] = useStyletron()

  return <span className={css(styles)} aria-hidden={true} />
}
export default Spacer
