import { Flexbox, Spinner, Spacer, Heading, Text } from '@components/common'
import { useStyletron } from 'styletron-react'

import { StyleObject } from '@styles-api/css.types'

interface DepreciationCardProps {
  disabled: boolean
  loading: boolean
  openForm: () => void
  helpText: string
  icon: any
  type: 'Run' | 'Update' | 'Undo' | 'Review' | 'Discard'
  titleColor?: string
}

const containerStyle = (disabled: boolean): StyleObject => ({
  color: 'var(--contrast-color)',
  backgroundColor: disabled ? 'var(--shade50)' : 'var(--base-color)',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'center',
  borderWidth: '1px',
  minHeight: '9em',
  borderColor: 'var(--shade300)',
  boxSizing: 'border-box',
  userSelect: 'none', // Prevent text selection
  msUserSelect: 'none', // Prevent text selection in IE 10 and IE 11
  WebkitUserSelect: 'none', // Prevent text selection in Safari
  borderRadius: 'var(--radius2)',
  cursor: disabled ? 'not-allowed' : 'pointer',
  padding: 'var(--scale400)',
  shadow: 'var(--shadow400)',
  ':hover': {
    backgroundColor: disabled ? 'var(--danger-color50)' : 'var(--shade100)',
  },
})

const DepreciationCard = ({
  disabled,
  loading,
  openForm,
  helpText,
  icon,
  type,
  titleColor = 'var(--primary-color500)',
}: DepreciationCardProps) => {
  const [css] = useStyletron()
  return (
    <div
      className={css(containerStyle(disabled))}
      onClick={!disabled ? openForm : undefined}
    >
      {loading ? (
        <Flexbox justifyContent="center">
          <Spinner size="large" />
        </Flexbox>
      ) : (
        <>
          <Flexbox justifyContent="center" style={{ paddingTop: 'var(--scale450)' }}>
            {icon}
            <Spacer x="default" />
            <Heading type="h2" style={{ color: titleColor }}>
              {type}
            </Heading>
          </Flexbox>

          <Spacer y="default" />

          <Flexbox justifyContent="center">
            <Text
              style={{
                color: 'var(--shade400)',
                overflow: 'visible',
              }}
            >
              {helpText}
            </Text>
          </Flexbox>
        </>
      )}
    </div>
  )
}

export default DepreciationCard
