import { Company, FieldLabels } from '@api/models'
import { Grid, Input } from '@components/common'
import { useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import { CompanyHookContext } from '@hooks/useCompany'

interface Props {
  fields: FieldLabels
  context: CompanyHookContext
  company: Company
}

const CompanyBasicInfoForm = ({ fields, context, company }: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<Company>()

  const { getInputProps } = useFormFieldProps<Company>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  return (
    <Grid>
      {context === 'create-company' ? (
        <Grid.Item>
          <Input {...getInputProps('AssetCompanyID')} label="Company ID" autoFocus />
        </Grid.Item>
      ) : (
        <Grid.Item style={{ display: 'none' }}>
          <Input {...getInputProps('AssetCompanyID')} value={company.AssetCompanyID} />
        </Grid.Item>
      )}
      <Grid.Item>
        <Input {...getInputProps('CM_DESC')} autoFocus={context === 'company-details'} />
      </Grid.Item>
      <Grid.Item>
        <Input {...getInputProps('CM_DESCS')} />
      </Grid.Item>
    </Grid>
  )
}

export default CompanyBasicInfoForm
