import { forwardRef, useEffect, useRef, useState } from 'react'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import { CustomDatePickerProps } from './date.types'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import Icon from '../Icon'
import moment from 'moment'
import { useGetSettingsQuery } from '@api'
import LoadingDots from '../LoadingDots'
import { CustomHeader } from './CustomHeader'
import CustomDatePickerInput from './CustomDatePickerInput'

import './CustomStyles.scss'

function parseDate(date: string) {
  var day = moment(date).date()
  var month = moment(date).month()
  var year = moment(date).year()
  return new Date(year, month, day, 0, 0, 0, 0)
}

const CustomInput = forwardRef((props: any, ref) => {
  return <CustomDatePickerInput {...props} ref={ref} />
})

const CustomDatePicker = forwardRef<HTMLInputElement, CustomDatePickerProps>(
  (
    {
      type = 'date',
      label,
      size = 'default',
      error = false,
      helper,
      errorMsg,
      disabled,
      autoFocus,
      iconRight,
      width = '100%',
      flexGrow = false,
      appearance = 'default',
      id,
      ...props
    },
    ref
  ) => {
    const [css] = useStyletron()
    const [focus, setFocus] = useState(false)
    const [startDate, setStartDate] = useState<Date | null>(parseDate(props.select))
    const { data: DateFormat, isLoading: isLoadingDateFormat } = useGetSettingsQuery(
      'DateFormat'
    )

    const inputRef = useRef(null)

    useEffect(() => {
      if (props.select === '0001-01-01T00:00:00') {
        setStartDate(null)
      } else {
        setStartDate(parseDate(props.select))
      }
    }, [props.select])

    if (isLoadingDateFormat) return <LoadingDots />

    const DatePickerFormat = DateFormat ? DateFormat : 'yyyy/MM/dd'

    let containerStyles = css({
      ...styles.container({ width, flexGrow }),
      ...styles.inputBaseDisabled({ disabled }),
    })

    let labelStyles = {
      ...styles.label({ disabled, label }),
    }
    let labelClassName = css(labelStyles)

    if (props.readOnly) containerStyles += ' ' + css(styles.getReadOnly)
    let helperStyles = css({
      ...styles.helperText({ disabled }),
    })

    let errorMsgClassName = css(styles.errorMsg)
    let errorIconStyles = css(styles.errorMsgIcon)

    const handleChangeRaw = (date: any) => {
      const newRaw = new Date(date.currentTarget.value)
      if (newRaw instanceof Date && !isNaN(newRaw as any)) {
        setStartDate(newRaw)
      }
    }

    const onDateChange = (date: Date | null) => {
      setStartDate(date)
      if (!date) return
      props.onChange(moment(date).format('yyyy-MM-DD')) //This date is being passed to the form.
      props.onBlur(moment(date).format('yyyy-MM-DD'))
    }

    return (
      <div className={containerStyles}>
        <label htmlFor={props.name} className={labelClassName} id={`${props.name}-label`}>
          {label
            ? `${props.required ? '*' : ''}${label}`
            : props.placeholder
            ? props.placeholder
            : `Enter value for ${props.name}`}
        </label>

        <div
          style={{
            ...styles.dateInputWrapper,
            ...styles.getFocus({ focus, error }),
          }}
        >
          <span
            className="icon"
            style={{
              ...styles.calendarIcon,
            }}
          >
            <Icon name="Calendar" size="s" />
          </span>

          <span
            style={{
              ...styles.dateInput,
              ...styles.inputWrapperDisabled({ disabled, appearance }),
              ...styles.getSize({ size, appearance, select: false }),
            }}
            onBlur={() => setFocus(false)}
          >
            <DatePicker
              selected={startDate}
              customInput={<CustomInput inputRef={inputRef} />}
              onChangeRaw={e => handleChangeRaw(e)}
              onChange={onDateChange}
              dateFormat={DatePickerFormat}
              label={props.name}
              todayButton="Select Today"
              placeholderText={DatePickerFormat}
              renderCustomHeader={CustomHeader}
              onFocus={() => setFocus(true)}
              onOutsideClick={() => setFocus(false)}
              disabled={disabled}
              id={id}
            />
          </span>
        </div>

        {helper && !error && <div className={helperStyles}>{helper}</div>}
        {errorMsg && error && (
          <div className={errorMsgClassName}>
            <Icon name="AlertCircle" className={errorIconStyles} /> {errorMsg}
          </div>
        )}
      </div>
    )
  }
)

export default CustomDatePicker
