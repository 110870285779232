import { Grid, Input, Note, Text, Textarea } from '@common'
import { Asset, FieldLabels } from '@api/models'
import { useFormFieldProps } from '@hooks'
import { useFormContext, Controller } from 'react-hook-form'

interface IdentifiersProps {
  fields: FieldLabels,
}

const Identifiers = ({ fields }: IdentifiersProps) => {
  const {
    formState: { isSubmitting, errors },
    register,
    control,
  } = useFormContext<Asset>()

  const { getInputProps } = useFormFieldProps({
    isSubmitting,
    errors,
    fields,
    register,
  })

  return (
    <Grid colGap="mini">
      <Grid.Item>
        <Text light>
          Set the identifiers for your new asset. More options (such as {fields.AS_SERIAL}{' '}
          and {fields.AS_PAR_ASS}) will be available once the asset is added.
        </Text>
      </Grid.Item>
      <Grid.Item>
        <Input {...getInputProps('AssetID')} autoFocus autocomplete="off" />
      </Grid.Item>
      <Grid.Item>
        <Input {...getInputProps('AS_DESC')} />
      </Grid.Item>
      <Grid.Item>
        <Controller
          name="AS_DESC_E"
          control={control}
          render={({ field }) => <Textarea 
            {...field} 
            label={fields['AS_DESC_E']} 
          />}
        />
      </Grid.Item>

      <Grid.Item>
        <Note
          intent="warning"
          content={'Asset ID cannot be changed after asset is added'}
        />
      </Grid.Item>
    </Grid>
  )
}

export default Identifiers
