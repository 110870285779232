import { Applications } from '@api/models'
import { Card, Heading, Spacer, Table } from '@components/common'
import { useMemo } from 'react'
import { Column, useTable, useSortBy, useFlexLayout } from 'react-table'
import OrganizationTableLoading from './OrganizationTableLoading'

export const OrganizationTable = ({ organizationData, isFetchingOrgData }) => {
  const applicationsColumns = useMemo<Column<Applications>[]>(
    () => [
      {
        Header: 'Application Name',
        accessor: 'name',
      },
      {
        Header: 'License Type',
        accessor: 'licenseType',
      },
      {
        Header: 'License Number',
        accessor: 'licenseNumber',
      },
      {
        Header: 'License Users Count',
        accessor: 'licenseUsersCount',
      },
    ],
    []
  )

  // Declare react table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns: applicationsColumns,
      data: organizationData ? organizationData.applications : [],
      manualSortBy: true,
      disableMultiSort: true,
    },
    useSortBy,
    useFlexLayout
  )
  return (
    <>
      <Heading type="h4">License Information</Heading>

      <Spacer y="default" />

      {isFetchingOrgData ? (
        <OrganizationTableLoading />
      ) : (
        <Card spacing="none">
          {organizationData?.applications.length === 0 ? (
            <Table.NoResults text="" />
          ) : (
            <Table
              {...getTableProps()}
              aria-label="License Information"
              label="License Information"
              aria-colcount={applicationsColumns.length}
              aria-rowcount={organizationData ? organizationData.applications.length : 0}
            >
              <Table.Head sticky>
                {isFetchingOrgData ? (
                  <Table.LoadingRows />
                ) : (
                  headerGroups.map((headerGroup, i) => (
                    <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, i) => (
                        <Table.Header
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          column={column}
                          key={i}
                          tabIndex={0}
                        >
                          {column.render('Header')}
                        </Table.Header>
                      ))}
                    </Table.HeadRow>
                  ))
                )}
              </Table.Head>

              <Table.Body {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Table.Row {...row.getRowProps()} tabIndex={0} key={i}>
                      {row.cells.map(cell => (
                        <Table.Cell {...cell.getCellProps()} column={cell.column}>
                          {cell.render('Cell')}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          )}
        </Card>
      )}
    </>
  )
}
