import { StyleObject } from '@styles-api/css.types'

export const container: StyleObject = {
  borderRadius: 'var(--radius1)',
  backgroundColor: 'var(--elevated-surface-background1)',
  boxShadow: 'var(--card-shadow)',
  overflow: 'hidden',
}

export const listItem: StyleObject = {
  borderBottomWidth: '1px',
  ':last-of-type': {
    borderBottomWidth: '0px',
  },
}
export const link = (isActive: boolean): StyleObject => {
  return {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'var(--size-default)',
    cursor: 'pointer',
    paddingLeft: 'var(--scale500)',
    paddingRight: 'var(--scale500)',
    borderBottomWidth: '1px',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: isActive ? 'var(--shade50)' : 'var(--base-color)',
    color: isActive ? 'var(--primary-color)' : 'var(--text-color)',
    ':last-of-type': {
      borderBottomWidth: '0px',
    },
    ':hover': {
      backgroundColor: isActive ? 'var(--shade50)' : 'var(--shade100)',
      color: 'var(--primary-color)',
    },
    ':active': {
      backgroundColor: 'var(--shade200)',
    },

    '::before': {
      position: 'absolute',
      height: '100%',
      width: '2px',
      backgroundColor: 'var(--primary-color)',
      left: 0,
      top: 0,
      content: '""',
      display: isActive ? 'block' : 'none',
    },
  }
}
