import { Card, Heading, Spacer } from '@components/common'
import UserTable from './UserTable'
import { useGetOrganizationQuery } from '@api/admPortal/organizationAPI'
import OrganizationTable from './OrganizationTable'
import OrganizationNameLoading from './OrganizationTable/OrganizationNameLoading'

const FixedAssetsAdmin = () => {
  const {
    data: organizationData,
    isFetching: isFetchingOrgData,
  } = useGetOrganizationQuery()

  return (
    <>
      <Card>
        <Heading type="h2" primary>
          Company Information
        </Heading>

        <Spacer y="default" />

        <Heading type="h4">Organization Name</Heading>

        <Spacer y="mini" />

        {isFetchingOrgData ? (
          <OrganizationNameLoading />
        ) : (
          <Heading type="h1" style={{ paddingLeft: 'var(--scale500)' }} shadow>
            {organizationData.name}
          </Heading>
        )}

        <Spacer y="large" />

        <OrganizationTable
          organizationData={organizationData}
          isFetchingOrgData={isFetchingOrgData}
        />
      </Card>

      <Spacer y="default" />

      <Card>
        <UserTable />
      </Card>
    </>
  )
}

export default FixedAssetsAdmin
