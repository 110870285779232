import HttpClient from '../HttpClient'

export class AttachmentApi extends HttpClient {
  private static classInstance?: AttachmentApi

  public constructor() {
    super(`/Attachment`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new AttachmentApi()
    }

    return this.classInstance
  }

  public upload = (
    assetCompanyId: number,
    objectIds: string,
    type: string,
    comment: string,
    file: any
  ) =>
    this.instance.post(
      `/UploadAttachment?assetCompanyId=${assetCompanyId}&objectIds=${objectIds}&type=${type}&comment=${comment}`,
      file
    )

  public delete = (id: number, type: string) =>
    this.instance.post(`/DeleteAttachment?id=${id}&type=${type}`)
  public edit = (data: any) => this.instance.post(`/EditAttachment`, data)
  public get = (objectId: string, type: string) =>
    this.instance.get(`/GetAttachments?objectId=${objectId}&type=${type}`)
  public assignFileToObject = (
    id: number,
    type: string,
    assetCompany: number,
    objectIds: string
  ) =>
    this.instance.post(
      `/AssignFileToObject?id=${id}&type=${type}&assetCompany=${assetCompany}&objectIds=${objectIds}`
    )
  public processImported = () => this.instance.get(`/ProcessImportedFiles`)
  public uploadImportFile = (file: any) => this.instance.post(`/UploadImportFile`, file)
  public uploadBlob = (file: any) => this.instance.post(`/UploadBlob`, file)
  public download = (fileName: string) =>
    this.instance.get(`/DownloadAttachmentFile?fileName=${fileName}`, {
      responseType: 'blob',
    })
  public deleteBlob = (fileName: string) =>
    this.instance.post(`/DeleteBlob?fileName=${fileName}`)
  public getOrgFolderSize = () => this.instance.get(`/GetOrganizationFolderSize`)
}
