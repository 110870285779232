import { useGetDepRunStatusQuery } from '@api/depreciation/depreciationStatus/depreciationStatusEndpoints'
import { useSelector } from 'react-redux'

export function useDepreciationStatus() {
  const assetCompanyID = useSelector<any>(state => state.company) as number

  const {
    data: depRunStatus,
    isLoading: depRunStatusLoading,
    isSuccess: depRunStatusSuccess,
    isError: depRunStatusError,
    error,
  } = useGetDepRunStatusQuery(assetCompanyID, {
    refetchOnMountOrArgChange: true,
  })

  return {
    assetCompanyID,
    depRunStatus,
    depRunStatusLoading,
    depRunStatusSuccess,
    depRunStatusError,
    error,
  }
}
