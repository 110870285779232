import { ButtonSkeleton, Card, Grid, Spacer, TextSkeleton } from '@components/common'

const CustomizeFormatLoading: React.FC = () => {
  return (
    <>
      <Grid>
        <Grid.Item m={6}>
          <Card>
            <TextSkeleton height="var(--scale1200)" />
            <Spacer y="default" />
            <TextSkeleton height="147px" />
            <Spacer y="default" />
            <ButtonSkeleton width="var(--scale1800)" />
          </Card>
        </Grid.Item>
      </Grid>
    </>
  )
}

export default CustomizeFormatLoading
