import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { FetchCoordinatesPayload, FetchCoordinatesResponse } from './models'

// Open Street Map API Slice
export const osmApiSlice = createApi({
  reducerPath: 'mapApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://nominatim.openstreetmap.org/' }),
  tagTypes: ['mapLocation'],
  endpoints: build => ({
    getLocationCoords: build.query<FetchCoordinatesResponse, FetchCoordinatesPayload>({
      query: payload => {
        const payloadAsArray = Object.keys(payload).map(key => payload[key])

        // Check if any of the payload values are null or undefined
        if (payloadAsArray.some(value => value === null || value === undefined)) {
          throw new Error('Payload data contains null or undefined values.')
        }

        const queryParams = {
          street: payloadAsArray[0].split(' ').join('+'),
          city: payloadAsArray[2],
          state: payloadAsArray[3].split(' ').join('+'),
          country: payloadAsArray[1].toLowerCase(),
          postalcode: payloadAsArray[4],
        }

        // Build the query string
        let queryString = Object.keys(queryParams)
          .map(key => queryParams[key] && `${key}=${queryParams[key]}`)
          .filter(Boolean)
          .join('&')

        queryString += '&format=jsonv2'
        return `search.php?${queryString}`
      },
      transformResponse: (res: any[]) => (res.length === 0 ? null : res[0]),
      providesTags: ['mapLocation'],
    }),
  }),
})

export const { useLazyGetLocationCoordsQuery } = osmApiSlice
