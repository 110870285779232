import FixedAssetsAdmin from '@components/AdmPortal/FixedAssetsAdmin'
import View from '@components/layout/View'

const FixedAssetsAdminView = () => {
  return (
    <View title="Administration Portal" showHeader={true}>
      <FixedAssetsAdmin />
    </View>
  )
}

export default FixedAssetsAdminView
