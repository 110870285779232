import { ImportColumn } from "../importTypes";

export const vendors: ImportColumn[] = [
    {
        label: 'Vendor ID',
        value: 'VN_VEN_ID'
    },
    {
        label: 'Vendor Name',
        value: 'VN_NAME'
    },
    {
        label: 'Vendor Street Address',
        value: 'VN_STREET'
    },
    {
        label: 'Vendor City',
        value: 'VN_CITY'
    },
    {
        label: 'Vendor Country',
        value: 'VN_CNTY'
    },
    {
        label: 'Vendor State',
        value: 'VN_STATE'
    },
    {
        label: 'Vendor Postal/Zip Code',
        value: 'VN_ZIP'
    },
    {
        label: 'Class Code 1',
        value: 'VN_CL1_UCD'
    },
    {
        label: 'Class Code 2',
        value: 'VN_CL2_UCD'
    },
    {
        label: 'Class Code 3',
        value: 'VN_CL3_UCD'
    },
    {
        label: 'Category ID 1',
        value: 'VN_CAT1_ID'
    },
    {
        label: 'Category ID 2',
        value: 'VN_CAT2_ID'
    },
    {
        label: 'Category ID 3',
        value: 'VN_CAT3_ID'
    },
    {
        label: 'Vendor Phone Number',
        value: 'VN_PHONE'
    },
    {
        label: 'Vendor Fax Number',
        value: 'VN_FAX'
    }
]