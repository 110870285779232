import { InputType } from '@components/common/Input/Input.types'
import { ReactSelectOptions, SelectType } from '@components/common/Select/Select.types'
import { SchemaType } from '@forms/validation'
import moment from 'moment'
import * as Yup from 'yup'

function getType(schemaType: string): InputType | 'checkbox' {
  switch (schemaType) {
    case 'number':
      return 'number'
    case 'date':
      return 'date'
    case 'boolean':
      return 'checkbox'
    default:
      return 'text'
  }
}

interface Props {
  name: string
  select?: SelectType
  selectOptions?: ReactSelectOptions
  fieldMap: any
  schema: SchemaType
  initialValues
  form
  setForm
}

export function getInputPropsForModalForms({
  name,
  select,
  selectOptions,
  fieldMap,
  schema,
  initialValues,
  form,
  setForm,
}: Props) {
  function onBlurHandler(e) {
    let value = null
    switch (e.target.type) {
      case 'checkbox':
        value = e.target.checked
        break
      case 'number':
        value = parseFloat(e.target.value)
        break
      default:
        value = e.target.value
        break
    }

    Yup.reach(schema, name)
      .validate(value)
      .then(() => {
        if (form.values[name] === value) {
          return
        }

        setForm(prevState => {
          let errors = { ...prevState.errors }
          if (errors[name]) delete errors[name]
          return { errors, values: { ...prevState.values, [name]: value } }
        })
      })
      .catch(err => {
        setForm(prevState => ({
          values: { ...prevState.values, [name]: value },
          errors: { ...prevState.errors, [name]: err.message },
        }))
      })
  }

  function onSelectHandler(e) {
    const value = e?.value || ''

    Yup.reach(schema, name)
      .validate(value)
      .then(() => {
        if (form.values[name] === value) {
          return
        }

        setForm(prevState => {
          let errors = { ...prevState.errors }
          if (errors[name]) delete errors[name]
          return { errors, values: { ...prevState.values, [name]: value } }
        })
      })
      .catch(err => {
        setForm(prevState => ({
          values: { ...prevState.values, [name]: value },
          errors: { ...prevState.errors, [name]: err.message },
        }))
      })
  }

  let required
  if (schema.fields[name].exclusiveTests.required) required = true

  let errorMsg
  let error
  if (form.errors[name]) {
    errorMsg = form.errors[name]
    error = true
  }

  let type
  let options
  let onBlur
  let defaultValue
  let defaultChecked
  let onChange
  if (select) {
    options = selectOptions
    type = select
    onChange = onSelectHandler
  } else {
    type = getType(schema.fields[name].type)
    if (type === 'checkbox') {
      defaultChecked = initialValues[name]
      onChange = onBlurHandler
    } else {
      onBlur = onBlurHandler
      defaultValue =
        schema.fields[name].type === 'date'
          ? moment(initialValues[name]).format('yyyy-MM-DD')
          : initialValues[name]
    }
  }

  let value
  if (options) {
    if (form.values[name] === null) {
      value = null
    } else if (type === 'creatable') {
      value = {
        label: form.values[name],
        value: form.values[name],
      }
    } else {
      value = options.find(option => option.value === form.values[name]) || null
    }
  }

  let checked
  if (type === 'checkbox') {
    checked = form.values[name]
  }

  return {
    name,
    type: type as any,
    label: fieldMap[name],
    onBlur,
    required,
    defaultValue,
    defaultChecked,
    checked,
    value,
    errorMsg,
    error,
    onChange,
    options,
  }
}
