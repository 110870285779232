import { Button, Flexbox, Heading, Modal, Spacer } from '@components/common'
import { MAINTENANCE_FORM_ID } from '@constants'

import MaintenanceForm from './MaintenanceForm'
import MaintenancesTable from './MaintenancesTable'
import useMaintenances from './useMaintenances'
import usePermission from '@components/Auth/Permissions/usePermission'
import { download } from '@utils/basic'
import ImportData from '@components/ImportData'

const Maintenances = () => {
  const {
    createMaintenanceModalIsOpen,
    openCreateMaintenanceModal,
    handleCreateMaintenance,
    closeCreateMaintenanceModal,
    selectedMaintenance,
    isLoadingCreateMaintenance,
  } = useMaintenances()
  const { canEditSettings: canEdit } = usePermission()

  const importButton = (
    <Button
      onClick={() => download(`${process.env.PUBLIC_URL}/templates/maintenance.csv`, 'maintenance.csv')}
      appearance="text"
    >
      Download an import template here.
    </Button>
  )

  return (
    <>
      <header>
        <Flexbox justifyContent="space-between">
          <Heading type="h1">Maintenance Contracts</Heading>
          <Flexbox alignItems='center' flexDirection='row' gap={12}>
            <ImportData action='maintenance' title='Import Maintenance Contracts' modalDesc={importButton} />
            <Button
              onClick={openCreateMaintenanceModal}
              appearance="primary"
              hidden={!canEdit}
            >
              Add new maintenance contract
            </Button>
          </Flexbox>
        </Flexbox>
      </header>

      <Spacer y="default" />

      <MaintenancesTable {...useMaintenances()} canEdit={canEdit} />

      <Modal
        isOpen={createMaintenanceModalIsOpen}
        title={`Add a new maintenance contract`}
        form={MAINTENANCE_FORM_ID}
        close={closeCreateMaintenanceModal}
        actionLabel={'Add maintenance contract'}
        isActionLoading={isLoadingCreateMaintenance}
        width={576}
      >
        <MaintenanceForm
          onSubmit={handleCreateMaintenance}
          isEdit={false}
          initialValues={selectedMaintenance}
        />
      </Modal>
    </>
  )
}
export default Maintenances
