import { Card, Checkbox, Fieldset, Form, Grid, Note, Spacer } from '@components/common'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { DEPRECIATION_EDIT_REPORT_PATH, DEPREC_PROFILES } from '@constants'
import ReportFilters from '../ReportFilters'
import { defaultReportFilterFields } from '../ReportFilters/report-filter-fields'
import { DeprecEditReportParams } from '@api/models'
import { DepProfileSelect, ActionButtons } from '../General'
import { useReport } from '@hooks'
import { useEffect, useState } from 'react'
import { reportResponseHandler } from '@utils/reports'
import { ReportsApi } from '@api'
import { isNullOrUndefined } from '@utils/basic'
import { useMemorizeReport } from '../General/MemorizeReports/useMemorizeReport'
import MemorizeReports from '../General/MemorizeReports/MemorizeReport'
import { useLocation } from 'react-router-dom'
import { useFormMethods } from '../General/useFormMethods'

const defaultValues: DeprecEditReportParams = {
  assetCompanyID: 0,
  filter: [],
  profile: DEPREC_PROFILES.CORPORATE,
  includeCaps: false,
  includeYr: false,
  includeDisposals: false,
  includeAccCode: false,
  includeDeprSys: false,
  includeDeprUsr: false,
  totalsOnly: false,
  fileFormat: 'pdf',
}

const DeprecEditForm = () => {
  const location = useLocation()
  const locationState: any = location.state

  const {
    methods,
    isRetrievingTemplate,
    isEditTemplate,
    selectedTemplateDetails,
  } = useFormMethods(locationState, defaultValues)

  const {
    formState: { isSubmitting },
    control,
    setValue,
  } = methods

  const {
    isLoading,
    isSuccess,
    isError,
    isNoCompanies,
    depProfilesMap,
    assetCompanyID,
  } = useReport()

  const {
    handleMemorizeTemplateSubmit,
    isMemorizeTemplateModalOpen,
    closeMemorizeTemplateModal,
    triggerMemorizeTemplateModal,
    handleEditTemplateSubmit,
  } = useMemorizeReport({ methods, currentReportPath: DEPRECIATION_EDIT_REPORT_PATH })

  useEffect(() => {
    if (isSuccess) {
      setValue('assetCompanyID', assetCompanyID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, assetCompanyID])

  const [formErrorMsg, setFormErrorMsg] = useState('')

  function getCheckBoxProps(field: any) {
    return {
      checked: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  async function onSubmitHandler(values: DeprecEditReportParams) {
    await ReportsApi.getInstance()
      .getDeprecEditReport(values)
      .then(data => reportResponseHandler(data, values.fileFormat))
      .catch(err => err.response.data.text())
      .then(text => setFormErrorMsg(text))
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (isNoCompanies) {
    return <div>No companies found</div>
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Card title={'Report Parameters'}>
            <Grid>
              {!isNullOrUndefined(formErrorMsg) && formErrorMsg !== '' ? (
                <Grid.Item>
                  <Note intent="danger" content={<>{formErrorMsg}</>} />
                </Grid.Item>
              ) : (
                ''
              )}
              <Grid.Item>
                <DepProfileSelect depProfilesMap={depProfilesMap} />
              </Grid.Item>

              <Grid.Item s={6}>
                <Fieldset legend={'Additional Columns to Include'}>
                  <Grid rowGap="mini">
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeYr"
                        render={({ field }) => (
                          <Checkbox label="Include years" {...getCheckBoxProps(field)} />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeAccCode"
                        render={({ field }) => (
                          <Checkbox
                            label="Include acceleration code"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDeprSys"
                        render={({ field }) => (
                          <Checkbox
                            label="Include depreciation system code"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDeprUsr"
                        render={({ field }) => (
                          <Checkbox
                            label="Include depreciation user code"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item s={6}>
                <Fieldset legend={'Options'}>
                  <Grid rowGap="mini">
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="totalsOnly"
                        render={({ field }) => (
                          <Checkbox label="Totals only" {...getCheckBoxProps(field)} />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeCaps"
                        render={({ field }) => (
                          <Checkbox
                            label="Include capitalizations"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDisposals"
                        render={({ field }) => (
                          <Checkbox
                            label="Include disposals"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item>
                <ReportFilters
                  filtersTemplateData={
                    isRetrievingTemplate
                      ? locationState.templateDetails.templateData.filter
                      : []
                  }
                  filterFields={defaultReportFilterFields}
                />
              </Grid.Item>
            </Grid>
          </Card>
          <Spacer y="default" />
          <ActionButtons
            isSubmitting={isSubmitting}
            triggerMemorizeTemplateModal={triggerMemorizeTemplateModal}
            isEditTemplate={isEditTemplate}
          />
        </Form>
      </FormProvider>

      <MemorizeReports
        isMemorizeTemplateModalOpen={isMemorizeTemplateModalOpen}
        closeMemorizeTemplateModal={closeMemorizeTemplateModal}
        handleMemorizeTemplateSubmit={handleMemorizeTemplateSubmit}
        handleEditTemplateSubmit={handleEditTemplateSubmit}
        isEditTemplate={isEditTemplate}
        selectedTemplateDetails={selectedTemplateDetails}
      />
    </>
  )
}

export default DeprecEditForm
