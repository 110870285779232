import { useModal } from '@components/common'
import { useGetYearEndUpdateInfoQuery, useYearEndUpdateMutation } from '@api'
import { useFields } from '@hooks'
import { useEffect } from 'react'

const useYearEndUpdateHook = (assetCompanyID: number) => {
  const { fields } = useFields()

  const {
    modalIsOpen: isYearEndUpdateFormOpen,
    triggerModal: openYearEndUpdateForm,
    closeModal: closeYearEndUpdateForm,
  } = useModal()

  const {
    data: yearEndUpdateInfo,
    isLoading: isLoadingGetYearEndUpdateInfo,
    isSuccess: isSuccessGetYearEndUpdateInfo,
    isError: isErrorGetYearEndUpdateInfo,
    error: errorGetYearEndUpdateInfo,
  } = useGetYearEndUpdateInfoQuery(assetCompanyID, {
    refetchOnMountOrArgChange: true,
    skip: !isYearEndUpdateFormOpen,
  })

  const [
    yearEndUpdateMutation,
    {
      isLoading: isYearEndUpdating,
      isSuccess: isSuccessYearEndUpdate,
      isError: isErrorYearEndUpdate,
    },
  ] = useYearEndUpdateMutation()

  useEffect(() => {
    if (isErrorGetYearEndUpdateInfo) {
      closeYearEndUpdateForm()
    }
  }, [closeYearEndUpdateForm, isErrorGetYearEndUpdateInfo])

  async function updateYearEndDepreciation(formEvent) {
    formEvent.preventDefault()
    closeYearEndUpdateForm()
    await yearEndUpdateMutation(assetCompanyID)
  }

  return {
    isSuccessYearEndUpdate,
    isErrorYearEndUpdate,
    isYearEndUpdateFormOpen,
    isYearEndUpdating,
    isLoadingGetYearEndUpdateInfo,
    isSuccessGetYearEndUpdateInfo,
    isErrorGetYearEndUpdateInfo,
    errorGetYearEndUpdateInfo: (errorGetYearEndUpdateInfo as any)?.data,
    yearEndUpdateInfo,
    fields,
    openYearEndUpdateForm,
    closeYearEndUpdateForm,
    updateYearEndDepreciation,
  }
}

export default useYearEndUpdateHook
