import React from 'react'
import { TemplateProps } from './Template.types'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import ViewHeader from './Header'
import LocalNav from '../LocalNav'
import Breadcrumbs from '../Breadcrumbs'

const Template: React.FC<TemplateProps> = ({
  title,
  pageHeader,
  localNav,
  maxWidth = 'full',
  showHeader = true,
  breadcrumbs,
  hasBackButton,
  backNavigateFunction,
  ...props
}) => {
  const [css] = useStyletron()

  let containerStyles = css({
    ...styles.viewContainer,
    ...styles.getMaxWidth({ maxWidth }),
  })

  return (
    <>
      {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} />}

      {title && showHeader && (
        <ViewHeader
          pageHeader={pageHeader ? pageHeader : title}
          localNav={localNav && <LocalNav items={localNav} />}
          hasBackButton={hasBackButton}
          backNavigateFunction={backNavigateFunction}
        />
      )}

      <div className={containerStyles}>{props.children}</div>
    </>
  )
}

export default Template
