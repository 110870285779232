import { useFields } from '@hooks'
import { useEffect, useMemo } from 'react'
import { Column, SortingRule, useFlexLayout, useSortBy, useTable } from 'react-table'
import {
  Button,
  ButtonGroup,
  Card,
  Flexbox,
  Icon,
  Modal,
  Search,
  Table,
  Text,
} from '@components/common'
import { Task } from '@api/models'
import Pagination from '@components/Pagination'
import { TasksHook } from '../useTasks'
import { TASK_FORM_ID } from '@constants'
import TaskForm from '../TaskForm'
import TasksTableLoading from './TasksTableLoading'

const TasksTable = ({
  pageNumber,
  itemsPerPage,
  handleSearch,
  searchValue,
  changePage,
  sortByInitialState,
  updateSortSearchParam,
  isTotalCountEmpty,
  //
  searchKey,
  tasks,
  isLoadingTasks,
  isErrorFetchingTasks,
  isRefetchingTasks,

  handleDeleteTask,
  isLoadingDeleteTask,
  openDeleteTaskModal,
  selectedTask,
  deleteTaskModalIsOpen,
  closeDeleteTaskModal,
  openEditTaskModal,
  closeEditTaskModal,
  editTaskModalIsOpen,
  handleEditTask,
  isLoadingEditTask,
  canEdit,
}: TasksHook) => {
  // Declare columns
  const { fields, isLoading: isLoadingFields, isError: isErrorGettingFields } = useFields()

  const columns = useMemo<Column<Task>[]>(
    () => [
      {
        Header: fields.OT_ACT_ID,
        accessor: 'OT_ACT_ID',
      },
      {
        Header: fields.OT_DESC,
        accessor: 'OT_DESC',
      },

      {
        Header: '',
        accessor: 'OT_ODPC_ID',
        disableSortBy: true,
        Cell: ({ row }) => (
          <Flexbox alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
            <ButtonGroup hidden={!canEdit}>
              <Button
                icon={<Icon name="Edit" />}
                size="compact"
                onClick={e => {
                  e.stopPropagation()
                  openEditTaskModal(row.original)
                }}
              >
                Edit
              </Button>

              <Button
                icon={<Icon name="Trash2" />}
                size="compact"
                intent="danger"
                onClick={e => {
                  e.stopPropagation()
                  openDeleteTaskModal(row.original)
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Flexbox>
        ),
      },
    ],
    [isLoadingFields]
  )

  // Declare react table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: tasks ? tasks.list : [],
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        sortBy: sortByInitialState() as SortingRule<Task>[],
      },
    },
    useSortBy,
    useFlexLayout
  )

  // Update sort param
  useEffect(() => {
    updateSortSearchParam(sortBy, setSortBy)
  }, [sortBy])

  // Returns
  const isTableLoading = isLoadingTasks || isLoadingFields
  const isError = isErrorFetchingTasks || isErrorGettingFields

  if (isTableLoading) {
    return <TasksTableLoading />
  }

  if (isError) {
    return <Table.NoResults heading="There was an error." />
  }

  if (isTotalCountEmpty(tasks.count)) {
    return (
      <Card spacing="none">
        <Table.NoResults heading="You haven't added any tasks yet." text="" />
      </Card>
    )
  }

  return (
    <>
      <Modal
        isOpen={deleteTaskModalIsOpen}
        close={closeDeleteTaskModal}
        title={`Delete task ${selectedTask?.OT_ACT_ID} (${selectedTask?.OT_DESC})`}
        width={540}
        action={() => handleDeleteTask(selectedTask)}
        actionLabel={`Delete task`}
        intent="danger"
        actionAutoFocus
        isActionLoading={isLoadingDeleteTask}
      >
        <Text p>
          Are you sure you want to delete task{' '}
          <strong>
            {selectedTask?.OT_ACT_ID} ({selectedTask?.OT_DESC})
          </strong>
          ? <Text intent="danger">This cannot be undone</Text>.
        </Text>
      </Modal>

      <Modal
        isOpen={editTaskModalIsOpen}
        close={closeEditTaskModal}
        title={`Edit task ${selectedTask?.OT_ACT_ID} (${selectedTask?.OT_DESC})`}
        width={576}
        actionLabel="Save changes"
        form={TASK_FORM_ID}
        isActionLoading={isLoadingEditTask}
        dismissLabel={canEdit ? 'Discard changes' : 'Close'}
        actionDisabled={!canEdit}
      >
        <TaskForm
          isEdit={true}
          initialValues={selectedTask}
          handleSubmit={handleEditTask}
          canEdit={canEdit}
        />
      </Modal>

      <Card spacing="none">
        <Table.Actions>
          <Flexbox>
            <Search
              onChange={handleSearch}
              placeholder="Search by task description..."
              name={searchKey}
              width={'640px'}
              value={searchValue}
            />
          </Flexbox>
        </Table.Actions>
        {tasks.list.length === 0 ? (
          <Table.NoResults text="" />
        ) : (
          <>
            <Table
              {...getTableProps()}
              aria-label="Company Table"
              label="Company Table"
              aria-colcount={columns.length}
              aria-rowcount={tasks.list.length}
              height="calc(100vh - 280px)"
            >
              <Table.Head sticky>
                {isRefetchingTasks ? (
                  <Table.LoadingRows />
                ) : (
                  headerGroups.map((headerGroup, i) => (
                    <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, i) => (
                        <Table.Header
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          column={column}
                          key={i}
                          tabIndex={0}
                        >
                          {column.render('Header')}
                        </Table.Header>
                      ))}
                    </Table.HeadRow>
                  ))
                )}
              </Table.Head>

              <Table.Body {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Table.Row
                      {...row.getRowProps()}
                      onClick={() => {
                        openEditTaskModal(row.original)
                      }}
                      tabIndex={0}
                      key={i}
                    >
                      {row.cells.map(cell => (
                        <Table.Cell {...cell.getCellProps()} column={cell.column}>
                          {cell.render('Cell')}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <Pagination
              changePage={changePage}
              totalItems={tasks.count}
              maxItemsPerPage={itemsPerPage}
              currentPageNumber={pageNumber}
              label={`Showing ${tasks.list.length} of ${tasks.count} record${
                tasks.count > 1 ? 's' : ''
              }`}
            />
          </>
        )}
      </Card>
    </>
  )
}
export default TasksTable
