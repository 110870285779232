import { useState, forwardRef } from 'react'
import { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { SelectProps } from './Select.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import * as inputStyles from '../Input/Styles'

import Icon from '../Icon'

const ClearIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props

  const [css] = useStyletron()
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: 'var(--size-default)',
        justifyContent: 'center',
      }}
    >
      <Icon name="X" className={css(styles.clearIcon)} />
    </div>
  )
}

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      No options yet. Type and press enter to add an option.
    </components.NoOptionsMessage>
  )
}

const CreatableSelectComponent = forwardRef<HTMLSelectElement, SelectProps>(
  ({ placeholder = '', isClearable = true, ...props }, ref) => {
    const [options, setOptions] = useState(props.options)
    const [css] = useStyletron()

    let containerStyles = css({
      ...inputStyles.container({ width: props.width, flexGrow: props.flexGrow }),
    })
    let labelStyles = css({
      ...inputStyles.label({ label: props.label, disabled: props.disabled }),
    })

    const selectStyles = {
      control: (provided, state) => ({
        ...styles.control(provided, state, props.disabled, props.error, 'creatable'),
      }),
      valueContainer: provided => ({ ...styles.valueContainer(provided) }),
      indicatorsContainer: () => ({ ...styles.indicatorsContainer }),
      menu: (provided, state) => ({
        ...styles.menu(provided, state),
      }),
      option: (provided, state) => ({
        ...styles.option(provided, state),
      }),
    }
    let helperStyles = css(inputStyles.helperText({ disabled: props.disabled }))
    let errorStyles = css(inputStyles.errorMsg)
    let errorIconStyles = css(inputStyles.errorMsgIcon)

    var emptyValue = !props.value || props?.value === ''
    var value = emptyValue ? null : options.find(c => props.value === c.value)

    return (
      <div className={containerStyles}>
        <label className={labelStyles} id={`${props.name}-label`} htmlFor={props.name}>
          {props.required && '*'}
          {props.label}
        </label>

        <CreatableSelect
          {...props}
          ref={ref}
          styles={selectStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: emptyValue ? () => null : ClearIndicator,
            NoOptionsMessage,
          }}
          isClearable={isClearable}
          placeholder={props.disabled ? '' : placeholder}
          options={options}
          value={emptyValue ? null : value}
          onChange={val => props?.onChange(val?.value)}
          onCreateOption={newVal => {
            setOptions(prevState => [...prevState, { label: newVal, value: newVal }])
            props?.onChange(newVal)
          }}
          aria-labelledby={
            props['aria-labelledby'] ? props['aria-labelledby'] : `${props.name}-label`
          }
        />

        {props.helper && !props.error && <div className={helperStyles}>{props.helper}</div>}
        {props.errorMsg && props.error && (
          <div className={errorStyles}>
            <Icon name="AlertCircle" className={errorIconStyles} /> {props.errorMsg}
          </div>
        )}
      </div>
    )
  }
)

export default CreatableSelectComponent
