import { apiSlice } from '@api'
import { getUserFullName } from '@components/Auth/profile'
import { formatISODate } from '@utils/basic'
import { AssetCharge, FetchAssetChargeParams, AssetChargeExtraFields } from '../models'

export const chargesEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    fetchAssetCharges: build.query<AssetCharge[], FetchAssetChargeParams>({
      query: payload =>
        `/Charges/FetchAssetCharges?assetCompanyId=${payload.assetCompanyID}&assetId=${payload.assetID}&type=${payload.type}`,
      transformResponse: (charges: AssetCharge[]) =>
        charges.map(charge => ({
          ...charge,
          CH_DATE: formatISODate(charge.CH_DATE),
          CH_ADD_DAT: formatISODate(charge.CH_ADD_DAT),
          CH_INV_DAT: formatISODate(charge.CH_INV_DAT),
          CH_LOC_DAT: formatISODate(charge.CH_LOC_DAT),
          CH_SER_DAT: formatISODate(charge.CH_SER_DAT),
        })),
      providesTags: ['assetCharge'],
    }),
    fetchAssetCharge: build.query<AssetCharge, number>({
      query: payload => `/Charges/Get?id=${payload}`,
      transformResponse: (charge: AssetCharge) => ({
        ...charge,
        CH_DATE: formatISODate(charge.CH_DATE),
        CH_ADD_DAT: formatISODate(charge.CH_ADD_DAT),
        CH_INV_DAT: formatISODate(charge.CH_INV_DAT),
        CH_LOC_DAT: formatISODate(charge.CH_LOC_DAT),
        CH_SER_DAT: formatISODate(charge.CH_SER_DAT),
      }),
      providesTags: ['assetCharge'],
    }),
    createCharge: build.mutation<void, AssetCharge>({
      query: payload => ({
        url: 'Charges/Create',
        method: 'POST',
        body: {
          ...payload,
          CH_ADD_USR: getUserFullName(),
        },
      }),
      invalidatesTags: ['assetCharge'],
    }),
    editCharge: build.mutation<void, AssetCharge>({
      query: payload => ({
        url: 'Charges/Edit',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['assetCharge'],
    }),
    deleteCharge: build.mutation<void, AssetCharge>({
      query: payload => ({
        url: 'Charges/Delete',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['assetCharge', 'assetHistory'],
    }),
    getExtraChargeFields: build.query<AssetChargeExtraFields, void>({
      query: () => `Charges/GetExtraFields`,
      providesTags: ['assetCharge'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchAssetChargesQuery,
  useCreateChargeMutation,
  useEditChargeMutation,
  useDeleteChargeMutation,
  useGetExtraChargeFieldsQuery,
  useFetchAssetChargeQuery,
} = chargesEndpoints
