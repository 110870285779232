import { useModal } from '@components/common'
import { useGetYearEndRunInfoQuery, useYearEndRunMutation } from '@api'
import { YearEndRunInput } from '@api/models'
import { useFields } from '@hooks'
import { useEffect } from 'react'

const useYearEndRunHook = (assetCompanyID: number) => {
  const { fields } = useFields()

  const {
    modalIsOpen: isYearEndRunFormOpen,
    triggerModal: openYearEndRunForm,
    closeModal: closeYearEndRunForm,
  } = useModal()

  const {
    data: yearEndRunInfo,
    isLoading: isLoadingGetYearEndRunInfo,
    isSuccess: isSuccessGetYearEndRunInfo,
    isError: isErrorGetYearEndRunInfo,
    error: errorGetYearEndRunInfo,
  } = useGetYearEndRunInfoQuery(assetCompanyID, {
    refetchOnMountOrArgChange: true,
    skip: !isYearEndRunFormOpen,
  })

  const [
    yearEndRunMutation,
    {
      isLoading: isYearEndRunning,
      isSuccess: isSuccessYearEndRun,
      isError: isErrorYearEndRun,
    },
  ] = useYearEndRunMutation()

  useEffect(() => {
    if (isErrorGetYearEndRunInfo) {
      closeYearEndRunForm()
    }
  }, [closeYearEndRunForm, isErrorGetYearEndRunInfo])

  async function runYearEndDepreciation(formData: YearEndRunInput) {
    closeYearEndRunForm()
    await yearEndRunMutation(formData)
  }

  return {
    isErrorYearEndRun,
    isErrorGetYearEndRunInfo,
    isSuccessYearEndRun,
    isYearEndRunFormOpen,
    isYearEndRunning,
    isLoadingGetYearEndRunInfo,
    isSuccessGetYearEndRunInfo,
    yearEndRunInfo,
    errorGetYearEndRunInfo: (errorGetYearEndRunInfo as any)?.data,
    fields,
    openYearEndRunForm,
    closeYearEndRunForm,
    runYearEndDepreciation,
  }
}

export default useYearEndRunHook
