import {
  Card,
  Flexbox,
  InputSkeleton,
  TableSkeleton,
  TextSkeleton,
} from '@components/common'

const GLPostsTableLoading: React.FC = () => {
  return (
    <>
      <Card spacing="none" overflow="hidden">
        <Flexbox style={{ padding: 'var(--scale500)' }}>
          <InputSkeleton width="640px" />
        </Flexbox>
      </Card>

      <Card spacing="none" overflow="hidden">
        <TableSkeleton rows={25} height="calc(100vh - 280px)" />
      </Card>

      <Card spacing="none" overflow="hidden">
        <Flexbox justifyContent="space-between" style={{ padding: 'var(--scale600)' }}>
          <TextSkeleton width="124px" height="var(--scale600)" />

          <TextSkeleton width="195px" height="var(--scale600)" />
        </Flexbox>
      </Card>
    </>
  )
}

export default GLPostsTableLoading
