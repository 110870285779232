// TODO: Payment Terms

import { Flexbox, Form, Grid, Input, Select, Spinner, Note } from '@common'
import { TASK_FORM_ID } from '@constants'
import { TaskFormProps, useTaskForm } from './useTaskForm'

const TaskForm = ({
  isEdit,
  initialValues,
  handleSubmit,
  canEdit = true,
}: TaskFormProps) => {
  const {
    getField,
    handleFormSubmit,
    isLoadingExtraFields,
    isLoadingGLPostsList,
    extraFields,
    glPostsListOptions,
  } = useTaskForm({
    initialValues,
    handleSubmit,
    canEdit,
  })

  if (isLoadingExtraFields || isLoadingGLPostsList) {
    return (
      <Flexbox alignItems="center" justifyContent="center" height={480}>
        <Spinner />
      </Flexbox>
    )
  }

  return (
    <Form id={TASK_FORM_ID} onSubmit={handleFormSubmit} noValidate>
      <Grid>
        {!isEdit && (
          <Grid.Item>
            <Input {...getField('OT_ACT_ID')} autoFocus={!isEdit} readOnly={isEdit} />
          </Grid.Item>
        )}
        <Grid.Item>
          <Input {...getField('OT_DESC')} autoFocus={isEdit} />
        </Grid.Item>
        <Grid.Item>
          <Select
            {...getField('OT_RETD_CD', 'default', extraFields?.dispositionType)}
            label="Disposition"
          />
        </Grid.Item>
        <Grid.Item>
          <Select
            {...getField('OT_OAPC_ID', 'default', glPostsListOptions)}
            label="Transaction Post Code"
          />
        </Grid.Item>
        <Grid.Item>
          <Select
            {...getField('OT_ODPC_ID', 'default', glPostsListOptions)}
            label="Depreciation Post Code"
          />
        </Grid.Item>
        {!isEdit && (
          <Grid.Item>
            <Note intent="warning" content={'ID cannot be changed once task is added.'} />
          </Grid.Item>
        )}
      </Grid>
    </Form>
  )
}
export default TaskForm
