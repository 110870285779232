import { CustomDepreciation } from '@api/models'
import { Form } from '@components/common'
import { DELETE_CUSTOM_DEPRECIATION_FORM_ID } from '@constants'
import { useFormContext } from 'react-hook-form'

interface CustomDepreciationDeleteFormProps {
  customDepreciation: CustomDepreciation
  deleteAction: (values: CustomDepreciation) => Promise<void>
}

const CustomDepreciationDeleteForm = ({
  customDepreciation,
  deleteAction,
}: CustomDepreciationDeleteFormProps) => {
  const { handleSubmit } = useFormContext<CustomDepreciation>()
  return (
    <Form
      id={DELETE_CUSTOM_DEPRECIATION_FORM_ID}
      onSubmit={handleSubmit(() => deleteAction(customDepreciation))}
    >
      <div>Are you sure you want to delete this custom depreciation record?</div>
    </Form>
  )
}

export default CustomDepreciationDeleteForm
