import moment from 'moment'
import { isEmpty } from 'lodash.isempty'
import { locales } from './locales'
import { Asset, InsurancePolicy } from '@api/models'

export function parseAsset(asset: Asset): Asset {
  return asset
}

export function parseAssetFormData(asset: Asset): Asset {
  Object.keys(asset).forEach(key => {
    if (key.includes('_DAT') || key.includes('_SYD')) asset[key] = formatISODate(asset[key])
  })
  asset.AS_NSER_DT = formatISODate(asset.AS_NSER_DT)
  return asset
}

export function parseInsurancePolicy(ins: InsurancePolicy): InsurancePolicy {
  ins.IN_CP_FRM = formatISODate(ins.IN_CP_FRM)
  ins.IN_CP_TO = formatISODate(ins.IN_CP_TO)
  return ins
}

export function formatISODate(date: string) {
  return moment(date).format('yyyy-MM-DD')
}

export function getTodaysDate() {
  return moment().format('yyyy-MM-DD')
}

export function formatDisplayDate(date: string, dateFormat: string) {
  const momentDateFormat = dateFormat ? dateFormat.toLocaleUpperCase() : 'yyyy/MM/DD'
  return moment(date).format(momentDateFormat)
}

export function getCurrentYear() {
  return moment().year()
}

export async function sleep(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms))
}

export function serializeArray(arr: Array<any>) {
  return encodeURIComponent(JSON.stringify(arr))
}

export const formatCurrency = (value: number | string, symbol = true): string => {
  if (typeof value === 'string') {
      value = parseFloat(value);

      if (isNaN(value)) {
          value = 0;
      }
  }
  
  const locale = navigator.language;
  const currency = locales[locale];

  if (!currency) {
      const formatted = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
      if (!symbol) {
          return formatted.replace('$', '').trim();
      }
      return formatted;
  } else {
      const formatted = Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
      if (!symbol) {
          return formatted.replace('$', '').trim();
      }
      return formatted;
  }
}

export const maxStringMsg = (val: number) => `Maximum ${val} characters`
export const maxDigitMsg = (val: number) => `Maximum ${val} digits`
export const exactDigitMsg = (val: number) => `Must be ${val} digits`

export function dataToList(arr: Array<{ id: string; name: string }>) {
  return arr.map(({ id, name }) => ({
    value: id,
    label: `${id} - ${name}`,
  }))
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined || value === ''
}

export function isObjectNullOrEmpty(obj: Object) {
  if (obj === null || obj === undefined || isEmpty(obj)) {
    return true
  }

  return false
}

export function getLastCharacterInString(string: string) {
  return string.charAt(string.length - 1)
}

export function serializeURLParams(params: any) {
  return Object.keys(params)
    .filter(key => params[key] !== null && params[key] !== undefined)
    .map(key => {
      const isObject = typeof params[key] === 'object'
      const value = isObject ? JSON.stringify(params[key]) : params[key]
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')
}

export function splitPathsIntoArray(pathname: string): string[] {
  return pathname.split('/')
}

export function refreshPage() {
  window.location.reload()
}

export function download(url: string, fileName: string) {
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
