import React from 'react'
import { LocalNavProps } from '../View/View.types'

import { NavLink } from 'react-router-dom'

import { AnimateSharedLayout } from 'framer-motion'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

const LocalNav: React.FC<LocalNavProps> = ({ items }) => {
  const [css] = useStyletron()

  return (
    <>
      <AnimateSharedLayout>
        <nav className={css(styles.localNav)}>
          <ul className={css(styles.localNavMenu)}>
            {items.map(item => (
              <li className={css(styles.localNavItem)} key={item.viewPath}>
                <NavLink
                  to={item.viewPath}
                  className={({ isActive }) =>
                    isActive
                      ? `${css(styles.localNavLink)} ${css(styles.localNavLinkActive)}`
                      : `${css(styles.localNavLink)}`
                  }
                >
                  <span data-content={item.label} className={css(styles.localNavText)}>
                    {item.label}
                  </span>

                  {/* {location.pathname === item.viewPath && (
                    <motion.span
                      initial={false}
                      transition={SPRING_MOTION}
                      className={css(styles.localNavIndicator)}
                    />
                  )} */}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </AnimateSharedLayout>
    </>
  )
}

export default LocalNav
