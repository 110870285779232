import {
  AllAssetRecalcFields,
  AssetRecalcDTO,
  AssetRecalcFieldNames,
  assetRecalcSchema,
  CustomDepreciation,
} from '@api/models'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Grid, Input, Fieldset, Radio, Form, Note, CustomDatePicker } from '@common'
import { CUSTOM_DEPRECIATION_RECALC_FORM_ID } from '@constants'

interface CustomDepreciationRecalcFormProps {
  deprecsp: CustomDepreciation
  deprecspRecalcDTO: AssetRecalcDTO
  submitAction: (
    customDepreciation: CustomDepreciation,
    recalcDTO: AssetRecalcDTO
  ) => Promise<void>
  isDelete: boolean
}

const CustomDepreciationRecalcForm = ({
  deprecsp,
  deprecspRecalcDTO,
  submitAction,
  isDelete,
}: CustomDepreciationRecalcFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm<AllAssetRecalcFields>({
    resolver: yupResolver(assetRecalcSchema),
    mode: 'onBlur',
    defaultValues: deprecspRecalcDTO,
  })
  const watchSelectedOption = watch('SelectedOption')

  function getInputProps(name: AssetRecalcFieldNames) {
    return {
      ...register(name),
      disabled: isSubmitting,
      error: errors[name] !== undefined,
      errorMsg: errors[name]?.message,
    }
  }

  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  function onSubmitHandler(formData: AllAssetRecalcFields) {
    submitAction(deprecsp, formData as AssetRecalcDTO)
  }

  const yearNotApplicable = deprecspRecalcDTO.AsOfYear === 0

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)} id={CUSTOM_DEPRECIATION_RECALC_FORM_ID}>
      <Grid colGap="compact">
        <Grid.Item>
          <Note
            intent="danger"
            content={`Please confirm recalculation before ${
              isDelete ? 'deleting' : 'saving'
            }.`}
          />
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="Options">
            <Controller
              control={control}
              name="SelectedOption"
              render={({ field: { onChange, value } }) => (
                <Radio
                  name="SelectedOption"
                  onChange={val => onChange(val.target.value)}
                  select={deprecspRecalcDTO.Options.find(c => value === c.Value).Value}
                >
                  {deprecspRecalcDTO.Options.map(option => (
                    <Radio.Option
                      key={option.Value}
                      value={option.Value}
                      label={option.Label}
                      disabled={option.Disabled}
                    />
                  ))}
                </Radio>
              )}
            />
          </Fieldset>
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="Parameters">
            <Grid colGap="compact">
              <Grid.Item l={6}>
                {yearNotApplicable ? (
                  <Input value="n/a" disabled label="As Of Year" />
                ) : (
                  <Input
                    {...getInputProps('AsOfYear')}
                    label="As Of Year"
                    disabled={watchSelectedOption !== 'specified'}
                  />
                )}
              </Grid.Item>
              <Grid.Item l={6}>
                <Controller
                  control={control}
                  name="PostingDate"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps(field)}
                      label="Posting Date"
                      disabled={watchSelectedOption === 'current'}
                      error={errors['PostingDate'] !== undefined}
                      errorMsg={errors['PostingDate']?.message}
                    />
                  )}
                />
              </Grid.Item>
            </Grid>
          </Fieldset>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default CustomDepreciationRecalcForm
