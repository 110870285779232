import { Button, Flexbox, Form, Grid, Modal, Note, Spacer } from '@components/common'
import { useDepreciationClass } from '@hooks'
import { DEPRECIATION_CLASS_FORM_ID, DEPREC_RECALC_FORM_ID } from '@constants'
import { FormProvider } from 'react-hook-form'
import { DeprecClassHookProps } from '../DepreciationClass.types'

import SaveBar from '@components/SaveBar'
import DeprecClassRecalcForm from '../DeprecClassRecalcForm'
import DiscardModal from '@components/DiscardModal'

//
import DeprecClassFormSection from './DeprecClassFormSection'
import DeprecClassDeprecInfoForm from './deprecClassDeprecInfo/DeprecClassDeprecInfoForm'
import DeprecClassDeprecInfoDisplay from './deprecClassDeprecInfo/DeprecClassDeprecInfoDisplay'
import DeprecClassMainDetailsForm from './deprecClassMainDetails/DeprecClassMainDetailsForm'
import DeprecClassMainDetailsDisplay from './deprecClassMainDetails/DeprecMainDetailsDisplay'
import DeprecClassClassificationForm from './deprecClassClassification/DeprecClassClassificationForm'
import DeprecClassClassificationDisplay from './deprecClassClassification/DeprecClassClassificationDisplay'
import DeprecClassFormLoading from './DeprecClassFormLoading'
import AssetSpecificDepreciationModal from '../AssetSpecificDepreciationModal'
import DeprecDeleteModal from '../DeprecDeleteModal'
import CommentEditor from '@components/CommentEditor'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { isNullOrUndefined } from '@utils/basic'
import usePermission from '@components/Auth/Permissions/usePermission'

const DepreciationClassForm = ({ params, context }: DeprecClassHookProps) => {
  const {
    methods,
    isDirty,
    isFormValid,
    depreciationClass,
    fields,
    isLoading,
    isSubmitting,
    handleSubmit,
    saveDepreciationClass,
    recalcFormState,
    closeRecalcForm,
    saveDepreciationClassWithRecalc,
    discardChanges,
    isDiscardModalOpen,
    setIsDiscardModalOpen,
    saveButtonLabel,
    isMainDetailsEdited,
    extraFields,
    extraFieldsLabels,
    isDeprecInfoEdited,
    isNoDepMethodSelected,
    isNonLinearDepMethodSelected,
    deductFromAcq,
    deductFromBookValue,
    lifetimeLabel,
    sectionSharedProps,
    isClassificationEdited,
    shouldBlockNavigation,
    isAssetSpecific,
    formState,
    setIsAssetSpecificModalOpen,
    isAssetSpecificModalOpen,
    handleAssetSpecificSubmit,
    isLoadingAssetSpecificSubmit,
    deleteDepreciationClassWithRecalc,
    deleteDepreciationClass,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    handleDeleteDeprec,
  } = useDepreciationClass({
    params,
    context,
  })

  const { canEditAssets: canEdit } = usePermission()

  let EditingEnabled = false

  const { data: isAssetDesc } = useGetAssetQuery({
    assetCompanyID: !isNullOrUndefined(params.assetCompanyID) ? params.assetCompanyID : 0,
    assetID: !isNullOrUndefined(params.assetID) ? params.assetID : '',
  })

  if (!isNullOrUndefined(isAssetDesc)) {
    EditingEnabled =
      isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false
  }

  return (
    <FormProvider {...methods}>
      {/* Save and discard */}
      <SaveBar
        formId={DEPRECIATION_CLASS_FORM_ID}
        isVisible={context !== 'create' ? isDirty : true}
        isSubmitting={isSubmitting}
        isSaveDisabled={!isFormValid}
        label={
          context === 'create'
            ? `Add new ${params.profile === 'A' ? 'category' : 'tax class'}`
            : 'You have unsaved changes'
        }
        saveButtonLabel={saveButtonLabel}
        shouldBlockNavigation={shouldBlockNavigation}
        openDiscardModal={() => setIsDiscardModalOpen(true)}
      />
      <DiscardModal
        discard={discardChanges}
        modalIsOpen={isDiscardModalOpen}
        closeModal={() => setIsDiscardModalOpen(false)}
      />

      {/* Recalc modal */}
      <Modal
        isOpen={recalcFormState.open}
        close={closeRecalcForm}
        form={DEPREC_RECALC_FORM_ID}
        title="Recalculate depreciation"
        actionLabel={
          recalcFormState.type === 'edit'
            ? 'Recalculate and save'
            : 'Delete and recalculate'
        }
        isActionLoading={isSubmitting}
      >
        <DeprecClassRecalcForm
          deprec={recalcFormState.intitialValues?.deprec}
          recalcDTO={recalcFormState.intitialValues?.deprecRecalcDTO}
          type={recalcFormState.type}
          submitAction={
            recalcFormState.type === 'edit'
              ? saveDepreciationClassWithRecalc
              : deleteDepreciationClassWithRecalc
          }
        />
      </Modal>

      {/* Asset-specific modal */}
      <AssetSpecificDepreciationModal
        isAssetSpecific={isAssetSpecific}
        isOpen={isAssetSpecificModalOpen}
        setIsOpen={setIsAssetSpecificModalOpen}
        handleSubmit={handleAssetSpecificSubmit}
        isSubmitting={isLoadingAssetSpecificSubmit}
      />

      <DeprecDeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        handleSubmit={handleDeleteDeprec}
        isSubmitting={isLoadingAssetSpecificSubmit}
      />

      {/* Main Form UI */}
      {isLoading ? (
        <DeprecClassFormLoading />
      ) : (
        <Form
          id={DEPRECIATION_CLASS_FORM_ID}
          noValidate
          onSubmit={handleSubmit(saveDepreciationClass)}
        >
          <Grid>
            {EditingEnabled && (
              <Grid.Item>
                <Note
                  appearance="elevated"
                  content={
                    EditingEnabled ? (
                      <>
                        Asset is in read-only mode
                        {isAssetDesc.AS_DIS_CD === 'P'
                          ? ' due to pending disposal.'
                          : ' because it has been disposed.'}
                      </>
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid.Item>
            )}
            {isAssetSpecific && !EditingEnabled && (
              <Grid.Item>
                <Note
                  appearance="elevated"
                  content={
                    <>
                      Asset is using asset-specific depreciation.{' '}
                      <Button
                        appearance="text"
                        onClick={() => deleteDepreciationClass(depreciationClass)}
                        disabled={isDirty}
                      >
                        Undo
                      </Button>
                      .
                    </>
                  }
                />
              </Grid.Item>
            )}
            {canEdit && context === 'asset' && !isAssetSpecific && !EditingEnabled && (
              <Grid.Item>
                <Note
                  appearance="elevated"
                  content={
                    <>
                      To edit this depreciation profile, you must{' '}
                      <Button
                        appearance="text"
                        onClick={() => setIsAssetSpecificModalOpen(true)}
                        disabled={isDirty}
                      >
                        create asset-specific depreciation
                      </Button>
                      .
                    </>
                  }
                />
              </Grid.Item>
            )}

            {!isAssetSpecific && (
              <Grid.Item>
                <DeprecClassFormSection
                  title="Main details"
                  stateKey="isMainDetailsCardOpen"
                  formElement={
                    <DeprecClassMainDetailsForm fields={fields} context={context} />
                  }
                  displayElement={
                    <DeprecClassMainDetailsDisplay
                      fields={fields}
                      deprec={depreciationClass}
                    />
                  }
                  isEdited={isMainDetailsEdited}
                  {...(sectionSharedProps as any)}
                  canEdit={canEdit}
                />
              </Grid.Item>
            )}

            <Grid.Item>
              <DeprecClassFormSection
                title="Depreciation info"
                stateKey="isDeprecInfoCardOpen"
                formElement={
                  <DeprecClassDeprecInfoForm
                    fields={fields}
                    extraFields={extraFields}
                    context={context}
                    isNoDepMethodSelected={isNoDepMethodSelected}
                    isNonLinearDepMethodSelected={isNonLinearDepMethodSelected}
                    deductFromAcq={deductFromAcq}
                    deductFromBookValue={deductFromBookValue}
                    lifetimeLabel={lifetimeLabel}
                  />
                }
                displayElement={
                  <DeprecClassDeprecInfoDisplay
                    fields={fields}
                    deprec={depreciationClass}
                    extraFieldsLabels={extraFieldsLabels}
                    isNoDepMethodSelected={isNoDepMethodSelected}
                    isNonLinearDepMethodSelected={isNonLinearDepMethodSelected}
                    deductFromAcq={deductFromAcq}
                    deductFromBookValue={deductFromBookValue}
                    lifetimeLabel={lifetimeLabel}
                  />
                }
                isEdited={isDeprecInfoEdited}
                {...(sectionSharedProps as any)}
                canEdit={canEdit}
              />
            </Grid.Item>

            <Grid.Item>
              <DeprecClassFormSection
                title="Classification/defaults"
                stateKey="isClassificationFormOpen"
                formElement={
                  <DeprecClassClassificationForm
                    fields={fields}
                    context={context}
                    extraFields={extraFields}
                  />
                }
                displayElement={
                  <DeprecClassClassificationDisplay
                    fields={fields}
                    deprec={depreciationClass}
                    extraFieldsLabels={extraFieldsLabels}
                  />
                }
                isEdited={isClassificationEdited}
                {...(sectionSharedProps as any)}
                canEdit={canEdit}
              />
            </Grid.Item>
            {context !== 'create' && (
              <Grid.Item>
                <CommentEditor
                  title={`${depreciationClass.DP_OBJ_ID}: ${depreciationClass.DP_DESC}`}
                  assetCompanyID={0}
                  type={params.profile === 'A' ? 'CA' : 'TX'}
                  objectID={depreciationClass.DP_OBJ_ID}
                  key1="00000"
                  key2="00000"
                  key5={1}
                  canEdit={canEdit}
                />
              </Grid.Item>
            )}
          </Grid>

          {canEdit && formState !== 'read-only' && !EditingEnabled && (
            <>
              <Spacer y="default" />
              <Flexbox justifyContent="space-between">
                {context !== 'create' && (
                  <Flexbox>
                    <Button
                      onClick={() => deleteDepreciationClass(depreciationClass)}
                      intent="danger"
                      disabled={isDirty}
                    >
                      Delete
                    </Button>
                  </Flexbox>
                )}
                <Spacer x="mini" />
                <Flexbox justifyContent="flex-end">
                  <Button
                    type="button"
                    onClick={() => setIsDiscardModalOpen(true)}
                    disabled={!isDirty || isSubmitting}
                  >
                    Discard changes
                  </Button>
                  <Spacer x="mini" />
                  <Button
                    type="submit"
                    appearance="primary"
                    disabled={!isDirty || !isFormValid}
                    loading={isSubmitting}
                  >
                    {saveButtonLabel}
                  </Button>
                </Flexbox>
              </Flexbox>
            </>
          )}
        </Form>
      )}
    </FormProvider>
  )
}

export default DepreciationClassForm
