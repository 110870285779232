import * as Yup from 'yup'
import { maxStringMsg } from '@utils/basic'
import { REQUIRED_FIELD } from '@constants'
import { dateExistValidation, exactLengthValidation } from '../../models/validation'
import { AssetHistoryApi } from '@api'

export interface DepInfo {
  AssetCompanyID: number
  ShortName: string
  LastYearClosed: number
  PostDate: string
  AsOfDate: string
  Period: number
  FiscalYear: number
  Remark1: string
  Remark2: string
  Remark3: string
}

export interface DepRunStatus {
  AssetCompanyID: number
  ShortName: string
  LastYearClosed: number
  PostDate: string
  AsOfDate: string
  Period: number
  FiscalYear: number
  Remark1: string
  Remark2: string
  Remark3: string
  IsRunningDepreciation: boolean
  NumberOfPeriods: number
}

export interface DepRunInput {
  AssetCompanyID: number
  PostDate: string
  AsOfDate: string
  Period: number
  FiscalYear: number
  Remark1: string
  Remark2: string
  Remark3: string
}

export interface YearEndInfo {
  AssetCompanyID: number
  ShortName: string
  LastYearClosed: number
  PostDate: string
  YearToClose: number
  Remark1: string
  Remark2: string
  Remark3: string
}

export interface YearEndUndoInfo {
  AssetCompanyID: number
  ShortName: string
  LastYearClosed: number
  PostDate: string
  YearToReopen: number
  Remark1: string
  Remark2: string
  Remark3: string
}

export interface YearEndRunInput {
  AssetCompanyID: number
  PostDate: string
  YearToClose: number
  Remark1: string
  Remark2: string
  Remark3: string
}

export interface YearEndUndoInput {
  AssetCompanyID: number
  PostDate: string
  YearToReopen: number
  Remark1: string
  Remark2: string
  Remark3: string
}

/* Period Run */

const INVALID_PERIOD_MSG = 'Invalid period'
const INVALID_FISCAL_YEAR_MSG = 'Invalid fiscal year'

const depSchema = (isPeriodBased: boolean, validationCallback) => {
  const conditionalSchemaFields = isPeriodBased
    ? {
        AsOfDate: Yup.string().nullable(),
        Period: Yup.number()
          .typeError(INVALID_PERIOD_MSG)
          .required(REQUIRED_FIELD)
          .min(1, INVALID_PERIOD_MSG)
          .max(99, INVALID_PERIOD_MSG),
        FiscalYear: Yup.number()
          .typeError(INVALID_FISCAL_YEAR_MSG)
          .required(REQUIRED_FIELD)
          .test(exactLengthValidation(4)),
      }
    : {
        AsOfDate: Yup.string()
          .typeError(REQUIRED_FIELD)
          .required(REQUIRED_FIELD)
          .test(dateExistValidation),
        Period: Yup.number().nullable(),
        FiscalYear: Yup.number().nullable(),
      }

  return Yup.object({
    ...conditionalSchemaFields,
    PostDate: Yup.string()
      .required(REQUIRED_FIELD)
      .min(10, INVALID_POSTING_DATE_MSG)
      .max(10, INVALID_POSTING_DATE_MSG)
      .typeError('Needs to be a date.'),
    Remark1: Yup.string().max(50, maxStringMsg(50)).nullable(),
    Remark2: Yup.string().max(50, maxStringMsg(50)).nullable(),
    Remark3: Yup.string().max(50, maxStringMsg(50)).nullable(),
  }).test({
    name: 'validateDepInput',
    test: async function (values) {
      try {
        const { PostDate, AsOfDate, Period, FiscalYear } = values
        if (isPeriodBased) {
          if (PostDate === null || Period === null || FiscalYear === null) {
            return true
          }
        } else {
          if (PostDate === null || AsOfDate === null) {
            return true
          }
        }
        await validationCallback(values)
        return true
      } catch (error: any) {
        const errorCode = error.response.data.ErrorCode
        return this.createError({
          message: errorCode,
        })
      }
    },
  })
}

const depRunValidationCallback = AssetHistoryApi.getInstance().validateDepRun
const depUndoValidationCallback = AssetHistoryApi.getInstance().validateDepUndo

export const depRunSchema = (isPeriodBased: boolean) =>
  depSchema(isPeriodBased, depRunValidationCallback)
export const depUndoSchema = (isPeriodBased: boolean) =>
  depSchema(isPeriodBased, depUndoValidationCallback)

/* Year End Run */

const INVALID_YEAR_MSG = 'Invalid year'
const INVALID_POSTING_DATE_MSG = 'Invalid posting date'

const yearEndSchema = (validationCallback, isUndo: boolean) => {
  if (isUndo) {
    return Yup.object({
      YearToReopen: Yup.number()
        .typeError(INVALID_YEAR_MSG)
        .test(exactLengthValidation(4))
        .required(REQUIRED_FIELD),
      PostDate: Yup.string().required(REQUIRED_FIELD).test(dateExistValidation),
      Remark1: Yup.string().max(50, maxStringMsg(50)).nullable(),
      Remark2: Yup.string().max(50, maxStringMsg(50)).nullable(),
      Remark3: Yup.string().max(50, maxStringMsg(50)).nullable(),
    }).test({
      name: 'validateYearEndInput',
      test: async function (values) {
        try {
          const { PostDate, YearToReopen } = values

          if (PostDate === null || YearToReopen === 0) {
            return true
          }

          await validationCallback(values)
          return true
        } catch (error: any) {
          const errorCode = error.response.data.ErrorCode
          return this.createError({
            message: errorCode,
          })
        }
      },
    })
  }

  return Yup.object({
    YearToClose: Yup.number()
      .typeError(INVALID_YEAR_MSG)
      .required(REQUIRED_FIELD)
      .test(exactLengthValidation(4)),
    PostDate: Yup.string().required(REQUIRED_FIELD).test(dateExistValidation),
    Remark1: Yup.string().max(50, maxStringMsg(50)).nullable(),
    Remark2: Yup.string().max(50, maxStringMsg(50)).nullable(),
    Remark3: Yup.string().max(50, maxStringMsg(50)).nullable(),
  }).test({
    name: 'validateYearEndInput',
    test: async function (values) {
      try {
        const { PostDate, YearToClose } = values

        if (PostDate === null || YearToClose === 0) {
          return true
        }

        await validationCallback(values)
        return true
      } catch (error: any) {
        const errorCode = error.response.data.ErrorCode
        return this.createError({
          message: errorCode,
        })
      }
    },
  })
}

const yearEndRunValidationCallback = AssetHistoryApi.getInstance().validateYrEndRun
const yearEndUndoValidationCallback = AssetHistoryApi.getInstance().validateYrEndUndo

export const yearEndRunSchema = yearEndSchema(yearEndRunValidationCallback, false)
export const yearEndUndoSchema = yearEndSchema(yearEndUndoValidationCallback, true)
