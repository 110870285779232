import { useEffect } from 'react'

const addBodyClass = locked => {
  locked === true && document.body.classList.add('scrolling-locked')
}
const removeBodyClass = locked => {
  document.body.classList.remove('scrolling-locked')
}

export default function useBodyClasses(locked) {
  useEffect(() => {
    locked instanceof Array ? locked.map(addBodyClass) : addBodyClass(locked)
    return () => {
      locked instanceof Array
        ? locked.map(removeBodyClass)
        : removeBodyClass(locked)
    }
  }, [locked])
}
