import {
  Button,
  Card,
  Flexbox,
  Form,
  Grid,
  Note,
  Spacer,
  Input,
  toast,
} from '@components/common'
import { EDIT_ASSETID_FORM_ID } from '@constants'
import { useForm } from 'react-hook-form'
import { EditAssetID, assetIDSchema } from '@api/models'
import { useEditAssetIDMutation } from '@api/assets/assetsEndpointsHooks'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import useFormFieldProps from '@hooks/useFormField'
import usePermission from '@components/Auth/Permissions/usePermission'

const EditAssetIDForm = () => {
  const assetCompanyID = useSelector<any>(state => state.company) as number
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
  } = useForm<EditAssetID>({
    resolver: yupResolver(assetIDSchema(assetCompanyID.toString())),
    mode: 'onSubmit',
  })

  const { getInputProps } = useFormFieldProps<EditAssetID>({
    fields: {},
    errors,
    isSubmitting,
    register,
  })

  const [editAssetID, { isLoading: isSavingAssetID }] = useEditAssetIDMutation()

  const { canEditSettings: canEdit } = usePermission()

  async function onSubmit(values: EditAssetID) {
    await editAssetID({ ...values, AssetCompanyID: assetCompanyID }).unwrap()
    toast.success(
      `Successfully changed AssetID from ${values.OldAssetID} to ${values.NewAssetID}.`
    )
    reset()
  }

  return (
    <Form id={EDIT_ASSETID_FORM_ID} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Item m={6}>
          <Card>
            <Note intent="default" content={<>Edit the Asset ID.</>} />
            <Spacer y="default" />
            <Flexbox>
              <Input
                {...getInputProps('OldAssetID')}
                label="Old Asset ID"
                disabled={!canEdit}
              />
            </Flexbox>
            <Spacer y="mini" />
            <Flexbox>
              <Input
                {...getInputProps('NewAssetID')}
                label="New Asset ID"
                disabled={!canEdit}
              />
            </Flexbox>
            <Spacer y="mini" />
            <Flexbox>
              <Button
                type="submit"
                appearance="primary"
                loading={isSavingAssetID}
                disabled={isSavingAssetID}
                hidden={!canEdit}
              >
                Submit
              </Button>
            </Flexbox>
          </Card>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default EditAssetIDForm
