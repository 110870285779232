import {
  Grid,
  Modal,
  Text,
  Fieldset,
  Flexbox,
  Input,
  Spacer,
  Spinner,
  toast,
  CustomDatePicker,
  Note,
  Icon,
} from '@components/common'
import { Controller, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { yearEndRunSchema, YearEndRunInput } from '@api/models'
import { yupResolver } from '@hookform/resolvers/yup'
import useYearEndRunHook from './useYearEndRunHook'
import DepreciationCard from './DepreciationCard'

interface YearEndRunFormProps {
  assetCompanyID: number
}

const YearEndRunForm = ({ assetCompanyID }: YearEndRunFormProps) => {
  const {
    isSuccessYearEndRun,
    isLoadingGetYearEndRunInfo,
    isYearEndRunFormOpen,
    isYearEndRunning,
    isSuccessGetYearEndRunInfo,
    isErrorGetYearEndRunInfo,
    isErrorYearEndRun,
    yearEndRunInfo,
    fields,
    errorGetYearEndRunInfo,
    openYearEndRunForm,
    closeYearEndRunForm,
    runYearEndDepreciation,
  } = useYearEndRunHook(assetCompanyID)

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<YearEndRunInput>({
    mode: 'onSubmit',
    resolver: yupResolver(yearEndRunSchema),
  })

  useEffect(() => {
    if (isSuccessGetYearEndRunInfo) {
      reset(yearEndRunInfo)
    } else if (isErrorGetYearEndRunInfo) {
      toast.error(errorGetYearEndRunInfo)
    }
  }, [
    isSuccessGetYearEndRunInfo,
    isErrorGetYearEndRunInfo,
    reset,
    yearEndRunInfo,
    errorGetYearEndRunInfo,
  ])

  useEffect(() => {
    if (!isYearEndRunning) {
      if (isErrorYearEndRun) {
        toast.error('Year end run failed')
      }
    }
  }, [isSuccessYearEndRun, isErrorYearEndRun, isYearEndRunning])

  function getInputProps(name: keyof YearEndRunInput) {
    return {
      ...register(name),
      error: errors[name] !== undefined,
      errorMsg: errors[name]?.message,
      disabled: isSubmitting,
    }
  }

  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      onBlur: field.onBlur,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  const isRunDisabled = assetCompanyID === undefined || assetCompanyID === null
  const formErrorMsg = errors['']?.message || '' // TODO: have the backend return an error code instead
  const shouldShowYearEndRunForm = isSuccessGetYearEndRunInfo && isYearEndRunFormOpen

  function closeyearEndRunInfoForm() {
    reset(yearEndRunInfo)
    closeYearEndRunForm()
  }

  return (
    <>
      <Modal
        isOpen={isYearEndRunning}
        close={() => {}}
        title="Running Year End Depreciation"
      >
        <Flexbox justifyContent="center">
          <Spinner size="large" />
        </Flexbox>
      </Modal>

      <Modal
        isOpen={shouldShowYearEndRunForm}
        close={closeyearEndRunInfoForm}
        onSubmit={handleSubmit(runYearEndDepreciation)}
        title="Year End Depreciation Run"
        actionLabel="Run"
      >
        {shouldShowYearEndRunForm && (
          <Grid>
            {formErrorMsg && errors['PostDate'] === undefined && (
              <Grid.Item>
                <Note intent="danger" content={<>{formErrorMsg}</>} />
              </Grid.Item>
            )}
            <Grid.Item>
              <Fieldset legend="Status" legendCenter>
                <Flexbox>
                  <Grid>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_COM_ID}
                        value={yearEndRunInfo.AssetCompanyID}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_DESCS}
                        value={yearEndRunInfo.ShortName}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label="Last Year Closed"
                        value={yearEndRunInfo.LastYearClosed}
                        readOnly
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Parameters" legendCenter>
                <Flexbox>
                  <Grid>
                    <Grid.Item m={6}>
                      <Input {...getInputProps('YearToClose')} label="Year To Close" />
                    </Grid.Item>
                    <Grid.Item m={6}>
                      <Controller
                        control={control}
                        name="PostDate"
                        render={({ field }) => (
                          <CustomDatePicker
                            {...getDateProps(field)}
                            label="Posting Date"
                            error={errors['PostDate'] !== undefined}
                            errorMsg={errors['PostDate']?.message}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Remarks" legendCenter>
                <Grid>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>1.</Text>
                      <Spacer x="compact" />
                      <Input {...getInputProps('Remark1')} />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>2.</Text>
                      <Spacer x="compact" />
                      <Input {...getInputProps('Remark2')} />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>3.</Text>
                      <Spacer x="compact" />
                      <Input {...getInputProps('Remark3')} />
                    </Flexbox>
                  </Grid.Item>
                </Grid>
              </Fieldset>
            </Grid.Item>
          </Grid>
        )}
      </Modal>

      <DepreciationCard
        disabled={isRunDisabled}
        loading={isLoadingGetYearEndRunInfo}
        openForm={openYearEndRunForm}
        helpText="Calculate depreciation specifically for year-end reporting, with temporary results for review."
        icon={<Icon name="TrendingDown" color="var(--primary-color500)" />}
        type={'Run'}
      />
    </>
  )
}

export default YearEndRunForm
