import { apiSlice } from '@api'

import {
  FetchFoldersResponse,
  ListFoldersResponse,
  SaveFolderListParams,
  Folder,
} from '@api/folder/models'

// Because this is global, I can leave in the @api folder
import { StandardFetchRequestPayload } from '@api/models'

export const folderEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    // FOLDERS
    listFolders: build.query<ListFoldersResponse, void>({
      query: () => `FldrMaster/ListFolders`,
      providesTags: ['folder'],
    }),
    fetchFolders: build.query<FetchFoldersResponse, StandardFetchRequestPayload>({
      query: ({ pageNumber, itemsPerPage, filter }) =>
        `FldrMaster/FetchFolders?pageNumber=${pageNumber}&itemsPerPage${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      providesTags: ['folder'],
    }),
    fetchAssetFolders: build.query<number[], { assetCompanyID: number; assetID: string }>({
      query: ({ assetCompanyID, assetID }) =>
        `FldrMaster/FetchAssetFolders?assetcompanyid=${assetCompanyID}&assetid=${assetID}`,
      transformResponse: (folders: { Key: number; Name: string }[]) => {
        const keys: number[] = []
        for (const folder of folders) {
          keys.push(folder.Key)
        }

        return keys
      },
      providesTags: ['assetTags'],
    }),
    createFolder: build.mutation<void, Folder>({
      query: ({ ...folder }) => ({
        url: 'FldrMaster/Create',
        method: 'POST',
        body: { ...folder },
      }),
      invalidatesTags: ['folder'],
    }),
    editFolder: build.mutation<void, Folder>({
      query: ({ ...folder }) => ({
        url: 'FldrMaster/Edit',
        method: 'PUT',
        body: { ...folder },
      }),
      invalidatesTags: ['folder'],
    }),
    deleteFolder: build.mutation<void, Folder>({
      query: ({ ...folder }) => ({
        url: 'FldrMaster/Delete',
        method: 'POST',
        body: { ...folder },
      }),
      invalidatesTags: ['folder'],
    }),
    saveFolderList: build.mutation<void, SaveFolderListParams>({
      query: payload => ({
        url: `FldrMaster/SaveList?assetcompanyid=${payload.assetCompanyID}&assetid=${
          payload.assetID
        }&keys=${JSON.stringify(payload.keys)}`,
        method: 'POST',
      }),
      invalidatesTags: ['Asset'],
    }),
  }),
  overrideExisting: false,
})

export const {
  // FOLDERS
  useListFoldersQuery,
  useFetchFoldersQuery,
  useCreateFolderMutation,
  useEditFolderMutation,
  useDeleteFolderMutation,
  useSaveFolderListMutation,
  useFetchAssetFoldersQuery,
} = folderEndpoints
