import { SortObject, StandardFetchRequestPayload } from '@api/models'
import { PAGINATION_NEXT_BUTTON_ID } from '@constants'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { InputChangeEvent } from '@types'
import { SortingRule } from 'react-table'
import isEqual from 'lodash.isequal'

interface TableParamsHookProps {
  searchKey: string
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  initialItemsPerPage: number
}

export interface TableParamsHook {
  pageNumber: number
  itemsPerPage: number
  fetchRequestPayload: StandardFetchRequestPayload
  handleSearch: (e: InputChangeEvent) => void
  searchValue: string
  changePage: (e: React.MouseEvent<HTMLButtonElement>) => void
  sortByInitialState: () => SortingRule<any>[]
  updateSortSearchParam: (
    sortBy: SortingRule<Object>[],
    setSortBy: (sortBy: SortingRule<Object>[]) => void
  ) => void
  isTotalCountEmpty?: (count: number) => boolean
  canEdit?: boolean
}

export default function useTableParams({
  searchKey,
  filterState,
  setFilterState,
  initialItemsPerPage,
}: TableParamsHookProps): TableParamsHook {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])

  // Get values
  const pageNumber: number = searchParams.get('pageNumber')
    ? parseInt(searchParams.get('pageNumber'))
    : 1
  const itemsPerPage: number = searchParams.get('itemsPerPage')
    ? parseInt(searchParams.get('itemsPerPage'))
    : initialItemsPerPage

  // Set mandatory search params on mount if none exist
  useEffect(() => {
    if (!searchParams.get('itemsPerPage')) {
      setSearchParams({
        ...searchParamsObject,
        itemsPerPage: '20',
      })
    }
    if (!searchParams.get('pageNumber')) {
      setSearchParams({
        ...searchParamsObject,
        pageNumber: '1',
      })
    }
  }, [searchParams.get('itemsPerPage'), searchParams.get('pageNumber')])

  // Set request payload based on search params and filterState
  const fetchRequestPayload: StandardFetchRequestPayload = {
    itemsPerPage: searchParams.get('itemsPerPage')
      ? parseInt(searchParams.get('itemsPerPage'))
      : 20,
    pageNumber: searchParams.get('pageNumber')
      ? parseInt(searchParams.get('pageNumber'))
      : 1,
    filter: filterState,
  }

  // useEffect to update payload filter based searchKey in URL params
  useEffect(() => {
    if (searchParams.get(searchKey)) {
      setFilterState(prevState => ({
        ...prevState,
        filter: {
          logic: 'and',
          filters: [
            {
              field: searchKey,
              operator: 'contains',
              value: searchParams.get(searchKey),
            },
          ],
        },
      }))
    } else {
      setFilterState({})
    }
  }, [searchParams.get(searchKey)])

  // useEffect to update payload filter based on sort in URL params
  useEffect(() => {
    if (searchParams.get('sort')) {
      // sort url param looks like: Field_asc or Field_desc
      const idUnderscore = searchParams.get('sort').lastIndexOf('_')
      const field = searchParams.get('sort').slice(0, idUnderscore)

      const dir = searchParams.get('sort').slice(idUnderscore + 1)

      const sortObject: SortObject = {
        field,
        dir: dir as 'asc' | 'desc',
      }
      setFilterState(prevState => ({
        ...prevState,
        sort: [sortObject],
      }))
    }
  }, [searchParams.get('sort')])

  const [searchValue, setSearchValue] = useState<string>(searchParams.get(searchKey) ?? '')

  function handleSearch(e: InputChangeEvent): void {
    setSearchValue(e.target.value)
    if (e.target.value === '') {
      setTimeout(() => {
        if (e.target.value === '') {
          searchParams.delete(searchKey)
          setSearchParams({ ...searchParams, pageNumber: '1' })
        }
      }, 500) // shorter timeout when user clears search
    } else {
      setTimeout(() => {
        setSearchParams({
          ...searchParamsObject,
          [searchKey]: e.target.value,
          pageNumber: '1',
        })
      }, 2000)
    }
  }

  function changePage(e: React.MouseEvent<HTMLButtonElement>): void {
    if (e.target instanceof Element) {
      let goToPageNumber: number
      if (e.target.id === PAGINATION_NEXT_BUTTON_ID) {
        goToPageNumber = pageNumber + 1
      } else {
        goToPageNumber = pageNumber - 1
      }

      setSearchParams({
        ...searchParamsObject,
        pageNumber: goToPageNumber.toString(),
      })
    }
  }

  const sortByInitialState = (): SortingRule<any>[] => {
    if (searchParams.get('sort')) {
      const idUnderscore = searchParams.get('sort').lastIndexOf('_')
      const field = searchParams.get('sort').slice(0, idUnderscore)

      const dir = searchParams.get('sort').slice(idUnderscore + 1)

      return [
        {
          id: field,
          desc: dir === 'desc',
        },
      ]
    } else return []
  }

  function updateSortSearchParam(
    sortBy: SortingRule<Object>[],
    setSortBy: (sortBy: SortingRule<Object>[]) => void
  ): void {
    // ** multi sort is disabled in useTable
    // if we need multisort this will need to be refactored
    if (sortBy.length > 0) {
      const { desc: isDesc, id: field } = sortBy[0]

      let dir: 'asc' | 'desc'
      if (isDesc) {
        dir = 'desc'
      } else {
        dir = 'asc'
      }

      setSearchParams({
        ...searchParamsObject,
        sort: `${field}_${dir}`,
      })
    } else {
      if (searchParams.get('sort')) {
        searchParams.delete('sort')
        setSearchParams(searchParams)
      }
    }

    // update sortBy if page loaded with sort in url params
    if (sortBy.length === 0 && searchParams.get('sort')) {
      const idUnderscore = searchParams.get('sort').lastIndexOf('_')
      const field = searchParams.get('sort').slice(0, idUnderscore)

      const dir = searchParams.get('sort').slice(idUnderscore + 1)

      setSortBy([
        {
          id: field,
          desc: dir === 'desc',
        },
      ])
    }
  }

  //
  const isTotalCountEmpty = (count: number): boolean => {
    if (isEqual(filterState, {}) && count === 0) {
      return true
    }

    return false
  }

  return {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    isTotalCountEmpty,
  }
}
