import { Card, EmptyState, Link } from '@components/common'
import { COMPANY_PATHS } from '@constants'

const AssetStructureCompanyHasNoPeriods = ({ assetCompanyID, assetID }) => {
  return (
    <Card>
      <EmptyState
        heading="You cannot perform a structure transfer before setting up periods"
        text={
          <>
            All structure transfers must be accounted for in a specific period. Once you
            <Link to={`/${COMPANY_PATHS.ROOT}/${assetCompanyID}/${COMPANY_PATHS.PERIODS}`}>
              set up company periods
            </Link>{' '}
            and run depreciation, you can perform a structure transfer here. <br /> Because
            this has asset has no depreciation history, you can edit the GL keys under{' '}
            <Link to={`/assets/${assetCompanyID}/${assetID}/details`}>asset details</Link>.
          </>
        }
      />
    </Card>
  )
}

export default AssetStructureCompanyHasNoPeriods
