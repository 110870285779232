import { Card, TableSkeleton, Table, TextSkeleton, Flexbox, ButtonSkeleton } from '@common'
import Template from '@components/layout/Template'

const CustomDepreciationLoading = () => {
  return (
    <Template maxWidth="compact">
      <Card spacing="none">
        <Table.Actions>
          <Flexbox justifyContent="space-between" alignItems="center">
            <TextSkeleton width="120px" />
            <ButtonSkeleton placeholder="Create custom depreciation" />
          </Flexbox>
        </Table.Actions>
        <TableSkeleton rows={5} />
      </Card>
    </Template>
  )
}
export default CustomDepreciationLoading
