import { Flexbox, Heading, Spacer, Spinner } from '@components/common'
import Template from '@components/layout/Template'

const CompanyTemplateLoading = () => {
  return (
    <Template
      maxWidth="compact"
      breadcrumbs={[
        {
          label: 'Companies',
          path: '/companies',
        },
        {
          label: 'Company Name...',
          isActive: true,
        },
      ]}
    >
      <Flexbox flexDirection="row">
        <Spinner />

        <Spacer x="default" />
        <Heading type="h1">Company Name...</Heading>
      </Flexbox>
      <Spacer y="default" />
    </Template>
  )
}
export default CompanyTemplateLoading
