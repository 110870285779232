import {
  useCreateReportTemplateMutation,
  useFetchAllReportTemplateQuery,
} from '@api/reportTemplate/reportTemplateEndpointsHooks'
import { toast, useModal } from '@components/common'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { IUseMemorizeReport } from './IMemorizeReports'
import {
  useDeleteTemplateMutation,
  useEditTemplateMutation,
} from '@api/reportTemplate/reportTemplateEndpoints'

// those methods is what I'm going to save in the database, as the template
export const useMemorizeReport = ({ methods, currentReportPath }: IUseMemorizeReport) => {
  const {
    modalIsOpen: isMemorizeTemplateModalOpen,
    closeModal: closeMemorizeTemplateModal,
    triggerModal: triggerMemorizeTemplateModal,
  } = useModal()

  const assetCompanyID = useSelector<any>(state => state.company) as number

  const [isDelete, setIsDelete] = useState<boolean>(false)

  // To save the template
  const [
    createReportTemplate,
    {
      isLoading: isLoadingCreateCompanyMutation,
      isSuccess: isSuccessCreateTemplate,
      isError: isErrorCreateTemplate,
      error: errorCreateTemplate,
    },
  ] = useCreateReportTemplateMutation()

  const [
    editReportTemplate,
    {
      isLoading: isLoadingEditCompanyMutation,
      isSuccess: isSuccessEditTemplate,
      isError: isErrorEditTemplate,
      error: errorEditTemplate,
    },
  ] = useEditTemplateMutation()

  const [
    deleteReportTemplate,
    {
      isLoading: isLoadingDeleteCompanyMutation,
      isSuccess: isSuccessDeleteTemplate,
      isError: isErrorDeleteTemplate,
      error: errorDeleteTemplate,
    },
  ] = useDeleteTemplateMutation()

  // To get all saved templates
  const {
    data: allReportTemplateData,
    isLoading: isLoadingFetchAllReportTemplateQuery,
    isSuccess: isSuccessFetchAllReportTemplateQuery,
    isError: isErrorFetchAllReportTemplateQuery,
  } = useFetchAllReportTemplateQuery(assetCompanyID)

  const handleMemorizeTemplateSubmit = async templateDetails => {
    await createReportTemplate({
      AssetCompanyID: assetCompanyID,
      TP_NAME: templateDetails['name'],
      TP_DESC: templateDetails['description'],
      TP_RP_PATH: currentReportPath,
      TP_DATA: JSON.stringify(methods?.getValues()),
    })
  }

  const handleEditTemplateSubmit = async templateDetails => {
    await editReportTemplate({
      AssetCompanyID: assetCompanyID,
      TP_NAME: templateDetails['name'],
      TP_DESC: templateDetails['description'],
      TP_RP_PATH: currentReportPath,
      TP_DATA: JSON.stringify(methods?.getValues()),
    })
  }

  const handleDeleteTemplateSubmit = async templateDetails => {
    await deleteReportTemplate({
      AssetCompanyID: assetCompanyID,
      TP_NAME: templateDetails['name'],
      TP_DESC: templateDetails['description'],
      TP_RP_PATH: currentReportPath,
      TP_DATA: JSON.stringify(methods?.getValues()),
    })

    setIsDelete(false)
  }

  useEffect(() => {
    if (isSuccessDeleteTemplate) {
      toast.success('Template deleted successfully')
      return
    }

    if (isErrorCreateTemplate) {
      toast.error(errorCreateTemplate['data'])
      return
    }
    if (isErrorEditTemplate) {
      toast.error(errorEditTemplate['data'])
      return
    }

    if (isSuccessCreateTemplate || isSuccessEditTemplate) {
      toast.success('Template saved successfully')
      closeMemorizeTemplateModal()
    }
  }, [
    isSuccessCreateTemplate,
    isErrorCreateTemplate,

    isSuccessEditTemplate,
    isErrorEditTemplate,

    isSuccessDeleteTemplate,
  ])

  return {
    isMemorizeTemplateModalOpen,
    allReportTemplateData,
    isLoadingFetchAllReportTemplateQuery,
    closeMemorizeTemplateModal,
    triggerMemorizeTemplateModal,
    handleMemorizeTemplateSubmit,

    handleEditTemplateSubmit,

    handleDeleteTemplateSubmit,
    isDelete,
    setIsDelete,
  }
}
