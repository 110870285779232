import React from 'react'
import { AccordionItemProps } from './Accordion.types'
import { motion, AnimatePresence } from 'framer-motion'
import { useStyletron } from 'styletron-react'

import * as styles from './Styles'
import AccordionIcon from './AccordionIcon'

const AccordionItem: React.FC<AccordionItemProps> = ({
  isOpen,
  item,
  onClick,
  id,
  autoFocus,
}) => {
  const [css] = useStyletron()
  let buttonStyles = css({
    ...styles.button,
  })

  let ariaId = `accordion-item-${id}`

  return (
    <li key={item.label} className={css(styles.item)}>
      <button
        type="button"
        className={buttonStyles}
        onClick={onClick}
        aria-controls={ariaId}
        aria-expanded={isOpen ? 'true' : 'false'}
        autoFocus={autoFocus}
      >
        <div>
          {item.label}{' '}
          <AnimatePresence initial={false}>
            {item.subHeading && !isOpen && (
              <motion.div
                className={css(styles.buttonSubheading)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.05, ease: [0.04, 0.62, 0.23, 0.8] }}
              >
                {item.subHeading}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <AccordionIcon isOpen={isOpen} />
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className={css(styles.content)}
            key="content"
            initial="collapsed"
            animate="isOpen"
            exit="collapsed"
            variants={{
              isOpen: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.16, ease: [0.04, 0.62, 0.23, 0.8] }}
            id={ariaId}
            role="region"
            aria-label={item.label}
          >
            <motion.div
              className={css(styles.content__inner)}
              initial="collapsed"
              animate="isOpen"
              exit="collapsed"
              variants={{
                isOpen: { opacity: 1 },
                collapsed: { opacity: 0 },
              }}
              transition={{
                duration: 0.2,
                ease: [0.23, 1, 0.32, 1],
                delay: 0.12,
              }}
            >
              {item.content}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  )
}

export default AccordionItem
