import { Button, Card, EmptyState, Link } from '@common'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { useParams } from 'react-router-dom'
import { HistoryBase } from '@api/models'
import usePermission from '@components/Auth/Permissions/usePermission'

interface Props {
  profileName: string
  assetID: string
  openForm: (isCreate: boolean, charge?: HistoryBase) => void
}
const EmptyHistoryState = ({ profileName, assetID, openForm }: Props) => {
  const params = useParams()
  const { data: isAssetDesc, isLoading: isAssetDetailLoading } = useGetAssetQuery({
    assetCompanyID: parseInt(params.companyID),
    assetID: params.assetID,
  })

  const { canEditAssets: canEdit } = usePermission()

  const EditingEnabled =
    isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false

  if (isAssetDetailLoading) return <>Loading...</>

  return (
    <Card>
      <EmptyState
        heading={`Asset ${assetID} has no ${profileName.toLowerCase()} depreciation history.`}
        text={
          <>
            When you <Link to={`/depreciation`}>run/update depreciation</Link>, history
            records for this asset will show here.
          </>
        }
        primaryAction={
          <Button
            appearance="primary"
            disabled={EditingEnabled}
            hidden={!canEdit}
            onClick={() => openForm(true)}
          >
            Add manual history record
          </Button>
        }
      />
    </Card>
  )
}
export default EmptyHistoryState
