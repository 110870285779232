import { apiSlice } from '@api'
import { getUserFullName } from '@components/Auth/profile'
import { serializeURLParams, formatISODate } from '@utils/basic'
import { GetCommentPayload, Comment } from './models'

export const commentsEndopoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getComment: build.query<Comment, GetCommentPayload>({
      query: payload => `Comment/GetComment?${serializeURLParams(payload)}`,
      transformResponse: (res: Comment) =>
        res != null
          ? {
              ...res,
              CO_AD_DAT: formatISODate(res.CO_AD_DAT),
            }
          : null,
      providesTags: ['comment'],
    }),
    updateComment: build.mutation<void, Comment>({
      query: payload => ({
        url: 'Comment/Edit',
        method: 'PUT',
        body: { ...payload, CO_USR: getUserFullName() },
      }),
      invalidatesTags: ['comment'],
    }),
  }),
  overrideExisting: false,
})

export const { useLazyGetCommentQuery, useUpdateCommentMutation } = commentsEndopoints
