import * as Yup from 'yup'
import { REQUIRED_FIELD } from '@constants'

export interface IMemorizeReportForm {
  name?: string
  description?: string
  required?: boolean
}

export interface IMemorizeReportsProps {
  isMemorizeTemplateModalOpen: boolean
  closeMemorizeTemplateModal: () => void
  handleMemorizeTemplateSubmit: (data: IMemorizeReportForm) => void
  handleEditTemplateSubmit?: (data: IMemorizeReportForm) => void
  isEditTemplate?: boolean
  selectedTemplateDetails?: any
}

export interface IMemorizedReportsTableProps {
  data: any

  setValueMemorizeReport?: any
  isMemorizeTemplateModal?: boolean
  setIsMemorizeTemplateModal?: any

  setValueRetrieveReport?: any
  isRetrieveTemplateModal?: boolean
  resetRetrieveReport?: any

  handleEditButtonClick?: any

  handleDeleteButtonClick?: any

  isEditTemplate?: boolean
  selectedTemplateDetails?: any

  handleSelectedRow?: any
}

export type IUseMemorizeReport = {
  methods?: any
  currentReportPath?: string
}

export const memorizeReportSchema = () =>
  Yup.object({
    name: Yup.string().min(1).max(50).required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
    description: Yup.string()
      .min(1)
      .max(100)
      .required(REQUIRED_FIELD)
      .typeError(REQUIRED_FIELD),
  })

export type MemorizeTemplateFields = Yup.InferType<ReturnType<typeof memorizeReportSchema>>
export type MemorizeTemplateFieldKeys = keyof MemorizeTemplateFields
