import { useState } from 'react'
import { Form, Grid, Input } from '@common'
import { useFields } from '@hooks'
import { getInputPropsForModalForms } from '@utils/getInputPropsForModalForms'
import { FOLDER_FORM_ID } from '@constants'
import { Folder, FolderKey, folderSchema } from '@api/models'
import { FormSubmitEvent } from '@types'
import { validateAndSubmit } from '@utils/validateAndSubmit'
import { ReactSelectOptions, SelectType } from '@components/common/Select/Select.types'

interface FolderFormProps {
  isEdit: boolean
  initialValues
  handleSubmit: (payload: Folder) => Promise<void>
  canEdit?: boolean
}

const FolderForm = ({
  isEdit,
  initialValues,
  handleSubmit,
  canEdit = true,
}: FolderFormProps) => {
  const { fields } = useFields()
  const [form, setForm] = useState({
    values: initialValues,
    errors: {},
  })

  function getField(
    name: FolderKey,
    select?: SelectType,
    selectOptions?: ReactSelectOptions
  ) {
    const field = getInputPropsForModalForms({
      name,
      select,
      selectOptions,
      fieldMap: fields,
      schema: folderSchema as any,
      initialValues,
      form,
      setForm,
    })

    return { ...field, readOnly: !canEdit }
  }

  async function handleFormSubmit(e: FormSubmitEvent): Promise<void> {
    e.preventDefault()
    await validateAndSubmit(folderSchema as any, form, setForm, handleSubmit)
  }

  return (
    <Form id={FOLDER_FORM_ID} onSubmit={handleFormSubmit} noValidate>
      <Grid>
        <Grid.Item>
          <Input {...getField('FL_FOLD_ID')} autoFocus />
        </Grid.Item>

        <Grid.Item>
          <Input {...getField('FL_TAB_NM')} />
        </Grid.Item>
        <Grid.Item>
          <Input {...getField('FL_TAB_VL')} />
        </Grid.Item>
      </Grid>
    </Form>
  )
}
export default FolderForm
