import { REQUIRED_FIELD } from '@constants'
import { maxStringMsg } from '@utils/basic'
import * as Yup from 'yup'
import { digitLengthValidation } from '../../models/validation'

export const folderSchema = Yup.object({
  FL_FOLD_ID: Yup.string().max(25, maxStringMsg(25)).required(REQUIRED_FIELD),
  FL_TAB_NM: Yup.string().max(25, maxStringMsg(25)).required(REQUIRED_FIELD),
  FL_TAB_VL: Yup.string().max(25, maxStringMsg(25)).required(REQUIRED_FIELD),
  FL_SYS_NR: Yup.number().test(digitLengthValidation(9)).nullable(),
})

export type Folder = Yup.InferType<typeof folderSchema>
export type FolderKey = keyof Folder

export const initialFolder: Folder = {
  FL_FOLD_ID: '',
  FL_TAB_NM: '',
  FL_TAB_VL: '',
  FL_SYS_NR: undefined,
}
// Payload requests and responses
export type ListFoldersResponse = { Key: number; Name: string }[]

export interface FetchFoldersResponse {
  count: number
  list: Folder[]
}

export interface SaveFolderListParams {
  assetCompanyID: number
  assetID: string
  keys: number[]
}
