import { REQUIRED_FIELD } from '@constants'
import { maxStringMsg } from '@utils/basic'
import * as Yup from 'yup'

export interface UserListItem {
  firstName: string
  lastName: string
  email: string
  userID: string
  userRoles: AppRole[]
  actions: any
  isEnabled: boolean
}

export interface FetchUsersResponse {
  count: number
  list: UserListItem[]
}

export interface UserInvitationPayload {
  email: string
  roleNames: string[]
}

export interface UserInvitationFormFields {
  email: string
  appRoles: AppRole[]
}

export interface AppRole {
  applicationName: string
  roleName: string
}

export interface OrgID {
  orgID: string
}

export interface GetOrganizationNameResponse {
  orgName: string
}

export const userInviteSchema = Yup.object({
  email: Yup.string().max(100, maxStringMsg(100)).required(REQUIRED_FIELD).default(''),
})
