import { Modal } from '@common'

interface IProps {
  discard
  formId?: string
  modalIsOpen: boolean
  closeModal
}

const DiscardModal = ({ discard, formId, modalIsOpen, closeModal }: IProps) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      close={closeModal}
      title="Discard all unsaved changes"
      width={520}
      dismissLabel="Continue editing"
      actionLabel="Discard changes"
      action={discard}
      form={formId}
      intent="danger"
      type={formId ? 'reset' : 'button'}
    >
      If you discard changes, you’ll delete any edits you made since you last saved.
    </Modal>
  )
}
export default DiscardModal
