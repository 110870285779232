import React from 'react'
import { HeadingProps } from './Heading.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

const Heading: React.FC<HeadingProps> = ({ type = 'h1', ...props }) => {
  const [css] = useStyletron()

  let headingStyles: string

  if (!props.baseSize && !props.eyebrow) {
    headingStyles = css({ ...styles.type({ type }) })
  } else if (props.baseSize) {
    headingStyles = css({ ...styles.baseSize })
  } else if (props.eyebrow) {
    headingStyles = css({ ...styles.eyebrow })
  }

  if (props.centered === true) headingStyles += ' ' + css({ ...styles.centered })
  if (props.bold === true) headingStyles += ' ' + css({ ...styles.bold })
  if (props.underline) headingStyles += ' ' + css({ ...styles.underline })
  if (props.primary) headingStyles += ' ' + css({ ...styles.primary })
  if (props.style) headingStyles += ' ' + css({ ...props.style })
  if (props.shadow) headingStyles += ' ' + css({ ...styles.shadow })

  let render: JSX.Element
  switch (type) {
    case 'h1':
      render = (
        <h1 className={headingStyles} id={props.id}>
          {props.children}
        </h1>
      )
      break
    case 'h2':
      render = (
        <h2 className={headingStyles} id={props.id}>
          {props.children}
        </h2>
      )
      break
    case 'h3':
      render = (
        <h3 className={headingStyles} id={props.id}>
          {props.children}
        </h3>
      )
      break
    case 'h4':
      render = (
        <h4 className={headingStyles} id={props.id}>
          {props.children}
        </h4>
      )
      break
    case 'h5':
      render = (
        <h5 className={headingStyles} id={props.id}>
          {props.children}
        </h5>
      )
      break
    case 'h6':
      render = (
        <h6 className={headingStyles} id={props.id}>
          {props.children}
        </h6>
      )
      break
  }

  return render
}
export default Heading
