import React from 'react'

import { useStyletron } from 'styletron-react'
import * as styles from '../Styles'

export interface TableRowProps extends React.HTMLProps<HTMLTableRowElement> {}

const Row: React.FC<TableRowProps> = ({ ...props }) => {
  const [css] = useStyletron()

  let rowStyles = css({
    ...styles.bodyRow({ onClick: props.onClick }),
  })

  return (
    <div role="row" className={rowStyles} {...props} tabIndex={0}>
      {props.children}
    </div>
  )
}

export default Row
