import React from 'react'

import { NavLink, useLocation } from 'react-router-dom'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

interface Props {
  items: Array<any>
  heading: string
}

const List: React.FC<Props> = ({ items, heading }) => {
  const [css] = useStyletron()
  const location = useLocation()

  let linkStyles = ({ active }) => {
    return {
      ...styles.linkBase,
      ...styles.getActive({ active }),
    }
  }

  return (
    <div className={css(styles.listContainer)}>
      <ul className={css(styles.list)}>
        {items.map((i, index) => (
          <li key={i.label} className={css(styles.item)}>
            <NavLink
              to={`${i.path}${location.pathname === i.path ? location.search : ''}`}
              className={({ isActive }) => css(linkStyles({ active: isActive }))}
            >
              {i.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default List
