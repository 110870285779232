import { AppState, APP_STATE_TYPES } from '@api/models'
import { Flexbox, Modal, Spacer, Spinner, Text } from '@components/common'
import moment from 'moment'
import { useEffect, useState } from 'react'

interface Props {
  appState: AppState
}

const AppStateModal = ({ appState }: Props) => {
  const [totalElapsedSeconds, setTotalElapsedSeconds] = useState(0)

  useEffect(() => {
    const currentTime = new Date().getTime()
    const appStateTime = new Date(appState.TimeStamp).getTime()
    const totalElapsedMilliseconds = Math.max(0, currentTime - appStateTime)
    setTotalElapsedSeconds(Math.floor(totalElapsedMilliseconds / 1000))

    const interval = setInterval(() => {
      setTotalElapsedSeconds(prevSeconds => prevSeconds + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [appState])

  let seconds = totalElapsedSeconds % 60
  let minutes = Math.floor(totalElapsedSeconds / 60) % 60
  let hours = Math.floor(totalElapsedSeconds / 3600) % 24

  function endsWithS(n: number) {
    return n === 1 ? '' : 's'
  }

  let reasonForLocking = ''
  switch (appState.StateType) {
    case APP_STATE_TYPES.PERIOD_DEPRECIATION_RUN:
      reasonForLocking = 'period run'
      break
    case APP_STATE_TYPES.YEAR_END_DEPRECIATION_RUN:
      reasonForLocking = 'year end run'
      break
    case APP_STATE_TYPES.PERIOD_DEPRECIATION_UPDATE:
      reasonForLocking = 'period update'
      break
    case APP_STATE_TYPES.YEAR_END_DEPRECIATION_UPDATE:
      reasonForLocking = 'year end update'
      break
    case APP_STATE_TYPES.PERIOD_DEPRECIATION_UNDO:
      reasonForLocking = 'period undo'
      break
    case APP_STATE_TYPES.YEAR_END_DEPRECIATION_UNDO:
      reasonForLocking = 'year end undo'
      break
    case APP_STATE_TYPES.CATEGORY_RECALCULATION:
      reasonForLocking = 'category recalculation'
      break
    case APP_STATE_TYPES.TAX_CLASS_RECALCULATION:
      reasonForLocking = 'tax class recalculation'
      break
    default:
      reasonForLocking = ''
  }

  return (
    <Modal isOpen={true} close={() => {}} title="Asset Book Locked" width={520}>
      <Flexbox flexDirection="column">
        <Flexbox
          justifyContent="flex-start"
          flexDirection="row"
          alignItems="center"
          height={50}
        >
          <Flexbox justifyContent="center" flexDirection="column" height={50}>
            <Text>A {reasonForLocking} is in progress, please wait.</Text>
            <Text>Started: {moment(appState.TimeStamp).toString()}</Text>
            <Text>
              Elapsed Time: {hours > 0 && `${hours} hour${endsWithS(hours)} `}
              {minutes} minute{endsWithS(minutes)} {seconds} second{endsWithS(seconds)}
            </Text>
          </Flexbox>
          <Spacer x="large" />
          <Spacer x="large" />
          <Spacer x="large" />
          <Spacer x="large" />
          <Spinner size="large" />
        </Flexbox>
      </Flexbox>
    </Modal>
  )
}

export default AppStateModal
