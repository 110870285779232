import { useState } from 'react'
import { useLockScroll } from '@hooks'

const useModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  function triggerModal() {
    setModalIsOpen(true)
  }
  function closeModal() {
    setModalIsOpen(false)
  }

  useLockScroll(modalIsOpen === true)

  return { modalIsOpen, triggerModal, closeModal }
}
export default useModal
