import { Button, Grid, Input, Text } from '@common'
import { useState } from 'react'

interface IDuplicateAssetForm {
  assetID: string,
  onDuplicateClick?: (newAssetId) => void,
  isLoadingDuplicate: boolean,
}

const DuplicateAssetForm = ({ assetID, onDuplicateClick, isLoadingDuplicate }: IDuplicateAssetForm) => {
  const [newAssetIDState, setNewAssetIDState] = useState<string>('')
  
  return (
    <Grid colGap="mini">
      <Grid.Item>
        <Text light>
          Enter the Asset Id for your new asset:

        </Text>
      </Grid.Item>
      <Grid.Item>
        <Input
          onChange={({target}) => setNewAssetIDState(target.value)}
          value={newAssetIDState}
          autoFocus 
          autocomplete="off"
          maxLength={12}
        />
      </Grid.Item>
      <Grid.Item>
        <Button
          flex
          onClick={() => onDuplicateClick(newAssetIDState)}
          appearance="primary"
          intent="default"
          disabled={isLoadingDuplicate || !newAssetIDState}
          loading={isLoadingDuplicate}
        >
          Duplicate Asset {assetID}
        </Button>
      </Grid.Item>
    </Grid>
  )
}
export default DuplicateAssetForm
