import { useStyletron } from 'styletron-react'
import { LayoutProps } from './Layout.types'
import * as styles from './Styles'

const Layout = ({ hasSidebar, sidebarElement, children }: LayoutProps) => {
  const [css] = useStyletron()

  if (hasSidebar)
    return (
      <div className={css(styles.layoutWrapper(hasSidebar))}>
        <div className={css(styles.layoutSidebar)}>{sidebarElement}</div>

        <div className={css(styles.layoutMain)}>{children}</div>
      </div>
    )

  return children
}

export default Layout
