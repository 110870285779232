import * as Yup from 'yup'
import { REQUIRED_FIELD } from '@constants'
import { currencyValidation, dateExistValidation } from '@api/models/validation'
import { getTodaysDate, maxStringMsg } from '@utils/basic'
import { ReactSelectOptions } from '@components/common/Select/Select.types'

export const assetChargeSchema = Yup.object({
  AssetCompanyID: Yup.number(),
  AssetID: Yup.string(),
  CH_ACM_CD: Yup.string().nullable(),
  CH_SYS_NR: Yup.number(),
  CH_ACT_ID: Yup.string().nullable(),
  CH_DATE: Yup.string()
    .typeError(REQUIRED_FIELD)
    .required(REQUIRED_FIELD)
    .default(getTodaysDate())
    .test(dateExistValidation),
  CH_DESC: Yup.string()
    .typeError(REQUIRED_FIELD)
    .max(50, maxStringMsg(50))
    .required(REQUIRED_FIELD),
  CH_PERSON: Yup.string().max(25, maxStringMsg(25)).nullable(),
  CH_COST: Yup.number()
    .typeError(REQUIRED_FIELD)
    .test(currencyValidation)
    .nullable()
    .default(0),
  CH_AUDITNR: Yup.string().max(12, maxStringMsg(12)).nullable(),
  CH_DIS_CD: Yup.string().required(REQUIRED_FIELD).default('D'),
  CH_CAPC_ID: Yup.string().nullable(),
  CH_CDPC_ID: Yup.string().nullable(),
  CH_LOCT_CD: Yup.string().max(12, maxStringMsg(12)).nullable(),
  CH_LOC_NR: Yup.string().max(12, maxStringMsg(12)).nullable(),
  CH_LOC_DAT: Yup.string().nullable(),
  CH_INV_DAT: Yup.string()
    .typeError(REQUIRED_FIELD)
    .nullable()
    .default(getTodaysDate())
    .test(dateExistValidation),
  CH_SER_LCO: Yup.number()
    .typeError(REQUIRED_FIELD)
    .test(currencyValidation)
    .nullable()
    .default(0),
  CH_SER_OCO: Yup.number()
    .typeError(REQUIRED_FIELD)
    .test(currencyValidation)
    .nullable()
    .default(0),
  CH_DEP_AMT: Yup.number()
    .typeError(REQUIRED_FIELD)
    .test(currencyValidation)
    .nullable()
    .default(0),
  CH_SER_CD: Yup.string().max(12, maxStringMsg(12)).nullable(),
  CH_POS_CD: Yup.string().nullable(),
  CH_POS_CDC: Yup.bool(),
  CH_POS_CDT: Yup.bool(),
  CH_POS_CDO: Yup.bool(),
  CH_DEP_STC: Yup.string().nullable(),
  CH_FOLDER: Yup.number().nullable(),
  CH_ADD_DAT: Yup.string().nullable(),
  CH_ADD_USR: Yup.string().nullable(),
  CH_REM_CD: Yup.bool(),
  CH_TAPC_ID: Yup.string().nullable(),
  CH_TDPC_ID: Yup.string().nullable(),
  CH_SER_ID: Yup.string().nullable(),
  CH_SER_DAT: Yup.string().nullable(),
  CH_MAIL: Yup.string().nullable(),
  CH_SYS_NR_OLD: Yup.number().nullable(),
})

export type AssetCharge = Yup.InferType<typeof assetChargeSchema>
export type AssetChargeKey = keyof AssetCharge

export type AssetChargeType = 'L' | 'S' | 'O' | 'P'

export interface FetchAssetChargeParams {
  assetCompanyID: number
  assetID: string
  type: AssetChargeType
}

// constants
export const ASSET_CHARGE_TYPES = [
  {
    label: 'Location',
    value: 'L',
  },
  {
    label: 'Service',
    value: 'S',
  },
  {
    label: 'Task/Activity',
    value: 'O',
  },
  {
    label: 'Folder',
    value: 'F',
  },
  {
    label: 'Partial Disposal',
    value: 'P',
  },
]

export interface AssetChargeExtraFields {
  references: ReactSelectOptions
  locations: ReactSelectOptions
  glPosts: ReactSelectOptions
  activities: ReactSelectOptions
  disposition: ReactSelectOptions
  servicedBy: ReactSelectOptions
  performedBy: ReactSelectOptions
  soldTo: ReactSelectOptions
}
