import { Button, Flexbox, Select, Text } from '@components/common'
import { OptionProps } from '@components/common/Select/Select.types'
import React, { useState } from 'react'
import { ImportColumn, ImportType } from './importTypes'
import { getOptions } from './columns'

interface ImportMapProps {
  file: File
  entity: ImportType
  updateMapping: (mapping: { [key: string]: ImportColumn }) => void
}

const ImportMap: React.FC<ImportMapProps> = ({ file, entity, updateMapping }) => {
  const [headers, setHeaders] = useState<string[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const [mapping, setMapping] = useState<{ [key: string]: ImportColumn }>({})

  const options = getOptions(entity)

  React.useEffect(() => {
    // Read the first line of the file to get the headers
    if (file) {
      const reader = new FileReader()
      reader.onload = e => {
        const lines = (e.target.result as string).split('\n')
        const headerLine = lines[0].split(',').map(h => h.replace('\r', ''))
        setHeaders(headerLine)

        headerLine.forEach(header => {
          const find = options.find(o => o.label === header)
          if (find) {
            setMapping(m => ({
              ...m,
              [header]: find,
            }))

            setSelected(s => [...s, header])
          }
        })
      }

      reader.readAsText(file)
    }

    return () => {
      setHeaders([])
    }
  }, [file, options])

  React.useEffect(() => {
    updateMapping(mapping)
  }, [mapping, updateMapping])

  const handleMappingChange = React.useCallback(
    (e: OptionProps, header: string) => {
      setSelected(s => [...s, e.label])

      setMapping(m => ({
        ...m,
        [header]: e as ImportColumn,
      }))
    },
    []
  )

  const clearItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const field = e.currentTarget.id

    if (field) {
      setSelected(s => s.filter(s => s !== field))
      mapping[field] = null
    }
  }

  if (!file || headers.length === 0) return <></>

  return (
    <Flexbox flexDirection="column" gap={12} style={{ padding: '16px' }}>
      <Flexbox flexDirection="row" alignItems="center" gap={12}>
        <Text style={{ width: '30%', fontSize: '1.1rem' }}>File Column</Text>
        <Text style={{ flexGrow: 1, fontSize: '1.1rem' }}>Map to</Text>
      </Flexbox>
      {headers.map(header => (
        <Flexbox flexDirection="row" alignItems="center" key={header} gap={12} style={{ borderBottom: '1px solid whitesmoke', padding: '8px 0'}}>
          <Text bold style={{ width: '30%' }}>{header}</Text>
          <Select
            placeholder="Unmapped"
            flexGrow
            onChange={e => handleMappingChange(e, header)}
            value={mapping[header]}
            options={options.filter(o => !selected.includes(o.label))}
          />
          <Button
            shape="pill"
            appearance="muted"
            intent="danger"
            size="compact"
            id={header}
            onClick={clearItem}
          >
            Clear
          </Button>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default ImportMap
