import { Button, Flexbox, Input, Spacer, Text } from '@components/common'
import { InputType } from '@components/common/Input/Input.types'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AssetsTableFilterField } from './Filters.types'

interface Props {
  filter: AssetsTableFilterField
}

const AssetsTableDefaultFilter = ({ filter }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])

  let initValue
  let initMaxValue

  if (filter.compare) {
    if (filter.type === Number || filter.type === 'currency') {
      initValue = searchParams.get(`MIN_${filter.column}`)
        ? parseInt(searchParams.get(`MIN_${filter.column}`))
        : undefined
      initMaxValue = searchParams.get(`MAX_${filter.column}`)
        ? parseInt(searchParams.get(`MAX_${filter.column}`))
        : undefined
    } else {
      initValue = searchParams.get(`MIN_${filter.column}`) ?? undefined
      initMaxValue = searchParams.get(`MAX_${filter.column}`) ?? undefined
    }
  } else {
    if (filter.type === Number || filter.type === 'currency') {
      initValue = searchParams.get(filter.column)
        ? parseInt(searchParams.get(filter.column))
        : undefined
    } else {
      initValue = searchParams.get(filter.column) ?? undefined
      initMaxValue = undefined
    }
  }

  const [value, setValue] = useState(initValue)
  const [maxValue, setMaxValue] = useState(initMaxValue)

  const inputChangeTimeoutDuration: number = 1200 // in MS --> so we don't run requests as user is typing

  function onChange(e: any) {
    setValue(e.target.value)
    setTimeout(() => {
      if (e.target.value === '') {
        searchParams.delete(e.target.name)
        setSearchParams(searchParams)
      } else {
        setSearchParams({
          ...searchParamsObject,
          [e.target.name]: (e.target.value = typeof Number
            ? e.target.value.toString()
            : e.target.value),
          pageNumber: '1',
        })
      }
    }, inputChangeTimeoutDuration)
  }
  function onChangeMax(e: any) {
    setMaxValue(e.target.value)
    setTimeout(() => {
      if (e.target.value === '') {
        searchParams.delete(e.target.name)
        setSearchParams(searchParams)
      } else {
        setSearchParams({
          ...searchParamsObject,
          [e.target.name]: (e.target.value = typeof Number
            ? e.target.value.toString()
            : e.target.value),
        })
      }
    }, inputChangeTimeoutDuration)
  }

  let inputType: InputType | undefined
  switch (filter.type) {
    case Date:
      inputType = 'date'
      break
    case Number:
    case 'currency':
      inputType = 'number'
      break
    case String:
      inputType = 'text'
      break
    case 'dropdown':
    default:
      inputType = undefined
  }

  function clearSelection() {
    if (filter.compare) {
      searchParams.delete(`MAX_${filter.column}`)
      searchParams.delete(`MIN_${filter.column}`)
      setSearchParams(searchParams)

      setValue('')
      setMaxValue('')
    } else {
      searchParams.delete(filter.column)
      setSearchParams(searchParams)
      setValue('')
    }
  }

  if (filter.compare) {
    return (
      <div id="filter_inputs_container">
        <Input
          name={`MIN_${filter.column}`}
          label={inputType === 'date' ? 'Earliest' : 'Min'}
          type={inputType}
          onChange={onChange}
          value={value}
          autoFocus
          icon={filter.type === 'currency' ? '$' : null}
        />
        <Spacer y="compact" />
        <Input
          name={`MAX_${filter.column}`}
          label={inputType === 'date' ? 'Latest' : 'Max'}
          type={inputType}
          onChange={onChangeMax}
          value={maxValue}
          icon={filter.type === 'currency' ? '$' : null}
          min={
            (inputType === 'number' || inputType === 'date') &&
            (value !== undefined || value !== '' || value !== null)
              ? value
              : undefined
          }
        />
        <Spacer y="mini" />
        <Flexbox justifyContent="flex-end">
          <Text small>
            <Button
              onClick={() => clearSelection()}
              appearance="text"
              disabled={
                (value === '' || value === undefined) &&
                (maxValue === '' || maxValue === undefined)
              }
            >
              Clear
            </Button>
          </Text>
        </Flexbox>
      </div>
    )
  } else {
    return (
      <div id="filter_inputs_container">
        <Input
          name={filter.column}
          type={inputType}
          onChange={onChange}
          value={value}
          autoFocus
          icon={filter.type === 'currency' ? '$' : null}
        />
        <Spacer y="mini" />
        <Flexbox justifyContent="flex-end">
          <Text small>
            <Button
              onClick={() => clearSelection()}
              appearance="text"
              disabled={value === '' || value === undefined}
            >
              Clear
            </Button>
          </Text>
        </Flexbox>
      </div>
    )
  }
}
export default AssetsTableDefaultFilter
