import { Segment, SegmentBase, SegmentKey, segmentSchema } from '@api/models'
import useFields from '@hooks/useFields'
import { getInputPropsForModalForms } from '@utils/getInputPropsForModalForms'
import { validateAndSubmit } from '@utils/validateAndSubmit'
import { useState } from 'react'
import { FormSubmitEvent } from 'types'

interface SegmentFormProps {
  initialValues: SegmentBase
  handleSubmit: (payload: Segment) => Promise<void>
}

export const useSegmentForm = ({ initialValues, handleSubmit }: SegmentFormProps) => {
  const { fields } = useFields()
  const [form, setForm] = useState({
    values: initialValues,
    errors: {},
  })

  function getField(name: SegmentKey) {
    return getInputPropsForModalForms({
      name,
      fieldMap: fields,
      schema: segmentSchema,
      initialValues,
      form,
      setForm,
    })
  }

  async function handleFormSubmit(e: FormSubmitEvent): Promise<void> {
    e.preventDefault()
    await validateAndSubmit(segmentSchema, form, setForm, handleSubmit)
  }

  return {
    getField,
    handleFormSubmit,
  }
}
