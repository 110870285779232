import { useParams } from 'react-router-dom'
import DepreciationClassForm from '@components/depreciation-class/DepreciationClassForm'
import { DeprecClassHookProps } from '@components/depreciation-class/DepreciationClass.types'
import { DepreciationProfileCode } from '@api/models'
import View from '@components/View'

interface Props {
  profile: DepreciationProfileCode
}

const DepreciationClassDetailsView = ({ profile }: Props) => {
  const params = useParams()

  const formProps: DeprecClassHookProps = {
    context: 'class',
    params: {
      assetCompanyID: 0,
      id: params.id,
      profile,
    },
  }

  return (
    <View title={`${profile === 'A' ? 'Category' : 'Tax Class'} ${params.id}`}>
      <DepreciationClassForm context={formProps.context} params={formProps.params} />
    </View>
  )
}

export default DepreciationClassDetailsView
