import HttpClient from "./HttpClient";

export class UserApi extends HttpClient {
    private static classInstance?: UserApi;

    public constructor () {
        super (`/User`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new UserApi();
        }

        return this.classInstance;
    }

    public fetch = () => this.instance.get(`/FetchUsers`);
    public get = (id: string) => this.instance.get(`/GetUser?id=${id}`);
    public create = (data: any) => this.instance.post(`/Create`, data);
    public edit = (data: any) => this.instance.put(`/Edit`, data);
    public delete = (data: any) => this.instance.post(`/Delete`, data);
}