import * as Yup from 'yup'
import { ERROR_REQUEST, REQUIRED_FIELD } from '@constants'
import {
  currencyValidation,
  dateAfterOrEqualValidation,
  dateExistValidation,
} from '@api/models/validation'
import { getTodaysDate, maxStringMsg } from '@utils/basic'
import { AssetsApi } from '../Assets'
import { toast } from '@components/common'
import { Asset } from './Asset.model'
import { MaintainedDepProfiles } from '@api/companies/models'

export interface AssetRecalcDTO {
  ShouldRecalc: boolean
  Options: RecalcOption[]
  SelectedOption: string
  AsOfYear: number
  PostingDate: string
}
export interface RecalcOption {
  Label: string
  Value: string
  Disabled: boolean
}

export const assetRecalcSchema = Yup.object({
  SelectedOption: Yup.string().required(REQUIRED_FIELD),
  AsOfYear: Yup.number().required(REQUIRED_FIELD),
  PostingDate: Yup.string().required(REQUIRED_FIELD).test(dateExistValidation),
})

export type AllAssetRecalcFields = Yup.InferType<typeof assetRecalcSchema>
export type AssetRecalcFieldNames = keyof AllAssetRecalcFields

async function doesAssetExist(companyID: number, assetID: string): Promise<boolean> {
  return await AssetsApi.getInstance()
    .doesAssetExist(companyID, assetID)
    .then(response => response.data)
    .catch(() => toast.error(ERROR_REQUEST))
}

export const assetDetailsSchema = (
  assetCompanyID: number,
  maintainedDepProfiles: MaintainedDepProfiles
) =>
  Yup.object({
    AS_DESC: Yup.string().max(50, maxStringMsg(50)).required(REQUIRED_FIELD).default(''),
    AS_DESC_E: Yup.string().max(50, maxStringMsg(50)).nullable().default(''),
    AS_SERIAL: Yup.string().max(25, maxStringMsg(25)).nullable().default(''),
    AS_KEY0_ID: Yup.string().nullable().default(''),
    AS_KEY1_ID: Yup.string().nullable().default(''),
    AS_KEY2_ID: Yup.string().nullable().default(''),
    AS_KEY3_ID: Yup.string().nullable().default(''),
    AS_KEY4_ID: Yup.string().nullable().default(''),
    AS_KEY5_ID: Yup.string().nullable().default(''),
    AS_KEY6_ID: Yup.string().nullable().default(''),
    AS_KEY7_ID: Yup.string().nullable().default(''),
    AS_KEY8_ID: Yup.string().nullable().default(''),
    AS_KEY9_ID: Yup.string().nullable().default(''),
    AS_CAT_ID: Yup.string().required(REQUIRED_FIELD).default(''),
    AS_TCLS_ID: maintainedDepProfiles?.IsTaxMaintained
      ? Yup.string().required(REQUIRED_FIELD)
      : Yup.string().nullable(),
    AS_SCLS_ID: maintainedDepProfiles?.IsStateTaxMaintained
      ? Yup.string().required(REQUIRED_FIELD)
      : Yup.string().nullable(),
    AS_4CLS_ID: maintainedDepProfiles?.IsOtherTaxMaintained
      ? Yup.string().required(REQUIRED_FIELD)
      : Yup.string().nullable(),
    AS_PRI_UCD: Yup.string().max(25, maxStringMsg(25)).nullable().default(''),
    AS_SEC_UCD: Yup.string().max(25, maxStringMsg(25)).nullable().default(''),
    AS_TAXB_CD: Yup.string().nullable().default(''),
    AS_TAXL_CD: Yup.string().nullable().default(''),
    AS_TAX_PTP: Yup.string().max(20, maxStringMsg(20)).nullable().default(''),
    AS_TAX_BUS: Yup.number()
      .min(0, 'Cannot be a negative percentage')
      .max(100, 'Cannot exceed 100%')
      .required()
      .typeError(REQUIRED_FIELD)
      .default(100),
    AS_REC_DAT: Yup.string()
      .required(REQUIRED_FIELD)
      .default(getTodaysDate())
      .test(dateExistValidation),
    AS_VEN_ID: Yup.string().nullable().default(''),
    AS_VEN_NM: Yup.string().max(50, maxStringMsg(50)).nullable().default(''),
    AS_REC_PON: Yup.string().max(25, maxStringMsg(25)).nullable().default(''),
    AS_REC_USR: Yup.string().max(25, maxStringMsg(25)).nullable().default(''),
    AS_FIN_COS: Yup.number()
      .test(currencyValidation)
      .required()
      .typeError(REQUIRED_FIELD)
      .default(0),
    AS_FIN_SAL: Yup.number()
      .test(currencyValidation)
      .required()
      .typeError(REQUIRED_FIELD)
      .default(0),
    AS_IN_POL: Yup.string().max(12, maxStringMsg(12)).nullable().default(''),
    AS_REP_DAT: Yup.string().required(REQUIRED_FIELD).default('').test(dateExistValidation),
    AS_STAT_CD: Yup.string().nullable().default(''),
    AS_BOK4_CD: Yup.string().nullable().default(''),

    AS_WRO_CD: Yup.string().nullable().default(''),
    AS_PAR_ASS: Yup.string()
      .max(12, maxStringMsg(12))
      .test({
        test: async parentID => {
          if (parentID === '' || parentID === null) return true
          return await doesAssetExist(assetCompanyID, parentID)
        },
        message: 'Must be an existing Asset ID',
      })
      .nullable()
      .default(''),
    //? need this test?
    // .test({
    //   test: (parentID, testContext) => testContext.parent.AssetID !== parentID,
    //   message: 'Cannot match current Asset ID',
    // })
    AS_LEASE: Yup.string().max(15, maxStringMsg(15)).default(''), //TODO
    AS_LOCD_CD: Yup.string().nullable().default(''),
    AS_LAPC_ID: Yup.string().nullable().default(''),
    AS_LDPC_ID: Yup.string().nullable().default(''),
    AS_SERD_CD: Yup.string().nullable().default(''),
    AS_SAPC_ID: Yup.string().nullable().default(''),
    AS_SDPC_ID: Yup.string().nullable().default(''),
    AS_UC1_UCD: Yup.string().max(25, maxStringMsg(25)).nullable().default(''),
    AS_UC2_UCD: Yup.string().max(25, maxStringMsg(25)).nullable().default(''),
    AS_UC3_UCD: Yup.string().max(25, maxStringMsg(25)).nullable().default(''),
    AS_MAIN_CN: Yup.string().max(12, maxStringMsg(12)).nullable().default(''),
    AS_DIS_CD: Yup.string().nullable().default(''),
    tags: Yup.array().of(Yup.number().required()).required().default([]),
    AS_NSER_DT: Yup.string()
      .nullable()
      .test(dateAfterOrEqualValidation('AS_REC_DAT'))
      .test(dateExistValidation),

    // AS_MAIN_CN: Yup.string().max(12, maxStringMsg(12)),
    // AS_DEP_STC: Yup.string(),
    // AS_LEASEP: Yup.number(),
    // AS_SUB_DCR: Yup.boolean(),
    // AS_SUB_DTR: Yup.boolean(),
    // AS_SUB_DSR: Yup.boolean(),
    // AS_SUB_DOR: Yup.boolean(),
    // AS_ADD_DAT: Yup.string(),
    // AS_ADD_USR: Yup.string(),
    // AS_CHG_DAT: Yup.string(),
    AS_CHG_USR: Yup.string().nullable(),
    // AS_FIN_VAL: Yup.number().test(currencyValidation),
    // AS_FIN_CLC: Yup.number().test(currencyValidation),
    // AS_FIN_CSC: Yup.number().test(currencyValidation),
    // AS_FIN_COC: Yup.number().test(currencyValidation),
    // AS_FIN_CFC: Yup.number().test(currencyValidation),
    // AS_FIN_TAC: Yup.number().test(currencyValidation),
    // AS_DIS_DAT: Yup.string(),
    // AS_DIS_SAL: Yup.number().test(currencyValidation),
    // AS_DIS_NAM: Yup.string().max(25, maxStringMsg(25)),
    // AS_DIS_SYD: Yup.string(),
    // AS_DIS_USR: Yup.string(),
    // AS_WRO_DAT: Yup.string(),
  })

export type AssetDetailSchema = ReturnType<typeof assetDetailsSchema>
export type AllAssetDetailFields = Yup.InferType<AssetDetailSchema>
export type AssetDetailFields = Partial<AllAssetDetailFields>
export type AssetDetailFieldNames = keyof AllAssetDetailFields

export interface EditAssetPayload {
  asset: Asset
  assetRecalcDTO: AssetRecalcDTO
}
