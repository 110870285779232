import React, { useCallback, useEffect } from 'react'

import { Toast } from '../Toast/'
import { useToast } from '../useToast'
import { emitter, toastDispatcher } from '../utils'
import { Events } from '../Toasts.types'

import { usePortal } from '@hooks'
import { createPortal } from 'react-dom'
import { useStyletron } from 'styletron-react'
import { containerStyles } from './Styles'
import { AnimatePresence, motion } from 'framer-motion'

const ToastContainer: React.FC = () => {
  const { toasts, dispatch } = useToast()

  useEffect(() => {
    toastDispatcher({ dispatch })

    return () => {
      emitter.off()
    }
  }, [dispatch])

  const onClose = useCallback((id: string) => {
    emitter.emit(Events.HIDE, id)
  }, [])

  const [css] = useStyletron()

  var containerCSS = css(containerStyles)

  const portal = usePortal('toast-root')
  if (!portal) return null
  return createPortal(
    <div className={containerCSS}>
      <AnimatePresence initial={false}>
        {toasts.map(toast => (
          <motion.div
            key={toast.id}
            layout
            initial={{ opacity: 1, translateY: -75 }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
              transition: {
                type: 'spring',
                stiffness: 700,
                damping: 50,
                duration: 500,
              },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.1, delay: 0.04 },
            }}
          >
            <Toast {...toast} onClose={onClose} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>,
    portal
  )
}

export default ToastContainer
