import { addAttachmentSchema, UploadAttachmentPayload } from '@api/models'
import { Grid, Input, Modal, toast } from '@components/common'
import ImportInput from '@components/ImportData/ImportInput'
import useFormFieldProps from '@hooks/useFormField'
import { ADD_ATTACHMENT_FORM_ID } from '@constants'
import { useForm } from 'react-hook-form'
import { useUploadAttachmentMutation } from '@api'
import { useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'

interface AddAttachmentFormProps {
  objectID: string
  assetCompanyID: number
  modalIsOpen: boolean
  closeModal: () => void
}

const AddAttachmentForm = ({
  objectID,
  assetCompanyID,
  modalIsOpen,
  closeModal,
}: AddAttachmentFormProps) => {
  const defaultValues = useMemo<UploadAttachmentPayload>(
    () => ({
      file: null,
      assetCompanyID,
      objectIDs: [objectID],
      type: 'Asset',
      comment: '',
    }),
    [objectID, assetCompanyID]
  )

  const { data: isAssetDesc } = useGetAssetQuery({
    assetCompanyID,
    assetID: objectID,
  })
  const headerTitle = `Add Attachment - Asset ${objectID}: ${isAssetDesc.AS_DESC}`

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<UploadAttachmentPayload>({
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(addAttachmentSchema),
  })

  const [upload] = useUploadAttachmentMutation()
  const file = watch('file')
  const { getInputProps } = useFormFieldProps<UploadAttachmentPayload>({
    fields: {},
    register,
    errors: errors as any,
    isSubmitting,
  })

  function handleFileSelection(e: any) {
    setValue('file', e.target.files[0])
  }

  function handleFileRemoval() {
    setValue('file', null)
  }

  async function onSubmit(values: UploadAttachmentPayload) {
    try {
      const formData = new FormData()

      Object.keys(values).forEach(key => {
        if (key === 'objectIDs') {
          formData.append(key, JSON.stringify(values[key]))
          return
        }
        formData.append(key, values[key])
      })

      await upload(formData).unwrap()
      closeModal()
      toast.success('Upload successful')
    } catch (_) {
      toast.error('Upload failed')
    }
  }

  useEffect(() => {
    if (!modalIsOpen) reset({ ...defaultValues })
  }, [defaultValues, modalIsOpen, reset])

  return (
    <Modal
      title={headerTitle}
      isOpen={modalIsOpen}
      close={closeModal}
      form={ADD_ATTACHMENT_FORM_ID}
      actionLabel="Upload"
      action={handleSubmit(onSubmit)}
      actionDisabled={isSubmitting}
    >
      <Grid>
        <Grid.Item>
          <Input {...getInputProps('comment')} label="Description" />
        </Grid.Item>
        <Grid.Item>
          <ImportInput
            file={file}
            onChange={handleFileSelection}
            onRemove={handleFileRemoval}
            uploadDesc="Click here to upload your attachment."
            acceptedFormatsDesc="Accepted formats: .pdf, .doc, .docx, .xls, .xlsx, .csv, .zip, .png, .jpg, .jpge, .jiff"
          />
        </Grid.Item>
      </Grid>
    </Modal>
  )
}

export default AddAttachmentForm
