import {
  ButtonSkeleton,
  Card,
  Grid,
  Spacer,
  TextSkeleton,
} from '@components/common'

const AccountSettingsLoading: React.FC = () => {
  return (
    <>
      <Grid>
        <Grid.Item m={6}>
          <Card>
            <TextSkeleton height="var(--scale1600)" />
            <Spacer y="mini" />
            <ButtonSkeleton width="260px" />
            <Spacer y="mini" />
            <ButtonSkeleton width="260px" />
            <Spacer y="mini" />
            <ButtonSkeleton width="260px" />
            <Spacer y="mini" />
            <ButtonSkeleton width="var(--scale1800)" />
          </Card>
        </Grid.Item>
      </Grid>
    </>
  )
}

export default AccountSettingsLoading
