import { Scale, Palette } from '@styles-api/css.types'
import { StyleObject } from '@styles-api/css.types'

export const wrapperStyles: StyleObject = {
  position: 'static',
  marginTop: 'var(--scale200)',
  marginBottom: 'var(--scale200)',
  display: 'flex',
}
export const getSpinnerStyles = ({ size, theme }) => {
  // get height & width dimensions depending on size
  let height: Scale
  switch (size) {
    case 'mini':
      height = 'var(--scale500)'
      break
    case 'compact':
      height = 'var(--scale550)'
      break
    case 'regular':
      height = 'var(--scale650)'
      break
    case 'large':
      height = 'var(--scale800)'
      break
  }
  // get colors depending on theme
  let bgColor: Palette
  let spinnerColor: Palette
  if (theme === 'light') {
    bgColor = 'var(--shade400)'
    spinnerColor = 'var(--primary-color)'
  } else if (theme === 'dark') {
    bgColor = 'var(--shade400)'
    spinnerColor = 'var(--interaction-contrast)'
  }

  return {
    height,
    width: height,
    borderBottomColor: bgColor,
    borderLeftColor: bgColor,
    borderRightColor: bgColor,
    borderTopColor: spinnerColor,
    borderStyle: 'solid',
    borderWidth: '2px',
    display: 'inline-block',
    borderRadius: '50%',
  }
}
export const animationStyles: StyleObject = {
  animationName: {
    from: {
      transform: 'rotate(-360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  animationDuration: '700ms',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
}
