import HttpClient from '../HttpClient'

export class SegmentsApi extends HttpClient {
  private static classInstance?: SegmentsApi

  public constructor() {
    super(`/Segment`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new SegmentsApi()
    }

    return this.classInstance
  }

  public fetch = (
    key: number,
    filter: string,
    pageNumber: number,
    itemsPerPage: number
  ) =>
    this.instance.get(
      `/FetchSegments?key=${key}&filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`
    )
  public list = () => this.instance.get(`/ListSegments`)
  public get = (kynr: number, kyvalue: string) =>
    this.instance.get(`/GetSegment?KYNR=${kynr}&KYVALUE=${kyvalue}`)
  public getByKey = (kynr: number) => this.instance.get(`/GetByKey?KYNR=${kynr}`)
  public getSegments = (kY_NR: number, desc: string) =>
    this.instance.get(`/GetSegments?KY_NR=${kY_NR}&desc=${desc}`)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (data: any) => this.instance.post(`/Delete`, data)
  public getSegmentTypes = () => this.instance.get(`/GetSegmentTypes`)
  public getSegmentUsageTypes = () => this.instance.get(`/GetSegmentUsageTypes`)
  public changeStructureName = (key: number, name: string) =>
    this.instance.post(`/ChangeStructureName?key=${key}&name=${name}`)
  public changeStructureToDefault = (key: number) =>
    this.instance.post(`/ChangeStructureToDefault?key=${key}`)
  public importSegment = (file: any) => this.instance.post(`/ImportSegment`, file)
}
