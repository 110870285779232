import { AttachmentType } from '@api/models'
import {
  Button,
  Card,
  Flexbox,
  Grid,
  Icon,
  Select,
  Spacer,
  Spinner,
  useModal,
} from '@components/common'
import { download } from '@utils/basic'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import AddAttachmentForm from '../AddAttachmentForm'
import EditAttachmentForm from '../EditAttachmentForm'
import NoAttachments from '../NoAttachments'
import useAttachmentHook from './useAttachmentHook'
import usePermission from '@components/Auth/Permissions/usePermission'

const defaultFileImage = require('../../../assets/images/file.png')

interface AttachmentEditorProps {
  type: AttachmentType
}

const AttachmentEditor = ({ type }: AttachmentEditorProps) => {
  const {
    isLoading,
    selectOptions,
    selectedOption,
    attachment,
    assetCompanyID,
    objectID,
    isDeletingAttachment,
    deleteAttachment,
    handleAttachmentSelection,
  } = useAttachmentHook({ type })

  const {
    modalIsOpen: addModalIsOpen,
    triggerModal: triggerAddModal,
    closeModal: closeAddModal,
  } = useModal()

  const {
    modalIsOpen: editModalIsOpen,
    triggerModal: triggerEditModal,
    closeModal: closeEditModal,
  } = useModal()

  const { canEditAssets: canEdit } = usePermission()

  const { data: isAssetDesc, isLoading: isAssetDetailLoading } = useGetAssetQuery({
    assetCompanyID: assetCompanyID,
    assetID: objectID,
  })

  const EditingEnabled =
    isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false

  if (isLoading || isAssetDetailLoading) return <>Loading...</>

  return (
    <>
      <AddAttachmentForm
        objectID={objectID}
        assetCompanyID={assetCompanyID}
        modalIsOpen={addModalIsOpen}
        closeModal={closeAddModal}
      />
      {attachment === null ? (
        <NoAttachments
          type={type}
          openAddAttachmentForm={triggerAddModal}
          canEdit={canEdit}
        />
      ) : (
        <>
          {!attachment.isLoading && (
            <EditAttachmentForm
              modalIsOpen={editModalIsOpen}
              closeModal={closeEditModal}
              defaultValues={attachment.details}
            />
          )}
          <Flexbox justifyContent="space-between" alignItems="center">
            <Flexbox alignItems="center">
              <Grid>
                <Grid.Item>
                  <Select
                    label="Associated attachments"
                    options={selectOptions}
                    value={selectedOption}
                    onChange={handleAttachmentSelection}
                  />
                </Grid.Item>
              </Grid>
            </Flexbox>
            {canEdit && (
              <Flexbox>
                <Button
                  appearance="primary"
                  disabled={EditingEnabled}
                  onClick={triggerAddModal}
                >
                  Add attachment
                </Button>
              </Flexbox>
            )}
          </Flexbox>
          <Spacer y="default" />
          <Card>
            <Flexbox alignItems="center" justifyContent="center">
              {attachment.isLoading && (
                <Flexbox justifyContent="center" alignItems="center" height={240}>
                  <Spinner />
                </Flexbox>
              )}
              {!attachment.isLoading && (
                <img
                  style={{
                    height: 'auto',
                    maxHeight: '1000px',
                    width: 'auto',
                    maxWidth: '500px',
                  }}
                  src={attachment.url}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = defaultFileImage
                  }}
                  draggable={false}
                />
              )}
            </Flexbox>
          </Card>
          <Spacer y="default" />
          {!attachment.isLoading && (
            <Flexbox justifyContent="flex-end">
              <Button
                icon={<Icon name="Download" />}
                onClick={() => download(attachment.url, attachment.details.FileName)}
              >
                Download
              </Button>
              {canEdit && (
                <>
                  <Spacer x="default" />
                  <Button
                    icon={<Icon name="Edit" />}
                    disabled={EditingEnabled}
                    onClick={triggerEditModal}
                  >
                    Edit
                  </Button>
                  <Spacer x="default" />
                  <Button
                    intent="danger"
                    icon={<Icon name="Trash2" />}
                    disabled={EditingEnabled}
                    onClick={() => deleteAttachment({ type, id: attachment.details.ID })}
                    loading={isDeletingAttachment}
                  >
                    Delete
                  </Button>
                </>
              )}
            </Flexbox>
          )}
        </>
      )}
    </>
  )
}

export default AttachmentEditor
