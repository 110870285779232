// export all constants here.
// to use constants in components, import { VALUE, ANOTHER_VALUE } from '@constants'

export * from './motion'
export * from './feedback'
export * from './breakpoints'
export * from './icons'
export * from './forms'
export * from './filters'
export * from './views'
export * from './paths'

// Insert misc constants here
export const APP_TITLE = 'WorthIT Fixed Assets'
export const APP_TITLE_DIVIDER = '-'

//

export const PAGINATION_NEXT_BUTTON_ID = 'pag_next_button'
export const PAGINATION_PREV_BUTTON_ID = 'pag_prev_button'
