import { Form, Modal } from '@components/common'
import { ASSET_SPECIFIC_DEPRECIATION_FORM_ID } from '@constants'
import { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import { FormSubmitEvent } from 'types'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  handleSubmit: (e: FormSubmitEvent) => Promise<void>
  isAssetSpecific: boolean
  isSubmitting: boolean
}

const AssetSpecificDepreciationModal = ({
  isOpen,
  setIsOpen,
  handleSubmit,
  isAssetSpecific,
  isSubmitting,
}: Props) => {
  const params = useParams()

  const getTitle = (isAssetSpecific: boolean) => {
    if (isAssetSpecific) return `Undo asset-specific depreciation`

    return `Create asset-specific depreciation`
  }

  const getContent = (isAssetSpecific: boolean) => {
    if (isAssetSpecific)
      return (
        <>
          Are you sure you want to undo asset-specific depreciation for asset{' '}
          {params.assetID}?
        </>
      )

    return (
      <>
        Are you sure you want to create asset-specific depreciation for asset{' '}
        {params.assetID}?
      </>
    )
  }

  const getActionLabel = (isAssetSpecific: boolean) => {
    if (isAssetSpecific) return `Undo asset-specific depreciation`

    return `Create asset-specific depreciation`
  }

  return (
    <Modal
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      form={ASSET_SPECIFIC_DEPRECIATION_FORM_ID}
      isActionLoading={isSubmitting}
      intent={isAssetSpecific ? 'danger' : 'default'}
      title={getTitle(isAssetSpecific)}
      actionLabel={getActionLabel(isAssetSpecific)}
    >
      <Form onSubmit={handleSubmit} id={ASSET_SPECIFIC_DEPRECIATION_FORM_ID}>
        {getContent(isAssetSpecific)}
      </Form>
    </Modal>
  )
}

export default AssetSpecificDepreciationModal
