import { AssetLocation, assetLocationSchema, FieldLabels } from '@api/models'
import { useEditLocationMutation, useGetAssetLocationQuery } from '@api'
import { ERROR_REQUEST } from '@constants'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import useFields from './useFields'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from '@components/common'

interface Props {
  assetCompanyID: number
  assetID: string
  systemNumber: number
}

interface IAssetLocationDetailsHook {
  location: AssetLocation
  isLoading: boolean
  hasLocation: boolean
  fields: FieldLabels

  formMethods: UseFormReturn<AssetLocation>
  handleLocationFormSubmit: (payload: AssetLocation) => Promise<void>
  isLoadingFormSubmit: boolean

  isLocationDetailsFormActive: boolean
  setIsLocationDetailsFormActive: Dispatch<SetStateAction<boolean>>

  dirtyFields: any
  isDirty: boolean
  isFormValid: boolean

  isShowingCoords: boolean
  setIsShowingCoords: Dispatch<SetStateAction<boolean>>
}

export default function useAssetLocationDetails({
  assetCompanyID,
  assetID,
  systemNumber,
}: Props): IAssetLocationDetailsHook {
  const defaultValues = assetLocationSchema.cast({
    AssetCompanyID: assetCompanyID,
    AssetID: assetID,
    ChargeSystemNumber: systemNumber,
  })

  const { fields, isLoading: isLoadingFields } = useFields()
  const [isLocationDetailsFormActive, setIsLocationDetailsFormActive] = useState(false)
  const [isShowingCoords, setIsShowingCoords] = useState(false)

  // React hook form
  const formMethods = useForm<AssetLocation>({
    resolver: yupResolver(assetLocationSchema),
    mode: 'all',
    defaultValues,
  })

  const isLatitudeZero = formMethods.getValues('Latitude') === 0
  const isLongitudeZero = formMethods.getValues('Longitude') === 0

  //get location
  const { data: location, isLoading: isLoadingLocations } = useGetAssetLocationQuery({
    systemNumber,
  })

  useEffect(() => {
    if (!isLoadingLocations)
      formMethods.reset({
        ...defaultValues,
        ...location,
        AssetCompanyID: assetCompanyID,
        AssetID: assetID,
        ChargeSystemNumber: systemNumber,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isLoadingLocations, assetCompanyID, assetID, systemNumber])

  // Form submit
  const [editLocation, { isLoading: isLoadingEditLocation }] = useEditLocationMutation()

  async function handleLocationFormSubmit(payload: AssetLocation): Promise<void> {
    try {
      await editLocation(payload).unwrap()
      toast.success(`Successfully saved location details record.`)
      setIsLocationDetailsFormActive(false)
    } catch (error) {
      toast.error(ERROR_REQUEST)
    }
  }

  const isLoadingFormSubmit: boolean = isLoadingEditLocation

  // Check before rendering
  const isLoading: boolean = isLoadingLocations || isLoadingFields

  const hasLocation: boolean =
    location != null && (location.Latitude !== 0 || location.Longitude !== 0)

  return {
    location,
    hasLocation,
    isLoading,
    fields,

    formMethods,
    handleLocationFormSubmit,
    isLoadingFormSubmit,

    isLocationDetailsFormActive,
    setIsLocationDetailsFormActive,

    dirtyFields: formMethods.formState.dirtyFields,
    isDirty: formMethods.formState.isDirty,
    isFormValid: formMethods.formState.isValid && !isLatitudeZero && !isLongitudeZero,

    isShowingCoords,
    setIsShowingCoords,
  }
}
