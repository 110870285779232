import { Card, Flexbox, Heading, Text } from '@components/common'
import { useParams } from 'react-router-dom'

const DepreciationProfileNotMaintained = ({ profileLabel }) => {
  const params = useParams()

  return (
    <Card>
      <Flexbox justifyContent="center" alignItems="center" height={180}>
        <div style={{ textAlign: 'center' }}>
          <Heading type="h4">Profile not maintained</Heading>
          <Text light p>
            Company {params.companyID} does not maintain {profileLabel.toLowerCase()}{' '}
            depreciation.
          </Text>
        </div>
      </Flexbox>
    </Card>
  )
}

export default DepreciationProfileNotMaintained
