import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const DEPRECIATION_TAGS: any = [
  'assetHistory',
  'disposal',
  'Asset',
  'depreciationRun',
  'depreciationStatus',
]

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URI,
  prepareHeaders: headers => {
    const token = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`
      )
    )?.access_token

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    } else {
      alert('Please login again.')
    }

    return headers
  },
})

// CONTRUCT ENDPOINTS AND API CALL HOOKS
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [
    'Asset',
    'assetCharge',
    'assetDepreciation',
    'assetHistory',
    'assetLocation',
    'assetStructures',
    'assetTags',
    'attachment',
    'comment',
    'company',
    'customDepreciation',
    'dashboard',
    'depreciation',
    'depreciationRun',
    'depreciationStatus',
    'disposal',
    'fieldParms',
    'folder',
    'glPost',
    'identifiers',
    'insurance',
    'maintenance',
    'periods',
    'reportTemplate',
    'segment',
    'setting',
    'task',
    'userTableParams',
    'vendor',
    'years',
  ],
  endpoints: () => ({}),
})
