import { UserRoleGrant } from '@components/Auth/UserRole'
import { SignoutRedirectArgs } from 'oidc-client-ts'
import { useAuth as useOidcAuth } from 'oidc-react'
import { useState } from 'react'

interface AuthHook {
  shouldProtectApp: boolean
  isAuthenticated: boolean
  isLoading: boolean
  roles: UserRoleGrant
  signOut: () => Promise<void>
  signOutRedirect: (args?: SignoutRedirectArgs) => Promise<void>
  isLoadingSignOut: boolean
  userName: string
}

/**
 * Extends oidc-react's useAuth hook.
 * IMPORTANT --> Can only be used within the AuthProvider component.
 */
const useAuth = (): AuthHook => {
  const { userData, isLoading, signOut: signOutOidc, signOutRedirect } = useOidcAuth()

  const [isLoadingSignOut, setIsLoadingSignOut] = useState(false)

  const shouldProtectApp = !userData

  let roles = userData?.profile?.role
  if (typeof roles === 'string') {
    roles = [roles]
  }

  const userName = userData?.profile.first_name + ' ' + userData?.profile.last_name


  function deleteAllCookies() {
    var cookies = document.cookie.split(';')

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i]
      var eqPos = cookie.indexOf('=')
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
  }

  const signOut = async () => {
    setIsLoadingSignOut(true)
    await signOutRedirect()
    await signOutOidc()
    setIsLoadingSignOut(true)

    localStorage.clear()
    sessionStorage.clear()
    deleteAllCookies()
  }

  return {
    shouldProtectApp,
    isAuthenticated: !!userData,
    roles: (roles || []) as UserRoleGrant,
    isLoading,
    signOut,
    signOutRedirect,
    isLoadingSignOut,
    userName,
  }
}

export default useAuth
