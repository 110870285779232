import { StyleObject } from '@styles-api/css.types'

export const layoutWrapper = (hasSidebar: boolean): StyleObject => {
  return {
    display: hasSidebar ? 'flex' : 'block',
  }
}

export const layoutSidebar: StyleObject = {
  minWidth: 'var(--local-side-nav-width)',
  marginRight: 'var(--gap-large)',
}

export const layoutMain: StyleObject = {
  flexGrow: 1,
}
