import HttpClient from '../HttpClient'
import { AssetDisposalFields, AssetTransfer } from '@api/assets/models'

export class AssetHistoryApi extends HttpClient {
  private static classInstance?: AssetHistoryApi

  public constructor() {
    super(`/AssetHistory`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new AssetHistoryApi()
    }

    return this.classInstance
  }

  public getHistoryProfiles = (assetCompanyID: number) =>
    this.instance.get(`/GetHistoryProfiles?assetCompanyID=${assetCompanyID}`)
  public getDepreciationHistory = (
    assetCompanyId: number,
    assetId: string,
    profile: string
  ) =>
    this.instance.get(
      `/GetDepreciationHistory?assetCompanyId=${assetCompanyId}&assetId=${assetId}&profile=${profile}`
    )

  public getAssetStructures = (assetCompanyId: number, assetId: string) =>
    this.instance.get(
      `/GetAssetStructures?assetCompanyId=${assetCompanyId}&assetId=${assetId}`
    )
  public getAssetStructure = (key: number, desc: string) =>
    this.instance.get(`/GetAssetStructure?key=${key}&desc=${desc}`)

  public validateTransfer = (
    assetCompanyId: number,
    assetId: string,
    m_asOf: string,
    year: number,
    period: number
  ) =>
    this.instance.post(
      `/ValidateTransfer?assetCompanyId=${assetCompanyId}&assetId=${assetId}&m_asOf=${m_asOf}&year=${year}&period=${period}`
    )
  public transfer = (transferObj: AssetTransfer) => {
    let url = `/Transfer?assetCompanyId=${transferObj.assetCompanyId}&assetId=${transferObj.assetId}&m_AsOf=${transferObj.m_asOf}&year=${transferObj.year}&period=${transferObj.period}`

    for (let i = 0; i < 10; i++) {
      let keyName = `key${i}`
      if (transferObj[keyName]) url += `&${keyName}=${transferObj[keyName]}`
    }

    return this.instance.post(url)
  }

  public getDisposalData = (assetCompanyID: number, assetID: string) =>
    this.instance.get(`/DisposalData?assetCompanyID=${assetCompanyID}&assetID=${assetID}`)
  public getDisposalProjections = (
    assetCompanyID: number,
    assetID: string,
    disposalDate?: string,
    period?: number,
    year?: number,
    realizedAmount?: number,
    disposalSoldTo?: string
  ) => {
    const periodQuery = period === undefined ? '' : `&period=${period}`
    const yearQuery = year === undefined ? '' : `&year=${year}`
    const realizedAmountQuery =
      realizedAmount === undefined ? '' : `&realizedAmount=${realizedAmount}`
    const disposalSoldToQuery =
      disposalSoldTo === undefined ? '' : `&disposalSoldTo=${disposalSoldTo}`
    const disposalDateQuery =
      disposalDate === undefined ? '' : `&disposalDate=${disposalDate}`
    return this.instance.get(
      `/DisposalProjections?assetCompanyId=${assetCompanyID}&assetId=${assetID}${periodQuery}${yearQuery}${realizedAmountQuery}${disposalSoldToQuery}${disposalDateQuery}`
    )
  }
  public disposal = (assetDisposalParams: AssetDisposalFields) =>
    this.instance.post(`/Disposal`, assetDisposalParams)

  public reinstate = (assetCompanyID: number, assetID: string) =>
    this.instance.post(`/Reinstate/${assetCompanyID}/${assetID}`)

  public isPendingDepreciation = (assetCompanyId: number) =>
    this.instance.get(`/IsPendingDepreciation?assetCompanyId=${assetCompanyId}`)

  public recalc = () => this.instance.get(`/Recalc`)
  public recalcPost = (options: string) => this.instance.post(`/Recalc?options=${options}`)

  public validateDepRun = input =>
    this.instance.get(`/ValidateDepRun?input=${JSON.stringify(input)}`)
  public getDepRunInfo = (assetCompanyID: number) =>
    this.instance.get(`/DepRunInfo/${assetCompanyID}`)
  public depRun = input => this.instance.post('/DepRun', input)

  public getDepUpdateInfo = (assetCompanyID: number) =>
    this.instance.get(`/DepUpdateInfo/${assetCompanyID}`)
  public depUpdate = (assetCompanyID: number) =>
    this.instance.post(`/DepUpdate/${assetCompanyID}`)

  public validateDepUndo = input =>
    this.instance.get(`/ValidateDepUndo?input=${JSON.stringify(input)}`)
  public getDepUndoInfo = (assetCompanyID: number) =>
    this.instance.get(`/DepUndoInfo/${assetCompanyID}`)
  public depUndo = input => this.instance.post('/DepUndo', input)

  public depDiscardRun = (assetCompanyID: number) =>
    this.instance.post(`/DepDiscardRun/${assetCompanyID}`)

  public validateYrEndRun = input =>
    this.instance.get(`/ValidateYrEndRun?input=${JSON.stringify(input)}`)
  public getYrEndRunInfo = (assetCompanyID: number) =>
    this.instance.get(`/YrEndRunInfo/${assetCompanyID}`)
  public yrEndRun = input => this.instance.post('/YrEndRun', input)

  public getYrEndUpdateInfo = (assetCompanyID: number) =>
    this.instance.get(`/YrEndUpdateInfo/${assetCompanyID}`)
  public yrEndUpdate = (assetCompanyID: number) =>
    this.instance.post(`/YrEndUpdate/${assetCompanyID}`)

  public validateYrEndUndo = input =>
    this.instance.get(`/ValidateYrEndUndo?input=${JSON.stringify(input)}`)
  public getYrEndUndoInfo = (assetCompanyID: number) =>
    this.instance.get(`/YrEndUndoInfo/${assetCompanyID}`)
  public yrEndUndo = input => this.instance.post('/YrEndUndo', input)

  public yrEndDiscardRun = (assetCompanyID: number) =>
    this.instance.post(`/YrEndDiscardRun/${assetCompanyID}`)
}
