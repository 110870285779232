import {
  Grid,
  Modal,
  Text,
  Fieldset,
  Flexbox,
  Input,
  Spacer,
  Spinner,
  toast,
  CustomDatePicker,
  Note,
  Icon,
} from '@components/common'
import { Controller, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { yearEndUndoSchema, YearEndUndoInput } from '@api/models'
import { yupResolver } from '@hookform/resolvers/yup'
import useYearEndUndoHook from './useYearEndUndoHook'
import DepreciationCard from './DepreciationCard'

interface YearEndUndoFormProps {
  assetCompanyID: number
}

const YearEndUndoForm = ({ assetCompanyID }: YearEndUndoFormProps) => {
  const {
    isSuccessYearEndUndo,
    isLoadingGetYearEndUndoInfo,
    isYearEndUndoFormOpen,
    isYearEndUndoing,
    isSuccessGetYearEndUndoInfo,
    isErrorGetYearEndUndoInfo,
    isErrorYearEndUndo,
    yearEndUndoInfo,
    fields,
    errorGetYearEndUndoInfo,
    openYearEndUndoForm,
    closeYearEndUndoForm,
    undoYearEndDepreciation,
  } = useYearEndUndoHook(assetCompanyID)

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<YearEndUndoInput>({
    mode: 'onSubmit',
    resolver: yupResolver(yearEndUndoSchema),
  })

  useEffect(() => {
    if (isSuccessGetYearEndUndoInfo) {
      reset(yearEndUndoInfo)
    }

    if (isErrorGetYearEndUndoInfo) {
      toast.error(errorGetYearEndUndoInfo)
    }

    if (isErrorYearEndUndo) {
      toast.error('Year end undo failed')
    }
  }, [
    isSuccessGetYearEndUndoInfo,
    isSuccessYearEndUndo,
    isErrorGetYearEndUndoInfo,
    isErrorYearEndUndo,
    reset,
    yearEndUndoInfo,
    errorGetYearEndUndoInfo
  ])

  function getInputProps(name: keyof YearEndUndoInput) {
    return {
      ...register(name),
      error: errors[name] !== undefined,
      errorMsg: errors[name]?.message,
      disabled: isSubmitting,
    }
  }

  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      onBlur: field.onBlur,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  const isUndoDisabled = assetCompanyID === undefined || assetCompanyID === null
  const formErrorMsg = errors['']?.message || '' // TODO: have the backend return an error code instead
  const shouldShowYearEndUndoForm = isSuccessGetYearEndUndoInfo && isYearEndUndoFormOpen

  function closeYearEndUndoInfoForm() {
    reset(yearEndUndoInfo)
    closeYearEndUndoForm()
  }
  return (
    <>
      <Modal
        isOpen={isYearEndUndoing}
        close={() => {}}
        title="Undoing Year End Depreciation"
      >
        <Flexbox justifyContent="center">
          <Spinner size="large" />
        </Flexbox>
      </Modal>

      <Modal
        isOpen={shouldShowYearEndUndoForm}
        close={closeYearEndUndoInfoForm}
        onSubmit={handleSubmit(undoYearEndDepreciation)}
        title="Year End Depreciation Undo"
        actionLabel="Undo"
      >
        {shouldShowYearEndUndoForm && (
          <Grid>
            {formErrorMsg && errors['PostDate'] === undefined && (
              <Grid.Item>
                <Note intent="danger" content={<>{formErrorMsg}</>} />
              </Grid.Item>
            )}
            <Grid.Item>
              <Fieldset legend="Status" legendCenter>
                <Flexbox>
                  <Grid>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_COM_ID}
                        value={yearEndUndoInfo.AssetCompanyID}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_DESCS}
                        value={yearEndUndoInfo.ShortName}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label="Last Year Closed"
                        value={yearEndUndoInfo.LastYearClosed}
                        readOnly
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Parameters" legendCenter>
                <Flexbox>
                  <Grid>
                    <Grid.Item m={6}>
                      <Input {...getInputProps('YearToReopen')} label="Year To Reopen" />
                    </Grid.Item>
                    <Grid.Item m={6}>
                      <Controller
                        control={control}
                        name="PostDate"
                        render={({ field }) => (
                          <CustomDatePicker
                            {...getDateProps(field)}
                            label="Posting Date"
                            error={errors['PostDate'] !== undefined}
                            errorMsg={errors['PostDate']?.message}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Remarks" legendCenter>
                <Grid>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>1.</Text>
                      <Spacer x="compact" />
                      <Input {...getInputProps('Remark1')} />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>2.</Text>
                      <Spacer x="compact" />
                      <Input {...getInputProps('Remark2')} />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>3.</Text>
                      <Spacer x="compact" />
                      <Input {...getInputProps('Remark3')} />
                    </Flexbox>
                  </Grid.Item>
                </Grid>
              </Fieldset>
            </Grid.Item>
          </Grid>
        )}
      </Modal>

      <DepreciationCard
        disabled={isUndoDisabled}
        loading={isLoadingGetYearEndUndoInfo}
        openForm={openYearEndUndoForm}
        helpText="Permanently remove year-end depreciation results from the history."
        icon={<Icon name="RotateCcw" color="var(--primary-color500)" />}
        type={'Undo'}
      />
    </>
  )
}

export default YearEndUndoForm
