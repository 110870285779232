import {
  FetchMaintenancesResponse,
  initialMaintenanceContract,
  MaintenanceContract,
  MaintenanceContractKey,
} from '@api/models'
import { toast, useModal } from '@components/common'
import {
  useCreateMaintenanceMutation,
  useDeleteMaintenanceMutation,
  useEditMaintenanceMutation,
  useFetchMaintenancesQuery,
} from '@api'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTableParams } from '@hooks'
import { TableParamsHook } from 'hooks/useTableParams'

export interface MaintenancesHook extends TableParamsHook {
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  searchKey: string
  selectedMaintenance: MaintenanceContract

  maintenances: FetchMaintenancesResponse
  isLoadingMaintenances: boolean
  isErrorFetchingMaintenances: boolean
  isRefetchingMaintenances: boolean

  handleEditMaintenance: (payload: MaintenanceContract) => Promise<void>
  handleCreateMaintenance: (payload: MaintenanceContract) => Promise<void>
  isLoadingEditMaintenance: boolean
  isLoadingCreateMaintenance: boolean

  handleDeleteMaintenance: (payload: MaintenanceContract) => Promise<void>
  isLoadingDeleteMaintenance: boolean

  deleteMaintenanceModalIsOpen: boolean
  triggerDeleteMaintenanceModal
  closeDeleteMaintenanceModal
  openDeleteMaintenanceModal: (maintenance: MaintenanceContract) => void
  openEditMaintenanceModal: (maintenance: MaintenanceContract) => void
  triggerEditMaintenanceModal
  closeEditMaintenanceModal
  editMaintenanceModalIsOpen: boolean

  openCreateMaintenanceModal: () => void
  createMaintenanceModalIsOpen: boolean
  closeCreateMaintenanceModal
}

export default function useMaintenances(): MaintenancesHook {
  const [filterState, setFilterState] = useState({})
  const [selectedMaintenance, setSelectedMaintenance] = useState<MaintenanceContract>(
    undefined
  )

  const searchKey: MaintenanceContractKey = 'MN_DESC'
  const initialItemsPerPage = 20

  // Use useTableParams hook for table filtering, pagination, etc.
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  // Fetch Maintenances
  const {
    data: maintenances,
    isLoading: isLoadingMaintenances,
    isError: isErrorFetchingMaintenances,
    isFetching: isRefetchingMaintenances,
  } = useFetchMaintenancesQuery(fetchRequestPayload)

  // MODAL STATES
  // Delete modal
  const {
    modalIsOpen: deleteMaintenanceModalIsOpen,
    triggerModal: triggerDeleteMaintenanceModal,
    closeModal: closeDeleteMaintenanceModal,
  } = useModal()
  function openDeleteMaintenanceModal(maintenance: MaintenanceContract): void {
    setSelectedMaintenance(maintenance)
    triggerDeleteMaintenanceModal()
  }

  // Edit modal
  const {
    modalIsOpen: editMaintenanceModalIsOpen,
    triggerModal: triggerEditMaintenanceModal,
    closeModal: closeEditMaintenanceModal,
  } = useModal()

  function openEditMaintenanceModal(maintenance: MaintenanceContract): void {
    setSelectedMaintenance(maintenance)
    triggerEditMaintenanceModal()
  }

  // Create modal
  const {
    modalIsOpen: createMaintenanceModalIsOpen,
    triggerModal: triggerCreateMaintenanceModal,
    closeModal: closeCreateMaintenanceModal,
  } = useModal()
  function openCreateMaintenanceModal(): void {
    setSelectedMaintenance(initialMaintenanceContract)
    triggerCreateMaintenanceModal()
  }

  // MUTATIONS
  // Delete
  const [
    deleteMaintenance,
    { isLoading: isLoadingDeleteMaintenance },
  ] = useDeleteMaintenanceMutation()
  async function handleDeleteMaintenance(payload: MaintenanceContract): Promise<void> {
    try {
      await deleteMaintenance(payload).unwrap()
      closeDeleteMaintenanceModal()
      toast.success(
        `Successfully deleted "${payload.MN_NAME}" from your maintenance contracts.`
      )
    } catch (error) {
      toast.error('There was an unexpected error.')
    }
  }

  // Edit
  const [
    editMaintenance,
    { isLoading: isLoadingEditMaintenance },
  ] = useEditMaintenanceMutation()
  async function handleEditMaintenance(payload: MaintenanceContract): Promise<void> {
    try {
      await editMaintenance(payload).unwrap()
      closeEditMaintenanceModal()
      toast.success(`Maintenance contract "${payload.MN_DESC}" successfully updated.`)
    } catch (error) {
      toast.error('There was an unexpected error updating this maintenance contract.')
    }
  }

  // Create
  const [
    createMaintenance,
    { isLoading: isLoadingCreateMaintenance },
  ] = useCreateMaintenanceMutation()
  async function handleCreateMaintenance(payload: MaintenanceContract): Promise<void> {
    try {
      await createMaintenance(payload).unwrap()

      closeCreateMaintenanceModal()
      toast.success(`Successfully added ${payload.MN_DESC} to maintenance contracts.`)
    } catch (error) {
      toast.error('There was an unexpected error creating the maintenance contract.')
    }
  }

  return {
    // tableParams return
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    //
    filterState,
    setFilterState,
    searchKey,
    selectedMaintenance,

    maintenances,
    isLoadingMaintenances,
    isErrorFetchingMaintenances,
    isRefetchingMaintenances,

    handleDeleteMaintenance,
    isLoadingDeleteMaintenance,

    handleEditMaintenance,
    handleCreateMaintenance,
    isLoadingCreateMaintenance,
    isLoadingEditMaintenance,

    deleteMaintenanceModalIsOpen,
    triggerDeleteMaintenanceModal,
    closeDeleteMaintenanceModal,
    openDeleteMaintenanceModal,
    openEditMaintenanceModal,
    triggerEditMaintenanceModal,
    closeEditMaintenanceModal,
    editMaintenanceModalIsOpen,

    createMaintenanceModalIsOpen,
    openCreateMaintenanceModal,
    closeCreateMaintenanceModal,
  }
}
