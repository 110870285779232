import { StyleObject } from '@styles-api/css.types'

export const icon_defaultStyles: StyleObject = {
  background: 'rgba(0,0,0,0)',
  padding: '0',
}

/* ------------------- SIZING/Spacing ------------------- */
export const icon_hasBgStyles: StyleObject = {
  borderRadius: 'var(--fullRadius)',
  overflow: 'visible',
}

export const icon_s_hasBgStyles: StyleObject = {
  padding: '3px',
  height: 'var(--sizeS)',
  width: 'var(--sizeS)',
}

export const icon_medium_hasBgStyles: StyleObject = {
  padding: '6px',
  height: 'var(--sizeM)',
  width: 'var(--sizeM)',
}
export const icon_large_hasBgStyles: StyleObject = {
  padding: '10px',
  height: 'var(--sizeL)',
  width: 'var(--sizeL)',
}
export const icon_xl_hasBgStyles: StyleObject = {
  padding: '12px',
  height: 'var(--sizeXL)',
  width: 'var(--sizeXL)',
}
export const icon_xxl_hasBgStyles: StyleObject = {
  padding: '14px',
  height: 'var(--sizeXXL)',
  width: 'var(--sizeXXL)',
}

/* ----------------- PRIMARY APPEARANCE ----------------- */
export const icon_primary_defaultStyles: StyleObject = {
  background: 'var(--primaryColor)',
  color: 'var(--primaryColorContrastText)',
}
export const icon_primary_successStyles: StyleObject = {
  background: 'var(--successColor)',
  color: 'var(--successColorContrastText)',
}
export const icon_primary_warningStyles: StyleObject = {
  background: 'var(--warningColor)',
  color: 'var(--warningColorContrastText)',
}
export const icon_primary_dangerStyles: StyleObject = {
  background: 'var(--dangerColor)',
  color: 'var(--dangerColorContrastText)',
}

/* ------------------ LIGHT APPEARANCE ------------------ */
export const icon_light_defaultStyles: StyleObject = {
  background: 'var(--primaryColorLight)',
  color: 'var(--primaryColor)',
}
export const icon_light_successStyles: StyleObject = {
  background: 'var(--successColorLight)',
  color: 'var(--successColor)',
}
export const icon_light_warningStyles: StyleObject = {
  background: 'var(--warningColorLight)',
  color: 'var(--warningColor)',
}
export const icon_light_dangerStyles: StyleObject = {
  background: 'var(--dangerColorLight)',
  color: 'var(--dangerColor)',
}

/* ------------------ MUTED APPEARANCE ------------------ */
export const icon_mutedStyles: StyleObject = {
  background: 'var(--shade200)',
}
export const icon_muted_successStyles: StyleObject = {
  color: 'var(--successColor)',
}
export const icon_muted_warningStyles: StyleObject = {
  color: 'var(--warningColor)',
}
export const icon_muted_dangerStyles: StyleObject = {
  color: 'var(--dangerColor)',
}

/* ------------------------ MISC ------------------------ */
export const icon_elevatedStyles: StyleObject = {
  boxShadow: 'var(--shadow1)',
}
export const icon_elevated_defaultStyles: StyleObject = {
  background: 'var(--elevatedSurfaceBackground1)',
}
