import {
  ButtonSkeleton,
  Card,
  Flexbox,
  InputSkeleton,
  Spacer,
  TableSkeleton,
  TextSkeleton,
} from '@components/common'

const AssetTableLoading: React.FC = () => {
  return (
    <>
      <Flexbox
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: 'var(--gap-default)' }}
      >
        <Flexbox alignItems="center">
          <TextSkeleton height="var(--scale1000)" width="var(--scale1800)" />
        </Flexbox>
        <Flexbox>
          <ButtonSkeleton placeholder="Create asset record" width="88px" />
        </Flexbox>
      </Flexbox>

      <Card spacing="none" overflow="hidden">
        <Flexbox justifyContent="space-between" style={{ padding: 'var(--scale500)' }}>
          <InputSkeleton width="640px" />

          <Flexbox>
            <ButtonSkeleton placeholder="Create asset record" width="var(--scale1600)" />
            <Spacer x="mini" />
            <ButtonSkeleton placeholder="Create asset record" width="105px" />
            <Spacer x="mini" />
            <ButtonSkeleton placeholder="Create asset record" width="165px" />
          </Flexbox>
        </Flexbox>
      </Card>

      <Card spacing="none" overflow="hidden">
        <TableSkeleton rows={25} height="calc(100vh - 280px)" />
      </Card>

      <Card spacing="none" overflow="hidden">
        <Flexbox justifyContent="space-between" style={{ padding: 'var(--scale600)' }}>
          <Flexbox
            justifyContent="space-between"
            alignItems="center"
            style={{ gap: 'var(--gap-mini)' }}
          >
            <TextSkeleton width="var(--scale1000)" height="var(--scale600)" />
            <InputSkeleton width="var(--scale1800)" size="mini" />
            <TextSkeleton width="var(--scale1800)" height="var(--scale600)" />
          </Flexbox>

          <Flexbox
            justifyContent="space-between"
            alignItems="center"
            style={{ gap: 'var(--gap-mini)', marginRight: 'var(--scale1400)' }}
          >
            <TextSkeleton width="var(--scale750)" height="var(--scale600)" />
            <InputSkeleton width="93px" size="mini" />
            <TextSkeleton width="var(--scale900)" height="var(--scale600)" />

            <ButtonSkeleton
              placeholder="Create asset record"
              width="var(--scale1600)"
              size="mini"
            />

            <ButtonSkeleton
              placeholder="Create asset record"
              width="var(--scale1600)"
              size="mini"
            />
          </Flexbox>
        </Flexbox>
      </Card>
    </>
  )
}

export default AssetTableLoading
