import { FOCUS, SR_ONLY } from '@styles-api/css-constants'
import { Scale } from '@styles-api/layout.types'
import { FontSize } from '@styles-api/typography.types'
import { StyleObject } from '@styles-api/css.types'

export const container = ({ width, flexGrow }) => {
  return {
    width: flexGrow ? 'min-content' : width,
    display: 'block',
    flexGrow: flexGrow ? 1 : undefined,
  }
}
export const label = ({ disabled, label }) => {
  let hidden
  if (!label) hidden = SR_ONLY
  return {
    color: disabled ? 'var(--text-muted)' : 'var(--text-color)',
    marginBottom: 'var(--scale200)',
    display: 'block',
    lineHeight: 1,
    fontWeight: 'var(--font-weight-normal)',
    ...hidden,
  }
}

// input wrapper
export const inputWrapperBase: StyleObject = {
  borderRadius: 'var(--radius1)',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  borderWidth: '1px',
  transition: 'var(--focus-shadow-transition)',
}
export const getFocus = ({ focus, error }) => {
  if (!error || focus)
    return {
      boxShadow: focus ? 'var(--focus-shadow)' : 'var(--input-inset-shadow)',
      borderColor: focus ? 'var(--primary-color)' : 'var(--btn-border-color)',
      outline: focus ? 'var(--focus-outline)' : 'none',
      backgroundColor: focus ? 'var(--base-color)' : 'var(--base-color)',
    }
  else if (error === true && focus === false) {
    return {
      borderColor: 'var(--danger-color)',
      backgroundColor: 'var(--danger-color50)',
    }
  }
}
export const getReadOnly: StyleObject = {
  pointerEvents: 'none',
}
export const inputWrapperDisabled = ({ disabled, appearance }) => {
  return {
    cursor: disabled ? 'not-allowed' : 'default',
  }
}
export const getSize = ({ size, select, appearance }) => {
  let height = `var(--size-${size})`
  let paddingX: Scale
  let fontSize: FontSize
  if (select === false) {
    switch (size) {
      case 'mini':
        paddingX = 'var(--scale500)'
        fontSize = 'var(--small-font-size)'
        break
      case 'compact':
        paddingX = 'var(--scale550)'
        fontSize = 'var(--base-font-size)'
        break
      case 'default':
        paddingX = 'var(--scale500)'
        fontSize = 'var(--base-font-size)'
        break
      case 'large':
        paddingX = 'var(--scale600)'
        fontSize = 'var(--large-font-size)'
        break
    }
  }

  return {
    height,
    paddingLeft: appearance === 'clear' ? '0' : paddingX,
    paddingRight: appearance === 'clear' ? '0' : paddingX,
    fontSize,
  }
}
export const getBorder = ({ focus, error }) => {
  if (focus === true) {
    return {
      outline: 'var(--focus-outline)',
      borderColor: 'var(--primary-color)',
      boxShadow: 'var(--focus-shadow)',
      ':after': {
        backgroundColor: 'var(--transparent)',
      },
    }
  } else if (error === true && focus === false) {
    return {
      outline: '2px solid var(--danger-color)',
      borderColor: 'var(--danger-color)',
      ':after': {
        backgroundColor: 'var(--transparent)',
      },
    }
  } else return null
}
// input element
export const inputBase: StyleObject = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'var(--transparent)',
}
export const inputBaseDisabled = ({ disabled }) => {
  return {
    cursor: disabled ? 'not-allowed' : 'text',
    color: disabled ? 'var(--text-muted)' : 'var(--text-color)',
  }
}

/// this is for the select component * -> Inputs, TextArea, Select etc. share same stylesheet
export const getSelect = ({ size, appearance }) => {
  let height = `var(--size-${size})`
  let paddingX: Scale
  let fontSize: FontSize

  switch (size) {
    case 'mini':
      paddingX = 'var(--scale500)'
      fontSize = 'var(--small-font-size)'
      break
    case 'compact':
      paddingX = 'var(--scale550)'
      fontSize = 'var(--base-font-size)'
      break
    case 'default':
      paddingX = 'var(--scale500)'
      fontSize = 'var(--base-font-size)'
      break
    case 'large':
      paddingX = 'var(--scale600)'
      fontSize = 'var(--large-font-size)'
      break
  }

  return {
    borderRadius: 'var(--radius1)',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'var(--default-btn-bg)',
    borderWidth: '1px',

    height,
    paddingLeft: appearance === 'clear' ? '0' : paddingX,
    paddingRight: paddingX,
    fontSize,
    cursor: 'pointer',
    background: 'none',
    appearance: 'none',
  }
}
export const getSelectFocus = ({ focus, error }) => {
  if (!error)
    return {
      boxShadow: focus ? 'var(--focus-shadow)' : 'var(--default-btn-shadow)',
      borderColor: focus ? 'var(--primary-color)' : 'var(--btn-border-color)',
      outline: focus ? 'var(--focus-outline)' : 'none',
      backgroundColor: focus ? 'var(--base-color)' : 'var(--default-btn-bg)',
    }
  else if (error === true && focus === false) {
    return {
      outline: '2px solid var(--danger-color)',
      borderColor: 'var(--danger-color)',
    }
  }
}
export const selectIcon = ({ size, appearance }) => {
  let spacingRight

  if (appearance !== 'clear') {
    switch (size) {
      case 'mini':
        spacingRight = 'var(--scale500)'
        break
      case 'compact':
        spacingRight = 'var(--scale500)'
        break
      case 'default':
        spacingRight = 'var(--scale500)'
        break
      case 'large':
        spacingRight = 'var(--scale600)'
        break
    }
  } else spacingRight = '0'
  return {
    height: '100%',
    top: 0,
    position: 'absolute',
    right: spacingRight,
    width: 'var(--scale550)',
    pointerEvents: 'none',
  }
}

// helper text && error message
export const msgBaseStyles: StyleObject = {
  fontSize: 'var(--small-font-size)',
  marginTop: 'var(--scale200)',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1,
}
export const helperText = ({ disabled }) => {
  return {
    color: disabled ? 'var(--text-muted)' : 'var(--text-light)',
    ...msgBaseStyles,
  }
}
export const errorMsg: StyleObject = {
  color: 'var(--danger-color)',
  ...msgBaseStyles,
}
export const errorMsgIcon: StyleObject = {
  height: 'var(--scale550)',
  width: 'var(--scale550)',
  marginRight: 'var(--scale100)',
}
// icons
export const icon = ({ size, position, isString }) => {
  let fontSize: FontSize
  let iconSize: Scale
  let spacing: Scale
  switch (size) {
    case 'mini':
      fontSize = 'var(--small-font-size)'
      iconSize = 'var(--scale550)'
      spacing = 'var(--scale500)'
      break
    case 'compact':
      fontSize = 'var(--base-font-size)'
      iconSize = 'var(--scale550)'
      spacing = 'var(--scale500)'
      break
    case 'default':
      fontSize = 'var(--base-font-size)'
      iconSize = 'var(--scale600)'
      spacing = 'var(--scale500)'
      break
    case 'large':
      fontSize = 'var(--large-font-size)'
      iconSize = 'var(--scale750)'
      spacing = 'var(--scale600)'
      break
  }
  return {
    fontSize,
    height: isString ? '100%' : iconSize,
    width: position === 'left' ? iconSize : 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: position === 'right' ? spacing : '0',
    paddingRight: position === 'left' ? 'var(--scale300)' : '0',
    boxSizing: 'content-box',
  }
}

// password visibility button
export const passwordVisibilityButton = ({ size }) => {
  let width = `var(--size-${size})`
  return {
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    width,
    background: 'none',
    display: 'flex',
    alignItems: 'center',

    justifyContent: 'center',
    cursor: 'pointer',
    ...FOCUS,
  }
}
export const passwordIcon: StyleObject = {
  height: 'var(--scale600)',
}

// skeleton
export const labelSkeleton: StyleObject = {
  height: 'var(--scale500)',
  width: '80px',
  marginBottom: 'var(--scale300)',
  marginTop: 'var(--scale300)',
}
export const inputSkeleton = ({ size }) => {
  let height = `calc(var(--size-${size}) + 1px)`
  return {
    height,
    width: '100%',
    borderRadius: 'var(--radius1)',
  }
}
