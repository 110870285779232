import Insurances from '@components/Insurances'
import View from '@components/layout/View'

const InsurancePoliciesView = () => {
  return (
    <View title="Insurance Policies" showHeader={false}>
      <Insurances />
    </View>
  )
}

export default InsurancePoliciesView
