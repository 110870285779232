import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider, useDispatch } from 'react-redux'
import { createAppStore } from '@store/storeConfig'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthProvider from '@components/Auth/AuthProvider'
import AuthProtector from '@components/Auth/AuthProtector'
// launch Styletron engine
import { Provider as StyletronProvider } from 'styletron-react'
import { Client as Styletron } from 'styletron-engine-atomic'

// import layout, render routes and templates
// import global fonts & stylesheets
// import './assets/fonts/rolphie/font.rolphie.css'
import './stylesheets/base/normalize.scss'
import './stylesheets/base/base.scss'
// import theme stylesheets
import './stylesheets/theme/root--mrs-ui.scss'
import './stylesheets/theme/theme--light.scss'
import './stylesheets/theme/theme--dark.scss'

const engine = new Styletron()

const store = createAppStore()
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types

ReactDOM.render(
  <BrowserRouter>
    <StyletronProvider value={engine}>
      <AuthProvider>
        <AuthProtector>
          <Provider store={store}>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </Provider>
        </AuthProtector>
      </AuthProvider>
    </StyletronProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
//Test
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
