import { StyleObject } from '@styles-api/css.types'

export const importInput__nativeInputStyles: StyleObject = {
  height: 0,
  width: 0,
  opacity: 0,
  display: 'none',
}
export const importInput__labelStyles: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: '200px',
  padding: 'var(--surfacePadding)',
  border: '2px dashed var(--borderColor)',
  transition: 'var(--hoverTransition)',
  cursor: 'pointer',
  borderRadius: 'var(--radius2)',
  ':hover': {
    background: 'var(--shade100)',
  },
}
