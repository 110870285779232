import { ButtonSkeleton, Card, Flexbox, TableSkeleton } from '@components/common'

const CompanyPeriodsLoading = () => {
  return (
    <>
      <Card spacing="none" overflow="hidden" style={{ padding: '12px 16px' }}>
        <Flexbox justifyContent="space-between" style={{ gap: 'var(--gap-mini)' }}>
          <ButtonSkeleton width="20%" />
          <ButtonSkeleton width="35%" />
        </Flexbox>
      </Card>

      <Card spacing="none" overflow="hidden">
        <TableSkeleton rows={12} />
      </Card>
    </>
  )
}
export default CompanyPeriodsLoading
