import { DEPRECIATION_TAGS, apiSlice } from '@api'
import { formatISODate } from '@utils/basic'
import { YearEndInfo, YearEndRunInput, YearEndUndoInfo, YearEndUndoInput } from '../models'

export const yearEndDepreciationEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getYearEndRunInfo: build.query<YearEndInfo, number>({
      query: assetCompanyID => `AssetHistory/YrEndRunInfo/${assetCompanyID}`,
      transformResponse: (info: YearEndInfo) => ({
        ...info,
        PostDate: formatISODate(info.PostDate),
      }),
      providesTags: ['depreciationRun'],
    }),
    yearEndRun: build.mutation<void, YearEndRunInput>({
      query: depRunInfo => ({
        url: `AssetHistory/YrEndRun`,
        method: 'POST',
        body: { ...depRunInfo },
      }),
      invalidatesTags: DEPRECIATION_TAGS,
    }),
    getYearEndUpdateInfo: build.query<YearEndInfo, number>({
      query: assetCompanyID => `AssetHistory/YrEndUpdateInfo/${assetCompanyID}`,
      transformResponse: (info: YearEndInfo) => ({
        ...info,
        PostDate: formatISODate(info.PostDate),
      }),
      providesTags: DEPRECIATION_TAGS,
    }),
    yearEndUpdate: build.mutation<void, number>({
      query: assetCompanyID => ({
        url: `AssetHistory/YrEndUpdate/${assetCompanyID}`,
        method: 'POST',
      }),
      invalidatesTags: DEPRECIATION_TAGS,
    }),
    yearEndUndoInfo: build.query<YearEndUndoInfo, number>({
      query: assetCompanyID => `AssetHistory/YrEndUndoInfo/${assetCompanyID}`,
      transformResponse: (info: YearEndUndoInfo) => ({
        ...info,
        PostDate: formatISODate(info.PostDate),
      }),
      providesTags: DEPRECIATION_TAGS,
    }),
    yearEndUndo: build.mutation<void, YearEndUndoInput>({
      query: undoInput => ({
        url: 'AssetHistory/YrEndUndo',
        method: 'POST',
        body: { ...undoInput },
      }),
      invalidatesTags: DEPRECIATION_TAGS,
    }),
    discardRun: build.mutation<void, number>({
      query: assetCompanyID => ({
        url: `AssetHistory/DiscardRun/${assetCompanyID}`,
        method: 'POST',
      }),
      invalidatesTags: DEPRECIATION_TAGS,
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetYearEndRunInfoQuery,
  useYearEndRunMutation,
  useGetYearEndUpdateInfoQuery,
  useYearEndUpdateMutation,
  useYearEndUndoInfoQuery,
  useYearEndUndoMutation,
  useDiscardRunMutation,
} = yearEndDepreciationEndpoints
