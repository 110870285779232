import React from 'react'
import { BreadcrumbsProps } from './Breadcrumbs.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

import { Icon, Link } from '@components/common'

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ ...props }) => {
  const [css] = useStyletron()

  return (
    <nav className={css(styles.nav)} aria-label="Current page breadcrumbs">
      <ol className={css(styles.list)}>
        {props.crumbs.map((i, index) => (
          <li key={i.label} className={css(styles.item)}>
            {i.isActive ? (
              <span>{i.label}</span>
            ) : (
              <Link to={i.path}>{i.label}</Link>
            )}
            {props.crumbs.length !== index + 1 && (
              <Icon name="ChevronRight" className={css(styles.icon)} />
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
