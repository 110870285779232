import {
  Card,
  Checkbox,
  CustomDatePicker,
  Fieldset,
  Form,
  Grid,
  Spacer,
  toast,
} from '@components/common'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  DEPRECIATION_SUMMARY_REPORT_PATH,
  DEPREC_PROFILES,
  ERROR_REQUEST,
} from '@constants'
import ReportFilters from '../ReportFilters'
import { defaultReportFilterFields } from '../ReportFilters/report-filter-fields'
import { DeprecSummaryReportParams } from '@api/models'
import { DepProfileSelect, ActionButtons } from '../General'
import { useReport } from '@hooks'
import { useEffect } from 'react'
import { getTodaysDate } from '@utils/basic'
import { reportResponseHandler } from '@utils/reports'
import { ReportsApi } from '@api'
import { useMemorizeReport } from '../General/MemorizeReports/useMemorizeReport'
import MemorizeReports from '../General/MemorizeReports/MemorizeReport'
import { useFormMethods } from '../General/useFormMethods'
import { useLocation } from 'react-router-dom'

const defaultValues: DeprecSummaryReportParams = {
  assetCompanyID: 0,
  filter: [],
  profile: DEPREC_PROFILES.CORPORATE,
  asOfDate: getTodaysDate(),
  includeCaps: false,
  includeAccelCode: false,
  includeDeprecSystemCode: false,
  includeDeprecUserCode: false,
  includeAcqCost: false,
  includeYears: false,
  includeSerialID: false,
  totalsOnly: false,
  includeDisposals: false,
  includeAssetDesc: false,
  fileFormat: 'pdf',
}

async function onSubmitHandler(values: DeprecSummaryReportParams) {
  await ReportsApi.getInstance()
    .getDeprecSummaryReport(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

const DeprecSummaryForm = () => {
  const location = useLocation()
  const locationState: any = location.state

  const {
    methods,
    isRetrievingTemplate,
    isEditTemplate,
    selectedTemplateDetails,
  } = useFormMethods(locationState, defaultValues)

  const {
    formState: { isSubmitting, errors },
    control,
    setValue,
  } = methods

  const {
    isLoading,
    isSuccess,
    isError,
    isNoCompanies,
    depProfilesMap,
    assetCompanyID,
  } = useReport()

  const {
    handleMemorizeTemplateSubmit,
    isMemorizeTemplateModalOpen,
    closeMemorizeTemplateModal,
    triggerMemorizeTemplateModal,
    handleEditTemplateSubmit,
  } = useMemorizeReport({ methods, currentReportPath: DEPRECIATION_SUMMARY_REPORT_PATH })

  useEffect(() => {
    if (isSuccess) {
      setValue('assetCompanyID', assetCompanyID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, assetCompanyID])

  function getCheckBoxProps(field: any) {
    return {
      checked: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      onBlur: field.onBlur,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (isNoCompanies) {
    return <div>No companies found</div>
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Card title={'Report Parameters'}>
            <Grid>
              <Grid.Item>
                <DepProfileSelect depProfilesMap={depProfilesMap} />
              </Grid.Item>
              <Grid.Item>
                <Controller
                  control={control}
                  name="asOfDate"
                  render={({ field }) => (
                    <CustomDatePicker {...getDateProps(field)} label="As of date" />
                  )}
                />
              </Grid.Item>

              <Grid.Item m={12} l={6}>
                <Fieldset legend={'Additional Columns to Include'}>
                  <Grid rowGap="mini">
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeAssetDesc"
                        render={({ field }) => (
                          <Checkbox
                            label="Include asset description"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeAccelCode"
                        render={({ field }) => (
                          <Checkbox
                            label="Include acceleration code"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDeprecSystemCode"
                        render={({ field }) => (
                          <Checkbox
                            label="Include depreciation system code"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDeprecUserCode"
                        render={({ field }) => (
                          <Checkbox
                            label="Include depreciation user code"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeAcqCost"
                        render={({ field }) => (
                          <Checkbox
                            label="Include acquisition cost"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeYears"
                        render={({ field }) => (
                          <Checkbox label="Include years" {...getCheckBoxProps(field)} />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeSerialID"
                        render={({ field }) => (
                          <Checkbox
                            label="Include serial ID"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item m={12} l={6}>
                <Fieldset legend={'Options'}>
                  <Grid rowGap="mini">
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeCaps"
                        render={({ field }) => (
                          <Checkbox
                            label="Include capitalizations"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDisposals"
                        render={({ field }) => (
                          <Checkbox
                            label="Include disposals"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="totalsOnly"
                        render={({ field }) => (
                          <Checkbox label="Totals only" {...getCheckBoxProps(field)} />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item>
                <ReportFilters
                  filtersTemplateData={
                    isRetrievingTemplate
                      ? locationState.templateDetails.templateData.filter
                      : []
                  }
                  filterFields={defaultReportFilterFields}
                />
              </Grid.Item>
            </Grid>
          </Card>
          <Spacer y="default" />
          <ActionButtons
            isSubmitting={isSubmitting}
            triggerMemorizeTemplateModal={triggerMemorizeTemplateModal}
            isEditTemplate={isEditTemplate}
          />
        </Form>
      </FormProvider>

      <MemorizeReports
        isMemorizeTemplateModalOpen={isMemorizeTemplateModalOpen}
        closeMemorizeTemplateModal={closeMemorizeTemplateModal}
        handleMemorizeTemplateSubmit={handleMemorizeTemplateSubmit}
        handleEditTemplateSubmit={handleEditTemplateSubmit}
        isEditTemplate={isEditTemplate}
        selectedTemplateDetails={selectedTemplateDetails}
      />
    </>
  )
}

export default DeprecSummaryForm
