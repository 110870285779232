import HttpClient from '../HttpClient'

export class FolderMasterApiHelper extends HttpClient {
  private static classInstance?: FolderMasterApiHelper

  public constructor() {
    super(`/FldrMaster`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new FolderMasterApiHelper()
    }

    return this.classInstance
  }

  public fetch = (filter: string, pageNumber: number, itemsPerPage: number) =>
    this.instance.get(
      `/FetchFolders?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`
    )
  public fetchAssetFolders = (assetCompanyId: number, assetId: string) =>
    this.instance.get(
      `/FetchAssetFolders?assetcompanyid=${assetCompanyId}&assetid=${assetId}`
    )
  public getFolder = (id: string) => this.instance.get(`/GetFolder?id=${id}`)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (data: any) => this.instance.post(`/Delete`, data)
  public importFolder = (file: any) => this.instance.post(`/ImportFolder`, file)
  public importFolderLink = (file: any) =>
    this.instance.post(`/ImportFolderLink`, file)
  public includeFolders = (
    assetCompanyId: number,
    assetId: string,
    keys: Array<number>
  ) =>
    this.instance.post(
      `/IncludeFolders?assetcompanyid=${assetCompanyId}&assetid=${assetId}&keys=${encodeURIComponent(
        JSON.stringify(keys)
      )}`
    )
  public excludeFolders = (
    assetCompanyId: number,
    assetId: string,
    keys: Array<number>
  ) =>
    this.instance.post(
      `/ExcludeFolders?assetcompanyid=${assetCompanyId}&assetid=${assetId}&keys=${encodeURIComponent(
        JSON.stringify(keys)
      )}`
    )
  public createAndIncludeFolder = (
    assetCompanyId: number,
    assetId: string,
    name: String
  ) =>
    this.instance.post(
      `/CreateAndIncludeFolder?assetcompanyid=${assetCompanyId}&assetid=${assetId}&name=${name}`
    )
  public list = () => this.instance.get(`/ListFolders`)
  public saveFolderList = (
    assetCompanyId: number,
    assetId: string,
    keys: Array<number>
  ) =>
    this.instance.post(
      `/SaveList?assetcompanyid=${assetCompanyId}&assetid=${assetId}&keys=${encodeURIComponent(
        JSON.stringify(keys)
      )}`
    )
}
