import {
  Checkbox,
  CustomDatePicker,
  Fieldset,
  Form,
  Grid,
  Input,
  Select,
  Spacer,
} from '@common'
import { useFields, useFormFieldProps } from '@hooks'
import { MAINTENANCE_FORM_ID } from '@constants'
import { MaintenanceContract, maintenanceSchema } from '@api/models'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useGetExtraMaintenanceFieldsQuery } from '@api'
import { getTodaysDate } from '@utils/basic'

interface MaintenanceFormProps {
  isEdit: boolean
  initialValues: MaintenanceContract
  onSubmit: (payload: MaintenanceContract) => Promise<void>
  canEdit?: boolean
}

const MaintenanceForm = ({
  isEdit,
  initialValues,
  onSubmit,
  canEdit = true,
}: MaintenanceFormProps) => {
  const { fields, isLoading: isFieldsLoading } = useFields()
  const {
    data: extraFields,
    isLoading: isExtraFieldsLoading,
  } = useGetExtraMaintenanceFieldsQuery()

  const {
    formState: { isSubmitting, errors },
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<MaintenanceContract>({
    defaultValues: isEdit
      ? initialValues
      : {
          ...initialValues,
          MN_CP_FRM: getTodaysDate(),
          MN_CP_TO: getTodaysDate(),
          MN_LB_FRM: getTodaysDate(),
          MN_LB_TO: getTodaysDate(),
          MN_PT_FRM: getTodaysDate(),
          MN_PT_TO: getTodaysDate(),
          MN_OT_FRM: getTodaysDate(),
          MN_OT_TO: getTodaysDate(),
        },
    resolver: yupResolver(maintenanceSchema),
    mode: 'onBlur',
  })

  const {
    getInputProps,
    getCheckBoxProps,
    getSelectProps,
    getCreatableSelectProps,
    getDateProps,
  } = useFormFieldProps({
    isSubmitting,
    errors,
    fields,
    register,
  })

  const isLabourIncluded = watch('MN_LB_CD')
  const isPartsIncluded = watch('MN_PT_CD')
  const isOtherIncluded = watch('MN_OT_CD')
  const isLoading = isFieldsLoading || isExtraFieldsLoading

  useEffect(() => {
    if (!isLabourIncluded) {
      setValue('MN_LB_FRM', getValues('MN_CP_FRM'), { shouldValidate: true })
      setValue('MN_LB_TO', getValues('MN_CP_TO'), { shouldValidate: true })
    }
  }, [isLabourIncluded, setValue, getValues])

  useEffect(() => {
    if (!isPartsIncluded) {
      setValue('MN_PT_FRM', getValues('MN_CP_FRM'), { shouldValidate: true })
      setValue('MN_PT_TO', getValues('MN_CP_TO'), { shouldValidate: true })
    }
  }, [isPartsIncluded, setValue, getValues])

  useEffect(() => {
    if (!isOtherIncluded) {
      setValue('MN_OT_FRM', getValues('MN_CP_FRM'), { shouldValidate: true })
      setValue('MN_OT_TO', getValues('MN_CP_TO'), { shouldValidate: true })
    }
  }, [isOtherIncluded, setValue, getValues])

  if (isLoading) return <div>Loading...</div>

  return (
    <Form id={MAINTENANCE_FORM_ID} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid>
        <Grid.Item>
          <Input {...getInputProps('MN_CON_ID')} autoFocus={!isEdit} disabled={isEdit} />
        </Grid.Item>
        <Grid.Item>
          <Input {...getInputProps('MN_DESC')} autoFocus={isEdit} disabled={!canEdit} />
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="Company Information">
            <Grid>
              <Grid.Item>
                <Input {...getInputProps('MN_NAME')} disabled={!canEdit} />
              </Grid.Item>
              <Grid.Item>
                <Input {...getInputProps('MN_STREET')} disabled={!canEdit} />
              </Grid.Item>
              <Grid.Item s={9}>
                <Controller
                  control={control}
                  name="MN_CITY"
                  render={({ field }) => (
                    <Select
                      {...getCreatableSelectProps('MN_CITY', field, extraFields.cities)}
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={3}>
                <Controller
                  control={control}
                  name="MN_STATE"
                  render={({ field }) => (
                    <Select
                      {...getCreatableSelectProps('MN_STATE', field, extraFields.states)}
                      helper="2 character code"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item>
                <Controller
                  control={control}
                  name="MN_CNTY"
                  render={({ field }) => (
                    <Select
                      {...getCreatableSelectProps('MN_CNTY', field, extraFields.countries)}
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item>
                <Input {...getInputProps('MN_ZIP')} disabled={!canEdit} />
              </Grid.Item>
              <Grid.Item>
                <Input {...getInputProps('MN_PHONE')} disabled={!canEdit} />
              </Grid.Item>
              <Grid.Item>
                <Input {...getInputProps('MN_FAX')} disabled={!canEdit} />
              </Grid.Item>
            </Grid>
          </Fieldset>
          <Spacer y="default" />
          <Grid.Item>
          <Fieldset legend="Contact Details">
            <Grid>
              <Grid.Item>
                <Input {...getInputProps('MN_CON_NM')} disabled={!canEdit} />
              </Grid.Item>
              <Grid.Item>
                <Input {...getInputProps('MN_CON_PN')} disabled={!canEdit} />
              </Grid.Item>
              <Grid.Item>
                <Input {...getInputProps('MN_CON_FX')} disabled={!canEdit} />
              </Grid.Item>
            </Grid>
          </Fieldset>
          </Grid.Item>
          <Spacer y="default" />
          <Fieldset legend="Contract Details">
            <Grid>
              <Grid.Item>
                <Input {...getInputProps('MN_CON_AMT', 'currency')} disabled={!canEdit} />
              </Grid.Item>
              <Grid.Item>
                <Controller
                  control={control}
                  name="MN_PMT_CD"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('MN_PMT_CD', field, extraFields.paymentTerms)}
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4}>
                <Controller
                  control={control}
                  name="MN_CP_FRM"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps('MN_CP_FRM', field)}
                      label={fields[`MN_CP_FRM`]}
                      autoFocus={isEdit}
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4}>
                <Controller
                  control={control}
                  name="MN_CP_TO"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps('MN_CP_TO', field)}
                      label={fields[`MN_CP_TO`]}
                      autoFocus={isEdit}
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4} startS={1}>
                <Controller
                  control={control}
                  name="MN_LB_FRM"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps('MN_LB_FRM', field)}
                      label="Labour start date"
                      disabled={!isLabourIncluded || !canEdit}
                      autoFocus={isEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4}>
                <Controller
                  control={control}
                  name="MN_LB_TO"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps('MN_LB_TO', field)}
                      label="Labour end date"
                      disabled={!isLabourIncluded || !canEdit}
                      autoFocus={isEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  control={control}
                  name="MN_LB_CD"
                  render={({ field }) => (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      label="Labour included"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4} startS={1}>
                <Controller
                  control={control}
                  name="MN_PT_FRM"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps('MN_PT_FRM', field)}
                      label="Parts start date"
                      disabled={!isPartsIncluded || !canEdit}
                      autoFocus={isEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4}>
                <Controller
                  control={control}
                  name="MN_PT_TO"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps('MN_PT_TO', field)}
                      label="Parts end date"
                      disabled={!isPartsIncluded || !canEdit}
                      autoFocus={isEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  control={control}
                  name="MN_PT_CD"
                  render={({ field }) => (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      label="Parts included"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4} startS={1}>
                <Controller
                  control={control}
                  name="MN_OT_FRM"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps('MN_OT_FRM', field)}
                      label="Other start date"
                      disabled={!isOtherIncluded || !canEdit}
                      autoFocus={isEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4}>
                <Controller
                  control={control}
                  name="MN_OT_TO"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps('MN_OT_TO', field)}
                      label="Other end date"
                      disabled={!isOtherIncluded || !canEdit}
                      autoFocus={isEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item s={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  control={control}
                  name="MN_OT_CD"
                  render={({ field }) => (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      label="Other included"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
            </Grid>
          </Fieldset>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default MaintenanceForm
