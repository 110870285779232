import { Card, Grid, Icon, Input, Link, Select, Spinner } from '@components/common'
import { useListFoldersQuery, useGetAssetIDsQuery } from '@api'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { Controller, useFormContext } from 'react-hook-form'
import { AllAssetDetailFields, Asset, FieldLabels } from '@api/models'
import { useFormFieldProps } from '@hooks'
import { isNullOrUndefined } from '@utils/basic'
import { IUserGrantProps } from '@components/Auth/UserRole'
import AssetOrganizationLoading from './AssetOrganizationLoading'

interface Props extends IUserGrantProps {
  fields: FieldLabels
  isLoadingInfo: boolean
  isAssetDisposedOrPendingDisposal: boolean
  asset: Asset
}

const AssetOrganization = ({
  fields,
  isLoadingInfo,
  isAssetDisposedOrPendingDisposal,
  asset,
  canEdit,
}: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
  } = useFormContext<AllAssetDetailFields>()

  const {
    data: folders,
    isLoading: isLoadingFolders,
    isSuccess: isSuccessGettingFolders,
  } = useListFoldersQuery()

  const {
    data: assetIDs,
    isLoading: isLoadingAssetIDs,
    isSuccess: isSuccessGettingAssetIDs,
  } =  useGetAssetIDsQuery({
    assetCompanyID: asset.AssetCompanyID
  })
 
  const { getMultiSelectProps, getInputProps, getSelectProps } = useFormFieldProps<AllAssetDetailFields>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  const tagOptions: ReactSelectOptions = []
  if (isSuccessGettingFolders) {
    for (const folder of folders) {
      tagOptions.push({
        label: folder.Name,
        value: folder.Key,
      })
    }
  }

  const parentIDOptions: ReactSelectOptions = []
  if (isSuccessGettingAssetIDs) {
    for (const assetID of assetIDs) {         
        parentIDOptions.push({
          label: assetID,
          value: assetID,
        }) 
    }
  }

  const isLoadingInfoOrFolders = isLoadingInfo || isLoadingFolders || isLoadingAssetIDs

  return (
    <Card title={'Asset organization'}>
      {isLoadingInfoOrFolders && <AssetOrganizationLoading />}

      {!isLoadingInfoOrFolders && (
        <Grid>
          <Grid.Item s={6}>
          <Controller
              control={control}
              name="AS_PAR_ASS"
              render={({ field }) => (
                <Select
                  {...getSelectProps('AS_PAR_ASS', field, parentIDOptions)}
                  isSearchable
                  readOnly={!canEdit || isAssetDisposedOrPendingDisposal}
                  helper={
                    !isNullOrUndefined(asset.AS_PAR_ASS) ? (
                      <Link
                        to={`/assets/${asset.AssetCompanyID}/${asset.AS_PAR_ASS}/details`}
                        external
                        icon={<Icon name="ExternalLink" />}
                      >
                        View parent asset
                      </Link>
                    ) : (
                      ''
                    )
                  }
                />
              )}
            />          
          </Grid.Item>
          <Grid.Item s={6}>
            <Input
              {...getInputProps('AS_LEASE')}
              readOnly={!canEdit || isAssetDisposedOrPendingDisposal}              
            />
          </Grid.Item>
          <Grid.Item>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <Select
                  {...getMultiSelectProps('tags', field, tagOptions)}
                  type="multi"
                  isSearchable
                  placeholder="Search for tags..."
                  label="Tags/folders"
                  disabled={!canEdit || isAssetDisposedOrPendingDisposal}
                />
              )}
            />
          </Grid.Item>
        </Grid>
      )}      
    </Card>
  )
}

export default AssetOrganization
