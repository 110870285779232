import React from 'react'
import { CardProps } from './Card.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

import { Heading } from '../'

const Card: React.FC<CardProps> = ({
  spacing = 'default',
  appearance = 'elevated',
  title,
  addon,
  type = 'default',
  overflow = 'default',
  ...props
}) => {
  const [css] = useStyletron()

  let cardStyles = css({
    ...styles.base,
    ...styles.getAppearance({ appearance }),
    ...styles.getType({ type }),
    ...styles.overflowHidden({ overflow }),
  })
  let innerStyles = css({ ...styles.getSpacing({ spacing }) })
  let headerStyles = css({ ...styles.header({ spacing }) })

  if (props.className) cardStyles += ' ' + props.className

  return (
    <div className={cardStyles}>
      {(title || addon) && (
        <div className={headerStyles}>
          <Heading type="h3" bold baseSize centered>
            {title}
          </Heading>
          {addon}
        </div>
      )}
      <div className={innerStyles}>{props.children}</div>
    </div>
  )
}

export default Card
