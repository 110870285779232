import HttpClient from "../HttpClient";

export class AssetFilesApi extends HttpClient {
    private static classInstance?: AssetFilesApi;

    public constructor () {
        super (`/AssetFiles`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new AssetFilesApi();
        }

        return this.classInstance;
    }

    public fetchAssetFiles = () => this.instance.get(`/FetchAssetFiles`);
    public getAssetFile = (id: number) => this.instance.get(`/GetAssetFile?id=${id}`);
    public create = (data: any) => this.instance.post(`/Create`, data);
    public edit = (id: string, data: any) => this.instance.put(`/Edit?id=${id}`, data);
    public download = (id: string) => this.instance.get(`/Download?id=${id}`);
    public delete = (id: string) => this.instance.post(`/Delete?id=${id}`);
}