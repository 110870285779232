import { SKELETON_SHINE } from '@styles-api/css-constants'
import React from 'react'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

interface Props {
  size?: 'mini' | 'compact' | 'default' | 'large'
  shape?: 'default' | 'pill' | 'circle' | 'square'
  flex?: boolean
  flexGrow?: boolean
  placeholder?: string
  width?: string
}

const ButtonSkeleton: React.FC<Props> = ({
  size = 'default',
  shape = 'default',
  flex = false,
  flexGrow = false,
  placeholder,
  width,
}) => {
  const [css] = useStyletron()

  let mainStyles = {
    ...styles.getSkeletonStyles({ shape, size, flex, flexGrow, width }),
    ...SKELETON_SHINE,
  }
  let mainClassName = css(mainStyles)
  return (
    <div className={mainClassName}>
      {shape !== 'circle' && shape !== 'square' && (
        <div className={css(styles.skeletonContentStyles)} role="presentation">
          {placeholder}
        </div>
      )}
    </div>
  )
}
export default ButtonSkeleton
