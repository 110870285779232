import React from 'react'
import { AvatarProps } from './Avatar.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

function getInitials(name) {
  const words = name.split(' ')
  const initials = words.map(word => word[0])
  return initials.slice(0, 2).join('').toUpperCase()
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  name,
  size = 'default',
  style,
  id,
  className,
}) => {
  const [css] = useStyletron()

  let wrapperStyles = {
    ...styles.base,
    ...styles.getSize({ size }),
  }
  let wrapperClassName = css(wrapperStyles)
  if (className) wrapperClassName += ' ' + css(className)

  let imageClassName = css(styles.img)
  let initialsClassName = css(styles.intitials)
  return (
    <div
      className={wrapperClassName}
      aria-label={`Avatar for ${name}`}
      role="img"
      style={style}
      id={id}
    >
      {src ? (
        <img src={src} className={imageClassName} alt={name} />
      ) : (
        <div className={initialsClassName}>{getInitials(name)}</div>
      )}
    </div>
  )
}

export default Avatar
