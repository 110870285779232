import { StyleObject } from '@styles-api/css.types'

export const tableRow = (isSelected: boolean): StyleObject => {
  return {
    display: 'flex',
    width: '100%',
    cursor: isSelected ? 'default' : 'pointer',
    backgroundColor: isSelected ? 'var(--shade100)' : 'none',
    color: isSelected ? 'var(--primary-color)' : 'var(--contrast-color)',
    fontWeight: isSelected ? 'var(--font-weight-bold)' : 'var(--font-weight-normal)',
  }
}
