import React from 'react'
import { StatusIndicatorProps } from './StatusIndicator.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  type = 'default',
  label,
}) => {
  const [css] = useStyletron()
  let dotStyles = css({
    ...styles.dot,
    ...styles.getColor({ type }),
  })

  return (
    <div className={css(styles.wrapper)}>
      <span className={dotStyles} />
      <span className={css(styles.label)}>{label}</span>
    </div>
  )
}

export default StatusIndicator
