import { apiSlice } from '@api'
import { AssetRecalcDTO } from '@api/assets/models'
import { formatISODate, serializeURLParams } from '@utils/basic'
import moment from 'moment'
import {
  FetchCustomDepreciationResponse,
  FetchCustomDepreciationParams,
  CustomDepreciationWrapper,
  EditCustomDepreciationWrapper,
  CustomDepreciationRecalcPayload,
} from '../models'

export const customDepreciationEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    fetchCustomDepreciation: build.query<
      FetchCustomDepreciationResponse,
      FetchCustomDepreciationParams
    >({
      query: ({ assetCompanyID, profile, objectID }) =>
        `/DeprecSP/Fetch/${assetCompanyID}/${profile}/${encodeURIComponent(objectID)}`,
      providesTags: ['customDepreciation'],
      transformResponse: (response: FetchCustomDepreciationResponse) => ({
        List: response.List.map(item => ({
          ...item,
          SP_YMO_FRM: formatISODate(item.SP_YMO_FRM),
          SP_YMO_TO: formatISODate(item.SP_YMO_TO),
        })),
        Method: response.Method,
      }),
    }),
    createCustomDepreciation: build.mutation<void, CustomDepreciationWrapper>({
      query: payload => ({
        url: 'DeprecSP/Create',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['customDepreciation', 'assetHistory'],
    }),
    editCustomDepreciation: build.mutation<void, EditCustomDepreciationWrapper>({
      query: payload => ({
        url: 'DeprecSP/Edit',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['customDepreciation', 'assetHistory'],
    }),
    deleteCustomDepreciation: build.mutation<void, CustomDepreciationWrapper>({
      query: payload => ({
        url: 'DeprecSP/Delete',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['customDepreciation', 'assetHistory'],
    }),
    doesCustomDepreciationNeedRecalc: build.query<
      AssetRecalcDTO,
      CustomDepreciationRecalcPayload
    >({
      query: payload => ({
        url: `DeprecSP/DeprecSPNeedsRecalc?${serializeURLParams(payload)}`,
      }),
      transformResponse: (recalcDTO: AssetRecalcDTO) => ({
        ...recalcDTO,
        PostingDate: formatISODate(moment().toISOString()),
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchCustomDepreciationQuery,
  useCreateCustomDepreciationMutation,
  useEditCustomDepreciationMutation,
  useDeleteCustomDepreciationMutation,
  useLazyDoesCustomDepreciationNeedRecalcQuery,
} = customDepreciationEndpoints
