import { StyleObject } from '@styles-api/css.types'

// main wrapper
export const wrapper: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 'var(--size-default)',
  borderBottomWidth: '1px',
}

export const labelText: StyleObject = {
  fontWeight: 'var(--font-weight-semiBold)',
  marginRight: 'var(--scale200)',
}
export const toggle__labelStyles: StyleObject = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  borderRadius: '50px',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '2px',
  backgroundColor: 'var(--shade200)',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05) inset;',
  ':focus': {
    outline: 'none',
  },
}
// container for the switch
export const toggleContainerStyles = ({ focus }) => {
  return {
    height: 'var(--scale600)',
    width: 'calc(var(--scale600) * 1.6)',
    position: 'relative',
    borderRadius: '50px',
    boxShadow: focus ? 'var(--focus-shadow)' : 'none',
    borderWidth: '1px',
    borderColor: focus ? 'var(--primary-color)' : 'var(--transparent)',
    boxSizing: 'content-box',
  }
}
export const toggle__hiddenInputStyles: StyleObject = {
  overflow: 'hidden',
  visibility: 'hidden',
  height: '0px',
  opacity: 0,
  position: 'absolute',
  zIndex: -1,
}
export const toggle__switchStyles = ({ focus }) => {
  return {
    height: 'calc(var(--scale600) - 4px)',
    width: 'calc(var(--scale600) - 4px)',
    background: 'var(--base-color)',
    display: 'block',
    borderRadius: '40px',
    boxShadow: 'var(--default-btn-shadow)',
  }
}

/* -------------------- STATE STYLES -------------------- */
export const toggle_on__labelStyles: StyleObject = {
  justifyContent: 'flex-end',
  backgroundColor: 'var(--success-color)!important',
}
