import React from 'react'
import { useStyletron } from 'styletron-react'
import { GridProps, GridSubComponents } from './Grid.types'
import GridItem from './GridItem'

import * as styles from './Styles'

const Grid: React.FC<GridProps> & GridSubComponents = ({
  gap = 'default',
  colGap,
  rowGap,
  ...props
}) => {
  const [css] = useStyletron()

  let gridStyles = {
    ...styles.baseGrid,
    ...styles.getGap({ gap, colGap, rowGap }),
  }
  let gridClassName = css(gridStyles)

  return <div className={gridClassName}>{props.children}</div>
}

Grid.Item = GridItem

export default Grid
