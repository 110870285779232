import React from 'react'
import { FieldsetProps } from './Fieldset.types'

import { useStyletron } from 'styletron-react'
import {
  FieldsetStyles,
  fieldset_flexStyles,
  fieldset__legend_centerStyles,
} from './Styles'

const Fieldset: React.FC<FieldsetProps> = ({
  legend,
  legendCenter = false,
  ...props
}) => {
  /* ====================================================== */
  /*                      APPLY STYLES                      */
  /* ====================================================== */
  const [css] = useStyletron()
  var baseStyles = css(FieldsetStyles)

  if (props.flex === true) {
    baseStyles += ' ' + css(fieldset_flexStyles)
  }

  /* ====================================================== */
  /*                         MARKUP                         */
  /* ====================================================== */
  return (
    <fieldset className={baseStyles} {...props}>
      {legend && (
        <legend
          className={`${legendCenter ? css(fieldset__legend_centerStyles) : ''}`}
        >
          {legend}
        </legend>
      )}
      {props.children}
    </fieldset>
  )
}

export default Fieldset
