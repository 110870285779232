import { Company, DEPREC_BASIS_OPTIONS, FieldLabels } from '@api/models'
import DataDisplayList from '@components/DataDisplayList'

interface Props {
  company: Company
  fields: FieldLabels
}

const CompanyDeprecInfoDisplay = ({ company, fields }: Props) => {
  const items = [
    {
      label: 'Year end',
      value: `${company?.CM_YE_MM < 10 ? '0' : ''}${company?.CM_YE_MM}/${company?.CM_YE_DD}`,
    },
    {
      label: 'Number of periods',
      value: company?.CM_NR_PER,
    },
    {
      label: fields.CM_YTD_BCD,
      value: `${DEPREC_BASIS_OPTIONS.find(x => x.value === company?.CM_YTD_BCD)?.label}`,
    },
    {
      label: 'Maintains tax records',
      value: `${company?.CM_TAX ? 'Yes' : 'No'}`,
    },
    {
      label: 'Maintains state tax records',
      value: `${company?.CM_SMH ? 'Yes' : 'No'}`,
    },
    {
      label: 'Maintains additional (other) tax records',
      value: `${company?.CM_B4MH ? 'Yes' : 'No'}`,
    },
  ]

  if (company?.CM_SMH) {
    items.push({
      label: 'Default state code for state tax records',
      value: company?.CM_STAT_CD,
    })
  }

  if (company?.CM_B4MH) {
    items.push({
      label: 'Default code for additional (other) tax records',
      value: company?.CM_BOK4_CD,
    })
  }

  return <DataDisplayList items={items} columns={2} />
}

export default CompanyDeprecInfoDisplay
