import { createUserManager } from "redux-oidc";
import { UserManagerSettings } from "oidc-client";

const userManagerConfig: UserManagerSettings = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  response_type: "code",
  scope: process.env.REACT_APP_SCOPE,
  authority: process.env.REACT_APP_AUTHORITY,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
