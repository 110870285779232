import { PropsWithChildren, useCallback } from 'react'
import PermissionContext from './PermissionContext'
import {
  CAN_EDIT_ASSETS,
  CAN_EDIT_COMPANIES,
  CAN_EDIT_SETTINGS,
  CAN_RUN_DEPRECIATION,
  CAN_RUN_REPORTS,
  CAN_VIEW_ASSETS,
  UserRole,
  UserRoleGrant,
} from '../UserRole'
import useAuth from '@hooks/useAuth'

const PermissionProvider = ({ children }: PropsWithChildren<any>) => {
  const { roles, isAuthenticated } = useAuth()

  //what my user is allowed to do based on their role and the permissions
  const checkUserGrants = (userRoles: UserRoleGrant, grants?: UserRoleGrant): boolean => {
    // ORIGINAL
    if (!grants) return true

    return userRoles.some((role: UserRole) => grants.includes(role))

    // // // MODIFIED - I can select what my role is for testing purposes
    // let myRole = [
    //   // UserRole.FIXED_ASSETS_READ_ONLY,
    //   // UserRole.FIXED_ASSETS_GENERAL_USER,
    //   // UserRole.ADMIN
    //   UserRole.FIXED_ASSETS_ADMIN,
    // ]

    // if (!grants) return true

    // return myRole.some((role: UserRole) => grants.includes(role))
  }

  const isAuthorized = useCallback(
    (grants: UserRoleGrant): boolean => {
      if (isAuthenticated && roles) {
        return checkUserGrants(roles, grants)
      }
      return false
    },
    [roles, isAuthenticated]
  )

  return (
    <PermissionContext.Provider
      value={{
        isAuthorized,
        canEditAssets: isAuthorized(CAN_EDIT_ASSETS),
        canViewAssets: isAuthorized(CAN_VIEW_ASSETS),
        canEditCompanies: isAuthorized(CAN_EDIT_COMPANIES),
        canEditSettings: isAuthorized(CAN_EDIT_SETTINGS),
        canRunDepreciation: isAuthorized(CAN_RUN_DEPRECIATION),
        canRunReports: isAuthorized(CAN_RUN_REPORTS),
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export default PermissionProvider
