import { StyleObject } from '@styles-api/css.types'

export const popMenu__wrapperStyles: StyleObject = {
  display: 'inline-block',
  position: 'relative',
  width: 'max-content',
}

export const popMenu__menuStyles: StyleObject = {
  position: 'absolute',
  boxShadow: 'var(--shadow2)',
  borderRadius: 'var(--radius2)',
  backgroundColor: 'var(--elevated-surface-background1)',
  width: '220px',
  zIndex: 'var(--z2)',
  borderWidth: '1px',
  paddingTop: 'var(--scale200)',
  paddingBottom: 'var(--scale200)',
}
export const popMenu_primary__menuStyles: StyleObject = {
  background: 'var(--primaryColor)',
  color: 'var(--primaryColorContrastText)',
}
export const popMenu__itemStyles: StyleObject = {
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  minHeight: 'var(--size-compact)',
  paddingLeft: 'var(--scale600)',
  paddingRight: 'var(--scale600)',
  backgroundColor: 'var(--elevated-surface-background1)',
  ':hover': {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--interaction-contrast)',
  },
}
export const popMenu__triggerWrapper: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}
export const popMenu__dividerStyles: StyleObject = {
  width: 'calc(100% + var(--gapS) + var(--gapS))',
  display: 'block',
  background: 'var(--borderColor)',
  margin: 'var(--gapS) auto',
  height: '1px',
  transform: 'translateX(calc(var(--gapS) * -1))',
}
export const popMenu__item__iconStyles: StyleObject = {
  marginRight: '8px',
  height: '20px',
}
/* ---------------------- POSITIONS --------------------- */
export const popMenu_bottomLeftStyles: StyleObject = {
  left: 0,
  marginTop: '6px',
}
export const popMenu_bottomRightStyles: StyleObject = {
  right: 0,
  marginTop: '6px',
}
export const popMenu_rightStartStyles: StyleObject = {
  right: 'calc(var(--gapS) * -1)',
  top: 0,
}
export const popMenu_rightEndStyles: StyleObject = {
  right: 'calc(var(--gapS) * -1)',
}
export const popMenu_leftStartStyles: StyleObject = {
  left: 'calc(var(--gapS) * -1)',
  top: 0,
}
export const popMenu_leftEndStyles: StyleObject = {
  left: 'calc(var(--gapS) * -1 )',
}
