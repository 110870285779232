import { CustomDepreciation, FieldLabels } from '@api/models'
import { Button, Table } from '@components/common'
import { formatCurrency, formatDisplayDate } from '@utils/basic'
import stopPropagation from '@utils/stopPropagation'
import { useGetSettingsQuery } from '@api'
import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { useTable, useFlexLayout, Column } from 'react-table'

interface CustomDepreciationTableProps {
  customDepreciationList: CustomDepreciation[]
  openEditForm: (customDepreciation: CustomDepreciation) => void
  openDeleteForm: (customDepreciation: CustomDepreciation) => void
  fieldLabels: FieldLabels
  depreciationMethod: string
  readonly: boolean
  canEdit: boolean
}

const CustomDepreciationTable = ({
  customDepreciationList,
  openEditForm,
  openDeleteForm,
  fieldLabels,
  depreciationMethod,
  readonly,
  canEdit,
}: CustomDepreciationTableProps) => {
  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  const columns = useMemo<Column<CustomDepreciation>[]>(
    () => [
      {
        Header: fieldLabels.SP_YMO_FRM,
        accessor: 'SP_YMO_FRM',
        Cell: props =>
          props.row.original.SP_MET_CD === 'P'
            ? `${props.row.original.SP_YOFL} (years of life)`
            : formatDisplayDate(props.row.original.SP_YMO_FRM, DateFormat),
      },
      {
        Header: fieldLabels.SP_YMO_TO,
        accessor: 'SP_YMO_TO',
        Cell: props =>
          props.row.original.SP_MET_CD === 'P'
            ? ''
            : formatDisplayDate(props.row.original.SP_YMO_TO, DateFormat),
      },
      {
        Header: depreciationMethod === 'P' ? 'Units' : 'Rate/Amount',
        accessor: 'SP_DEP_AMT',
        Cell: props => {
          if (props.row.original.SP_SUB_CMT === 'R') {
            return `${new BigNumber(props.row.original.SP_RATE).toFixed(4)}%`
          }

          if (props.row.original.SP_MET_CD === 'U') return props.row.original.SP_DEP_AMT

          return formatCurrency(props.row.original.SP_DEP_AMT)
        },
      },
      {
        Header: '',
        accessor: 'SP_OBJ_ID',
        width: 80,
        Cell: props =>
          !canEdit || readonly ? (
            ''
          ) : (
            <Button
              appearance="text"
              onClick={e => {
                stopPropagation(e)
                openDeleteForm(props.row.original)
              }}
            >
              Delete
            </Button>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable(
    {
      columns,
      data: customDepreciationList,
    },
    useFlexLayout
  )

  return (
    <Table
      {...getTableProps()}
      label="Custom depreciation table"
      aria-label="Custom depreciation table"
    >
      <Table.Head>
        {headerGroups.map((headerGroup, i) => (
          <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => (
              <Table.Header
                {...column.getHeaderProps()}
                column={column}
                key={i}
                tabIndex={0}
              >
                {column.render('Header')}
              </Table.Header>
            ))}
          </Table.HeadRow>
        ))}
      </Table.Head>
      <Table.Body {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <Table.Row
              {...row.getRowProps()}
              tabIndex={0}
              onClick={() => openEditForm(row.original)}
            >
              {row.cells.map(cell => {
                return (
                  <Table.Cell {...cell.getCellProps()} column={cell.column}>
                    {cell.render('Cell')}
                  </Table.Cell>
                )
              })}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default CustomDepreciationTable
