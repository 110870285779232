import { DepreciationClass, ExtraFieldsMap, FieldLabels } from '@api/models'
import DataDisplayList from '@components/DataDisplayList'
import { formatCurrency } from '@utils/basic'

interface Props {
  fields: FieldLabels
  deprec: DepreciationClass
  extraFieldsLabels: ExtraFieldsMap
  isNonLinearDepMethodSelected: boolean
  isNoDepMethodSelected: boolean
  deductFromAcq: boolean
  deductFromBookValue: boolean
  lifetimeLabel: string
}

const DeprecClassDeprecInfoDisplay = ({
  fields,
  deprec,
  extraFieldsLabels,
  deductFromAcq,
  deductFromBookValue,
  lifetimeLabel,
}: Props) => {
  const isMethodNonLinear = ['C', 'D'].includes(deprec.DP_MET_CD)

  const items = [
    {
      label: fields.DP_MET_CD,
      value: extraFieldsLabels.depreciationMethod[deprec.DP_MET_CD],
    },
    {
      label: fields.DP_STRT_CD,
      value: extraFieldsLabels.startCode[deprec.DP_STRT_CD],
    },
    {
      label: lifetimeLabel,
      value: deprec.DP_LIFETIM,
    },
    {
      label: fields.DP_RATE,
      value:
        extraFieldsLabels.deprecRateCode[deprec.DP_RATE_CD] +
        (isMethodNonLinear ? ` (${deprec.DP_RATE}%)` : ''),
    },
    {
      label: fields.DP_ACPC_ID,
      value: extraFieldsLabels.glPost[deprec.DP_ACPC_ID],
    },
    {
      label: fields.DP_DSPC_ID,
      value: extraFieldsLabels.glPost[deprec.DP_DSPC_ID],
    },
    {
      label: fields.DP_DPPC_ID,
      value: extraFieldsLabels.glPost[deprec.DP_DPPC_ID],
    },
    {
      label: fields.DP_DGPC_ID,
      value: extraFieldsLabels.glPost[deprec.DP_DGPC_ID],
    },
  ]

  if (deductFromAcq) {
    items.push(
      {
        label: 'Year 1 Tax Credit Amount',
        value: formatCurrency(deprec.DP_TAX_CRD),
      },
      {
        label: fields.DP_TAX_UCD,
        value: deprec.DP_TAX_UCD,
      }
    )
  } else {
    items.push({
      label: 'Year 1 Tax Credit - deduct from acquisition cost',
      value: deductFromAcq ? 'Yes' : 'No',
    })
  }

  if (deductFromBookValue) {
    items.push(
      {
        label: 'Year 1 Depreciation Add-on Amount',
        value: formatCurrency(deprec.DP_INT_DED),
      },
      {
        label: fields.DP_INT_UCD,
        value: deprec.DP_INT_UCD,
      }
    )
  } else {
    items.push({
      label: 'Year 1 Depreciation add-on - deduct from book value',
      value: deductFromBookValue ? 'Yes' : 'No',
    })
  }

  return <DataDisplayList items={items} columns={2} />
}
export default DeprecClassDeprecInfoDisplay
