import { StyleObject } from '@styles-api/css.types'

export const tagStyles: StyleObject = {
  display: 'inline-flex',
  alignItems: 'center',
  minHeight: 'var(--scale700)',
  borderRadius: 'var(--pill-radius)',
  fontWeight: 'var(--font-weight-semiBold)',
  overflow: 'hidden',
  marginRight: 'var(--gap-mini)',
  marginTop: 'var(--gap-mini)',
  color: 'var(--primary-color)',
}
export const tag__contentStyles = (canRemove: boolean): StyleObject => {
  return {
    paddingLeft: 'var(--scale500)',
    paddingRight: canRemove ? 'var(--scale100)' : 'var(--scale500)',
  }
}
export const getAppearance = ({ appearance }): StyleObject => {
  if (appearance === 'default')
    return {
      backgroundColor: 'var(--primary-color100)',
    }
  if (appearance === 'outline')
    return {
      borderWidth: '1px',
      borderColor: 'var(--primary-color)',
    }
}
export const tag__removeButtonStyles: StyleObject = {
  height: 'var(--scale700)',
  width: 'var(--scale700)',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'var(--button-hover-transition)',
  ':hover': {
    backgroundColor: 'var(--primary-color200)',
    color: 'var(--primary-color50)',
  },
  ':active': {
    backgroundColor: 'var(--primary-color50)',
    ':hover': {
      backgroundColor: 'var(--primary-color50)',
    },
  },
}
export const tag__removeButton__iconStyles: StyleObject = {
  height: 'var(--scale500)',
}
