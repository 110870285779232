import { AuthProvider as OidcAuthProvider, AuthProviderProps } from 'oidc-react'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * AuthProvider to wrap the entire app using the oidc-react package.
 *
 */
const AuthProvider = ({ children }: { children?: ReactNode | undefined }): JSX.Element => {
  const navigate = useNavigate()

  const oidcConfig: AuthProviderProps = {
    authority: process.env.REACT_APP_AUTHORITY,
    clientId: process.env.REACT_APP_CLIENT_ID,
    scope: process.env.REACT_APP_SCOPE,
    responseType: 'code',
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    silentRedirectUri: process.env.REACT_APP_SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
    loadUserInfo: true,
    onSignIn: () => {
      // TODO --> Redirect to the proper page based on landing url.
      navigate('/assets')
    },
  }

  return <OidcAuthProvider {...oidcConfig}>{children}</OidcAuthProvider>
}
export default AuthProvider
