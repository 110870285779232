import { ReactSelectOptions } from '@components/common/Select/Select.types'

export const DEFAULT_COMPANY = {
  CM_YTD_BCD: 'D',
  CM_YE_MM: 12,
  CM_YE_DD: 31,
  CM_NR_PER: 12,
}
export const DEFAULT_PERIOD = { cP_FRWD: false, cP_WEEKEND: true }
export const DEFAULT_SEGMENT = {}
export const DEFAULT_GLPOST = {}
export const DEFAULT_DEPREC = {
  DP_TYP_CD: 'C',
  DP_MET_CD: 'S',
  DP_RATE_CD: null,
}
export const DEFAULT_VENDOR = {}
export const DEFAULT_FOLDER = {}
export const DEFAULT_ACTIVITY = {}
export const DEFAULT_INSURANCE = {}
export const DEFAULT_MAINTENANCE = {}
export const WRITE_OFF_CODES: ReactSelectOptions = [
  { label: 'Next Run', value: 'R' },
  { label: 'Next Year', value: 'Y' },
]
export const DISPOSITION_OPTIONS: ReactSelectOptions = [
  { label: 'Capitalize', value: 'C' },
  { label: 'Expense', value: 'E' },
  { label: 'Do Not Post', value: 'D' },
]
export const PROPERTY_CODES: ReactSelectOptions = [
  { label: 'Listed Property', value: 'L' },
  { label: 'Regular Property', value: 'R' },
]
export const BUILDING_CODES: ReactSelectOptions = [
  { label: 'Building (CAN)', value: 'L' },
  { label: 'Non-Residential (USA)', value: 'N' },
  { label: 'Residential Rental (USA)', value: 'R' },
]
export const DEPREC_PROFILES = {
  CORPORATE: 'C',
  TAX: 'T',
  STATE: 'S',
  OTHER: 'O',
}

export const STATUS_MAP = {
  A: { status: 'Active', type: 'success' },
  P: { status: 'Pending', type: 'warning' },
  N: { status: 'Non-Active', type: 'primary' },
  D: { status: 'Disposed', type: 'danger' },
}

// Form id's
export const ASSET_SERVICE_FORM_ID = 'asset_service_form'
export const ASSET_TASK_FORM_ID = 'asset_task_form'
export const ASSET_LOCATION_FORM_ID = 'asset_location_form'
export const ASSET_RECALC_FORM_ID = 'asset_recalc_form'
export const ASSET_DETAILS_FORM_ID = 'asset_details_form'
export const ASSET_FORM_ID = 'asset_form'
export const ASSET_DISPOSAL_FORM_ID = 'asset_disposal_form'
export const ASSET_REINSTATEMENT_FORM_ID = 'asset_reinstatement_form'
export const ASSET_STRUCTURE_TRANSFER_FORM_ID = 'asset_structure_form'
export const ASSET_CHARGE_FORM_ID = 'asset_charge_form'

export const ASSET_SPECIFIC_DEPRECIATION_FORM_ID = 'asset_specific_depreciation_form_id'
export const DELETE_DEPRECIATION_CLASS_FORM_ID = 'delete_depreciation_class_form'
export const DEPRECIATION_PROFILE_FORM_ID = 'depreciation_profile_form'
export const DEPRECIATION_CLASS_FORM_ID = 'depreciation_class_form'

export const ADD_COMPANY_FORM_ID = 'add_company_form'

export const COMPANY_FORM_ID = 'company_form'
export const COMPANY_PERIOD_FORM_ID = 'company_period_form'
export const COMPANY_GENERATE_PERIODS_FORM_ID = 'company_generate_periods_form'

export const DEPRECIATION_FORM_ID = 'depreciation_form'
export const DEPREC_RECALC_FORM_ID = 'deprec_recalc_form'
export const FOLDER_FORM_ID = 'folder_form'
export const GLPOST_FORM_ID = 'gl_post_form'
export const INSURANCE_FORM_ID = 'insurance_form'
export const MAINTENANCE_FORM_ID = 'maintenance_form'
export const SEGMENT_FORM_ID = 'segment_form'
export const SEGMENT_TYPE_FORM_ID = 'segment_type_form'
export const TASK_FORM_ID = 'task_form'
export const VENDOR_FORM_ID = 'vendor_form'
export const MANUAL_HISTORY_FORM_ID = 'manual_history_form'
export const DEPRECIATION_DISCARD_RUN_FORM_ID = 'depreciation_discard_run_form'
export const CHANGE_PASSWORD_FORM_ID = 'change_password'

// settings
export const FIELD_PARM_ID = 'field_parm_form'
export const EDIT_ASSETID_FORM_ID = 'edit_assetid_form'

//account settings
export const ACCOUNT_FORM_ID = 'account_form'

//custom date setting
export const CUSTOM_DATE_FORM_ID = 'custom_date_form'

// Report form id
export const COMPANY_LIST_FORM_ID = 'company_list_form'

export const ADMIN_INSURANCE_REPORT_FORM_ID = 'admin_insurance_report_form'

export const CUSTOM_DEPRECIATION_FORM_ID = 'custom_depreciation_form'
export const CUSTOM_DEPRECIATION_RECALC_FORM_ID = 'custom_depreciation_recalc_form'
export const DELETE_CUSTOM_DEPRECIATION_FORM_ID = 'delete_custom_depreciation_form'

export const COMMENT_FORM_ID = 'comment_form'

export const ADD_ATTACHMENT_FORM_ID = 'add_attachment_form'
export const EDIT_ATTACHMENT_FORM_ID = 'edit_attachment_form'

export const EDIT_ASSET_LOCATION_DETAILS_FORM_ID = 'edit_asset_location_details_form'

export const USER_INVITE_FORM_ID = 'INVITE_USER_FORM_ID'
export const APP_ROLE_FORM_ID = 'APP_ROLE_FORM_ID'
