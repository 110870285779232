import { LocationDetails } from '@api/models'
import { Grid, Text } from '@components/common'
import { isNullOrUndefined } from '@utils/basic'
import { LatLngTuple } from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

interface AssetLocationMapProps {
  location: LocationDetails
}

const AssetLocationMap = ({ location }: AssetLocationMapProps) => {
  const position: LatLngTuple = [location.Latitude, location.Longitude]

  const cityStateCountry = [location.City, location.State, location.Country]
    .filter(x => !isNullOrUndefined(x))
    .join(', ')

  return (
    <MapContainer
      key={`${location.Latitude}-${location.Longitude}`}
      style={{ height: '450px' }}
      center={position}
      zoom={14}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          <Grid rowGap="mini">
            <Grid.Item>
              <Text bold>{location.Address}</Text>
            </Grid.Item>
            <Grid.Item>
              <Text light>{cityStateCountry}</Text>
            </Grid.Item>
            <Grid.Item>
              <Text light>{location.PostalCode}</Text>
            </Grid.Item>
          </Grid>
        </Popup>
      </Marker>
    </MapContainer>
  )
}

export default AssetLocationMap
