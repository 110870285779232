import { Flexbox, Spacer, TextSkeleton } from '@components/common'
import { skeleton } from './styles'
import { useStyletron } from 'styletron-react'

const DashboardItemLoading = () => {
  const [css] = useStyletron()

  return (
    <Flexbox justifyContent="space-between" flexDirection="column" alignItems="center">
      <div style={{ marginRight: 'auto' }}>
        <TextSkeleton width="200px" height="var(--scale850)" />
      </div>
      <Spacer y="large" />
      <div style={{ width: '100px', height: '32px', scale: '1.8' }}>
        <TextSkeleton width="100%" height="100%" />
      </div>
      <Spacer y="large" />

      <div
        className={css(skeleton({ width: '100%', height: '300px' }))}
        style={{ zIndex: 'var(--z2)' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="300"
          height="300"
          viewBox="0 0 36 36"
          aria-hidden="true"
          role="img"
          style={{
            zIndex: 'var(--z1)',
            opacity: 0.1,
            borderRadius: 'var(--radius4)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -30%)',
          }}
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            fill="#CCD6DD"
            d="M31 2H5a3 3 0 0 0-3 3v26a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3z"
          ></path>
          <path
            fill="#E1E8ED"
            d="M31 1H5a4 4 0 0 0-4 4v26a4 4 0 0 0 4 4h26a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4zm0 2c1.103 0 2 .897 2 2v4h-6V3h4zm-4 16h6v6h-6v-6zm0-2v-6h6v6h-6zM25 3v6h-6V3h6zm-6 8h6v6h-6v-6zm0 8h6v6h-6v-6zM17 3v6h-6V3h6zm-6 8h6v6h-6v-6zm0 8h6v6h-6v-6zM3 5c0-1.103.897-2 2-2h4v6H3V5zm0 6h6v6H3v-6zm0 8h6v6H3v-6zm2 14c-1.103 0-2-.897-2-2v-4h6v6H5zm6 0v-6h6v6h-6zm8 0v-6h6v6h-6zm12 0h-4v-6h6v4c0 1.103-.897 2-2 2z"
          ></path>
          <path fill="#5C913B" d="M13 33H7V16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v17z"></path>
          <path fill="#3B94D9" d="M29 33h-6V9a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v24z"></path>
          <path fill="#DD2E44" d="M21 33h-6V23a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v10z"></path>
        </svg>
      </div>
    </Flexbox>
  )
}
export default DashboardItemLoading
