// TODO: Vendor Contacts
// TODO: Vendor Category IDs and Class Codes

import { Button, Flexbox, Heading, Modal, Spacer } from '@components/common'
import { VENDOR_FORM_ID } from '@constants'

import VendorForm from './VendorForm'
import VendorsTable from './VendorsTable'
import useVendors from './useVendors'
import usePermission from '@components/Auth/Permissions/usePermission'
import { download } from '@utils/basic'
import ImportData from '@components/ImportData'

const Vendors = () => {
  const {
    createVendorModalIsOpen,
    openCreateVendorModal,
    handleCreateVendor,
    closeCreateVendorModal,
    selectedVendor,
    isLoadingCreateVendor,
  } = useVendors()

  const { canEditSettings: canEdit } = usePermission()

  const importButton = (
    <Button
      onClick={() => download(`${process.env.PUBLIC_URL}/templates/vendors.csv`, 'vendors.csv')}
      appearance="text"
    >
      Download an import template here.
    </Button>
  )

  return (
    <>
      <header>
        <Flexbox justifyContent="space-between">
          <Heading type="h1">Vendors</Heading>
          <Flexbox alignItems='center' flexDirection='row' gap={12}>
            <ImportData action='vendors' title='Import Vendors' modalDesc={importButton} />
            <Button onClick={openCreateVendorModal} appearance="primary" hidden={!canEdit}>
              Add vendor
            </Button>
          </Flexbox>
        </Flexbox>
      </header>

      <Spacer y="default" />

      <VendorsTable {...useVendors()} canEdit={canEdit} />

      <Modal
        isOpen={createVendorModalIsOpen}
        title={`Add a new vendor`}
        form={VENDOR_FORM_ID}
        close={closeCreateVendorModal}
        actionLabel={'Add vendor'}
        isActionLoading={isLoadingCreateVendor}
        width={576}
      >
        <VendorForm
          handleSubmit={handleCreateVendor}
          isEdit={false}
          initialValues={selectedVendor}
        />
      </Modal>
    </>
  )
}
export default Vendors
