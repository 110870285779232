export const ERROR_REQUEST = 'Request error.'
export const ERROR_SAVE = 'An error happened during saving.'
export const ERROR_DELETE = 'An error happened during deletion.'
export const ERROR_UPLOAD_FILE = 'Error while uploading the file.'
export const SAVE_COMPLETED = 'Saved successfully.'
export const IMPORT_COMPLETED = 'File has been uploaded successfully.'
export const DELETE_SUCCESS = 'Deleted successfully.'
export const DUPLICATE_SUCCESS = 'Asset duplicated successfully.'
export const DISPOSE_SUCCESS = 'Disposed successfully.'
export const REINSTATE_SUCCESS = 'Reinstated successfully.'
export const REQUIRED_FIELD = 'This field is required'
export const CREATE_SUCCESS = 'Created successfully.'
export const MUST_BE_NUMBER = 'Must be a number'