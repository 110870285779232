import React, { useState } from 'react'
import { InputProps } from './Input.types'

import BaseInput from './BaseInput'
import PasswordInput from './PasswordInput'

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',

      label,
      size = 'default',
      error = false,
      helper,
      errorMsg,
      disabled,
      icon,
      iconRight,
      appearance = 'default',
      width = '100%',
      ...props
    },
    ref
  ) => {
    const [passwordShown, ] = useState(false)
    props.step = props.step || 'any'

    if (type === 'password') {
      return (
        <PasswordInput
          size={size}
          icon={icon}
          iconRight={iconRight}
          helper={helper}
          error={error}
          errorMsg={errorMsg}
          name={props.name}
          type={passwordShown ? 'text' : 'password'}
          label={label}
          id={props.id}
          onChange={props.onChange}
          value={props.value}
          disabled={disabled}
          maxLength={props.maxLength}
          minLength={props.minLength}
          autoFocus={props.autoFocus}
          required={props.required}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          pattern={props.pattern}
          readOnly={props.readOnly}
          placeholder={props.placeholder}
          appearance={appearance}
          ref={ref}
          aria-labelledby={
            props['aria-labelledby'] ? props['aria-labelledby'] : `${props.name}-label`
          }
          width={width}
          flexGrow={props.flexGrow}
        />
      )
    }

    return (
      <BaseInput
        size={size}
        icon={icon}
        iconRight={iconRight}
        appearance={appearance}
        helper={helper}
        error={error}
        errorMsg={errorMsg}
        name={props.name}
        type={type}
        label={label}
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        disabled={disabled}
        maxLength={props.maxLength}
        minLength={props.minLength}
        autoFocus={props.autoFocus}
        required={props.required}
        onKeyPress={props.onKeyPress}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        step={type === 'number' && props.step}
        min={type === 'number' && props.min}
        max={type === 'number' && props.max}
        pattern={props.pattern}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        ref={ref}
        aria-labelledby={
          props['aria-labelledby'] ? props['aria-labelledby'] : `${props.name}-label`
        }
        onBlur={props.onBlur}
        width={width}
        defaultValue={props.defaultValue}
        flexGrow={props.flexGrow}
        autocomplete={props.autocomplete}
      />
    )
  }
)
export default Input
