import { Asset, FieldLabels } from '@api/models'
import { ToggleSwitch } from '@components/common'
import { ColumnInstance } from 'react-table'

interface Props {
  tableColumns: ColumnInstance<Asset>[]
  fields: FieldLabels
}

const SelectColumns = ({ tableColumns, fields }: Props) => {
  const sortedColumns: ColumnInstance<Asset>[] = tableColumns.sort(function (a, b) {
    var textA = a.Header
    var textB = b.Header
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })

  return (
    <div>
      {sortedColumns.map((column, index) => (
        <ToggleSwitch
          onChange={() => column.toggleHidden()}
          label={fields[column.id]}
          key={column.id}
          name={fields[column.id]}
          id={fields[column.id]}
          checked={column.isVisible}
          autoFocus={index === 0}
        />
      ))}
    </div>
  )
}
export default SelectColumns
