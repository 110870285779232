import { FieldLabels, MaintainedDepProfiles } from '@api/models'
import { Asset, AssetKey, assetSchema } from '@api/assets/models'

import { useMaintainedDepProfilesQuery } from '@api'
import { useAddNewAssetMutation } from '@api/assets/assetsEndpointsHooks'
import { useNavigate } from 'react-router-dom'
import { toast } from '@components/common'
import { ASSET_PATHS, ASSETS_PATH } from '@constants'
import useFields from '@hooks/useFields'
import HttpStatusCode from '@components/common/Helper/HttpStatusCode'

interface IAddAssetForm {
  fields: FieldLabels
  schema: ReturnType<typeof assetSchema>
  identifiersLabel: string
  identifiersFieldNames: Array<AssetKey>
  purchaseDetailsLabel: string
  purchaseDetailsFieldNames: Array<AssetKey>
  deprProfilesLabel: string
  deprProfilesFieldNames: Array<AssetKey>
  isLoadingAddNewAsset: boolean
  isLoading: boolean
  maintainedDepProfiles: MaintainedDepProfiles
  handleAddAsset: (payload: Asset) => Promise<void>
}

interface UseAddAssetFormHook {
  assetCompanyID: number
}

export default function useAddAssetForm({
  assetCompanyID
}: UseAddAssetFormHook): IAddAssetForm {
  // Add asset mutation
  const [addNewAsset, { isLoading: isLoadingAddNewAsset }] = useAddNewAssetMutation()
  const {
    data: maintainedDepProfiles,
    isLoading: isLoadingMaintainedDepProfiles,
  } = useMaintainedDepProfilesQuery(assetCompanyID)
  const { fields, isLoading: isLoadingFields } = useFields()
  const navigate = useNavigate()

  async function handleAddAsset(payload: Asset): Promise<void> {
    try {
      await addNewAsset(payload).unwrap()

      toast.success('Successfully added new asset.')
      navigate(
        `/${ASSETS_PATH}/${assetCompanyID}/${payload.AssetID}/${ASSET_PATHS.DETAILS}`
      )
    } catch (error: any) {
      if (error && error.originalStatus === HttpStatusCode.UNPROCESSABLE_ENTITY && error.data) {
        toast.error(error.data)
        return;
    }
      toast.error('There was an error adding this asset.')
    }
  }

  // Labels and field names
  const identifiersLabel = 'Identifiers'
  const identifiersFieldNames: Array<AssetKey> = [
    'AssetID',
    'AS_DESC',
    'AS_DESC_E',
    'AS_SERIAL',
    'AS_PAR_ASS',
    'AS_LEASE',
  ]

  const purchaseDetailsLabel = 'Book Information'
  const purchaseDetailsFieldNames: Array<AssetKey> = [
    'AS_FIN_COS',
    'AS_REC_PON',
    'AS_REC_DAT',
    'AS_REP_DAT',
    'AS_FIN_SAL',
    'AS_TAX_BUS',
    'AS_VEN_ID',
    'AS_VEN_NM',
    'AS_REC_USR',
  ]

  const isLoading = isLoadingMaintainedDepProfiles || isLoadingFields
  const schema = isLoading
    ? null
    : assetSchema(assetCompanyID.toString(), maintainedDepProfiles)

  const deprProfilesLabel = 'Depreciation Profiles'
  const deprProfilesFieldNames: Array<AssetKey> = ['AS_CAT_ID']

  if (!isLoading) {
    if (maintainedDepProfiles.IsTaxMaintained) deprProfilesFieldNames.push('AS_TCLS_ID')
    if (maintainedDepProfiles.IsStateTaxMaintained)
      deprProfilesFieldNames.push('AS_SCLS_ID')
    if (maintainedDepProfiles.IsOtherTaxMaintained)
      deprProfilesFieldNames.push('AS_4CLS_ID')
  }

  return {
    maintainedDepProfiles,
    schema,
    identifiersFieldNames,
    identifiersLabel,
    purchaseDetailsLabel,
    purchaseDetailsFieldNames,
    deprProfilesFieldNames,
    deprProfilesLabel,
    isLoadingAddNewAsset,
    isLoading,
    handleAddAsset,
    fields,
  }
}
