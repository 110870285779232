import { LocationDetails, FieldLabels } from '@api/models'
import DataDisplayList from '@components/DataDisplayList'
import { useFormContext } from 'react-hook-form'

interface Props {
  fields: FieldLabels
}

const AssetLocationDetailsDisplay = ({ fields }: Props) => {
  const { watch } = useFormContext<LocationDetails>()

  const items = [
    {
      label: fields.LocationName,
      value: watch('LocationName'),
    },
    {
      label: fields.Latitude,
      value: watch('Latitude'),
    },
    {
      label: fields.Longitude,
      value: watch('Longitude'),
    },
    {
      label: fields.Address,
      value: watch('Address'),
    },
    {
      label: fields.Country,
      value: watch('Country'),
    },
    {
      label: fields.City,
      value: watch('City'),
    },
    {
      label: fields.State,
      value: watch('State'),
    },

    {
      label: fields.PostalCode,
      value: watch('PostalCode'),
    },
    {
      label: fields.LUserName,
      value: watch('LUserName'),
    },
  ]

  return <DataDisplayList items={items} columns={2} />
}
export default AssetLocationDetailsDisplay
