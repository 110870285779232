import { Scale, Palette } from '@styles-api/css.types'
import { StyleObject } from '@styles-api/css.types'

// base styles applied to all variations
export const base: StyleObject = {
  backgroundColor: 'var(--base-color)',
  position: 'relative',
  borderRadius: 'var(--radius1)',
  boxSizing: 'border-box',
}
export const overflowHidden = ({ overflow }) => {
  if (overflow === 'hidden')
    return {
      overflow: 'hidden',
    }
}
//spacing styles
export const getSpacing = ({ spacing }) => {
  let val: Scale
  switch (spacing) {
    case 'tight':
      val = 'var(--scale400)'
      break
    case 'default':
      val = 'var(--scale600)'
      break
  }
  return {
    paddingTop: val,
    paddingBottom: val,
    paddingRight: val,
    paddingLeft: val,
  }
}

// appearance
export const getAppearance = ({ appearance }) => {
  if (appearance === 'elevated') {
    return {
      boxShadow: 'var(--card-shadow)',
    }
  } else if (appearance === 'flat') {
    return {
      borderWidth: '1px',
    }
  }
}
// types
export const getType = ({ type }) => {
  let backgroundColor: Palette
  let borderColor: Palette
  switch (type) {
    case 'default':
      backgroundColor = 'var(--base-color)'
      break
    case 'light':
      backgroundColor = 'var(--shade200)'
      break
    case 'warning':
      backgroundColor = 'var(--warning-color50)'
      borderColor = 'var(--warning-color200)'
      break
    case 'success':
      backgroundColor = 'var(--success-color50)'
      borderColor = 'var(--success-color200)'
      break
    case 'danger':
      backgroundColor = 'var(--danger-color50)'
      borderColor = 'var(--danger-color200)'
      break
  }
  return {
    backgroundColor,
    borderColor,
  }
}
export const header = ({ spacing }) => {
  let xSpace: Scale
  switch (spacing) {
    case 'tight':
      xSpace = 'var(--scale400)'
      break
    case 'default':
    case 'none':
      xSpace = 'var(--scale600)'
      break
  }
  return {
    borderBottomWidth: '1px',
    borderTopLeftRadius: 'var(--radius1)',
    borderTopRightRadius: 'var(--radius1)',
    backgroundColor: 'var(--base-color)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 'var(--size-default)',
    paddingTop: 'var(--scale500)',
    paddingBottom: 'var(--scale500)',
    paddingLeft: xSpace,
    paddingRight: xSpace,
    fontWeight: 'var(--font-weight-semiBold)',
  }
}
