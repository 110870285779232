import { Comment, FieldLabels } from '@api/models'
import { IUserGrantProps } from '@components/Auth/UserRole'
import {
  Button,
  CustomDatePicker,
  EmptyState,
  Grid,
  Input,
  Textarea,
} from '@components/common'
import useFormFieldProps from '@hooks/useFormField'
import { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

interface CommentFormProps extends IUserGrantProps {
  fields: FieldLabels
  comment?: Comment
  methods: UseFormReturn<Comment, any>
  onSave?: (formValues: Comment) => Promise<void>
  hideSaveBtn?: boolean
  showComment: boolean
  setShowComment: (showComment: boolean) => void
}

const CommentForm = ({
  fields,
  methods,
  canEdit,
  onSave,
  comment,
  hideSaveBtn = false,
  showComment,
  setShowComment,
}: CommentFormProps) => {
  useEffect(() => {
    if (comment && comment.CO_USR && comment.CO_COMMENT) {
      setShowComment(true)
    }
  }, [comment])

  const {
    register,
    formState: { errors, isSubmitting },
    control,
  } = methods

  const { getInputProps, getDateProps } = useFormFieldProps({
    isSubmitting,
    errors,
    register,
    fields,
  })

  return (
    <>
      {showComment ? (
        <Grid>
          <Grid.Item m={12}>
            <Controller
              control={control}
              name="CO_AD_DAT"
              render={({ field }) => (
                <CustomDatePicker
                  {...getDateProps('CO_AD_DAT', field)}
                  label="Added Date"
                  readOnly
                />
              )}
            />
          </Grid.Item>
          <Grid.Item m={12}>
            <Input {...getInputProps('CO_USR')} readOnly label="Entered By" />
          </Grid.Item>
          <Grid.Item>
            <Controller
              name="CO_COMMENT"
              control={control}
              render={({ field }) => <Textarea {...field} label="" readOnly={!canEdit} />}
            />
          </Grid.Item>

          {!hideSaveBtn && (
            <Grid.Item>
              <Button
                onClick={methods.handleSubmit(onSave)}
                disabled={!canEdit}
                loading={isSubmitting}
              >
                Save
              </Button>
            </Grid.Item>
          )}
        </Grid>
      ) : (
        <EmptyState
          heading={`No comment records have been added to this asset`}
          height={230}
          primaryAction={
            <Button
              appearance="primary"
              disabled={isSubmitting}
              onClick={() => setShowComment(true)}
              hidden={!canEdit}
            >
              Add comment
            </Button>
          }
        />
      )}
    </>
  )
}

export default CommentForm
