import { Form, Grid, Input, Select } from '@common'
import { CompanyStructureBase, SegmentType } from '@api/models'
import { SEGMENT_TYPE_FORM_ID } from '@constants'
import { useSegmentTypeForm } from './useSegmentTypeForm'

interface SegmentFormProps {
  isEdit: boolean
  initialValues: CompanyStructureBase
  handleSubmit: (payload: CompanyStructureBase) => Promise<void>
  selectedStructureType?: SegmentType
  selectSegmentTypeOptions?: SegmentType[]
  handleChange?: (e: { label: string; value: any }) => void
}

const SegmentTypeForm = ({
  isEdit,
  initialValues,
  handleSubmit,
  selectSegmentTypeOptions,
  selectedStructureType,
  handleChange,
}: SegmentFormProps) => {
  const { getField, handleFormSubmit } = useSegmentTypeForm({
    initialValues,
    handleSubmit,
    selectedStructureType,
  })

  return (
    <Form id={SEGMENT_TYPE_FORM_ID} onSubmit={handleFormSubmit} noValidate>
      <Grid>
        {!isEdit && (
          <>
            <Grid.Item>
              <Select
                value={selectedStructureType}
                options={selectSegmentTypeOptions}
                onChange={handleChange}
                name="KY_VALUE"
                label="Segment Type Label"
                readOnly={isEdit}
                autoFocus={!isEdit}
                required={!isEdit}
              />
            </Grid.Item>
          </>
        )}
        <Grid.Item>
          <Input {...getField('KY_DESC')} />
        </Grid.Item>
      </Grid>
    </Form>
  )
}
export default SegmentTypeForm
