import View from '@components/layout/View'
import ChangePasswordPrefs from '@components/settings/change-password'

const ChangePasswordPrefView = () => {
  return (
    <View title="Change Password" showHeader={false}>
      <ChangePasswordPrefs />
    </View>
  )
}

export default ChangePasswordPrefView
