import { AppRole, SubscribedApplicationRoles } from '@api/models'
import {
  Button,
  Fieldset,
  Flexbox,
  Icon,
  Input,
  Select,
  Spacer,
  Tooltip,
} from '@components/common'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { isNullOrUndefined } from '@utils/basic'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

interface AppRoleSelectProps {
  subbedAppRoles: SubscribedApplicationRoles
}

interface RoleOptions {
  [key: string]: ReactSelectOptions
}

/**
 * This component is required to be inside a react-hook-form form context.
 * Use this component to make updates to the 'appRoles' field.
 */
export const AppRoleSelect = ({ subbedAppRoles }: AppRoleSelectProps) => {
  const [selectedAppRoles, setSelectedAppRoles] = useState<AppRole[]>([])
  const { setValue } = useFormContext()

  useEffect(() => {
    // set form value upon app/role change
    setValue('appRoles', selectedAppRoles)
  }, [setValue, selectedAppRoles])

  function handleAppSelect(applicationName: string, index: number) {
    setSelectedAppRoles(prevAppRoles => {
      prevAppRoles[index].applicationName = applicationName
      return [...prevAppRoles]
    })
  }

  function handleRoleSelect(roleName: string, index: number) {
    setSelectedAppRoles(prevAppRoles => {
      prevAppRoles[index].roleName = roleName
      return [...prevAppRoles]
    })
  }

  function addAppRole() {
    const blankAppRole = { applicationName: '', roleName: '' }
    setSelectedAppRoles(prevAppRoles => {
      return [...prevAppRoles, blankAppRole]
    })
  }

  function removeAppRole(index: number) {
    setSelectedAppRoles(prevAppRoles => {
      prevAppRoles.splice(index, 1)
      return [...prevAppRoles]
    })
  }

  const appOptions = subbedAppRoles.subscribedApplicationNames.map(applicationName => ({
    label: applicationName,
    value: applicationName,
  }))

  const roleOptionsByApp: RoleOptions = {}
  subbedAppRoles.subscribedApplicationNames.forEach(applicationName => {
    const roleNames = subbedAppRoles.roleNamesBySubscribedApplicationNames[applicationName]
    roleOptionsByApp[applicationName] = roleNames.map(roleName => ({
      label: roleName,
      value: roleName,
    }))
  })

  const hasAppRoles = selectedAppRoles.length > 0
  const allAppsSelected = selectedAppRoles.length === appOptions.length

  return (
    <Fieldset legend="Specify Permitted Applications & Roles">
      {!hasAppRoles && <Button onClick={() => addAppRole()}>Add application access</Button>}
      {hasAppRoles && (
        <>
          <ul>
            {selectedAppRoles.map((appRole, index) => (
              <li>
                <Flexbox alignItems="end" justifyContent="space-between">
                  <Select
                    options={appOptions.filter(
                      x =>
                        !selectedAppRoles.map(x => x.applicationName).includes(x.value) ||
                        x.value === appRole?.applicationName
                    )}
                    name={`app-select-${index}`}
                    value={{
                      label: appRole?.applicationName,
                      value: appRole?.applicationName,
                    }}
                    onChange={option => handleAppSelect(option.value, index)}
                    label="Application"
                    width={'40%'}
                  />
                  <Spacer x="mini" />
                  {isNullOrUndefined(appRole.applicationName) ? (
                    <Tooltip content="Select an application first" flexGrow>
                      <Input disabled label="Role" />
                    </Tooltip>
                  ) : (
                    <Select
                      options={roleOptionsByApp[appRole.applicationName]}
                      name={`role-select-${index}`}
                      value={{
                        label: appRole.roleName,
                        value: appRole.roleName,
                      }}
                      onChange={option => handleRoleSelect(option.value, index)}
                      label="Role"
                      width={'100%'}
                    />
                  )}
                  <Spacer x="mini" />
                  <Flexbox flexDirection="column" justifyContent="flex-end">
                    <Button
                      onClick={() => removeAppRole(index)}
                      icon={<Icon name="Trash2" />}
                      shape="square"
                    />
                  </Flexbox>
                </Flexbox>
                <Spacer y="mini" />
              </li>
            ))}
          </ul>
          <Spacer y="default" />
          <Flexbox justifyContent="flex-end">
            <Button
              onClick={() => addAppRole()}
              icon={<Icon name="PlusCircle" />}
              appearance="text"
              disabled={allAppsSelected}
            >
              Add new application access
            </Button>
          </Flexbox>
        </>
      )}
    </Fieldset>
  )
}
