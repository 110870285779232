import { Asset } from '@forms/assetForm'
import HttpClient from '../HttpClient'

export class AssetsApi extends HttpClient {
  private static classInstance?: AssetsApi

  public constructor() {
    super(`/Asset`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new AssetsApi()
    }

    return this.classInstance
  }

  public getAssetDesc = (assetCompanyId: number, assetId: string) =>
    this.instance.get(
      `/GetAssetDesc?assetCompanyId=${assetCompanyId}&assetId=${assetId}`
    )

  public doesAssetExist = (assetCompanyId: number, assetId: string) =>
    this.instance.get(
      `/DoesAssetExist?assetCompanyId=${assetCompanyId}&assetId=${assetId}`
    )

  public fetch = (currentCompany, itemsPerPage, pageNumber, currentFilter) =>
    this.instance.get(
      `/FetchAssets?assetCompanyID=${currentCompany}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&filter=${JSON.stringify(
        currentFilter
      )}`
    )
  public fetchAsset = (assetCompanyId: number, assetId: string) =>
    this.instance.get(
      `/GetAsset?assetCompanyId=${assetCompanyId}&assetId=${assetId}`
    )
  public fetchAssetFolders = (assetCompanyId: number, assetId: string) =>
    this.instance.get(
      `/FetchAssetFolders?assetCompanyID=${assetCompanyId}&assetId=${assetId}`
    )
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, { asset: data })
  public delete = (assetCompanyId: number, assetId: string) =>
    this.instance.post(`/Delete/${assetCompanyId}/${assetId}`)
  public doesAssetNeedRecalc = (asset: Asset) =>
    this.instance.post(`/AssetNeedsRecalc`, asset)
}
