import { StyleObject } from '@styles-api/css.types'

export const localNav: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 'var(--gap-default)',
  borderBottomWidth: '1px',
}
export const localNavMenu: StyleObject = {
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '-1px',
  marginLeft: 'calc(var(--scale600) * -1)',
}
export const localNavItem: StyleObject = {
  display: 'block',
}
export const localNavLink: StyleObject = {
  height: 'var(--size-large)',
  paddingRight: 'var(--scale600)',
  paddingLeft: 'var(--scale600)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'var(--button-hover-transition)',
  position: 'relative',
  fontWeight: 'var(--font-weight-normal)',
  overflow: 'hidden',

  '::after': {
    content: '""',
    height: '2px',
    width: 'calc(100% - var(--scale600) - var(--scale600))',
    bottom: '-2px',
    left: 'var(--scale600)',
    backgroundColor: 'var(--shade300)',
    position: 'absolute',
    borderTopRightRadius: 'var(--radius1)',
    borderTopLeftRadius: 'var(--radius1)',
    transition: '0.1s ease-in-out',
  },
  // ...getFocusLightStyle,
  ':hover': {
    '::after': {
      bottom: '0px!important',
    },
  },
  ':active': {
    '::after': {
      bottom: '-2px!important',
    },
  },
  color: 'var(--text-color)',
}
export const localNavText: StyleObject = {
  '::after': {
    content: 'attr(data-content)',
    fontWeight: 'var(--font-weight-bold)',
    display: 'block',
    visibility: 'hidden',
    height: 0,
  },
}
export const localNavLinkActive: StyleObject = {
  fontWeight: 'var(--font-weight-bold)',
  outline: '1px solid transparent)',
  outlineOffset: '-1px',
  '::after': {
    bottom: '0px!important',
    backgroundColor: 'var(--primary-color)!important',
  },
}
export const localNavIndicator: StyleObject = {
  backgroundColor: '#f9826c',
  height: '2px',
  position: 'absolute',
  bottom: '-1px',
  left: 0,
  width: '100%',
}
