import { createSlice } from '@reduxjs/toolkit'

const filtersSlice = createSlice({
  name: 'filters',
  initialState: {},
  reducers: {
    setFilter: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    clearFiltersButKeepSort: (state: { sort?: [{ fields: string; dir: string }] }) => {
      // if I do have a sort, I want to keep it
      if (state.sort) {
        const { sort } = state
        return {
          sort,
        }
      }
      return {}
    },
    setSort: (state, action) => {
      const { field, dir } = action.payload
      return {
        ...state,
        sort: [
          {
            field,
            dir,
          },
        ],
      }
    },
    clearSort: (state: { sort?: [{ fiels: string; dir: string }] }) => {
      const { sort, ...rest } = state
      return rest
    },
  },
})

export const {
  setFilter,
  clearFiltersButKeepSort,
  clearSort,
  setSort,
} = filtersSlice.actions
export default filtersSlice.reducer
