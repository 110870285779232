import { toast } from '@components/common'
import { reportResponseHandler } from '@utils/reports'
import { ReportsApi } from '@api'
import { ERROR_REQUEST } from '@constants'
import { ListReportParams } from '@api/models'

async function companyListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getCompanyPeriodList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

async function segmentListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getSegmentList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

async function glPostListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getGLPostList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

async function categoryListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getCategoryList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

async function taxClassListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getTaxClassList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

async function vendorListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getVendorList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

async function taskActivityListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getTaskActivityList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

async function insuranceListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getInsuranceList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

async function maintenanceListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getMaintenanceList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

async function folderListOnSubmitHandler(values: ListReportParams) {
  await ReportsApi.getInstance()
    .getFolderList(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

export {
  companyListOnSubmitHandler,
  segmentListOnSubmitHandler,
  glPostListOnSubmitHandler,
  categoryListOnSubmitHandler,
  taxClassListOnSubmitHandler,
  vendorListOnSubmitHandler,
  taskActivityListOnSubmitHandler,
  insuranceListOnSubmitHandler,
  maintenanceListOnSubmitHandler,
  folderListOnSubmitHandler,
}
