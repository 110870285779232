import {
  Grid,
  Modal,
  Text,
  Fieldset,
  Flexbox,
  Input,
  Spacer,
  Spinner,
  toast,
  CustomDatePicker,
  Note,
  Icon,
} from '@components/common'
import { Controller, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { DepRunInput, depRunSchema } from '@api/models'
import { yupResolver } from '@hookform/resolvers/yup'
import useDepRunHook from './useDepRunHook'
import DepreciationCard from './DepreciationCard'

interface DepreciationRunFormProps {
  assetCompanyID: number
  isPeriodBased: boolean
}

const DepreciationRunForm = ({
  assetCompanyID,
  isPeriodBased,
}: DepreciationRunFormProps) => {
  const {
    isLoadingGetDepRunInfo,
    isSuccessGetDepRunInfo,
    isDepRunFormOpen,
    isDepreciationRunning,
    isErrorDepRun,
    isErrorGetDepRunInfo,
    errorGetDepRunInfo,
    depRunInfo,
    fields,
    openDepRunForm,
    closeDepRunForm,
    runDepreciation,
  } = useDepRunHook(assetCompanyID)

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<DepRunInput>({
    mode: 'onSubmit',
    resolver: yupResolver(depRunSchema(isPeriodBased)),
  })

  useEffect(() => {
    if (isSuccessGetDepRunInfo) {
      reset(depRunInfo)
    } else if (isErrorGetDepRunInfo) {
      toast.error(errorGetDepRunInfo)
      reset(depRunInfo)
    }
  }, [isSuccessGetDepRunInfo, isErrorGetDepRunInfo, depRunInfo, errorGetDepRunInfo, reset])

  useEffect(() => {
    if (!isDepreciationRunning) {
      if (isErrorDepRun) {
        toast.error('Depreciation Period run failed')
      }
    }
    // }, [isSuccessDepRun, isErrorDepRun, isDepreciationRunning])
  }, [isErrorDepRun, isDepreciationRunning])

  function getInputProps(name: keyof DepRunInput) {
    return {
      ...register(name),
      error: errors[name] !== undefined,
      errorMsg: errors[name]?.message,
      disabled: isSubmitting,
    }
  }

  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      onBlur: field.onBlur,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  function closeDepForm() {
    reset(depRunInfo)
    closeDepRunForm()
  }

  const isRunDisabled = assetCompanyID === undefined || assetCompanyID === null
  const formErrorMsg = errors['']?.message || ''
  const shouldShowDepRunForm = isSuccessGetDepRunInfo && isDepRunFormOpen

  return (
    <>
      <Modal
        isOpen={isDepreciationRunning}
        close={() => {}}
        title="Running Period Depreciation"
      >
        <Flexbox justifyContent="center">
          <Spinner size="large" />
        </Flexbox>
      </Modal>

      <Modal
        isOpen={shouldShowDepRunForm}
        close={closeDepForm}
        onSubmit={handleSubmit(runDepreciation)}
        title="Period Depreciation Run"
        actionLabel="Run"
      >
        {shouldShowDepRunForm && (
          <Grid>
            {formErrorMsg &&
              errors['PostDate'] === undefined &&
              errors['AsOfDate'] === undefined && (
                <Grid.Item>
                  <Grid.Item>
                    <Note intent="danger" content={<>{formErrorMsg}</>} />
                  </Grid.Item>
                </Grid.Item>
              )}
            <Grid.Item>
              <Fieldset legend="Status" legendCenter>
                <Flexbox>
                  <Grid>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_COM_ID}
                        value={depRunInfo.AssetCompanyID}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label={fields.CM_DESCS}
                        value={depRunInfo.ShortName}
                        readOnly
                      />
                    </Grid.Item>
                    <Grid.Item m={4}>
                      <Input
                        label="Last Year Closed"
                        value={depRunInfo.LastYearClosed}
                        readOnly
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Parameters" legendCenter>
                <Flexbox>
                  <Grid>
                    {isPeriodBased ? (
                      <>
                        <Grid.Item m={4}>
                          <Input {...getInputProps('FiscalYear')} label="Year" />
                        </Grid.Item>
                        <Grid.Item m={3}>
                          <Input {...getInputProps('Period')} label="Period" />
                        </Grid.Item>
                      </>
                    ) : (
                      <Grid.Item m={5}>
                        <Controller
                          control={control}
                          name="AsOfDate"
                          render={({ field }) => (
                            <CustomDatePicker
                              {...getDateProps(field)}
                              label="As of Date"
                              error={errors['AsOfDate'] !== undefined}
                              errorMsg={errors['AsOfDate']?.message}
                            />
                          )}
                        />
                      </Grid.Item>
                    )}
                    <Grid.Item m={5}>
                      <Controller
                        control={control}
                        name="PostDate"
                        render={({ field }) => (
                          <CustomDatePicker
                            {...getDateProps(field)}
                            label="Posting Date"
                            error={errors['PostDate'] !== undefined}
                            errorMsg={errors['PostDate']?.message}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Flexbox>
              </Fieldset>
            </Grid.Item>
            <Grid.Item>
              <Fieldset legend="Remarks" legendCenter>
                <Grid>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>1.</Text>
                      <Spacer x="compact" />
                      <Input {...getInputProps('Remark1')} />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>2.</Text>
                      <Spacer x="compact" />
                      <Input {...getInputProps('Remark2')} />
                    </Flexbox>
                  </Grid.Item>
                  <Grid.Item>
                    <Flexbox>
                      <Text bold>3.</Text>
                      <Spacer x="compact" />
                      <Input {...getInputProps('Remark3')} />
                    </Flexbox>
                  </Grid.Item>
                </Grid>
              </Fieldset>
            </Grid.Item>
          </Grid>
        )}
      </Modal>

      <DepreciationCard
        disabled={isRunDisabled}
        loading={isLoadingGetDepRunInfo}
        icon={<Icon name="TrendingDown" color="var(--primary-color500)" />}
        type={'Run'}
        openForm={openDepRunForm}
        helpText="Calculate depreciation for the selected period, allowing review before permanent commitment."
      />
    </>
  )
}

export default DepreciationRunForm
