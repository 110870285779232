import HttpClient from '../HttpClient'

export class AssetCompanyApi extends HttpClient {
  private static classInstance?: AssetCompanyApi

  public constructor() {
    super(`/AssetCompanies`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new AssetCompanyApi()
    }

    return this.classInstance
  }

  public fetchCompanies = () => this.instance.get('/FetchCompanies')
  public fetchAssetCompanies = (filter: string, pageNumber: number, itemsPerPage: number) =>
    this.instance.get(
      `/FetchAssetCompanies?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`
    )
  public getAssetCompany = (assetCompanyId: number) =>
    this.instance.get(`/GetAssetCompany?assetCompanyId=${assetCompanyId}`)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (data: any) => this.instance.post(`/Delete`, data)
  public getCompanyBasis = (assetCompanyID: number) =>
    this.instance.get(`/GetCompanyBasis?assetCompanyID=${assetCompanyID}`)
}
