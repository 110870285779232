import { DepreciationProfileCode, ReportFilter } from '@api/models'
import { Input, Select, Spacer, Spinner, Tooltip } from '@components/common'
import {
  useGetExtraChargeFieldsQuery,
  useGetSegmentByKeyQuery,
  useListDepreciationsQuery,
  useListInsurancesQuery,
  useListVendorsQuery,
} from '@api'
import { InputChangeEvent } from 'types'
import { ReportFilterField } from './ReportFilter.types'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { getLastCharacterInString } from '@utils/basic'
import { useExtraFields, usePicklist } from '@hooks'
import { DISPOSITION_OPTIONS } from '@constants'
interface Props {
  state: ReportFilter[]
  index: number
  filterField: ReportFilterField
  handleSingleValueChange: (e: InputChangeEvent, index: number) => void
  getSingleFieldValue: (index: number) => string
  handleRangeValueChange: (e: InputChangeEvent, index: number) => void
  getRangeFieldValue: (index: number, key: 'From' | 'To') => string
  getMultiSelectValue: (index: number) => string[]
  handleMultiSelectChange: (list: string[], index: number) => void
}

const ReportFilterInput = ({
  index,
  handleSingleValueChange,
  filterField,
  getSingleFieldValue,
  handleRangeValueChange,
  getRangeFieldValue,
  getMultiSelectValue,
  handleMultiSelectChange,
}: Props) => {
  // Fetch data for select options

  // Segments
  const {
    data: segments,
    isLoading: isLoadingSegments,
    isSuccess: isSuccessGettingSegments,
  } = useGetSegmentByKeyQuery(
    {
      KY_NR: parseInt(filterField?.name.charAt(6)),
    },
    { skip: filterField?.options !== 'Segment' }
  )

  const segmentOptions: ReactSelectOptions = []
  if (isSuccessGettingSegments) {
    for (const segment of segments) {
      segmentOptions.push({
        label: `${segment.KY_VALUE} (${segment.KY_DESC})`,
        value: segment.KY_VALUE,
      })
    }
  }

  // Depreciations
  const skipDepreciationsCall: boolean =
    filterField?.options !== 'DepreciationTypeX' &&
    filterField?.options !== 'DepreciationTypeA'
  const {
    data: depreciations,
    isLoading: isLoadingDepreciations,
    isSuccess: isSuccessGettingDepreciations,
  } = useListDepreciationsQuery(
    {
      profile: filterField?.options
        ? (getLastCharacterInString(filterField?.options) as DepreciationProfileCode)
        : 'A',
    },
    {
      skip: skipDepreciationsCall,
    }
  )
  const depreciationOptions: ReactSelectOptions = []
  if (isSuccessGettingDepreciations) {
    for (const depreciation of depreciations) {
      depreciationOptions.push({
        label: `${depreciation.ID} - ${depreciation.Name}`,
        value: depreciation.ID,
      })
    }
  }

  // Insurance Policies
  const {
    data: insurances,
    isLoading: isLoadingInsurances,
    isSuccess: isSuccessGettingInsurances,
  } = useListInsurancesQuery(undefined, { skip: filterField?.options !== 'Insurance' })

  const insuranceOptions: ReactSelectOptions = []
  if (isSuccessGettingInsurances) {
    for (const policy of insurances) {
      insuranceOptions.push({
        label: `${policy.ID}`,
        value: policy.ID,
      })
    }
  }

  // Vendors
  const {
    data: vendors,
    isLoading: isLoadingVendors,
    isSuccess: isSuccessGettingVendors,
  } = useListVendorsQuery(undefined, { skip: filterField?.options !== 'Vendor' })

  const vendorOptions: ReactSelectOptions = []
  if (isSuccessGettingVendors) {
    for (const vendor of vendors) {
      vendorOptions.push({
        label: `${vendor.ID} (${vendor.Name})`,
        value: vendor.ID,
      })
    }
  }

  // Picklist
  const {
    picklist,
    isLoading: isLoadingPicklist,
    isSuccess: isSuccessGettingPicklist,
  } = usePicklist({ skipRequest: filterField?.options !== 'Picklist' })

  const picklistOptions: ReactSelectOptions = []
  if (isSuccessGettingPicklist && filterField?.name in picklist) {
    for (const item of picklist[filterField?.name]) {
      picklistOptions.push({
        label: item.label,
        value: item.value,
      })
    }
  }

  // Acquisition Post Codes (GL Post Codes) - Extra Fields for Depreciation Report
  const { extraFields, isLoadingExtraFields } = useExtraFields({
    skipRequest:
      filterField?.options !== 'Acquisition Post Code' &&
      filterField?.options !== 'Depreciation Post Code',
  })

  // Task/Activities - Activity ID
  const {
    data: chargesExtraFields,
    isLoading: isLoadingChargesExtraFields,
  } = useGetExtraChargeFieldsQuery(undefined, {
    skip: filterField?.options !== 'Activity ID' && filterField?.options !== 'References',
  })

  // Returns
  if (!filterField) {
    return (
      <Tooltip content="Select a field first" flexGrow>
        <Input disabled label="Value" />
      </Tooltip>
    )
  }

  switch (filterField.type) {
    case String:
    default: {
      return (
        <Input
          label="Value"
          value={getSingleFieldValue(index)}
          onChange={(e: InputChangeEvent) => handleSingleValueChange(e, index)}
          name={`report-filter-val-${index}`}
          width="auto"
          flexGrow
        />
      )
    }
    case Date: {
      return (
        <>
          <Input
            label="From"
            id="From"
            name={`report-filter-from-val-${index}`}
            onChange={(e: InputChangeEvent) => handleRangeValueChange(e, index)}
            type="date"
            value={getRangeFieldValue(index, 'From')}
            width="auto"
            flexGrow
          />
          <Spacer x="mini" />
          <Input
            label="To"
            id="To"
            name={`report-filter-to-val-${index}`}
            onChange={(e: InputChangeEvent) => handleRangeValueChange(e, index)}
            type="date"
            value={getRangeFieldValue(index, 'To')}
            width="auto"
            flexGrow
          />
        </>
      )
    }
    case 'options': {
      let options: ReactSelectOptions

      switch (filterField.options) {
        case 'Segment':
          if (isLoadingSegments) return <Spinner />
          options = segmentOptions
          break
        case 'DepreciationTypeA':
        case 'DepreciationTypeX':
          if (isLoadingDepreciations) return <Spinner />
          options = depreciationOptions
          break
        case 'Insurance':
          if (isLoadingInsurances) return <Spinner />
          options = insuranceOptions
          break
        case 'Picklist':
          if (isLoadingPicklist) return <Spinner />
          options = picklistOptions
          break
        case 'Vendor':
          if (isLoadingVendors) return <Spinner />
          options = vendorOptions
          break
        case 'Disposition':
          options = DISPOSITION_OPTIONS
          break
        case 'Acquisition Post Code':
        case 'Depreciation Post Code':
          if (isLoadingExtraFields) return <Spinner />
          options = extraFields.glPost
          break
        case 'References':
          if (isLoadingChargesExtraFields) return <Spinner />
          options = chargesExtraFields.references
          break
        case 'Activity ID':
          if (isLoadingChargesExtraFields) return <Spinner />
          options = chargesExtraFields.activities
          break
      }

      return (
        <Select
          type="multi"
          options={options}
          label="Include in report"
          value={getMultiSelectValue(index)}
          placeholder="Search for options..."
          flexGrow
          onChange={e => handleMultiSelectChange(e, index)}
          name={`report-filter-multi-val-${index}`}
        />
      )
    }
    case 'create-options': {
      return (
        <Select
          type="creatable-multi"
          options={[]}
          label="Include in report"
          value={getMultiSelectValue(index)}
          placeholder="Search for options..."
          flexGrow
          onChange={e => handleMultiSelectChange(e, index)}
          name={`report-filter-creatable-multi-val-${index}`}
        />
      )
    }
  }
}

export default ReportFilterInput
