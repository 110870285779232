import {
  AssetSpecificDepreciationParams,
  DeprecExtraFields,
  DepreciationClass,
  DepreciationClassKey,
  depreciationClassSchema,
  DeprecRecalcDTO,
  EditDepreciationPayload,
  ExtraFieldsMap,
  FieldLabels,
  initialDepreciation,
} from '@api/models'
import {
  useCreateAssetSpecificDepreciationMutation,
  useCreateDepreciationMutation,
  useDeleteDepreciationMutation,
  useDoesDeleteDeprecNeedRecalcMutation,
  useDoesDeprecNeedRecalcMutation,
  useEditDepreciationMutation,
  useGetAssetDepreciationQuery,
  useGetDepreciationQuery,
} from '@api'
import { useForm, UseFormHandleSubmit, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useFields, useExtraFields } from '@hooks'
import { toast } from '@components/common'
import { useNavigate } from 'react-router-dom'
import { DEPRECIATION_CLASS_VIEWS, ERROR_REQUEST } from '@constants'
import {
  DeprecClassHookProps,
  DepreciationClassCardsState,
  DepreciationClassFormState,
} from '@components/depreciation-class/DepreciationClass.types'
import { FormSubmitEvent } from 'types'

export interface CodeLabels {
  deprecType: string
  deprecMethod: string
  startCode: string
  rateCode: string
  systemCode: string
  accelerationCode: string
}

interface RecalcFormState {
  open: boolean
  intitialValues:
    | {
        deprec: DepreciationClass
        deprecRecalcDTO: DeprecRecalcDTO
      }
    | undefined
  type: 'edit' | 'delete'
}

interface IDepreciationProfileHook {
  // React-hook-form
  methods: UseFormReturn<DepreciationClass>
  handleSubmit: UseFormHandleSubmit<DepreciationClass>
  isDirty: boolean
  isFormValid: boolean

  depreciationClass: DepreciationClass
  isAssetSpecific: boolean
  fields: FieldLabels
  extraFieldsLabels: ExtraFieldsMap
  extraFields: DeprecExtraFields

  formState: DepreciationClassFormState
  recalcFormState: RecalcFormState
  isDiscardModalOpen: boolean
  setIsDiscardModalOpen: Dispatch<SetStateAction<boolean>>
  isAssetSpecificModalOpen: boolean
  setIsAssetSpecificModalOpen: Dispatch<SetStateAction<boolean>>
  cardsState: DepreciationClassCardsState
  setCardsState: Dispatch<SetStateAction<DepreciationClassCardsState>>

  isMainDetailsEdited: boolean
  isDeprecInfoEdited: boolean
  isClassificationEdited: boolean
  sectionSharedProps: {}

  closeRecalcForm: () => void
  discardChanges: () => void
  shouldBlockNavigation: boolean

  mainDetailsFields: DepreciationClassKey[]

  saveDepreciationClass: (payload: DepreciationClass) => Promise<void>
  saveDepreciationClassWithRecalc: (payload: EditDepreciationPayload) => Promise<void>

  handleAssetSpecificSubmit: (e: FormSubmitEvent) => Promise<void>
  isLoadingAssetSpecificSubmit: boolean

  isLoading: boolean
  isSubmitting: boolean

  saveButtonLabel: string
  isNonLinearDepMethodSelected: boolean
  isNoDepMethodSelected: boolean

  deductFromAcq: boolean
  deductFromBookValue: boolean
  lifetimeLabel: string
  isProfileMaintained: boolean

  deleteDepreciationClass: (payload: DepreciationClass) => Promise<void>
  deleteDepreciationClassWithRecalc: (payload: EditDepreciationPayload) => Promise<void>
  isDeleteModalOpen: boolean
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>
  handleDeleteDeprec: (e: FormSubmitEvent) => Promise<void>
}

export default function useDepreciationClass({
  params,
  context,
}: DeprecClassHookProps): IDepreciationProfileHook {
  const { fields, isLoading: isLoadingFields } = useFields()
  const { extraFields, extraFieldsLabels, isLoadingExtraFields } = useExtraFields()
  const navigate = useNavigate()
  // Declare form hook
  const methods = useForm<DepreciationClass>({
    resolver: yupResolver(depreciationClassSchema(context === 'create')),
    mode: 'onChange',
    defaultValues: initialDepreciation(params.profile),
  })

  const {
    reset,
    formState: { dirtyFields, isDirty, isValid: isFormValid },
    watch,
    handleSubmit,
  } = methods

  // Declare form ui states
  const [recalcFormState, setRecalcFormState] = useState<RecalcFormState>({
    open: false,
    intitialValues: undefined,
    type: 'edit',
  })
  function closeRecalcForm(): void {
    setRecalcFormState(prevState => ({
      ...prevState,
      open: false,
    }))
  }

  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false)
  const [isAssetSpecificModalOpen, setIsAssetSpecificModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const [cardsState, setCardsState] = useState<DepreciationClassCardsState>({
    isMainDetailsCardOpen: context === 'create' ? true : false,
    isDeprecInfoCardOpen: context === 'create' ? true : false,
    isClassificationFormOpen: context === 'create' ? true : false,
  })

  // Reset form ui state
  function resetFormUiState() {
    setRecalcFormState({
      open: false,
      intitialValues: undefined,
      type: 'edit',
    })

    setIsDiscardModalOpen(false)
    setIsAssetSpecificModalOpen(false)
    setCardsState(prevState => {
      Object.keys(prevState).forEach(key => {
        prevState[key] = false
      })

      return prevState
    })
  }

  function discardChanges(): void {
    if (context !== 'create') {
      reset(depreciationClass())
      resetFormUiState()
    } else {
      navigate(`/${params.profile === 'A' ? 'categories' : 'tax-classes'}`)
    }
  }

  // IF ON CATEGORIES/TAX CLASSES DETAILS VIEWS
  const {
    data: depreciationProfileClass,
    isLoading: isLoadingDepreciationProfileClass,
    isSuccess: isSuccessGettingProfileClass,
  } = useGetDepreciationQuery(
    {
      assetCompanyID: params.assetCompanyID,
      id: params.id,
      profile: params.profile,
    },
    { skip: context !== 'class' }
  )

  // IF IS ASSET DEPREC
  // Get asset depreciation
  const {
    data: assetDepreciationClass,
    isLoading: isLoadingAssetDepreciation,
    isSuccess: isSuccessGettingAssetDepreciation,
  } = useGetAssetDepreciationQuery(
    {
      assetCompanyID: params.assetCompanyID,
      assetID: params.assetID,
      profile: params.profile,
    },
    { skip: context !== 'asset' }
  )

  // Return class
  const depreciationClass = (): DepreciationClass => {
    switch (context) {
      case 'asset':
        return assetDepreciationClass
      case 'class':
        return depreciationProfileClass
      case 'create':
        return initialDepreciation(params.profile)
    }
  }

  useEffect(
    function resetFormOnLoad() {
      if (
        (context === 'class' && isSuccessGettingProfileClass) ||
        (context === 'asset' && isSuccessGettingAssetDepreciation)
      ) {
        reset(depreciationClass())
      }
    },
    [depreciationProfileClass, assetDepreciationClass]
  )

  // Check if is asset specific
  const isAssetSpecific: boolean =
    assetDepreciationClass?.AssetCompanyID !== 0 && context === 'asset'

  // Form state; i.e. if user can edit, read-only, etc.
  const formState = (): DepreciationClassFormState => {
    if (context === 'create') {
      return 'edit-only'
    }

    if (context === 'class') {
      return 'can-edit'
    }

    if (context === 'asset' && isAssetSpecific) {
      return 'can-edit'
    }

    return 'read-only'
  }

  // Form sections
  const mainDetailsFields: DepreciationClassKey[] = ['DP_OBJ_ID', 'DP_DESC']
  let isMainDetailsEdited: boolean
  for (const field of mainDetailsFields) {
    if (dirtyFields[field] === true) {
      isMainDetailsEdited = true
    }
  }

  const deprecInfoFields: DepreciationClassKey[] = [
    'DP_MET_CD',
    'DP_STRT_CD',
    'DP_LIFETIM',
    'DP_RATE',
    'DP_RATE_CD',
    'DP_ACPC_ID',
    'DP_DSPC_ID',
    'DP_DPPC_ID',
    'DP_DGPC_ID',
    'DP_TAX_CRD',
    'DP_TAX_UCD',
    'DP_TAXC_CD',
    'DP_INT_DED',
    'DP_INT_UCD',
    'DP_INT_CD',
  ]
  let isDeprecInfoEdited: boolean
  for (const field of deprecInfoFields) {
    if (dirtyFields[field] === true) {
      isDeprecInfoEdited = true
    }
  }

  const classificationFields: DepreciationClassKey[] = [
    'DP_REPA_CD',
    'DP_REPS_CD',
    'DP_REP_UCD',
    'DP_LOCD_CD',
    'DP_LAPC_ID',
    'DP_LDPC_ID',
    'DP_SERD_CD',
    'DP_SAPC_ID',
    'DP_SDPC_ID',
  ]
  let isClassificationEdited: boolean
  for (const field of classificationFields) {
    if (dirtyFields[field] === true) {
      isClassificationEdited = true
    }
  }

  const sectionSharedProps = {
    fields,
    cardsState,
    setCardsState,
    formState: formState(),
    deprec: depreciationClass(),
  }
  // Save form
  const [
    doesDeprecNeedRecalc,
    { isLoading: isLoadingDeprecNeedsRecalcDTO },
  ] = useDoesDeprecNeedRecalcMutation({ fixedCacheKey: 'doesDeprecNeedRecalc' })

  // delete depreciation
  const [
    doesDeleteDeprecNeedRecalc,
    { isLoading: isLoadingDeleteDeprecNeedsRecalcDTO },
  ] = useDoesDeleteDeprecNeedRecalcMutation({ fixedCacheKey: 'doesDeleteDeprecNeedRecalc' })

  const [deleteDeprec, { isLoading: isDeletingDeprec }] = useDeleteDepreciationMutation()

  const [editDeprec, { isLoading: isLoadingEditDeprec }] = useEditDepreciationMutation()

  const [
    createDeprec,
    { isLoading: isLoadingCreateDeprec, isSuccess: isSuccessCreateDeprec },
  ] = useCreateDepreciationMutation()

  async function saveDepreciationClass(payload: DepreciationClass): Promise<void> {
    try {
      // Check if deprec needs recalc
      if (context !== 'create') {
        const deprecRecalcDTO = await doesDeprecNeedRecalc(payload).unwrap()

        if (deprecRecalcDTO.ShouldRecalc) {
          // Open recalc modal if needed
          setRecalcFormState({
            open: true,
            intitialValues: {
              deprec: payload,
              deprecRecalcDTO,
            },
            type: 'edit',
          })
        } else {
          // Edit deprec if does not need recalc
          const updatedDeprec = await editDeprec({
            deprec: payload,
            deprecRecalcDTO: null,
          }).unwrap()

          reset(updatedDeprec)

          toast.success(
            `Depreciation class ${depreciationClass().DP_OBJ_ID} successfully updated.`
          )

          resetFormUiState()
        }
      } else {
        // Create deprec class and navigate to details page

        await createDeprec(payload)

        toast.success(`Successfully created depreciation class ${watch('DP_OBJ_ID')}`)
        navigate(
          `/${params.profile === 'A' ? 'categories' : 'tax-classes'}/${watch(
            'DP_OBJ_ID'
          )}/${DEPRECIATION_CLASS_VIEWS.DETAILS}`
        )
      }
    } catch (error) {
      toast.error(ERROR_REQUEST)
    }
  }

  async function saveDepreciationClassWithRecalc(
    payload: EditDepreciationPayload
  ): Promise<void> {
    try {
      const updatedDeprec = await editDeprec(payload).unwrap()

      reset(updatedDeprec)

      toast.success(
        `Depreciation class ${depreciationClass().DP_OBJ_ID} successfully updated.`
      )
    } catch (error: any) {
      toast.error(error.data)
    } finally {
      resetFormUiState()
    }
  }

  async function deleteDepreciationClass(payload: DepreciationClass): Promise<void> {
    try {
      // Check if deprec needs recalc
      const deprecRecalcDTO = await doesDeleteDeprecNeedRecalc(payload).unwrap()

      if (deprecRecalcDTO.ShouldRecalc) {
        // Open recalc modal if needed
        setRecalcFormState({
          open: true,
          intitialValues: {
            deprec: payload,
            deprecRecalcDTO,
          },
          type: 'delete',
        })
      } else {
        if (isAssetSpecific) setIsAssetSpecificModalOpen(true)
        else setIsDeleteModalOpen(true)
        // // Delete deprec if it does not need recalc
        // const globalDeprec = await deleteDeprec({
        //   deprec: payload,
        //   deprecRecalcDTO: null,
        // }).unwrap()
        // if (globalDeprec != null) reset(globalDeprec)
        // toast.success(
        //   `Depreciation class ${depreciationClass().DP_OBJ_ID} successfully deleted.`
        // )
        // resetFormUiState()
      }
    } catch (error) {
      toast.error(ERROR_REQUEST)
    }
  }

  async function deleteDepreciationClassWithRecalc(
    payload: EditDepreciationPayload
  ): Promise<void> {
    try {
      // Delete deprec if it does not need recalc
      const globalDeprec = await deleteDeprec(payload).unwrap()

      if (globalDeprec == null) {
        // TODO: case for global deprec (use navigate to deprec list)
      } else {
        reset(globalDeprec)
      }

      toast.success(
        `Depreciation class ${depreciationClass().DP_OBJ_ID} successfully deleted.`
      )
    } catch (error) {
      toast.error(ERROR_REQUEST)
    } finally {
      resetFormUiState()
    }
  }

  async function handleDeleteDeprec(e: FormSubmitEvent): Promise<void> {
    try {
      e.preventDefault()
      await deleteDeprec({
        deprec: depreciationProfileClass,
        deprecRecalcDTO: null,
      }).unwrap()
      navigate(`/${params.profile === 'A' ? 'categories' : 'tax-classes'}`)
    } catch (error: any) {
      toast.error(error.data)
      setIsDeleteModalOpen(false)
    }
  }

  // Handle asset specific functions
  // Asset-specific deprec submit
  const [
    createAssetSpecificDepreciation,
    { isLoading: isLoadingCreateAssetSpecificDepreciation },
  ] = useCreateAssetSpecificDepreciationMutation()

  async function handleAssetSpecificSubmit(e: FormSubmitEvent): Promise<void> {
    e.preventDefault()

    const payload: AssetSpecificDepreciationParams = {
      assetCompanyID: params.assetCompanyID,
      assetID: params.assetID,
      profile: params.profile,
    }

    try {
      if (isAssetSpecific) {
        // Delete deprec if it does not need recalc
        const globalDeprec = await deleteDeprec({
          deprec: assetDepreciationClass,
          deprecRecalcDTO: null,
        }).unwrap()

        if (globalDeprec != null) reset(globalDeprec)

        resetFormUiState()
        toast.success('Successfully deleted asset-specific depreciation')
      } else {
        await createAssetSpecificDepreciation(payload).unwrap()

        toast.success('Successfully created asset-specific depreciation')
      }
    } catch (error) {
      toast.error(ERROR_REQUEST)
    } finally {
      resetFormUiState()
    }
  }

  const isLoadingAssetSpecificSubmit: boolean = isLoadingCreateAssetSpecificDepreciation

  // Misc
  const saveButtonLabel = (): string => {
    if (context !== 'create') return 'Save changes'
    else return `Create ${params.profile === 'A' ? 'category' : 'tax class'}`
  }

  const deprecMethod = watch('DP_MET_CD')
  const isNonLinearDepMethodSelected: boolean = 'NSGUP'.includes(deprecMethod)
  const isNoDepMethodSelected: boolean = deprecMethod === 'N'

  const deductFromAcq: boolean = watch('DP_TAXC_CD')
  const deductFromBookValue: boolean = watch('DP_INT_CD')
  const lifetimeLabel: string = watch('DP_MET_CD') === 'P' ? 'Units' : 'Years'

  const isProfileMaintained: boolean = context === 'asset' && depreciationClass() !== null

  // Navigation blocker
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false)

  useEffect(
    function setShouldBlockNavigationOnDirty() {
      setShouldBlockNavigation(isDirty)
    },
    [isDirty]
  )

  useEffect(
    function allowNavigationFromCreateView() {
      if (context === 'create') {
        // Allow navigation back to categories from discard modal
        if (isDiscardModalOpen) {
          setShouldBlockNavigation(false)
        } else {
          setShouldBlockNavigation(isDirty)
        }

        // Allow navigation to new deprec class page after creation
        if (isLoadingCreateDeprec || isSuccessCreateDeprec) {
          setShouldBlockNavigation(false)
        }
      }
    },
    [isDiscardModalOpen, isLoadingCreateDeprec]
  )

  // Check if anything required to render is loading
  const isLoading: boolean =
    isLoadingAssetDepreciation ||
    isLoadingDepreciationProfileClass ||
    isLoadingFields ||
    isLoadingExtraFields

  const isSubmitting: boolean =
    isLoadingDeprecNeedsRecalcDTO ||
    isLoadingEditDeprec ||
    isLoadingCreateDeprec ||
    isLoadingDeleteDeprecNeedsRecalcDTO ||
    isDeletingDeprec

  return {
    handleDeleteDeprec,
    setIsDeleteModalOpen,
    isDeleteModalOpen,
    deleteDepreciationClassWithRecalc,
    deleteDepreciationClass,
    methods,
    handleSubmit,
    isDirty,
    isFormValid,

    depreciationClass: depreciationClass(),
    isAssetSpecific,
    fields,
    extraFields,
    extraFieldsLabels,

    formState: formState(),
    recalcFormState,
    closeRecalcForm,
    cardsState,
    setCardsState,
    isDiscardModalOpen,
    setIsDiscardModalOpen,
    isAssetSpecificModalOpen,
    setIsAssetSpecificModalOpen,
    handleAssetSpecificSubmit,
    isLoadingAssetSpecificSubmit,
    discardChanges,

    mainDetailsFields,
    isMainDetailsEdited,
    isDeprecInfoEdited,
    isClassificationEdited,
    sectionSharedProps,
    shouldBlockNavigation,

    saveDepreciationClass,
    saveDepreciationClassWithRecalc,

    isLoading,
    isSubmitting,
    saveButtonLabel: saveButtonLabel(),

    isNoDepMethodSelected,
    isNonLinearDepMethodSelected,
    deductFromAcq,
    deductFromBookValue,
    lifetimeLabel,
    isProfileMaintained,
  }
}
