import { Button, Card, EmptyState } from '@common'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { useNavigate, useParams } from 'react-router-dom'

interface Props {
  chargeType: string
  canEdit?: boolean
}

const NoAssetLocations = ({ chargeType, canEdit = true }: Props) => {
  const params = useParams()
  const { data: isAssetDesc, isLoading: isAssetDetailLoading } = useGetAssetQuery({
    assetCompanyID: parseInt(params.companyID),
    assetID: params.assetID,
  })

  const EditingEnabled =
    isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false
  const navigate = useNavigate()

  if (isAssetDetailLoading) return <>Loading...</>

  return (
    <Card>
      <EmptyState
        heading={`No ${chargeType.toLowerCase()} records have been added to this asset`}
        primaryAction={
          <Button
            appearance="primary"
            disabled={EditingEnabled}
            onClick={() => navigate(`create`)}
            hidden={!canEdit}
          >
            Add {chargeType.toLowerCase()} record
          </Button>
        }
      />
    </Card>
  )
}
export default NoAssetLocations
