import { Grid, InputSkeleton } from '@components/common'

const AssetOrganizationLoading: React.FC = () => {
  return (
    <Grid>
      <Grid.Item>
        <InputSkeleton label />
        <InputSkeleton label />
      </Grid.Item>
    </Grid>
  )
}

export default AssetOrganizationLoading
