import { apiSlice } from '@api'
import {
  GetHistoryProfilesResponse,
  GetAssetHistoryPeriodsParams,
  AssetHistoryRecord,
  AssetHasHistoryPayload,
  GetAssetHistoryParams,
  AssetHistoryLockParams,
  HistoryBase,
} from '../models'

export const assetHistoryEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getHistoryProfiles: build.query<GetHistoryProfilesResponse, number>({
      query: assetCompanyID =>
        `AssetHistory/GetHistoryProfiles?assetCompanyID=${assetCompanyID}`,
      providesTags: [],
    }),
    getAssetHistoryPeriods: build.query<string[], GetAssetHistoryPeriodsParams>({
      query: payload =>
        `/History/GetAssetHistoryPeriods?assetCompanyId=${payload.assetCompanyID}&assetId=${payload.assetID}&profile=${payload.profile}`,
      providesTags: ['assetHistory', 'periods'],
    }),
    getAssetHistory: build.query<AssetHistoryRecord, GetAssetHistoryParams>({
      query: payload =>
        `/History/GetAssetHistory?assetCompanyId=${payload.assetCompanyID}&assetId=${payload.assetID}&profile=${payload.profile}&date=${payload.date}`,
      providesTags: ['assetHistory'],
    }),
    lockAssetHistory: build.mutation<void, AssetHistoryLockParams>({
      query: payload => ({
        url: `/History/Lock?assetCompanyId=${payload.assetCompanyID}&assetId=${payload.assetID}&FN_SYS_NR=${payload.FN_SYS_NR}&FN_TYP_CD=${payload.FN_TYP_CD}&FN_AS_DAT=${payload.FN_AS_DAT}`,
        method: 'GET',
      }),

      invalidatesTags: ['assetHistory'],
    }),
    unlockAssetHistory: build.mutation<void, AssetHistoryLockParams>({
      query: payload => ({
        url: `/History/Unlock?assetCompanyId=${payload.assetCompanyID}&assetId=${payload.assetID}&FN_SYS_NR=${payload.FN_SYS_NR}&FN_TYP_CD=${payload.FN_TYP_CD}&FN_AS_DAT=${payload.FN_AS_DAT}`,
        method: 'GET',
      }),

      invalidatesTags: ['assetHistory'],
    }),
    manualCreateAssetHistory: build.mutation<void, HistoryBase>({
      query: payload => ({
        url: 'History/Create',
        method: 'POST',
        body: {
          ...payload,
        },
      }),
      invalidatesTags: ['assetHistory'],
    }),
    assetHasHistory: build.query<boolean, AssetHasHistoryPayload>({
      query: ({ assetCompanyID, assetID }) =>
        `History/AssetHasHistory?assetCompanyId=${assetCompanyID}&assetId=${assetID}`,
      providesTags: ['assetHistory'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetHistoryProfilesQuery,
  useAssetHasHistoryQuery,
  useGetAssetHistoryPeriodsQuery,
  useGetAssetHistoryQuery,
  useLockAssetHistoryMutation,
  useUnlockAssetHistoryMutation,
  useManualCreateAssetHistoryMutation,
} = assetHistoryEndpoints
