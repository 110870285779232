import { AllAssetDetailFields, FieldLabels } from '@api/models'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import { Flexbox, Grid, Input, Select, Spinner, Fieldset } from '@components/common'
import { useListGLPostsQuery } from '@api'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { BUILDING_CODES, DISPOSITION_OPTIONS, PROPERTY_CODES } from '@constants'

interface Props {
  fields: FieldLabels
}

const AssetPreferencesForm = ({ fields }: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
  } = useFormContext<AllAssetDetailFields>()

  const { getInputProps, getSelectProps } = useFormFieldProps<AllAssetDetailFields>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  const {
    data: glPostCodes,
    isLoading: isLoadingGlPostCodes,
    isSuccess: isSuccessGettingGlPostCodes,
  } = useListGLPostsQuery()
  const postCodeOptions: ReactSelectOptions = []
  if (isSuccessGettingGlPostCodes) {
    for (const code of glPostCodes) {
      postCodeOptions.push({
        label: `${code.ID} - ${code.Name}`,
        value: code.ID,
      })
    }
  }

  if (isLoadingGlPostCodes)
    return (
      <Flexbox justifyContent="center" alignItems="center" height={240}>
        <Spinner />
      </Flexbox>
    )

  return (
    <Grid>
      {/* SERVICE DEFAULTS*/}
      <Grid.Item>
        <Fieldset legend="Service defaults">
          <Grid>
            <Grid.Item>
              <Controller
                control={control}
                name="AS_SERD_CD"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('AS_SERD_CD', field, DISPOSITION_OPTIONS)}
                    autoFocus
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="AS_SAPC_ID"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('AS_SAPC_ID', field, postCodeOptions)}
                    type="autocomplete"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="AS_SDPC_ID"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('AS_SDPC_ID', field, postCodeOptions)}
                    type="autocomplete"
                  />
                )}
              />
            </Grid.Item>
          </Grid>
        </Fieldset>
      </Grid.Item>
      {/* LOCATIONS DEFAULTS*/}
      <Grid.Item>
        <Fieldset legend="Location defaults">
          <Grid>
            <Grid.Item>
              <Controller
                control={control}
                name="AS_LOCD_CD"
                render={({ field }) => (
                  <Select {...getSelectProps('AS_LOCD_CD', field, DISPOSITION_OPTIONS)} />
                )}
              />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="AS_LAPC_ID"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('AS_LAPC_ID', field, postCodeOptions)}
                    type="autocomplete"
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="AS_LDPC_ID"
                render={({ field }) => (
                  <Select
                    {...getSelectProps('AS_LDPC_ID', field, postCodeOptions)}
                    type="autocomplete"
                  />
                )}
              />
            </Grid.Item>
          </Grid>
        </Fieldset>
      </Grid.Item>
      {/* LOCATIONS DEFAULTS*/}
      <Grid.Item>
        <Fieldset legend="Property defaults">
          <Grid>
            <Grid.Item>
              <Input {...getInputProps('AS_TAX_PTP')} />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="AS_TAXL_CD"
                render={({ field }) => (
                  <Select {...getSelectProps('AS_TAXL_CD', field, PROPERTY_CODES)} />
                )}
              />
            </Grid.Item>
            <Grid.Item>
              <Controller
                control={control}
                name="AS_TAXB_CD"
                render={({ field }) => (
                  <Select {...getSelectProps('AS_TAXB_CD', field, BUILDING_CODES)} />
                )}
              />
            </Grid.Item>
          </Grid>
        </Fieldset>
      </Grid.Item>
    </Grid>
  )
}

export default AssetPreferencesForm
