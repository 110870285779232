import React from 'react'
import { TextProps } from './Text.types'

import { useStyletron } from 'styletron-react'
import {
  text_blockStyles,
  text_boldStyles,
  text_dangerStyles,
  text_italicStyles,
  text_lightStyles,
  text_smallStyles,
  text_successStyles,
  text_warningStyles,
  text_centeredStyles,
} from './Styles'

const Text: React.FC<TextProps> = ({
  p = false,
  light = false,
  block = false,
  italic = false,
  small = false,
  bold = false,
  intent = 'default',
  centered = false,
  ...props
}) => {
  const [css] = useStyletron()
  var classNames = `${props.className ? props.className : ''}`
  if (light) classNames += ' ' + css(text_lightStyles)
  if (block) classNames += ' ' + css(text_blockStyles)
  if (italic) classNames += ' ' + css(text_italicStyles)
  if (small) classNames += ' ' + css(text_smallStyles)
  if (bold) classNames += ' ' + css(text_boldStyles)
  if (centered) classNames += ' ' + css(text_centeredStyles)
  if (intent === 'success') classNames += ' ' + css(text_successStyles)
  if (intent === 'warning') classNames += ' ' + css(text_warningStyles)
  if (intent === 'danger') classNames += ' ' + css(text_dangerStyles)
  if (p) {
    return (
      <p className={classNames} id={props.id} {...props}>
        {props.children}
      </p>
    )
  } else if (small) {
    return (
      <small className={classNames} id={props.id} {...props}>
        {props.children}
      </small>
    )
  } else {
    return (
      <span className={classNames} id={props.id} {...props}>
        {props.children}
      </span>
    )
  }
}
export default Text
