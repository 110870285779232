import { filterFields } from './filter-fields'
import AssetsTableFilter from './Filter'
import { AccordionItem } from '@components/common/Accordion/Accordion.types'
import { Accordion, Tag } from '@components/common'
import { ActiveFilterTag } from '../AssetsTable.types'

interface Props {
  fields
  activeFilterTags: ActiveFilterTag[]
  removeFilter: (field: string, isRange: boolean, isMin?: boolean, isMax?: boolean) => void
}
const Filters = ({ fields, activeFilterTags, removeFilter }: Props) => {
  // Sort fields alphabetically by field/column name
  filterFields.sort(function (a, b) {
    var textA = fields[a.column].toUpperCase()
    var textB = fields[b.column].toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })

  const isFilterActive = (column: string): boolean => {
    if (activeFilterTags.filter(activeFilter => activeFilter.field === column).length > 0) {
      return true
    }

    return false
  }

  const fieldActiveFilterTag = (column: string) => {
    return activeFilterTags.find(activeFilter => activeFilter.field === column)
  }

  const filterFieldsInSidesheet = filterFields.filter(filter => filter.column !== 'AS_DESC')

  const accordionItems: AccordionItem[] = filterFieldsInSidesheet.map(filter => ({
    label: fields[filter.column],
    content: <AssetsTableFilter filter={filter} />,
    subHeading: isFilterActive(filter.column) ? (
      <Tag
        content={fieldActiveFilterTag(filter.column).label}
        appearance="default"
        remove={() =>
          removeFilter(
            fieldActiveFilterTag(filter.column).field,
            fieldActiveFilterTag(filter.column).isRange,
            fieldActiveFilterTag(filter.column).isMin,
            fieldActiveFilterTag(filter.column).isMax
          )
        }
      />
    ) : null,
  }))

  return (
    <div>
      <Accordion items={accordionItems} />
    </div>
  )
}
export default Filters
