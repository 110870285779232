import {
  Card,
  Flexbox,
  Grid,
  InputSkeleton,
  TableSkeleton,
  TextSkeleton,
} from '@components/common'

const FieldPrefsLoading: React.FC = () => {
  return (
    <>
      <Grid>
        <Grid.Item m={8}>
          <Card spacing="none" overflow="hidden">
            <Flexbox
              justifyContent="flex-start"
              style={{ padding: 'var(--scale500)', gap: '10px' }}
            >
              <InputSkeleton width="260px" />
              <InputSkeleton width="640px" />
            </Flexbox>
          </Card>

          <Card spacing="none" overflow="hidden">
            <TableSkeleton rows={20} />
          </Card>

          <Card spacing="none" overflow="hidden">
            <Flexbox justifyContent="space-between" style={{ padding: 'var(--scale600)' }}>
              <TextSkeleton width="110px" height="var(--scale600)" />
              <TextSkeleton width="var(--scale1600)" height="var(--scale600)" />
            </Flexbox>
          </Card>
        </Grid.Item>
      </Grid>
    </>
  )
}

export default FieldPrefsLoading
