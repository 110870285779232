import React from 'react'
import { InputProps } from './Input.types'
import BaseInput from './BaseInput'

const PasswordInput: React.FC<InputProps> = ({
  type = 'text',

  label,
  size = 'default',
  error = false,
  helper,
  errorMsg,
  disabled,
  icon,
  iconRight,
  ...props
}) => {
  return (
    <BaseInput
      type={type}
      size={size}
      icon={icon}
      iconRight={iconRight}
      helper={helper}
      error={error}
      errorMsg={errorMsg}
      name={props.name}
      label={label}
      id={props.id}
      onChange={props.onChange}
      value={props.value}
      disabled={disabled}
      maxLength={props.maxLength}
      minLength={props.minLength}
      autoFocus={props.autoFocus}
      required={props.required}
      onKeyPress={props.onKeyPress}
      onKeyDown={props.onKeyDown}
      onKeyUp={props.onKeyUp}
      step={type === 'number' && props.step}
      min={type === 'number' && props.min}
      max={type === 'number' && props.max}
      pattern={props.pattern}
      readOnly={props.readOnly}
      placeholder={props.placeholder}
      aria-labelledby={
        props['aria-labelledby'] ? props['aria-labelledby'] : `${props.name}-label`
      }
    />
  )
}
export default PasswordInput
