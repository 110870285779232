import {
  Card,
  Flexbox,
  InputSkeleton,
  TableSkeleton,
  TextSkeleton,
} from '@components/common'

const FoldersTableLoading: React.FC = () => {
  return (
    <>
      <Card spacing="none" overflow="hidden">
        <Flexbox
          justifyContent="flex-start"
          style={{ padding: 'var(--scale500)', gap: '10px' }}
        >
          <InputSkeleton width="640px" />
        </Flexbox>
      </Card>

      <Card spacing="none" overflow="hidden">
        <TableSkeleton rows={5} />
      </Card>

      <Card spacing="none" overflow="hidden">
        <Flexbox justifyContent="space-between" style={{ padding: 'var(--scale600)' }}>
          <TextSkeleton width="110px" height="var(--scale600)" />
          <TextSkeleton width="var(--scale1600)" height="var(--scale600)" />
        </Flexbox>
      </Card>
    </>
  )
}

export default FoldersTableLoading
