// TODO: Payment Terms

import { CustomDatePicker, Form, Grid, Input, Tabs } from '@common'
import { useFields, useFormFieldProps } from '@hooks'
import { INSURANCE_FORM_ID } from '@constants'
import { InsurancePolicy, insuranceSchema } from '@api/models'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getTodaysDate } from '@utils/basic'

interface InsuranceFormProps {
  isEdit: boolean
  initialValues
  handleSubmit: (payload: InsurancePolicy) => Promise<void>
  canEdit?: boolean
}

const InsuranceForm = ({
  isEdit,
  initialValues,
  handleSubmit,
  canEdit = true,
}: InsuranceFormProps) => {
  const { fields } = useFields()

  const {
    register,
    handleSubmit: formHandleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useForm({
    defaultValues: isEdit
      ? initialValues
      : { ...initialValues, IN_CP_FRM: getTodaysDate(), IN_CP_TO: getTodaysDate() },
    mode: 'onBlur',
    resolver: yupResolver(insuranceSchema),
  })
  const { getDateProps } = useFormFieldProps<InsurancePolicy>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  function getInputProps(name: keyof InsurancePolicy) {
    return {
      ...register(name),
      disabled: isSubmitting || !canEdit,
      error: errors[name] !== undefined,
      errorMsg: errors[name]?.message,
      label: fields[name],
    }
  }

  function onSubmitHandler(values: InsurancePolicy) {
    handleSubmit(values)
  }

  return (
    <Form id={INSURANCE_FORM_ID} onSubmit={formHandleSubmit(onSubmitHandler)} noValidate>
      <Grid>
        <Grid.Item>
          <Input {...getInputProps('IN_POL_ID')} autoFocus={!isEdit} readOnly={isEdit} />
        </Grid.Item>
        <Grid.Item>
          <Input {...getInputProps('IN_DESC')} autoFocus={isEdit} />
        </Grid.Item>
        <Grid.Item>
          <Tabs>
            <Tabs.Item title="Company Information">
              <Grid>
                <Grid.Item>
                  <Input {...getInputProps('IN_NAME')} />
                </Grid.Item>
                <Grid.Item>
                  <Input {...getInputProps('IN_STREET')} />
                </Grid.Item>
                <Grid.Item s={9}>
                  <Input {...getInputProps('IN_CITY')} />
                </Grid.Item>
                <Grid.Item s={3}>
                  <Input {...getInputProps('IN_STATE')} helper="2 character code" />
                </Grid.Item>
                <Grid.Item>
                  <Input {...getInputProps('IN_CNTY')} />
                </Grid.Item>
                <Grid.Item>
                  <Input {...getInputProps('IN_ZIP')} />
                </Grid.Item>
                <Grid.Item>
                  <Input {...getInputProps('IN_PHONE')} />
                </Grid.Item>
                <Grid.Item>
                  <Input {...getInputProps('IN_FAX')} />
                </Grid.Item>
              </Grid>
            </Tabs.Item>
            <Tabs.Item title="Policy Details">
              <Grid>
                <Grid.Item>
                  <Input {...getInputProps('IN_INS_AMT')} icon={'$'} />
                </Grid.Item>
                <Grid.Item>
                  <Input {...getInputProps('IN_PRM_AMT')} icon={'$'} />
                </Grid.Item>
                <Grid.Item>
                  <Input {...getInputProps('IN_OC_DED')} icon={'$'} />
                </Grid.Item>
                <Grid.Item>
                  <Input {...getInputProps('IN_YR_DED')} icon={'$'} />
                </Grid.Item>
                <Grid.Item>
                  <Input {...getInputProps('IN_AG_LMT')} icon={'$'} />
                </Grid.Item>
                <Grid.Item>
                  <Controller
                    control={control}
                    name="IN_CP_FRM"
                    render={({ field }) => (
                      <CustomDatePicker
                        {...getDateProps('IN_CP_FRM', field)}
                        label={fields[`IN_CP_FRM`]}
                      />
                    )}
                  />
                </Grid.Item>
                <Grid.Item>
                  <Controller
                    control={control}
                    name="IN_CP_TO"
                    render={({ field }) => (
                      <CustomDatePicker
                        {...getDateProps('IN_CP_TO', field)}
                        label={fields[`IN_CP_TO`]}
                      />
                    )}
                  />
                </Grid.Item>
              </Grid>
            </Tabs.Item>
          </Tabs>
        </Grid.Item>
      </Grid>
    </Form>
  )
}
export default InsuranceForm
