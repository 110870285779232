import { Company, CompanyKey, companySchema, FieldLabels } from '@api/models'
import { useForm, UseFormHandleSubmit, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useCreateCompanyMutation,
  useDoesCompanyHaveHistoryQuery,
  useEditCompanyMutation,
  useGetCompanyQuery,
} from '@api'
import { useFields, usePicklist } from '@hooks'
import { toast } from '@components/common'
import { COMPANY_PATHS } from '@constants'
import { useDeleteCompanyMutation } from '@api/companies/companiesEndpoints'

export interface CompanyDetailsCardsState {
  isBasicInfoCardOpen: boolean
  isDeprecInfoCardOpen: boolean
}

export type CompanyHookContext = 'company-details' | 'create-company'

interface CompanyHookProps {
  context: CompanyHookContext
  companyID: number
}

interface ICompanyHook {
  methods: UseFormReturn<Company>
  handleSubmit: UseFormHandleSubmit<Company>
  isDirty: boolean
  isValid: boolean
  errors: any
  isEmpty: (obj: any) => boolean

  cardsState: CompanyDetailsCardsState
  setCardsState: Dispatch<SetStateAction<CompanyDetailsCardsState>>
  isDiscardModalOpen: boolean
  setIsDiscardModalOpen: Dispatch<SetStateAction<boolean>>
  discardChanges: () => void

  company: Company
  companyHasHistory: boolean
  fields: FieldLabels
  isLoadingPicklist: boolean

  isLoadingCompanyDetails: boolean
  isLoadingCreateCompany: boolean

  isBasicInfoEdited: boolean
  isDeprecInfoEdited: boolean

  saveCompany: (payload: Company) => Promise<void>
  isSaving: boolean

  shouldBlockNavigation: boolean

  deleteCompany: (payload: Company) => Promise<void>
  isDeleteCompanyModalOpen: boolean
  setIsDeleteCompanyModalOpen: Dispatch<SetStateAction<boolean>>
  isLoadingDeleteCompany: boolean
  isSuccessDeleteCompany: boolean
}

export default function useCompany({ context, companyID }: CompanyHookProps): ICompanyHook {
  const navigate = useNavigate()

  // Declare form hook
  const methods = useForm<Company>({
    resolver: yupResolver(companySchema),
    mode: 'onChange',
    defaultValues: companySchema.cast({}),
  })

  const {
    reset,
    formState: { dirtyFields, isDirty, isValid, errors },
    handleSubmit,
  } = methods

  // Form ui states
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false)

  const [cardsState, setCardsState] = useState<CompanyDetailsCardsState>({
    isBasicInfoCardOpen: context === 'create-company' ? true : false,
    isDeprecInfoCardOpen: context === 'create-company' ? true : false,
  })

  function resetFormUiState(): void {
    setIsDiscardModalOpen(false)
    setCardsState({
      isBasicInfoCardOpen: false,
      isDeprecInfoCardOpen: false,
    })
  }

  function discardChanges(): void {
    if (context === 'company-details') {
      resetFormUiState()
      reset(company)
    } else {
      navigate('/companies')
    }
  }

  function isEmpty(obj: any) {
    return Object.keys(obj).length === 0
  }

  // Get data
  const { data: company, isLoading: isLoadingCompany } = useGetCompanyQuery(companyID, {})

  const {
    data: companyHasHistory,
    isLoading: isLoadingCompanyHasHistory,
  } = useDoesCompanyHaveHistoryQuery(companyID, {
    skip: context === 'create-company',
  })

  const { fields, isLoading: isLoadingFields } = useFields()
  const { isLoading: isLoadingPicklist } = usePicklist({
    skipRequest: !cardsState.isDeprecInfoCardOpen && context === 'company-details',
  })

  const isLoadingCompanyDetails =
    isLoadingCompany || isLoadingCompanyHasHistory || isLoadingFields

  const isLoadingCreateCompany = context === 'create-company' && isLoadingPicklist

  // Form sections
  const basicInfoFields: CompanyKey[] = ['AssetCompanyID', 'CM_DESC', 'CM_DESCS']
  let isBasicInfoEdited: boolean
  for (const field of basicInfoFields) {
    if (dirtyFields[field] === true) {
      isBasicInfoEdited = true
    }
  }

  const deprecInfoFields: CompanyKey[] = [
    'CM_YE_MM',
    'CM_YE_DD',
    'CM_NR_PER',
    'CM_YTD_BCD',
    'CM_TAX',
    'CM_SMH',
    'CM_STAT_CD',
    'CM_B4MH',
    'CM_BOK4_CD',
  ]
  let isDeprecInfoEdited: boolean
  for (const field of deprecInfoFields) {
    if (dirtyFields[field] === true) {
      isDeprecInfoEdited = true
    }
  }

  // On submit
  const [editCompany, { isLoading: isLoadingEditCompany }] = useEditCompanyMutation()
  const [
    createCompany,
    { isLoading: isLoadingCreateCompanyMutation, isSuccess: isSuccessCreateCompany },
  ] = useCreateCompanyMutation()

  async function saveCompany(payload: Company): Promise<void> {
    try {
      if (context === 'create-company') {
        await createCompany(payload).unwrap()

        toast.success(`Successfully created company ${payload.AssetCompanyID}`)

        navigate(
          `/${COMPANY_PATHS.ROOT}/${payload.AssetCompanyID}/${COMPANY_PATHS.DETAILS}`
        )

        resetFormUiState()
      } else {
        await editCompany(payload).unwrap()

        reset(payload)

        toast.success(`Company ${payload.AssetCompanyID} successfully updated.`)

        resetFormUiState()
      }
    } catch (error: any) {
      toast.error(error.data.validation_errors[0])
    }
  }

  const isSaving: boolean = isLoadingEditCompany || isLoadingCreateCompanyMutation

  // Navigation blocker
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false)

  useEffect(
    function setShouldBlockNavigationOnDirty() {
      setShouldBlockNavigation(isDirty)
    },
    [isDirty]
  )

  useEffect(
    function allowNavigationFromCreateView() {
      if (context === 'create-company') {
        // Allow navigation back to categories from discard modal
        if (isDiscardModalOpen) {
          setShouldBlockNavigation(false)
        } else {
          setShouldBlockNavigation(isDirty)
        }

        // Allow navigation to new deprec class page after creation
        if (isLoadingCreateCompanyMutation || isSuccessCreateCompany) {
          setShouldBlockNavigation(false)
        }
      }
    },
    [isDiscardModalOpen, isLoadingCreateCompanyMutation, isSuccessCreateCompany, isDirty]
  )

  // Company deletion
  const [isDeleteCompanyModalOpen, setIsDeleteCompanyModalOpen] = useState<boolean>(false)
  const [
    deleteCompanyQuery,
    { isLoading: isLoadingDeleteCompany, isSuccess: isSuccessDeleteCompany },
  ] = useDeleteCompanyMutation()

  async function deleteCompany(payload: Company): Promise<void> {
    try {
      await deleteCompanyQuery(payload).unwrap()
      toast.success(
        `Successfully deleted company ${payload.AssetCompanyID}: ${payload.CM_DESC}`
      )
      navigate('/companies')
    } catch (error: any) {
      toast.error(error.data)
    }
  }

  return {
    methods,
    handleSubmit,
    isDirty,
    isValid,
    errors,
    isEmpty,

    cardsState,
    setCardsState,
    isDiscardModalOpen,
    setIsDiscardModalOpen,
    discardChanges,

    company,
    companyHasHistory,
    fields,
    isLoadingPicklist,

    isLoadingCompanyDetails,
    isLoadingCreateCompany,

    isBasicInfoEdited,
    isDeprecInfoEdited,

    saveCompany,
    isSaving,

    shouldBlockNavigation,

    deleteCompany,
    isDeleteCompanyModalOpen,
    setIsDeleteCompanyModalOpen,
    isLoadingDeleteCompany,
    isSuccessDeleteCompany,
  }
}
