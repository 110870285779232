import React from 'react'
import { SKELETON_SHINE } from '@styles-api/css-constants'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

interface Props {
  size?: 'mini' | 'compact' | 'default' | 'large'
  height?: string
  flexGrow?: boolean
  title?: boolean
}

const NoteSkeleton: React.FC<Props> = ({ size, height, flexGrow, title }) => {
  const [css] = useStyletron()

  let containerStyles = css({ ...styles.container({ height, flexGrow }) })

  let titleStyles = css({
    ...styles.labelSkeleton,
    ...SKELETON_SHINE,
  })

  let bodyStyles = css({
    ...styles.noteSkeleton({ size }),
    ...SKELETON_SHINE,
  })

  return (
    <div className={containerStyles}>
      {title && <div className={titleStyles} />}
      <div className={bodyStyles} />
    </div>
  )
}

export default NoteSkeleton
