import { StyleObject } from '@styles-api/css.types'

export const tooltip__wrapperStyles: StyleObject = {
  display: 'inline-block',
  position: 'relative',
  width: 'max-content',
}

export const tooltip__baseStyles: StyleObject = {
  position: 'absolute',
  background: 'var(--elevated-surface-background2)',
  zIndex: 'var(--z2)',
  boxShadow: 'var(--shadow2)',
  borderRadius: 'var(--radius1)',
  maxWidth: '320px',
  paddingLeft: 'var(--scale500)',
  paddingRight: 'var(--scale500)',
  paddingTop: 'var(--scale200)',
  paddingBottom: 'var(--scale200)',
  width: 'max-content',
  borderWidth: '1px',

  '::before': {
    content: '""',
    left: '50%',
    backgroundColor: 'var(--elevated-surface-background2)',
    height: '6px',
    width: '6px',
    position: 'absolute',
    pointerEvents: 'none',
    transform: 'rotate(45deg)',
    marginLeft: '-3px',
    marginTop: '-2px',
    borderBottomWidth: '1px',
    borderRightWidth: '1px',
  },
}

/* ---------------------- POSITIONS --------------------- */

// =====================================
// *Note: translate X & Y values are placed in the motion.div to maintain animation
// =====================================
export const tooltip_topStyles: StyleObject = {
  bottom: '32px',
  left: '50%',
  '::before': {
    top: '100%',
    borderTopColor: 'var(--elevated-surface-background2)',
  },
}
export const tooltip_rightStyles: StyleObject = {
  left: 'calc(100% + 24px)',
  top: '50%',
  '::before': {
    transform: 'translateX(0) translateY(-50%)',
    top: '50%',
    left: '-6px',
    borderRightColor: 'var(--elevated-surface-background2)',
  },
}
export const tooltip_bottomStyles: StyleObject = {
  bottom: '-32px',
  left: '50%',
  '::before': {
    bottom: '100%',
    borderBottomColor: 'var(--elevated-surface-background2)',
  },
}
export const tooltip_leftStyles: StyleObject = {
  right: 'calc(100% + 24px)',
  top: '50%',
  '::before': {
    transform: 'translateX(0%) translateY(-50%)',
    left: 'auto',
    top: '50%',
    right: '-12px',
    borderLeftColor: 'var(--elevated-surface-background2)',
  },
}

/* ---------------------- INTENTS --------------------- */
export const tooltip_successStyles: StyleObject = {
  color: 'var(--success-color)',
}
export const tooltip_warningStyles: StyleObject = {
  color: 'var(--warning-color)',
}
export const tooltip_dangerStyles: StyleObject = {
  color: 'var(--danger-color)',
}
