import View from '@components/View'
import AssetLocationWrapper from '@components/asset/AssetLocations/AssetLocationWrapper'
import { useParams } from 'react-router-dom'
interface AssetLocationViewProps {
  isCreate: boolean
}
const AssetLocationWrapperView = ({ isCreate }: AssetLocationViewProps) => {
  const params = useParams()
  const systemNumber = parseInt(params?.locationID)

  return (
    <View title="Asset Charge">
      <AssetLocationWrapper isCreate={isCreate} systemNumber={systemNumber} />
    </View>
  )
}

export default AssetLocationWrapperView
