import { StyleObject } from '@styles-api/css.types'

export const base: StyleObject = {
  ':last-child': {
    borderTopLeftRadius: '0!important',
    borderBottomLeftRadius: '0!important',
  },
  ':first-child': {
    borderTopRightRadius: '0!important',
    borderBottomRightRadius: '0!important',
    marginRight: '-1px',
  },
}
