import { AllAssetDetailFields, FieldLabels } from '@api/models'
import { Button, Card, Text } from '@components/common'
import { Dispatch, SetStateAction } from 'react'
import AssetMainDetailsDisplay from './AssetMainDetailsDisplay'
import AssetMainDetailsForm from './AssetMainDetailsForm'
import { IUserGrantProps } from '@components/Auth/UserRole'
import AssetMainDetailsDisplayLoading from './AssetMainDetailsDisplayLoading'

interface Props extends IUserGrantProps {
  fields: FieldLabels
  isLoadingInfo: boolean
  dirtyFields: object
  isMainDetailsFormActive: boolean
  setIsMainDetailsFormActive: Dispatch<SetStateAction<boolean>>
  isAssetDisposedOrPendingDisposal: boolean
}

const AssetMainDetails = ({
  fields,
  isLoadingInfo,
  dirtyFields,
  isMainDetailsFormActive,
  setIsMainDetailsFormActive,
  isAssetDisposedOrPendingDisposal,
  canEdit,
}: Props) => {
  const mainDetailsFields: Array<keyof AllAssetDetailFields> = [
    'AS_DESC',
    'AS_DESC_E',
    'AS_VEN_ID',
    'AS_VEN_NM',
    'AS_IN_POL',
    'AS_PRI_UCD',
    'AS_SEC_UCD',
    'AS_REC_PON',
    'AS_NSER_DT',
    'AS_REP_DAT',
    'AS_WRO_CD',
    'AS_UC1_UCD',
    'AS_UC2_UCD',
    'AS_UC3_UCD',
  ]

  let isCardValuesEdited: boolean
  for (const field of mainDetailsFields) {
    if (dirtyFields[field] === true) {
      isCardValuesEdited = true
    }
  }

  const editComponent = isCardValuesEdited ? (
    <Text light>Edited</Text>
  ) : (
    !isAssetDisposedOrPendingDisposal && (
      <Button
        onClick={() => setIsMainDetailsFormActive(!isMainDetailsFormActive)}
        appearance="text"
      >
        {isMainDetailsFormActive ? 'Close' : 'Edit'}
      </Button>
    )
  )

  return (
    <Card title="Main details" {...(canEdit && { addon: editComponent })}>
      {isMainDetailsFormActive && !isAssetDisposedOrPendingDisposal ? (
        <AssetMainDetailsForm fields={fields} />
      ) : (
        <>
          {isLoadingInfo && <AssetMainDetailsDisplayLoading />}
          {!isLoadingInfo && <AssetMainDetailsDisplay fields={fields} />}
        </>
      )}
    </Card>
  )
}
export default AssetMainDetails
