import React from 'react'
import { useStyletron } from 'styletron-react'
import { Heading, Icon } from '..'
import { ModalHeaderProps } from './Modal.types'

import * as styles from './Styles'

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, close }) => {
  const [css] = useStyletron()
  return (
    <div className={css(styles.header)}>
      <Heading type="h1" baseSize bold>
        {title}
      </Heading>

      <button className={css(styles.closeButton)} onClick={close} type="reset">
        <Icon name="X" className={css(styles.closeIcon)} />
      </button>
    </div>
  )
}
export default ModalHeader
