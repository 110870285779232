import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTableParams } from '@hooks'
import { TableParamsHook } from 'hooks/useTableParams'
import { FieldParm, FetchFieldParmsResponse } from '@api/models'
import { useModal, toast } from '@components/common'
import { useFetchFieldParmsQuery, useEditFieldParmMutation } from '@api'
import {
  ReactSelectOption,
  ReactSelectOptions,
} from '@components/common/Select/Select.types'
import { SortingRule } from 'react-table'

export interface FieldPrefsHook extends TableParamsHook {
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  selectedFieldParm: FieldParm | undefined
  setSelectedFieldParm: Dispatch<SetStateAction<FieldParm>>

  selectTablePrefixValue: ReactSelectOption
  searchKey: string
  fieldParms: FetchFieldParmsResponse
  isLoadingFieldParms: boolean
  isErrorFetchingFieldParms: boolean
  isRefetchingFieldParms: boolean

  handleEditFieldParm: (payload: FieldParm) => Promise<void>
  isLoadingEditFieldParm: boolean

  handleChangeTablePrefix
  openEditFieldParmModal: (fieldParm: FieldParm) => void
  closeEditFieldParmModal
  editFieldParmModalIsOpen: boolean
  sortByInitialState: () => SortingRule<any>[]
  selectTablePrefixes: ReactSelectOptions
}

const useFieldPrefs = (): FieldPrefsHook => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])

  const [filterState, setFilterState] = useState({})
  const [selectedFieldParm, setSelectedFieldParm] = useState<FieldParm>(undefined)

  const searchKey = 'PM_PMT'
  const initialItemsPerPage = 20

  // Use useTableParams hook for table filtering, pagination, etc.
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  const {
    data: fieldParms,
    isLoading: isLoadingFieldParms,
    isError: isErrorFetchingFieldParms,
    isFetching: isRefetchingFieldParms,
  } = useFetchFieldParmsQuery({
    ...fetchRequestPayload,
    tablePrefix: searchParams.get('tablePrefix') ?? 'AS',
  })

  // Field parm table prefix values
  const selectTablePrefixes: ReactSelectOptions = useMemo(
    () => [
      { label: 'Asset', value: 'AS' },
      { label: 'Charge', value: 'CH' },
      { label: 'Comment', value: 'CO' },
      { label: 'Company', value: 'CM' },
      { label: 'Period', value: 'CP' },
      { label: 'Depreciation', value: 'DP' },
      { label: 'Folder', value: 'FL' },
      { label: 'History', value: 'FN' },
      { label: 'G/L', value: 'GL' },
      { label: 'Insurance', value: 'IN' },
      { label: 'Vouch', value: 'JV' },
      { label: 'Segment', value: 'KY' },
      { label: 'Maintanence', value: 'MN' },
      { label: 'Task/Activity', value: 'OT' },
      { label: 'Maintanence', value: 'MN' },
      { label: 'Custom Depreciation', value: 'SP' },
      { label: 'Transfers', value: 'TR' },
    ],
    []
  )

  const handleChangeTablePrefix = useCallback(
    (e: { label: string; value: any }) => {
      setSearchParams({
        ...searchParamsObject,
        tablePrefix: e.value.toString(),
        pageNumber: '1',
      })
    },
    [searchParamsObject, setSearchParams]
  )

  const selectTablePrefixValue: ReactSelectOption = {
    label:
      selectTablePrefixes.find(
        prefixes => prefixes.value === searchParams.get('tablePrefix')
      )?.label ?? selectTablePrefixes[0].label,
    value: searchParams.get('tablePrefix') ?? selectTablePrefixes[0].value,
  }

  const {
    modalIsOpen: editFieldParmModalIsOpen,
    triggerModal: triggerEditFieldParmModal,
    closeModal: closeEditFieldParmModal,
  } = useModal()

  function openEditFieldParmModal(fieldParm: FieldParm): void {
    setSelectedFieldParm(fieldParm)
    triggerEditFieldParmModal()
  }

  // Edit
  const [editFieldParm, { isLoading: isLoadingEditFieldParm }] = useEditFieldParmMutation()
  async function handleEditFieldParm(payload: FieldParm): Promise<void> {
    try {
      await editFieldParm(payload).unwrap()
      closeEditFieldParmModal()
      toast.success(`Field successfully updated.`)
    } catch (error) {
      toast.error('There was an unexpected error updating this field.')
    }
  }

  const tablePrefix = searchParams.get('tablePrefix')
  useEffect(() => {
    if (!tablePrefix) {
      handleChangeTablePrefix(selectTablePrefixes[0])
    }
  }, [handleChangeTablePrefix, selectTablePrefixes, tablePrefix])

  return {
    // tableParams return
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    //
    selectTablePrefixes,
    isLoadingEditFieldParm,
    handleEditFieldParm,
    openEditFieldParmModal,
    editFieldParmModalIsOpen,
    selectTablePrefixValue,
    isRefetchingFieldParms,
    isErrorFetchingFieldParms,
    isLoadingFieldParms,
    fieldParms,
    handleChangeTablePrefix,
    selectedFieldParm,
    setSelectedFieldParm,
    closeEditFieldParmModal,

    //
    filterState,
    setFilterState,
    searchKey,
  }
}

export default useFieldPrefs
