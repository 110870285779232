import { ImportType } from '../importTypes'
import { assets } from './assets'
import { categories } from './categories'
import { companies } from './companies'
import { fields } from './fields'
import { folders } from './folders'
import { glposts } from './glposts'
import { insurance } from './insurance'
import { maintenance } from './maintenance'
import { segments } from './segments'
import { tasks } from './tasks'
import { vendors } from './vendors'

export const getOptions = (entity: ImportType) => {
  switch (entity) {
    case 'assets':
      return assets
    case 'categories':
      return categories
    case 'companies':
      return companies
    case 'fields':
      return fields
    case 'folders':
      return folders
    case 'glposts':
      return glposts
    case 'insurance':
      return insurance
    case 'maintenance':
      return maintenance
    case 'segments':
      return segments
    case 'tasks':
      return tasks
    case 'vendors':
      return vendors
    default:
      return []
  }
}
