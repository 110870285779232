import AssetHistory from '@components/asset/AssetHistory'
import View from '@components/View'
import { useParams } from 'react-router-dom'

const AssetHistoryView = () => {
  const params = useParams()

  return (
    <View title="History">
      <AssetHistory assetCompanyID={parseInt(params.companyID)} assetID={params.assetID} />
    </View>
  )
}

export default AssetHistoryView
