import { Palette } from '@styles-api/palette.types'
import { StyleObject } from '@styles-api/css.types'

export const base: StyleObject = {
  boxShadow: 'var(--shadow3)',
  borderRadius: 'var(--radius3)',
  minHeight: 'var(--scale1600)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 'var(--scale800)',
  width: '100%',
  paddingLeft: 'var(--scale700)',
  paddingRight: 'var(--scale700)',
  paddingTop: 'var(--scale700)',
  paddingBottom: 'var(--scale700)',
  position: 'relative',
  overflow: 'hidden',
}
export const getType = ({ type }) => {
  let backgroundColor: Palette
  let color: Palette

  switch (type) {
    case 'default':
      backgroundColor = 'var(--base-color)'
      color = 'var(--text-color)'
      break
    case 'success':
      backgroundColor = 'var(--success-color)'
      color = 'var(--base-color)'
      break
    case 'error':
      backgroundColor = 'var(--danger-color)'
      color = 'var(--base-color)'
      break
    case 'warning':
      backgroundColor = 'var(--warning-color)'
      color = 'var(--base-color)'
      break
  }
  return {
    backgroundColor,
    color,
  }
}
export const content: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  paddingRight: 'calc(var(--scale650) + 12px)',
}
export const icon: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  marginRight: 'var(--scale500)',
  height: 'var(--scale700)',
  width: 'var(--scale700)',
}
export const dismissButton: StyleObject = {
  position: 'absolute',
  top: 0,
  right: 0,
  paddingLeft: 'var(--scale700)',
  paddingRight: 'var(--scale700)',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: 'var(--base-color)',
  ':hover': {
    color: 'var(--shade200)',
  },
  ':active': {
    color: 'var(--base-color)',
  },
}
export const dismissIcon: StyleObject = {
  height: 'var(--scale550)',
  width: 'var(--scale550)',
}
/* ----------------------- DEFAULT ---------------------- */
export const toast_defaultStyles: StyleObject = {
  background: 'var(--elevated-surface-background3)',
  color: 'var(--text-color)',
}

/* ------------------------ INFO ------------------------ */
export const toast_infoStyles: StyleObject = {
  background: 'var(--primary-color)',
  color: 'var(--interaction-contrast)',
}
export const toast_info__dismissStyles: StyleObject = {
  color: 'var(--interaction-contrast)',
  background: 'var(--primary-color)',

  ':hover': {
    background: 'var(--primary-color500)!important',
    color: 'var(--interaction-contrast)',
  },
}
/* ----------------------- SUCCESS ---------------------- */
export const toast_successStyles: StyleObject = {
  background: 'var(--success-color)',
  color: 'var(--interaction-contrast)',
}
export const toast_success__dismissStyles: StyleObject = {
  color: 'var(--interaction-contrast)',
  backgroundColor: 'var(--success-color)',

  ':hover': {
    backgroundColor: 'var(--success-color500)',
    color: 'var(--interaction-contrast)',
  },
}
/* ----------------------- WARNING ---------------------- */
export const toast_warningStyles: StyleObject = {
  background: 'var(--warning-color)',
  color: 'var(--warningColorContrastText)',
}
export const toast_warning__dismissStyles: StyleObject = {
  color: 'var(--warningColorContrastText)',
  background: 'var(--warningColor)',

  ':hover': {
    background: 'var(--warningColorDark)',
    color: 'var(--warningColorContrastText)',
  },
}
/* ----------------------- DANGER ----------------------- */
export const toast_dangerStyles: StyleObject = {
  background: 'var(--danger-color)',
  color: 'var(--interaction-contrast)',
}
export const toast_danger__dismissStyles: StyleObject = {
  color: 'var(--interaction-contrast)',
  background: 'var(--danger-color)',

  ':hover': {
    background: 'var(--danger-color500)!important',
    color: 'var(--interaction-contrast)',
  },
}
