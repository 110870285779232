import { Card, Form, Grid, Spacer, toast } from '@components/common'
import { FormProvider } from 'react-hook-form'
import { ASSET_REGISTER_REPORT_PATH, ERROR_REQUEST } from '@constants'
import ReportFilters from '../ReportFilters'
import { defaultReportFilterFields } from '../ReportFilters/report-filter-fields'
import { AssetRegisterReportParams } from '@api/models'
import { useReport } from '@hooks'
import { useEffect } from 'react'
import { ReportsApi } from '@api'
import AssetRegisterOptions from './AssetRegisterOptions'
import AssetSelector from './AssetSelector'
import AssetIndividualSelections from './AssetIndividualSelections'
import { useLocation, useSearchParams } from 'react-router-dom'
import { reportResponseHandler } from '@utils/reports'
import { useMemorizeReport } from '../General/MemorizeReports/useMemorizeReport'
import MemorizeReports from '../General/MemorizeReports/MemorizeReport'
import { ActionButtons } from '../General'
import { useFormMethods } from '../General/useFormMethods'

const defaultValues: AssetRegisterReportParams = {
  assetCompanyID: 0,
  filter: [],
  sections: {
    identification: false,
    acquisition: false,
    disposal: false,
    transfers: false,
    corporateProfile: false,
    taxProfile: false,
    stateProfile: false,
    otherProfile: false,
    corporateHistory: false,
    taxHistory: false,
    stateHistory: false,
    otherHistory: false,
    service: false,
    location: false,
    taskActivity: false,
    folders: false,
  },
  sectionComments: {
    identification: false,
    acquisition: false,
    disposal: false,
    transfers: false,
    corporateProfile: false,
    taxProfile: false,
    stateProfile: false,
    otherProfile: false,
    corporateHistory: false,
    taxHistory: false,
    stateHistory: false,
    otherHistory: false,
    service: false,
    location: false,
    taskActivity: false,
    folders: false,
  },
  assets: [],
  fileFormat: 'pdf',
}

const AssetRegisterForm = () => {
  const location = useLocation()
  const locationState: any = location.state
  const [, setSearchParams] = useSearchParams()

  const {
    methods,
    isRetrievingTemplate,
    isEditTemplate,
    selectedTemplateDetails,
  } = useFormMethods(locationState, defaultValues)

  const {
    formState: { isSubmitting },
    setValue,
  } = methods

  const {
    isLoading,
    isSuccess,
    isError,
    isNoCompanies,
    depProfilesMap,
    assetCompanyID,
  } = useReport()

  const {
    handleMemorizeTemplateSubmit,
    isMemorizeTemplateModalOpen,
    closeMemorizeTemplateModal,
    triggerMemorizeTemplateModal,
    handleEditTemplateSubmit,
  } = useMemorizeReport({ methods, currentReportPath: ASSET_REGISTER_REPORT_PATH })

  useEffect(() => {
    if (isSuccess) {
      setValue('assetCompanyID', assetCompanyID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, assetCompanyID, isSuccess])

  useEffect(() => {
    if (assetCompanyID !== 0) {
      // Fix for search params
      let paramSplit = window.location.search
        ? window.location.search.split('?')[1].split('&')
        : []
      let params = {} as any

      Object.keys(paramSplit).forEach(key => {
        params[paramSplit[key].split('=')[0]] = paramSplit[key].split('=')[1]
      })

      setSearchParams({
        ...params,
        assetCompanyID: assetCompanyID.toString(),
        pageNumber: '1',
        itemsPerPage: '20',
      })

      if (!isRetrievingTemplate) {
        setValue('assets', [])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetCompanyID])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (isNoCompanies || assetCompanyID === 0) {
    return <div>No companies found</div>
  }

  async function onSubmitHandler(values: AssetRegisterReportParams) {
    await ReportsApi.getInstance()
      .getAssetRegisterReport(values)
      .then(data => reportResponseHandler(data, 'pdf'))
      .catch(() => toast.error(ERROR_REQUEST))
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Card title="Report Parameters">
            <Grid>
              <Grid.Item l={6}>
                <Grid>
                  <Grid.Item>
                    <AssetIndividualSelections />
                  </Grid.Item>

                  <Grid.Item>
                    <ReportFilters
                      filtersTemplateData={
                        isRetrievingTemplate
                          ? locationState?.templateDetails.templateData.filter
                          : []
                      }
                      filterFields={defaultReportFilterFields}
                    />
                  </Grid.Item>

                  <Grid.Item>
                    <AssetRegisterOptions
                      depProfilesMap={depProfilesMap}
                      assetCompanyID={assetCompanyID}
                      sectionsInitialValues={
                        locationState?.templateDetails.templateData.sections
                      }
                      sectionCommentsInitialValues={
                        locationState?.templateDetails.templateData.sectionComments
                      }
                    />
                  </Grid.Item>
                </Grid>
              </Grid.Item>

              <Grid.Item l={6}>
                <AssetSelector />
              </Grid.Item>
            </Grid>
          </Card>
          <Spacer y="default" />

          <ActionButtons
            isSubmitting={isSubmitting}
            pdfOnly={true}
            allowExcel={false}
            triggerMemorizeTemplateModal={triggerMemorizeTemplateModal}
            isEditTemplate={isEditTemplate}
          />
        </Form>
      </FormProvider>

      <MemorizeReports
        isMemorizeTemplateModalOpen={isMemorizeTemplateModalOpen}
        closeMemorizeTemplateModal={closeMemorizeTemplateModal}
        handleMemorizeTemplateSubmit={handleMemorizeTemplateSubmit}
        handleEditTemplateSubmit={handleEditTemplateSubmit}
        isEditTemplate={isEditTemplate}
        selectedTemplateDetails={selectedTemplateDetails}
      />
    </>
  )
}

export default AssetRegisterForm
