export enum Events {
  SHOW = 'show',
  HIDE = 'hide',
  HIDE_ALL = 'hideAll',
}

export type Type = 'default' | 'success' | 'error' | 'warning'

export type ToastContent = any

export type ToastConfig = {
  backgroundColor?: string
  color?: string
}

export interface Toast {
  id: string
  content: ToastContent
  type: Type
  config?: ToastConfig
}

export interface ToastProvider {
  content: ToastContent
  config?: ToastConfig
}
