import { DepreciationClass, FieldLabels } from '@api/models'
import { useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import { Grid, Input } from '@components/common'
import { DeprecClassHookProps } from '@components/depreciation-class/DepreciationClass.types'

interface Props extends DeprecClassHookProps {
  fields: FieldLabels
}

const DeprecClassMainDetailsForm = ({ fields, context }: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<DepreciationClass>()

  const { getInputProps } = useFormFieldProps<DepreciationClass>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  return (
    <Grid>
      {context === 'create' && (
        <Grid.Item>
          <Input {...getInputProps('DP_OBJ_ID')} autoFocus={context === 'create'} />
        </Grid.Item>
      )}

      <Grid.Item>
        <Input {...getInputProps('DP_DESC')} autoFocus={context !== 'create'} />
      </Grid.Item>
    </Grid>
  )
}

export default DeprecClassMainDetailsForm
