import { StyleObject } from '@styles-api/css.types'

export const base: StyleObject = {
  backgroundColor: 'var(--border-color)',
  border: 'none',
}
export const getOrientation = ({ orientation }) => {
  return {
    width: orientation === 'horizantal' ? '100%' : '1px',
    height: orientation === 'horizantal' ? '1px' : '100%',
  }
}
export const getSpacing = ({ orientation, spacing }) => {
  let spacingVal = `var(--gap-${spacing})`
  if (spacing)
    return {
      marginTop: orientation === 'horizantal' ? spacingVal : '0',
      marginBottom: orientation === 'horizantal' ? spacingVal : '0',
      marginLeft: orientation === 'vertical' ? spacingVal : '0',
      marginRight: orientation === 'vertical' ? spacingVal : '0',
    }
}
