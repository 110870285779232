import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { apiSlice, osmApiSlice } from '@api'
import { createBrowserHistory } from 'history'
import userManager from './userManager'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createOidcMiddleware from 'redux-oidc'
import { reducer as oidcReducer } from 'redux-oidc'
import companyReducer from '@store/reducers/companyReducer'
import filtersReducer from '@store/filtersSlice'
import assetRegisterFilters from '@store/assetRegisterFiltersSlice'
import { userManagementAPI } from '@api/admPortal'
import { applicationAPI } from '@api/admPortal/applicationAPI'
import { organizationAPI } from '@api/admPortal/organizationAPI'

export const history = createBrowserHistory()

export function createAppStore() {
  userManager.events.addUserLoaded(user => {
    if (user) {
      sessionStorage.setItem(`oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`, JSON.stringify(user))
    }
  })

  userManager.events.addSilentRenewError(error => {
    console.error('Error renewing silently:', error)
  })

  userManager.events.addAccessTokenExpiring(async () => {
    const userDetails = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`))
    if (userDetails) {
      userManager.startSilentRenew()
    }
  })

  userManager.events.addAccessTokenExpired(async () => {
    await userManager.signinSilentCallback().then(user => {
      if (user) {
        sessionStorage.setItem(`oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`, JSON.stringify(user))
      }
    })
  })

  return configureStore({
    reducer: combineReducers({
      [apiSlice.reducerPath]: apiSlice.reducer,
      [osmApiSlice.reducerPath]: osmApiSlice.reducer,
      [userManagementAPI.reducerPath]: userManagementAPI.reducer,
      [applicationAPI.reducerPath]: applicationAPI.reducer,
      [organizationAPI.reducerPath]: organizationAPI.reducer,
      router: connectRouter(history),
      oidc: oidcReducer,
      company: companyReducer,
      filters: filtersReducer,
      assetRegisterFilters: assetRegisterFilters,
    }),

    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        apiSlice.middleware,
        osmApiSlice.middleware,
        userManagementAPI.middleware,
        applicationAPI.middleware,
        organizationAPI.middleware,
        createOidcMiddleware(userManager),
        routerMiddleware(history)
      ),
  })
}
