import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios'

const STATUS_401_UNAUTHORIZED = 401

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class HttpClient {
  protected readonly instance: AxiosInstance
  protected readonly contentType: string

  public constructor(baseURL: string, responseType?: ResponseType, contentType?: string) {
    this.contentType = contentType === undefined ? 'application/json' : contentType
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URI}${baseURL}`,
      responseType,
    })

    this._initializeResponseInterceptor()
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError)
    this.instance.interceptors.request.use(this._handleRequest)
  }

  private _handleRequest = (config: AxiosRequestConfig) => {
    const oidcUserSessionPath = `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`
    if (sessionStorage.getItem(oidcUserSessionPath) == null) {
      this.redirectToLogin()
    }

    const token = JSON.parse(sessionStorage.getItem(oidcUserSessionPath)).access_token
    config.headers['Authorization'] = `Bearer ${token}`
    config.headers['Content-type'] = this.contentType
    return config
  }

  private _handleResponse = (res: AxiosResponse) => res

  protected _handleError = (error: any) => {
    if (error.response.status === STATUS_401_UNAUTHORIZED) {
      this.redirectToLogin()
    }

    return Promise.reject(error)
  }

  private redirectToLogin = () => {
    sessionStorage.clear()
    localStorage.clear()
    window.location.reload()
  }
}

export default HttpClient
