import { StyleObject } from '@styles-api/css.types'

export const wrapper: StyleObject = {
  height: '100%',
  width: '100%',
}

export const helpIcon: StyleObject = {
  height: 'var(--scale550)',
  width: 'var(--scale550)',
  ':hover': { cursor: 'help' },
}
