import React from 'react'
import { StepProps } from './Stepper.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import Icon from '../Icon'

const Step: React.FC<StepProps> = ({ step, status, label }) => {
  const [css] = useStyletron()
  let baseStyles = css({ ...styles.step({ status }), ...styles.stepAlignCenter })
  let stepInidcatorStyles = css({
    ...styles.stepIndicator({ status }),
  })
  let labelStyles = css(styles.stepLabel)
  let checkStyles = css(styles.check)

  return (
    <li className={baseStyles}>
      <div className={stepInidcatorStyles}>
        {status === 'current' || status === 'upcoming' ? (
          step + 1
        ) : (
          <Icon name="Check" className={checkStyles} />
        )}
      </div>
      <div className={labelStyles}> {label}</div>
    </li>
  )
}
export default Step
