import { Form, Icon, Modal, Text } from '@components/common'
import useDiscardRunHook from './useDiscardRunHook'
import DepreciationCard from './DepreciationCard'
import { useDepreciationStatus } from '@components/layout/Frame/TopBar/DepreciationStatus/useDepreciationStatus'

interface DiscardRunProps {
  assetCompanyID: number
}

const DiscardRun = ({ assetCompanyID }: DiscardRunProps) => {
  const {
    handleDiscardRunSubmit,
    isDiscardModalOpen,
    setIsDiscardModalOpen,
  } = useDiscardRunHook({
    assetCompanyID,
  })

  const { depRunStatus } = useDepreciationStatus()

  return (
    <>
      <Modal
        intent="danger"
        actionLabel="Discard Run"
        title={`Discard Run`}
        isOpen={isDiscardModalOpen}
        close={() => setIsDiscardModalOpen(false)}
        width={540}
        form={'depreciation_discard_run_form'}
        actionAutoFocus
        isActionLoading={false}
      >
        <Form
          id="depreciation_discard_run_form"
          noValidate
          onSubmit={handleDiscardRunSubmit}
        >
          <Text p>
            Are you sure you want to discard the run? <b>This action cannot be undone. </b>
          </Text>
        </Form>
      </Modal>

      <DepreciationCard
        disabled={isDiscardModalOpen || depRunStatus.IsRunningDepreciation === false}
        loading={false}
        openForm={() => setIsDiscardModalOpen(true)}
        helpText="Discard the current run. This action cannot be undone."
        icon={<Icon name="XSquare" color="var(--danger-color)" />}
        type="Discard"
        titleColor="var(--danger-color)"
      />
    </>
  )
}

export default DiscardRun
