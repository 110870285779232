import * as Yup from 'yup'
import { REQUIRED_FIELD } from '@constants'
import { currencyValidation, dateExistValidation } from '../../models/validation'
import { getTodaysDate, maxStringMsg } from '@utils/basic'
import { AssetRecalcDTO } from '@api/assets/models'
import { DepreciationProfileCode } from './DepreciationClass.model'

export interface FetchCustomDepreciationResponse {
  List: CustomDepreciation[]
  Method: string //'C' | 'D' | 'G' | 'N' | 'P' | 'S' | 'U'
}
export interface CustomDepreciation {
  AssetCompanyID: number
  SP_OBJ_ID: string
  SP_TYP_CD: string
  SP_YMO_FRM: string
  SP_YMO_TO: string
  SP_KEY: string
  SP_YOFL: number
  SP_DEP_AMT: number
  SP_RATE: number
  SP_MET_CD: string
  SP_ID_UCD: string
  SP_SUB_CMT: string
}
export interface FetchCustomDepreciationParams {
  assetCompanyID: number
  profile: DepreciationProfileCode
  objectID: string
}

export interface EditCustomDepreciationWrapper {
  deprecsp: CustomDepreciation
  deprecspRecalcDTO: AssetRecalcDTO
  prevFromDate: string
  userFullName: string
}

// for create/delete form
export interface CustomDepreciationWrapper {
  deprecsp: CustomDepreciation
  deprecspRecalcDTO: AssetRecalcDTO
  userFullName: string
}

export interface CustomDepreciationRecalcPayload {
  deprecsp: CustomDepreciation
  prevFromDate: string
  isEdit: boolean
  isDelete: boolean
}

export const customDepreciationSchema = Yup.object({
  AssetCompanyID: Yup.number().required(REQUIRED_FIELD),
  SP_OBJ_ID: Yup.string().required(REQUIRED_FIELD),
  SP_TYP_CD: Yup.string().required(REQUIRED_FIELD),
  SP_YMO_FRM: Yup.string()
    .typeError(REQUIRED_FIELD)
    .required(REQUIRED_FIELD)
    .default(getTodaysDate())
    .test(dateExistValidation),
  SP_YMO_TO: Yup.string()
    .typeError(REQUIRED_FIELD)
    .required(REQUIRED_FIELD)
    .default(getTodaysDate())
    .test(dateExistValidation),
  SP_KEY: Yup.string().nullable(),
  SP_YOFL: Yup.number().typeError(REQUIRED_FIELD).test(currencyValidation).default(0),
  SP_DEP_AMT: Yup.number().typeError(REQUIRED_FIELD).test(currencyValidation).default(0),
  SP_RATE: Yup.number().typeError(REQUIRED_FIELD).test(currencyValidation).default(0),
  SP_MET_CD: Yup.string().required(REQUIRED_FIELD),
  SP_ID_UCD: Yup.string().max(12, maxStringMsg(12)).nullable(),
  SP_SUB_CMT: Yup.string().required(REQUIRED_FIELD),
})
