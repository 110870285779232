import AssetDetails from '@components/asset/AssetDetails'
import View from '@components/View'

const AssetMainView = () => {
  return (
    <View title="Details">
      <AssetDetails />
    </View>
  )
}

export default AssetMainView
