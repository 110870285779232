import React from 'react'
import { SelectProps } from './Select.types'

import AutocompleteSelect from './Autocomplete'
import CreatableSelect from './CreatableSelect'
import CreatableMultiSelect from './CreatableMultiSelect'
import MultiSelect from './MultiSelect'

const Select = React.forwardRef<HTMLElement, SelectProps>(
  ({ type = 'default', isClearable = true, ...props }, ref) => {
    switch (type) {
      case 'default':
        return (
          <AutocompleteSelect
            ref={ref}
            placeholder={props.placeholder}
            isClearable={false}
            isSearchable={false}
            type={type}
            {...props}
          />
        )
      case 'autocomplete':
        return (
          <AutocompleteSelect
            ref={ref}
            placeholder={props.placeholder}
            isClearable={isClearable}
            type={type}
            {...props}
          />
        )
      case 'creatable':
        return (
          <CreatableSelect
            ref={ref}
            placeholder={props.placeholder}
            isClearable={isClearable}
            type={type}
            {...props}
          />
        )
      case 'multi':
        return (
          <MultiSelect
            ref={ref}
            placeholder={props.placeholder}
            isClearable={isClearable}
            type={type}
            {...props}
          />
        )
      case 'creatable-multi':
        return (
          <CreatableMultiSelect
            ref={ref}
            placeholder={props.placeholder}
            isClearable={isClearable}
            type={type}
            {...props}
          />
        )
    }
  }
)
export default Select
