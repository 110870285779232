import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { importInput__labelStyles, importInput__nativeInputStyles } from './Styles';
import { Button, Flexbox, Icon, Spacer, Text } from '@common';
import { formatBytes } from '@utils/basic';

interface ImportInputProps {
  acceptedFormatsDesc?: string;
  uploadDesc?: React.ReactNode;
  onChange: any;
  onRemove: () => void;
  file: File;
}

const ImportInput: React.FC<ImportInputProps> = ({
  onChange,
  onRemove,
  uploadDesc,
  acceptedFormatsDesc,
  file,
}) => {
  const [css] = useStyletron();
  const [isDragging, setIsDragging] = useState(false);
  const dropRef = useRef<HTMLDivElement | null>(null);

  const handleDrag = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragIn = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragOut = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      onChange({ target: { files: e.dataTransfer.files } });
      e.dataTransfer.clearData();
    }
  }, [onChange]);

  useEffect(() => {
    const div = dropRef.current;
    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', handleDrop);

      return () => {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      };
    }
  }, [handleDrag, handleDragIn, handleDragOut, handleDrop]);

  if (file) {
    const lastModified = new Date(file.lastModified);

    return (
      <>
        <Text light block>
          File name: {file.name}
        </Text>
        <Text light block>
          Size: {formatBytes(file.size)}
        </Text>
        <Text light block>
          Last modified: {lastModified.toString()}
        </Text>
        <Spacer y="default" />
        <Button intent="danger" appearance="default" onClick={() => onRemove()} flex>
          Remove attachment
        </Button>
      </>
    );
  }

  return (
    <>
      <div
        ref={dropRef}
        className={css({
          border: isDragging ? '2px dashed #000' : '2px dashed #ccc',
          borderRadius: '4px',
          textAlign: 'center',
        })}
      >
        <input
          type="file"
          onChange={onChange}
          name="importData"
          id="importData"
          className={css(importInput__nativeInputStyles)}
        />
        <label htmlFor="importData" id="importData" className={css(importInput__labelStyles)}>
          <Flexbox justifyContent="center" flexDirection="column" alignItems="center">
            <Icon name="Upload" appearance="light" size="xl" />
            <Spacer y="large" />
            <Text light>{uploadDesc}</Text>
            <Text light>{acceptedFormatsDesc}</Text>
          </Flexbox>
        </label>
      </div>
    </>
  );
};

export default ImportInput;
