import { useAuth } from 'oidc-react'
import ProfilePrefsMain from './ProfilePrefsMain'
import ProfileLoading from './ProfileLoading'

const ProfilePrefs = () => {
  const { userData, isLoading: isLoadingUserInfo } = useAuth()

  return (
    <>
      {isLoadingUserInfo ? (
        <ProfileLoading />
      ) : (
        <>
          <ProfilePrefsMain user={userData?.profile} />
        </>
      )}
    </>
  )
}

export default ProfilePrefs
