import React from 'react'

interface Props {
  className?: string
}

const PassClassDown: React.FC<Props> = ({ className, ...props }) => {
  return (
    <>
      {React.Children.map(props.children, child => {
        return React.cloneElement(child as React.ReactElement<any>, {
          className: className,
        })
      })}
    </>
  )
}
export default PassClassDown
