import { apiSlice } from '@api'
import { formatISODate } from '@utils/basic'
import {
  GetYearsPayload,
  Period,
  FetchPeriodsPayload,
  EditPeriodsPayload,
  GeneratePeriodsParams,
} from './models'

export const periodsEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getYears: build.query<number[], GetYearsPayload>({
      query: payload => `Periods/GetYears?companyId=${payload.assetCompanyID}`,
      providesTags: ['years', 'periods'],
    }),
    fetchPeriods: build.query<Period[], FetchPeriodsPayload>({
      query: payload =>
        `Periods/FetchPeriods?companyId=${payload.assetCompanyID}&year=${payload.year}`,
      transformResponse: (res: Period[]) => {
        let periods: Period[] = []

        for (const period of res) {
          periods.push({
            ...period,
            CP_DATE: formatISODate(period.CP_DATE.toString()),
          })
        }

        return periods
      },
      providesTags: ['periods', 'years'],
    }),
    editPeriods: build.mutation<void, EditPeriodsPayload>({
      query: payload => ({
        url: `/Periods/Edit`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['periods'],
    }),
    generatePeriods: build.mutation<void, GeneratePeriodsParams>({
      query: payload => ({
        url: 'Periods/Generate',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['periods', 'years'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetYearsQuery,
  useFetchPeriodsQuery,
  useEditPeriodsMutation,
  useGeneratePeriodsMutation,
} = periodsEndpoints
