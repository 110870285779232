import View from '@components/View'
import AssetChargeWrapper from '@components/asset/AssetCharges/AssetChargeWrapper'
import { useParams } from 'react-router-dom'

interface AssetChargeViewProps {
  chargeTypeCode: 'P' | 'S' | 'O' | 'L'
  isCreate: boolean
}
const AssetChargeView = ({ chargeTypeCode, isCreate = false }: AssetChargeViewProps) => {
  const params = useParams()

  const systemNumber = parseInt(params?.systemNumber)
  return (
    <View title="Asset Charge">
      <AssetChargeWrapper
        assetCompanyID={parseInt(params.companyID)}
        assetID={params.assetID}
        chargeTypeCode={chargeTypeCode}
        isCreate={isCreate}
        systemNumber={systemNumber}
      />
    </View>
  )
}

export default AssetChargeView
