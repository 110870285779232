import React from 'react'
import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

interface Props {
  width?: string
  height?: string
}

const TextSkeleton: React.FC<Props> = ({ width = '100%', height }) => {
  const [css] = useStyletron()

  let skeletonStyles = css({
    ...styles.skeleton({ width, height }),
  })
  return <span className={skeletonStyles} />
}
export default TextSkeleton
