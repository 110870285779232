import { useEditSettingsMutation, useGetSettingsQuery } from '@api'
import { Setting } from '@api/models'
import { toast } from '@components/common'
import { isNullOrUndefined } from '@utils/basic'

export interface CustomDateFormatHook {
  onSubmit: (finalResult: Setting) => Promise<void>
  DateFormats: string
  isGettingSettings: boolean
}

export default function useSettingDate(): CustomDateFormatHook {
  const [editSettings] = useEditSettingsMutation()
  const { data: DateFormatValue, isLoading: isGettingSettings } = useGetSettingsQuery(
    'DateFormat'
  )

  const DateFormats = !isNullOrUndefined(DateFormatValue) ? DateFormatValue : 'yyyy/MM/dd'

  async function onSubmit(formValues: Setting): Promise<void> {
    try {
      await editSettings({ SS_KEY: 'DateFormat', SS_VALUE: formValues.SS_VALUE })
      toast.success('Date format selection saved successfully.')
    } catch (error) {
      toast.error('Error saving date format selection.')
    }
  }

  return {
    onSubmit,
    DateFormats,
    isGettingSettings,
  }
}
