import { AllAssetDetailFields, FieldLabels } from '@api/models'
import { Button, Card, Text } from '@components/common'
import { Dispatch, SetStateAction } from 'react'
import AssetPreferencesDisplay from './AssetPreferencesDisplay'
import AssetPreferencesForm from './AssetPreferencesForm'
import { IUserGrantProps } from '@components/Auth/UserRole'
import AssetPreferencesDisplayLoading from './AssetPreferencesDisplayLoading'

interface Props extends IUserGrantProps {
  fields: FieldLabels
  isLoadingInfo: boolean
  dirtyFields: object
  isPreferencesFormActive: boolean
  setIsPreferencesFormActive: Dispatch<SetStateAction<boolean>>
  isAssetDisposedOrPendingDisposal: boolean
}

const AssetPreferences = ({
  fields,
  isLoadingInfo,
  dirtyFields,
  isPreferencesFormActive,
  setIsPreferencesFormActive,
  isAssetDisposedOrPendingDisposal,
  canEdit,
}: Props) => {
  const preferencesFields: Array<keyof AllAssetDetailFields> = [
    'AS_SERD_CD',
    'AS_SAPC_ID',
    'AS_SDPC_ID',
    'AS_LOCD_CD',
    'AS_LAPC_ID',
    'AS_LDPC_ID',
    'AS_TAX_PTP',
    'AS_TAXL_CD',
    'AS_TAXB_CD',
  ]

  let isCardValuesEdited: boolean
  for (const field of preferencesFields) {
    if (dirtyFields[field] === true) {
      isCardValuesEdited = true
    }
  }

  const editComponent = isCardValuesEdited ? (
    <Text light>Edited</Text>
  ) : (
    !isAssetDisposedOrPendingDisposal && (
      <Button
        onClick={() => setIsPreferencesFormActive(!isPreferencesFormActive)}
        appearance="text"
      >
        {isPreferencesFormActive ? 'Close' : 'Edit'}
      </Button>
    )
  )

  return (
    <Card title="Preferences" {...(canEdit && { addon: editComponent })}>
      {(isPreferencesFormActive || isCardValuesEdited) &&
      !isAssetDisposedOrPendingDisposal ? (
        <AssetPreferencesForm fields={fields} />
      ) : (
        <>
          {isLoadingInfo && <AssetPreferencesDisplayLoading />}

          {!isLoadingInfo && (
            <AssetPreferencesDisplay
              fields={fields}
              setIsPreferencesFormActive={setIsPreferencesFormActive}
              isAssetDisposedOrPendingDisposal
            />
          )}
        </>
      )}
    </Card>
  )
}
export default AssetPreferences
