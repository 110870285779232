import HttpClient from '../../HttpClient'

export class HistoryApi extends HttpClient {
  private static classInstance?: HistoryApi

  public constructor() {
    super(`/History`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new HistoryApi()
    }

    return this.classInstance
  }

  public fetch = (assetCompanyId: number, assetId: string, profile: string) =>
    this.instance.get(
      `/FetchHistory?assetCompanyId=${assetCompanyId}&assetId=${assetId}&profile=${profile}`
    )
  public assetHasHistory = (assetCompanyId: number, assetId: string) =>
    this.instance.get(
      `/AssetHasHistory?assetCompanyId=${assetCompanyId}&assetId=${assetId}`
    )
  public get = (
    assetCompanyId: number,
    assetId: string,
    profile: string,
    date: string
  ) =>
    this.instance.get(
      `/GetAssetHistory?assetCompanyId=${assetCompanyId}&assetId=${assetId}&profile=${profile}&date=${date}`
    )
  public getAssetHistoryPeriods = (
    assetCompanyId: number,
    assetId: string,
    profile: string
  ) =>
    this.instance.get(
      `/GetAssetHistoryPeriods?assetCompanyId=${assetCompanyId}&assetId=${assetId}&profile=${profile}`
    )
  public getHistoryProfile = () => this.instance.get(`/GetHistoryProfile`)
  public lock = (
    assetCompanyId: number,
    assetId: string,
    fnSysNr: number,
    fnTypCd: string,
    fnAsDat: string
  ) =>
    this.instance.get(
      `/Lock?assetCompanyId=${assetCompanyId}&assetId=${assetId}&FN_SYS_NR=${fnSysNr}&FN_TYP_CD=${fnTypCd}&FN_AS_DAT=${fnAsDat}`
    )
  public unlock = (
    assetCompanyId: number,
    assetId: string,
    fnSysNr: number,
    fnTypCd: string,
    fnAsDat: string
  ) =>
    this.instance.get(
      `/Unlock?assetCompanyId=${assetCompanyId}&assetId=${assetId}&FN_SYS_NR=${fnSysNr}&FN_TYP_CD=${fnTypCd}&FN_AS_DAT=${fnAsDat}`
    )
  public import = (file: any) => this.instance.post(`/ImportHistory`, file)
}
