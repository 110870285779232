import { Button, Card, Flexbox, Heading, Link, Spacer, Text } from '@components/common'
import { COMPANY_VIEWS } from '@constants'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  setIsGenerateModalOpen: Dispatch<SetStateAction<boolean>>
  assetCompanyID: number
  canEdit: boolean
}

const CompanyHasNoPeriods = ({
  setIsGenerateModalOpen,
  assetCompanyID,
  canEdit,
}: Props) => {
  return (
    <Card>
      <Flexbox justifyContent="center" alignItems="center" height={240}>
        <div style={{ maxWidth: '432px', marginLeft: 'auto', marginRight: 'auto' }}>
          <Heading type="h2" centered>
            No periods generated yet.
          </Heading>
          <Text light p centered>
            When you generate periods for a range of fiscal years, they will appear here. To
            change your year-end date and number of periods, go to{' '}
            <Link to={`/${COMPANY_VIEWS.ROOT}/${assetCompanyID}/${COMPANY_VIEWS.DETAILS}`}>
              company details
            </Link>
            .
          </Text>
          {canEdit && (
            <>
              <Spacer y="default" />
              <Button
                appearance="primary"
                onClick={() => setIsGenerateModalOpen(true)}
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
              >
                Generate periods
              </Button>
            </>
          )}
        </div>
      </Flexbox>
    </Card>
  )
}
export default CompanyHasNoPeriods
