import AccountSettingForm from './AccountSettingForm'
import AccountSettingsLoading from './AccountSettingsLoading'
import useAccounts from './useAccSettingPrefs'

const AccountSettingPrefs = () => {
  const {
    selectSegmentTypeOptions,
    isLoadingAccountList,
    onSubmit,
    Accounts,
    isGettingSettings,
  } = useAccounts()

  if (isLoadingAccountList || isGettingSettings) {
    return <AccountSettingsLoading />
  }
  return (
    <AccountSettingForm
      selectSegmentTypeOptions={selectSegmentTypeOptions}
      onSubmit={onSubmit}
      Accounts={Accounts}
      isGettingSettings={isGettingSettings}
    />
  )
}

export default AccountSettingPrefs
