import React from 'react'
import { useStyletron } from 'styletron-react'

import * as styles from './Styles'

const AccordionIcon = ({ isOpen }) => {
  // HTML for this SVG icon comes from the "Plus" react-feather icon
  // reason for this is to add class and transition to the first line

  const [css] = useStyletron()

  let lineStyles = {
    ...styles.getIconLineStyles({ isOpen }),
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={css(styles.icon)}
    >
      <line x1="12" y1="5" x2="12" y2="19" className={css(lineStyles)}></line>
      <line x1="5" y1="12" x2="19" y2="12"></line>
    </svg>
  )
}
export default AccordionIcon
