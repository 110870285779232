import { UserListItem } from '@api/models'
import {
  Button,
  ButtonGroup,
  Card,
  Flexbox,
  Heading,
  Icon,
  Modal,
  Search,
  Select,
  Spacer,
  Table,
  Text,
} from '@components/common'
import Pagination from '@components/Pagination'

import { useEffect, useMemo } from 'react'
import { Column, SortingRule, useFlexLayout, useSortBy, useTable } from 'react-table'
import { useUserTable } from './useUserTable'
import { UserInviteForm } from './UserInviteForm'
import UserTableLoading from './UserTableLoading'
import useAuth from '@hooks/useAuth'

export const UserTable = () => {
  const {
    users,
    sortByInitialState,
    updateSortSearchParam,
    isLoading,
    isError,
    handleSearch,
    searchKey,
    searchValue,
    isRefetchingUsers,
    isUserInviteModalOpen,
    openUserInviteModal,
    closeUserInviteModal,
    isAdmin,
    changePage,
    itemsPerPage,
    pageNumber,
    subbedAppRoles,
    selectedUser,
    isDeleteUserModalOpen,
    closeDeleteUserModal,
    openDeleteUserModal,
    isDisableUserModalOpen,
    closeDisableUserModal,
    openDisableUserModal,
    isEnableUserModalOpen,
    closeEnableUserModal,
    openEnableUserModal,
    handleDeleteUser,
    isLoadingDeleteUser,
    handleDisableUser,
    isLoadingDisableUser,
    handleEnableUser,
    isLoadingEnableUser,
    rolesOptions,
    handleRoleChange,
  } = useUserTable()

  const columns = useMemo<Column<UserListItem>[]>(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
        maxWidth: 100,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'userRoles',
        disableSortBy: true,
        Cell: ({ row }) => {
          const userRole =
            row.original.userRoles && row.original.userRoles.length > 0
              ? row.original.userRoles[0]
              : null
          const roleName = userRole ? userRole.roleName : null
          const label = roleName ? roleName.substring(13) : ''

          return (
            <Select
              value={{
                label: label,
                value: roleName,
              }}
              options={rolesOptions}
              onChange={selectedOption =>
                handleRoleChange({
                  email: row.original.email,
                  changeRoleTo: selectedOption.value,
                  userID: row.original.userID,
                })
              }
              width={'164px'}
              appearance="default"
              disabled={!row.original.isEnabled}
            />
          )
        },
      },
      {
        Header: ' ',
        accessor: 'actions',
        disableSortBy: true,
        Cell: ({ row }) => (
          <Flexbox alignItems="center" justifyContent="center" style={{ width: '100%' }}>
            {!row.original.isEnabled ? (
              <ButtonGroup>
                <Button
                  icon={<Icon name="CheckCircle" />}
                  onClick={e => {
                    e.stopPropagation()
                    openEnableUserModal(row.original)
                  }}
                  size="compact"
                >
                  Enable
                </Button>
                <Button
                  icon={<Icon name="Trash2" />}
                  intent="danger"
                  onClick={e => {
                    e.stopPropagation()
                    openDeleteUserModal(row.original)
                  }}
                  size="compact"
                >
                  Delete
                </Button>
              </ButtonGroup>
            ) : (
              <Button
                icon={<Icon name="XCircle" />}
                onClick={e => {
                  e.stopPropagation()
                  openDisableUserModal(row.original)
                }}
                size="compact"
              >
                Disable
              </Button>
            )}
          </Flexbox>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // Declare react table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: users ? users.list : [],
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        sortBy: sortByInitialState() as SortingRule<UserListItem>[],
      },
    },
    useSortBy,
    useFlexLayout
  )

  // Get User Name
  const { userName } = useAuth()

  // Update sort param
  useEffect(() => {
    updateSortSearchParam(sortBy, setSortBy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  if (isLoading) {
    return <UserTableLoading />
  }

  if (isError) {
    return <Table.NoResults heading="There was an error." />
  }

  return (
    <>
      <Modal
        isOpen={isDeleteUserModalOpen}
        close={closeDeleteUserModal}
        title={`Delete ${selectedUser?.email}`}
        width={540}
        action={() => handleDeleteUser(selectedUser)}
        actionLabel="Delete record"
        intent="danger"
        actionAutoFocus
        isActionLoading={isLoadingDeleteUser}
      >
        <Text p>
          Are you sure you want to delete <strong>{selectedUser?.email}</strong>?{' '}
          <Text intent="danger">This cannot be undone</Text>.
        </Text>
      </Modal>

      {/* modal for disable user */}
      <Modal
        isOpen={isDisableUserModalOpen}
        close={closeDisableUserModal}
        title={`Disable ${selectedUser?.email}`}
        width={540}
        action={() => handleDisableUser(selectedUser)}
        actionLabel="Disable user"
        intent="danger"
        actionAutoFocus
        isActionLoading={isLoadingDisableUser}
      >
        <Text p>
          Disable <strong>{selectedUser?.email}</strong>?
        </Text>
      </Modal>

      {/* modal for enable user */}
      <Modal
        isOpen={isEnableUserModalOpen}
        close={closeEnableUserModal}
        title={`Enable ${selectedUser?.email}`}
        width={540}
        action={() => handleEnableUser(selectedUser)}
        actionLabel="Enable user"
        intent="default"
        actionAutoFocus
        isActionLoading={isLoadingEnableUser}
      >
        <Text p>
          Enable <strong>{selectedUser?.email}</strong>?
        </Text>
      </Modal>

      <header>
        <Flexbox justifyContent="space-between" alignItems="center">
          <Flexbox flexDirection="column">
            <Heading type="h2" primary>
              User Account Management
            </Heading>

            <Spacer y="mini" />

            <Heading type="h6" style={{ color: 'var(--text-light)' }}>
              Welcome,{' '}
              <Text bold intent='default'>
                {userName}
              </Text>
              ! Below is the list of users from your company. You can easily view, edit, and
              manage their information.
            </Heading>
          </Flexbox>

          <Flexbox>
            <Search
              onChange={handleSearch}
              placeholder="Search by email..."
              name={searchKey}
              width={'350px'}
              value={searchValue}
            />
          </Flexbox>

          <Button
            onClick={() => openUserInviteModal()}
            appearance="primary"
            disabled={!isAdmin}
            size="default"
            style={{ minWidth: '100px' }}
          >
            Invite User
          </Button>
        </Flexbox>
      </header>

      <Spacer y="default" />

      <UserInviteForm
        isOpen={isUserInviteModalOpen}
        closeModal={closeUserInviteModal}
        subbedAppRoles={subbedAppRoles}
      />
      <Card spacing="none">
        {users.list.length === 0 ? (
          <Table.NoResults text="" />
        ) : (
          <>
            <Table
              {...getTableProps()}
              aria-label="User Table"
              label="User Table"
              aria-colcount={columns.length}
              aria-rowcount={users.list.length}
              height="calc(100vh - 280px)"
            >
              <Table.Head sticky>
                {isRefetchingUsers ? (
                  <Table.LoadingRows />
                ) : (
                  headerGroups.map((headerGroup, i) => (
                    <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, i) => (
                        <Table.Header
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          column={column}
                          key={i}
                          tabIndex={0}
                        >
                          {column.render('Header')}
                        </Table.Header>
                      ))}
                    </Table.HeadRow>
                  ))
                )}
              </Table.Head>

              <Table.Body {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Table.Row
                      {...row.getRowProps()}
                      // onClick={}
                      tabIndex={0}
                      key={i}
                    >
                      {row.cells.map(cell => (
                        <Table.Cell
                          {...cell.getCellProps()}
                          column={cell.column}
                          disabled={
                            row.original.isEnabled !== true && cell.column.Header !== ' '
                          }
                        >
                          {cell.render('Cell')}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <Pagination
              changePage={changePage}
              totalItems={users.count}
              maxItemsPerPage={itemsPerPage}
              currentPageNumber={pageNumber}
              label={`Showing ${users.list.length} of ${users.count} record${
                users.count > 1 ? 's' : ''
              }`}
            />
          </>
        )}
      </Card>
    </>
  )
}
