import { Card, InputSkeleton, Spacer } from '@common'

const AssetStructureLoading = () => {
  return (
    <Card>
      <InputSkeleton label />
      <Spacer y="default" />
      <InputSkeleton label />
      <Spacer y="default" />
      <InputSkeleton label />
      <Spacer y="default" />
      <InputSkeleton label />
      <Spacer y="default" />
      <InputSkeleton label />
      <Spacer y="default" />
      <InputSkeleton label />
      <Spacer y="default" />
      <InputSkeleton label />
      <Spacer y="default" />
      <InputSkeleton label />
      <Spacer y="default" />
      <InputSkeleton label />
      <Spacer y="default" />
      <InputSkeleton label />
      <Spacer y="default" />
    </Card>
  )
}
export default AssetStructureLoading
