import { apiSlice } from '@api'
import {
  CompanyStructureBase,
  CreateSegmentPayload,
  FetchSegmentsPayload,
  FetchSegmentsResponse,
  GetSegmentByKeyPayload,
  GetSegmentByKeyRespone,
  GetSegmentsPayload,
  Segment,
  SegmentBase,
  SegmentType,
} from './models'

export const segmentEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    // SEGMENTS
    getSegments: build.query<Segment[], GetSegmentsPayload>({
      query: ({ KY_NR, desc }) => `Segment/GetSegments?KYNR=${KY_NR}&desc=${desc}`,
      providesTags: ['segment'],
    }),
    listSegments: build.query<Segment[], void>({
      query: () => `Segment/ListSegments`,
      providesTags: ['segment'],
    }),
    getSegmentByKey: build.query<GetSegmentByKeyRespone, GetSegmentByKeyPayload>({
      query: ({ KY_NR }) => `Segment/GetByKey?KYNR=${KY_NR}`,
    }),
    fetchSegments: build.query<FetchSegmentsResponse, FetchSegmentsPayload>({
      query: ({ key, pageNumber, itemsPerPage, filter }) =>
        `Segment/FetchSegments?key=${key}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      providesTags: ['segment'],
    }),
    getSegmentTypes: build.query<SegmentType[], void>({
      query: () => `Segment/GetSegmentTypes`,
      providesTags: ['segment'],
    }),
    createSegment: build.mutation<void, CreateSegmentPayload>({
      query: ({ ...segment }) => ({
        url: 'Segment/Create',
        method: 'POST',
        body: { ...segment },
      }),
      invalidatesTags: ['segment'],
    }),
    editSegment: build.mutation<void, SegmentBase>({
      query: ({ ...segment }) => ({
        url: 'Segment/Edit',
        method: 'PUT',
        body: { ...segment },
      }),
      invalidatesTags: ['segment'],
    }),
    deleteSegment: build.mutation<void, SegmentBase>({
      query: ({ ...segment }) => ({
        url: 'Segment/Delete',
        method: 'POST',
        body: { ...segment },
      }),
      invalidatesTags: ['segment'],
    }),
    changeStructure: build.mutation<void, CompanyStructureBase>({
      query: ({ ...segment }) => {
        const transformedSegment = {
          key: segment.value,
          name: segment.KY_DESC,
        }

        return {
          url: 'Segment/ChangeStructureName',
          method: 'POST',
          body: transformedSegment,
        }
      },
      invalidatesTags: ['segment'],
    }),
  }),
})

export const {
  useGetSegmentsQuery,
  useListSegmentsQuery,
  useGetSegmentByKeyQuery,
  useFetchSegmentsQuery,
  useGetSegmentTypesQuery,
  useCreateSegmentMutation,
  useEditSegmentMutation,
  useDeleteSegmentMutation,
  useChangeStructureMutation,
} = segmentEndpoints
