import HttpClient from "../HttpClient";

export class AssetLocationsApi extends HttpClient {
    private static classInstance?: AssetLocationsApi;

    public constructor () {
        super (`/AssetLocations`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new AssetLocationsApi();
        }

        return this.classInstance;
    }

    public fetchAssetLocations = (assetCompanyId: number, assetId: string) => this.instance.get(`/FetchAssetLocations?assetCompanyId=${assetCompanyId}&assetId=${assetId}`);
    public getAssetLocation = (id: number) => this.instance.get(`/GetAssetLocation?id=${id}`);
    public create = (data: any) => this.instance.post(`/Create`, data);
    public edit = (data: any) => this.instance.put(`/Edit`, data);
    public delete = (data: any) => this.instance.post(`/Delete`, data);
}