import HttpClient from '../HttpClient'

export class GLPostApi extends HttpClient {
  private static classInstance?: GLPostApi

  public constructor() {
    super(`/GLPost`)
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new GLPostApi()
    }

    return this.classInstance
  }

  public fetchPosts = (
    filter: string,
    pageNumber: number,
    itemsPerPage: number
  ) =>
    this.instance.get(
      `/FetchGLPosts?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`
    )
  public list = () => this.instance.get('/ListGLPosts')
  public getPost = (id: string) => this.instance.get(`/GetGLPost?id=${id}`)
  public getKeys = (id: string) => this.instance.get(`/GetKeys?id=${id}`)
  public create = (data: any) => this.instance.post(`/Create`, data)
  public edit = (data: any) => this.instance.put(`/Edit`, data)
  public delete = (data: any) => this.instance.post(`/Delete`, data)
  public getExtraFields = () => this.instance.get(`/GetExtraFields`)
  public import = (file: any) => this.instance.get(`/ImportGLPost`, file)
}
