import React from 'react'
import { TagProps } from './Tag.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import { Icon } from '..'

const Tag: React.FC<TagProps> = ({
  content,
  remove = false,
  appearance = 'default',
  style,
}) => {
  const [css] = useStyletron()
  let baseStyles = css({
    ...styles.tagStyles,
    ...styles.getAppearance({ appearance }),
  })
  return (
    <span className={baseStyles} style={style}>
      <span className={css(styles.tag__contentStyles(remove))}>{content}</span>
      {remove && (
        <span
          role="button"
          tabIndex={0}
          onClick={remove}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              remove()
            }
          }}
          className={css(styles.tag__removeButtonStyles)}
        >
          <Icon name="X" className={css(styles.tag__removeButton__iconStyles)} />
        </span>
      )}
    </span>
  )
}

export default Tag
