import { StyleObject } from '@styles-api/css.types'
import { useStyletron } from 'styletron-react'

interface Props {
  items: {
    label: string
    value: any
  }[]
  columns?: 1 | 2
}

const DataDisplayList = ({ items, columns = 1 }: Props) => {
  const [css] = useStyletron()

  const tableStyle: StyleObject = {
    width: 'calc(100% + var(--gap-mini) + var(--gap-mini)) ',
    borderCollapse: 'collapse',
    marginLeft: 'calc(var(--gap-mini) * -1)',
    marginRight: 'calc(var(--gap-mini) * -1)',
  }

  const trStyle: StyleObject = {
    ':nth-of-type(even)': {
      backgroundColor: 'var(--shade50)',
    },
    width: '100%',
  }

  const tdStyle = (columns: 1 | 2): StyleObject => {
    return {
      paddingLeft: 'var(--gap-mini)',
      paddingRight: 'var(--gap-mini)',
      paddingTop: 'var(--scale100)',
      paddingBottom: 'var(--scale100)',
      width: columns === 1 ? '100%' : '50%',
    }
  }

  const tdInnerStyle: StyleObject = {
    display: 'flex',
    justifyContent: 'space-between',
  }

  // if 2 column layout, we group table by 2 cells per row
  const groupedByTwo = items.reduce(function (
    accumulator,
    currentValue,
    currentIndex,
    array
  ) {
    if (currentIndex % 2 === 0)
      accumulator.push(array.slice(currentIndex, currentIndex + 2))

    return accumulator
  },
  [])

  if (columns === 2)
    return (
      <table className={css(tableStyle)}>
        <tbody>
          {groupedByTwo.map((group, index) => (
            <tr key={`Group ${index}`} className={css(trStyle)}>
              {group.map((item, index) => (
                <td key={`${item.label}-${index}`} className={css(tdStyle(columns))}>
                  <div className={css(tdInnerStyle)}>
                    <b>{item.label}:</b> <span>{item.value}</span>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )

  return (
    <table className={css(tableStyle)}>
      <tbody>
        {items.map((item, index) => (
          <tr className={css(trStyle)} key={`${item.value}-${index}`}>
            <td key={`${item.label}-${item.value}`} className={css(tdStyle(columns))}>
              <div className={css(tdInnerStyle)}>
                <b>{item.label}:</b> <span>{item.value}</span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
export default DataDisplayList
