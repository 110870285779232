import * as Yup from 'yup'
import { REQUIRED_FIELD } from '@constants'
import { getCurrentYear } from '@utils/basic'
import moment from 'moment'
import { dateExistValidation } from '../../models/validation'

export interface GetYearsPayload {
  assetCompanyID: number
}

export interface FetchPeriodsPayload {
  assetCompanyID: number
  year: number
}

export const periodSchema = (allowWeekends: boolean) =>
  Yup.object({
    AssetCompanyID: Yup.number()
      .typeError(REQUIRED_FIELD)
      .required(REQUIRED_FIELD)
      .default(100),
    CP_YEAR: Yup.number()
      .typeError(REQUIRED_FIELD)
      .required(REQUIRED_FIELD)
      .default(getCurrentYear()),
    CP_PERIOD: Yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD).default(1),
    CP_START_DATE: Yup.string()
    .typeError(REQUIRED_FIELD)
    .required(REQUIRED_FIELD)
    .default(''),
    CP_DATE: Yup.string()
      .typeError(REQUIRED_FIELD)
      .required(REQUIRED_FIELD)
      .default('')
      .test('allowWeekendsTest', 'Weekends are not allowed', date => {
        if (allowWeekends) return true
        const dayOfWeek = moment(date).weekday()
        return !(dayOfWeek === 0 || dayOfWeek === 6)
      })
      .test(dateExistValidation), 
    CP_PERCENT: Yup.number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD).default(0),
    CP_FRWD: Yup.bool().default(false),
    CP_WEEKEND: Yup.bool().default(true),
    CP_YTD_PCT: Yup.number().required(REQUIRED_FIELD),
  })
export type Period = Yup.InferType<ReturnType<typeof periodSchema>>
export type PeriodKey = keyof Period

export interface EditPeriodsPayload {
  Periods: Period[]
  AllowWeekends: boolean
}

export const editPeriodsSchema = Yup.object({
  Periods: Yup.array().when('AllowWeekends', {
    is: true,
    then: Yup.array().of(periodSchema(true)),
    otherwise: Yup.array().of(periodSchema(false)),
  }),
  AllowWeekends: Yup.bool().default(true),
})

export const generatePeriodSchema = (companyHasPeriods: boolean, years: number[]) =>
  Yup.object({
    AssetCompanyID: Yup.number().required(REQUIRED_FIELD).default(100),
    FromYear: Yup.number()
      .max(2999, 'Enter a valid year')
      .test({
        test: function isFromYearBeforeOrEqualToToYear(FromYear) {
          if (!companyHasPeriods && this.parent.ToYear < FromYear) return false

          return true
        },
        message: 'Must be less than or equal to up to year.',
      })
      .default(getCurrentYear()),

    ToYear: Yup.number()
      .min(1900, 'Enter a valid year')
      .max(2999, 'Enter a valid year')
      .required(REQUIRED_FIELD)
      .test({
        test: function isToYearAfterMostRecentGeneratedYear(ToYear) {
          if (companyHasPeriods && years[years.length - 1] >= ToYear) return false

          return true
        },
        message: 'Must be greater than the most recent generated fiscal year.',
      })
      .test({
        test: function isToYearAfterFromYear(ToYear) {
          if (!companyHasPeriods && this.parent.FromYear > ToYear) return false

          return true
        },
        message: 'Must be greater than or equal to from year.',
      })
      .default(getCurrentYear()),
    AllowWeekends: Yup.bool().required(REQUIRED_FIELD).default(false),
  })
export interface GeneratePeriodsParams {
  AssetCompanyID: number
  FromYear: number
  ToYear: number
  AllowWeekends: boolean
}

export type GeneratePeriodParamKey = keyof GeneratePeriodsParams
