import { Button, Card, Icon, Table } from '@components/common'
import React, { MouseEventHandler, useEffect } from 'react'
import { useSortBy, useTable, useColumnOrder, useFlexLayout } from 'react-table'
import { useStyletron } from 'styletron-react'
import * as styles from './styles'
import { IMemorizedReportsTableProps } from './IMemorizeReports'

const MemorizedReportsTable = ({
  data,
  setValueMemorizeReport,
  setValueRetrieveReport,
  isMemorizeTemplateModal,
  isRetrieveTemplateModal,
  handleEditButtonClick,
  handleDeleteButtonClick,
  isEditTemplate,
  selectedTemplateDetails,
  handleSelectedRow,
}: IMemorizedReportsTableProps) => {
  const [css] = useStyletron()
  const [selectedRow, setSelectedRow] = React.useState<any>(
    isEditTemplate
      ? {
          TP_NAME: selectedTemplateDetails?.name,
          TP_DESC: selectedTemplateDetails?.description,
        }
      : null
  )

  const columns = React.useMemo(
    () => [
      { Header: 'Name', accessor: 'TP_NAME', width: 70 }, // the accessor is the "key" in the data
      { Header: 'Description', accessor: 'TP_DESC' },
    ],
    []
  )

  // Declare React-Table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useSortBy,
    useColumnOrder
  )

  const handleRowClick = row => {
    if (isRetrieveTemplateModal) {
      const name = row.original.TP_NAME
      const description = row.original.TP_DESC

      //map through the data to find the path where the TM_NAME is equal to name
      const path = data.find((item: any) => item.TP_NAME === name)?.TP_RP_PATH
      const templateData = data.find((item: any) => item.TP_NAME === name)?.TP_DATA

      setValueRetrieveReport('name', name)
      setValueRetrieveReport('description', description)
      setValueRetrieveReport('path', path)
      setValueRetrieveReport('templateData', templateData)

      setSelectedRow({
        TP_NAME: row.original.TP_NAME,
        TP_DESC: row.original.TP_DESC,
      })

      handleSelectedRow(selectedRow)
    }
  }

  const handleDoubleClick = row => {
    if (isMemorizeTemplateModal) {
      const name = row.original.TP_NAME
      const description = row.original.TP_DESC
      setValueMemorizeReport('name', name)
      setValueMemorizeReport('description', description)

      setSelectedRow({
        TP_NAME: row.original.TP_NAME,
        TP_DESC: row.original.TP_DESC,
      })
    }
  }

  useEffect(() => {
    if (isEditTemplate && selectedTemplateDetails) {
      setValueMemorizeReport('name', selectedTemplateDetails.name)
      setValueMemorizeReport('description', selectedTemplateDetails.description)
    }
  }, [isEditTemplate, selectedTemplateDetails])

  return (
    <Table {...getTableProps()}>
      <Table.Head>
        {headerGroups.map((headerGroup, i) => (
          <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => (
              <Table.Header
                {...column.getHeaderProps({
                  style: {
                    width: `${column.width}`,
                  },
                })}
                key={i}
              >
                {column.render('Header')}
              </Table.Header>
            ))}
          </Table.HeadRow>
        ))}
      </Table.Head>
      <Table.Body
        {...getTableBodyProps()}
        style={{ maxHeight: '200px', overflowY: 'scroll', overflowX: 'hidden' }}
      >
        {rows.map((row, i) => {
          prepareRow(row)

          const isSelected = selectedRow && selectedRow.TP_NAME === row.original['TP_NAME']

          return (
            <div style={{ display: 'flex' }} key={i}>
              <Table.Row
                {...row.getRowProps()}
                key={i}
                onClick={() => handleRowClick(row)}
                onDoubleClick={() => handleDoubleClick(row)}
                className={css(styles.tableRow({ isSelected, isMemorizeTemplateModal }))}
              >
                {row.cells.map(cell => {
                  return (
                    <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                  )
                })}
              </Table.Row>
              {isSelected && isRetrieveTemplateModal && (
                <div className={css(styles.helpButtonsWrapper)}>
                  <Button
                    className={css(styles.editButton)}
                    onClick={handleEditButtonClick}
                    type="submit"
                  >
                    <Icon name="Edit" size="s" />
                  </Button>
                  <Button
                    className={css(styles.deleteButton)}
                    size="compact"
                    onClick={handleDeleteButtonClick}
                    type="submit"
                  >
                    <Icon name="Trash2" intent="danger" size="s" />
                  </Button>
                </div>
              )}
            </div>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default MemorizedReportsTable
