import { StyleObject } from '@styles-api/css.types'

export const containerStyles: StyleObject = {
  position: 'fixed',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 'var(--z4)',
  width: '480px',
}
