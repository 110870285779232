import React from 'react'
import { BackButtonProps } from './View.types'

import * as styles from './Styles'
import { useStyletron } from 'styletron-react'
import { Button, Icon } from '@common'

const BackButton: React.FC<BackButtonProps> = ({ backTo, backText }) => {
  const [css] = useStyletron()

  let isString = typeof backTo === 'string'

  if (isString)
    return (
      <Button
        link={backTo}
        appearance="text"
        className={css(styles.backButton)}
        icon={<Icon name="ChevronLeft" />}
      >
        {backText}
      </Button>
    )
  else return <button onClick={backTo}>{backText}</button>
}
export default BackButton
