import { Asset } from '@api/models'
import { Button, Card, Flexbox, Search, Spacer, Table, Tag } from '@components/common'
import Pagination from '@components/Pagination'
import { useEffect, useState } from 'react'
import { useTable, useSortBy, useFlexLayout, useColumnOrder } from 'react-table'
import { useAssetsTable } from '@hooks'
import { useStyletron } from 'styletron-react'
import * as styles from './styles'
import { useDispatch, useSelector } from 'react-redux'

interface AssetsTableProps {
  rowAction: (row: { original: Asset }) => void
  isRowSelected: (row: { original: Asset }) => boolean
}

const AssetsRegisterTable = ({ rowAction, isRowSelected }: AssetsTableProps) => {
  const [css] = useStyletron()
  const setFilterState = useDispatch()
  const filterState = useSelector<any>(state => state.assetRegisterFilters)

  // Call useAssetsTable
  const {
    isErrorGettingCompanies,

    isErrorGettingFields,
    tableColumns,

    getHiddenColumns,

    isErrorGettingUserTableParams,

    assets,

    isReFetchingAssets,
    isErrorGettingAssets,

    isTableLoading,

    currentPage,
    itemsPerPage,
    activeFilterTags,

    changePage,
    removeFilter,
    clearAllFilters,
    searchByDescription,
    searchByDescriptionValue,
    updateSortSearchParam,
    sortByInitialState,
  } = useAssetsTable({
    filterState,
    setFilterState,
    isAssetRegister: true,
  })

  const columns: (keyof Asset)[] = ['AssetID', 'AS_DESC']

  // Declare React-Table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    setSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns: tableColumns,
      data: assets ? assets.list : [],
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        hiddenColumns: getHiddenColumns(columns),
        columnOrder: columns,
        sortBy: sortByInitialState(),
      },
    },

    useFlexLayout,
    useSortBy,
    useColumnOrder
  )

  // Update sort param
  useEffect(() => {
    updateSortSearchParam(sortBy, setSortBy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  // Returns
  if (isTableLoading) {
    return <p>Loading...</p>
  }

  if (isErrorGettingCompanies) {
    return <p>Error getting companies.</p>
  }

  if (isErrorGettingAssets) {
    return <p>Error getting assets.</p>
  }

  if (isErrorGettingFields || isErrorGettingUserTableParams) {
    return <p>Error getting fields.</p>
  }

  return (
    <>
      <Card spacing="none" style={{ minHeight: '100%' }}>
        <Table.Actions>
          <Flexbox justifyContent="space-between">
            <Search
              value={searchByDescriptionValue}
              onChange={searchByDescription}
              placeholder="Search by asset description..."
              name="AS_DESC"
            />
          </Flexbox>

          {activeFilterTags.length > 0 && (
            <div id="assets_table_active_filters">
              <Spacer y="mini" />
              <ul>
                {activeFilterTags.map(filter => (
                  <li style={{ display: 'inline-block' }}>
                    <Tag
                      content={filter.label}
                      appearance="outline"
                      remove={() =>
                        removeFilter(
                          filter.field,
                          filter.isRange,
                          filter.isMin,
                          filter.isMax
                        )
                      }
                    />
                  </li>
                ))}
              </ul>
              <Spacer y="compact" />
              <Button onClick={() => clearAllFilters()} overShade>
                Clear all filters
              </Button>
              <Spacer y="mini" />
            </div>
          )}
        </Table.Actions>
        {assets.list.length === 0 ? (
          <Table.NoResults text="Try changing the active company, filters or search query." />
        ) : (
          <>
            {' '}
            <Table
              {...getTableProps()}
              aria-label="Assets Table"
              label="Assets Table"
              aria-colcount={visibleColumns.length}
              aria-rowcount={assets.list.length}
              minHeight={'calc(100vh - 150px)'}
            >
              <Table.Head sticky>
                {isReFetchingAssets ? (
                  <Table.LoadingRows />
                ) : (
                  headerGroups.map((headerGroup, i) => (
                    <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                      {headerGroup.headers.map((column, i) => (
                        <Table.Header
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          column={column}
                          key={i}
                          tabIndex={0}
                        >
                          {column.render('Header')}
                        </Table.Header>
                      ))}
                    </Table.HeadRow>
                  ))
                )}
              </Table.Head>
              <Table.Body {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row)
                  const tableRow = (row as unknown) as {
                    original: Asset
                  }

                  const isSelected = isRowSelected(tableRow)

                  return (
                    <Table.Row
                      {...row.getRowProps()}
                      onClick={() => rowAction(tableRow)}
                      onKeyDown={() => rowAction(tableRow)}
                      tabIndex={0}
                      key={tableRow.original.AssetID}
                      className={css(styles.tableRow(isSelected))}
                    >
                      {row.cells.map(cell => {
                        return (
                          <Table.Cell {...cell.getCellProps()} column={cell.column}>
                            {cell.render('Cell')}
                          </Table.Cell>
                        )
                      })}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <Pagination
              changePage={changePage}
              totalItems={assets.count}
              maxItemsPerPage={itemsPerPage}
              currentPageNumber={currentPage}
              label={`Showing ${assets.list.length} of ${assets.count} asset${
                assets.count > 1 ? 's' : ''
              }`}
            />
          </>
        )}
      </Card>
    </>
  )
}
export default AssetsRegisterTable
