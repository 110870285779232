import { StyleObject } from '@styles-api/css.types'

const ITEM_X_PADDING = 'var(--scale600)'
const ITEM_Y_PADDING = 'var(--scale400)'

const paddingStyles: StyleObject = {
  paddingTop: ITEM_Y_PADDING,
  paddingBottom: ITEM_Y_PADDING,
  paddingLeft: ITEM_X_PADDING,
  paddingRight: ITEM_X_PADDING,
}

export const aside: StyleObject = {
  height: 'calc(100vh - var(--topbar-height))',
  top: 'var(--topbar-height)',
  position: 'sticky',
  gredTemplateArea: 'appSidebar',
  left: 0,
  width: 'var(--sidebar-width)',
  paddingTop: 'var(--gap-default)',
  paddingBottom: 'var(--gap-default)',
  // overflowY: 'scroll',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  backgroundColor: 'var(--base-color)',
  borderRightWidth: '1px',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}
export const nav: StyleObject = {
  width: '100%',
  flexDirection: 'column',
  listStyle: 'none',
}
export const listContainer: StyleObject = {
  display: 'block',
  width: '100%',
}
export const list: StyleObject = {
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  marginTop: 'var(--scale200)',
}
export const item: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}
export const linkBase: StyleObject = {
  ...paddingStyles,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  transition: 'var(--button-hover-transition)',

  ':hover': {
    color: 'var(--primary-color)',
    backgroundColor: 'var(--shade100)',
    ':active': {
      backgroundColor: 'var(--shade200)',
    },
  },
  ':active': {
    backgroundColor: 'var(--shade200)',
  },
}
export const getActive = ({ active }) => {
  const indicator = active => {
    if (active)
      return {
        // '::after': {
        //   position: 'absolute',
        //   content: '""',
        //   width: '12px',
        //   borderTopLeftRadius: 'var(--radius1)',
        //   borderBottomLeftRadius: 'var(--radius1)',

        //   height: '100%',
        //   right: '-1px',
        //   top: 0,
        //   borderWidth: '1px',
        //   borderRightColor: 'var(--base-color)',
        // },
        backgroundColor: 'var(--shade100)',
        '::before': {
          position: 'absolute',
          content: '""',
          width: '2px',
          borderTopRightRadius: 'var(--radius1)',
          borderBottomRightRadius: 'var(--radius1)',

          height: '28px',
          left: '0',
          top: '5px',
          backgroundColor: 'var(--primary-color)',
        },
      }
  }
  return {
    color: active ? 'var(--primary-color)' : 'var(--text-color)',
    fontWeight: active ? 'var(--font-weight-bold)' : 'var(--font-weight-normal)',
    backgroundColor: active ? 'var(--base-color)' : 'var(--base-color)',
    ...indicator(active),
  }
}
