import { useModal } from '@components/common'
import { useGetDepUpdateInfoQuery, useDepUpdateMutation } from '@api'
import { useFields } from '@hooks'
import { useEffect } from 'react'

const useDepUpdateHook = (assetCompanyID: number) => {
  const { fields } = useFields()

  const {
    modalIsOpen: isDepUpdateFormOpen,
    triggerModal: openDepUpdateForm,
    closeModal: closeDepUpdateForm,
  } = useModal()

  const {
    data: depUpdateInfo,
    isLoading: isLoadingGetDepUpdateInfo,
    isSuccess: isSuccessGetDepUpdateInfo,
    isError: isErrorGetDepUpdateInfo,
    error: errorGetDepUpdateInfo,
  } = useGetDepUpdateInfoQuery(assetCompanyID, {
    refetchOnMountOrArgChange: true,
    skip: !isDepUpdateFormOpen,
  })

  const [
    depUpdateMutation,
    {
      isLoading: isDepreciationUpdating,
      isError: isErrorDepUpdate,
      isSuccess: isSuccessDepUpdate,
    },
  ] = useDepUpdateMutation()

  useEffect(() => {
    if (isErrorGetDepUpdateInfo) {
      closeDepUpdateForm()
    }
  }, [closeDepUpdateForm, isErrorGetDepUpdateInfo])

  async function updateDepreciation(formEvent) {
    formEvent.preventDefault()
    closeDepUpdateForm()
    await depUpdateMutation(assetCompanyID)
  }

  return {
    isSuccessDepUpdate,
    isErrorDepUpdate,
    isDepUpdateFormOpen,
    openDepUpdateForm,
    closeDepUpdateForm,
    depUpdateInfo,
    isLoadingGetDepUpdateInfo,
    isSuccessGetDepUpdateInfo,
    isErrorGetDepUpdateInfo,
    updateDepreciation,
    isDepreciationUpdating,
    errorGetDepUpdateInfo: (errorGetDepUpdateInfo as any)?.data,
    fields,
  }
}

export default useDepUpdateHook
