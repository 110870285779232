import React from 'react'
import { MenuProps } from './PopMenu.types'

import { motion } from 'framer-motion'

const Menu: React.FC<MenuProps> = ({
  xTranslate,
  yTranslate,
  outside,
  className,
  width,
  ...props
}) => {
  return (
    <motion.div
      ref={outside}
      className={className}
      initial={{
        opacity: 0,
        scale: 0.5,
        translateX: xTranslate,
        translateY: yTranslate,
      }}
      animate={{
        opacity: 1,
        scale: 1,
        translateX: xTranslate,
        translateY: yTranslate,
        transition: { type: 'spring', stiffness: 700, damping: 33 },
      }}
      exit={{
        opacity: 0,
        scale: 0.8,
        translateX: xTranslate,
        translateY: yTranslate,
        transition: { duration: 0.14 },
      }}
      style={{ width: width + 'px' }}
    >
      {props.children}
    </motion.div>
  )
}
export default Menu
