import { SaveBarProps } from './SaveBar.types'
import { Button, Spacer } from '@components/common'
import SaveBarStyles from './Styles'
import { useStyletron } from 'styletron-react'
import { usePortal, usePrompt } from '@hooks'
import { createPortal } from 'react-dom'
import { Helmet } from 'react-helmet'
import navLogo from '../../assets/images/navLogo.png'
import { AnimatePresence, motion } from 'framer-motion'

var styles = new SaveBarStyles()

const SaveBar: React.FC<SaveBarProps> = ({
  save,
  formId,
  isSubmitting,
  isVisible,
  label = 'You have unsaved changes',
  isSaveDisabled,
  blockerMessage = 'If you leave this page, any unsaved changes will be lost.',
  saveButtonLabel = 'Save changes',
  shouldBlockNavigation,
  openDiscardModal,
}) => {
  const [css] = useStyletron()
  const portal = usePortal('save-bar')

  usePrompt(
    blockerMessage,
    shouldBlockNavigation !== undefined ? shouldBlockNavigation : isVisible
  )

  // Use react helmet to add attr to body, styke <main> element conditionally ...
  // If save bar is visible, we add margin to top of main element

  if (!portal) return null
  else
    return createPortal(
      <>
        <Helmet>
          <body data-savebar-visible={isVisible} />
        </Helmet>
        <AnimatePresence initial={false}>
          {isVisible && (
            <motion.div
              className={css(styles.bar)}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.08 },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.12 },
              }}
            >
              <div className={css(styles.sidebarArea)}>
                <img
                  src={navLogo}
                  className={css(styles.logo)}
                  alt="WorthIT Fixed Assets icon"
                />
                WorthIT Fixed Assets
              </div>
              <div className={css(styles.contentArea)}>
                <div className={css(styles.label)}>{label}</div>
                <div className={css(styles.buttons)}>
                  {openDiscardModal && (
                    <>
                      <Button onClick={openDiscardModal}>Discard</Button>
                      <Spacer x="mini" />
                    </>
                  )}

                  <Button
                    {...(save ? { onClick: save } : undefined)}
                    type="submit"
                    form={formId}
                    disabled={isSaveDisabled}
                    appearance="primary"
                    loading={isSubmitting}
                  >
                    {saveButtonLabel}
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>,
      portal
    )
}

export default SaveBar
