import { ImportColumn } from "../importTypes";

export const glposts: ImportColumn[] = [
    {
        label: 'G/L Post Code',
        value: 'GL_POST_ID'
    },
    {
        label: 'Post Code Description',
        value: 'GL_DESC'
    },
    {
        label: 'Credit Source',
        value: 'GL_ICO_CD'
    },
    {
        label: 'Credit Key 0',
        value: 'GL_CKEY0'
    },
    {
        label: 'Credit Key 1',
        value: 'GL_CKEY1'
    },
    {
        label: 'Credit Key 2',
        value: 'GL_CKEY2'
    },
    {
        label: 'Credit Key 3',
        value: 'GL_CKEY3'
    },
    {
        label: 'Credit Key 4',
        value: 'GL_CKEY4'
    },
    {
        label: 'Credit Key 5',
        value: 'GL_CKEY5'
    },
    {
        label: 'Credit Key 6',
        value: 'GL_CKEY6'
    },
    {
        label: 'Credit Key 7',
        value: 'GL_CKEY7'
    },
    {
        label: 'Credit Key 8',
        value: 'GL_CKEY8'
    },
    {
        label: 'Credit Key 9',
        value: 'GL_CKEY9'
    },
    {
        label: 'Debit Source',
        value: 'GL_IDO_CD'
    },
    {
        label: 'Debit Key 0',
        value: 'GL_DKEY0'
    },
    {
        label: 'Debit Key 1',
        value: 'GL_DKEY1'
    },
    {
        label: 'Debit Key 2',
        value: 'GL_DKEY2'
    },
    {
        label: 'Debit Key 3',
        value: 'GL_DKEY3'
    },
    {
        label: 'Debit Key 4',
        value: 'GL_DKEY4'
    },
    {
        label: 'Debit Key 5',
        value: 'GL_DKEY5'
    },
    {
        label: 'Debit Key 6',
        value: 'GL_DKEY6'
    },
    {
        label: 'Debit Key 7',
        value: 'GL_DKEY7'
    },
    {
        label: 'Debit Key 8',
        value: 'GL_DKEY8'
    },
    {
        label: 'Debit Key 9',
        value: 'GL_DKEY9'
    }
]