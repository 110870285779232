import { Route, Routes } from 'react-router-dom'
import privateRoutes from './privateRoutes'
import { NotFound } from '../views'
import usePermission from '@components/Auth/Permissions/usePermission'

const AppRouter = () => {
  const { isAuthorized } = usePermission()

  const buildRoutes = routes => {
    if (!routes) return

    return routes
      .filter(route => !route.grants || isAuthorized(route.grants))
      .map((route: any) => buildRoute(route))
  }

  const buildRoute = route => {
    return (
      <Route
        {...(route.path && { path: route.path })}
        element={route.element}
        {...(route.index && { index: route.index })}
        key={route.key ?? `routeId${Math.random()}`}
      >
        {buildRoutes(route.children)}
      </Route>
    )
  }
  const filteredRoutes = buildRoutes(privateRoutes)

  return (
    <Routes>
      {filteredRoutes}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRouter
