const initialState = 0

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ON_SET_COMPANY':
      if (action.payload != null) {
        return action.payload
      }
      return state
    default:
      return state
  }
}

export default companyReducer
