import { Card, Grid, Heading, Helper, Icon, Spacer } from '@components/common'
import { DashboardItemProps } from './DashboardFilters/DashboardFilter.types'
import { nFormatter } from './DashboardFilters/useDashboardFilters'
import { useStyletron } from 'styletron-react'
import { headingStyles, totalAmount } from './styles'
import DashboardItemLoading from './DashboardItemLoading'

const DashboardItem = ({
  title,
  count,
  renderChart,
  gridSize,
  icon,
  isFetchingDashboardAssets,
  isLoadingDepreciations,
  helpIcon,
  helpText,
}: DashboardItemProps) => {
  const [css] = useStyletron()

  let isFetchingData: boolean

  if (isFetchingDashboardAssets || isLoadingDepreciations) {
    isFetchingData = true
  } else {
    isFetchingData = false
  }

  return (
    <Grid.Item s={gridSize.s} m={gridSize.m} l={gridSize.l}>
      {isFetchingData && (
        <Card>
          <DashboardItemLoading />
        </Card>
      )}

      {!isFetchingData && (
        <Card>
          <div className={css(headingStyles)}>
            <Heading type="h3" primary>
              {title}
            </Heading>

            {helpIcon && <Helper content={helpText} />}
          </div>

          <div className={css(totalAmount)}>
            <Heading type="h1" style={{ scale: '1.8' }}>
              {icon && <Icon name={icon} style={{ scale: '0.5' }} />}
              {count.toString().length === 4 ? count.toLocaleString() : nFormatter(count, 2)}
            </Heading>

            {/* TODO: add this historic growth values */}
            {/* <div
                className=""
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: growth ? '#2ea44f' : 'red',
                }}
              >
                {growth ? (
                  <Icon
                    name="ArrowUp"
                    style={{ scale: '1', position: 'relative', right: '0.5rem' }}
                  />
                ) : (
                  <Icon
                    name="ArrowDown"
                    style={{ scale: '1', position: 'relative', right: '0.5rem' }}
                  />
                )}
                <Text style={{ scale: '1.3' }}>15%</Text>
              </div>

              <Text p={true}>From previous 30 days</Text> */}
          </div>

          <Spacer y="large" />

          {!isFetchingData && renderChart()}
        </Card>
      )}
    </Grid.Item>
  )
}

export default DashboardItem
