import { apiSlice } from '@api'
import {
  IAssetStructure,
  GetAssetStructuresParams,
  ValidateAssetTransferParams,
  AssetTransferParams,
} from '@api/assets/models'

export const assetStructureEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getAssetStructures: build.query<IAssetStructure[], GetAssetStructuresParams>({
      query: payload =>
        `/AssetHistory/GetAssetStructures?assetCompanyId=${payload.assetCompanyID}&assetId=${payload.assetID}`,
      providesTags: ['assetStructures'],
    }),
    validateAssetTransfer: build.mutation<void, ValidateAssetTransferParams>({
      query: payload => ({
        url: `/AssetHistory/ValidateTransfer?assetCompanyId=${payload.assetCompanyID}&assetId=${payload.assetID}&m_asOf=${payload.m_asOf}&year=${payload.year}&period=${payload.period}`,
        method: 'POST',
      }),

      invalidatesTags: [],
    }),
    assetTransfer: build.mutation<void, AssetTransferParams>({
      query: payload => {
        let url = '/AssetHistory/Transfer?'

        for (const [key, value] of Object.entries(payload)) {
          url += `${key}=${value}&`
        }

        return {
          url,
          method: 'POST',
        }
      },

      invalidatesTags: ['assetStructures'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAssetStructuresQuery,
  useValidateAssetTransferMutation,
  useAssetTransferMutation,
} = assetStructureEndpoints
