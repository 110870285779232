import { StyleObject } from '@styles-api/css.types'

export const container: StyleObject = {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 'var(--z4)',
  height: '100vh',
  overflowY: 'hidden',
  width: '380px',
  backgroundColor: 'var(--elevated-surface-background4)',
  boxShadow: 'var(--shadow4)',
  borderLeftWidth: '1px',
}
export const header: StyleObject = {
  minHeight: 'var(--topbar-height)',
  display: 'flex',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--border-color)',
  paddingLeft: 'var(--gap-compact)',
  paddingRight: 'var(--gap-compact)',
  position: 'relative',
  backgroundColor: 'var(--shade100)',
}
export const closeButton: StyleObject = {
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: 'var(--transparent)!important',
  height: '100%',
  paddingLeft: 'var(--gap-compact)',
  paddingRight: 'var(--gap-compact)',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    color: 'var(--primary-color)',
  },
  ':active': {
    color: 'var(--primary-color500)',
    ':hover': {
      color: 'var(--primary-color500)',
    },
  },
  focus: {
    color: 'var(--primary-color)',
  },
}
export const closeIcon: StyleObject = {
  height: 'var(--gap-compact)',
  width: 'var(--gap-compact)',
}
export const content: StyleObject = {
  paddingTop: 'var(--gap-compact)',
  paddingBottom: 'var(--gap-compact)',
  paddingLeft: 'var(--gap-compact)',
  paddingRight: 'var(--gap-compact)',
}
export const getContentHeight = ({ title, action }) => {
    return {
      maxHeight: 'calc(100vh - var(--topbar-height) - var(--scale1600))',
      overflowY: 'scroll',
    }
}

export const footer: StyleObject = {
  borderTopWidth: '1px',
  paddingLeft: 'var(--gap-compact)',
  paddingRight: 'var(--gap-compact)',
  paddingBottom: 'var(--scale600)',
  paddingTop: 'var(--scale600)',
  backgroundColor: 'var(--base-color)',
  position: 'absolute',
  bottom: 0,
  width: '100%',
}
