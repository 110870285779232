import { apiSlice } from '@api'
import { getUserFullName } from '@components/Auth/profile'
import { serializeURLParams } from '@utils/basic'
import {
  AssetLocation,
  FetchAssetLocationParams,
  FetchAssetLocationDetailsParams,
} from '../models'

export const assetLocationEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    fetchAssetLocations: build.query<AssetLocation[], FetchAssetLocationParams>({
      query: payload =>
        `/AssetLocations/FetchAssetLocations??${serializeURLParams(payload)}`,
      providesTags: ['assetLocation'],
    }),
    getAssetLocation: build.query<AssetLocation, FetchAssetLocationDetailsParams>({
      query: payload => `AssetLocations/GetAssetLocation?${serializeURLParams(payload)}`,
      providesTags: ['assetLocation'],
    }),
    getAssetCurrentLocation: build.query<AssetLocation, FetchAssetLocationParams>({
      query: payload =>
        `AssetLocations/GetAssetCurrentLocation?${serializeURLParams(payload)}`,
      providesTags: ['assetLocation'],
    }),
    createLocation: build.mutation<void, AssetLocation>({
      query: payload => ({
        url: 'AssetLocations/Create',
        method: 'POST',
        body: {
          ...payload,
          LUserName: getUserFullName(),
        },
      }),
      invalidatesTags: ['assetLocation', 'assetCharge'],
    }),
    editLocation: build.mutation<void, AssetLocation>({
      query: payload => ({
        url: 'AssetLocations/Edit',
        method: 'PUT',
        body: {
          ...payload,
          LUserName: getUserFullName(),
        },
      }),
      invalidatesTags: ['assetLocation', 'assetCharge'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchAssetLocationsQuery,
  useGetAssetLocationQuery,
  useGetAssetCurrentLocationQuery,
  useCreateLocationMutation,
  useEditLocationMutation,
} = assetLocationEndpoints
