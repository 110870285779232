import { AssetsTableFilterField } from './Filters.types'

// IMPORTANT: a field with type: 'options' must have a defined optionsType

export const filterFields: AssetsTableFilterField[] = [
  {
    column: 'AS_DESC',
    type: String,
    compare: false,
    operator: 'contains',
  },
  {
    column: 'AS_REC_DAT',
    type: Date,
    compare: true,
  },
  {
    column: 'AS_FIN_COS',
    type: 'currency',
    compare: true,
    isCurrency: true,
  },
  {
    column: 'AS_DIS_CD',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'Radio',
    types: [
      {
        name: 'Active',
        value: 'A',
      },
      {
        name: 'Pending',
        value: 'P',
      },
      {
        name: 'Non-Active',
        value: 'N',
      },
      {
        name: 'Disposed',
        value: 'D',
      },
    ],
  },
  {
    column: 'AS_DIS_DAT',
    type: Date,
    compare: true,
  },
  {
    column: 'AS_CAT_ID',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'DepreciationTypeA',
  },
  {
    column: 'AS_TCLS_ID',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'DepreciationTypeX',
  },
  {
    column: 'AS_DESC_E',
    type: String,
    compare: false,
    operator: 'contains',
  },
  {
    column: 'AS_KEY0_ID',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'Segment',
  },
  {
    column: 'AS_KEY1_ID',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'Segment',
  },
  {
    column: 'AS_KEY2_ID',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'Segment',
  },
  {
    column: 'AS_KEY3_ID',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'Segment',
  },
  {
    column: 'AS_KEY4_ID',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'Segment',
  },
  {
    column: 'AS_FIN_TAC',
    type: Number,
    compare: true,
  },
  {
    column: 'AS_IN_POL',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'Insurance',
  },
  {
    column: 'AS_LEASE',
    type: String,
    compare: false,
    operator: 'eq',
  },
  {
    column: 'AS_MAIN_CN',
    type: String,
    compare: false,
    operator: 'eq',
  },
  {
    column: 'AS_NSER_DT',
    type: Date,
    compare: true,
  },
  {
    column: 'AS_FIN_SAL',
    type: 'currency',
    compare: true,
  },
  {
    column: 'AS_PRI_UCD',
    type: String,
    compare: false,
    operator: 'eq',
  },
  {
    column: 'AS_SEC_UCD',
    type: String,
    compare: false,
    operator: 'eq',
  },
  {
    column: 'AS_UC1_UCD',
    type: String,
    compare: false,
    operator: 'eq',
  },
  {
    column: 'AS_UC2_UCD',
    type: String,
    compare: false,
    operator: 'eq',
  },
  {
    column: 'AS_UC3_UCD',
    type: String,
    compare: false,
    operator: 'eq',
  },
  {
    column: 'AS_REC_USR',
    type: 'options',
    compare: false,
    optionsType: 'Picklist',
  },
  {
    column: 'AS_ADD_DAT',
    type: Date,
    compare: true,
  },
  {
    column: 'AS_SERIAL',
    type: String,
    compare: false,
    operator: 'eq',
  },
  {
    column: 'AS_VEN_ID',
    type: 'options',
    compare: false,
    operator: 'eq',
    optionsType: 'Vendor',
  },
]
