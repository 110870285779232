import { useLazyGetCommentQuery, useUpdateCommentMutation } from '@api'
import { Comment, commentSchema, CommentType } from '@api/models'
import { toast } from '@components/common'
import useFields from '@hooks/useFields'
import { getTodaysDate } from '@utils/basic'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

interface IUseCommentsHook {
  assetCompanyID: number
  type: CommentType
  objectID: string
  key1?: string
  key2?: string
  key3?: string
  key4?: string
  key5?: number
}

const useCommentsHook = ({
  assetCompanyID,
  type,
  objectID,
  key1,
  key2,
  key3,
  key4,
  key5,
}: IUseCommentsHook) => {
  const {
    fields,
    isLoading: isLoadingLabels,
    isSuccess: isSuccessGettingLabels,
  } = useFields()
  const [
    getComment,
    { data: comment, isLoading: isLoadingComment, isSuccess: isSuccessGettingComment },
  ] = useLazyGetCommentQuery()
  const [updateComment] = useUpdateCommentMutation()

  useEffect(() => {
    getComment({ assetCompanyID, type, objectID, key1, key2, key3, key4, key5 })
  }, [assetCompanyID, getComment, key1, key2, key3, key4, key5, objectID, type])

  useEffect(() => {
    if (isSuccessGettingComment && comment != null) {
      methods.reset({ ...comment })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, isSuccessGettingComment])

  const defaultComment: Comment = {
    AssetCompanyID: assetCompanyID,
    CO_ORI_CD: type,
    CO_GRP_ID: objectID,
    CO_KEY1: key1,
    CO_KEY2: key2,
    CO_KEY3: key3,
    CO_KEY4: key4,
    CO_KEY5: key5,
    CO_AD_DAT: getTodaysDate(),
    CO_COMMENT: '',
    CO_USR: '',
  }

  const methods = useForm<Comment>({
    defaultValues: { ...defaultComment },
    reValidateMode: 'onBlur',
    resolver: yupResolver(commentSchema),
  })

  async function onSave(formValues: Comment) {
    try {
      await updateComment(formValues).unwrap()
      toast.success('Comment saved successfully')
    } catch (error) {}
  }

  function close() {
    if (comment == null) methods.reset({ ...defaultComment })
    else methods.reset({ ...comment })
  }

  const [showComment, setShowComment] = useState(false)

  return {
    methods,
    fields,
    comment: comment ?? ({ CO_AD_DAT: getTodaysDate() } as Comment),
    isLoadingComment: isLoadingComment || isLoadingLabels,
    isSuccessGettingComment: isSuccessGettingComment && isSuccessGettingLabels,
    onSave,
    close,

    showComment,
    setShowComment,
  }
}

export default useCommentsHook
