import {
  DepreciationClass,
  DepreciationProfileCode,
  initialDepreciation,
  FetchDepreciationsResponse,
  EditDepreciationPayload,
} from '@api/models'
import { toast, useModal } from '@components/common'
import { useTableParams } from '@hooks'
import {
  useCreateDepreciationMutation,
  useDeleteDepreciationMutation,
  useEditDepreciationMutation,
  useFetchCategoriesQuery,
  useFetchTaxClassesQuery,
} from '@api'
import { TableParamsHook } from 'hooks/useTableParams'
import { Dispatch, SetStateAction, useState } from 'react'

interface DepreciationHookProps {
  profile: DepreciationProfileCode
}

export interface DepreciationsTableHook extends TableParamsHook {
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  searchKey: string
  selectedDepreciation: DepreciationClass

  tableData: FetchDepreciationsResponse
  isLoadingTableData: boolean
  isErrorGettingTableData: boolean
  isRefetchingTableData: boolean

  handleEditDepreciation: (payload: DepreciationClass) => Promise<void>
  handleCreateDepreciation: (payload: DepreciationClass) => Promise<void>
  isLoadingEditDepreciation: boolean
  isLoadingCreateDepreciation: boolean

  handleDeleteDepreciation: (payload: EditDepreciationPayload) => Promise<void>
  isLoadingDeleteDepreciation: boolean

  deleteDepreciationModalIsOpen: boolean
  triggerDeleteDepreciationModal
  closeDeleteDepreciationModal
  openDeleteDepreciationModal: (depreciation: DepreciationClass) => void
  openEditDepreciationModal: (depreciation: DepreciationClass) => void
  triggerEditDepreciationModal
  closeEditDepreciationModal
  editDepreciationModalIsOpen: boolean

  openCreateDepreciationModal: () => void
  createDepreciationModalIsOpen: boolean
  closeCreateDepreciationModal
}

export default function useDepreciationClassesTable({
  profile,
}: DepreciationHookProps): DepreciationsTableHook {
  const [filterState, setFilterState] = useState({})
  const [selectedDepreciation, setSelectedDepreciation] = useState<DepreciationClass>(
    undefined
  )

  const searchKey = 'DP_OBJ_ID'
  const initialItemsPerPage = 20

  // Use useTableParams hook for table filtering, pagination, etc.
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    isTotalCountEmpty,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  // Fetch categories depr type
  const skipFetchCategories: boolean = profile === 'X'
  const {
    data: categories,
    isLoading: isLoadingCategories,
    isError: isErrorGettingCategories,
    isFetching: isRefetchingCategories,
  } = useFetchCategoriesQuery(fetchRequestPayload, { skip: skipFetchCategories })

  // Fetch tax classes depr type
  const skipFetchTaxClasses: boolean = profile === 'A'
  const {
    data: taxClasses,
    isLoading: isLoadingTaxClasses,
    isError: isErrorGettingTaxClasses,
    isFetching: isRefetchingTaxClasses,
  } = useFetchTaxClassesQuery(fetchRequestPayload, { skip: skipFetchTaxClasses })

  let tableData: FetchDepreciationsResponse
  let isLoadingTableData: boolean
  let isErrorGettingTableData: boolean
  let isRefetchingTableData: boolean
  switch (profile) {
    case 'A':
      tableData = categories
      isLoadingTableData = isLoadingCategories
      isErrorGettingTableData = isErrorGettingCategories
      isRefetchingTableData = isRefetchingCategories
      break
    case 'X':
      tableData = taxClasses
      isLoadingTableData = isLoadingTaxClasses
      isErrorGettingTableData = isErrorGettingTaxClasses
      isRefetchingTableData = isRefetchingTaxClasses
      break
  }

  // MODAL STATES
  // Delete modal
  const {
    modalIsOpen: deleteDepreciationModalIsOpen,
    triggerModal: triggerDeleteDepreciationModal,
    closeModal: closeDeleteDepreciationModal,
  } = useModal()
  function openDeleteDepreciationModal(depreciation: DepreciationClass): void {
    setSelectedDepreciation({ ...depreciation, DP_TYP_CD: profile })
    triggerDeleteDepreciationModal()
  }

  // Edit modal
  const {
    modalIsOpen: editDepreciationModalIsOpen,
    triggerModal: triggerEditDepreciationModal,
    closeModal: closeEditDepreciationModal,
  } = useModal()

  function openEditDepreciationModal(depreciation: DepreciationClass): void {
    setSelectedDepreciation({ ...depreciation, DP_TYP_CD: profile })
    triggerEditDepreciationModal()
  }

  // Create modal
  const {
    modalIsOpen: createDepreciationModalIsOpen,
    triggerModal: triggerCreateDepreciationModal,
    closeModal: closeCreateDepreciationModal,
  } = useModal()
  function openCreateDepreciationModal(): void {
    setSelectedDepreciation(initialDepreciation(profile))
    triggerCreateDepreciationModal()
  }

  // MUTATIONS
  // Delete
  const [
    deleteDepreciation,
    { isLoading: isLoadingDeleteDepreciation },
  ] = useDeleteDepreciationMutation()
  async function handleDeleteDepreciation(payload: EditDepreciationPayload): Promise<void> {
    try {
      await deleteDepreciation(payload).unwrap()
      closeDeleteDepreciationModal()
      toast.success(
        `Successfully deleted "${payload.deprec.DP_DESC}" from your depreciation profiles.`
      )
    } catch (error) {
      toast.error('There was an unexpected error.')
    }
  }

  // Edit
  const [
    editDepreciation,
    { isLoading: isLoadingEditDepreciation },
  ] = useEditDepreciationMutation()
  async function handleEditDepreciation(payload: DepreciationClass): Promise<void> {
    try {
      await editDepreciation(payload as any).unwrap()
      closeEditDepreciationModal()
      toast.success(`Depreciation profile ${payload.DP_OBJ_ID} successfully updated.`)
    } catch (error) {
      toast.error('There was an unexpected error updating this depreciation profile.')
    }
  }

  // Create
  const [
    createDepreciation,
    { isLoading: isLoadingCreateDepreciation },
  ] = useCreateDepreciationMutation()
  async function handleCreateDepreciation(payload: DepreciationClass): Promise<void> {
    try {
      await createDepreciation(payload).unwrap()

      closeCreateDepreciationModal()
      toast.success(
        `Successfully added ${payload.DP_OBJ_ID} (${payload.DP_DESC}) to depreciation profiles.`
      )
    } catch (error) {
      toast.error('There was an unexpected error creating the depreciation profile.')
    }
  }

  return {
    // tableParams return
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    isTotalCountEmpty,
    //
    filterState,
    setFilterState,
    searchKey,
    selectedDepreciation,

    tableData,
    isLoadingTableData,
    isErrorGettingTableData,
    isRefetchingTableData,

    handleDeleteDepreciation,
    isLoadingDeleteDepreciation,

    handleEditDepreciation,
    handleCreateDepreciation,
    isLoadingCreateDepreciation,
    isLoadingEditDepreciation,

    deleteDepreciationModalIsOpen,
    triggerDeleteDepreciationModal,
    closeDeleteDepreciationModal,
    openDeleteDepreciationModal,
    openEditDepreciationModal,
    triggerEditDepreciationModal,
    closeEditDepreciationModal,
    editDepreciationModalIsOpen,

    createDepreciationModalIsOpen,
    openCreateDepreciationModal,
    closeCreateDepreciationModal,
  }
}
