import { LocationDetails, FieldLabels, AssetLocation } from '@api/models'
import { useFormContext } from 'react-hook-form'
import { useAssetLocationDetails, useFormFieldProps } from '@hooks'
import { Button, Card, Form, Grid, Input, Note, Spacer, Text } from '@components/common'
import { EDIT_ASSET_LOCATION_DETAILS_FORM_ID } from '@constants'
import { useEffect, useState } from 'react'
import { isNullOrUndefined } from '@utils/basic'
import { useCoords } from '@hooks/useCoords'
import { useParams } from 'react-router-dom'
import AssetLocationMap from './AssetLocationMap'

interface Props {
  fields: FieldLabels
  handleLocationFormSubmit: (payload: AssetLocation) => Promise<void>
  hasLocation: boolean
}

const AssetLocationDetailsForm = ({
  fields,
  handleLocationFormSubmit,
  hasLocation,
}: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit,
    setValue,
  } = useFormContext<LocationDetails>()

  const { getCoords, isLoadingCoords, coords } = useCoords()

  const { getInputProps } = useFormFieldProps<LocationDetails>({
    isSubmitting,
    errors,
    fields,
    register,
  })

  const lastEditedBy = watch('LUserName')
  const [verifyOnClicked, setverifyOnClicked] = useState('')
  const [notMyLocation, setNotMyLocation] = useState(false)

  // To get if I do have coordanates or not
  const params = useParams()

  const { isShowingCoords, setIsShowingCoords } = useAssetLocationDetails({
    assetCompanyID: parseInt(params.companyID),
    assetID: params.assetID,
    systemNumber: parseInt(params.locationID),
  })

  const location: LocationDetails = {
    LocationName: watch('LocationName'),
    Latitude: isNaN(watch('Latitude')) ? null : watch('Latitude'),
    Longitude: isNaN(watch('Longitude')) ? null : watch('Longitude'),
    Address: watch('Address'),
    City: watch('City'),
    Country: watch('Country'),
    State: watch('State'),
    PostalCode: watch('PostalCode'),
    LUserName: watch('LUserName'),
    AssetCompanyID: 0,
    AssetID: '',
    ChargeSystemNumber: 0,
  }

  useEffect(() => {
    if (!isLoadingCoords && !isNullOrUndefined(coords)) {
      setValue('Latitude', coords.lat)
      setValue('Longitude', coords.lon)
      setIsShowingCoords(true)
    } else if (
      !isLoadingCoords &&
      isNullOrUndefined(coords) &&
      verifyOnClicked === 'clickedVerified'
    ) {
      setValue('Latitude', 0)
      setValue('Longitude', 0)
      setIsShowingCoords(false)
    }
  }, [isLoadingCoords, coords, verifyOnClicked, setIsShowingCoords, setValue])

  function findCoordinates(e = null) {
    //Verifying the event for onLoad/onClicked Call
    if (e != null) {
      e.preventDefault()
      setverifyOnClicked('clickedVerified')
      getCoords({
        Address: watch('Address'),
        Country: watch('Country'),
        City: watch('City'),
        State: watch('State'),
        PostalCode: watch('PostalCode'),
      })
    }
  }

  function isValidLocation(
    location: LocationDetails,
    isShowingCoords: boolean,
    hasLocation: boolean
  ): boolean {
    return (
      (isShowingCoords || hasLocation) &&
      location &&
      location.Latitude !== null &&
      location.Longitude !== null
    )
  }

  return (
    <>
      <Form
        id={EDIT_ASSET_LOCATION_DETAILS_FORM_ID}
        noValidate
        onSubmit={handleSubmit(handleLocationFormSubmit)}
      >
        <Grid>
          <Grid.Item>
            <Input {...getInputProps('LocationName')} autoFocus />
          </Grid.Item>
          <Grid.Item m={8}>
            <Input {...getInputProps('Address')} />
          </Grid.Item>
          <Grid.Item m={4}>
            <Input {...getInputProps('Country')} />
          </Grid.Item>
          <Grid.Item m={4}>
            <Input {...getInputProps('City')} />
          </Grid.Item>
          <Grid.Item m={4}>
            <Input {...getInputProps('State')} />
          </Grid.Item>
          <Grid.Item m={4}>
            <Input {...getInputProps('PostalCode')} />
          </Grid.Item>

          <Grid.Item m={12}>
            {!isLoadingCoords &&
            isNullOrUndefined(coords?.lat) &&
            isNullOrUndefined(coords?.lon) &&
            verifyOnClicked === 'clickedVerified' ? (
              <>
                <Note
                  intent="danger"
                  content={<>Unable to find location, please enter a valid address.</>}
                />
                <Spacer y="default" />
              </>
            ) : (
              ''
            )}
            <Button
              appearance="muted"
              flex
              onClick={findCoordinates}
              loading={isLoadingCoords}
              disabled={isLoadingCoords}
            >
              Find Location
            </Button>

            <Spacer y="default" />

            {isShowingCoords && (
              <>
                <Text intent="default" bold>
                  Not your exact location on the map?
                </Text>
                <Spacer y="mini" />
                <Button onClick={() => setNotMyLocation(!notMyLocation)}>
                  Set Latitude and Longitude
                </Button>
                <Spacer y="default" />
                {notMyLocation && (
                  <Grid>
                    <Grid.Item m={6}>
                      <Input {...getInputProps('Latitude', 'number')} />
                    </Grid.Item>
                    <Grid.Item m={6}>
                      <Input {...getInputProps('Longitude', 'number')} />
                    </Grid.Item>
                  </Grid>
                )}
              </>
            )}
          </Grid.Item>

          <Grid.Item>
            <Card title="Map">
              {isValidLocation(location, isShowingCoords, hasLocation) ? (
                <AssetLocationMap location={location} />
              ) : (
                <Note
                  intent="warning"
                  content={<>To load map, please enter a valid address.</>}
                />
              )}
            </Card>
          </Grid.Item>
        </Grid>

        <Spacer y="default" />

        <Button
          type="submit"
          style={{ backgroundColor: 'var(--primary-color)', color: 'white' }}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Form>

      <Spacer y="default" />

      {lastEditedBy && (
        <Grid.Item>
          <Input value={lastEditedBy} label={fields['LUserName']} readOnly />
        </Grid.Item>
      )}
    </>
  )
}

export default AssetLocationDetailsForm
