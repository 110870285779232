import ReportTemplate from '@components/Report/ReportTemplate'
import { REPORTS_PATH, SETTING_PATHS } from '@constants'
import { Navigate } from 'react-router-dom'
import assetRoutes from './asset-routes'
import companyRoutes from './company-routes'
import reportRoutes from './report-routes'

import {
  HomeView,
  AssetsView,
  TasksView,
  InsurancePoliciesView,
  MaintenanceContractsView,
  FoldersView,
  DepreciationRootView,
  GLPostsView,
  VendorsView,
  SegmentsView,
  CompaniesView,
  CreateDepreciationClassView,
  DepreciationClassesView,
  CreateCompanyView,
  FieldPrefsView,
  ProfileView,
  AccountSettingView,
  CustomizedFormatView,
  ChangePasswordPrefView,
  EditAssetIDView,
  FixedAssetsAdminView,
} from '@views'
import {
  AssetTemplate,
  CompanyTemplate,
  DepreciationClassTemplate,
  PrivateRouteTemplate,
  SettingsTemplate,
} from '@templates'
import deprecClassRoutes from './deprec-class-routes'
import ImportAllPrefs from '@components/ImportData/ImportAllPrefs'
import {
  CAN_ACCESS_FIXED_ASSETS_ADMIN_PORTAL,
  CAN_RUN_DEPRECIATION,
  CAN_VIEW_ASSETS,
} from '@components/Auth/UserRole'

const privateRoutes = [
  {
    element: <PrivateRouteTemplate />,
    children: [
      {
        key: 'Home',
        path: '/',
        element: <HomeView />,
      },
      {
        key: 'Assets',
        path: '/assets',
        grants: CAN_VIEW_ASSETS,
        children: [
          {
            index: true,
            element: <AssetsView />,
          },
          {
            element: <AssetTemplate />,
            path: ':companyID/:assetID',
            children: assetRoutes,
          },
        ],
      },
      {
        key: 'Admin Portal',
        path: '/admin-portal',
        grants: CAN_ACCESS_FIXED_ASSETS_ADMIN_PORTAL,
        element: <FixedAssetsAdminView />,
      },
      {
        path: REPORTS_PATH,
        element: <ReportTemplate />,
        children: [
          ...reportRoutes,
          {
            index: true,
            element: <Navigate to={reportRoutes[0].path} replace={true} />,
          },
        ],
      },
      {
        key: 'Depreciation',
        path: '/depreciation',
        element: <DepreciationRootView />,
        grants: CAN_RUN_DEPRECIATION,
      },
      {
        key: 'Companies',
        path: '/companies',
        children: [
          {
            index: true,
            element: <CompaniesView />,
          },
          {
            key: 'Create',
            path: 'create',
            element: <CreateCompanyView />,
          },
          {
            element: <CompanyTemplate />,
            path: ':companyID',
            children: [...companyRoutes],
          },
        ],
      },

      {
        key: 'Tasks',
        path: '/tasks-activities',
        element: <TasksView />,
      },
      {
        key: 'Insurance',
        path: '/insurance-policies',
        element: <InsurancePoliciesView />,
      },
      {
        key: 'Maintenance-contracts',
        path: '/maintenance-contracts',
        element: <MaintenanceContractsView />,
      },
      {
        key: 'Folder',
        path: '/folders',
        element: <FoldersView />,
      },

      {
        key: 'GLPost',
        path: '/post-codes',
        element: <GLPostsView />,
      },
      {
        key: 'Vendor',
        path: '/vendors',
        element: <VendorsView />,
      },
      {
        key: 'Segment',
        path: '/segments',
        element: <SegmentsView />,
      },

      {
        key: 'Categories',
        path: '/categories',

        children: [
          {
            index: true,
            element: <DepreciationClassesView profile="A" />,
          },
          {
            key: 'Create',
            path: 'create',
            element: <CreateDepreciationClassView profile="A" />,
          },
          {
            element: <DepreciationClassTemplate profile="A" />,
            path: ':id',
            children: deprecClassRoutes('A'),
          },
        ],
      },
      {
        key: 'Tax Classes',
        path: '/tax-classes',

        children: [
          {
            index: true,
            element: <DepreciationClassesView profile="X" />,
          },
          {
            key: 'Create',
            path: 'create',
            element: <CreateDepreciationClassView profile="X" />,
          },
          {
            element: <DepreciationClassTemplate profile="X" />,
            path: ':id',
            children: deprecClassRoutes('X'),
          },
        ],
      },
      {
        key: 'Settings',
        path: '/settings',
        element: <SettingsTemplate />,
        children: [
          {
            index: true,
            element: <Navigate to="profile" replace={true} />,
          },
          {
            path: SETTING_PATHS.PROFILE,
            element: <ProfileView />,
          },
          {
            path: SETTING_PATHS.FIELD_PREFS,
            element: <FieldPrefsView />,
          },
          {
            path: SETTING_PATHS.CUSTOMIZE_FORMATS,
            element: <CustomizedFormatView />,
          },
          {
            path: SETTING_PATHS.ACCOUNT_STRUCTURE_SETUP,
            element: <div>Account Structure Setup</div>,
          },
          {
            path: SETTING_PATHS.ACCOUNT_SETTINGS,
            element: <AccountSettingView />,
          },
          {
            path: SETTING_PATHS.CHANGE_PASSWORD,
            element: <ChangePasswordPrefView />,
          },
          {
            path: SETTING_PATHS.EDIT_ASSETID,
            element: <EditAssetIDView />,
          },
        ],
      },
      {
        key: 'Help',
        path: '/help',
        element: <div>Help Page</div>,
      },
      {
        key: 'Import',
        path: '/import',
        element: <ImportAllPrefs />,
      },
    ],
  },
]

export default privateRoutes
