import {
  AllAssetRecalcFields,
  AssetRecalcDTO,
  AssetRecalcFieldNames,
  assetRecalcSchema,
  DepreciationClass,
  EditDepreciationPayload,
} from '@api/models'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import {
  Grid,
  Input,
  Fieldset,
  Radio,
  Form,
  Note,
  Heading,
  Spacer,
  CustomDatePicker,
} from '@components/common'
import { DEPREC_RECALC_FORM_ID } from '@constants'

interface DeprecRecalcFormProps {
  deprec: DepreciationClass
  recalcDTO: AssetRecalcDTO
  submitAction: (payload: EditDepreciationPayload) => Promise<void>
  type: 'edit' | 'delete'
}

const DeprecClassRecalcForm = ({
  deprec,
  recalcDTO,
  submitAction,
  type,
}: DeprecRecalcFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm<AllAssetRecalcFields>({
    resolver: yupResolver(assetRecalcSchema),
    mode: 'onBlur',
    defaultValues: recalcDTO,
  })
  const watchSelectedOption = watch('SelectedOption')

  function getInputProps(name: AssetRecalcFieldNames) {
    return {
      ...register(name),
      disabled: isSubmitting,
      error: errors[name] !== undefined,
      errorMsg: errors[name]?.message,
    }
  }
  function getDateProps(field: any) {
    return {
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
      select: field.value,
      onBlur: field.onBlur,
      error: errors[field] !== undefined,
      errorMsg: errors[field]?.message,
    }
  }

  function onSubmitHandler(formData: AllAssetRecalcFields) {
    submitAction({ deprecRecalcDTO: { ...formData } as AssetRecalcDTO, deprec })
  }

  const yearNotApplicable = recalcDTO.AsOfYear === 0
  const isAssetSpecifc = deprec.AssetCompanyID !== 0

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)} id={DEPREC_RECALC_FORM_ID}>
      <Grid colGap="compact">
        <Grid.Item>
          <Note
            intent="danger"
            content={
              <>
                <Heading type="h2" eyebrow>
                  Depreciation recalculation required
                </Heading>
                <Spacer y="mini" />
                {type === 'edit' ? (
                  <div>
                    {isAssetSpecifc ? (
                      <>
                        The changes you made to this class will effect the depreciation
                        records for asset <b>{deprec['DP_OBJ_ID']}</b>. Please review the
                        options and parameters below and confirm recalculation before
                        saving.
                      </>
                    ) : (
                      <>
                        The changes you made to{' '}
                        <b>
                          {deprec['DP_OBJ_ID']} ({deprec.DP_DESC})
                        </b>
                        will effect the depreciation records for every asset using this
                        class. Please review the options and parameters below and confirm
                        recalculation before saving.
                      </>
                    )}{' '}
                  </div>
                ) : (
                  <div>
                    Undoing this depreciation profile will revert the asset to using its
                    global depreciation profile. Please review the options and parameters
                    below and confirm recalculation before deleting.
                  </div>
                )}
              </>
            }
          />
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="Options">
            <Controller
              control={control}
              name="SelectedOption"
              render={({ field: { onChange, value } }) => (
                <Radio
                  name="SelectedOption"
                  onChange={val => onChange(val.target.value)}
                  select={recalcDTO.Options.find(c => value === c.Value).Value}
                >
                  {recalcDTO.Options.map(option => (
                    <Radio.Option
                      key={option.Value}
                      value={option.Value}
                      label={option.Label}
                      disabled={option.Disabled}
                    />
                  ))}
                </Radio>
              )}
            />
          </Fieldset>
        </Grid.Item>
        <Grid.Item>
          <Fieldset legend="Parameters">
            <Grid colGap="compact">
              <Grid.Item l={6}>
                {yearNotApplicable ? (
                  <Input value="n/a" disabled label="As Of Year" />
                ) : (
                  <Input
                    {...getInputProps('AsOfYear')}
                    label="As Of Year"
                    disabled={watchSelectedOption !== 'specified'}
                  />
                )}
              </Grid.Item>
              <Grid.Item l={6}>
                <Controller
                  control={control}
                  name="PostingDate"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps(field)}
                      label="Posting Date"
                      disabled={watchSelectedOption === 'current'}
                      error={errors['PostingDate'] !== undefined}
                      errorMsg={errors['PostingDate']?.message}
                    />
                  )}
                />
              </Grid.Item>
            </Grid>
          </Fieldset>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default DeprecClassRecalcForm
