import { useState } from 'react'
import { useFields } from '@hooks'
import { getInputPropsForModalForms } from '@utils/getInputPropsForModalForms'
import { Task, TaskKey, taskSchema } from '@api/models'
import { FormSubmitEvent } from '@types'
import { validateAndSubmit } from '@utils/validateAndSubmit'
import { ReactSelectOptions, SelectType } from '@components/common/Select/Select.types'
import { useGetExtraDepreciationFieldsQuery, useListGLPostsQuery } from '@api'

export interface TaskFormProps {
  isEdit?: boolean
  initialValues: Task
  handleSubmit: (payload: Task) => Promise<void>
  canEdit?: boolean
}

export const useTaskForm = ({ initialValues, handleSubmit, canEdit }: TaskFormProps) => {
  const { fields } = useFields()
  const [form, setForm] = useState({
    values: initialValues,
    errors: {},
  })

  function getField(
    name: TaskKey,
    select?: SelectType,
    selectOptions?: ReactSelectOptions
  ) {
    const field = getInputPropsForModalForms({
      name,
      select,
      selectOptions,
      fieldMap: fields,
      schema: taskSchema as any,
      initialValues,
      form,
      setForm,
    })

    return { ...field, disabled: !canEdit }
  }

  const { data: glPostsList, isLoading: isLoadingGLPostsList } = useListGLPostsQuery()
  const {
    data: extraFields,
    isLoading: isLoadingExtraFields,
  } = useGetExtraDepreciationFieldsQuery()

  async function handleFormSubmit(e: FormSubmitEvent): Promise<void> {
    e.preventDefault()
    await validateAndSubmit(taskSchema as any, form, setForm, handleSubmit)
  }

  const getOptions = glPostsList => [
    { label: '', value: '' },
    ...glPostsList?.map(post => ({
      label: `${post.ID} (${post.Name})`,
      value: post.ID,
    })),
  ]

  const glPostsListOptions =
    !isLoadingExtraFields && !isLoadingGLPostsList ? getOptions(glPostsList) : []

  return {
    getField,
    handleFormSubmit,
    form,
    isLoadingExtraFields,
    isLoadingGLPostsList,
    extraFields,
    glPostsListOptions,
  }
}
