import HttpClient from "../../HttpClient";

export class ChargesApi extends HttpClient {
    private static classInstance?: ChargesApi;

    public constructor () {
        super (`/Charges`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new ChargesApi();
        }

        return this.classInstance;
    }

    public fetchCharges = () => this.instance.get(`/FetchCharges`);
    public fetchAssetCharges = (assetCompanyId: number, assetId: string, type: string) =>
        this.instance.get(`/FetchAssetCharges?assetCompanyId=${assetCompanyId}&assetId=${assetId}&type=${type}`);
    public getCharge = (id: number) => this.instance.get(`/GetCharge?id=${id}`);
    public getExtraFields = () => this.instance.get(`/GetExtraFields`);
    public importCharge = (file: any) => this.instance.post(`/ImportCharge`, file);
    public validateFiscalYear = (data: any) => this.instance.get(`/ValidateFiscalYear`, data);
    public create = (data: any) => this.instance.post(`/Create`, data);
    public edit = (data: any) => this.instance.put(`/Edit`, data);
    public delete = (data: any) => this.instance.post(`/Delete`, data);

}