import { ImportColumn } from "../importTypes";

export const fields: ImportColumn[] = [
    {
        label: 'Field Name',
        value: 'PM_NAME'
    },
    {
        label: 'Edit Mask',
        value: 'PM_MASK'
    },
    {
        label: 'Default Prompt',
        value: 'PM_MRS_PMT'
    },
    {
        label: 'Default Column Header 1',
        value: 'PM_MRS_CH1'
    },
    {
        label: 'Default Column Header 2',
        value: 'PM_MRS_CH2'
    },
    {
        label: 'Prompt',
        value: 'PM_PMT'
    },
    {
        label: 'Header 1',
        value: 'PM_CH1'
    },
    {
        label: 'Header 2',
        value: 'PM_CH2'
    },
    {
        label: 'Pick List Code',
        value: 'PM_PCKLST'
    },
    {
        label: 'Edit Mask Maintainable',
        value: 'PM_MSK_CHG'
    }
]