import { StyleObject } from '@styles-api/css.types'

const ITEM_X_PADDING = 'var(--scale600)'
const ITEM_Y_PADDING = 'var(--scale400)'

const paddingStyles: StyleObject = {
  paddingTop: ITEM_Y_PADDING,
  paddingBottom: ITEM_Y_PADDING,
  paddingLeft: ITEM_X_PADDING,
  paddingRight: ITEM_X_PADDING,
}

export const container: StyleObject = {
  backgroundColor: 'var(--base-color)',
  borderRadius: 'var(--radius2)',
  overflow: 'hidden',
  boxShadow: 'var(--card-shadow)',
  position: 'fixed',
  top: 'var(--scale1800) + var(--scale800) + 33.6px + var(--scale600)',
  width: '17%',
}
export const categoryButton = (isExpanded: boolean, isSelected: boolean): StyleObject => {
  return {
    ...paddingStyles,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    boxSizing: 'border-box',
    borderBottomWidth: '1px',
    borderTopWidth: '1px',
    marginTop: '-1px',
    backgroundColor: isSelected ? 'var(--shade100)' : 'none',
    color: isSelected ? 'var(--primary-color)' : 'var(--contrast-color)',
    fontWeight: isSelected ? 'var(--font-weight-bold)' : 'var(--font-weight-normal)',
    ':hover': {
      backgroundColor: 'var(--shade100)',
    },
  }
}

export const categoryButtonIcon = (isExpanded: boolean): StyleObject => {
  return {
    transform: isExpanded ? 'scaleY(-1)' : 'scaleY(1)',
    height: 'var(--scale600)',
  }
}

export const categoryList: StyleObject = {
  width: '100%',
}

export const listItem = (isSelected: boolean): StyleObject => {
  return {
    display: 'flex',
    width: '100%',
    position: 'relative',
    '::before': {
      position: 'absolute',
      top: '7px',
      left: '-0px',
      width: '4px',
      height: '24px',
      content: '""',
      borderTopRightRadius: 'var(--radius1)',
      borderBottomRightRadius: 'var(--radius1)',
      background: isSelected ? 'var(--primary-color)' : 'none',
    },
  }
}

export const link = (isSelected: boolean): StyleObject => {
  return {
    ...paddingStyles,
    color: isSelected ? 'var(--primary-color)' : 'var(--contrast-color)',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: isSelected ? 'var(--shade100)' : 'none',
    fontWeight: isSelected ? 'var(--font-weight-bold)' : 'var(--font-weight-normal)',
    ':hover': {
      color: 'var(--primary-color)',
    },
  }
}
