import { Card, TableSkeleton, Table, TextSkeleton, Flexbox, ButtonSkeleton } from '@common'

const AssetChargesLoading = () => {
  return (
    <Card spacing="none">
      <Table.Actions>
        <Flexbox justifyContent="space-between" alignItems="center">
          <TextSkeleton width="120px" />
          <ButtonSkeleton placeholder="Create charge record" />
        </Flexbox>
      </Table.Actions>
      <TableSkeleton rows={5} />
    </Card>
  )
}
export default AssetChargesLoading
