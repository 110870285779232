import DepreciationClassForm from '@components/depreciation-class/DepreciationClassForm'
import { DepreciationProfileCode } from '@api/models'
import View from '@components/View'
import { Heading, Spacer } from '@components/common'
import Template from '@components/layout/Template'

interface Props {
  profile: DepreciationProfileCode
}

const CreateDepreciationClassView = ({ profile }: Props) => {
  return (
    <View title={`Create ${profile === 'A' ? 'Category' : 'Tax Class'} `}>
      <Template maxWidth="compact">
        <Heading type="h1">Add new {profile === 'A' ? 'category' : 'tax class'} </Heading>
        <Spacer y="default" />

        <DepreciationClassForm context="create" params={{ profile }} />
      </Template>
    </View>
  )
}

export default CreateDepreciationClassView
