import { CustomDepreciation, FieldLabels } from '@api/models'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps } from '@hooks'
import {
  CustomDatePicker,
  Fieldset,
  Form,
  Grid,
  Input,
  Note,
  Radio,
  Select,
  Spacer,
} from '@components/common'
import { CUSTOM_DEPRECIATION_FORM_ID } from '@constants'
import { useEffect, useMemo, useState } from 'react'

interface CustomDepreciationFormProps {
  type: 'add' | 'edit' | 'delete'
  fieldLabels: FieldLabels
  onSubmit: (data: CustomDepreciation) => Promise<void>
  initialValues: CustomDepreciation
  depreciationMethod: string
  disabled: boolean
  readonly: boolean
}

const CustomDepreciationForm = ({
  type,
  fieldLabels,
  onSubmit,
  initialValues,
  depreciationMethod,
  disabled,
  readonly,
}: CustomDepreciationFormProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useFormContext<CustomDepreciation>()

  const {
    getInputProps,
    getCreatableSelectProps,
    getRadioProps,
    getDateProps,
  } = useFormFieldProps<CustomDepreciation>({
    isSubmitting,
    errors: errors as any,
    fields: fieldLabels,
    register,
  })

  const defaultAmountOptions = useMemo(
    () => [
      { value: 'A', label: 'Value below is an amount', disabled: false },
      { value: 'R', label: 'Value below is a rate', disabled: false },
    ],
    []
  )
  const [amountOptions, setAmountOptions] = useState(defaultAmountOptions)

  const isCustomDepMethodPercent = watch('SP_MET_CD') === 'P'
  const isDepMethodProductionUnits = depreciationMethod === 'P'

  useEffect(() => {
    reset({ ...initialValues })
  }, [initialValues, reset])

  useEffect(() => {
    if (isDepMethodProductionUnits && type === 'add') {
      setValue('SP_MET_CD', 'U')
      setValue('SP_SUB_CMT', 'A')
    } else if (type === 'add') {
      setValue('SP_MET_CD', 'S')
      setValue('SP_SUB_CMT', 'A')
    }
  }, [isDepMethodProductionUnits, setValue, type])

  useEffect(() => {
    if (isCustomDepMethodPercent) {
      setValue('SP_SUB_CMT', 'R')
      setAmountOptions([
        { value: 'A', label: 'Value below is an amount', disabled: true },
        { value: 'R', label: 'Value below is a rate', disabled: false },
      ])
    } else if (isDepMethodProductionUnits) {
      setAmountOptions([
        { value: 'A', label: 'Value below is in units', disabled: false },
        { value: 'R', label: 'Value below is a rate', disabled: true },
      ])
    } else {
      setAmountOptions(defaultAmountOptions)
    }
  }, [
    isCustomDepMethodPercent,
    isDepMethodProductionUnits,
    setValue,
    setAmountOptions,
    defaultAmountOptions,
  ])

  const defaultMethodOptions = [
    { value: 'S', label: 'Net Book Value', disabled: false },
    { value: 'D', label: 'Acquisition Amount', disabled: false },
    { value: 'P', label: 'Percent of Acquisition for the Year', disabled: false },
  ]

  const productionMethodOptions = [
    { value: 'U', label: 'Production Units', disabled: false },
    { value: 'S', label: 'Net Book Value', disabled: true },
    { value: 'P', label: 'Percent of Acquisition for the Year', disabled: true },
  ]

  const methodOptions = isDepMethodProductionUnits
    ? productionMethodOptions
    : defaultMethodOptions

  const isRate = watch('SP_SUB_CMT') === 'R'

  return (
    <Form id={CUSTOM_DEPRECIATION_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        {isCustomDepMethodPercent && (
          <Grid.Item>
            <Note
              intent="warning"
              content="Percent for year will override all other depreciation calculations."
            />
          </Grid.Item>
        )}
        <Grid.Item m={6}>
          <Fieldset legend="Custom Method">
            <Controller
              control={control}
              name="SP_MET_CD"
              render={({ field }) => (
                <Radio {...getRadioProps(field)}>
                  {methodOptions.map(option => (
                    <Radio.Option
                      value={option.value}
                      label={option.label}
                      key={option.value}
                      disabled={option.disabled || disabled}
                      readOnly={readonly}
                    />
                  ))}
                </Radio>
              )}
            />
          </Fieldset>
        </Grid.Item>
        <Grid.Item m={6}>
          <Fieldset>
            <Controller
              name="SP_SUB_CMT"
              render={({ field }) => (
                <Radio {...getRadioProps(field)}>
                  {amountOptions.map(option => (
                    <Radio.Option
                      value={option.value}
                      label={option.label}
                      key={option.value}
                      disabled={option.disabled || disabled}
                      readOnly={readonly}
                    />
                  ))}
                </Radio>
              )}
            />
            <Spacer y="default" />
            <Grid.Item>
              {isRate ? (
                <Input
                  {...getInputProps('SP_RATE', 'percentage')}
                  label=""
                  disabled={disabled}
                  readOnly={readonly}
                />
              ) : (
                <Input
                  {...getInputProps(
                    'SP_DEP_AMT',
                    isDepMethodProductionUnits ? 'number' : 'currency'
                  )}
                  label=""
                  disabled={disabled}
                  readOnly={readonly}
                />
              )}
            </Grid.Item>
          </Fieldset>
        </Grid.Item>
        <Grid.Item>
          <Controller
            control={control}
            name="SP_ID_UCD"
            render={({ field }) => (
              <Select
                {...getCreatableSelectProps('SP_ID_UCD', field, [])}
                disabled={disabled}
                readOnly={readonly}
              />
            )}
          />
        </Grid.Item>
        {isCustomDepMethodPercent ? (
          <Grid.Item>
            <Input
              {...getInputProps('SP_YOFL', 'number')}
              disabled={disabled}
              readOnly={readonly}
            />
          </Grid.Item>
        ) : (
          <>
            <Grid.Item m={6}>
              <Controller
                control={control}
                name="SP_YMO_FRM"
                render={({ field }) => (
                  <CustomDatePicker
                    {...getDateProps('SP_YMO_FRM', field)}
                    label={fieldLabels[`SP_YMO_FRM`]}
                    disabled={disabled}
                    readOnly={readonly}
                    error={errors['SP_YMO_FRM'] !== undefined}
                    errorMsg={errors['SP_YMO_FRM']?.message}
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item m={6}>
              <Controller
                control={control}
                name="SP_YMO_TO"
                render={({ field }) => (
                  <CustomDatePicker
                    {...getDateProps('SP_YMO_TO', field)}
                    label={fieldLabels[`SP_YMO_TO`]}
                    disabled={disabled}
                    readOnly={readonly}
                    error={errors['SP_YMO_TO'] !== undefined}
                    errorMsg={errors['SP_YMO_TO']?.message}
                  />
                )}
              />
            </Grid.Item>
          </>
        )}
      </Grid>
    </Form>
  )
}

export default CustomDepreciationForm
