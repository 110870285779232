import { useState } from 'react'
import { useFields } from '@hooks'
import { getInputPropsForModalForms } from '@utils/getInputPropsForModalForms'
import { GLPost, GLPostKey, glPostSchema, IGLPostKey } from '@api/models'
import { FormSubmitEvent } from '@types'
import { validateAndSubmit } from '@utils/validateAndSubmit'
import { ReactSelectOptions, SelectType } from '@components/common/Select/Select.types'
import { getAsyncSelectPropsForModalForms } from '@utils/getAsyncSelectPropsForModalForms'
import { SegmentsApi } from '@api'

export interface GLPostFormProps {
  initialValues: GLPost
  isEdit?: boolean
  handleSubmit: (payload: GLPost) => Promise<void>
  selectedGLPostKeys: IGLPostKey[]
  canEdit?: boolean
}

export const useGLPostForm = ({
  handleSubmit,
  initialValues,
  selectedGLPostKeys,
  canEdit,
}: GLPostFormProps) => {
  const { fields } = useFields()
  const [form, setForm] = useState({
    values: initialValues,
    errors: {},
  })

  function getField(
    name: GLPostKey,
    select?: SelectType,
    selectOptions?: ReactSelectOptions
  ) {
    const field = getInputPropsForModalForms({
      name,
      select,
      selectOptions,
      fieldMap: fields,
      schema: glPostSchema,
      initialValues,
      form,
      setForm,
    })

    return { ...field, disabled: !canEdit }
  }

  function getAsyncField(name: GLPostKey) {
    const field = getAsyncSelectPropsForModalForms({
      name,
      fieldMap: fields,
      schema: glPostSchema,
      form,
      setForm,
      initialValues: selectedGLPostKeys,
    })

    return { ...field, disabled: !canEdit }
  }

  async function handleFormSubmit(e: FormSubmitEvent): Promise<void> {
    e.preventDefault()
    await validateAndSubmit(glPostSchema, form, setForm, handleSubmit)
  }

  const sourceOptions: { label: string; value: string }[] = [
    { label: 'Asset Preferred', value: 'A' },
    { label: 'Asset', value: 'B' },
    { label: 'Table Preferred', value: 'T' },
    { label: 'Table', value: 'U' },
  ]

  async function getSegments(key: number, desc: string): Promise<ReactSelectOptions> {
    return await SegmentsApi.getInstance()
      .getSegments(key, desc)
      .then(response =>
        response.data.map(item => ({
          label: `${item.KY_VALUE}_${item.KY_DESC}`,
          value: item.KY_VALUE,
        }))
      )
      .catch(err => Promise.reject(err))
  }

  return {
    getField,
    getAsyncField,
    handleFormSubmit,
    sourceOptions,
    getSegments,
    fields,
  }
}
