import { Card, Flexbox, Grid, Spinner, TableSkeleton } from '@components/common'
import NoteSkeleton from '@components/common/Note/NoteSkeleton'

const ProfileLoading = () => {
  return (
    <Card overflow="hidden">
      <Grid>
        <Grid.Item m={6}>
          <Flexbox alignItems="center" justifyContent="center" flexDirection="column">
            <NoteSkeleton height="100px" size="large" />
            <Spinner />
          </Flexbox>
        </Grid.Item>

        <Grid.Item m={6}>
          <TableSkeleton rows={7} />
        </Grid.Item>
      </Grid>
    </Card>
  )
}

export default ProfileLoading
