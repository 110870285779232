import React from 'react'
import { useStyletron } from 'styletron-react'
import PassClassDown from '@utils/PassClassDown'

import * as styles from './Styles'

const InputIcon = ({ size, icon, position }) => {
  const [css] = useStyletron()
  let isString = typeof icon === 'string'
  let iconStyles = {
    ...styles.icon({ size, position, isString }),
  }

  return (
    <PassClassDown className={css(iconStyles)}>
      {isString ? <div>{icon}</div> : icon}
    </PassClassDown>
  )
}
export default InputIcon
