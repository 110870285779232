import { Asset } from '@api/models'
import { Form, Text, toast } from '@components/common'
import { useReinstateAssetMutation } from '@api'
import { ASSET_REINSTATEMENT_FORM_ID, ERROR_REQUEST } from '@constants'

interface Props {
  assetCompanyID: number
  assetID: string

  asset: Asset
}

const AssetReinstatementForm = ({ assetCompanyID, assetID, asset }: Props) => {
  const [reinstateAsset] = useReinstateAssetMutation({ fixedCacheKey: 'reinstateAsset' })

  async function onSubmitHandler() {
    try {
      await reinstateAsset({ assetCompanyID, assetID }).unwrap()
    } catch (error) {
      toast.error(ERROR_REQUEST)
    }
  }
  return (
    <Form id={ASSET_REINSTATEMENT_FORM_ID} onSubmit={onSubmitHandler}>
      <Text>
        Are you sure you want to reinstate asset{' '}
        <b>
          {assetID} ({asset.AS_DESC})
        </b>
        ?
      </Text>
    </Form>
  )
}

export default AssetReinstatementForm
