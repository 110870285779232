import React from 'react'
import { motion } from 'framer-motion'
import {
  loadingContainer,
  loadingContainerVariants,
  loadingCircle,
  loadingCircleVariants,
  loadingWrapper,
} from './Styles'
const LoadingDots = () => {
  return (
    <motion.div style={loadingWrapper}>
      <motion.div
        style={loadingContainer}
        variants={loadingContainerVariants}
        initial="start"
        animate="end"
      >
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeatType: 'mirror',
            repeat: Infinity,
            repeatDelay: 0.2,
          }}
        />
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeatType: 'mirror',
            repeat: Infinity,
            repeatDelay: 0.2,
          }}
        />
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={{
            duration: 1,
            ease: 'circIn',
            repeatType: 'mirror',
            repeat: Infinity,
            repeatDelay: 0.2,
          }}
        />
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeatType: 'mirror',
            repeat: Infinity,
            repeatDelay: 0.2,
          }}
        />
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeatType: 'mirror',
            repeat: Infinity,
            repeatDelay: 0.2,
          }}
        />
      </motion.div>
    </motion.div>
  )
}

export default LoadingDots
