import React, { useState } from 'react'
import { TooltipProps } from './Tooltip.types'

import { AnimatePresence, motion } from 'framer-motion'
import { useStyletron } from 'styletron-react'
import {
  tooltip_rightStyles,
  tooltip_topStyles,
  tooltip_bottomStyles,
  tooltip_leftStyles,
  tooltip__baseStyles,
  tooltip__wrapperStyles,
  tooltip_successStyles,
  tooltip_warningStyles,
  tooltip_dangerStyles,
} from './Styles'

const Tooltip: React.FC<TooltipProps> = ({
  content,
  position = 'top',
  intent = 'default',
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  const showTip = () => {
    setVisible(true)
  }
  const hideTip = () => {
    setVisible(false)
  }

  /* ====================================================== */
  /*                      APPLY STYLES                      */
  /* ====================================================== */
  const [css] = useStyletron()
  var wrapperStyles = css(tooltip__wrapperStyles)
  if (props.flexGrow) wrapperStyles += ' ' + css({ flexGrow: 1 })
  var tooltipStyles = css(tooltip__baseStyles)

  /* ---------------------- POSITIONS --------------------- */
  if (position === 'top') {
    tooltipStyles += ' ' + css(tooltip_topStyles)
    var xTranslate = '-50%'
    var yTranslate = '0%'
  }
  if (position === 'right') {
    tooltipStyles += ' ' + css(tooltip_rightStyles)
    xTranslate = '0%'
    yTranslate = '-50%'
  }
  if (position === 'bottom') {
    tooltipStyles += ' ' + css(tooltip_bottomStyles)
    xTranslate = '-50%'
    yTranslate = '0%'
  }
  if (position === 'left') {
    tooltipStyles += ' ' + css(tooltip_leftStyles)
    xTranslate = '0%'
    yTranslate = '-50%'
  }

  /* ----------------------- INTENTS ---------------------- */
  if (intent === 'success') tooltipStyles += ' ' + css(tooltip_successStyles)
  if (intent === 'warning') tooltipStyles += ' ' + css(tooltip_warningStyles)
  if (intent === 'danger') tooltipStyles += ' ' + css(tooltip_dangerStyles)

  return (
    <div className={wrapperStyles} onMouseEnter={showTip} onMouseLeave={hideTip}>
      {props.children}
      <AnimatePresence>
        {visible && (
          <motion.div
            className={tooltipStyles}
            initial={{
              opacity: 0,
              scale: 0.7,
              translateX: xTranslate,
              translateY: yTranslate,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              translateX: xTranslate,
              translateY: yTranslate,
              transition: {
                type: 'spring',
                stiffness: 1300,
                damping: 45,
              },
            }}
            exit={{
              opacity: 0,
              scale: 0.9,
              translateX: xTranslate,
              translateY: yTranslate,
              transition: { duration: 0.08 },
            }}
          >
            {content}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Tooltip
