import { Button, Grid, Modal, Spacer } from '@components/common'
import { useAssetStructure } from '@hooks'
import { FormProvider } from 'react-hook-form'
import AssetStructureCompanyHasNoPeriods from './AssetStructureCompanyHasNoPeriods'
import AssetStructureForm from './AssetStructureForm'
import AssetStructureLoading from './AssetStructureLoading'

interface Props {
  assetCompanyID: number
  assetID: string
}

const AssetStructure = ({ assetCompanyID, assetID }: Props) => {
  const {
    isLoading,
    fields,
    getSegmentOptions,
    methods,
    handleValidateTransfer,
    periodOptions,
    activeTransfers,
    isStructuresChanged,
    companyHasPeriods,
    yearOptions,
    isConfirmTransferModalOpen,
    closeConfirmTransferModal,
    isValidatingTransfer,
    isLoadingAssetTransfer,
    handleAssetTransfer,
    validateTransferState,
    closeValidateTransferModal,
  } = useAssetStructure({
    assetCompanyID,
    assetID,
  })

  if (isLoading) return <AssetStructureLoading />

  if (!companyHasPeriods)
    return (
      <AssetStructureCompanyHasNoPeriods
        assetCompanyID={assetCompanyID}
        assetID={assetID}
      />
    )

  return (
    <FormProvider {...methods}>
      {/* Confirm transfer modal */}
      <Modal
        isOpen={isConfirmTransferModalOpen}
        close={closeConfirmTransferModal}
        title="Confirm structure transfer"
        action={methods.handleSubmit(handleAssetTransfer)}
        actionLabel="Transfer asset"
        isActionLoading={isLoadingAssetTransfer}
      >
        Are you sure you want to perform a structure transfer for asset {assetID}?
      </Modal>

      {/* Validate modal */}
      <Modal
        isOpen={validateTransferState.isModalOpen}
        close={closeValidateTransferModal}
        title="Confirm structure transfer"
      >
        <p style={{ whiteSpace: 'pre-line' }}> {validateTransferState.message}</p>
        <Spacer y="default" />
        <Grid colGap="mini">
          <Grid.Item s={6}>
            <Button onClick={() => closeValidateTransferModal()} flex>
              No
            </Button>
          </Grid.Item>
          <Grid.Item s={6}>
            <Button
              onClick={methods.handleSubmit(handleAssetTransfer)}
              flex
              appearance="primary"
              loading={isLoadingAssetTransfer}
            >
              Yes
            </Button>
          </Grid.Item>
        </Grid>
      </Modal>

      <AssetStructureForm
        fields={fields}
        getSegmentOptions={getSegmentOptions}
        assetCompanyID={assetCompanyID}
        handleValidateTransfer={handleValidateTransfer}
        isStructuresChanged={isStructuresChanged}
        activeTransfers={activeTransfers}
        periodOptions={periodOptions}
        yearOptions={yearOptions}
        isValidatingTransfer={isValidatingTransfer}
      />
    </FormProvider>
  )
}

export default AssetStructure
