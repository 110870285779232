import { useStyletron } from 'styletron-react'
import * as styles from './styles'

const ProfileCircle = ({ user, size = 150, margin = 10 }) => {
  const [css] = useStyletron()

  const circleStyle = {
    ...styles.circle,
    width: `${size}px`,
    height: `${size}px`,
    margin: `${margin}px`,
  }

  const circleInnerStyle = {
    ...styles.circleInner,
    height: `${size}px`,
    width: `${size}px`,
    fontSize: `${size / 2.5}px`,
  }

  return (
    <div className={css(circleStyle)}>
      <div className={css(circleInnerStyle)}>
        {user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase()}
      </div>
    </div>
  )
}

export default ProfileCircle
