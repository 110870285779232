import { FieldParm } from '@api/models'
import { Form, Grid, Input } from '@components/common'
import { FIELD_PARM_ID } from '@constants'
import useFormFieldProps from '@hooks/useFormField'
import { useForm } from 'react-hook-form'

interface FieldPrefsFormProps {
  initialValues: FieldParm
  onSubmit: (values: FieldParm) => Promise<void>
}

const FieldPrefsForm = ({ initialValues, onSubmit }: FieldPrefsFormProps) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<FieldParm>({
    mode: 'onBlur',
    defaultValues: initialValues,
  })

  const { getInputProps } = useFormFieldProps<FieldParm>({
    fields: {},
    errors,
    isSubmitting,
    register,
  })

  return (
    <Form id={FIELD_PARM_ID} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid>
        <Grid.Item>
          <Input {...getInputProps('PM_PMT')} label="Field label" />
        </Grid.Item>
      </Grid>
    </Form>
  )
}

export default FieldPrefsForm
