import { AssetCharge, AssetChargeType, FieldLabels } from '@api/models'
import { ButtonGroup, Button, Flexbox, Icon, Table, useModal } from '@components/common'
import { DeleteChargeState } from '@hooks/useAssetCharges'
import { formatCurrency, formatDisplayDate } from '@utils/basic'
import stopPropagation from '@utils/stopPropagation'
import { DISPOSITION_OPTIONS } from '@constants'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTable, useFlexLayout, Column } from 'react-table'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import InlineCommentEditor from '@components/CommentEditor/InlineCommentEditor'
import { useGetSettingsQuery } from '@api'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'

interface Props {
  charges: AssetCharge[]
  chargeTypeCode: AssetChargeType
  openForm: (isCreate: boolean, charge?: AssetCharge) => void
  setDeleteChargeState: Dispatch<SetStateAction<DeleteChargeState>>
  fields: FieldLabels
  canEdit: boolean
}

const AssetLocationsTable = ({
  charges,
  openForm,
  setDeleteChargeState,
  chargeTypeCode,
  fields,
  canEdit,
}: Props) => {
  const navigate = useNavigate()
  const params = useParams()
  const { modalIsOpen, triggerModal, closeModal } = useModal()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: isAssetDesc } = useGetAssetQuery({
    assetCompanyID: parseInt(params.companyID),
    assetID: params.assetID,
  })

  const EditingEnabled =
    isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false

  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  const columns = useMemo<Column<AssetCharge>[]>(
    () => [
      {
        Header: fields[`CH_DESC${chargeTypeCode}`],
        accessor: 'CH_DESC',
      },
      {
        Header: fields[`CH_DATE${chargeTypeCode}`],
        accessor: 'CH_DATE',
        Cell: props => formatDisplayDate(props.value, DateFormat),
      },
      {
        Header: 'Installation Cost',
        accessor: 'CH_DEP_AMT',
        Cell: props => formatCurrency(props.value),
      },
      {
        Header: fields.CH_DIS_CD,
        accessor: 'CH_DIS_CD',
        Cell: props =>
          DISPOSITION_OPTIONS.find(option => option.value === props.value)?.label,
      },
      {
        Header: '',
        accessor: 'AssetID',
        width: 180,
        Cell: props => (
          <Flexbox alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
            <ButtonGroup>
              <Button
                icon={<Icon name="Map" />}
                appearance="clear"
                onClick={e => {
                  stopPropagation(e)
                  navigate(`${props.row.original.CH_SYS_NR}`)
                }}
                size="compact"
              >
                Map
              </Button>
              <Button
                icon={<Icon name="Trash2" />}
                intent="danger"
                appearance="clear"
                disabled={EditingEnabled}
                onClick={e => {
                  stopPropagation(e)
                  setDeleteChargeState({
                    isModalOpen: true,
                    charge: props.row.original,
                  })
                }}
                size="compact"
                hidden={!canEdit}
              ></Button>
              <Button
                icon={<Icon name="MessageSquare" />}
                intent="default"
                appearance="clear"
                onClick={e => {
                  stopPropagation(e)
                  setSearchParams({ id: props.row.original.CH_SYS_NR.toString() })
                  triggerModal()
                }}
                size="compact"
              ></Button>
            </ButtonGroup>
          </Flexbox>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chargeTypeCode]
  )

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable(
    {
      columns,
      data: charges ?? [],
    },
    useFlexLayout
  )

  return (
    <>
      {modalIsOpen && (
        <InlineCommentEditor
          title={'Edit Location Comment'}
          assetCompanyID={parseInt(params.companyID)}
          type={'AL'}
          objectID={params.assetID}
          isOpen={modalIsOpen}
          close={closeModal}
          key1={'00000'}
          key2={searchParams.get('id')}
          canEdit={canEdit}
        />
      )}
      <Table
        {...getTableProps()}
        label="Asset charges table"
        aria-label="Asset charges table"
      >
        <Table.Head>
          {headerGroups.map((headerGroup, i) => (
            <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                <Table.Header
                  {...column.getHeaderProps()}
                  column={column}
                  key={i}
                  tabIndex={0}
                >
                  {column.render('Header')}
                </Table.Header>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Table.Row
                {...row.getRowProps()}
                tabIndex={0}
                onClick={(e: any) => {
                  e.stopPropagation()
                  navigate(`${row.original.CH_SYS_NR}/edit`)
                }}
              >
                {row.cells.map(cell => {
                  return (
                    <Table.Cell {...cell.getCellProps()} column={cell.column}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}

export default AssetLocationsTable
