import { useLazyGetLocationCoordsQuery } from '@api'
import { FetchCoordinatesPayload, FetchCoordinatesResponse } from '@api/models'
import { isNullOrUndefined } from '@utils/basic'
import { useState } from 'react'

interface Props {
  getCoords: (data: FetchCoordinatesPayload) => void
  isLoadingCoords: boolean
  coords: FetchCoordinatesResponse
}

// returns map coordinates
export const useCoords = (): Props => {
  const [getCoords] = useLazyGetLocationCoordsQuery()

  const [data, setData] = useState<{
    isLoadingCoords: boolean
    coords: FetchCoordinatesResponse
  }>({
    isLoadingCoords: false,
    coords: null,
  })

  async function checkResPayload(payload: FetchCoordinatesPayload) {
    setData({ isLoadingCoords: true, coords: null })
    let coords: FetchCoordinatesResponse | null = null

    try {
      coords = await getCoords(payload, true).unwrap()

      if (!isNullOrUndefined(payload.PostalCode) && isNullOrUndefined(coords)) {
        const payloadWithOutPostalCode = { ...payload }
        delete payloadWithOutPostalCode['PostalCode']
        coords = await getCoords(payloadWithOutPostalCode, true).unwrap()
      }

      setData({ isLoadingCoords: false, coords })
    } catch (error) {
      console.error('An error occurred while fetching coordinates:', error)
      setData({ isLoadingCoords: false, coords: null })
    }
  }
  return {
    getCoords: checkResPayload,
    isLoadingCoords: data.isLoadingCoords,
    coords: data.coords,
  }
}
