import Layout from '@components/layout/Layout'
import LocalSideNav from '@components/layout/LocalSideNav'
import DepreciationProfileNotMaintained from './DepreciationProfileNotMaintained'
import { useAssetDepreciationProfiles } from '@hooks'
import { Outlet } from 'react-router-dom'

const AssetDepreciationProfiles = () => {
  const {
    isLoading,
    depreciationProfileLabel,
    isProfileMaintained,
    sideNavItems,
  } = useAssetDepreciationProfiles()

  if (isLoading) return <p>Loading...</p>

  return (
    <Layout hasSidebar sidebarElement={<LocalSideNav items={sideNavItems} />}>
      {isProfileMaintained ? (
        <Outlet />
      ) : (
        <DepreciationProfileNotMaintained profileLabel={depreciationProfileLabel} />
      )}
    </Layout>
  )
}
export default AssetDepreciationProfiles
