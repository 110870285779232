import { apiSlice } from '@api'
import { formatISODate } from '@utils/basic'
import {
  AssetDisposalDTO,
  GetDisposalDataPayload,
  AssetDisposalProjections,
  DisposalProjectionsParams,
  AssetDisposalParams,
  ReinstateAssetParams,
} from '../models'

export const boilerplate = apiSlice.injectEndpoints({
  endpoints: build => ({
    getDisposalData: build.query<AssetDisposalDTO, GetDisposalDataPayload>({
      query: payload =>
        `AssetHistory/DisposalData?assetCompanyID=${payload.assetCompanyID}&assetID=${payload.assetID}`,
      transformResponse: (res: AssetDisposalDTO) => ({
        ...res,
        AssetDisposalParams: {
          ...res.AssetDisposalParams,
          DisposalDate: formatISODate(res.AssetDisposalParams.DisposalDate),
        },
      }),
      providesTags: ['disposal'],
    }),
    getDisposalProjections: build.query<
      AssetDisposalProjections,
      DisposalProjectionsParams
    >({
      query: payload => {
        let query = `AssetHistory/DisposalProjections?assetCompanyId=${payload.assetCompanyID}&assetId=${payload.assetID}`;
        if (payload.period) query += `&period=${payload.period}`;
        if (payload.year) query += `&year=${payload.year}`;
        if (payload.realizedAmount) query += `&realizedAmount=${payload.realizedAmount}`;
        if (payload.disposalSoldTo) query += `&disposalSoldTo=${payload.disposalSoldTo}`;
        if (payload.disposalDate) query += `&disposalDate=${payload.disposalDate}`;
        return query;
      }
    }),
    assetDisposal: build.mutation<void, AssetDisposalParams>({
      query: payload => ({
        url: 'AssetHistory/Disposal',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Asset', 'disposal', 'assetHistory', 'dashboard'],
    }),
    reinstateAsset: build.mutation<void, ReinstateAssetParams>({
      query: payload => ({
        url: `AssetHistory/Reinstate/${payload.assetCompanyID}/${payload.assetID}`,
        method: 'POST',
      }),
      invalidatesTags: ['Asset', 'disposal', 'assetHistory', 'dashboard'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetDisposalDataQuery,
  useGetDisposalProjectionsQuery,
  useAssetDisposalMutation,
  useReinstateAssetMutation,
} = boilerplate
