import React, { useState } from 'react'
import { Button, Modal, useModal, toast, Text, Spacer } from '@common'
import { IMPORT_COMPLETED, ERROR_UPLOAD_FILE } from '@constants'
import ImportInput from './ImportInput'
import { ImportApi } from '@api/Import'
import { ImportType } from './importTypes'
import ImportMap from './ImportMap'

interface ImportDataProps {
  action: ImportType
  title?: string
  modalDesc?: React.ReactNode
  uploadDesc?: React.ReactNode
  onUploadCompletion?: () => void
}

const ImportData = ({
  action,
  title,
  modalDesc = '',
  uploadDesc = '',
  onUploadCompletion = () => {},
}: ImportDataProps) => {
  const [file, setFile] = useState(null)
  const [mapping, setMapping] = useState({})
  const { modalIsOpen, triggerModal, closeModal } = useModal()

  const clearFile = React.useCallback(() => {
    setFile(null)
  }, []);

  const onClose = React.useCallback(() => {
    setFile(null)
    closeModal()
  }, [closeModal]);

  const updateMapping = React.useCallback((mapping) => {
    setMapping(mapping)
  }, []);

  const uploadFile = React.useCallback(async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('file', file)

    const client = ImportApi.getInstance()
    // use a switch statement to determine which import function to call
    // save the response in a variable

    const keys = Object.keys(mapping)
    const lookup = {}

    keys.forEach(key => {
      const item = mapping[key]
      if (!item) return
      lookup[item.value] = key
    })

    const req = async () => {
      switch (action) {
        case 'assets':
          return await client.importAssets(formData, lookup)
        case 'categories':
          return await client.importCategories(formData, lookup)
        case 'companies':
          return await client.importCompanies(formData, lookup)
        case 'glposts':
          return await client.importGLPosts(formData, lookup)
        case 'folders':
          return await client.importFolders(formData, lookup)
        case 'insurance':
          return await client.importInsurance(formData, lookup)
        case 'maintenance':
          return await client.importMaintenance(formData, lookup)
        case 'segments':
          return await client.importSegments(formData, lookup)
        case 'tasks':
          return await client.importTasks(formData, lookup)
        case 'vendors':
          return await client.importVendors(formData, lookup)
        default:
          throw new Error('Invalid import type')
      }
    }

    const res = await req()

    if (await res.status === 200) {
      setFile(null)
      onClose()
      onUploadCompletion()
      toast.success(IMPORT_COMPLETED)
    } else {
      console.error(res.message)
      toast.error(ERROR_UPLOAD_FILE)
    }

  }, [file, action, mapping, onUploadCompletion, onClose]);

  return (
    <React.Fragment>
      <Button appearance='default' onClick={triggerModal}>Import</Button>
      <Modal
        isOpen={modalIsOpen}
        close={onClose}
        onSubmit={e => uploadFile(e)}
        actionDisabled={file === null || file === undefined || file === ''}
        actionLabel="Upload and continue"
        title={title}
        width={600}
      >
        <Text p>{modalDesc}</Text>
        <Spacer y="default" />
        <ImportInput
          file={file}
          onRemove={clearFile}
          uploadDesc={uploadDesc}
          onChange={e => setFile(e.target.files[0])}
        />
        <ImportMap file={file} entity={action} updateMapping={updateMapping} />
      </Modal>
    </React.Fragment>
  )
}
export default ImportData
