import { Text, Spacer, Checkbox } from '@common'
import { useFormFieldProps } from '@hooks'
import { Asset, AssetKey, FieldLabels, MaintainedDepProfiles } from '@api/models'
import { formatCurrency, formatDisplayDate } from '@utils/basic'
import { Controller, useFormContext } from 'react-hook-form'
import { useGetSettingsQuery } from '@api'

interface ConfirmProps {
  maintainedDepProfiles: MaintainedDepProfiles
  fields: FieldLabels
}

const Confirm = ({ maintainedDepProfiles, fields }: ConfirmProps) => {
  const {
    formState: { isSubmitting, errors },
    register,
    watch,
    getValues,
    control,
  } = useFormContext<Asset>()

  const { getCheckBoxProps } = useFormFieldProps({
    isSubmitting,
    errors,
    fields,
    register,
  })

  const formValues: AssetKey[] = [
    'AssetID',
    'AS_DESC',
    'AS_DESC_E',
    'AS_FIN_COS',
    'AS_REC_DAT',
    'AS_FIN_SAL',
    'AS_TAX_BUS',
    'AS_CAT_ID',
  ]

  if (maintainedDepProfiles.IsTaxMaintained) {
    formValues.push('AS_TCLS_ID')
  }
  if (maintainedDepProfiles.IsStateTaxMaintained) {
    formValues.push('AS_SCLS_ID')
  }
  if (maintainedDepProfiles.IsOtherTaxMaintained) {
    formValues.push('AS_4CLS_ID')
  }

  const assetID = watch('AssetID')
  const assetDesc = watch('AS_DESC')
  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  return (
    <>
      <Text block>
        Are you sure you want to add asset {assetID} ({assetDesc}) to{' '}
        {maintainedDepProfiles.AssetCompanyID} ({maintainedDepProfiles.CompanyDesc})?
      </Text>

      <Spacer y="default" />

      <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
        {formValues.map((val, index) => {
          const formValue = getValues(val) as string | number
          return (
            <li
              key={index}
              style={{
                marginBottom: 'var(--scale100)',
                width: '50%',
                paddingRight: 'var(--scale100)',
              }}
            >
              <b>{fields[val]}:</b>{' '}
              {val === 'AS_FIN_COS' || val === 'AS_FIN_SAL'
                ? formatCurrency(formValue)
                : val === 'AS_REC_DAT'
                ? formatDisplayDate(formValue.toString(), DateFormat)
                : formValue}
            </li>
          )
        })}
      </ul>
      <Spacer y="default" />

      <Controller
        name="AS_DIS_CD"
        control={control}
        render={({ field }) => (
          <Checkbox {...getCheckBoxProps(field)} label="Asset is active once added" />
        )}
      />
    </>
  )
}
export default Confirm
