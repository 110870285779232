import AssetLocationDetails from '@components/asset/AssetLocationDetails'
import View from '@components/View'

const AssetLocationDetailsView = () => {
  return (
    <View title="Location Details">
      <AssetLocationDetails />
    </View>
  )
}

export default AssetLocationDetailsView
