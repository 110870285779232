import { REQUIRED_FIELD } from '@constants'
import * as Yup from 'yup'
import { dateExistValidation } from '../../models/validation'

export interface Comment {
  AssetCompanyID: number
  CO_ORI_CD: string
  CO_GRP_ID: string
  CO_KEY1: string
  CO_KEY2: string
  CO_KEY3: string
  CO_KEY4: string
  CO_KEY5: number
  CO_AD_DAT: string
  CO_USR: string
  CO_COMMENT: string
}

export type CommentType = 'AD' | 'AI' | 'AL' | 'AE' | 'AO' | 'CA' | 'TX' | 'AS' | 'AP'

export interface GetCommentPayload {
  assetCompanyID: number
  type: string
  objectID: string
  key1: string
  key2: string
  key3: string
  key4: string
  key5: number
}

export const commentSchema = Yup.object({
  CO_AD_DAT: Yup.string().required(REQUIRED_FIELD).test(dateExistValidation),
  CO_COMMENT: Yup.string().nullable(),
})
