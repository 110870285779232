import { DEPRECIATION_CLASS_VIEWS } from '@constants'
import { useGetDepreciationDescQuery } from '@api'
import { Outlet, useParams } from 'react-router-dom'
import Template from '@components/layout/Template'

interface DepreciationClassViewProps {
  profile: string
}

const profileMap = {
  A: {
    name: 'Category',
    deprecViewPath: '/categories',
    breadcrumbTitle: 'Categories',
  },
  X: {
    name: 'Tax Class',
    deprecViewPath: '/tax-classes',
    breadcrumbTitle: 'Tax Classes',
  },
}

const DepreciationClassTemplate = ({ profile }: DepreciationClassViewProps) => {
  const params = useParams()
  const { data: desc, isLoading, isSuccess } = useGetDepreciationDescQuery({
    assetCompanyID: 0,
    id: params.id,
    profile,
  })

  if (!isSuccess && !isLoading) {
    return <div>Error</div>
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  const { name, deprecViewPath, breadcrumbTitle } = profileMap[profile]
  const title = `${name} ${decodeURIComponent(params.id)}: ${desc}`

  const navItems = [
    {
      label: 'Details',
      viewPath: `${deprecViewPath}/${encodeURIComponent(params.id)}/${
        DEPRECIATION_CLASS_VIEWS.DETAILS
      }`,
    },
    {
      label: 'Custom Depreciation',
      viewPath: `${deprecViewPath}/${encodeURIComponent(params.id)}/${
        DEPRECIATION_CLASS_VIEWS.CUSTOM_DEPRECIATION
      }`,
    },
  ]

  return (
    <Template
      localNav={navItems}
      title={title}
      maxWidth="compact"
      breadcrumbs={[
        {
          label: breadcrumbTitle,
          path: deprecViewPath,
        },
        {
          label: params.id,
          isActive: true,
        },
      ]}
    >
      <Outlet />
    </Template>
  )
}

export default DepreciationClassTemplate
