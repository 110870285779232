import { TextareaProps } from './Textarea.types'

import { useStyletron } from 'styletron-react'
import * as styles from '../Input/Styles'
import Icon from '../Icon'
import React, { useState } from 'react'

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>
  (
    (
      {
        label,
        height,
        error = false,
        helper,
        errorMsg,
        width,
        flexGrow,
        appearance = 'default',
        value = '',
        ...props
      }: TextareaProps,
      ref
      ) => {
      const [focus, setFocus] = useState(false)

      // Styling
      const [css] = useStyletron()

      var containerStyles = css({
        ...styles.container({ width, flexGrow }),
      })

      var labelStyles = css({
        ...styles.label({ disabled: props.disabled, label }),
      })

      let inputWrapperStyles = css({
        ...styles.inputWrapperBase,
        ...styles.inputWrapperDisabled({ disabled: props.disabled, appearance }),
        ...styles.getFocus({ focus, error }),
      })

      let inputStyles = css({
        ...styles.inputBase,
        ...styles.inputBaseDisabled({ disabled: props.disabled }),
        paddingLeft: 'var(--scale500)',
        paddingRight: 'var(--scale500)',
        paddingTop: 'var(--scale200)',
        paddingBottom: 'var(--scale200)',
        height: '64px',
        minWidth: '100%',
        boxSizing: 'border-box',
      })
      if (props.readOnly) inputStyles += ' ' + css(styles.getReadOnly)

      let helperStyles = css({
        ...styles.helperText({ disabled: props.disabled }),
      })

      let errorMsgClassName = css(styles.errorMsg)
      let errorIconStyles = css(styles.errorMsgIcon)

      // let tabIndex
      // if (props.readOnly) tabIndex = -1

      return (
        <div className={containerStyles}>
          <label className={labelStyles} htmlFor={props.name} id={`${props.name}-label`}>
            {label
              ? `${props.required ? '*' : ''}${label}`
              : props.placeholder
                ? props.placeholder
                : `Enter value for ${props.name}`}
          </label>
          <div className={inputWrapperStyles}>
            <textarea
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              className={inputStyles}
              aria-label={label}
              onChange={props.onChange}
              value={value ?? ''}
              defaultValue={props.defaultValue}
              name={props.name ? props.name : props.id}
              id={props.id}
              disabled={props.disabled}
              maxLength={props.maxLength}
              minLength={props.minLength}
              autoComplete={props.autoComplete}
              autoFocus={props.autoFocus}
              form={props.form}
              required={props.required}
              readOnly={props.readOnly}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              onKeyUp={props.onKeyUp}
              placeholder={props.placeholder}
              ref={ref}
              aria-labelledby={
                props['aria-labelledby'] ? props['aria-labelledby'] : `${props.name}-label`
              }
            />
          </div>
          {helper && !error && <div className={helperStyles}>{helper}</div>}
          {errorMsg && error && (
            <div className={errorMsgClassName}>
              <Icon name="AlertCircle" className={errorIconStyles} /> {errorMsg}
            </div>
          )}
        </div>
      )
    })
export default Textarea
