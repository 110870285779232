import { Asset } from '@api/models'
import { Modal, Note, Spacer, Text } from '@components/common'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  isOpen: boolean
  setIsChangeAssetStatusModalOpen: Dispatch<SetStateAction<boolean>>
  asset: Asset
  onSubmit
  isLoadingSubmit: boolean
  isDirty: boolean
}

const ChangeAssetStatusModal = ({
  isOpen,
  setIsChangeAssetStatusModalOpen,
  asset,
  onSubmit,
  isDirty,
  isLoadingSubmit,
}: Props) => {
  const currentAssetStatus: 'A' | 'N' = asset.AS_DIS_CD as any

  return (
    <Modal
      title={`${currentAssetStatus === 'A' ? 'Deactivate' : 'Active'} ${asset.AssetID} (${
        asset.AS_DESC
      })`}
      isOpen={isOpen}
      close={() => setIsChangeAssetStatusModalOpen(false)}
      action={onSubmit}
      actionLabel={`${currentAssetStatus === 'A' ? 'Deactivate asset' : 'Activate asset'} ${
        isDirty ? ' and save' : ''
      }`}
      intent={currentAssetStatus === 'A' ? 'danger' : 'default'}
      isActionLoading={isLoadingSubmit}
    >
      <Text p>
        {' '}
        Are you sure you want to {currentAssetStatus === 'A' && 'de'}activate asset{' '}
        <b>
          {asset.AssetID} ({asset.AS_DESC})
        </b>
        ?
      </Text>
      {isDirty && (
        <>
          <Spacer y="default" />
          <Note
            intent="warning"
            content={
              'You have other unsaved changes. By continuing, you will also save those changes.'
            }
          />
        </>
      )}
    </Modal>
  )
}
export default ChangeAssetStatusModal
