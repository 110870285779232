export type AppStateType =
  | 'IDL'
  | 'PDR'
  | 'YDR'
  | 'PDU'
  | 'YDU'
  | 'PDN'
  | 'YDN'
  | 'CAR'
  | 'TCR'

export interface AppState {
  AssetCompanyID: number
  StateType: AppStateType
  TimeStamp: string
  UserEmail: string
}

export const APP_STATE_TYPES = {
  IDLE: 'IDL',

  PERIOD_DEPRECIATION_RUN: 'PDR',
  YEAR_END_DEPRECIATION_RUN: 'YDR',

  PERIOD_DEPRECIATION_UPDATE: 'PDU',
  YEAR_END_DEPRECIATION_UPDATE: 'YDU',

  PERIOD_DEPRECIATION_UNDO: 'PDN',
  YEAR_END_DEPRECIATION_UNDO: 'YDN',

  CATEGORY_RECALCULATION: 'CAR',
  TAX_CLASS_RECALCULATION: 'TCR',
}
