import GLPosts from '@components/GLPosts'
import View from '@components/layout/View'

const GLPostRootView = () => {
  return (
    <View title="General Ledger Codes" showHeader={false}>
      <GLPosts />
    </View>
  )
}

export default GLPostRootView
