import { Company, FieldLabels } from '@api/models'
import DataDisplayList from '@components/DataDisplayList'

interface Props {
  company: Company
  fields: FieldLabels
}

const CompanyBasicInfoDisplay = ({ company, fields }: Props) => {
  const items = [
    {
      label: 'Company ID',
      value: company?.AssetCompanyID,
    },
    {
      label: fields.CM_DESC,
      value: company?.CM_DESC,
    },
    {
      label: fields.CM_DESCS,
      value: company?.CM_DESCS,
    },
  ]
  return <DataDisplayList items={items} columns={1} />
}

export default CompanyBasicInfoDisplay
