import React, { useState } from 'react'
import { AsyncSelectProps } from './AsyncSelect.types'
import AsyncSelect from 'react-select/async'
import { useStyletron } from 'styletron-react'
import * as styles from '../Select/Styles'
import * as inputStyles from '../Input/Styles'
import Icon from '../Icon'
import Spinner from '../Spinner'

const ClearIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props

  const [css] = useStyletron()
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: 'var(--size-default)',
        justifyContent: 'center',
      }}
    >
      <Icon name="X" className={css(styles.clearIcon)} />
    </div>
  )
}

const AsyncSelectComponent: React.FC<AsyncSelectProps> = ({ ...props }) => {
  const [css] = useStyletron()
  const [state, setState] = useState({ searchTimeout: null, inputValue: null })

  const selectStyles = {
    control: (provided, state) => ({
      ...styles.control(provided, state, props.disabled, props.error, 'default'),
    }),
    valueContainer: provided => ({ ...styles.valueContainer(provided) }),
    indicatorsContainer: () => ({ ...styles.indicatorsContainer }),
    menu: (provided, state) => ({
      ...styles.menu(provided, state),
    }),
    option: (provided, state) => ({
      ...styles.option(provided, state),
    }),
  }
  let labelStyles = css({
    ...inputStyles.label({ label: props.label, disabled: props.disabled }),
  })
  let iconStyles = css({
    ...inputStyles.selectIcon({ appearance: 'default', size: 'default' }),
  })

  function loadOptions(inputValue: string) {
    return new Promise<Array<any>>(resolve => {
      if (state.searchTimeout) {
        clearTimeout(state.searchTimeout)
      }

      setState(prevState => ({
        ...prevState,
        searchTimeout: setTimeout(() => {
          resolve(props.action(inputValue))
        }, 600),
      }))
    })
  }

  function getNoOptionsMsg() {
    return state.inputValue ? 'No results found' : 'Start typing to search...'
  }

  function handleInputChange(e) {
    setState(prevState => ({
      ...prevState,
      inputValue: e,
    }))
  }

  let isEmpty = !props.value || props?.value?.value === ''
  let isUndef = props.value === undefined

  let value
  if (isUndef) {
    value = undefined
  } else if (isEmpty) {
    value = null
  } else {
    value = { label: props.value.label, value: props.value.value }
  }
  var emptyValue = !value || value === ''

  return (
    <div>
      <label className={labelStyles}>
        {props.required && '*'}
        {props.label}
      </label>
      <AsyncSelect
        cacheOptions={false}
        defaultOptions={false}
        isClearable={true}
        value={value}
        loadOptions={loadOptions}
        onChange={props.onChange}
        noOptionsMessage={getNoOptionsMsg}
        onInputChange={handleInputChange}
        placeholder={props.placeholder || ''}
        isDisabled={props.disabled}
        styles={selectStyles}
        components={{
          DropdownIndicator: emptyValue
            ? () => <Icon name="ChevronDown" className={iconStyles} />
            : null,
          IndicatorSeparator: () => null,
          ClearIndicator: emptyValue ? () => null : ClearIndicator,
          LoadingIndicator: () => (
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                marginRight: 'var(--scale500)',
              }}
            >
              <Spinner size="compact" />
            </div>
          ),
        }}
      />
    </div>
  )
}

export default AsyncSelectComponent
