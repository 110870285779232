import { ForwardRefRenderFunction, forwardRef } from 'react'

interface InputProps {
  className: string
  value: string
  placeholder: string
  onFocus: () => void
  onChange: () => void
  onClick: () => void
}
const CustomDatePickerInput: ForwardRefRenderFunction<any, InputProps> = (
  { className, value, placeholder, onClick, onFocus, onChange },
  ref
) => {
  return (
    <input
      className={className}
      type="text"
      value={value}
      ref={ref}
      placeholder={placeholder}
      onFocus={onFocus}
      onChange={onChange}
      onClick={onClick}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  )
}

export default forwardRef(CustomDatePickerInput)
