import { Grid, Select, Spinner, Text, Flexbox, AccordionSkeleton } from '@common'
import { Asset, FieldLabels, MaintainedDepProfiles } from '@api/models'
import { useFetchTaxClassesQuery, useListDepreciationsQuery } from '@api'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import useFormFieldProps from '@hooks/useFormField'
import { Controller, useFormContext } from 'react-hook-form'
import DepreciationProfilesLoading from './DepreciationProfilesLoading'

interface DepreciationProfilesProps {
  assetCompanyID: number
  maintainedDepProfiles: MaintainedDepProfiles
  fields: FieldLabels,
}

const DepreciationProfiles = ({
  fields,
  maintainedDepProfiles,
}: DepreciationProfilesProps) => {
  // get depreciation profiles
  const {
    data: categories,
    isLoading: isLoadingCategories,
    isSuccess: isSuccessGettingCategories,
  } = useListDepreciationsQuery({ profile: 'A' })

  const categoryOptions: ReactSelectOptions = []
  if (isSuccessGettingCategories) {
    for (const category of categories) {
      categoryOptions.push({
        label: `${category.ID} - ${category.Name}`,
        value: category.ID,
      })
    }
  }

  const {
    data: taxClasses,
    isLoading: isLoadingtaxClasses,
    isSuccess: isSuccessGettingTaxClasses,
  } = useListDepreciationsQuery(
    { profile: 'X' },
    { skip: !maintainedDepProfiles.IsTaxMaintained }
  )

  const taxClassesOptions: ReactSelectOptions = []
  if (isSuccessGettingTaxClasses) {
    for (const taxClass of taxClasses) {
      taxClassesOptions.push({
        label: `${taxClass.ID} - ${taxClass.Name}`,
        value: taxClass.ID,
      })
    }
  }

  const {
    formState: { isSubmitting, errors },
    register,
    control,
  } = useFormContext<Asset>()

  const { getSelectProps } = useFormFieldProps({
    isSubmitting,
    errors,
    fields,
    register,
  })

  const getTotalInputs = () => {
    let count = 1
    if (maintainedDepProfiles.IsTaxMaintained) count++
    if (maintainedDepProfiles.IsStateTaxMaintained) count++
    if (maintainedDepProfiles.IsOtherTaxMaintained) count++
    return count
  }

  const isLoading: boolean = isLoadingCategories || isLoadingtaxClasses

  if (isLoading) return <DepreciationProfilesLoading totalInputs={getTotalInputs()} />

  return (
    <Grid colGap="mini">
      <Grid.Item>
        <Text light>
          Set the depreciation profiles for this asset. You can add asset-specific
          depreciation after the asset is added.
        </Text>
      </Grid.Item>
      <Grid.Item>
        <Controller
          name={'AS_CAT_ID'}
          control={control}
          render={({ field }) => (
            <Select
              {...getSelectProps('AS_CAT_ID', field, categoryOptions)}
              helper="Sets the default corporate depreciation profile for this asset"
              autoFocus
            />
          )}
        />
      </Grid.Item>
      {maintainedDepProfiles.IsTaxMaintained && (
        <Grid.Item>
          <Controller
            name={'AS_TCLS_ID'}
            control={control}
            render={({ field }) => (
              <Select
                {...getSelectProps('AS_TCLS_ID', field, taxClassesOptions)}
                helper="Sets the default tax depreciation profile for this asset"
              />
            )}
          />
        </Grid.Item>
      )}
      {maintainedDepProfiles.IsStateTaxMaintained && (
        <Grid.Item>
          <Controller
            name={'AS_SCLS_ID'}
            control={control}
            render={({ field }) => (
              <Select
                {...getSelectProps('AS_SCLS_ID', field, taxClassesOptions)}
                helper="Sets the state tax depreciation profile for this asset"
              />
            )}
          />
        </Grid.Item>
      )}
      {maintainedDepProfiles.IsOtherTaxMaintained && (
        <Grid.Item>
          <Controller
            name={'AS_4CLS_ID'}
            control={control}
            render={({ field }) => (
              <Select
                {...getSelectProps('AS_4CLS_ID', field, taxClassesOptions)}
                helper="Sets the additional (other) tax depreciation profile for this asset"
              />
            )}
          />
        </Grid.Item>
      )}
    </Grid>
  )
}

export default DepreciationProfiles
