import React, { useContext, useState } from 'react'
import { RadioOptionProps } from './Radio.types'

import { RadioGroupContext } from './context'
import { useStyletron } from 'styletron-react'

import * as styles from './Styles'

const RadioOption: React.FC<RadioOptionProps> = ({ ...props }) => {
  const context = useContext(RadioGroupContext)
  const [focus, setFocus] = useState(false)

  const [css] = useStyletron()

  let wrapperClassName = css(styles.optionWrapper)
  let labelClassName = css(styles.label({ disabled: props.disabled }))

  let buttonStyles = {
    ...styles.buttonBase,
    ...styles.getButtonState({
      checked: props.value === context.select,
      disabled: props.disabled,
    }),
    ...styles.getFocus({ focus }),
  }
  let buttonClassName = css(buttonStyles)
  let buttonInnerClassName = css(
    styles.buttonInner({
      checked: props.value === context.select,
      disabled: props.disabled,
    })
  )
  let nativeInputClassName = css(styles.nativeInput({ disabled: props.disabled }))
  let labelTextClassName = css(styles.labelText)

  return (
    <div className={wrapperClassName}>
      <label
        className={labelClassName}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      >
        <input
          {...context}
          value={props.value}
          label={props.label}
          select="false"
          className={nativeInputClassName}
          aria-label={props.label}
          disabled={props.disabled}
          checked={props.checked}
          tabIndex={0}
        />
        <div className={buttonClassName}>
          <div className={buttonInnerClassName} />
        </div>
        <div className={labelTextClassName}>{props.label}</div>
      </label>
    </div>
  )
}
export default RadioOption
