import AssetStructure from '@components/asset/AssetStructure'
import { useParams } from 'react-router-dom'

const AssetStructureView = () => {
  const params = useParams()

  return (
    <AssetStructure assetCompanyID={parseInt(params.companyID)} assetID={params.assetID} />
  )
}

export default AssetStructureView
