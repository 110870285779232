import { parseAsset, parseAssetFormData, formatISODate } from '@utils/basic'
import { getUserEmail, getUserFullName } from '@components/Auth/profile'
import {
  Asset,
  DeleteAssetPayload,
  GetAssetPayload,
  GetAssetsPayload,
  GetAssetsResponse,
  EditAssetID,
  AssetRecalcDTO,
  EditAssetPayload,
  DuplicateAssetPayload,
} from '@api/assets/models'
import moment from 'moment'
import { apiSlice } from '@api'

export const assetsEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getAssets: build.query<GetAssetsResponse, GetAssetsPayload>({
      query: ({ assetCompanyID, pageNumber, itemsPerPage, filter }) =>
        `Asset/FetchAssets?assetCompanyID=${assetCompanyID}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      transformResponse: ({ count, list }: GetAssetsResponse) => ({
        count,
        list: list.map(asset => parseAsset(asset)),
      }),
      providesTags: ['Asset'],
    }),
    getAsset: build.query<Asset, GetAssetPayload>({
      query: ({ assetCompanyID, assetID }) =>
        `Asset/GetAsset?assetCompanyID=${assetCompanyID}&assetID=${assetID}`,
      transformResponse: res => {
        return parseAsset(res)
      },
      providesTags: ['Asset'],
    }),
    getAssetIDs: build.query<string[], { assetCompanyID: number}>({
      query: payload =>
        `Asset/GetAssetIDs?assetCompanyID=${payload.assetCompanyID}`,
      providesTags: ['Asset'],
    }),
    getAssetDesc: build.query<string, { assetCompanyID: number; assetID: string }>({
      query: payload =>
        `Asset/GetAssetDesc?assetCompanyID=${payload.assetCompanyID}&assetID=${payload.assetID}`,
      providesTags: ['Asset'],
    }),
    addNewAsset: build.mutation<void, Asset>({
      query: initialAsset => {
        const userFullName = getUserFullName()
        const assetStatusCheckbox = initialAsset.AS_DIS_CD.toString() === 'true' ? 'A' : 'N'

        return {
          url: `Asset/Create`,
          method: 'POST',
          body: {
            ...initialAsset,
            AS_CHG_USR: userFullName,
            AS_ADD_USR: userFullName,
            AS_DIS_CD: assetStatusCheckbox,
          },
        }
      },
      invalidatesTags: ['Asset', 'dashboard'],
    }),
    // put recalc in the payload after the &
    updateAsset: build.mutation<Asset, EditAssetPayload>({
      query: assetPayload => ({
        url: `Asset/Edit`,
        method: 'POST',
        body: {
          ...assetPayload,
          asset: {
            ...parseAssetFormData(assetPayload.asset),
            AS_CHG_USR: getUserFullName(),
          },
        },
      }),
      transformResponse: (response: Asset, _meta, _arg) => parseAssetFormData(response),
      invalidatesTags: ['Asset', 'assetHistory', 'identifiers', 'assetTags'],
    }),
    assetNeedsRecalc: build.mutation<AssetRecalcDTO, Asset>({
      query: payload => ({
        url: 'Asset/AssetNeedsRecalc',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (recalcDTO: AssetRecalcDTO) => ({
        ...recalcDTO,
        PostingDate: formatISODate(moment().toISOString()),
      }),
    }),
    deleteAsset: build.mutation<void, DeleteAssetPayload>({
      query: ({ assetCompanyID, assetID }) => ({
        url: `Asset/Delete/${assetCompanyID}/${assetID}`,
        method: 'POST',
      }),
      invalidatesTags: ['Asset', 'dashboard'],
    }),
    editAssetID: build.mutation<void, EditAssetID>({
      query: payload => ({
        url: 'Asset/EditID',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Asset'],
    }),
    duplicateAsset: build.mutation<void, DuplicateAssetPayload>({
      query: payload => ({
        url: 'Asset/DuplicateAsset',
        method: 'POST',
        body: {
          ...payload,
          userEmail: getUserEmail(),
        },
      }),
      invalidatesTags: ['Asset', 'dashboard'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useDeleteAssetMutation,
  useAddNewAssetMutation,
  useUpdateAssetMutation,
  useGetAssetQuery,
  useGetAssetIDsQuery,
  useGetAssetDescQuery,
  useGetAssetsQuery,
  useAssetNeedsRecalcMutation,
  useEditAssetIDMutation,
  useDuplicateAssetMutation,
} = assetsEndpoints
