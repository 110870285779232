import { Button, Flexbox, Heading, Modal, Spacer } from '@components/common'
import { FOLDER_FORM_ID } from '@constants'

import FolderForm from './FolderForm'
import FoldersTable from './FoldersTable'
import useFolders from './useFolders'
import usePermission from '@components/Auth/Permissions/usePermission'
import ImportData from '@components/ImportData'
import { download } from '@utils/basic'

const Folders = () => {
  const {
    createFolderModalIsOpen,
    openCreateFolderModal,
    handleCreateFolder,
    closeCreateFolderModal,
    selectedFolder,
    isLoadingCreateFolder,
  } = useFolders()

  const { canEditSettings: canEdit } = usePermission()

  const importButton = (
    <Button
      onClick={() => download(`${process.env.PUBLIC_URL}/templates/folders.csv`, 'folders.csv')}
      appearance="text"
    >
      Download an import template here.
    </Button>
  )

  return (
    <>
      <header>
        <Flexbox justifyContent="space-between">
          <Heading type="h1">Folders</Heading>
          <Flexbox alignItems='center' flexDirection='row' gap={12}>
            <ImportData action='folders' title='Import Folders' modalDesc={importButton} />
            <Button onClick={openCreateFolderModal} appearance="primary" hidden={!canEdit}>
              Add folder
            </Button>
          </Flexbox>
        </Flexbox>
      </header>

      <Spacer y="default" />

      <FoldersTable {...useFolders()} canEdit={canEdit} />

      <Modal
        isOpen={createFolderModalIsOpen}
        title={`Add a new folder`}
        form={FOLDER_FORM_ID}
        close={closeCreateFolderModal}
        actionLabel={'Add folder'}
        isActionLoading={isLoadingCreateFolder}
        width={576}
      >
        <FolderForm
          handleSubmit={handleCreateFolder}
          isEdit={false}
          initialValues={selectedFolder}
        />
      </Modal>
    </>
  )
}
export default Folders
