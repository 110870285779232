import { apiSlice } from '@api'
import {
  AssetDepreciationParams,
  AssetSpecificDepreciationParams,
  DepreciationClass,
  DepreciationClassParams,
  DepreciationExtraFields,
  EditDepreciationPayload,
  FetchDepreciationsResponse,
  ListDepreciationsPayload,
  ListDepreciationsResponse,
} from './models'
import { AssetRecalcDTO } from '@api/assets/models'
import { StandardFetchRequestPayload } from '@api/models'
import { formatISODate } from '@utils/basic'
import moment from 'moment'

export const depreciationEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    // DEPRECIATION PROFILES
    listDepreciations: build.query<ListDepreciationsResponse, ListDepreciationsPayload>({
      query: ({ profile }) => `Deprecs/ListDepreciations?type=${profile}`,
    }),
    fetchCategories: build.query<FetchDepreciationsResponse, StandardFetchRequestPayload>({
      query: ({ pageNumber, itemsPerPage, filter }) =>
        `Deprecs/FetchCategories?pageNumber=${pageNumber}&itemsPerPage${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      providesTags: ['depreciation'],
    }),
    fetchTaxClasses: build.query<FetchDepreciationsResponse, StandardFetchRequestPayload>({
      query: ({ pageNumber, itemsPerPage, filter }) =>
        `Deprecs/FetchTaxClasses?pageNumber=${pageNumber}&itemsPerPage${itemsPerPage}&filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`,
      providesTags: ['depreciation'],
    }),
    getDepreciation: build.query<DepreciationClass, DepreciationClassParams>({
      query: ({ assetCompanyID, id, profile }) =>
        `Deprecs/GetDepreciation/${assetCompanyID}/${id}/${profile}`,
      providesTags: ['depreciation', 'assetDepreciation'],
    }),
    getDepreciationDesc: build.query<{ Desc: string }, DepreciationClassParams>({
      query: ({ assetCompanyID, id, profile }) =>
        `Deprecs/GetDepreciationDesc/${assetCompanyID}/${id}/${profile}`,
      providesTags: ['depreciation'],
      transformResponse: ({ Desc }) => Desc,
    }),
    createDepreciation: build.mutation<void, DepreciationClass>({
      query: ({ ...depreciation }) => ({
        url: 'Deprecs/Create',
        method: 'POST',
        body: { ...depreciation },
      }),
      invalidatesTags: ['depreciation', 'assetDepreciation'],
    }),
    editDepreciation: build.mutation<DepreciationClass, EditDepreciationPayload>({
      query: deprecEdit => ({
        url: 'Deprecs/Edit',
        method: 'PUT',
        body: deprecEdit,
      }),
      invalidatesTags: [
        'depreciation',
        'assetDepreciation',
        'customDepreciation',
        'assetHistory',
      ],
    }),
    deleteDepreciation: build.mutation<DepreciationClass, EditDepreciationPayload>({
      query: deprecDelete => ({
        url: 'Deprecs/Delete',
        method: 'POST',
        body: deprecDelete,
      }),
      invalidatesTags: [
        'depreciation',
        'assetDepreciation',
        'customDepreciation',
        'assetHistory',
      ],
    }),
    getExtraDepreciationFields: build.query<DepreciationExtraFields, void>({
      query: () => `Deprecs/GetExtraFields`,
    }),
    getAssetDepreciation: build.query<DepreciationClass, AssetDepreciationParams>({
      query: payload =>
        `Deprecs/GetAssetDepreciation?assetCompanyID=${payload.assetCompanyID}&assetID=${payload.assetID}&type=${payload.profile}`,
      providesTags: ['assetDepreciation'],
    }),
    createAssetSpecificDepreciation: build.mutation<void, AssetSpecificDepreciationParams>({
      query: payload => ({
        url: `Deprecs/CreateAssetSpecificDepreciation/${payload.assetCompanyID}/${payload.assetID}/${payload.profile}`,
        method: 'POST',
      }),
      invalidatesTags: ['assetDepreciation', 'depreciation', 'customDepreciation'],
    }),
    doesDeprecNeedRecalc: build.mutation<AssetRecalcDTO, DepreciationClass>({
      query: depreciation => ({
        url: 'Deprecs/DeprecNeedsRecalc',
        method: 'POST',
        body: { ...depreciation },
      }),
      transformResponse: (recalcDTO: AssetRecalcDTO) => ({
        ...recalcDTO,
        PostingDate: formatISODate(moment().toISOString()),
      }),
      invalidatesTags: ['depreciation', 'assetDepreciation'],
    }),
    doesDeleteDeprecNeedRecalc: build.mutation<AssetRecalcDTO, DepreciationClass>({
      query: depreciation => ({
        url: 'Deprecs/DeleteDeprecNeedsRecalc',
        method: 'POST',
        body: { ...depreciation },
      }),
      transformResponse: (recalcDTO: AssetRecalcDTO) => ({
        ...recalcDTO,
        PostingDate: formatISODate(moment().toISOString()),
      }),
      invalidatesTags: ['depreciation', 'assetDepreciation'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useListDepreciationsQuery,
  useFetchCategoriesQuery,
  useFetchTaxClassesQuery,
  useGetDepreciationQuery,
  useGetDepreciationDescQuery,
  useEditDepreciationMutation,
  useCreateDepreciationMutation,
  useDeleteDepreciationMutation,
  useGetExtraDepreciationFieldsQuery,
  useGetAssetDepreciationQuery,
  useCreateAssetSpecificDepreciationMutation,
  useDoesDeprecNeedRecalcMutation,
  useDoesDeleteDeprecNeedRecalcMutation,
} = depreciationEndpoints
