import { AssetLocation } from '@api/models'
import { Button, Card, Grid, Icon, Note, Spacer, Text } from '@components/common'
import AssetLocationDetailsDisplay from './AssetLocationDetailsDisplay'
import AssetLocationDetailsForm from './AssetLocationDetailsForm'
import { FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useAssetLocationDetails } from '@hooks'
import SaveBar from '@components/SaveBar'
import { EDIT_ASSET_LOCATION_DETAILS_FORM_ID } from '@constants'
import AssetLocationMap from './AssetLocationMap'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import usePermission from '@components/Auth/Permissions/usePermission'

const AssetLocationDetails = () => {
  const params = useParams()

  const {
    location,
    hasLocation,
    isLoading,
    fields,

    formMethods,
    handleLocationFormSubmit,
    isLoadingFormSubmit,

    isLocationDetailsFormActive, // this is to "Edit" or "Close" the form
    setIsLocationDetailsFormActive,

    dirtyFields,
    isDirty,
    isFormValid,
  } = useAssetLocationDetails({
    assetCompanyID: parseInt(params.companyID),
    assetID: params.assetID,
    systemNumber: parseInt(params.locationID),
  })

  const { canEditAssets: canEdit } = usePermission()

  const locationDetailFields: Array<keyof AssetLocation> = [
    'LocationName',
    'Latitude',
    'Longitude',
    'Address',
    'City',
    'Country',
    'State',
    'PostalCode',
    'LUserName',
  ]

  let isCardValuesEdited = locationDetailFields.some(fieldName => dirtyFields[fieldName])

  const { data: AssDetails, isLoading: AssDetailsLoading } = useGetAssetQuery({
    assetCompanyID: parseInt(params.companyID),
    assetID: params.assetID,
  })

  const EditingEnabled =
    AssDetails.AS_DIS_CD === 'D' || AssDetails.AS_DIS_CD === 'P' ? true : false

  if (isLoading || AssDetailsLoading) return <p>Loading...</p>

  const editComponent =
    isCardValuesEdited || EditingEnabled ? (
      <Text light>Edited</Text>
    ) : (
      <Button
        onClick={() => setIsLocationDetailsFormActive(prevState => !prevState)}
        appearance="text"
      >
        {isLocationDetailsFormActive ? 'Close' : 'Edit'}
      </Button>
    )

  return (
    <>
      <SaveBar
        isVisible={isDirty}
        formId={EDIT_ASSET_LOCATION_DETAILS_FORM_ID}
        isSubmitting={isLoadingFormSubmit}
        isSaveDisabled={!isFormValid}
      />

      <Grid>
        <Grid.Item m={2}>
          <Button link=".." icon={<Icon name="ArrowLeft" />}>
            Back to all locations
          </Button>
        </Grid.Item>
      </Grid>
      <Spacer y="default" />

      <Card title="Location Details" addon={canEdit && editComponent}>
        <FormProvider {...formMethods}>
          {isLocationDetailsFormActive ? (
            <AssetLocationDetailsForm
              fields={fields}
              handleLocationFormSubmit={handleLocationFormSubmit}
              hasLocation={hasLocation}
            />
          ) : (
            <AssetLocationDetailsDisplay fields={fields} />
          )}
        </FormProvider>
      </Card>

      <Spacer y="default" />

      {!isLocationDetailsFormActive && hasLocation && (
        <Card title="Map">
          {hasLocation ? (
            <AssetLocationMap location={location} />
          ) : (
            <Note
              intent="warning"
              content={<>To load map, please enter a valid latitude and longitude.</>}
            />
          )}
        </Card>
      )}
    </>
  )
}

export default AssetLocationDetails
