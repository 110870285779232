import HttpClient from "../HttpClient";

export class FieldsApi extends HttpClient {
    private static classInstance?: FieldsApi;

    public constructor () {
        super (`/Fldparm`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new FieldsApi();
        }

        return this.classInstance;
    }

    public fetch = () => this.instance.get(`/FetchFields`);
    public list = (filter: string, pageNumber: number, itemsPerPage: number) => 
        this.instance.get(`/ListFields?filter=${filter}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`);
    public getField = (pmname: string) => this.instance.get(`/GetFields?PMNAME=${pmname}`);
    public getUserTableParams = () => this.instance.get(`/GetUserTableParams`);
    public setUserTableParams = (data: any) => this.instance.post(`/SetUserTableParams`, data);
    public import = (file: any) => this.instance.post(`/Importfldparm`, file);
    public edit = (data: any) => this.instance.put(`/Edit`, data);
}