import View from '@components/layout/View'
import AccountSettingPrefs from '@components/settings/account-setting/AcoountSettingPrefs'

//For Account Selection on Setting Page
const AccountSettingView = () => {
  return (
    <View title="Account Settings" showHeader={false}>
      <AccountSettingPrefs />
    </View>
  )
}

export default AccountSettingView
