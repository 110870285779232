import { FetchTasksResponse, initialTask, Task, TaskKey } from '@api/models'
import { toast, useModal } from '@components/common'
import {
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useEditTaskMutation,
  useFetchTasksQuery,
} from '@api'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTableParams } from '@hooks'
import { TableParamsHook } from 'hooks/useTableParams'

export interface TasksHook extends TableParamsHook {
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  searchKey: string
  selectedTask: Task

  tasks: FetchTasksResponse
  isLoadingTasks: boolean
  isErrorFetchingTasks: boolean
  isRefetchingTasks: boolean

  handleEditTask: (payload: Task) => Promise<void>
  handleCreateTask: (payload: Task) => Promise<void>
  isLoadingEditTask: boolean
  isLoadingCreateTask: boolean

  handleDeleteTask: (payload: Task) => Promise<void>
  isLoadingDeleteTask: boolean

  deleteTaskModalIsOpen: boolean
  triggerDeleteTaskModal
  closeDeleteTaskModal
  openDeleteTaskModal: (task: Task) => void
  openEditTaskModal: (task: Task) => void
  triggerEditTaskModal
  closeEditTaskModal
  editTaskModalIsOpen: boolean

  openCreateTaskModal: () => void
  createTaskModalIsOpen: boolean
  closeCreateTaskModal
}

export default function useTasks(): TasksHook {
  const [filterState, setFilterState] = useState({})
  const [selectedTask, setSelectedTask] = useState<Task>(undefined)

  const searchKey: TaskKey = 'OT_DESC'
  const initialItemsPerPage = 20

  // Use useTableParams hook for table filtering, pagination, etc.
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    isTotalCountEmpty,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  // Fetch Tasks
  const {
    data: tasks,
    isLoading: isLoadingTasks,
    isError: isErrorFetchingTasks,
    isFetching: isRefetchingTasks,
  } = useFetchTasksQuery(fetchRequestPayload)

  // MODAL STATES
  // Delete modal
  const {
    modalIsOpen: deleteTaskModalIsOpen,
    triggerModal: triggerDeleteTaskModal,
    closeModal: closeDeleteTaskModal,
  } = useModal()
  function openDeleteTaskModal(task: Task): void {
    setSelectedTask(task)
    triggerDeleteTaskModal()
  }

  // Edit modal
  const {
    modalIsOpen: editTaskModalIsOpen,
    triggerModal: triggerEditTaskModal,
    closeModal: closeEditTaskModal,
  } = useModal()

  function openEditTaskModal(task: Task): void {
    setSelectedTask(task)
    triggerEditTaskModal()
  }

  // Create modal
  const {
    modalIsOpen: createTaskModalIsOpen,
    triggerModal: triggerCreateTaskModal,
    closeModal: closeCreateTaskModal,
  } = useModal()
  function openCreateTaskModal(): void {
    setSelectedTask(initialTask)
    triggerCreateTaskModal()
  }

  // MUTATIONS
  // Delete
  const [deleteTask, { isLoading: isLoadingDeleteTask }] = useDeleteTaskMutation()
  async function handleDeleteTask(task: Task): Promise<void> {
    try {
      await deleteTask({ id: task.OT_ACT_ID }).unwrap()
      closeDeleteTaskModal()
      toast.success(`Successfully deleted "${task.OT_DESC}" from your tasks.`)
    } catch (error) {
      toast.error('There was an unexpected error.')
    }
  }

  // Edit
  const [editTask, { isLoading: isLoadingEditTask }] = useEditTaskMutation()
  async function handleEditTask(payload: Task): Promise<void> {
    try {
      await editTask(payload).unwrap()
      closeEditTaskModal()
      toast.success(`Task  "${payload.OT_DESC}" successfully updated.`)
    } catch (error) {
      toast.error('There was an unexpected error updating this task.')
    }
  }

  // Create
  const [createTask, { isLoading: isLoadingCreateTask }] = useCreateTaskMutation()
  async function handleCreateTask(payload: Task): Promise<void> {
    try {
      await createTask(payload).unwrap()

      closeCreateTaskModal()
      toast.success(`Successfully added ${payload.OT_DESC} to tasks.`)
    } catch (error) {
      toast.error('There was an unexpected error creating the task.')
    }
  }

  return {
    // tableParams return
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    isTotalCountEmpty,
    //
    filterState,
    setFilterState,
    searchKey,
    selectedTask,

    tasks,
    isLoadingTasks,
    isErrorFetchingTasks,
    isRefetchingTasks,

    handleDeleteTask,
    isLoadingDeleteTask,

    handleEditTask,
    handleCreateTask,
    isLoadingCreateTask,
    isLoadingEditTask,

    deleteTaskModalIsOpen,
    triggerDeleteTaskModal,
    closeDeleteTaskModal,
    openDeleteTaskModal,
    openEditTaskModal,
    triggerEditTaskModal,
    closeEditTaskModal,
    editTaskModalIsOpen,

    createTaskModalIsOpen,
    openCreateTaskModal,
    closeCreateTaskModal,
  }
}
