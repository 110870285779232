import React from 'react'
import { FormProps } from './Form.types'

const Form = React.forwardRef<HTMLFormElement, FormProps>(
    (
      { ...props },  ref
    ) => {
    return <form {...props} ref={ref}>{props.children}</form>
  }
)

export default Form
