import { Flexbox, Grid, Heading, Helper, Spacer } from '@components/common'
import {
  DepreciationRunForm,
  DepreciationUndoForm,
  DepreciationUpdateForm,
  DiscardRun,
  YearEndRunForm,
  YearEndUndoForm,
  YearEndUpdateForm,
} from '@components/Depreciation'
import View from '@components/layout/View'
import { useGetCompaniesQuery } from '@api'
import { useSelector } from 'react-redux'

interface CompanyOption {
  value: number
  label: string
  isPeriodBased: boolean
}

const DepreciationRootView = () => {
  const { data, isLoading } = useGetCompaniesQuery()
  const assetCompanyID = useSelector<any>(state => state.company) as number
  let selectedCompany: CompanyOption = null

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (data.length > 0 && assetCompanyID !== 0) {
    const comp = data.find(c => c.AssetCompanyID === assetCompanyID)
    selectedCompany = {
      value: comp.AssetCompanyID,
      label: comp.CM_DESC,
      isPeriodBased: comp.CM_YTD_BCD === 'P',
    }
  }

  if (!selectedCompany) {
    return <p>No companies found</p>
  }

  return (
    <View title="Depreciation">
      <Flexbox
        flexDirection="column"
        style={{
          backgroundColor: 'var(--base-color)',
          padding: '10px 20px 20px 20px',
          borderRadius: '10px',
        }}
      >
        <Flexbox>
          <Heading type="h2" style={{ color: 'var(--primary-color)' }}>
            Period
          </Heading>
          <Spacer x="mini" />
          <Helper
            content={
              'Run Depreciation for a selected period. From Period one to (total fiscal year periods minus one --- i.e period 11 = 12 - 1)'
            }
            position="right"
            color="primary"
          />
        </Flexbox>

        <Spacer y="mini" />
        <Grid>
          <Grid.Item m={4}>
            <DepreciationRunForm
              assetCompanyID={selectedCompany.value}
              isPeriodBased={selectedCompany.isPeriodBased}
            />
          </Grid.Item>
          <Grid.Item m={4}>
            <DepreciationUpdateForm
              assetCompanyID={selectedCompany.value}
              isPeriodBased={selectedCompany.isPeriodBased}
            />
          </Grid.Item>
          <Grid.Item m={4}>
            <DepreciationUndoForm
              assetCompanyID={selectedCompany.value}
              isPeriodBased={selectedCompany.isPeriodBased}
            />
          </Grid.Item>
        </Grid>
      </Flexbox>

      <Spacer y="default" />

      <Flexbox
        flexDirection="column"
        style={{
          backgroundColor: 'var(--base-color)',
          padding: '10px 20px 20px 20px',
          borderRadius: '10px',
        }}
      >
        <Flexbox>
          <Heading type="h2" style={{ color: 'var(--primary-color)' }}>
            Year End
          </Heading>
          <Spacer x="mini" />
          <Helper
            content={'Run Depreciation for Year End Only'}
            position="right"
            color="primary"
          />
        </Flexbox>
        <Spacer y="mini" />

        <Grid>
          <Grid.Item m={4}>
            <YearEndRunForm assetCompanyID={selectedCompany.value} />
          </Grid.Item>
          <Grid.Item m={4}>
            <YearEndUpdateForm assetCompanyID={selectedCompany.value} />
          </Grid.Item>
          <Grid.Item m={4}>
            <YearEndUndoForm assetCompanyID={selectedCompany.value} />
          </Grid.Item>
        </Grid>
      </Flexbox>

      <Spacer y="default" />

      <Flexbox
        flexDirection="column"
        style={{
          backgroundColor: 'var(--base-color)',
          padding: '10px 20px 20px 20px',
          borderRadius: '10px',
        }}
      >
        <Flexbox>
          <Heading type="h2" style={{ color: 'var(--primary-color)' }}>
            Discard
          </Heading>
          <Spacer x="mini" />
          <Helper
            content={
              'You may discard or remove a Depreciation RUN via this process. But once you UPDATE you Must use the UNDO process.'
            }
            position="right"
            color="primary"
          />
        </Flexbox>
        <Spacer y="mini" />
        <Grid>
          <Grid.Item m={4}>
            <DiscardRun assetCompanyID={selectedCompany.value} />
          </Grid.Item>
        </Grid>
      </Flexbox>
    </View>
  )
}

export default DepreciationRootView
