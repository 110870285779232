import { DepreciationClass, FieldLabels } from '@api/models'
import DataDisplayList from '@components/DataDisplayList'

interface Props {
  fields: FieldLabels
  deprec: DepreciationClass
}

const DeprecClassMainDetailsDisplay = ({ fields, deprec }: Props) => {
  const items = [
    {
      label: fields.DP_OBJ_ID,
      value: deprec.DP_OBJ_ID,
    },
    {
      label: fields.DP_DESC,
      value: deprec.DP_DESC,
    },
  ]

  return <DataDisplayList items={items} columns={2} />
}
export default DeprecClassMainDetailsDisplay
