import React, { useCallback } from 'react'
import { TabButtonProps } from './Tabs.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

const TabButton: React.FC<TabButtonProps> = ({
  title,
  setSelectedTab,
  index,
  className,
  selected,
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  const [css] = useStyletron()

  return (
    <button
      onClick={onClick}
      className={css(styles.button({ selected }))}
      type="button"
      role="tab"
      aria-selected={selected}
      aria-controls={`${title.toLowerCase().replace(/\s+/g, '-')}-tab-id`}
      tabIndex={0}
    >
      {title}
    </button>
  )
}

export default TabButton
