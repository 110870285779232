import React from 'react'
import { ButtonGroupProps } from './ButtonGroup.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import PassClassDown from '@utils/PassClassDown'
import Flexbox from '../Flexbox'

const ButtonGroup: React.FC<ButtonGroupProps> = ({ hidden, ...props }) => {
  const [css] = useStyletron()

  if (hidden) return <></>

  return (
    <Flexbox>
      <PassClassDown className={css(styles.base)}>{props.children}</PassClassDown>
    </Flexbox>
  )
}

export default ButtonGroup
