import { FieldParm } from '@api/models'
import {
  Card,
  Flexbox,
  Grid,
  Modal,
  Search,
  Select,
  Spacer,
  Table,
} from '@components/common'
import Pagination from '@components/Pagination'
import { FIELD_PARM_ID } from '@constants'
import { useEffect, useMemo } from 'react'
import { Column, SortingRule, useFlexLayout, useSortBy, useTable } from 'react-table'
import FieldPrefsForm from './FieldPrefsForm'
import { FieldPrefsHook } from './useFieldPrefs'
import usePermission from '@components/Auth/Permissions/usePermission'
import FieldPrefsLoading from './FieldPrefsLoading'

const FieldPrefsTable = ({
  pageNumber,
  itemsPerPage,
  handleSearch,
  searchValue,
  changePage,
  sortByInitialState,
  updateSortSearchParam,
  selectedFieldParm,
  selectTablePrefixValue,
  searchKey,
  fieldParms,
  isLoadingFieldParms,
  isErrorFetchingFieldParms,
  isRefetchingFieldParms,

  handleEditFieldParm,
  isLoadingEditFieldParm,

  handleChangeTablePrefix,
  openEditFieldParmModal,
  closeEditFieldParmModal,
  editFieldParmModalIsOpen,
  selectTablePrefixes,
}: FieldPrefsHook) => {
  // Declare columns

  const columns = useMemo<Column<FieldParm>[]>(
    () => [
      {
        Header: 'Field Label',
        accessor: 'PM_PMT',
      },
    ],
    []
  )

  // Declare react table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: fieldParms ? fieldParms.list : [],
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        sortBy: sortByInitialState() as SortingRule<FieldParm>[],
      },
    },
    useSortBy,
    useFlexLayout
  )

  const { canEditSettings: canEdit } = usePermission()

  // Update sort param
  useEffect(() => {
    updateSortSearchParam(sortBy, setSortBy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  // Returns
  const isTableLoading = isLoadingFieldParms
  const isError = isErrorFetchingFieldParms
  if (isTableLoading) {
    return <FieldPrefsLoading />
  }

  if (isError) {
    return <Table.NoResults heading="There was an error." />
  }

  return (
    <>
      <Modal
        isOpen={editFieldParmModalIsOpen}
        close={closeEditFieldParmModal}
        title="Edit label"
        width={540}
        actionLabel="Save label"
        form={FIELD_PARM_ID}
        isActionLoading={isLoadingEditFieldParm}
      >
        <FieldPrefsForm initialValues={selectedFieldParm} onSubmit={handleEditFieldParm} />
      </Modal>

      <Grid>
        <Grid.Item m={8}>
          <Card spacing="none">
            <Table.Actions>
              <Flexbox>
                <Select
                  value={selectTablePrefixValue}
                  options={selectTablePrefixes}
                  onChange={handleChangeTablePrefix}
                  width="260px"
                />
                <Spacer x="mini" />
                <Search
                  onChange={handleSearch}
                  placeholder="Search by field label..."
                  name={searchKey}
                  width={'640px'}
                  value={searchValue}
                />
              </Flexbox>
            </Table.Actions>
            {fieldParms.list.length === 0 ? (
              <Table.NoResults text="" />
            ) : (
              <>
                <Table
                  {...getTableProps()}
                  aria-label="Field Parms Table"
                  label="Field Parms Table"
                  aria-colcount={columns.length}
                  aria-rowcount={fieldParms.list.length}
                  height="calc(100vh - 280px)"
                >
                  <Table.Head sticky>
                    {isRefetchingFieldParms ? (
                      <Table.LoadingRows />
                    ) : (
                      headerGroups.map((headerGroup, i) => (
                        <Table.HeadRow {...headerGroup.getHeaderGroupProps()} key={i}>
                          {headerGroup.headers.map((column, i) => (
                            <Table.Header
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              column={column}
                              key={i}
                              tabIndex={0}
                            >
                              {column.render('Header')}
                            </Table.Header>
                          ))}
                        </Table.HeadRow>
                      ))
                    )}
                  </Table.Head>

                  <Table.Body {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <Table.Row
                          {...row.getRowProps()}
                          onClick={() => {
                            if (!canEdit) return
                            openEditFieldParmModal(row.original)
                          }}
                          tabIndex={0}
                          key={i}
                        >
                          {row.cells.map(cell => (
                            <Table.Cell {...cell.getCellProps()} column={cell.column}>
                              {cell.render('Cell')}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
                <Pagination
                  changePage={changePage}
                  totalItems={fieldParms.count}
                  maxItemsPerPage={itemsPerPage}
                  currentPageNumber={pageNumber}
                  label={`Showing ${fieldParms.list.length} of ${fieldParms.count} record${
                    fieldParms.count > 1 ? 's' : ''
                  }`}
                />
              </>
            )}
          </Card>
        </Grid.Item>
      </Grid>
    </>
  )
}

export default FieldPrefsTable
