import React, { useState } from 'react'
import { NoteProps } from './Note.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

import { AnimatePresence, motion } from 'framer-motion'

import { Button, Icon } from '@common'

const Note: React.FC<NoteProps> = ({
  label = 'Note',
  iconLabel = false,
  content,
  intent = 'default',
  appearance = 'default',
  removable = false,
}) => {
  const [removed, setRemoved] = useState(false)
  const removeNote = () => {
    setRemoved(true)
  }
  const [css] = useStyletron()
  let noteStyles = css({
    ...styles.base,
    ...styles.getIntent({ intent, appearance }),
  })
  let iconStyles = css(styles.icon)
  let icon

  switch (intent) {
    case 'default':
      icon = <Icon name="Info" className={iconStyles} />
      break
    case 'success':
      icon = <Icon name="CheckCircle" className={iconStyles} />
      break
    case 'warning':
      icon = <Icon name="AlertTriangle" className={iconStyles} />
      break
    case 'danger':
      icon = <Icon name="AlertCircle" className={iconStyles} />
  }
  return (
    <AnimatePresence>
      {!removed && (
        <motion.div
          className={noteStyles}
          initial={{ opacity: 1, scale: 1 }}
          exit={{
            opacity: 0,
            scale: 0.96,
            transition: { duration: 0.1 },
          }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: { type: 'spring', stiffness: 700, damping: 33 },
          }}
          role="alert"
        >
          {icon}
          <div className={css({ width: '-webkit-fill-available' })}>{content}</div>

          {removable && (
            <Button
              icon={<Icon name="X" />}
              onClick={removeNote}
              appearance="clear"
              shape="circle"
              size="compact"
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
export default Note
