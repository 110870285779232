import React from 'react'

import { Heading } from '@common'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import { ViewHeaderProps } from './View.types'

const ViewHeader: React.FC<ViewHeaderProps> = ({
  pageHeader,
  localNav,
  actions,
}) => {
  const [css] = useStyletron()
  return (
    <div className={css(styles.viewHeader)}>
      <div className={css(styles.viewTitle)}>
        <Heading type="h1">{pageHeader}</Heading>
      </div>
      {localNav && localNav}
    </div>
  )
}
export default ViewHeader
