import HttpClient from "../HttpClient";

export class PicklistApi extends HttpClient {
    private static classInstance?: PicklistApi;

    public constructor () {
        super (`/Picklist`);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new PicklistApi();
        }

        return this.classInstance;
    }

    public fetch = () => this.instance.get(`/FetchPicklists`);
    public get = (pkName: string, pkVal: string) => this.instance.get(`/GetPicklist?pkName=${pkName}&pkVal=${pkVal}`);
    public import = (file: any) => this.instance.post(`/ImportPicklist`, file);
    public create = (data: any) => this.instance.post(`/Create`, data);
}