import { Card, Checkbox, Fieldset, Form, Grid, Spacer, toast } from '@components/common'
import { ReportsApi } from '@api'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { ADMIN_MAINTENANCE_REPORT_PATH, DEPREC_PROFILES, ERROR_REQUEST } from '@constants'
import ReportFilters from '../ReportFilters'
import { adminMaintenanceReportFilterFields } from '../ReportFilters/report-filter-fields'
import { AdminMaintenanceReportParams } from '@api/models'
import { DepProfileSelect, ActionButtons } from '../General'
import { useReport } from '@hooks'
import { useEffect } from 'react'
import { reportResponseHandler } from '@utils/reports'
import MemorizeReports from '../General/MemorizeReports/MemorizeReport'
import { useMemorizeReport } from '../General/MemorizeReports/useMemorizeReport'
import { useLocation } from 'react-router-dom'
import { useFormMethods } from '../General/useFormMethods'

const defaultValues: AdminMaintenanceReportParams = {
  assetCompanyID: 0,
  filter: [],
  profile: DEPREC_PROFILES.CORPORATE,
  includeCaps: false,
  includeDisposals: false,
  includeSerialID: false,
  includePrimaryID: false,
  includeSecondaryID: false,
  includeCategoryID: false,
  fileFormat: 'pdf',
}

async function onSubmitHandler(values: AdminMaintenanceReportParams) {
  await ReportsApi.getInstance()
    .getAdminMaintenanceReport(values)
    .then(response => reportResponseHandler(response, values.fileFormat))
    .catch(() => toast.error(ERROR_REQUEST))
}

const AdminMaintenanceForm = () => {
  const location = useLocation()
  const locationState: any = location.state

  const {
    methods,
    isRetrievingTemplate,
    isEditTemplate,
    selectedTemplateDetails,
  } = useFormMethods(locationState, defaultValues)

  const {
    formState: { isSubmitting },
    control,
    setValue,
  } = methods

  const {
    depProfilesMap,
    isLoading,
    isSuccess,
    isError,
    isNoCompanies,
    assetCompanyID,
  } = useReport()

  const {
    handleMemorizeTemplateSubmit,
    isMemorizeTemplateModalOpen,
    closeMemorizeTemplateModal,
    triggerMemorizeTemplateModal,
    handleEditTemplateSubmit,
  } = useMemorizeReport({ methods, currentReportPath: ADMIN_MAINTENANCE_REPORT_PATH })

  useEffect(() => {
    if (isSuccess) {
      setValue('assetCompanyID', assetCompanyID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, assetCompanyID])

  function getCheckBoxProps(field: any) {
    return {
      checked: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (isNoCompanies) {
    return <div>No companies found</div>
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Card title={'Report Parameters'}>
            <Grid>
              <Grid.Item>
                <DepProfileSelect depProfilesMap={depProfilesMap} />
              </Grid.Item>

              <Grid.Item m={6}>
                <Fieldset legend="Additional Columns to Include">
                  <Grid>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeSerialID"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Serial ID"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includePrimaryID"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Primary ID"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeSecondaryID"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Secondary ID"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeCategoryID"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Category ID"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item m={6}>
                <Fieldset legend="Options">
                  <Grid>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeCaps"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Capitalizations"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Controller
                        control={control}
                        name="includeDisposals"
                        render={({ field }) => (
                          <Checkbox
                            label="Include Disposals"
                            {...getCheckBoxProps(field)}
                          />
                        )}
                      />
                    </Grid.Item>
                  </Grid>
                </Fieldset>
              </Grid.Item>

              <Grid.Item>
                <ReportFilters
                  filtersTemplateData={
                    isRetrievingTemplate
                      ? locationState.templateDetails.templateData.filter
                      : []
                  }
                  filterFields={adminMaintenanceReportFilterFields}
                />
              </Grid.Item>
            </Grid>
          </Card>
          <Spacer y="default" />
          <ActionButtons
            isSubmitting={isSubmitting}
            allowExcel={false}
            triggerMemorizeTemplateModal={triggerMemorizeTemplateModal}
          />
        </Form>
      </FormProvider>

      <MemorizeReports
        isMemorizeTemplateModalOpen={isMemorizeTemplateModalOpen}
        closeMemorizeTemplateModal={closeMemorizeTemplateModal}
        handleMemorizeTemplateSubmit={handleMemorizeTemplateSubmit}
        handleEditTemplateSubmit={handleEditTemplateSubmit}
        isEditTemplate={isEditTemplate}
        selectedTemplateDetails={selectedTemplateDetails}
      />
    </>
  )
}

export default AdminMaintenanceForm
