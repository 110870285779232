import { useGetCompaniesQuery } from '@api'
import { useEffect, useState } from 'react'

/**
 * Use this hook to make API requests that need to be cached before loading the main app component.
 * These requests can't have a user input.
 */
const useInitialRequests = () => {
  const [isLoadingDelay, setIsLoadingDelay] = useState(true)
  const { isLoading: isLoadingCompanies } = useGetCompaniesQuery()

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingDelay(false)
    }, 1500)
  }, [])

  const isLoading = isLoadingDelay || isLoadingCompanies

  return { isLoading }
}

export default useInitialRequests
