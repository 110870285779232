import AssetChargesForm from './AssetChargesForm'
import { FormProvider } from 'react-hook-form'
import { Button, Card, Flexbox, Icon, Spacer } from '@components/common'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import Template from '@components/layout/Template'
import { AssetChargeType } from '@api/models'
import useAssetCharge from '@hooks/useAssetCharge'
import usePermission from '@components/Auth/Permissions/usePermission'

interface AssetChargeViewProps {
  chargeTypeCode: AssetChargeType
  assetCompanyID: number
  assetID: string
  isCreate: boolean
  systemNumber: number
}

const AssetChargeWrapper = ({
  chargeTypeCode,
  assetCompanyID,
  assetID,
  isCreate,
  systemNumber,
}: AssetChargeViewProps) => {
  const type = chargeTypeCode

  const { handleChargeFormSubmit, fields, formMethods, isLoading } = useAssetCharge({
    assetCompanyID,
    assetID,
    type,
    systemNumber,
    isCreate,
  })

  const { data: isAssetDesc, isLoading: isAssetDescLoading } = useGetAssetQuery({
    assetCompanyID,
    assetID,
  })

  const { canEditAssets: canEdit } = usePermission()

  const EditingEnabled =
    isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false

  let chargeTypeName = ''
  switch (type) {
    case 'S':
      chargeTypeName = 'Service'
      break
    case 'O':
      chargeTypeName = 'Task/Activity'
      break
    case 'P':
      chargeTypeName = 'Partial Disposal'
      break
  }
  if (isLoading || isAssetDescLoading) {
    return <p>Loading...</p>
  }

  return (
    <>
      <Template maxWidth="compact">
        <Flexbox alignItems="end">
          <Button link=".." icon={<Icon name="ArrowLeft" />}>
            Back to main page
          </Button>
        </Flexbox>
        <Spacer y="default" />

        <Card
          overflow="hidden"
          title={`${
            isCreate ? 'Add' : 'Edit'
          } ${chargeTypeName.toLowerCase()} record - Asset ${assetID} : ${
            isAssetDesc.AS_DESC
          }`}
        >
          <FormProvider {...formMethods}>
            <AssetChargesForm
              fields={fields}
              handleChargeFormSubmit={handleChargeFormSubmit}
              chargeTypeCode={chargeTypeCode}
              chargeType={chargeTypeName}
              isCreate={isCreate}
              EditingEnabled={EditingEnabled}
              canEdit={canEdit}
            />
          </FormProvider>
        </Card>
      </Template>
    </>
  )
}

export default AssetChargeWrapper
