import { Company } from '@api/models'
import { Button, Input, Modal, Spacer, Text } from '@components/common'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { InputChangeEvent } from '@types'
import { useGetAssetsQuery } from '@api/index'

interface Props {
  handleDelete: () => void
  isLoadingDelete: boolean
  isOpen: boolean
  setIsConfirmDeleteModalOpen: Dispatch<SetStateAction<boolean>>

  company: Company
}

const ConfirmDeleteCompanyModal = ({
  handleDelete,
  isLoadingDelete,
  isOpen,
  setIsConfirmDeleteModalOpen,
  company,
}: Props) => {
  const [inputState, setInputState] = useState<string>('')

  const assets = useGetAssetsQuery({
    assetCompanyID: company?.AssetCompanyID,
    pageNumber: 1,
    itemsPerPage: 1,
    filter: {},
  })

  const companyHasAssets = assets.data?.count !== 0

  const DELETE_MATCH_CHARS = 'delete'

  function handleInputChange(e: InputChangeEvent) {
    setInputState(e.target.value)
  }

  const deleteButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (inputState === DELETE_MATCH_CHARS) {
      deleteButtonRef.current!.focus()
    }
  }, [inputState])

  useEffect(() => {
    setInputState('')
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      close={() => setIsConfirmDeleteModalOpen(false)}
      title={`Delete company ${company?.AssetCompanyID}: ${company.CM_DESC}`}
    >
      <>
        {companyHasAssets ? (
          <>
            <Text>
              <b> Notice:</b> The deletion of this company is currently restricted. Company
              records with assets cannot be removed. Please ensure all assets are
              deleted before attempting deletion.
            </Text>
            <Spacer y="default" />
          </>
        ) : (
          <>
            <Text p>
              Are you sure you want to delete company{' '}
              <b>
                {company?.AssetCompanyID}: {company.CM_DESC}
              </b>
              ? <Text intent="danger">This cannot be undone</Text>.
            </Text>

            <Spacer y="default" />

            <Input
              onChange={handleInputChange}
              value={inputState}
              label={`Enter '${DELETE_MATCH_CHARS}' to confirm`}
              autoFocus={true}
            />
            <Spacer y="default" />

            <div>
              <Button
                flex
                onClick={handleDelete}
                appearance="primary"
                intent="danger"
                disabled={inputState !== DELETE_MATCH_CHARS}
                ref={deleteButtonRef}
                loading={isLoadingDelete}
              >
                Delete company {company?.AssetCompanyID}
              </Button>
            </div>
          </>
        )}
      </>
    </Modal>
  )
}
export default ConfirmDeleteCompanyModal
