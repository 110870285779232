import CustomizeFormatsForm from './CustomizeFormatForm'
import CustomizeFormatLoading from './CustomizeFormatLoading'
import useSettingDate from './useCustomFormatPrefs'

const CustomizeFormatPrefs = () => {
  const { onSubmit, DateFormats, isGettingSettings } = useSettingDate()

  if (isGettingSettings) {
    return <CustomizeFormatLoading />
  }
  return (
    <CustomizeFormatsForm
      onSubmit={onSubmit}
      DateFormats={DateFormats}
      isGettingSettings={isGettingSettings}
    />
  )
}

export default CustomizeFormatPrefs
