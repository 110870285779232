import { Button, Form, Grid, Modal, Spacer, toast } from '@components/common'
import { ImportApi } from '@api'
import { useState } from 'react'
import { sleep } from '@utils/basic'

const ImportAll = () => {
  const [state, setState] = useState({
    files: [],
    isLoading: false,
  })

  function handleChange(e) {
    setState({
      ...state,
      files: e.target.files,
    })
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault()
      setState({
        ...state,
        isLoading: true,
      })

      const formData = new FormData()
      for (let i = 0; i < state.files.length; i++) {
        // note: 'files' is not an arbritrary name, it is the name of the api param
        formData.append('files', state.files[i])
      }

      await sleep(2000)
      await ImportApi.getInstance().importAll(formData)
    } catch (_) {
      toast.error('Error importing data')
    } finally {
      setState({
        ...state,
        isLoading: false,
      })
      toast.success('Data imported successfully')
    }
  }

  return (
    <>
      <Modal title="Uploading files..." isOpen={state.isLoading} close={() => {}} />
      <Form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Item>
            <p>
              <b>Only use Import All for testing purposes.</b>.
            </p>
            <h3>Import All</h3>
          </Grid.Item>
          <Grid.Item>
            <input type="file" multiple onChange={handleChange} />
            <Spacer y="default" />
            <Button type="submit" appearance="primary">
              Upload
            </Button>
          </Grid.Item>
        </Grid>
      </Form>
    </>
  )
}

export default ImportAll
