import * as Yup from 'yup'
import { REQUIRED_FIELD } from '@constants'
import { currencyValidation } from '@api/models/validation'

export interface AssetDisposalDTO {
  AssetDisposalStatus: AssetDisposalStatus
  AssetDisposalParams: AssetDisposalParams
}

export interface GetDisposalDataPayload {
  assetCompanyID: number
  assetID: string
}

export interface ReinstateAssetParams {
  assetCompanyID: number
  assetID: string
}

export interface DisposalProjectionsParams {
  assetCompanyID: number
  assetID: string
  period?: number
  year?: number
  realizedAmount: number
  disposalDate?: string
  disposalSoldTo?: string
}

export interface AssetDisposalStatus {
  Description: string
  DateInService: string
  AcquisitionCost: number
  LifeTime: number
  SalvageAmount: number
}

export interface AssetDisposalParams {
  AssetCompanyID: number
  AssetID: string
  Period: number
  Year: number
  RealizedAmount: number
  DisposalDate: string
  DisposalSoldTo: string
  IsPeriodBased: boolean
}

export interface AssetDisposalProjections {
  LTDD: number
  NBV: number
  PGL: number
  IsDepRunPending: boolean
}

export const assetDisposalSchema = (isPeriodBased: boolean) =>
  Yup.object({
    Year: Yup.number().when('isPeriodBased', {
      is: !isPeriodBased,
      then: Yup.number()
        .min(1000)
        .max(3000)
        .required(REQUIRED_FIELD)
        .typeError(REQUIRED_FIELD),
    }),
    Period: Yup.number().when('isPeriodBased', {
      is: !isPeriodBased,
      then: Yup.string().min(1).max(99).required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
    }),
    RealizedAmount: Yup.number().test(currencyValidation).required(REQUIRED_FIELD),
    DisposalSoldTo: Yup.string(),
    DisposalDate: Yup.string().when('isPeriodBased', {
      is: isPeriodBased,
      then: Yup.string().required(REQUIRED_FIELD),
    }),
  })

export type AssetDisposalFields = Yup.InferType<ReturnType<typeof assetDisposalSchema>>
export type AssetDisposalFieldKeys = keyof AssetDisposalFields
