import { AssetRegisterReportParams, AssetRegisterSectionOption } from '@api/models'
import { Fieldset, Checkbox, Grid, Heading } from '@components/common'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelectedFieldsAndComments } from './useSelectedFieldsAndComments'
import React from 'react'

interface AssetRegisterOptionsProps {
  depProfilesMap: { [key: string]: ReactSelectOptions }
  assetCompanyID: number
  sectionsInitialValues: AssetRegisterSectionOption
  sectionCommentsInitialValues: AssetRegisterSectionOption
}

const AssetRegisterOptions = ({
  depProfilesMap,
  assetCompanyID,
  sectionsInitialValues,
  sectionCommentsInitialValues,
}: AssetRegisterOptionsProps) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<AssetRegisterReportParams>()

  const {
    selectedFields,
    selectedComments,
    handleFieldCheckboxChange,
    handleCommentsCheckboxChange,
  } = useSelectedFieldsAndComments({
    sectionsInitialValues,
    sectionCommentsInitialValues,
  })

  function getSectionOptions(
    depProfilesMap: { [key: string]: ReactSelectOptions },
    assetCompanyID: number
  ) {
    const maintainedClasses = depProfilesMap[assetCompanyID]
    const isTaxClassMaintained = maintainedClasses.some(({ value }) => value === 'T')
    const isOtherTaxMaintained = maintainedClasses.some(({ value }) => value === 'S')
    const isStateTaxMaintained = maintainedClasses.some(({ value }) => value === 'O')

    let sectionOptions: { label: string; path: keyof AssetRegisterSectionOption }[] = [
      {
        label: 'Identification',
        path: 'identification',
      },
      {
        label: 'Acquisition',
        path: 'acquisition',
      },
      {
        label: 'Disposal',
        path: 'disposal',
      },
      {
        label: 'Transfers',
        path: 'transfers',
      },
    ]

    sectionOptions.push({
      label: 'Corporate Profile',
      path: 'corporateProfile',
    })

    if (isTaxClassMaintained) {
      sectionOptions.push({
        label: 'Tax Profile',
        path: 'taxProfile',
      })
    }

    if (isOtherTaxMaintained) {
      sectionOptions.push({
        label: 'Other Profile',
        path: 'otherProfile',
      })
    }

    if (isStateTaxMaintained) {
      sectionOptions.push({
        label: 'State Profile',
        path: 'stateProfile',
      })
    }

    sectionOptions.push({
      label: 'Corporate History',
      path: 'corporateHistory',
    })

    if (isTaxClassMaintained) {
      sectionOptions.push({
        label: 'Tax History',
        path: 'taxHistory',
      })
    }

    if (isOtherTaxMaintained) {
      sectionOptions.push({
        label: 'Other History',
        path: 'otherHistory',
      })
    }

    if (isStateTaxMaintained) {
      sectionOptions.push({
        label: 'State History',
        path: 'stateHistory',
      })
    }

    sectionOptions.push(
      {
        label: 'Service',
        path: 'service',
      },
      {
        label: 'Location',
        path: 'location',
      },
      {
        label: 'Task/Activity',
        path: 'taskActivity',
      },
      {
        label: 'Folder Assignments',
        path: 'folders',
      }
    )

    return sectionOptions
  }

  const sectionOptions = getSectionOptions(depProfilesMap, assetCompanyID)

  function getCheckBoxProps(field: any) {
    return {
      checked: field.value,
      onChange: field.onChange,
      ref: field.ref,
      disabled: isSubmitting,
    }
  }

  return (
    <Fieldset legend="Sections to Include">
      <Grid>
        <Grid.Item s={6}>
          <Heading type="h4">Field Name</Heading>
        </Grid.Item>
        <Grid.Item s={6}></Grid.Item>
        {sectionOptions.map(({ label, path }) => (
          <React.Fragment key={path}>
            <Grid.Item s={6}>
              <Controller
                name={`sections.${path}`}
                control={control}
                render={({ field }) => {
                  return (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      onChange={e => {
                        handleFieldCheckboxChange(path)
                        field.onChange(e)
                      }}
                      label={label}
                    />
                  )
                }}
              />
            </Grid.Item>
            <Grid.Item s={6}>
              {!path.includes('History') && selectedFields.includes(path) ? (
                <Controller
                  name={`sectionComments.${path}`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      onChange={e => {
                        handleCommentsCheckboxChange(path)
                        field.onChange(e)
                      }}
                      label="Comments"
                      checked={selectedComments.includes(path)}
                    />
                  )}
                />
              ) : null}
            </Grid.Item>
          </React.Fragment>
        ))}
      </Grid>
    </Fieldset>
  )
}

export default AssetRegisterOptions
