import { Flexbox, Heading, Spacer, Spinner } from '@components/common'

const OrganizationNameLoading: React.FC = () => {
  return (
    <Flexbox flexDirection="row">
      <Spinner />
      <Spacer x="default" />
      <Heading type="h1" style={{ paddingLeft: 'var(--scale500)' }}>
        Organization Name...
      </Heading>
    </Flexbox>
  )
}

export default OrganizationNameLoading
