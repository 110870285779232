import Maintenances from '@components/Maintenances'
import View from '@components/layout/View'

const MaintenanceContractsView = () => {
  return (
    <View title="Maintenance Contracts" showHeader={false}>
      <Maintenances />
    </View>
  )
}

export default MaintenanceContractsView
