import React from 'react'
import { useStyletron } from 'styletron-react'
import { SpinnerProps } from './Spinner.types'

import * as styles from './Styles'

export const Spinner: React.FC<SpinnerProps> = ({
  theme = 'light',
  size = 'regular',
}) => {
  const [css] = useStyletron()

  var animationStyles = styles.animationStyles
  var spinnerStyles = {
    ...styles.getSpinnerStyles({ theme, size }),
  }
  const spinnerClassName = `${css(spinnerStyles)} ${css(animationStyles)}`

  return (
    <div className={css(styles.wrapperStyles)}>
      <span className={spinnerClassName} />
    </div>
  )
}
export default Spinner
