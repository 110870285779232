import { StyleObject } from '@styles-api/css.types'

export const nav: StyleObject = {
  marginBottom: 'var(--scale500)',
}
export const list: StyleObject = {
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
}
export const item: StyleObject = {
  color: 'var(--text-light)',
  display: 'flex',
  alignItems: 'center',
}
export const icon: StyleObject = {
  marginRight: 'var(--scale200)',
  marginLeft: 'var(--scale200)',
  height: 'var(--scale600)',
  width: 'var(--scale600)',
}
