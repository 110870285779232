import { Asset, FieldLabels } from '@api/models'
import DataDisplayList from '@components/DataDisplayList'
import { formatDisplayDate } from '@utils/basic'
import { useGetSettingsQuery } from '@api'
import { useFormContext } from 'react-hook-form'

interface Props {
  fields: FieldLabels
}

const checkDateValueAndFormatIt = (datevalue: string, DateFormat: string) => {
  if (datevalue && datevalue.substring(0, 10) === '0001-01-01') {
    return ''
  }
  return formatDisplayDate(datevalue, DateFormat)
};

const AssetMainDetailsDisplay = ({ fields }: Props) => {
  const { watch } = useFormContext<Asset>()
  const { data: DateFormat } = useGetSettingsQuery('DateFormat')

  const items = [
    {
      label: fields.AssetID,
      value: watch('AssetID'),
    },
    {
      label: fields.AS_DESC,
      value: watch('AS_DESC'),
    },
    {
      label: fields.AS_DESC_E,
      value: watch('AS_DESC_E'),
    },
    {
      label: fields.AS_SERIAL,
      value: watch('AS_SERIAL'),
    },
    {
      label: fields.AS_IN_POL,
      value: watch('AS_IN_POL'),
    },
    {
      label: fields.AS_MAIN_CN,
      value: watch('AS_MAIN_CN'),
    },
    {
      label: fields.AS_VEN_ID,
      value: watch('AS_VEN_ID'),
    },
    {
      label: fields.AS_VEN_NM,
      value: watch('AS_VEN_NM'),
    },
    {
      label: fields.AS_REC_USR,
      value: watch('AS_REC_USR'),
    },

    {
      label: fields.AS_PRI_UCD,
      value: watch('AS_PRI_UCD'),
    },
    {
      label: fields.AS_SEC_UCD,
      value: watch('AS_SEC_UCD'),
    },
    {
      label: fields.AS_UC1_UCD,
      value: watch('AS_UC1_UCD'),
    },
    {
      label: fields.AS_UC2_UCD,
      value: watch('AS_UC2_UCD'),
    },
    {
      label: fields.AS_UC3_UCD,
      value: watch('AS_UC3_UCD'),
    },
    {
      label: fields.AS_REC_PON,
      value: watch('AS_REC_PON'),
    },
    {
      label: fields.AS_REP_DAT,
      value: formatDisplayDate(watch('AS_REP_DAT'), DateFormat),
    },
    {
      label: fields.AS_NSER_DT,
      value: checkDateValueAndFormatIt(watch('AS_NSER_DT'), DateFormat),
    },
    {
      label: fields.AS_WRO_CD,
      value: watch('AS_WRO_CD'),   
    },
  ]

  return <DataDisplayList items={items} columns={2} />
}
export default AssetMainDetailsDisplay
