import { StyleObject } from './css.types'

export const FOCUS: StyleObject = {
  outlineOffset: '-1px',
  ':focus': {
    boxShadow: 'var(--focus-shadow), inset 0 0 0 1px var(--primary-color)',
  },
}

export const SR_ONLY: StyleObject = {
  clip: 'rect(1px, 1px, 1px, 1px)',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
}

export const SKELETON_SHINE: StyleObject = {
  backgroundImage:
    'linear-gradient(135deg, var(--shade100), var(--shade100), var(--shade100), var(--shade100), var(--shade100), var(--shade100), rgb(246, 246, 246), var(--shade100), var(--shade100), var(--shade100), var(--shade100), var(--shade100), var(--shade100))',
  backgroundSize: '400% 100%',
  animationDuration: '1.5s',
  animationTimingFunction: 'ease-out',
  animationIterationCount: 'infinite',

  animationName: {
    from: {
      backgroundPosition: '100% 50%',
    },
    to: {
      backgroundPosition: '0% 50%',
    },
  },
}

export const OVERLAY: StyleObject = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 'var(--z3)',
  height: '100vh',
  width: '100vw',
  background: 'var(--overlay)',
}

export const overflowShadow = ({
  canScrollRight,
  canScrollLeft,
  canScrollDown,
  canScrollUp,
}) => {
  let shadowColor = 'rgba(0, 0, 0, 0.2)'

  let bottomShadow = `inset 0 -2rem 2rem -2rem ${shadowColor}`
  let topShadow = `inset 0 2rem 2rem -2rem ${shadowColor}`

  const shadows = []

  if (canScrollDown) shadows.push(bottomShadow)
  if (canScrollUp) shadows.push(topShadow)

  let shadowsString = shadows.join()

  return {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    zIndex: 2,
    boxShadow: shadowsString,
    transition: 'all 0.1s',
  }
}
