import { Button, Flexbox, Heading, Modal, Spacer } from '@components/common'
import { INSURANCE_FORM_ID } from '@constants'

import InsuranceForm from './InsuranceForm'
import InsurancesTable from './InsurancesTable'
import useInsurances from './useInsurances'
import usePermission from '@components/Auth/Permissions/usePermission'
import { download } from '@utils/basic'
import ImportData from '@components/ImportData'

const Insurances = () => {
  const {
    createInsuranceModalIsOpen,
    openCreateInsuranceModal,
    handleCreateInsurance,
    closeCreateInsuranceModal,
    selectedInsurance,
    isLoadingCreateInsurance,
  } = useInsurances()
  const { canEditSettings: canEdit } = usePermission()

  const importButton = (
    <Button
      onClick={() => download(`${process.env.PUBLIC_URL}/templates/insurance.csv`, 'insurance.csv')}
      appearance="text"
    >
      Download an import template here.
    </Button>
  )

  return (
    <>
      <header>
        <Flexbox justifyContent="space-between">
          <Heading type="h1">Insurance Policies</Heading>
          <Flexbox alignItems='center' flexDirection='row' gap={12}>
            <ImportData action='insurance' title='Import Insurance Policies' modalDesc={importButton} />
            <Button onClick={openCreateInsuranceModal} appearance="primary" hidden={!canEdit}>
              Add new insurance policy
            </Button>
          </Flexbox>
        </Flexbox>
      </header>

      <Spacer y="default" />

      <InsurancesTable {...useInsurances()} canEdit={canEdit} />

      <Modal
        isOpen={createInsuranceModalIsOpen}
        title={`Add a new insurance policy`}
        form={INSURANCE_FORM_ID}
        close={closeCreateInsuranceModal}
        actionLabel={'Add insurance policy'}
        isActionLoading={isLoadingCreateInsurance}
        width={576}
      >
        <InsuranceForm
          handleSubmit={handleCreateInsurance}
          isEdit={false}
          initialValues={selectedInsurance}
        />
      </Modal>
    </>
  )
}
export default Insurances
