import { useStyletron } from 'styletron-react'
import * as styles from '../Styles'

import { Heading, Text } from '@components/common'

export interface NoResultsProps {
  heading?: string
  text?: string
}

const NoResults: React.FC<NoResultsProps> = ({
  heading = 'No results found.',
  text = 'Try changing the filters or search term.',
}) => {
  const [css] = useStyletron()
  return (
    <div className={css(styles.noResultsContainer)}>
      <Heading type="h2">{heading}</Heading>
      <Text p light>
        {text}
      </Text>
    </div>
  )
}
export default NoResults
