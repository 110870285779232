import { Button, Heading, Icon, Spacer } from '@common'
import { useNavigate } from 'react-router-dom'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import { HeaderProps } from './Template.types'

const ViewHeader = ({
  pageHeader,
  localNav,
  hasBackButton,
  backNavigateFunction,
}: HeaderProps) => {
  const [css] = useStyletron()
  const navigate = useNavigate()

  return (
    <div className={css(styles.viewHeader)}>
      <div className={css(styles.viewTitle)}>
        {hasBackButton && (
          <>
            <Button
              icon={<Icon name="ChevronLeft" />}
              onClick={backNavigateFunction ? backNavigateFunction : () => navigate(-1)}
              shape="square"
              size="compact"
            />
            <Spacer x="compact" />
          </>
        )}
        <Heading type="h1">{pageHeader}</Heading>
      </div>
      {localNav && localNav}
    </div>
  )
}
export default ViewHeader
