import React from 'react'
import { FlexboxProps } from './Flexbox.types'

import { useStyletron } from 'styletron-react'
import { StyleObject } from '@styles-api/css.types'

const Flexbox: React.FC<FlexboxProps> = ({
  justifyContent,
  alignItems,
  flexDirection = 'row',
  className,
  gap,
  style,
  height,
  ...props
}) => {
  const [css] = useStyletron()

  const styles: StyleObject = {
    display: 'flex',
    justifyContent: justifyContent,
    alignItems: alignItems,
    flexDirection: flexDirection,
    gap: gap ? `${gap}px` : '0',
  }

  return (
    <div
      className={`${css(styles)} ${className ? className : ''}`}
      style={{ ...style, height: height ? `${height}px` : '' }}
    >
      {props.children}
    </div>
  )
}

export default Flexbox
