import {
  AssetRecalcDTO,
  CustomDepreciation,
  customDepreciationSchema,
  FetchCustomDepreciationParams,
} from '@api/models'
import {
  useCreateCustomDepreciationMutation,
  useDeleteCustomDepreciationMutation,
  useEditCustomDepreciationMutation,
  useFetchCustomDepreciationQuery,
  useLazyDoesCustomDepreciationNeedRecalcQuery,
} from '@api'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import useFields from '@hooks/useFields'
import { yupResolver } from '@hookform/resolvers/yup'
import { getTodaysDate } from '@utils/basic'
import moment from 'moment'
import { toast } from '@components/common'
import { getUserFullName } from '@components/Auth/profile'

interface FormState {
  selectedCustomDepreciation: CustomDepreciation | null
  isCustomDepFormOpen: boolean
  isDeleteFormOpen: boolean
  type: 'add' | 'edit' | 'delete'
  recalcDTO: AssetRecalcDTO | null
  needsRecalc: boolean
  prevFromDate: string
}

const useCustomDepreciation = (params: FetchCustomDepreciationParams) => {
  const {
    data: fetchCustomDepreciationResponse,
    isLoading: isLoadingCustomDepreciationList,
  } = useFetchCustomDepreciationQuery(params)

  const { fields: fieldLabels, isLoading: isLoadingfieldLabels } = useFields()

  const [formState, setFormState] = useState<FormState>({
    selectedCustomDepreciation: null,
    isCustomDepFormOpen: false,
    isDeleteFormOpen: false,
    type: 'add',
    recalcDTO: null,
    needsRecalc: false,
    prevFromDate: '',
  })

  const listLength = fetchCustomDepreciationResponse?.List.length ?? 0
  const lastEle = fetchCustomDepreciationResponse?.List[listLength - 1]
  const doesNextDateExist = lastEle?.SP_YMO_TO !== undefined
  const defaultFromDate =
    doesNextDateExist && lastEle.SP_MET_CD !== 'P'
      ? moment(lastEle?.SP_YMO_TO, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
      : getTodaysDate()
  const defaultToDate = moment(defaultFromDate, 'YYYY-MM-DD')
    .add(1, 'M')
    .subtract(1, 'day')
    .format('YYYY-MM-DD')
  const defaultCustomDep = customDepreciationSchema.cast({
    AssetCompanyID: params.assetCompanyID,
    SP_OBJ_ID: params.objectID,
    SP_TYP_CD: params.profile,
    SP_YMO_FRM: defaultFromDate,
    SP_YMO_TO: defaultToDate,
  })

  const customDepFormMethods = useForm<CustomDepreciation>({
    mode: 'onBlur',
    resolver: yupResolver(customDepreciationSchema),
    defaultValues: defaultCustomDep,
  })

  const deleteMethods = useForm<CustomDepreciation>({ mode: 'onBlur' })

  function openAddForm() {
    customDepFormMethods.reset(defaultCustomDep)
    setFormState(prevState => ({
      ...prevState,
      selectedCustomDepreciation: defaultCustomDep,
      isCustomDepFormOpen: true,
      type: 'add',
    }))
  }

  function openEditForm(customDepreciation: CustomDepreciation) {
    customDepFormMethods.reset(customDepreciation)
    setFormState(prevState => ({
      ...prevState,
      selectedCustomDepreciation: customDepreciation,
      isCustomDepFormOpen: true,
      type: 'edit',
      prevFromDate: customDepreciation.SP_YMO_FRM,
    }))
  }

  function openDeleteForm(customDepreciation: CustomDepreciation) {
    setFormState(prevState => ({
      ...prevState,
      selectedCustomDepreciation: customDepreciation,
      isDeleteFormOpen: true,
      type: 'delete',
    }))
  }

  function closeForm() {
    setFormState({
      selectedCustomDepreciation: null,
      isCustomDepFormOpen: false,
      isDeleteFormOpen: false,
      type: 'add',
      recalcDTO: null,
      needsRecalc: false,
      prevFromDate: '',
    })
  }

  const [
    createCustomDep,
    { isLoading: isLoadingCreate },
  ] = useCreateCustomDepreciationMutation()
  const [editCustomDep, { isLoading: isLoadingEdit }] = useEditCustomDepreciationMutation()
  const [
    deleteCustomDep,
    { isLoading: isLoadingDelete },
  ] = useDeleteCustomDepreciationMutation()
  const [triggerRecalcCheck] = useLazyDoesCustomDepreciationNeedRecalcQuery()

  async function handleRecalcCheck(customDepreciation: CustomDepreciation) {
    try {
      const isEdit = formState.type === 'edit'
      const isDelete = formState.type === 'delete'
      const newRecalcDTO = await triggerRecalcCheck({
        deprecsp: customDepreciation,
        prevFromDate: isEdit ? formState.prevFromDate : customDepreciation.SP_YMO_FRM,
        isEdit,
        isDelete,
      }).unwrap()

      if (newRecalcDTO.ShouldRecalc) {
        setFormState(prevState => ({
          ...prevState,
          selectedCustomDepreciation: customDepreciation,
          recalcDTO: newRecalcDTO,
          needsRecalc: true,
        }))
      } else {
        await handleCustomDepSubmit(customDepreciation, newRecalcDTO)
      }
    } catch (error: any) {
      toast.error(error.data)
    }
  }

  async function handleCustomDepSubmit(
    customDepreciation: CustomDepreciation,
    recalcDTO: AssetRecalcDTO
  ) {
    try {
      const userFullName = getUserFullName()

      switch (formState.type) {
        case 'add': {
          await createCustomDep({
            deprecsp: customDepreciation,
            deprecspRecalcDTO: recalcDTO,
            userFullName,
          }).unwrap()
          toast.success('Custom depreciation added successfully')
          break
        }
        case 'edit': {
          await editCustomDep({
            deprecsp: customDepreciation,
            deprecspRecalcDTO: recalcDTO,
            prevFromDate: formState.prevFromDate,
            userFullName,
          }).unwrap()
          toast.success('Custom depreciation updated successfully')
          break
        }
        case 'delete': {
          await deleteCustomDep({
            deprecsp: customDepreciation,
            deprecspRecalcDTO: recalcDTO,
            userFullName,
          }).unwrap()
          toast.success('Custom depreciation deleted successfully')
          break
        }
      }
      closeForm()
    } catch (error: any) {
      toast.error(error.data)
    }
  }

  const customDepreciationList = fetchCustomDepreciationResponse?.List ?? []
  const depreciationMethod = fetchCustomDepreciationResponse?.Method ?? ''
  const hasCustomDepreciation = customDepreciationList.length > 0

  const isSubmittingCustomDepForm =
    customDepFormMethods.formState.isSubmitting || isLoadingCreate || isLoadingEdit
  const isDeleting = deleteMethods.formState.isSubmitting || isLoadingDelete

  const isCustomDepFormValid = customDepFormMethods.formState.isValid
  const isDeleteFormValid = deleteMethods.formState.isValid

  const isLoadingData = isLoadingCustomDepreciationList || isLoadingfieldLabels

  return {
    handleRecalcCheck,
    handleCustomDepSubmit,
    customDepFormMethods,
    deleteMethods,
    customDepreciationList,
    isLoadingData,
    hasCustomDepreciation,
    depreciationMethod,
    openAddForm,
    openEditForm,
    openDeleteForm,
    closeForm,
    ...formState,
    isSubmittingCustomDepForm,
    isDeleting,
    isCustomDepFormValid,
    isDeleteFormValid,
    fieldLabels,
  }
}

export default useCustomDepreciation
