import React from 'react'

import { Type } from '../Toasts.types'
import { Icon } from '@common'

interface IToastIcon {
  /** Icon type */
  type: Type
}

export const toastIcon = ({ type }: IToastIcon) => {
  switch (type) {
    case 'default':
      return <Icon name="Info" />
    case 'error':
      return <Icon name="AlertCircle" />
    case 'warning':
      return <Icon name="AlertTriangle" />
    case 'success':
      return <Icon name="CheckCircle" />
    default:
      return null
  }
}
