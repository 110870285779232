import { Helmet } from 'react-helmet'

interface Props {
  title?: string
  children
}

const View = ({ title, children }: Props) => {
  const Head = () => (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )

  return (
    <>
      <Head />
      {children}
    </>
  )
}

export default View
