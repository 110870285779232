import * as Yup from 'yup'
import { maxStringMsg } from '@utils/basic'
import { PicklistMap } from '@components/Picklist/Picklist.types'
import { ReactSelectOptions } from '@components/common/Select/Select.types'
import { REQUIRED_FIELD } from '@constants'
import type { RecalcOption } from '@api/assets/models'
import { DepreciationApi } from '@api'
import { rateZeroValidation } from '../../models/validation'

export type DepreciationProfileCode = 'C' | 'T' | 'S' | 'O' | 'X' | 'A'

export interface DeprecProps {
  AssetCompanyID?: number
  DP_OBJ_ID?: string
  DP_TYP_CD?: string // 'A' | 'X' | 'S' | 'O'
  DP_DESC?: string
  DP_ACPC_ID?: string
  DP_DPPC_ID?: string
  DP_DSPC_ID?: string
  DP_DGPC_ID?: string
  DP_MET_CD?: string // 'S' | 'D' | 'C' | 'U' | 'P' | 'N' | 'G'
  DP_REPA_CD?: string
  DP_STRT_CD?: string
  DP_LIFETIM?: number
  DP_RATE?: number
  DP_RATE_CD?: string
  DP_TAX_CRD?: number
  DP_TAX_UCD?: string
  DP_TAXC_CD?: boolean
  DP_INT_DED?: number
  DP_INT_UCD?: string
  DP_INT_CD?: boolean
  DP_REPS_CD?: string
  DP_REP_UCD?: string
}

export interface DeprecFormProps {
  extraFields?: DeprecExtraFields
  initialAssetDeprec?: DeprecProps
  picklistMap?: PicklistMap
  onSave?: (currentAssetDeprec: DeprecProps) => void
  formType: 'edit' | 'create'
  modalIsOpen: boolean
  closeModal: () => void
  name: string
}

export interface DeprecExtraFields {
  deprecCountry: ReactSelectOptions
  deprecDepreciationType: ReactSelectOptions
  depreciationMethod: ReactSelectOptions
  dispositionType: ReactSelectOptions
  startCode: ReactSelectOptions
  deprecRateCode: ReactSelectOptions
  glPost: ReactSelectOptions
  accelerationCode: ReactSelectOptions
  deprecSystem: ReactSelectOptions
  yearOneDeprecAddonCode: ReactSelectOptions
  taxCreditCode: ReactSelectOptions
  deprecUserCode: ReactSelectOptions
}

export interface ExtraFieldsMap {
  deprecCountry: {}
  deprecDepreciationType: {}
  depreciationMethod: {}
  dispositionType: {}
  startCode: {}
  deprecRateCode: {}
  glPost: {}
  accelerationCode: {}
  deprecSystem: {}
  yearOneDeprecAddonCode: {}
  taxCreditCode: {}
  deprecUserCode: {}
}

export interface DepreciationExtraFields {
  deprecCountry: ReactSelectOptions
  deprecDepreciationType: ReactSelectOptions
  depreciationMethod: ReactSelectOptions
  dispositionType: ReactSelectOptions
  startCode: ReactSelectOptions
  deprecRateCode: ReactSelectOptions
  glPost: ReactSelectOptions
  accelerationCode: ReactSelectOptions
  taxCreditCode: ReactSelectOptions
  yearOneDeprecAddonCode: ReactSelectOptions
  deprecUserCode: ReactSelectOptions
  deprecSystem: ReactSelectOptions
}

export type DeprecList = Array<{ id: string; name: string }>

export const depreciationClassSchema = (isCreate: boolean) =>
  Yup.object({
    AssetCompanyID: Yup.number().required(),
    DP_OBJ_ID: Yup.string()
      .typeError(REQUIRED_FIELD)
      .max(12, maxStringMsg(12))
      .test({
        test: async function () {
          const { DP_OBJ_ID, DP_TYP_CD } = this.parent

          if (!isCreate || DP_OBJ_ID == null || DP_OBJ_ID === '') return true

          return !(
            await DepreciationApi.getInstance().doesDeprecExist(
              encodeURIComponent(DP_OBJ_ID),
              DP_TYP_CD
            )
          ).data
        },
        message: 'An depreciation class with this ID already exists',
      })
      .required(REQUIRED_FIELD),
    DP_TYP_CD: Yup.string(),
    DP_DESC: Yup.string().max(50, maxStringMsg(50)).required(REQUIRED_FIELD),
    DP_ACPC_ID: Yup.string().nullable(),
    DP_DPPC_ID: Yup.string().nullable(),
    DP_DSPC_ID: Yup.string().nullable(),
    DP_DGPC_ID: Yup.string().nullable(),
    DP_TFPC_ID: Yup.string().nullable(),
    DP_LOCD_CD: Yup.string().nullable(),
    DP_LAPC_ID: Yup.string().nullable(),
    DP_LDPC_ID: Yup.string().nullable(),
    DP_SERD_CD: Yup.string().nullable(),
    DP_SAPC_ID: Yup.string().nullable(),
    DP_SDPC_ID: Yup.string().nullable(),
    DP_MET_CD: Yup.string().required(REQUIRED_FIELD),
    DP_RATE_CD: Yup.string().nullable(),
    DP_LIFETIM: Yup.number()
      .required(REQUIRED_FIELD)
      .test({
        test: function () {
          const { DP_LIFETIM, DP_RATE_CD, DP_MET_CD } = this.parent
          if (DP_RATE_CD === 'L' && DP_LIFETIM === 0) {
            return false
          }
          if (DP_MET_CD === 'C' && DP_LIFETIM === 0) {
            return false
          }

          return true
        },
        message:
          'If the depreciation method is non-linear, then years must be greater than zero.',
      }),
    DP_STRT_CD: Yup.string().required(REQUIRED_FIELD),
    DP_RATE: Yup.number().required(REQUIRED_FIELD).test(rateZeroValidation('DP_MET_CD')),
    DP_TAX_CRD: Yup.number().nullable(),
    DP_TAXC_CD: Yup.bool().nullable(),
    DP_TAX_UCD: Yup.string().nullable(),
    DP_INT_DED: Yup.number().nullable(),
    DP_INT_CD: Yup.bool().nullable(),
    DP_INT_UCD: Yup.string().nullable(),
    DP_REPA_CD: Yup.string().nullable(),
    DP_REPS_CD: Yup.string().nullable(),
    DP_REP_UCD: Yup.string().nullable(),
    DP_CNTY_CD: Yup.string().nullable(),
    DP_SAL_PCT: Yup.string().nullable(),
    DP_LOCKED: Yup.bool().nullable(),
  })
export type DepreciationClass = Yup.InferType<ReturnType<typeof depreciationClassSchema>>

export type DepreciationClassKey = keyof DepreciationClass

export const initialDepreciation = (type: DepreciationProfileCode): DepreciationClass => {
  return {
    AssetCompanyID: 0,
    DP_OBJ_ID: '',
    DP_TYP_CD: type,
    DP_DESC: '',
    DP_ACPC_ID: null,
    DP_DPPC_ID: null,
    DP_DSPC_ID: null,
    DP_DGPC_ID: null,
    DP_TFPC_ID: null,
    DP_LOCD_CD: null,
    DP_LAPC_ID: null,
    DP_LDPC_ID: null,
    DP_SERD_CD: null,
    DP_SAPC_ID: null,
    DP_SDPC_ID: null,
    DP_MET_CD: null,
    DP_LIFETIM: 0,
    DP_STRT_CD: 'D',
    DP_RATE: 0,
    DP_RATE_CD: 'A',
    DP_TAX_CRD: 0,
    DP_TAXC_CD: false,
    DP_TAX_UCD: null,
    DP_INT_DED: 0,
    DP_INT_CD: false,
    DP_INT_UCD: null,
    DP_REPA_CD: null,
    DP_REPS_CD: null,
    DP_REP_UCD: null,
    DP_CNTY_CD: null,
    DP_SAL_PCT: '0',
    DP_LOCKED: false,
  }
}

// Payload and respone models
export interface ListDepreciationsPayload {
  profile: DepreciationProfileCode
}

export type ListDepreciationsResponse = {
  ID: string
  Name: string
}[]

export interface FetchDepreciationsResponse {
  count: number
  list: DepreciationClass[]
}

export interface DepreciationClassParams {
  id: string
  profile: string
  assetCompanyID: number
}

export interface DeprecRecalcDTO {
  ShouldRecalc: boolean
  Options: RecalcOption[]
  SelectedOption: string
  AsOfYear: number
  PostingDate: string
}

export interface EditDepreciationPayload {
  deprec: DepreciationClass
  deprecRecalcDTO: DeprecRecalcDTO
}

export interface AssetDepreciationParams {
  assetCompanyID: number
  assetID: string
  profile: DepreciationProfileCode
}

export interface AssetSpecificDepreciationParams {
  assetCompanyID: number
  assetID: string
  profile: DepreciationProfileCode
}

export interface DeprecCodeLabels {
  deprecProfile: string
  deprecMethod: string
  startCode: string
  rateCode: string
  systemCode: string
  accelerationCode: string
}

// Constants
export const DEPRECIATION_PROFILES: { code: DepreciationProfileCode; label: string }[] = [
  {
    code: 'C',
    label: 'Corporate',
  },
  {
    code: 'T',
    label: 'Tax',
  },
  {
    code: 'S',
    label: 'State',
  },
  {
    code: 'O',
    label: 'Other',
  },
  {
    code: 'X',
    label: 'Tax Class',
  },
  {
    code: 'A',
    label: 'Category',
  },
]

export const DEPRECIATION_METHODS: ReactSelectOptions = [
  {
    value: 'S',
    label: 'Straight Line Net',
  },
  {
    value: 'D',
    label: 'Declining Balance',
  },
  {
    value: 'C',
    label: 'Compound',
  },
  {
    value: 'U',
    label: 'Sum of Digits',
  },
  {
    value: 'P',
    label: 'Units of Production',
  },
  {
    value: 'N',
    label: 'No Depreciation',
  },
]
