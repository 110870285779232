import { AssetCharge, AssetChargeType, DisposalProjectionsParams, FieldLabels } from '@api/models'
import {
  Button,
  Checkbox,
  CustomDatePicker,
  Divider,
  Fieldset,
  Flexbox,
  Form,
  Grid,
  Input,
  Note,
  Radio,
  Select,
  Spacer,
  Spinner,
} from '@components/common'
import { ASSET_CHARGE_FORM_ID, DISPOSITION_OPTIONS } from '@constants'
import { Controller, set, useFormContext } from 'react-hook-form'
import { useAssetDetails, useFormFieldProps } from '@hooks'
import { formatCurrency, isNullOrUndefined } from '@utils/basic'
import { useGetDisposalProjectionsQuery, useGetExtraChargeFieldsQuery, useGetTaskQuery } from '@api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import stopPropagation from '@utils/stopPropagation'

interface Props {
  fields: FieldLabels
  handleChargeFormSubmit: (payload: AssetCharge) => Promise<void>
  chargeTypeCode: AssetChargeType
  isCreate?: boolean
  chargeType: string
  EditingEnabled: boolean
  canEdit: boolean
}

const AssetChargesForm = ({
  fields,
  handleChargeFormSubmit,
  chargeTypeCode,
  isCreate,
  chargeType,
  EditingEnabled,
  canEdit,
}: Props) => {
  const {
    register,
    formState: { errors, isSubmitting, },
    control,
    handleSubmit,
    watch,
    setValue,
  } = useFormContext<AssetCharge>()

  const {
    getInputProps,
    getSelectProps,
    getCheckBoxProps,
    getCreatableSelectProps,
    getDateProps,
  } = useFormFieldProps<AssetCharge>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  const asset = useAssetDetails()

  const params = useParams()
  const assetCompanyID = parseInt(params.companyID)
  const assetID = params.assetID

  const [percent, setPercent] = useState(0)
  const [amountOfAcquisition, setAmountOfAcquisition] = useState(0)
  const [amountType, setAmountType] = useState<'percent' | 'amount' | 'acquisition'>('amount');

  const {
    data: extraFields,
    isLoading: isLoadingExtraFields,
  } = useGetExtraChargeFieldsQuery()

  const activityID = watch('CH_ACT_ID')

  const { data: task, isLoading: isLoadingTask } = useGetTaskQuery(activityID, {
    skip: isNullOrUndefined(activityID),
  })

  const disposalAmount = watch('CH_COST')

  const projectionParams: DisposalProjectionsParams = {
    assetCompanyID,
    assetID,
    realizedAmount: watch('CH_SER_LCO'),
    disposalSoldTo: watch('CH_PERSON'),
    disposalDate: watch('CH_DATE'),
  }

  // Get projection data
  const {
    data: disposalProjections,
    isLoading: isLoadingDisposalProjections,
    isFetching: isFetchingDisposalProjections,
  } = useGetDisposalProjectionsQuery(projectionParams, {
    skip: chargeTypeCode !== 'P',
  });

  const navigate = useNavigate()

  const inputAmount = useMemo(() => {
    if (isNullOrUndefined(disposalProjections)) {
      return 0
    }

    if (amountType === 'percent') {
      const value = disposalProjections?.NBV * (percent / 100)
      return Number(value.toFixed(2))
    } else if (amountType === 'amount') {
      return Number(disposalAmount.toFixed(2))
    } else if (amountType === 'acquisition') {
      const percentOfTotal = amountOfAcquisition / asset.asset.AS_FIN_COS
      return Number((disposalProjections?.NBV * percentOfTotal).toFixed(2))
    }
  }, [amountType, disposalAmount, amountOfAcquisition, disposalProjections, percent, asset.asset.AS_FIN_COS])

  const partialDisposalHelpText = useMemo(() => {
    switch (amountType) {
      case 'acquisition':
        return 'Enter the amount to be disposed based on its original acquisition cost. The system will calculate the amount based on the current net book value at the selected disposal date.'
      case 'amount':
        return 'Enter the amount to be disposed based on its current net book value. This is the value as of the selected disposal date.'
      case 'percent':
        return 'Enter a percentage of the asset to be disposed. The amount will be calculated as a percentage of the current net book value at the selected disposal date.'
      default:
        return ''
    }
  }, [amountType])

  useEffect(() => {
    setValue('CH_COST', 0)
    setAmountOfAcquisition(0)
    setPercent(0)
  }, [amountType])

  useEffect(() => {
    setValue('CH_COST', inputAmount)
  }, [inputAmount, setValue]);

  useEffect(() => {
    if (!isNullOrUndefined(activityID) && !isLoadingTask) {
      setValue('CH_DIS_CD', task.OT_RETD_CD, { shouldValidate: true })
      setValue('CH_CAPC_ID', task.OT_OAPC_ID)
      setValue('CH_CDPC_ID', task.OT_ODPC_ID)
      setValue('CH_TAPC_ID', task.OT_OAPC_ID)
      setValue('CH_TDPC_ID', task.OT_ODPC_ID)
    }
  }, [task, activityID, isLoadingTask, setValue])

  const isActivityIDSelected = !isNullOrUndefined(activityID)
  const isLocationForm = chargeTypeCode === 'L'
  const isServiceForm = chargeTypeCode === 'S'
  const isTaskActivityForm = chargeTypeCode === 'O'
  const isPartialDisposalForm = chargeTypeCode === 'P'

  if (isLoadingExtraFields)
    return (
      <Flexbox height={240} justifyContent="center" alignItems="center">
        <Spinner />
      </Flexbox>
    )

  return (
    <>
      <Form
        id={ASSET_CHARGE_FORM_ID}
        noValidate
        onSubmit={handleSubmit(handleChargeFormSubmit)}
      >
        <Grid>
          {isPartialDisposalForm && (
            <Grid.Item>
              <Note
                intent="default"
                content="If Proceeds exceeds NBV at Disposal then Depreciable Value of Partial Disposal = Zero"
              />
            </Grid.Item>
          )}

          {/* Service Description */}
          <Grid.Item>
            <Input
              {...getInputProps('CH_DESC')}
              autoFocus
              label={fields[`CH_DESC${chargeTypeCode}`]}
              readOnly={!canEdit}
            />
          </Grid.Item>

          {/* Service Date */}
          <Grid.Item>
            <Controller
              control={control}
              name="CH_DATE"
              render={({ field }) => (
                <CustomDatePicker
                  {...getDateProps('CH_DATE', field)}
                  label={fields[`CH_DATE${chargeTypeCode}`]}
                  readOnly={!canEdit}
                />
              )}
            />
          </Grid.Item>

          {isLocationForm && (
            <>
              <Grid.Item m={4}>
                <Input
                  {...getInputProps('CH_COST', 'currency')}
                  label={fields[`CH_COST${chargeTypeCode}`]}
                  readOnly={!canEdit}
                />
              </Grid.Item>
              <Grid.Item m={8}>
                <Controller
                  control={control}
                  name="CH_DIS_CD"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('CH_DIS_CD', field, DISPOSITION_OPTIONS)}
                      disabled={!canEdit || isActivityIDSelected}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item>
                <Controller
                  control={control}
                  name="CH_LOCT_CD"
                  render={({ field }) => (
                    <Select
                      {...getCreatableSelectProps(
                        'CH_LOCT_CD',
                        field,
                        extraFields.locations
                      )}
                      menuPlacement="top"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item m={6}>
                <Input {...getInputProps('CH_LOC_NR')} readOnly={!canEdit} />
              </Grid.Item>
              <Grid.Item m={6}>
                <Input {...getInputProps('CH_AUDITNR')} readOnly={!canEdit} />
              </Grid.Item>
              <Grid.Item>
                <Controller
                  control={control}
                  name="CH_REM_CD"
                  render={({ field }) => (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      label="Depreciate using remaining asset life"
                      readOnly={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
            </>
          )}

          {isServiceForm && (
            <>
              {/* Serviced By */}
              <Grid.Item>
                <Controller
                  control={control}
                  name="CH_PERSON"
                  render={({ field }) => (
                    <Select
                      {...getCreatableSelectProps(
                        'CH_PERSON',
                        field,
                        extraFields.servicedBy
                      )}
                      menuPlacement="top"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid.Item>

              {/* Material Cost */}
              <Grid.Item m={4}>
                <Input
                  {...getInputProps('CH_COST', 'currency')}
                  label={fields[`CH_COST${chargeTypeCode}`]}
                  readOnly={!canEdit}
                />
              </Grid.Item>

              {/* Labor Cost */}
              <Grid.Item m={4}>
                <Input {...getInputProps('CH_SER_LCO', 'currency')} readOnly={!canEdit} />
              </Grid.Item>

              {/* Other Cost */}
              <Grid.Item m={4}>
                <Input {...getInputProps('CH_SER_OCO', 'currency')} readOnly={!canEdit} />
              </Grid.Item>

              {/* Disposition */}
              <Grid.Item>
                <Controller
                  control={control}
                  name="CH_DIS_CD"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('CH_DIS_CD', field, DISPOSITION_OPTIONS)}
                      disabled={!canEdit || isActivityIDSelected}
                    />
                  )}
                />
              </Grid.Item>

              {/* Service Code */}
              <Grid.Item m={6}>
                <Input {...getInputProps('CH_SER_CD')} readOnly={!canEdit} />
              </Grid.Item>

              {/* Reference */}
              <Grid.Item m={6}>
                <Input {...getInputProps('CH_AUDITNR')} readOnly={!canEdit} />
              </Grid.Item>

              {/* Checkbox */}
              <Grid.Item>
                <Controller
                  control={control}
                  name="CH_REM_CD"
                  render={({ field }) => (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      label="Depreciate using remaining asset life"
                      readOnly={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
            </>
          )}

          {isTaskActivityForm && (
            <>
              <Grid.Item>
                <Controller
                  control={control}
                  name="CH_PERSON"
                  render={({ field }) => (
                    <Select
                      {...getCreatableSelectProps(
                        'CH_PERSON',
                        field,
                        extraFields.performedBy
                      )}
                      label={fields['CH_PERSONO']}
                      menuPlacement="top"
                      readOnly={!canEdit}
                    />
                  )}
                />
              </Grid.Item>

              <Grid.Item m={6}>
                <Controller
                  control={control}
                  name="CH_INV_DAT"
                  render={({ field }) => (
                    <CustomDatePicker
                      {...getDateProps('CH_INV_DAT', field)}
                      label={fields[`CH_INV_DAT`]}
                      readOnly={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item m={6}>
                <Input {...getInputProps('CH_AUDITNR')} readOnly={!canEdit} />
              </Grid.Item>
              <Grid.Item>
                <Controller
                  control={control}
                  name="CH_ACT_ID"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('CH_ACT_ID', field, extraFields.activities)}
                      menuPlacement="top"
                      label="Activity ID"
                      readOnly={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item m={4}>
                <Input
                  {...getInputProps('CH_COST', 'currency')}
                  label={fields[`CH_COST${chargeTypeCode}`]}
                  readOnly={!canEdit}
                />
              </Grid.Item>
              <Grid.Item m={8}>
                <Controller
                  control={control}
                  name="CH_DIS_CD"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('CH_DIS_CD', field, DISPOSITION_OPTIONS)}
                      disabled={!canEdit || isActivityIDSelected}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item>
                <Controller
                  control={control}
                  name="CH_REM_CD"
                  render={({ field }) => (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      label="Depreciate using remaining asset life"
                      readOnly={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
            </>
          )}
          {isPartialDisposalForm && (
            <>
              <Grid.Item>
                {!isLoadingDisposalProjections && !isFetchingDisposalProjections ?
                <>
                <span style={{display: 'block'}}>Projected net book value at disposal date: <b>{
                  formatCurrency(disposalProjections?.NBV ?? 0)
                }</b>
                </span>
                <span style={{ display: 'block' }}>Acquisition cost: <b>{
                  formatCurrency(asset.asset.AS_FIN_COS ?? 0)
                }</b>
                </span>
                </>
                :
                <Spinner />
                }
              </Grid.Item>
              <Grid.Item>
                <Radio
                  name="amountType"
                  onChange={e => setAmountType(e.target.value)}
                  select={amountType}
                >
                  <Radio.Option checked={amountType === 'amount'} value="amount" label="Amount to be disposed based on net book value" />
                  <Radio.Option checked={amountType === 'percent'} value="percent" label="Percentage of asset to be disposed" />
                  <Radio.Option checked={amountType === 'acquisition'} value="acquisition" label="Amount to be disposed based on acquisition cost" />
                </Radio>
              </Grid.Item>
              <Grid.Item>
                <Note content={partialDisposalHelpText} />
              </Grid.Item>
              <Grid.Item>
                {amountType === 'percent' && (
                  <Input
                    value={percent}
                    onChange={e => setPercent(e.target.value)}
                    error={percent >= 100}
                    errorMsg='Percentage of asset to be disposed must be less than 100'
                    type="number"
                    label="Percentage of asset to be disposed"
                    readOnly={!canEdit}
                    icon='%'
                  />
                )}
                {amountType === 'amount' && (
                  <Input
                    {...getInputProps('CH_COST', 'currency')}
                    error={inputAmount >= disposalProjections?.NBV}
                    errorMsg='Amount to be disposed must be less than the projected net book value at disposal date'
                    label={'Amount to be disposed based on net book value'}
                    readOnly={!canEdit}
                  />
                )}
                {amountType === 'acquisition' && (
                  <Input
                    value={amountOfAcquisition}
                    onChange={e => setAmountOfAcquisition(e.target.value)}
                    error={amountOfAcquisition >= asset.asset.AS_FIN_COS}
                    errorMsg='Amount to be disposed must be less than the acquisition cost'
                    type="number"
                    icon="$"
                    label={'Amount to be disposed based on acquisition cost'}
                    readOnly={!canEdit}
                  />
                )}
              </Grid.Item>
              <Grid.Item>
                <span style={{ display: 'block' }}>
                  Percent of asset to be disposed: <b>{amountType === 'percent' ? percent : (inputAmount / disposalProjections?.NBV * 100)?.toFixed(2) ?? 0}%</b>
                </span>
                <span style={{ display: 'block'}}>
                  Book value after disposal: <b>{
                    formatCurrency((disposalProjections?.NBV - inputAmount) ?? 0)
                  }</b>
                </span>
                <span style={{ display: 'block' }}>
                  Book value to be disposed: <b>{
                    formatCurrency(disposalAmount)
                  }</b>
                </span>
              </Grid.Item>
              <Grid.Item m={8}>
                <Controller
                  control={control}
                  name="CH_PERSON"
                  render={({ field }) => (
                    <Select
                      {...getCreatableSelectProps('CH_PERSON', field, extraFields.soldTo)}
                      label={fields['CH_PERSONP']}
                      menuPlacement="top"
                      readOnly={!canEdit}
                    />
                  )}
                />
              </Grid.Item>
              <Grid.Item m={4}>
                <Input
                  {...getInputProps('CH_SER_LCO', 'currency')}
                  label={fields['CH_SER_LCP']}
                  readOnly={!canEdit}
                />
              </Grid.Item>
            </>
          )}

          <Grid.Item>
            <Fieldset legend="Post Codes">
              <Grid>
                <Grid.Item m={6}>
                  <Controller
                    control={control}
                    name="CH_CAPC_ID"
                    render={({ field }) => (
                      <Select
                        {...getSelectProps('CH_CAPC_ID', field, extraFields.glPosts)}
                        menuPlacement="top"
                        disabled={!canEdit || isActivityIDSelected}
                        {...(isPartialDisposalForm ? { label: 'Disposal Post Code' } : {})}
                      />
                    )}
                  />
                </Grid.Item>
                <Grid.Item m={6}>
                  <Controller
                    control={control}
                    name="CH_CDPC_ID"
                    render={({ field }) => (
                      <Select
                        {...getSelectProps('CH_CDPC_ID', field, extraFields.glPosts)}
                        menuPlacement="top"
                        disabled={!canEdit || isActivityIDSelected}
                        {...(isPartialDisposalForm
                          ? { label: 'Disposal Gn/Ls Post Code' }
                          : {})}
                      />
                    )}
                  />
                </Grid.Item>
                <Grid.Item m={6}>
                  <Controller
                    control={control}
                    name="CH_TAPC_ID"
                    render={({ field }) => (
                      <Select
                        {...getSelectProps('CH_TAPC_ID', field, extraFields.glPosts)}
                        menuPlacement="top"
                        disabled={!canEdit || isActivityIDSelected}
                        {...(isPartialDisposalForm
                          ? { label: 'Tax Disposal Post Code' }
                          : {})}
                      />
                    )}
                  />
                </Grid.Item>
                <Grid.Item m={6}>
                  <Controller
                    control={control}
                    name="CH_TDPC_ID"
                    render={({ field }) => (
                      <Select
                        {...getSelectProps('CH_TDPC_ID', field, extraFields.glPosts)}
                        menuPlacement="top"
                        disabled={!canEdit || isActivityIDSelected}
                        {...(isPartialDisposalForm
                          ? { label: 'Tax Disposal Gn/Ls Post Code' }
                          : {})}
                      />
                    )}
                  />
                </Grid.Item>
              </Grid>
            </Fieldset>
          </Grid.Item>

          {!isCreate && !isNullOrUndefined(watch('CH_ADD_USR')) && (
            <Grid.Item>
              <Divider spacing="default" />
              <b>Added by:</b> {watch('CH_ADD_USR')}
            </Grid.Item>
          )}
          {canEdit && (
            <Grid.Item>
              <Flexbox justifyContent="flex-end">
                <Button
                  appearance="default"
                  disabled={false}
                  onClick={e => {
                    stopPropagation(e)
                    navigate(`..`)
                  }}
                >
                  Cancel
                </Button>
                <Spacer x="default" />
                <Button type="submit" appearance="primary" disabled={EditingEnabled || isSubmitting}>
                  {`${isCreate ? 'Add' : 'Edit'} ${chargeType.toLowerCase()} record`}
                </Button>
              </Flexbox>
            </Grid.Item>
          )}
        </Grid>
      </Form>
    </>
  )
}

export default AssetChargesForm
