import { Flexbox, Button, Grid, useModal, Modal } from '@components/common'
import FileFormatSelect from './FileFormatSelect'

interface IActionButtonsProps {
  isSubmitting: boolean
  pdfOnly?: boolean
  allowExcel?: boolean
  allowCSV?: boolean
  methods?: any
  handleMemorizeTemplate?: any
  triggerMemorizeTemplateModal?: any
  isEditTemplate?: boolean
}

const ActionButtons = ({
  isSubmitting,
  pdfOnly,
  allowExcel = false,
  allowCSV = false,
  triggerMemorizeTemplateModal,
  isEditTemplate,
}: IActionButtonsProps) => {
  return (
    <Flexbox justifyContent="flex-end">
      <Grid>
        <Grid.Item startS={2} s={4}>
          <Button onClick={triggerMemorizeTemplateModal} appearance={'primary'}>
            {`${isEditTemplate ? 'Save' : 'Memorize Template'}`}
          </Button>
        </Grid.Item>

        <Grid.Item startS={6} s={3}>
          <FileFormatSelect pdfOnly={pdfOnly} allowExcel={allowExcel} allowCSV={allowCSV} />
        </Grid.Item>

        <Grid.Item s={4}>
          <Button
            type="submit"
            appearance={'primary'}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Generate report
          </Button>
        </Grid.Item>
      </Grid>
    </Flexbox>
  )
}

export default ActionButtons
