import { DEPRECIATION_TAGS, apiSlice } from '@api'
import { formatISODate } from '@utils/basic'
import { DepInfo, DepRunInput } from '../models'

export const periodDepreciationEndpoints = apiSlice.injectEndpoints({
  endpoints: build => ({
    getDepRunInfo: build.query<DepInfo, number>({
      query: assetCompanyID => `AssetHistory/DepRunInfo/${assetCompanyID}`,
      transformResponse: (info: DepInfo) => ({
        ...info,
        PostDate: formatISODate(info.PostDate),
        AsOfDate: info.AsOfDate === null ? null : formatISODate(info.AsOfDate),
      }),
      providesTags: ['depreciationRun'],
    }),
    depRun: build.mutation<void, DepRunInput>({
      query: depRunInfo => ({
        url: `AssetHistory/DepRun`,
        method: 'POST',
        body: { ...depRunInfo },
      }),
      invalidatesTags: DEPRECIATION_TAGS,
    }),
    getDepUpdateInfo: build.query<DepInfo, number>({
      query: assetCompanyID => `AssetHistory/DepUpdateInfo/${assetCompanyID}`,
      transformResponse: (info: DepInfo) => ({
        ...info,
        PostDate: formatISODate(info.PostDate),
        AsOfDate: info.AsOfDate === null ? null : formatISODate(info.AsOfDate),
      }),
      providesTags: DEPRECIATION_TAGS,
    }),
    depUpdate: build.mutation<void, number>({
      query: assetCompanyID => ({
        url: `AssetHistory/DepUpdate/${assetCompanyID}`,
        method: 'POST',
      }),
      invalidatesTags: [...DEPRECIATION_TAGS, 'dashboard'],
    }),
    depUndoInfo: build.query<DepInfo, number>({
      query: assetCompanyID => `AssetHistory/DepUndoInfo/${assetCompanyID}`,
      transformResponse: (info: DepInfo) => ({
        ...info,
        PostDate: formatISODate(info.PostDate),
        AsOfDate: info.AsOfDate === null ? null : formatISODate(info.AsOfDate),
      }),
      providesTags: DEPRECIATION_TAGS,
    }),
    depUndo: build.mutation<void, DepRunInput>({
      query: undoInput => ({
        url: 'AssetHistory/DepUndo',
        method: 'POST',
        body: { ...undoInput },
      }),
      invalidatesTags: [...DEPRECIATION_TAGS, 'dashboard'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetDepRunInfoQuery,
  useDepRunMutation,
  useGetDepUpdateInfoQuery,
  useDepUpdateMutation,
  useDepUndoInfoQuery,
  useDepUndoMutation,
} = periodDepreciationEndpoints
