import React, { useState } from 'react'

import { useStyletron } from 'styletron-react'
import {
  table__head__sortIconStyles,
  table__head__sortStyles,
  columnHeader_styles,
} from '../Styles'
import * as styles from '../Styles'

import { Icon } from '@components/common'

export interface TableHeaderProps extends React.HTMLProps<HTMLTableHeaderCellElement> {
  column?: any
  canResize?: boolean
  centered?: boolean
}

const Header: React.FC<TableHeaderProps> = ({ column, canResize, centered, ...props }) => {
  const [css] = useStyletron()
  const [, setHovered] = useState(false)
  const [resizeHovered, ] = useState(false)
  const handleHover = () => setHovered(true)
  const handleLeave = () => setHovered(false)

  return (
    <div
      role="columnheader"
      {...props}
      aria-sort="none"
      className={css(columnHeader_styles)}
    >
      <div
        className={css(styles.header({ column, resizeHovered, centered }))}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        {props.children}
        {column && column.canSort && (
          <span className={css(table__head__sortStyles)}>
            {column?.isSorted ? (
              column?.isSortedDesc ? (
                <Icon name="ArrowDown" className={css(table__head__sortIconStyles)} />
              ) : (
                <Icon name="ArrowUp" className={css(table__head__sortIconStyles)} />
              )
            ) : (
              ''
            )}
            {/* {column.isSorted === false && hovered && (
              <Icon name="ArrowUp" className={css(table__head__sortIconStyles)} />
            )} */}
          </span>
        )}
      </div>
      {canResize && <span className={css(styles.resizer)}></span>}
    </div>
  )
}

export default Header
