import React from 'react'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

import { Button } from '..'

const SideSheetFooter = ({
  close,
  dismissLabel,
  actionLabel,
  action,
  intent,
}) => {
  const [css] = useStyletron()
  return (
    <div className={css(styles.footer)}>
      <Button appearance="primary" intent={intent} onClick={close} flex>
        Done
      </Button>
    </div>
  )
}
export default SideSheetFooter
