import React from 'react'
import { HelperProps } from './Helper.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import Tooltip from '../Tooltip'
import Icon from '../Icon'
import Flexbox from '../Flexbox'

const Helper: React.FC<HelperProps> = ({ color = 'primary', ...props }) => {
  const [css] = useStyletron()

  const colorMap: { [key: string]: string } = {
    primary: 'var(--primary-color)',
    grey: 'var(--shade400)',
    black: 'var(--contrast-color)',
  }

  const iconColor = colorMap[color]

  return (
    <Tooltip content={props.content} position={props.position}>
      <Flexbox className={css(styles.wrapper)} justifyContent="center" alignItems="center">
        <Icon name="HelpCircle" className={css({ ...styles.helpIcon, color: iconColor })} />
      </Flexbox>
    </Tooltip>
  )
}

export default Helper
