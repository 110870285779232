import { DepreciationProfileCode } from '@api/models'
import { Flexbox, Heading, Button, Icon, Divider } from '@components/common'
import DepreciationClassForm from '@components/depreciation-class/DepreciationClassForm'
import { ASSET_PATHS } from '@constants'
import useAssetDepreciationProfiles from '@hooks/useAssetDepreciationProfiles'
import { useGetAssetQuery } from '@api/assets/assetsEndpointsHooks'
import { useParams } from 'react-router-dom'
import { isNullOrUndefined } from '@utils/basic'

const AssetDeprecClassFormWrapper = () => {
  const urlParams = useParams()

  const params = {
    profile: urlParams.profile as DepreciationProfileCode,
    assetID: urlParams.assetID,
    assetCompanyID: parseInt(urlParams.companyID),
  }

  const {
    isLoading,
    depreciationProfileLabel,
    isProfileMaintained,
  } = useAssetDepreciationProfiles()

  let EditingEnabled = false

  const { data: isAssetDesc } = useGetAssetQuery({
    assetCompanyID: parseInt(urlParams.companyID),
    assetID: urlParams.assetID,
  })

  if (!isNullOrUndefined(isAssetDesc)) {
    EditingEnabled =
      isAssetDesc.AS_DIS_CD === 'D' || isAssetDesc.AS_DIS_CD === 'P' ? true : false
  }

  if (isLoading) return <></>

  return (
    <>
      <Flexbox alignItems="center" justifyContent="space-between">
        <div>
          <Heading type="h2">{depreciationProfileLabel} Depreciation Profile</Heading>
        </div>
        {isProfileMaintained && (
          <Button
            link={`${ASSET_PATHS.CUSTOM_DEPRECIATION}`}
            iconRight={<Icon name="ArrowRight" />}
            disabled={EditingEnabled}
          >
            Custom Depreciation
          </Button>
        )}
      </Flexbox>
      <Divider spacing="default" />
      <DepreciationClassForm context="asset" params={params} />
    </>
  )
}

export default AssetDeprecClassFormWrapper
