import {
  FetchInsurancesResponse,
  initialInsurancePolicy,
  InsurancePolicy,
  InsurancePolicyKey,
} from '@api/models'
import { toast, useModal } from '@components/common'
import {
  useCreateInsuranceMutation,
  useDeleteInsuranceMutation,
  useEditInsuranceMutation,
  useFetchInsurancesQuery,
} from '@api'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTableParams } from '@hooks'
import { TableParamsHook } from 'hooks/useTableParams'

export interface InsurancesHook extends TableParamsHook {
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  searchKey: string
  selectedInsurance: InsurancePolicy

  insurances: FetchInsurancesResponse
  isLoadingInsurances: boolean
  isErrorFetchingInsurances: boolean
  isRefetchingInsurances: boolean

  handleEditInsurance: (payload: InsurancePolicy) => Promise<void>
  handleCreateInsurance: (payload: InsurancePolicy) => Promise<void>
  isLoadingEditInsurance: boolean
  isLoadingCreateInsurance: boolean

  handleDeleteInsurance: (payload: InsurancePolicy) => Promise<void>
  isLoadingDeleteInsurance: boolean

  deleteInsuranceModalIsOpen: boolean
  triggerDeleteInsuranceModal
  closeDeleteInsuranceModal
  openDeleteInsuranceModal: (insurance: InsurancePolicy) => void
  openEditInsuranceModal: (insurance: InsurancePolicy) => void
  triggerEditInsuranceModal
  closeEditInsuranceModal
  editInsuranceModalIsOpen: boolean

  openCreateInsuranceModal: () => void
  createInsuranceModalIsOpen: boolean
  closeCreateInsuranceModal
}

export default function useInsurances(): InsurancesHook {
  const [filterState, setFilterState] = useState({})
  const [selectedInsurance, setSelectedInsurance] = useState<InsurancePolicy>(undefined)

  const searchKey: InsurancePolicyKey = 'IN_DESC'
  const initialItemsPerPage = 20

  // Use useTableParams hook for table filtering, pagination, etc.
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  // Fetch Insurances
  const {
    data: insurances,
    isLoading: isLoadingInsurances,
    isError: isErrorFetchingInsurances,
    isFetching: isRefetchingInsurances,
  } = useFetchInsurancesQuery(fetchRequestPayload)

  // MODAL STATES
  // Delete modal
  const {
    modalIsOpen: deleteInsuranceModalIsOpen,
    triggerModal: triggerDeleteInsuranceModal,
    closeModal: closeDeleteInsuranceModal,
  } = useModal()
  function openDeleteInsuranceModal(insurance: InsurancePolicy): void {
    setSelectedInsurance(insurance)
    triggerDeleteInsuranceModal()
  }

  // Edit modal
  const {
    modalIsOpen: editInsuranceModalIsOpen,
    triggerModal: triggerEditInsuranceModal,
    closeModal: closeEditInsuranceModal,
  } = useModal()

  function openEditInsuranceModal(insurance: InsurancePolicy): void {
    setSelectedInsurance(insurance)
    triggerEditInsuranceModal()
  }

  // Create modal
  const {
    modalIsOpen: createInsuranceModalIsOpen,
    triggerModal: triggerCreateInsuranceModal,
    closeModal: closeCreateInsuranceModal,
  } = useModal()
  function openCreateInsuranceModal(): void {
    setSelectedInsurance(initialInsurancePolicy)
    triggerCreateInsuranceModal()
  }

  // MUTATIONS
  // Delete
  const [
    deleteInsurance,
    { isLoading: isLoadingDeleteInsurance },
  ] = useDeleteInsuranceMutation()
  async function handleDeleteInsurance(payload: InsurancePolicy): Promise<void> {
    try {
      await deleteInsurance(payload).unwrap()
      closeDeleteInsuranceModal()
      toast.success(
        `Successfully deleted "${payload.IN_NAME}" from your insurance policies.`
      )
    } catch (error) {
      toast.error('There was an unexpected error.')
    }
  }

  // Edit
  const [editInsurance, { isLoading: isLoadingEditInsurance }] = useEditInsuranceMutation()
  async function handleEditInsurance(payload: InsurancePolicy): Promise<void> {
    try {
      await editInsurance(payload).unwrap()
      closeEditInsuranceModal()
      toast.success(`Insurance policy "${payload.IN_NAME}" successfully updated.`)
    } catch (error) {
      toast.error('There was an unexpected error updating this insurance policy.')
    }
  }

  // Create
  const [
    createInsurance,
    { isLoading: isLoadingCreateInsurance },
  ] = useCreateInsuranceMutation()
  async function handleCreateInsurance(payload: InsurancePolicy): Promise<void> {
    try {
      await createInsurance(payload).unwrap()

      closeCreateInsuranceModal()
      toast.success(`Successfully added ${payload.IN_NAME} to insurance policies.`)
    } catch (error) {
      toast.error('There was an unexpected error creating the insurance policy.')
    }
  }

  return {
    // tableParams return
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    //
    filterState,
    setFilterState,
    searchKey,
    selectedInsurance,

    insurances,
    isLoadingInsurances,
    isErrorFetchingInsurances,
    isRefetchingInsurances,

    handleDeleteInsurance,
    isLoadingDeleteInsurance,

    handleEditInsurance,
    handleCreateInsurance,
    isLoadingCreateInsurance,
    isLoadingEditInsurance,

    deleteInsuranceModalIsOpen,
    triggerDeleteInsuranceModal,
    closeDeleteInsuranceModal,
    openDeleteInsuranceModal,
    openEditInsuranceModal,
    triggerEditInsuranceModal,
    closeEditInsuranceModal,
    editInsuranceModalIsOpen,

    createInsuranceModalIsOpen,
    openCreateInsuranceModal,
    closeCreateInsuranceModal,
  }
}
