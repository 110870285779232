import { maxStringMsg } from '@utils/basic'
import { REQUIRED_FIELD } from '@constants'
import * as Yup from 'yup'

export const taskFormId = 'task_form'
export const taskSchema = Yup.object({
  OT_ACT_ID: Yup.string()
    .typeError(REQUIRED_FIELD)
    .max(12, maxStringMsg(12))
    .required(REQUIRED_FIELD),
  OT_DESC: Yup.string()
    .typeError(REQUIRED_FIELD)
    .max(50, maxStringMsg(50))
    .required(REQUIRED_FIELD),
  OT_RETD_CD: Yup.string().nullable(),
  OT_OAPC_ID: Yup.string().nullable(),
  OT_ODPC_ID: Yup.string().nullable(),
})
export type Task = Yup.InferType<typeof taskSchema>
export type TaskKey = keyof Task

export const initialTask: Task = {
  OT_ACT_ID: '',
  OT_DESC: '',
  OT_RETD_CD: undefined,
  OT_OAPC_ID: undefined,
  OT_ODPC_ID: undefined,
}

// Payloads and responses

export interface FetchTasksResponse {
  count: number
  list: Task[]
}
