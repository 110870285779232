import { BorderRadius, FontSize, Palette, Scale, BoxShadow } from '@styles-api/css.types'
import { StyleObject } from '@styles-api/css.types'

import { APPEARANCE, SIZE, SHAPE, INTENT } from './constants'

// base button styles applied to all variations
export const base: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition:
    'all 70ms cubic-bezier(0,0,.38,.9),box-shadow 70ms cubic-bezier(0,0,.38,.9),border-color 70ms cubic-bezier(0,0,.38,.9),outline 70ms cubic-bezier(0,0,.38,.9)',
  fontWeight: 'var(--font-weight-semiBold)',
  overflow: 'hidden',
  boxSizing: 'border-box',
  position: 'relative',
}

export const textButton = ({ disabled }): StyleObject => {
  let pointerEvents
  if (disabled) pointerEvents = 'none'
  return {
    color: disabled ? 'var(--text-muted)' : 'var(--primary-color)',
    display: 'inline-block',
    cursor: disabled ? 'not-allowed' : 'pointer',
    pointerEvents,
    fontWeight: 'var(--font-weight-semiBold)',

    ':active': {
      color: 'var(--primary-color500)',
    },
    ':hover': {
      textDecoration: 'underline',
    },
    ':focus-visible': {
      textDecoration: 'underline',
      color: 'var(--primary-color500)',
    },
  }
}

// get color & background color styles based on appearance and intent
export const getApperanceIntent = ({ appearance, intent, disabled, overShade }) => {
  // initial state vars
  var backgroundColor: Palette | string
  var shadow: BoxShadow
  var borderWidth: string
  var borderColor: Palette
  var color: Palette

  // hover state vars
  var backgroundColorHover: Palette
  var borderColorHover: Palette
  var colorHover: Palette

  // active state vars
  var backgroundColorActive: Palette
  var shadowActive: BoxShadow
  // var borderColorActive: Palette

  // focus state vars
  var backgroundColorFocus: Palette
  var borderColorFocus: Palette
  var colorFocus: Palette
  var shadowFocus

  var pointerEvents

  // let fsPrimary = 'inset 0 0 0 1px var(--primary-color), inset 0 0 0 2px #fff'
  let fsDef = 'inset 0 0 0 1px var(--primary-color), inset 0 0 0 2px #fff'

  if (disabled === false) {
    if (intent === INTENT.default) {
      switch (appearance) {
        case APPEARANCE.default:
          backgroundColor = 'var(--default-btn-bg)'
          shadow = 'var(--default-btn-shadow)'
          borderWidth = '1px'
          borderColor = 'var(--btn-border-color)'
          color = 'var(--text-color)'

          backgroundColorHover = 'var(--default-btn-bg-hover)'
          borderColorHover = 'var(--btn-border-color)'
          colorHover = 'var(--text-color)'

          backgroundColorActive = 'var(--default-btn-bg-active)'
          //borderColorActive = 'var(--btn-border-color-active)'
          shadowActive = 'none'

          shadowFocus = 'var(--focus-shadow)'
          backgroundColorFocus = 'var(--default-btn-bg)'
          borderColorFocus = 'var(--primary-color)'

          break
        case APPEARANCE.primary:
          backgroundColor = 'linear-gradient(180deg,#0d82df,#0070c9)'
          shadow = 'var(--primary-btn-shadow)'
          borderWidth = '1px'
          borderColor = 'var(--btn-border-color)'
          color = 'var(--interaction-contrast)'

          backgroundColorHover = 'var(--success-color500)'
          borderColorHover = 'var(--btn-border-color)'
          colorHover = 'var(--interaction-contrast)'

          backgroundColorActive = 'var(--success-color500)'
          shadowActive = 'var(--btn-active-shadow)'

          shadowFocus = 'var(--focus-shadow)'
          backgroundColorFocus = 'var(--success-color)'
          borderColorFocus = 'var(--success-color)'
          break
        case APPEARANCE.muted:
          backgroundColor = 'var(--shade200)'
          backgroundColorHover = 'var(--shade300)'
          backgroundColorActive = 'var(--shade400)'
          color = 'var(--text-color)'
          borderColor = 'var(--shade200)'
          borderColorHover = 'var(--shade300)'
          borderColorFocus = 'var(--primary-color)'
          shadowFocus = fsDef
          break
        case APPEARANCE.clear:
          backgroundColor = 'var(--transparent)'
          color = 'var(--text-color)'
          borderWidth = '1px'
          borderColor = 'var(--transparent)'

          backgroundColorActive = 'var(--shade300)'

          backgroundColorHover = 'var(--shade200)'

          // shadowFocus = 'var(--focus-shadow)'
          // backgroundColorFocus = 'var(--default-btn-bg)'
          // borderColorFocus = 'var(--primary-color)'
          break
      }
    } else if (intent === INTENT.danger) {
      switch (appearance) {
        case APPEARANCE.default:
          backgroundColor = 'var(--default-btn-bg)'
          shadow = 'var(--default-btn-shadow)'
          borderWidth = '1px'
          borderColor = 'var(--btn-border-color)'
          color = 'var(--danger-color)'

          backgroundColorHover = 'var(--default-btn-bg-hover)'
          borderColorHover = 'var(--btn-border-color)'
          colorHover = 'var(--danger-color)'

          backgroundColorActive = 'var(--default-btn-bg-active)'
          // borderColorActive = 'var(--btn-border-color-active)'
          shadowActive = 'none'

          shadowFocus = 'var(--focus-shadow)'
          backgroundColorFocus = 'var(--default-btn-bg)'
          borderColorFocus = 'var(--primary-color)'
          break
        case APPEARANCE.primary:
          backgroundColor = 'var(--danger-color)'
          shadow = 'var(--primary-btn-shadow)'
          borderWidth = '1px'
          borderColor = 'var(--btn-border-color)'
          color = 'var(--interaction-contrast)'

          backgroundColorHover = 'var(--danger-color500)'
          borderColorHover = 'var(--btn-border-color)'
          colorHover = 'var(--interaction-contrast)'

          backgroundColorActive = 'var(--danger-color500)'
          shadowActive = 'var(--default-inset-shadow)'

          shadowFocus = 'var(--focus-shadow)'
          backgroundColorFocus = 'var(--danger-color)'
          borderColorFocus = 'var(--btn-border-color)'

          break
        case APPEARANCE.muted:
          backgroundColor = 'var(--shade200)'
          color = 'var(--danger-color)'

          backgroundColorHover = 'var(--shade300)'
          colorHover = 'var(--danger-color)'

          backgroundColorActive = 'var(--base-color)'
          shadowActive = 'var(--default-inset-shadow)'

          shadowFocus = 'var(--focus-shadow)'
          backgroundColorFocus = 'var(--default-btn-bg)'
          borderColorFocus = 'var(--primary-color)'
          break
        case APPEARANCE.clear:
          backgroundColor = 'var(--transparent)'
          color = 'var(--danger-color)'

          backgroundColorHover = 'var(--shade200)'
          colorHover = 'var(--danger-color)'

          backgroundColorActive = 'var(--base-color)'
          shadowActive = 'var(--default-inset-shadow)'

          shadowFocus = 'var(--focus-shadow)'
          backgroundColorFocus = 'var(--default-btn-bg)'
          borderColorFocus = 'var(--primary-color)'
          break
      }
    }
  } else if (disabled) {
    backgroundColor = 'var(--shade200)'
    color = 'var(--text-muted)'
    pointerEvents = 'none'
    borderColor = 'var(--shade200)'
    switch (appearance) {
      case APPEARANCE.default:
      case APPEARANCE.primary:
        borderWidth = '1px'
    }
  }
  if (overShade && appearance === 'default') backgroundColor = 'var(--base-color)'
  if (appearance === 'clear' && disabled) backgroundColor = 'var(--transparent)'
  return {
    background: backgroundColor,
    borderWidth,
    borderColor,
    color,
    pointerEvents,
    cursor: disabled ? 'not-allowed' : 'pointer',
    boxShadow: shadow,

    ':hover': {
      backgroundColor: backgroundColorHover,
      color: colorHover,
      borderColor: borderColorHover,
    },
    ':active': {
      backgroundColor: backgroundColorActive,
      boxShadow: shadowActive,
    },
    ':focus-visible': {
      backgroundColor: backgroundColorFocus,
      color: colorFocus,
      boxShadow: shadowFocus,
      borderColor: borderColorFocus,
      outline: 'var(--focus-outline)',
      ':hover': {
        borderColor: borderColorFocus,
      },
      ':active': {
        backgroundColor: backgroundColorActive,
        boxShadow: shadowActive,
        borderColor: borderColor,
        outline: 'none',
      },
    },
  }
}

// size & shape styles
export const getSizeShape = ({ size, shape, flex, loading, flexGrow, sizeY }) => {
  // height is only dependent on size prop, so we can easily define it with:
  var height = `var(--size-${size})`
  //
  var paddingX: Scale
  var paddingY: Scale

  var fontSize: FontSize
  var width: string
  var borderRadius: BorderRadius

  let fg
  if (flexGrow === true) fg = 1

  // if shape is not circle or square, get padding; otherwise, get width to equal size value:
  if (shape !== SHAPE.square && shape !== SHAPE.circle) {
    switch (size) {
      case SIZE.mini:
        paddingX = 'var(--scale500)'
        fontSize = 'var(--small-font-size)'
        break
      case SIZE.compact:
        paddingX = 'var(--scale550)'
        fontSize = 'var(--base-font-size)'
        break
      case SIZE.default:
        paddingX = 'var(--scale600)'
        fontSize = 'var(--base-font-size)'
        break
      case SIZE.large:
        paddingX = 'var(--scale700)'
        fontSize = 'var(--large-font-size)'
        break
      case SIZE.extraLarge:
        paddingX = 'var(--scale1600)'
        fontSize = 'var(--large-font-size)'
        break
    }
    if (sizeY) {
      switch (sizeY) {
        case SIZE.extraLarge:
          paddingY = 'var(--scale1600)'
          break
      }
    }
  } else if (shape === SHAPE.circle || shape === SHAPE.square) {
    width = `var(--size-${size})`
  }
  if (flex === true) {
    width = '100%'
  }

  // get border-radius depending on shape
  switch (true) {
    case shape === SHAPE.default:
    case shape === SHAPE.square:
      borderRadius = 'var(--radius1)'
      break
    case shape === SHAPE.pill:
      borderRadius = 'var(--pill-radius)'
      break
    case shape === SHAPE.circle:
      borderRadius = '100%'
      break
  }

  // if loading, we change the flex-direction to 'column' to center the loading spinner
  let flexDirection
  if (loading === true) {
    flexDirection = 'column'
  }

  return {
    height,
    width,
    fontSize,
    paddingLeft: paddingX,
    paddingRight: paddingX,
    paddingTop: paddingY,
    paddingBottom: paddingY,
    borderRadius,
    flexDirection,
    flexGrow: fg,
  }
}

// icon styles
export const getIconWrapperStyles = ({ icon, iconRight, shape }) => {
  let spacing = 'var(--scale300)'
  var paddingRight
  var paddingLeft

  switch (true) {
    case icon && shape !== SHAPE.circle && shape !== SHAPE.square:
      paddingRight = spacing
      break
    case iconRight && shape !== SHAPE.circle && shape !== SHAPE.square:
      paddingLeft = spacing
      break
  }

  return {
    paddingLeft,
    paddingRight,
    display: 'flex',
    alignItems: 'center',
  }
}
export const getIconStyles = ({ size, shape }) => {
  var iconSize: Scale
  if (shape === 'default' || shape === 'pill') {
    switch (size) {
      case SIZE.mini:
        iconSize = 'var(--scale500)'
        break
      case SIZE.compact:
        iconSize = 'var(--scale500)'
        break
      case SIZE.default:
        iconSize = 'var(--scale600)'
        break
      case SIZE.large:
        iconSize = 'var(--scale650)'
        break
    }
  } else if (shape === 'circle' || shape === 'square') {
    switch (size) {
      case SIZE.mini:
        iconSize = 'var(--scale550)'
        break
      case SIZE.compact:
        iconSize = 'var(--scale600)'
        break
      case SIZE.default:
        iconSize = 'var(--scale600)'
        break
      case SIZE.large:
        iconSize = 'var(--scale650)'
        break
    }
  }

  return {
    height: iconSize,
    width: iconSize,
  }
}

// loading styles
// we wrap the button content (text and icon) in a div and hide it when loading to maintain width of button
export const getContentStyles = ({ loading }): StyleObject => {
  let height
  let opacity
  if (loading === true) {
    height = 0
    opacity = 0
  }
  return {
    display: 'flex',
    alignItems: 'center',
    height,
    opacity,
    pointerEvents: 'none', // this ensures that e.target is the button element, not inner div
  }
}

// skeleton styles

export const getSkeletonStyles = ({ size, shape, flex, flexGrow, width }) => {
  var height = `var(--size-${size})`
  var paddingX: Scale
  var fontSize: FontSize
  var borderRadius: BorderRadius
  if (shape !== SHAPE.square && shape !== SHAPE.circle) {
    switch (size) {
      case SIZE.mini:
        paddingX = 'var(--scale500)'
        fontSize = 'var(--small-font-size)'
        break
      case SIZE.compact:
        paddingX = 'var(--scale550)'
        fontSize = 'var(--base-font-size)'
        break
      case SIZE.default:
        paddingX = 'var(--scale600)'
        fontSize = 'var(--base-font-size)'
        break
      case SIZE.large:
        paddingX = 'var(--scale700)'
        fontSize = 'var(--large-font-size)'
        break
    }
  } else if (shape === SHAPE.circle || shape === SHAPE.square) {
    width = `var(--size-${size})`
  }
  if (flex === true) {
    width = '100%'
  }

  let fg
  if (flexGrow === true) fg = 1

  // get border-radius depending on shape
  switch (true) {
    case shape === (SHAPE.default || SHAPE.square):
      borderRadius = 'var(--radius1)'
      break
    case shape === SHAPE.pill:
      borderRadius = 'var(--pill-radius)'
      break
    case shape === SHAPE.circle:
      borderRadius = '100%'
      break
  }
  return {
    height,
    width,
    fontSize,
    paddingLeft: paddingX,
    paddingRight: paddingX,
    borderRadius,
    borderWidth: '2px',
    borderColor: 'var(--transparent)',
    flexGrow: fg,
  }
}
export const skeletonContentStyles: StyleObject = {
  display: 'flex',
  alignItems: 'center',
  opacity: 0,
  height: 0,
  fontWeight: 'var(--font-weight-semiBold)',
}
