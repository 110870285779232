import { CustomDatePicker, Grid, Input, Text } from '@components/common'
import { useFormFieldProps } from '@hooks'
import { Asset, FieldLabels } from '@api/models'
import { Controller, useFormContext } from 'react-hook-form'

interface PurchaseDetailsProps {
  fields: FieldLabels,
}

const AddAssetPurchaseDetails = ({ fields }: PurchaseDetailsProps) => {
  const {
    formState: { isSubmitting, errors },
    register,
    control,
  } = useFormContext<Asset>()

  const { getInputProps, getDateProps } = useFormFieldProps({
    isSubmitting,
    errors,
    fields,
    register,
  })

  return (
    <Grid>
      <Grid.Item>
        <Text light>
          Define the book information for this asset. You can change these values after the
          asset is added.
        </Text>
      </Grid.Item>
      <Grid.Item>
        <Input
          {...getInputProps('AS_FIN_COS', 'currency')}
          autoFocus
        />
      </Grid.Item>

      <Grid.Item>
        <Controller
          control={control}
          name="AS_REC_DAT"
          render={({ field }) => (
            <CustomDatePicker
              {...getDateProps('AS_REC_DAT', field)}
              label={fields[`AS_REC_DAT`]}
              />
            )}
        />
      </Grid.Item>
      <Grid.Item>
        <Input {...getInputProps('AS_FIN_SAL', 'currency')}
        />
      </Grid.Item>
      <Grid.Item>
        <Input {...getInputProps('AS_TAX_BUS', 'percentage')}
        />
      </Grid.Item>
    </Grid>
  )
}

export default AddAssetPurchaseDetails
