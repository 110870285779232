import React from 'react'
import { useStyletron } from 'styletron-react'

import * as styles from './Styles'

const Main = ({ children }) => {
  const [css] = useStyletron()
  return <main className={css(styles.main)}>{children}</main>
}
export default Main
