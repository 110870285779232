import {
  CompanyStructureBase,
  FetchSegmentsResponse,
  Segment,
  SegmentBase,
  SegmentType,
} from '@api/models'
import { toast, useModal } from '@components/common'
import {
  useCreateSegmentMutation,
  useDeleteSegmentMutation,
  useEditSegmentMutation,
  useFetchSegmentsQuery,
  useGetSegmentTypesQuery,
} from '@api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTableParams } from '@hooks'
import { TableParamsHook } from 'hooks/useTableParams'
import { useChangeStructureMutation } from '@api/segment/segmentEndpoints'

export interface SegmentsHook extends TableParamsHook {
  filterState: Object
  setFilterState: Dispatch<SetStateAction<{}>>
  selectedSegment: Segment | undefined
  setSelectedSegment: Dispatch<SetStateAction<Segment>>

  searchKey: string
  isLoadingSegmentTypes: boolean
  isErrorGettingSegmentTypes: boolean
  segments: FetchSegmentsResponse
  isLoadingSegments: boolean
  isErrorFetchingSegments: boolean
  isRefetchingSegments: boolean

  selectSegmentTypeOptions: SegmentType[]
  handleChangeSegmentType: (e: { label: string; value: any }) => void
  selectSegmentTypeValue: SegmentType

  handleEditSegment: (payload: SegmentBase) => Promise<void>
  handleCreateSegment: (payload: SegmentBase) => Promise<void>
  isLoadingEditSegment: boolean
  isLoadingCreateSegment: boolean

  handleDeleteSegment: (payload: SegmentBase) => Promise<void>
  isLoadingDeleteSegment: boolean

  deleteSegmentModalIsOpen: boolean
  triggerDeleteSegmentModal
  closeDeleteSegmentModal
  openDeleteSegmentModal: (segment: Segment) => void
  openEditSegmentModal: (segment: Segment) => void
  triggerEditSegmentModal
  closeEditSegmentModal
  editSegmentModalIsOpen: boolean

  openCreateSegmentModal: () => void
  createSegmentModalIsOpen: boolean
  closeCreateSegmentModal

  handleChangeSegmentTypeInForm: (e: { label: string; value: any }) => void
  newSegmentType: SegmentType

  navigate: any
  previousPage: string

  changeStructureModalIsOpen: boolean
  openChangeStructureModal: () => void
  closeChangeStructureModal: () => void
  handleChangeStructure: (payload: CompanyStructureBase) => Promise<void>
  isLoadingChangeStructure: boolean
  selectedStructureType: SegmentType
  setSelectedStructureType: Dispatch<SetStateAction<SegmentType>>
  handleChangeSegmentTypeInEditForm: (e: { label: string; value: any }) => void
}

export default function useSegments(): SegmentsHook {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])

  const [filterState, setFilterState] = useState({})
  const [selectedSegment, setSelectedSegment] = useState<Segment>(undefined)

  const searchKey = 'KY_DESC'
  const initialItemsPerPage = 20

  // Use useTableParams hook for table filtering, pagination, etc.
  const {
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
  } = useTableParams({
    searchKey,
    filterState,
    setFilterState,
    initialItemsPerPage,
  })

  // First we need segment types
  const {
    data: segmentTypes,
    isLoading: isLoadingSegmentTypes,
    isError: isErrorGettingSegmentTypes,
    isSuccess: hasLoadedSegmentTypes,
  } = useGetSegmentTypesQuery()

  // Update URL param when segment types have loaded
  useEffect(() => {
    if (segmentTypes && segmentTypes.length > 0 && !searchParams.get('key')) {
      setSearchParams({
        ...searchParamsObject,
        key: segmentTypes[0].value.toString(),
      })
    }
  }, [isLoadingSegmentTypes])

  // Fetch segments
  const skipFetchSegments: boolean = !hasLoadedSegmentTypes

  const {
    data: segments,
    isLoading: isLoadingSegments,
    isError: isErrorFetchingSegments,
    isFetching: isRefetchingSegments,
  } = useFetchSegmentsQuery(
    {
      ...fetchRequestPayload,
      key: searchParams.get('key') ? parseInt(searchParams.get('key')) : 0,
    },
    {
      skip: skipFetchSegments,
    }
  )

  // Segment type functions and values
  const selectSegmentTypeOptions: SegmentType[] = segmentTypes?.map(segmentType => ({
    label: segmentType.label,
    value: segmentType.value,
  }))

  function handleChangeSegmentType(e: { label: string; value: any }): void {
    setSearchParams({
      ...searchParamsObject,
      key: e.value.toString(),
      pageNumber: '1',
    })
  }
  const selectSegmentTypeValue: SegmentType = {
    label: segmentTypes?.find(
      segmentType => segmentType.value === parseInt(searchParams.get('key'))
    )?.label,
    value: parseInt(searchParams.get('key')),
  }

  // Modal states
  const {
    modalIsOpen: deleteSegmentModalIsOpen,
    triggerModal: triggerDeleteSegmentModal,
    closeModal: closeDeleteSegmentModal,
  } = useModal()

  function openDeleteSegmentModal(segment: Segment): void {
    setSelectedSegment(segment)
    triggerDeleteSegmentModal()
  }

  const {
    modalIsOpen: editSegmentModalIsOpen,
    triggerModal: triggerEditSegmentModal,
    closeModal: closeEditSegmentModal,
  } = useModal()

  function openEditSegmentModal(segment: Segment): void {
    setSelectedSegment(segment)
    triggerEditSegmentModal()
  }

  const {
    modalIsOpen: createSegmentModalIsOpen,
    triggerModal: triggerCreateSegmentModal,
    closeModal: closeCreateSegmentModal,
  } = useModal()

  function openCreateSegmentModal(): void {
    triggerCreateSegmentModal()
  }

  // MUTATIONS
  // Delete
  const [deleteSegment, { isLoading: isLoadingDeleteSegment }] = useDeleteSegmentMutation()
  async function handleDeleteSegment(payload: SegmentBase): Promise<void> {
    try {
      await deleteSegment(payload).unwrap()
      closeDeleteSegmentModal()
      toast.success(`Successfully deleted "${payload.KY_DESC}" from segments.`)
    } catch (error) {
      toast.error('There was an unexpected error deleting segment.')
    }
  }

  // Edit
  const [editSegment, { isLoading: isLoadingEditSegment }] = useEditSegmentMutation()
  async function handleEditSegment(payload: SegmentBase): Promise<void> {
    try {
      await editSegment(payload).unwrap()
      closeEditSegmentModal()
      toast.success(`Segment successfully updated.`)
    } catch (error) {
      toast.error('There was an unexpected error updating this segment.')
    }
  }

  // Create
  const [newSegmentType, setNewSegmentType] = useState<SegmentType>({
    label: undefined,
    value: undefined,
  })

  useEffect(() => {
    if (searchParams.get('key')) {
      setNewSegmentType({
        label: segmentTypes?.find(
          option => option.value === parseInt(searchParams.get('key'))
        )?.label,
        value: parseInt(searchParams.get('key')),
      })
    }
  }, [searchParams.get('key'), segmentTypes])

  function handleChangeSegmentTypeInForm(e: { label: string; value: any }): void {
    setNewSegmentType(e)
  }

  const [createSegment, { isLoading: isLoadingCreateSegment }] = useCreateSegmentMutation()
  async function handleCreateSegment(payload: SegmentBase): Promise<void> {
    try {
      await createSegment({
        kY_NR: newSegmentType.value,
        ...payload,
      }).unwrap()

      if (newSegmentType.value !== parseInt(searchParams.get('key'))) {
        setSearchParams({
          ...searchParamsObject,
          key: newSegmentType.value.toString(),
        })
      }
      closeCreateSegmentModal()
      toast.success(`Successfully added "${payload.KY_DESC}" to ${newSegmentType.label}.`)
    } catch (error) {
      toast.error('There was an unexpected error creating segment.')
    }
  }

  // This is used to navigate back to the previous page when the user clicks "View Segments" inside the asset details page
  const navigate = useNavigate()
  const [previousPage, setPreviousPage] = useState(null)

  useEffect(() => {
    const storedPage = sessionStorage.getItem('previousPage')
    if (storedPage) {
      setPreviousPage(storedPage)
    }
    return () => {
      // Cleanup function to clear the previousPage storage when this component is unmounted
      sessionStorage.removeItem('previousPage')
    }
  }, [])

  // Change Structure
  const {
    modalIsOpen: changeStructureModalIsOpen,
    triggerModal: triggerChangeStructureModal,
    closeModal: closeChangeStructureModal,
  } = useModal()

  function openChangeStructureModal(): void {
    triggerChangeStructureModal()
  }

  const [
    changeStructure,
    { isLoading: isLoadingChangeStructure },
  ] = useChangeStructureMutation()
  async function handleChangeStructure(payload: CompanyStructureBase): Promise<void> {
    try {
      await changeStructure(payload).unwrap()
      closeChangeStructureModal()
      toast.success(`Segment successfully updated.`)
    } catch (error) {
      toast.error('There was an unexpected error updating this segment.')
    }
  }

  const [selectedStructureType, setSelectedStructureType] = useState<SegmentType>({
    label: undefined,
    value: undefined,
  })

  function handleChangeSegmentTypeInEditForm(e: { label: string; value: any }): void {
    setSelectedStructureType(e)
  }

  return {
    // tableParams return
    pageNumber,
    itemsPerPage,
    fetchRequestPayload,
    handleSearch,
    searchValue,
    changePage,
    sortByInitialState,
    updateSortSearchParam,
    //
    selectedSegment,
    setSelectedSegment,
    filterState,
    setFilterState,
    searchKey,
    isLoadingSegmentTypes,
    isErrorGettingSegmentTypes,
    segments,
    isLoadingSegments,
    isErrorFetchingSegments,
    isRefetchingSegments,
    selectSegmentTypeOptions,
    handleChangeSegmentType,
    selectSegmentTypeValue,
    handleDeleteSegment,
    isLoadingDeleteSegment,

    handleEditSegment,
    handleCreateSegment,
    isLoadingCreateSegment,
    isLoadingEditSegment,

    deleteSegmentModalIsOpen,
    triggerDeleteSegmentModal,
    closeDeleteSegmentModal,
    openDeleteSegmentModal,
    openEditSegmentModal,
    triggerEditSegmentModal,
    closeEditSegmentModal,
    editSegmentModalIsOpen,

    createSegmentModalIsOpen,
    openCreateSegmentModal,
    closeCreateSegmentModal,
    handleChangeSegmentTypeInForm,
    newSegmentType,

    navigate,
    previousPage,

    changeStructureModalIsOpen,
    openChangeStructureModal,
    closeChangeStructureModal,
    handleChangeStructure,
    isLoadingChangeStructure,
    selectedStructureType,
    setSelectedStructureType,
    handleChangeSegmentTypeInEditForm,
  }
}
