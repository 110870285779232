import Template from '@components/layout/Template'
import { APP_TITLE, APP_TITLE_DIVIDER, SETTING_PATHS } from '@constants'
import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'

const SettingsTemplate = () => {
  const navItems = [
    {
      label: 'Profile',
      viewPath: SETTING_PATHS.PROFILE,
    },
    {
      label: 'Field Preferences',
      viewPath: SETTING_PATHS.FIELD_PREFS,
    },
    {
      label: 'Customize Formats',
      viewPath: SETTING_PATHS.CUSTOMIZE_FORMATS,
    },
    {
      label: 'Account Settings',
      viewPath: SETTING_PATHS.ACCOUNT_SETTINGS,
    },
    {
      label: 'Change Password',
      viewPath: SETTING_PATHS.CHANGE_PASSWORD,
    },
    {
      label: 'Edit AssetID',
      viewPath: SETTING_PATHS.EDIT_ASSETID,
    },
    // {
    //   label: 'Account Structure Setup',
    //   viewPath: SETTING_PATHS.ACCOUNT_STRUCTURE_SETUP,
    // }, 
  ]

  return (
    <>
      <Helmet
        titleTemplate={`${APP_TITLE} ${APP_TITLE_DIVIDER} Settings ${APP_TITLE_DIVIDER}  %s `}
      />
      <Template localNav={navItems} title="Settings" maxWidth="medium">
        <Outlet />
      </Template>
    </>
  )
}

export default SettingsTemplate
