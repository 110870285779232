import { Asset, FieldLabels } from '@api/models'
import {
  Button,
  Card,
  Divider,
  Flexbox,
  Icon,
  Spacer,
  StatusIndicator,
} from '@components/common'
import { Dispatch, SetStateAction, useState } from 'react'
import { ASSET_PATHS, STATUS_MAP } from '@constants'
import AssetDisposalDetails from '@components/asset/AssetDisposal/AssetDisposalDetails'
import { useNavigate } from 'react-router-dom'
import { IUserGrantProps } from '@components/Auth/UserRole'
import AssetStatusLoading from './AssetStatusLoading'

interface Props extends IUserGrantProps {
  setIsDisposalFormModalOpen: Dispatch<SetStateAction<boolean>>
  asset: Asset
  assetCompanyID: number
  assetHasHistory: boolean
  isDirty: boolean
  setIsReinstatementModalOpen: Dispatch<SetStateAction<boolean>>
  fields: FieldLabels
  isLoadingInfo: boolean
  setIsChangeAssetStatusModalOpen: Dispatch<SetStateAction<boolean>>
}
const AssetStatus = ({
  setIsDisposalFormModalOpen,
  asset,
  assetHasHistory,
  isDirty,
  setIsReinstatementModalOpen,
  fields,
  isLoadingInfo,
  assetCompanyID,
  setIsChangeAssetStatusModalOpen,
  canEdit,
}: Props) => {
  const [showDisposalDetails, setShowDisposalDetails] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <Card title={'Asset status'}>
      {isLoadingInfo && <AssetStatusLoading />}

      {!isLoadingInfo && (
        <>
          <Flexbox justifyContent="space-between" alignItems="center">
            <StatusIndicator
              type={STATUS_MAP[asset.AS_DIS_CD]?.type ?? 'default'}
              label={`${STATUS_MAP[asset.AS_DIS_CD].status}`}
            />

            {canEdit && (
              <Flexbox alignItems="center">
                {(asset.AS_DIS_CD === 'A' || asset.AS_DIS_CD === 'N') && !assetHasHistory && (
                  <>
                    <Button
                      appearance="text"
                      onClick={() => setIsChangeAssetStatusModalOpen(true)}
                      icon={
                        asset.AS_DIS_CD === 'A' ? (
                          <Icon name="StopCircle" />
                        ) : (
                          <Icon name="PlayCircle" />
                        )
                      }
                    >
                      {asset.AS_DIS_CD === 'A' ? 'Deactivate' : 'Activate'}
                    </Button>
                    <Spacer x="mini" /> | <Spacer x="mini" />
                  </>
                )}
                {asset.AS_DIS_CD === 'P' && (
                  <Button
                    type="button"
                    appearance="text"
                    onClick={() => setIsReinstatementModalOpen(true)}
                    icon={<Icon name="ArrowUpCircle" />}
                  >
                    Reinstate asset
                  </Button>
                )}
                {asset.AS_DIS_CD !== 'D' && asset.AS_DIS_CD !== 'P' && (
                  <Button
                    onClick={() => navigate(ASSET_PATHS.DISPOSAL)}
                    intent="danger"
                    flex
                    disabled={isDirty}
                    appearance="text"
                    icon={<Icon name="Trash2" />}
                  >
                    Dispose
                  </Button>
                )}
              </Flexbox>
            )}
          </Flexbox>

          {(asset.AS_DIS_CD === 'D' || asset.AS_DIS_CD === 'P') && (
            <>
              <Divider spacing="default" />
              <Flexbox justifyContent="center">
                <Button
                  appearance="text"
                  type="button"
                  iconRight={
                    showDisposalDetails ? (
                      <Icon name="ChevronUp" />
                    ) : (
                      <Icon name="ChevronDown" />
                    )
                  }
                  onClick={() => setShowDisposalDetails(!showDisposalDetails)}
                >
                  {showDisposalDetails ? 'Hide' : 'Show'} disposal details
                </Button>
              </Flexbox>
            </>
          )}

          {(asset.AS_DIS_CD === 'D' || asset.AS_DIS_CD === 'P') && showDisposalDetails && (
            <>
              <Divider spacing="default" />
              <AssetDisposalDetails
                assetCompanyID={assetCompanyID}
                assetID={asset.AssetID}
                asset={asset}
                fields={fields}
              />
            </>
          )}
        </>
      )}
    </Card>
  )
}

export default AssetStatus
