import React from 'react'
import Select, { components } from 'react-select'
import { SelectProps } from './Select.types'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import * as inputStyles from '../Input/Styles'

import Icon from '../Icon'
import Tag from '../Tag'

const ClearIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props

  const [css] = useStyletron()
  return (
    <div
      {...restInnerProps}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: 'var(--size-default)',
        justifyContent: 'center',
      }}
    >
      <Icon name="X" className={css(styles.clearIcon)} />
    </div>
  )
}
const DropdownIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props

  const [css] = useStyletron()
  return (
    <div
      {...restInnerProps}
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: 'var(--size-default)',
        justifyContent: 'center',
      }}
    >
      <Icon name="ChevronDown" className={css(styles.dropdownIndicator)} />
    </div>
  )
}

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      No options yet.
      {props.type === 'autocomplete' && 'Type and press enter to add an option.'}
    </components.NoOptionsMessage>
  )
}

const MultiSelect = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ placeholder = '', isClearable = true, disabled, ...props }, ref) => {
    const [css] = useStyletron()
    const currentValue = props.value || []

    let containerStyles = css({
      ...inputStyles.container({ width: props.width, flexGrow: props.flexGrow }),
    })
    let labelStyles = css({
      ...inputStyles.label({ label: props.label, disabled: disabled }),
    })

    const selectStyles = {
      control: (provided, state) => ({
        ...styles.control(provided, state, disabled, props.error, props.type),
        height: 'auto',
        minHeight: 'var(--size-default)',
      }),
      valueContainer: provided => ({ ...styles.valueContainer(provided) }),
      indicatorsContainer: () => ({ ...styles.indicatorsContainer }),
      menu: (provided, state) => ({
        ...styles.menu(provided, state),
      }),
      option: (provided, state) => ({
        ...styles.option(provided, state),
      }),
    }
    let helperStyles = css(inputStyles.helperText({ disabled: disabled }))
    let errorStyles = css(inputStyles.errorMsg)
    let errorIconStyles = css(inputStyles.errorMsgIcon)

    var emptyValue = props.value === undefined || props.value.length === 0

    const MultiValue = props => {
      return (
        <div>
          <Tag
            content={props.children}
            remove={!disabled && props.removeProps.onClick}
            style={{ marginTop: '4px', marginBottom: '4px' }}
          />
        </div>
      )
    }

    return (
      <div className={containerStyles}>
        <label className={labelStyles} id={`${props.name}-label`} htmlFor={props.name}>
          {props.required && '*'}
          {props.label}
        </label>

        <Select
          {...props}
          error={props.error}
          styles={selectStyles}
          components={{
            DropdownIndicator: !emptyValue || disabled ? () => null : DropdownIndicator,
            IndicatorSeparator: () => null,
            ClearIndicator: emptyValue || disabled ? () => null : ClearIndicator,
            NoOptionsMessage,
            MultiValue,
          }}
          isClearable={isClearable}
          isMulti
          placeholder={placeholder}
          value={props.options?.filter(v => currentValue.includes(v.value))}
          onChange={vals => props?.onChange(vals.map(v => v.value))}
          onBlur={props.onBlur}
          ref={ref}
          aria-labelledby={
            props['aria-labelledby'] ? props['aria-labelledby'] : `${props.name}-label`
          }
          disabled={disabled}
        />
        {props.helper && !props.error && <div className={helperStyles}>{props.helper}</div>}
        {props.errorMsg && props.error && (
          <div className={errorStyles}>
            <Icon name="AlertCircle" className={errorIconStyles} /> {props.errorMsg}
          </div>
        )}
      </div>
    )
  }
)

export default MultiSelect
